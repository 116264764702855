import { Flex, FlexProps, FormattedColor, Text } from '@tw/ui-components';
import { ShopIcon } from '../../blocks/ShopIcon';
import { SettingsAvatar } from '../../blocks/SettingsAvatar';
import { useDarkMode } from 'dark-mode-control';
import { useIsNavWideStyle } from 'components/Nav/stores';
import { useAppSelector } from 'reducers/RootType';
import { getFormattedShopName } from 'components/Nav/utils';
import { $currentShopId } from '$stores/$shop';
import { $shop } from '$stores/$shop';
import { useStoreValue } from '@tw/snipestate';

const smallProps: Partial<FlexProps> = {
  direction: 'column',
  w: '100%',
  justify: 'center',
  align: 'center',
  gap: 'xs',
  py: 'xs',
};

const largeProps: Partial<FlexProps> = {
  direction: 'row',
  justify: 'space-between',
  align: 'center',
};

export const TopSection = () => {
  const darkMode = useDarkMode();
  const isWide = useIsNavWideStyle();
  const shopsAsArray = useAppSelector((state) => state.shopsAsArray);
  const shop = useStoreValue($shop);
  const textColor: FormattedColor = darkMode ? 'white' : 'gray.5';
  const formattedShopName = getFormattedShopName(shop.shopName || $currentShopId.get(), {
    capitalize: true,
    length: 20,
  });

  return (
    <Flex {...(isWide ? largeProps : smallProps)} mih={59}>
      {isWide && (
        <Flex gap="xs" align="center">
          {shopsAsArray.length === 1 && <ShopIcon />}
          <Text color={textColor} fz={16} fw={500}>
            {formattedShopName}
          </Text>
        </Flex>
      )}

      <Flex gap="xs">
        <SettingsAvatar size={isWide ? undefined : 28} />
      </Flex>
    </Flex>
  );
};
