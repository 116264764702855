import { OptionList } from '@shopify/polaris';
import { Text } from '@tw/ui-components';
import {
  DatePickerTimePeriods,
  getDatePickerOptionValueOptions,
} from 'components/useDatePickerSelectedOptions';
import { useMemo } from 'react';

type DateOptionsListProps = {
  selectedOption: string | null;
  onOptionSelect: (option: string) => void;
};

export const DateOptionsList: React.FC<DateOptionsListProps> = ({
  selectedOption,
  onOptionSelect,
}) => {
  const datePickerOptionValueOptions = useMemo(() => getDatePickerOptionValueOptions(), []);

  return (
    <div>
      <OptionList
        onChange={(updated) => {
          onOptionSelect(updated[0] as DatePickerTimePeriods);
        }}
        options={datePickerOptionValueOptions.map((o) => ({
          value: o.id,
          label: <Text size="sm">{o.label}</Text>,
        }))}
        selected={[selectedOption ?? '']}
      />
    </div>
  );
};
