import React, { useEffect, useState, useRef, useMemo } from 'react';
import { useSelector } from 'react-redux';
import moment from '@tw/moment-cached/module/timezone';
import {
  Box,
  Icon as TWIcon,
  UnstyledButton,
  Button,
  Group,
  TextInput,
  Anchor,
  Grow,
  Flex,
} from '@tw/ui-components';
import {
  Card,
  Popover,
  ActionList,
  Spinner,
  Text,
  Icon,
  Modal,
  Select,
  ButtonGroup,
} from '@shopify/polaris';
import {
  DeleteMinor,
  MobileVerticalDotsMajor,
  CircleAlertMajor,
  BugMajor,
  RefreshMinor,
  EditMajor,
} from '@shopify/polaris-icons';
import { FREQUENCY } from './utils';
import { InlineMetricTags } from './ReportsSection';
import { GoogleSheetsReportBuilder } from './GoogleSheetsReportBuilder';
import type { RootState } from 'reducers/RootType';
import {
  loadGoogleSheetsReports,
  createGoogleSheetsReport,
  googleSheetsConnect,
  deleteGoogleSheetsReport,
  updateLegacyReportAccount,
  AnyGoogleSheetsReportInfo,
  GoogleSheetsMetricsReportInfo,
  editReportInfo,
} from 'ducks/googleSheets';
import { useAppDispatch } from 'index';
import { toast } from 'react-toastify';
import { FeatureFlag } from '@tw/feature-flag-system/module/types';
import { computeFeatureFlags } from 'feature-flag-system';
import { UpgradePageFallBack } from 'feature-flag-system/components';
import { Button as TwButton } from 'components/BaseComponents/Button';
import _ from 'lodash';
import copyToClipboard from 'utils/copyToClipboard';

export const GoogleSheetsReports: React.FC<{
  isFromActions?: boolean;
  actionType?: string;
  reportId?: string;
  defaultReports?: GoogleSheetsMetricsReportInfo;
}> = ({ isFromActions = false, defaultReports = null, reportId = null, actionType = null }) => {
  const dispatch = useAppDispatch();
  const {
    googleSheetsReports,
    isGoogleSheetsConnected,
    loadingGoogleSheetsReports,
    googleSheetsReportLoadingError,
    googleSheetsAccounts,
  } = useSelector((state: RootState) => state);
  const [gsConnectLoading, setGsConnectLoading] = useState<boolean>(false);
  const [building, setBuilding] = useState<boolean>(isFromActions);
  const [timer, setTimer] = useState<number>(0);

  useEffect(() => {
    if (!googleSheetsReports?.length) {
      dispatch(loadGoogleSheetsReports());
    }
  }, []);

  useEffect(() => {
    if (!timer && (googleSheetsReports || []).some((r) => r.building)) {
      let timer = window.setTimeout(() => {
        dispatch(loadGoogleSheetsReports());
      }, 30 * 1000);
      setTimer(timer);
    }
    return () => {
      window.clearTimeout(timer);
    };
  }, [googleSheetsReports]);

  return (
    <section className="reports-sections py-[5%] mx-auto max-w-[1200px]">
      {building ? (
        <GoogleSheetsReportBuilder
          isFromActions={isFromActions}
          defaultReports={defaultReports}
          onCancel={() => setBuilding(false)}
          onSubmit={(report: AnyGoogleSheetsReportInfo) => {
            dispatch(createGoogleSheetsReport(report));
            setBuilding(false);
          }}
        />
      ) : (
        <>
          {!reportId && (
            <div className="mb-[30px] lg:mb-[50px]">
              <div className="text-[20px] mb-[8px] font-semibold">Google Sheets Data Sync</div>
              <div className="lg:flex justify-between">
                <div className="text-[14px]">Automatically sync your data to a Google Sheet</div>
                {isGoogleSheetsConnected && (
                  <button
                    className="bg-[#1877F2] hover:bg-[#156BD9] text-white sm:px-[10px] lg:px-[24px] py-[8px] min-w-[75px] rounded-[4px] border-none cursor-pointer"
                    onClick={() => {
                      setBuilding(true);
                    }}
                  >
                    + Create new sync
                  </button>
                )}
              </div>
            </div>
          )}
          {!isGoogleSheetsConnected ? (
            <div className="mb-[30px] lg:mb-[50px]">
              <Button
                onClick={() => {
                  setGsConnectLoading(true);
                  googleSheetsConnect(isFromActions);
                }}
                loading={gsConnectLoading}
              >
                Connect to Google Sheets
              </Button>
            </div>
          ) : loadingGoogleSheetsReports ? (
            <Spinner />
          ) : googleSheetsReportLoadingError ? (
            <GoogleSheetsReportLoadingError />
          ) : googleSheetsReports.length ? (
            googleSheetsReports
              .filter((x) => (reportId ? x.id === reportId : true))
              .map((report, i) => (
                <GoogleSheetsReport
                  key={i}
                  isFromAction={!!reportId}
                  actionType={actionType}
                  report={report}
                  sheetOwner={
                    googleSheetsAccounts.find((account) => account.id === report.sheetsAccount) || {
                      name: 'Unknown - Please Reconnect!',
                      email: 'Unknown',
                    }
                  }
                />
              ))
          ) : (
            <div className="text-[14px] text-center">
              You don't have any syncs set up yet. Click the{' '}
              <span className="font-semibold">+ Create new sync</span> button to create and
              customize a new sync to Sheets!
            </div>
          )}
        </>
      )}
    </section>
  );
};

export const GoogleSheetsReportsList = computeFeatureFlags(
  FeatureFlag.EXPORT_TO_SHEETS_FF,
  GoogleSheetsReports,
  () => (
    <div className="relative top-[10vh]">
      <UpgradePageFallBack
        InAppContextBannerEnabled={false}
        title="Google Sheets"
        description="Download all your data from Triple Whale"
        featureFlag={FeatureFlag.EXPORT_TO_SHEETS_FF}
      />
    </div>
  ),
);

const useReportContextMenu = ({ settingsIsOpen, setSettingsIsOpen, report }) => {
  const dispatch = useAppDispatch();
  const items = [
    {
      content: 'Delete',
      icon: DeleteMinor,
      onAction: () => {
        toast.info(`Deleting report "${report.title}"...`);
        dispatch(deleteGoogleSheetsReport(report));
        setSettingsIsOpen(false);
      },
      accessibilityLabel: 'Delete',
    },
    report.lastErrorMessage && {
      content: 'Get Error Info',
      icon: BugMajor,
      onAction: () => {
        toast.info(getDebugInfo(report));
      },
      accessibilityLabel: 'Get Error Info',
    },
    (report.building || report.lastErrorMessage) && {
      content: 'Re-create',
      icon: RefreshMinor,
      onAction: () => {
        toast.info(`Re-creating report "${report.title}"...`);
        dispatch(createGoogleSheetsReport({ ...report, retry: true }));
        setSettingsIsOpen(false);
      },
      accessibilityLabel: 'Re-create',
    },
  ].filter(Boolean);
  return {
    content: (
      <Popover
        active={settingsIsOpen}
        onClose={() => setSettingsIsOpen(false)}
        activator={
          <MobileVerticalDotsMajor onClick={() => setSettingsIsOpen(true)} className="w-8 h-8" />
        }
      >
        <ActionList items={items as any} />
      </Popover>
    ) as any,
  };
};

export const GoogleSheetsReport = ({ report, sheetOwner, isFromAction, actionType }) => {
  const [settingsIsOpen, setSettingsIsOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  return (
    <Card
      sectioned
      key={report.title}
      title={report.title}
      subdued={true}
      actions={
        actionType === 'delete'
          ? []
          : [useReportContextMenu({ settingsIsOpen, setSettingsIsOpen, report })]
      }
    >
      <hr className="h-px mb-[15px] bg-[#c1c1c1] border-0" />
      <div className="lg:inline-flex justify-between w-full">
        <Store store={report.shopId || report.shopDomain} />
        {report.reportType === 'forecast' ? (
          <ForecastUpdateInfo
            frequency={report.frequency}
            lastUpdated={report.lastUpdated}
            oneTime={report.oneTime}
            numPeriodsAhead={report.numPeriodsAhead}
            numPeriodsBehind={report.numPeriodsBehind}
            groupBy={report.forecastingGroupBy}
          />
        ) : (
          <UpdateInfo
            frequency={report.frequency}
            lastUpdated={report.lastUpdated}
            startDate={report.startDate}
            oneTime={report.oneTime}
          />
        )}
        {report.building ? (
          <Building />
        ) : report.error ? (
          <Error report={report} />
        ) : (
          <SheetInfo
            report={report}
            sheetOwner={sheetOwner}
            isModalOpen={isModalOpen}
            setIsModalOpen={setIsModalOpen}
          />
        )}
      </div>
      {report.reportType === 'forecast' ? (
        <></>
      ) : // <ForecastScenarioInfo scenarioIds={report?.forecastingScenarios} />
      report.reportType === 'customSpends' ? (
        <CustomSpendsInfo report={report} />
      ) : (
        <MetricsInfo id={report.id} metrics={report.metrics} />
      )}
    </Card>
  );
};

const GoogleSheetsReportLoadingError = () => {
  return (
    <div className="lg:flex my-[1rem] p-[1rem] lg:justify-end lg:items-center">
      <div className="mx-[1rem]">
        <Icon source={CircleAlertMajor} color="warning" />
      </div>
      <div className="lg:grow">
        <Text as="h2" variant="headingMd">
          Error loading Google Sheets reports!
        </Text>
        <Text as="p" variant="bodyMd">
          If this keeps happening, please reach out to us at{' '}
          <a href="mailto:support@triplewhale.com">support@triplewhale.com</a> or via chat
        </Text>
      </div>
    </div>
  );
};

const Store = ({ store }) => (
  <div className="flex-[2] mb-[12px] lg:mb-0">
    <div className="opacity-60 mb-[2px]">Store:</div>
    <div>{store}</div>
  </div>
);

const UpdateInfo = ({ frequency, lastUpdated, startDate, oneTime }) => {
  const { shopTimezone } = useSelector((state: RootState) => state);
  return (
    <div className="flex-[2] mb-[12px] lg:mb-0">
      <div className="flex">
        <div className="opacity-60 mb-[2px] mr-[4px]">Auto-update frequency:</div>
        <div>{oneTime ? 'None' : FREQUENCY.find((f) => f.value === frequency)?.label}</div>
      </div>
      {startDate && (
        <div className="flex">
          <div className="opacity-60 mb-[2px] mr-[4px]">Report Start Date:</div>
          <div>{moment(startDate).tz(shopTimezone).format('MMMM D, Y')}</div>
        </div>
      )}
      {lastUpdated && (
        <div className="flex">
          <div className="opacity-60 mb-[2px] mr-[4px]">
            {oneTime ? 'Exported on:' : 'Last Updated:'}
          </div>
          <div>{moment(lastUpdated).tz(shopTimezone).format('MMMM D, Y')}</div>
        </div>
      )}
    </div>
  );
};

const ForecastUpdateInfo = ({
  numPeriodsAhead,
  numPeriodsBehind,
  frequency,
  lastUpdated,
  oneTime,
  groupBy,
}) => {
  const { shopTimezone } = useSelector((state: RootState) => state);
  return (
    <div className="flex-[2] mb-[12px] lg:mb-0">
      <div className="flex">
        <div className="opacity-60 mb-[2px] mr-[4px]">Auto-update frequency:</div>
        <div>{oneTime ? 'None' : FREQUENCY.find((f) => f.value === frequency)?.label}</div>
      </div>
      <div className="flex">
        <div className="opacity-60 mb-[2px] mr-[4px]">Group By:</div>
        <div>{_.startCase(groupBy)}</div>
      </div>
      <div className="flex">
        <div className="opacity-60 mb-[2px] mr-[4px]">Number of Periods Behind:</div>
        <div>{numPeriodsBehind}</div>
      </div>
      <div className="flex">
        <div className="opacity-60 mb-[2px] mr-[4px]">Number of Periods Ahead:</div>
        <div>{numPeriodsAhead}</div>
      </div>
      {/* {startDate && (
        <div className="flex">
          <div className="opacity-60 mb-[2px] mr-[4px]">Report Start Date:</div>
          <div>{moment(startDate).tz(shopTimezone).format('MMMM D, Y')}</div>
        </div>
      )} */}
      {lastUpdated && (
        <div className="flex">
          <div className="opacity-60 mb-[2px] mr-[4px]">
            {oneTime ? 'Exported on:' : 'Last Updated:'}
          </div>
          <div>{moment(lastUpdated).tz(shopTimezone).format('MMMM D, Y')}</div>
        </div>
      )}
    </div>
  );
};

export const Building = ({ title = 'report' }) => {
  const text = `We're building your ${title} and connecting it to Google Sheets. This can take a few minutes.`;
  return (
    <div className="flex-[3] mb-[12px] lg:mb-0 flex items-center justify-center">
      <div className="mr-[1rem]">{text}</div>
    </div>
  );
};

export const Error = ({ report }) => {
  const dispatch = useAppDispatch();

  return (
    <div className="flex-[3] mx-[1rem]">
      <div className="border-red-200 border-solid bg-red-100 rounded-lg py-[0.5rem] px-[1rem] max-w-[350px]">
        <p>There was an error building this report: </p>
        <Anchor
          onClick={() => {
            dispatch(createGoogleSheetsReport(report, { retry: true }));
          }}
        >
          Try again
        </Anchor>
      </div>
    </div>
  );
};

const SheetInfo = ({ report, sheetOwner, isModalOpen, setIsModalOpen }) => (
  <div className="flex-[3] mb-[12px] lg:mb-0">
    <div className="opacity-60 mb-[4px]">Sheet Owner:</div>
    <div>
      {sheetOwner.name || ''}
      {sheetOwner.email ? ` (${sheetOwner.email})` : ''}
    </div>
    <div>
      <a target="_blank" href={report.spreadsheetUrl}>
        Go to Google Sheet
      </a>
    </div>
  </div>
);

// const ForecastScenarioInfo = ({ scenarioIds }) => {
//   const { forecastingScenarios } = useSelector((state: RootState) => state.forecasting);

//   const scenarioTitles = useMemo(() => {
//     const selectedScenarios = forecastingScenarios.filter((s) => scenarioIds?.includes(s?.id));
//     return selectedScenarios.map((s) => s.name).join(', ');
//   }, [forecastingScenarios, scenarioIds]);

//   return (
//     <div className="mt-[10px]">
//       <div className="opacity-60 mb-[2px] metrics">Metrics:</div>
//       <div>
//         <span className="font-bold">Scenarios: </span>
//         <span>{scenarioTitles}</span>
//       </div>
//     </div>
//   );
// };

const CustomSpendsInfo = ({ report }) => {
  const errors = report?.customSpendErrors ?? {};
  return Object.keys(errors)?.length > 0 ? (
    <div className="mt-[10px]">
      <div className="font-bold">
        There are error(s) on your Custom Expenses Google Sheet. Please fix the following error(s):
      </div>
      {Object.keys(errors)?.map((error) => (
        <div>
          Row {error} : {errors[error]}
        </div>
      ))}
    </div>
  ) : null;
};

const MetricsInfo = ({ id, metrics }) => {
  const metricsWrapperRef = useRef(null);
  const [isMetricsExpanded, setIsMetricsExpanded] = useState(false);

  return (
    <div className="mt-[10px]">
      {!isMetricsExpanded && (
        <input
          type="checkbox"
          id={`expand#${id}`}
          className="hidden"
          onChange={() => setIsMetricsExpanded(true)}
        />
      )}
      <div className="opacity-60 mb-[2px] metrics">Metrics:</div>
      <div
        ref={metricsWrapperRef}
        style={
          isMetricsExpanded
            ? {}
            : {
                overflow: 'hidden',
                display: '-webkit-box',
                WebkitLineClamp: 2,
                WebkitBoxOrient: 'vertical',
              }
        }
      >
        <span className="font-bold">Summary: </span>
        <InlineMetricTags tags={metrics} />
      </div>
      {!isMetricsExpanded && (
        <label htmlFor={`expand#${id}`} role="button" className="text-[#1877F2] cursor-pointer">
          see more
        </label>
      )}
    </div>
  );
};

function getDebugInfo(report) {
  const str =
    `Error info for "${report.title}":` +
    `\r\n - Report ID: ${report.id}` +
    `\r\n - Shop ID: ${report.shopId}` +
    `\r\n - Sheets Account: ${report.sheetsAccount}` +
    `\r\n - Error message: ${report.lastErrorMessage}` +
    `\r\n - Error time: ${new Date(report.lastErrorTime?.seconds).toUTCString()}`;
  copyToClipboard(str);
  return 'Error info was copied to clipboard! Please reach out to our CS team and provide them with this info.';
}
