import React from 'react';
import { useSelector } from 'react-redux';
import { Button } from '@shopify/polaris';
import { GlobeMinor, CashDollarMajor, CashEuroMajor, CashPoundMajor } from '@shopify/polaris-icons';
import { convertStatsCurrency, getConversionRate } from 'ducks/actions';
import CURRENCIES from 'constants/currencies';
import { type RootState } from 'reducers/RootType';
import { useAppDispatch } from 'index';
import { Menu } from '@tw/ui-components';

type CurrencyButtonProps = {
  onChange?: (e: string) => void;
  value?: string;
  closeCallback?: () => void;
  activator?: (e: string) => React.ReactNode;
  dropdownPosition?: any;
  dropDownOffset?: number;
  withinPortal?: boolean;
};

const CurrencyButton: React.FC<CurrencyButtonProps> = ({
  onChange,
  value,
  closeCallback,
  activator,
  dropdownPosition,
  dropDownOffset,
  withinPortal = true,
}) => {
  const currency = useSelector((state: RootState) => state.currency) || 'USD';
  const dollarCurrencies = ['USD', 'CAD', 'AUD', 'BRL', 'NZD', 'SGD'];
  const options = Object.keys(CURRENCIES)
    .sort()
    .map((x) => ({ value: x, label: x }));

  const statsCurr = useSelector((state: RootState) => state.statsCurrency) || currency;
  const statsCurrency = value || statsCurr;

  const dispatch = useAppDispatch();
  const handleChange = (e) => {
    if (onChange) {
      onChange(e[0]);
    } else {
      dispatch(convertStatsCurrency(e[0]));
      dispatch(getConversionRate(currency, e[0]));
    }
  };
  return (
    <Menu
      onClose={() => {
        if (closeCallback) {
          closeCallback();
        }
      }}
      withinPortal={withinPortal}
      position={dropdownPosition}
      mah={100}
      offset={dropDownOffset}
    >
      <Menu.Target>
        {activator ? (
          activator(statsCurrency)
        ) : (
          <div className="relative w-fit">
            <Button
              disclosure
              icon={
                dollarCurrencies.includes(statsCurrency)
                  ? CashDollarMajor
                  : statsCurrency === 'EUR'
                    ? CashEuroMajor
                    : statsCurrency === 'GBP'
                      ? CashPoundMajor
                      : GlobeMinor
              }
            >
              {statsCurrency}
            </Button>
          </div>
        )}
      </Menu.Target>
      <Menu.Dropdown className="max-h-[250px] overflow-auto">
        {options.map((option) => (
          <Menu.Item
            key={option.value}
            onClick={() => {
              handleChange([option.value]);
              if (closeCallback) {
                closeCallback();
              }
            }}
            bg={statsCurrency === option.value ? 'named2.4' : undefined}
          >
            {option.label}
          </Menu.Item>
        ))}
      </Menu.Dropdown>
    </Menu>
  );
};

export default CurrencyButton;
