import { Alert, Button, Container, Flex, Icon, Text } from '@tw/ui-components';
import { FC, useMemo } from 'react';
import { WillyPrompt } from '../types/willyTypes';
import { PromptsCategory } from './PromptLibraryPopup';
import { useHistory } from 'react-router';
import { PromptLibraryUpgradeMoby } from './PromptLibraryUpgradeMoby';

type PromptLibrarySideMenuProps = {
  activeSubCategory: string;
  onActiveSubCategoryChange: (category: string) => void;
  promptsCategories: PromptsCategory[];
};

export const PromptLibrarySideMenu: FC<PromptLibrarySideMenuProps> = ({
  activeSubCategory,
  onActiveSubCategoryChange,
  promptsCategories,
}) => {
  const history = useHistory();

  return (
    <Container flex={1} className="flex gap-[32px] flex-col overflow-auto" py="lg" px={0}>
      {promptsCategories.map((category) => (
        <Flex direction="column" gap="xs" w="100%" key={category.name}>
          {category.name.length > 0 && (
            <Text weight={600} size="lg" p={7}>
              {category.name}
            </Text>
          )}
          {category.subCategories.map((subcategory) => (
            <Container
              key={`sub-${subcategory.name}`}
              m={0}
              p={7}
              className="cursor-pointer hover:bg-[--gray-light-mode-300] rounded-md flex items-center gap-3 transition-all"
              onClick={() => {
                onActiveSubCategoryChange(subcategory.name);
              }}
              bg={activeSubCategory === subcategory.name ? 'gray.2' : undefined}
            >
              <Text weight={500} size="sm" color="gray.8">
                {subcategory.name}
              </Text>
              {subcategory.icon && <Icon name={subcategory.icon} size={16} color="gray.8" />}
            </Container>
          ))}
        </Flex>
      ))}
      <PromptLibraryUpgradeMoby />
    </Container>
  );
};
