import { SubscriptionFeature } from '@tw/types';
import {
  BillingInterval,
  SubscriptionPlanType,
  subscriptionItem,
  subscriptionPlan,
} from '@tw/types/module/services/subscription-manager';
import { getPlans, getRevenueIdForShop, getRevenues } from 'ducks/subscription';
import { useAppDispatch } from 'index';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useAppSelector } from 'reducers/RootType';

// "flag" is optional param used for icon
type PlanWithFlag = Partial<subscriptionPlan> & {
  flag?: SubscriptionFeature;
  product_name: string;
  product_description: string;
  price_id: string;
  product_type?: SubscriptionPlanType | null;
  for_sale: boolean;
  product_sort: number;
  billing_interval: BillingInterval | null;
  price: string;
};

const freePlan: PlanWithFlag = {
  price: '0',
  product_name: 'Free',
  product_description: 'Essential eCommerce Board and Analytics for Startups.',
  price_id: 'free',
  product_type: 'package',
  for_sale: true,
  product_sort: 0,
  flag: SubscriptionFeature.PROD_FREE_WILLY,
  billing_interval: 'year',
};

export const useUpgradePackagesAndAddons = () => {
  const dispatch = useAppDispatch();
  const [addons, setAddons] = useState<subscriptionPlan[]>([]);

  const subscription = useAppSelector((state) => state.subscription);
  const plans = useAppSelector<subscriptionPlan[]>((state) => state.plans);
  const revenues = useAppSelector((state) => state.revenues);
  const isFreeShop = useAppSelector((state) => state.isFreeShop);
  const shopRevenueId = useAppSelector((state) => state.shopRevenueTier);
  const { revenue } = useAppSelector((state) => state.currentShop?.annualRevenue || {});

  const billingInterval: BillingInterval = useAppSelector(
    (state) => state.billingIntervalsPerShop[subscription.shop],
  );
  const currentSubbedItems: subscriptionItem[] = useMemo(
    () => (subscription?.items?.length ? subscription.items : []),
    [subscription],
  );
  const uncappedTierId: number | null = useMemo(() => {
    if (!subscription.uncapped_tier) return null;
    const tier = revenues.find((r) => r.description === subscription.uncapped_tier);
    return tier?.id ? tier.id : null;
  }, [subscription, revenues]);

  const filterAddons = useCallback(
    (subscribedPack: subscriptionPlan) => {
      const tempAddons: subscriptionPlan[] = [];

      for (const plan of plans) {
        if (
          plan.product_type === 'addon' &&
          plan.for_sale &&
          plan.billing_interval === billingInterval &&
          !subscribedPack?.features?.includes(plan.product_flag)
        ) {
          if (
            currentSubbedItems.find(
              (item) => item.product_id === plan.product_id && item.price_id === plan.price_id,
            )
          ) {
            tempAddons.push(plan);
          } else if (
            ((uncappedTierId && plan.revenue_id === uncappedTierId) ||
              (!uncappedTierId && plan.revenue_id === subscription?.revenue)) &&
            !currentSubbedItems.find((item) => item.product_id === plan.product_id)
          ) {
            tempAddons.push(plan);
          }
        }
      }

      setAddons(tempAddons);
    },
    [currentSubbedItems, uncappedTierId, subscription, plans],
  );

  const packages = useMemo(() => {
    if (!plans.length || (!subscription?.revenue && !isFreeShop)) return;

    const tempPlans = new Array<PlanWithFlag>();
    for (const plan of plans) {
      if (isFreeShop) {
        if (
          plan.product_type === 'package' &&
          plan.for_sale &&
          plan.billing_interval === 'year' &&
          plan.revenue_id === shopRevenueId
        ) {
          tempPlans.push(plan);
        }
        continue;
      }

      if (
        plan.product_type === 'package' &&
        plan.for_sale &&
        plan.billing_interval === billingInterval
      ) {
        if (
          currentSubbedItems.some(
            (item) => item.product_id === plan.product_id && item.price_id === plan.price_id,
          )
        ) {
          tempPlans.push(plan);
        } else if (
          ((uncappedTierId && plan.revenue_id === uncappedTierId) ||
            (!uncappedTierId && plan.revenue_id === subscription?.revenue)) &&
          !currentSubbedItems.some((item) => item.product_id === plan.product_id)
        ) {
          tempPlans.push(plan);
        }
        if (subscription?.features.includes(plan.product_flag)) {
          filterAddons(plan);
        }
      }
    }

    if (isFreeShop) tempPlans.push(freePlan);

    return tempPlans.sort((a, b) => a.product_sort - b.product_sort);
  }, [
    plans,
    subscription?.revenue,
    subscription?.features,
    isFreeShop,
    billingInterval,
    shopRevenueId,
    currentSubbedItems,
    uncappedTierId,
    freePlan,
  ]);

  useEffect(() => {
    if (plans.length && revenues.length) return;
    dispatch(getPlans());
    dispatch(getRevenues());
  }, [plans, revenues]);

  useEffect(() => {
    dispatch(getRevenueIdForShop(revenue));
  }, [revenue]);

  return { packages, addons };
};
