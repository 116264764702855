import { Tooltip } from '@shopify/polaris';
import { Skeleton } from '@tw/ui-components';
import { useEffect, useMemo, useState } from 'react';
import { CHAT_WITH_DASH_QUESTIONS, EDITOR_QUESTIONS, INPUT_SEARCH_MAX_LENGTH } from './constants';
import { Suggestion, useSuggestions } from './hooks/useSuggestions';
import { ChatSources } from './types/willyTypes';
import { isMobileApp } from 'utils/Device';
import { useIsSmall } from 'hooks/useDefaultWindowSizes';

const MAX_SUGGESTIONS_PER_ROW = 4;

type WillySuggestQuestionsProps = {
  onQuestionClick: (question?: string) => void;
  inputValue: string;
  source: ChatSources;
};

const emptySuggestions: Suggestion[] = Array.apply(null, Array(MAX_SUGGESTIONS_PER_ROW)).map(
  () => ({
    title: 'loading',
    loading: true,
    width: `${Math.floor(Math.random() * (131 - 70)) + 70}px`,
  }),
);

export const WillySuggestQuestions: React.FC<WillySuggestQuestionsProps> = ({
  onQuestionClick,
  inputValue,
  source,
}) => {
  const dashSuggestions = CHAT_WITH_DASH_QUESTIONS.map((q) => ({ title: q, description: q }));
  const editorSuggestions = EDITOR_QUESTIONS.map((q) => ({ title: q, description: q }));
  const questionSuggestions = useSuggestions({ question: inputValue });
  const { suggestions, loadingSuggestions, getRelevantQuestions } = questionSuggestions;
  const isSmall = useIsSmall();

  const [typing, setTyping] = useState(false);

  const suggestionsToShow = useMemo(() => {
    return source === 'dashboard' || source === 'summary' || source === 'pixel'
      ? dashSuggestions
      : source === 'editor'
        ? editorSuggestions
        : (loadingSuggestions ||
          (typing &&
            inputValue.length > 0 &&
            !suggestions.find((v) => v.title === inputValue) &&
            !dashSuggestions.find((v) => v.title === inputValue))
            ? emptySuggestions
            : suggestions.length > 0
              ? suggestions
              : emptySuggestions
          )
            .slice(0, MAX_SUGGESTIONS_PER_ROW)
            .map((suggestion) =>
              suggestion.loading || suggestion.title ? suggestion : { title: suggestion },
            );
  }, [
    source,
    dashSuggestions,
    editorSuggestions,
    loadingSuggestions,
    typing,
    inputValue,
    suggestions,
  ]);

  useEffect(() => {
    const timer = setTimeout(() => {
      setTyping(false);
    }, 500);

    return () => {
      inputValue.length < INPUT_SEARCH_MAX_LENGTH && setTyping(true);
      clearTimeout(timer);
    };
  }, [inputValue]);

  useEffect(() => {
    if (source === 'chat' && inputValue.length > 0 && typing && !loadingSuggestions) {
      const timer = setTimeout(() => {
        getRelevantQuestions(inputValue);
      }, 300);

      return () => {
        clearTimeout(timer);
      };
    }
  }, [getRelevantQuestions, inputValue, loadingSuggestions, dashSuggestions, typing, source]);

  return (
    <div className={`${source === 'chat' ? '' : 'pb-6.5'} relative`}>
      {/* <div
        className="absolute top-0 -right-8 cursor-pointer"
        onClick={() => {
          fetchSuggestions();
        }}
      >
        <RefreshMajor className="w-6" />
      </div> */}
      {isSmall || source != 'chat' ? (
        <div className="flex gap-6 -mx-6 px-6 sm:m-0 sm:p-0 overflow-y-scroll no-scrollbar pb-[15px]">
          {suggestionsToShow?.map((suggestion, i) => {
            return (
              <WillySuggestQuestion
                key={i}
                suggestion={suggestion}
                onQuestionClick={onQuestionClick}
              />
            );
          })}
        </div>
      ) : (
        <div className="flex @3xl:grid grid-cols-1 @3xl:grid-cols-2 gap-4">
          {suggestionsToShow?.map((suggestion, i) => {
            return (
              <WillySuggestQuestion
                key={i}
                suggestion={suggestion}
                onQuestionClick={onQuestionClick}
              />
            );
          })}
        </div>
      )}
    </div>
  );
};

type WillySuggestQuestionProps = {
  suggestion: Suggestion;
  onQuestionClick?: (question?: string) => void;
};

const WillySuggestQuestion: React.FC<WillySuggestQuestionProps> = ({
  suggestion,
  onQuestionClick,
}) => {
  const isSmall = useIsSmall();

  return (
    <div
      onClick={() => onQuestionClick?.(suggestion?.title)}
      role="button"
      className={`${
        isSmall ? 'w-[80vw min-w-[80vw] ' : ''
      } willy-suggest-question p-4 flex flex-col overflow-hidden cursor-pointer animateFadeToRight rounded-lg border border-solid border-[#D1D4DB] hover:bg-[#E5E7EB] transition-colors`}
    >
      {suggestion.loading && (
        <div className="flex flex-col gap-4">
          <Skeleton animate height="17px" width={suggestion.width} />
          <Skeleton animate height="16px" />
        </div>
      )}
      {!suggestion.loading ? (
        <Tooltip content={suggestion.description ?? suggestion.title} preferredPosition="above">
          <div className="line-clamp-2">{suggestion.title}</div>
        </Tooltip>
      ) : null}
    </div>
  );
};
