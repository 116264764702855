import { useEffect } from 'react';
import { updateCurrentShopId } from 'ducks/auth';
import { lighthouseOpen } from 'ducks/lighthouse';
import { useAppDispatch } from 'index';
import { useLocation } from 'react-router';

export const useShopDomainSync = (): void => {
  const location = useLocation();
  const dispatch = useAppDispatch();

  useEffect(() => {
    const search = new URLSearchParams(location.search);
    const shopDomain = search.get('shop-domain') || search.get('shop-id') || '';
    const defaultLighthouseOpen = search.get('lighthouse');
    if (!shopDomain.length) return;

    dispatch(updateCurrentShopId(shopDomain));
    if (!defaultLighthouseOpen) return;

    dispatch(lighthouseOpen(shopDomain));
  }, [dispatch, location.search]);
};
