import db, { batch, toArray } from '../utils/DB';
import { toast } from 'react-toastify';
import axiosInstance from '../utils/axiosInstance';
import { RECEIVE_SHOPIFY_SEGMENTS, getShopifySegments } from './shopify/newShopify';
import { ShopifySegmentType } from 'types/shopify';

const DELETE_PAYMENT_GATEWAY_COST = 'DELETE_PAYMENT_GATEWAY_COST';
const deletePaymentGatewayCost = (id) => ({
  type: DELETE_PAYMENT_GATEWAY_COST,
  id,
});

const UPDATE_PAYMENT_GATEWAY_COST = 'UPDATE_PAYMENT_GATEWAY_COST';
export const updatePaymentGatewayCost = (data) => ({
  type: UPDATE_PAYMENT_GATEWAY_COST,
  data,
});

export const paymentGatewayOnDelete = (cost) => {
  return (dispatch, getState) => {
    const { currentShopId } = getState();
    const url = `/v2/shopify/delete-segment`;
    axiosInstance.post(url, {
      attributes: {
        shopId: currentShopId,
        dataType: 'payment_gateway_costs',
      },
      data: {
        id: cost,
      },
    });
    toast.success(`${cost} successfully deleted`);
    dispatch(deletePaymentGatewayCost(cost));
  };
};

export const paymentGatewayOnSave = (cost = 0, fee = 0.3, id) => {
  return async (dispatch, getState) => {
    const { currentShopId } = getState();
    const url = `/v2/shopify/update-segment`;
    const data = {
      id,
      fee,
      cost,
    };
    await axiosInstance.post(url, {
      attributes: {
        shopId: currentShopId,
        dataType: 'payment_gateway_costs',
      },
      data,
    });
    dispatch(updatePaymentGatewayCost(data));
    toast.success(`${id} successfully updated`);
  };
};

export const transformGatewayCosts = (gatewayCosts) => {
  if (!gatewayCosts.length) return [];
  return gatewayCosts.map((cost) => {
    return {
      id: cost.id,
      name: cost?.name ?? cost.id,
      cost: cost?.costs?.[0]?.value ?? 0,
      fee: cost?.fees?.[0]?.value ?? 0.3,
      costs: cost?.costs ?? [],
      fees: cost?.fees ?? [],
    };
  });
};

const paymentGatewayCosts = (state: any[] = [], action) => {
  let newState;
  switch (action.type) {
    case RECEIVE_SHOPIFY_SEGMENTS:
      return transformGatewayCosts(
        action.shopifySegments?.[ShopifySegmentType.PAYMENT_GATEWAY_COSTS] ?? [],
      );
    case UPDATE_PAYMENT_GATEWAY_COST:
      newState = [...state];
      let cost = newState.findIndex((pgc) => pgc.id === action.data.id);
      newState[cost] = { ...newState[cost], ...action.data };
      return newState;
    case DELETE_PAYMENT_GATEWAY_COST:
      newState = state.filter((cost) => cost.id !== action.id);
      return newState;
    default:
      return state;
  }
};

export const reducers = {
  paymentGatewayCosts,
};
