import { GET_STARTED_SECTION, WILLY_BUILD_SECTION, WILLY_SECTION } from './willy';
import { NavSection } from '../types';
import { APPS_SECTION } from './apps';
import { $derived } from '@tw/snipestate';
import { $hasSequenceAccess } from '$stores/willy/$sequences';
import { $msp } from '$stores/$shop';

const DATA_SECTION: NavSection = {
  title: 'Data',
  routes: [WILLY_SECTION, WILLY_BUILD_SECTION].flatMap((x) => x.routes),
};

export const $moreSection = $derived((get) => {
  // workflows route depends on sequences access,
  // so we need this config to be reactive towarsds that
  // to make sure user has access to that route.
  get($hasSequenceAccess);
  get($msp);

  return [DATA_SECTION, APPS_SECTION, GET_STARTED_SECTION]
    .filter((x) => !x.hidden)
    .map(({ spread, routes, ...x }) => ({
      ...x,
      routes: routes.filter((x) => !x.hidden),
    }));
});

export const $moreRoutes = $derived((get) => {
  return new Set(get($moreSection).flatMap((s) => s.routes.map((r) => r.url)));
});
