import React, { useCallback } from 'react';
import { Droppable, Draggable } from 'react-beautiful-dnd';
import { ItemCallback, MetricItem } from './MetricItem';

// This method is needed for rendering clones of draggables
const getRenderItem = (props) => (provided, snapshot, rubric) => {
  const item = props.items[rubric.source.index];
  return (
    <div {...provided.draggableProps} {...provided.dragHandleProps} ref={provided.innerRef}>
      <MetricItem
        removeExpressionElement={props.removeExpressionElement}
        updateMetricItem={props.updateMetricItem}
        updateIntegerItem={props.updateIntegerItem}
        isGlobal={props.isGlobal}
        {...item}
      ></MetricItem>
    </div>
  );
};

type ExpressionOptionsProps = {
  items: any[];
  addMetricItem: (item: any) => void;
  className?: string;
};

export const ExpressionOptions: React.FC<ExpressionOptionsProps & ItemCallback> = (props) => {
  return (
    <Droppable
      renderClone={getRenderItem(props)}
      droppableId="ExpressionOptions"
      isDropDisabled={true}
    >
      {(provided, snapshot) => (
        <div ref={provided.innerRef} className="flex flex-wrap p-[8px]">
          {props.items.map((item, index) => {
            const shouldRenderClone = item.id === snapshot.draggingFromThisWith;
            return (
              <div key={item.id}>
                {shouldRenderClone ? (
                  <div>
                    {' '}
                    <MetricItem {...item}></MetricItem>
                  </div>
                ) : (
                  <Draggable draggableId={item.id} index={index}>
                    {(provided, snapshot) => (
                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                      >
                        <MetricItem
                          {...item}
                          isGlobal={props.isGlobal}
                          removeExpressionElement={props.removeExpressionElement}
                          updateMetricItem={props.updateMetricItem}
                          updateIntegerItem={props.updateIntegerItem}
                          onClick={() => props.addMetricItem(item)}
                        ></MetricItem>
                      </div>
                    )}
                  </Draggable>
                )}
              </div>
            );
          })}
          {provided.placeholder}
        </div>
      )}
    </Droppable>
  );
};
