import CURRENCIES from 'constants/currencies';
import { loadCustomMetrics } from 'ducks/customMetrics';
import { getGoogleAdsCustomers } from 'ducks/googleAds';
import { getNewStatsForAllServices, getShopifyNewStats } from 'ducks/newStats';
import { PINTEREST_FETCHING_ACCOUNTS, PINTEREST_RECEIVED_ACCOUNTS } from 'ducks/pinterest';
import { TWITTER_FETCHING_ACCOUNTS, TWITTER_RECEIVED_ACCOUNTS } from 'ducks/twitter';
import { getPixelInstallStatus, getUtmReport } from 'ducks/pixel';
import { setCDPSegmentsFromServer } from 'ducks/cdp/segments';
import { getOnboarding } from 'ducks/shop';
import { REGROUPING_STATS_END } from 'ducks/stats';
import { checkingForImporting, finishedCheckForImporting } from 'ducks/tiles';
import moment from '@tw/moment-cached/module/timezone';
import { Dispatch } from 'redux';
import {
  ActivePage,
  BackgroundDiffusionPopupData,
  GenerativeAiPopupData,
  ShopBillingIntervalMap,
  ShopPrompt,
  SummaryPopupMetadata,
} from 'types/general';
import axiosInstance from 'utils/axiosInstance';
import { fetchCurrenciesRate } from 'utils/currencyConvertion';

import { groupStats } from '@tw/stats/module/groupStats';
import { AbstractChart } from '@tw/types';

import { isLocalhost } from '../../config';
import Api from '../../utils/Api';
import CSVExport from '../../utils/CSVExport';
import db, { FieldValue, stats, toArray, userDb } from '../../utils/DB';
import { loadCustomSections } from '../customSections';
import {
  FACEBOOK_ADS_ACCOUNTS_NOT_SELECTED,
  FACEBOOK_FETCHING_ADS_ACCOUNTS,
  facebookError,
} from '../facebook';
import { calculateInventory } from '../inventory';
import { statsLtvReceived } from '../ltv';
import { getAllSlackChannels, getSlackChannel } from '../slack';
import { SNAPCHAT_FETCHING_ORGANIZATIONS, SNAPCHAT_RECEIVED_ORGANIZATIONS } from '../snapchat';
import { tiktokAccountsAreNotSelected } from '../tiktok';
import { type RootState } from 'reducers/RootType';
import { ServicesIds, servicesRoles } from '@tw/types/module/services';
import _db from '../../utils/DB';
import { loadShopServices } from 'ducks/shopServices';
import allServices from 'constants/services';
import { getShopifySegments } from '../shopify/newShopify';
import { BuiltAdType } from 'constants/creativeCockpit';
import { type AppDispatch } from 'index';
import { genericEventLogger, analyticsEvents, aiActions } from 'utils/dataLayer';
import {
  serviceAds,
  SET_OPTIMISTIC_IMPORTING_FOR_SERVICE,
  ADD_NEW_CUSTOM_SPEND_CATEGORY,
  IMPORTING_DETECTED,
  IMPORTING_NEW_INFRA_DETECTED,
  SERVICE_FINISHED_IMPORTING,
  SET_EARLIEST_DATE,
  INIT_UI,
  INIT_SHOP,
  UPDATE_SHOP,
  GOT_PAYMENTS_DETAILS,
  AUDIT_SEGMENT_INIT,
  RECEIVED_PREVIOUS_PERIOD_STATS,
  RECEIVED_STATS_COMPARISONS,
  LOADING_STATS_COMPARISONS,
  DATES_TO_COMPARE,
  RECEIVE_CUSTOM_SPENDS,
  UPDATE_CUSTOM_EXPENSE_CATEGORIES,
  CUSTOM_EXPENSE_CALCULATE_COGS,
  LOADING_ORDERS,
  LOADING_STATS,
  LOADING_STATS_LTV,
  LOADING_REFUNDS,
  LOADING_CUSTOM_SPENDS,
  LIGHTBOX_OPEN,
  LIGHTBOX_CLOSE,
  TRIGGER_RELOAD_CONTENT_HUB_TAB,
  FINISHED_RELOAD_CONTENT_HUB_TAB,
  STATS_RECEIVED,
  RECEIVE_ORDERS,
  RECEIVE_REFUNDS,
  INITIALIZING,
  TOGGLE_TOPBAR_USER_MENU,
  MAIN_DATE_PICKER_MONTH_CHANGE,
  COMPARE_DATE_PICKER_MONTH_CHANGE,
  NEW_CUSTOM_SPEND_START_DATE_PICKER_MONTH_CHANGE,
  NEW_CUSTOM_SPEND_END_DATE_PICKER_MONTH_CHANGE,
  MAIN_DATE_PICKER_SELECTION_CHANGE,
  MAIN_DATE_PICKER_SPECIAL_PERIOD_CHANGE,
  MAIN_DATE_PICKER_SELECTED_OPTION,
  COMPARE_DATE_PICKER_SELECTED_OPTION,
  DURATION_FILTERS_VALUE_CHANGED,
  CUSTOM_SELECTED_COMPARE_OPTION,
  RESET_SELECTED_COMPARE_OPTION,
  NEW_CUSTOM_SPEND_START_DATE_ON_CHANGE,
  COGS_UPLOAD_DIALOG_CANCEL_ON_PRESS,
  PRODUCTS_TABLE_PRODUCT_ON_CHANGE_PRODUCT_COST,
  IMPORT_PRODUCTS_TABLE_ON_PRESS,
  TOPBAR_MOBILE_NAVIGATION_TOGGLE,
  TOPBAR_MOBILE_NAVIGATION_CLOSE,
  NEW_CUSTOM_SPEND_WINDOW_TOGGLE,
  NEW_METRIC_BASED_CUSTOM_SPEND_WINDOW_TOGGLE,
  NEW_PAYMENT_GATEWAY_NAME_ON_CHANGE,
  NEW_CUSTOM_SPEND_TITLE_ON_CHANGE,
  NEW_PAYMENT_GATEWAY_COST_ON_CHANGE,
  NEW_CUSTOM_SPEND_COST_ON_CHANGE,
  NEW_CUSTOM_SPEND_METRIC_ON_SELECT,
  NEW_CUSTOM_SPEND_PERCENT_ON_CHANGE,
  NEW_CUSTOM_SPEND_RECURRING_ON_CHANGE,
  CUSTOM_SPEND_ADSPEND_ON_CHANGE,
  CUSTOM_SPEND_SAVE,
  EDIT_CUSTOM_SPEND,
  SUMMARY_IS_EXPANDED_TOGGLE,
  SUMMARY_CHART_POPUP_IS_OPEN_TOGGLE,
  SUMMARY_BOX_ON_PRESS,
  NEW_CUSTOM_SPEND_START_DATE_WINDOW_TOGGLE,
  NEW_CUSTOM_SPEND_END_DATE_WINDOW_TOGGLE,
  CUSTOM_SPEND_START_DATE_WINDOW_TOGGLE,
  SUMMARY_ON_REFRESH,
  CUSTOM_SPEND_ON_DELETE,
  SET_CUSTOM_SPENDS_FLAG,
  HIDE_CUSTOM_SPEND_BANNER,
  CUSTOM_SPENDS_UPLOAD_DIALOG_CANCEL_ON_PRESS,
  CUSTOM_SPENDS_EXPORT_CSV,
  EXPORT_CUSTOM_EXPENSES,
  IMPORT_CUSTOM_SPENDS_TABLE_ON_PRESS,
  MOBILE_NOTIFICATIONS_PERMISSION_GIVEN,
  SHOW_PROMPT,
  HIDE_PROMPT,
  CONVERT_STATS_CURRENCY,
  LOADING_CONVERSION_RATE,
  SET_CONVERSION_RATE,
  SHOW_GENERAL_ERROR_TOAST,
  HIDE_GENERAL_ERROR_TOAST,
  SET_BIDIRECTIONAL_COGS,
  REMOVE_ALERT,
  SAVE_SHOP_INDUSTRY,
  UPDATE_CUSTOM_EXPENSE_ATTRIBUTION_TYPE,
  GENERATIVE_AI_POPUP_OPENED,
  GENERATIVE_AI_POPUP_CLOSED,
  GENERATIVE_AI_CHANGE_ENTITY,
  BACKGROUND_DIFFUSION_POPUP_OPENED,
  BACKGROUND_DIFFUSION_POPUP_CLOSED,
  AD_PREVIEW_POPUP_OPENED,
  AD_PREVIEW_POPUP_CLOSED,
  GENERATIVE_LOADING_AD_INFO,
  GENERATIVE_LOADING_AD_INFO_FINISHED,
  GENERATIVE_LOADING_PRODUCT_INFO,
  GENERATIVE_LOADING_PRODUCT_INFO_FINISHED,
  GENERATE_COPY_AI_POPUP_OPENED,
  GENERATE_COPY_AI_POPUP_CLOSED,
  GENERATE_COPY_CHANGE_ENTITY,
  GENERATE_COPY_LOADING_AD_INFO,
  GENERATE_COPY_LOADING_AD_INFO_FINISHED,
  SET_GENERATED_TEXT,
  UNSET_GENERATED_TEXT,
  CHANGE_CONTENT_HUB_SEARCH_TERM,
  OPEN_UPLOAD_MEDIA,
  CLOSE_UPLOAD_MEDIA,
  CHANGE_CONTENT_HUB_FOLDER,
  SELECTED_STATS_SERVCIES_CHANGED,
  IS_NAVIGATION_OPEN,
  CHANGE_AD_BUILDER_SOURCE,
  TOGGLE_BULK_ADBUILDER,
  ADD_BRAND_COLOR,
  REMOVE_BRAND_COLOR,
  ADD_BRAND_FONT,
  REMOVE_BRAND_FONT,
  INIT_PROMPTS,
  ADD_PROMPT,
  REMOVE_PROMPT,
  PIN_PROMPT,
  UNPIN_PROMPT,
  CHANGE_PROMPT_ORDER,
  INIT_MULTIPLE_SHOPS_BILLING_INTERVAL,
  SET_SHOP_BILLING_INTERVAL,
  COPY_TO_CLIPBOARD_ACTIVE,
  COPY_TO_CLIPBOARD_INACTIVE,
  SEGMENT_INIT,
  NEW_CUSTOM_SPEND_END_DATE_ON_CHANGE,
  CHANGE_ACTIVE_PAGE,
  CLEAR_INITIAL_DATA,
  GOOGLE_FETCHING_ACCOUNTS,
  PPS_STATUS,
  ONLINE,
  OFFLINE,
  HIDE_OFFLINE_BANNER,
  IMPORT_CUSTOM_SPENDS_GS_MODAL_TOGGLE,
  SHIPPING_IMPORT_BY_ORDER_ON_CLOSE,
  SHIPPING_IMPORT_BY_ORDER_ON_PRESS,
  SET_USE_SHIPPING_CHARGES,
  SET_ACTIVE_APP,
} from 'ducks/constants';
import { loadInsightsFilters } from 'ducks/insights/filterReducers';
import { fetchWillySummary } from 'ducks/willy';
import { toast } from 'react-toastify';
import { getProviders } from '../sensory';
import { isInIframe } from 'config';
import { PreviousPeriodIds } from '@tw/types/module/datePicker/datePicker';
import { BillingInterval, TASK_STATUS } from '@tw/types/module/services/subscription-manager';
import { callToIntegrationsUpdateToday } from 'ducks/shopIntegrations';
import { $activeAppVersion } from '$stores/nav-config-stores';
import { loadHsCompany } from 'ducks/hubSpot';
import { $effect } from '@tw/snipestate';
import { $useClickHouseInSummary, $useSummaryWillyWay } from '../../$stores/$shop';

const REFRESH_TIME_INTERVAL = 15;

export function startStore(remoteData) {
  return async (dispatch, getState: () => RootState) => {
    const { currentShopId, activePage, sensory } = getState();
    const { msp } = remoteData;
    const hasAccess = window.twFeatures ? window.twFeatures.features?.length > 0 : true;
    dispatch(initShop({ ...remoteData, shopId: currentShopId, id: currentShopId, activePage }));
    if (hasAccess) {
      dispatch(getProviders(false));
    }

    $effect(async (unsubscribe, get) => {
      const version = get($activeAppVersion);
      // if (version === '3.0') return;

      // right away so we only load 2.0 stuff once
      unsubscribe();

      // probably from here it's unnecessary to load
      if (version !== '3.0') dispatch(getOnboarding(currentShopId)); // need it for optimization page not it 3.0 - Chezi
      dispatch(getUtmReport()); // where we are using it? and move it to there - Chasya
      dispatch(setCDPSegmentsFromServer()); //why it's here and on the insight pages -  Chaya Stren

      if (hasAccess) {
        dispatch(initSegments()); // global ads segment - need it
        // if (!document.location.pathname.includes('summary')) {
        //   dispatch(getStatsLtv());
        //   if (msp === 'shopify') {
        //     dispatch(getShopifyNewStats(true));
        //   }
        //   dispatch(fetchWillySummary(currentShopId, 'tile')); // need it? - Chezi
        //   dispatch(fetchWillySummary(currentShopId, 'widget')); // need it? - Chezi
        //   // dispatch(getShopifySegmentsStats(true));
        //   dispatch(getNewStatsForAllServices());
        //   // dispatch(getSensoryIntegrations());
        // }

        if (msp === 'shopify' || msp === 'bigcommerce') {
          dispatch(getShopifySegments());
        }

        dispatch(getCustomSpends()); // need it
        dispatch(loadCustomMetrics()); // need it
        dispatch(calculateInventory()); // need it
        dispatch(loadCustomSections()); // need it summary page

        dispatch(getSlackChannel(remoteData)); // need it for several places

        dispatch(checkServicesConnection(remoteData)); // need it for 3.0? in general do we need it to jump need UI UX here - Chezi
        dispatch(watchForImporting()); // no need old importing way - Fay verify
        dispatch(getPixelInstallStatus()); // need it
        dispatch(loadHsCompany(currentShopId)); //get hubspot data and send to intercom
        dispatch(checkPpsStatus()); // need it here? Chaya S
        // If we find a broken place as a result of this change
        // We can uncomment it back

        // COGS IS BROKEN @ivan! I uncomment it back
        // Moving it to cogs page @chezi
        //dispatch(loadProducts());

        dispatch(loadShopServices()); // need it - Noga?
        //dead code - removed
        if (window.Intercom) {
          window.Intercom('update', {
            app_id: 'ogniecxz',
            Paying: hasAccess,
            hide_default_launcher: isInIframe ? true : false,
          });
        }

        if (isLocalhost) {
          return;
        }
        const { lastUpdateToday } = (await db().get()).data()!;
        if (
          lastUpdateToday &&
          moment().utc().diff(lastUpdateToday, 'minutes') < REFRESH_TIME_INTERVAL
        ) {
          return;
        }
        dispatch(callToIntegrationsUpdateToday());

        // for now, don't reimport today data for pinterest and snapchat
        // dispatch(callToUpdateTodayPinterest());
        // dispatch(callToUpdateTodaySnapchat());

        await Promise.all([
          checkShopServicesConnection(currentShopId),
          db().set({ lastUpdateToday: moment().utc().format() }, { merge: true }),
        ]);
      }
    });
  };
}

const checkServicesConnection = (remoteData) => {
  return async (dispatch, getState) => {
    const { facebookAccessToken, facebookAccounts } = remoteData;
    var accounts;
    if (facebookAccessToken && (!facebookAccounts || !facebookAccounts.length)) {
      dispatch({ type: FACEBOOK_FETCHING_ADS_ACCOUNTS });
      try {
        const { currentShopId } = getState();
        accounts = (
          await axiosInstance.get(`v2/facebook-ads/fetch/accounts?shopDomain=${currentShopId}`)
        ).data?.result;
      } catch (e) {
        console.error('Error fetchFacebookAccounts', e);
        dispatch(
          showErrorToast(
            'We could not fetch a Facebook Ads Accounts list. Please contact our support team',
          ),
        );
      }
      dispatch(facebookError({ code: FACEBOOK_ADS_ACCOUNTS_NOT_SELECTED, accounts }));
    }
    const { snapchatAccessToken, snapchatAccounts } = remoteData;
    if (snapchatAccessToken && (!snapchatAccounts || !snapchatAccounts.length)) {
      dispatch({ type: SNAPCHAT_FETCHING_ORGANIZATIONS });
      let organizations;
      try {
        const { currentShopId } = getState();
        organizations = (
          await axiosInstance.get(`v2/snapchat-ads/fetch/accounts?shopDomain=${currentShopId}`)
        ).data?.result;
      } catch (e) {
        console.error('Error fetchSnapchatAccounts', e);
        dispatch(
          showErrorToast(
            'We could not fetch a Snapchat Ads Accounts list. Please contact our support team',
          ),
        );
      }
      dispatch({ type: SNAPCHAT_RECEIVED_ORGANIZATIONS, organizations });
    }
    const { tiktokAccessToken, tiktokAccounts } = remoteData;
    if (tiktokAccessToken && (!tiktokAccounts || !tiktokAccounts.length)) {
      dispatch(tiktokAccountsAreNotSelected(tiktokAccessToken));
    }

    const { pinterestToken, pinterestAccounts } = remoteData;
    if (pinterestToken && (!pinterestAccounts || !pinterestAccounts.length)) {
      dispatch({ type: PINTEREST_FETCHING_ACCOUNTS });
      try {
        const { currentShopId } = getState();
        accounts = (
          await axiosInstance.get(`v2/pinterest-ads/fetch/accounts?shopDomain=${currentShopId}`)
        ).data?.result;
      } catch (e) {
        console.error('Error fetchPinterestAccounts', e);
        dispatch(
          showErrorToast(
            'We could not fetch a Pinterest Ads Accounts list. Please contact our support team',
          ),
        );
      }
      dispatch({ type: PINTEREST_RECEIVED_ACCOUNTS, accounts });
    }

    const { twitterToken, twitterAccounts } = remoteData;
    if (twitterToken && (!twitterAccounts || !Object.keys(twitterAccounts).length)) {
      dispatch({ type: TWITTER_FETCHING_ACCOUNTS });
      try {
        const { currentShopId } = getState();
        const { data: accounts } = await axiosInstance.get(
          `v2/twitter-ads/fetch/accounts?shopDomain=${currentShopId}`,
        );

        dispatch({ type: TWITTER_RECEIVED_ACCOUNTS, accounts });
      } catch (e) {
        console.error('Error fetchTwitterAccounts', e);
        dispatch(
          showErrorToast(
            'We could not fetch a Twitter Ads Accounts list. Please contact our support team',
          ),
        );
      }
    }

    const { googleAdsAccessToken, googleAdsAccounts } = remoteData;
    if (googleAdsAccessToken && (!googleAdsAccounts || !Object.keys(googleAdsAccounts).length)) {
      dispatch(getGoogleAdsCustomers(remoteData.googleAdsAccessToken));
    }
  };
};

const watchForImporting = () => {
  return async (dispatch, getState) => {
    const { importingServices } = getState();
    dispatch(checkingForImporting());
    db()
      .collection('importing')
      .onSnapshot((servicesSnapshot) => {
        var services = {};
        servicesSnapshot.forEach((serviceDoc) => {
          services[serviceDoc.id] = serviceDoc.data();
        });

        // ORDER IS IMPORTANT:

        // FIRST DETECTING JUST FINISHED SERVICES
        Object.keys(importingServices).forEach((key) => {
          if (!services[key]) {
            dispatch(serviceJustFinishedImporting(key));
          }
        });

        // NOW DISPATCHING CHANGE
        dispatch(importingDetected(services));
        dispatch(finishedCheckForImporting());
      });
  };
};

export const setOptimisticImporting = (serviceId) => ({
  type: SET_OPTIMISTIC_IMPORTING_FOR_SERVICE,
  serviceId,
});

export const importingDetected = (services) => ({
  type: IMPORTING_DETECTED,
  services,
});

export const importingNewInfraDetected = (services) => ({
  type: IMPORTING_NEW_INFRA_DETECTED,
  services,
});

export const serviceJustFinishedImporting = (id) => ({
  type: SERVICE_FINISHED_IMPORTING,
  id,
});

export const getLiveData = (isSilent = false) => {
  return (dispatch, getState) => {
    var { mainDatePickerSelectionRange } = getState();
    if (!mainDatePickerSelectionRange) return;
    var { start } = mainDatePickerSelectionRange;
    dispatch(mainDatePickerOnSelectionChange({ start, end: moment().endOf('day') }, isSilent));
  };
};

export const setEarliestDate = (earliestDate) => {
  return (dispatch) => {
    dispatch({
      type: SET_EARLIEST_DATE,
      earliestDate,
    });
  };
};

export const initUI = (remoteData) => ({
  type: INIT_UI,
  ...remoteData,
});

export const initShop = (remoteData) => ({
  type: INIT_SHOP,
  ...remoteData,
});

export const updateShop = (remoteData) => ({
  type: UPDATE_SHOP,
  ...remoteData,
});

export const gotPaymentsDetails = (details) => ({
  type: GOT_PAYMENTS_DETAILS,
  details,
});

export const initSegments = () => async (dispatch) => {
  const adSegmentsArray = await Promise.all(
    Object.keys(allServices).map(async (service) => {
      const segmentsServices = (
        await _db().collection('ads-segments').doc(service).collection('segments').get()
      ).docs;
      return Object.fromEntries(segmentsServices.map((doc) => [doc.id, doc.data()]));
    }),
  );
  const creativesSegmentsArray = await Promise.all(
    Object.values(servicesRoles).map(async (service) => {
      const segmentsServices = (
        await _db().collection('creative_cockpit').doc(service).collection('segments').get()
      ).docs;
      return Object.fromEntries(segmentsServices.map((doc) => [doc.id, doc.data()]));
    }),
  );
  const creativesFiltersArray = await Promise.all(
    Object.values(servicesRoles).map(async (service) => {
      const filtersServices = (
        await _db().collection('creative_cockpit').doc(service).collection('filters').get()
      ).docs;
      return Object.fromEntries(filtersServices.map((doc) => [doc.id, doc.data()]));
    }),
  );
  const ads_segments = Object.fromEntries(
    adSegmentsArray.map((k, i) => [Object.keys(allServices)[i], k]),
  );
  const creative_segments = Object.fromEntries(
    creativesSegmentsArray.map((k, i) => [Object.values(servicesRoles)[i], k]),
  );
  const creative_filters = Object.fromEntries(
    creativesFiltersArray.map((k, i) => [Object.values(servicesRoles)[i], k]),
  );
  const data = { ads_segments, creative_segments, creative_filters };
  dispatch({ type: SEGMENT_INIT, ...data });
};

export const getCustomSpends = () => {
  return (dispatch, getState) => {
    const { newStats } = getState();
    dispatch(loadingCustomSpends());
    return db()
      .collection('custom_spends')
      .get()
      .then((data) => {
        dispatch(receiveCustomSpends(toArray(data)));
      });
  };
};

var getStatsSamePeriodComparisonKey = null;

export const getStatsSamePeriodComparison = (activeOrderSegment) => {
  return async (dispatch, getState: () => RootState) => {
    dispatch({ type: LOADING_STATS_COMPARISONS });

    const {
      mainDatePickerSelectionRange,
      groupStatsBy,
      mainDatePickerSpecialPeriod,
      datesToCompare,
    } = getState();
    if (!mainDatePickerSelectionRange) return;
    const { start, end } = mainDatePickerSelectionRange;
    const period1 = { start: start.format(), end: end.format() };
    const periods = [period1];
    getStatsSamePeriodComparisonKey = (start.format() + end.format()) as any;

    let daysDiff = end.diff(start, 'days');
    daysDiff += 1;

    const now = moment();
    const isToday = now.diff(start, 'days') === 0;
    // const todayHour = isToday
    //   ? now.subtract('hour').hour() === 0
    //     ? -1
    //     : now.subtract(1, 'hour').hour()
    //   : null;
    const todayHour = isToday ? now.hour() : null;
    let start2: moment.Moment;
    let end2: moment.Moment;

    if (datesToCompare?.id === 'none') {
      dispatch({
        type: RECEIVED_STATS_COMPARISONS,
        comparisons: [{}],
      });
      dispatch({
        type: RECEIVED_PREVIOUS_PERIOD_STATS,
        previousPeriodRawStats: {},
        start: start.format(),
        end: end.format(),
      });
      return;
    }

    let period2: { start: string; end: string };

    if (datesToCompare?.id !== 'default') {
      const start = datesToCompare.start;
      const end = datesToCompare.end;
      start2 = moment(start);
      end2 = moment(end);
      period2 = { start, end };
    } else {
      if (mainDatePickerSpecialPeriod) {
        start2 = moment(start).subtract(1, mainDatePickerSpecialPeriod);
        end2 = moment(end).subtract(1, mainDatePickerSpecialPeriod);
      } else {
        start2 = moment(start).subtract(daysDiff, 'days');
        end2 = moment(end).subtract(daysDiff, 'days');
      }
      period2 = { start: start2.format(), end: end2.format() };
    }
    periods.push(period2);

    try {
      const { currentShopId } = getState();
      const url = `/v2/summary-page/compare-stats`;
      const { data } = await axiosInstance.post(url, {
        periods,
        todayHour,
        todayHourSubtractForPrevPeriod: true,
        shopDomain: currentShopId,
        key: getStatsSamePeriodComparisonKey,
        includeCalculatedStats: true,
        includeRawStats: true,
        includeCharts: true,
        groupStatsBy,
        activeOrderSegment,
        useClickhouse: $useClickHouseInSummary.get(),
        willyWay: $useSummaryWillyWay.get(),
      });
      const { comparisons, key, previousPeriodRawStats, calculatedStats, charts } = data;
      const { mainDatePickerSelectionRange, ...rest } = previousPeriodRawStats;
      if (key === getStatsSamePeriodComparisonKey) {
        dispatch({
          type: RECEIVED_STATS_COMPARISONS,
          comparisons,
        });
        dispatch({
          type: RECEIVED_PREVIOUS_PERIOD_STATS,
          previousPeriodRawStats: rest,
          start: start2,
          end: end2,
          previousPeriodCalculatedCharts: charts?.[1] || {},
          calculatedCharts: charts?.[0] || {},
          calculatedStats: calculatedStats?.[0] || {},
          previousPeriodCalculatedStats: calculatedStats?.[1] || {},
        });
      }
    } catch (e) {
      console.error('Error compareStats', e);
    }
  };
};

type DatesToCompareProps = { start: moment.Moment; end: moment.Moment; id: PreviousPeriodIds };
export const changeDatesToCompare = (props: DatesToCompareProps) => {
  let { start, end, id } = props || {};
  return (dispatch, getState) => {
    const period = { start: start?.format(), end: end?.format(), id };
    dispatch({
      type: DATES_TO_COMPARE,
      period,
    });
  };
};

export const getStatsLtv = () => {
  return async (dispatch, getState) => {
    var { mainDatePickerSelectionRange, currentShopId } = getState();
    var { start, end } = mainDatePickerSelectionRange;

    var format = 'YYYY-MM-DDTHH:mm:ss';
    start = start.format(format);
    end = end.format(format);
    dispatch(loadingStatsLtv());

    try {
      let url = `/v2/shopify/mongo/get-unique-customers-count`;
      let params = { shopId: currentShopId, start, end, granularity: 'total' };
      let uniqueCustomerCount = (await axiosInstance.post(url, params)).data[0].customer_count;
      dispatch(statsLtvReceived({ uniqueCustomerCount }));
      dispatch({ type: 'REGROUPING_STATS_END' });
    } catch {
      // dispatch(showErrorToast(DEFAULT_ERROR_MSG));
    }

    if (isLocalhost || (window.location && window.location.href.indexOf('stats=true') > 0))
      // console.log('STATS:LTV', stats);

      return dispatch(summaryRefresh());
  };
};

// export const receiveCustomSpends = (customSpends) => ({
//   type: RECEIVE_CUSTOM_SPENDS,
//   customSpends,
// });
export const receiveCustomSpends = (customSpends) => {
  return (dispatch) => {
    customSpends?.forEach((spend) => {
      if (
        !spend.metricId &&
        (!spend.start_date ||
          (spend.recurring && !spend.recurringIntervalLength) ||
          (!spend.recurring && !spend.end_date))
      ) {
        dispatch(setCustomSpendFlag(true));
      } else if (spend.metricId && !spend.start_date) {
        dispatch(setCustomSpendFlag(true));
      }
    });
    dispatch({
      type: RECEIVE_CUSTOM_SPENDS,
      customSpends,
    });
  };
};

export const addNewCustomSpendCategory = (category, categories) => {
  return (dispatch) => {
    if (!category || categories.includes(category)) return;
    else {
      categories.unshift(category);
      db().set(
        {
          // customSpendCategories: FieldValue.arrayUnion(category),
          customSpendCategories: categories,
        },
        { merge: true },
      );
      dispatch({
        type: ADD_NEW_CUSTOM_SPEND_CATEGORY,
        category,
      });
    }
  };
};

export const updateCustomExpenseCategories = (categories) => {
  return (dispatch) => {
    db().set(
      {
        customSpendCategories: FieldValue.arrayUnion(...categories),
      },
      { merge: true },
    );
    dispatch({
      type: UPDATE_CUSTOM_EXPENSE_CATEGORIES,
      categories,
    });
  };
};

export const loadingOrders = () => ({
  type: LOADING_ORDERS,
});

export const loadingStats = () => ({
  type: LOADING_STATS,
});

export const loadingStatsLtv = () => ({
  type: LOADING_STATS_LTV,
});

export const loadingRefunds = () => ({
  type: LOADING_REFUNDS,
});

export const loadingCustomSpends = () => ({
  type: LOADING_CUSTOM_SPENDS,
});

export const lightboxOpen = (data) => {
  return async (dispatch, getState) => {
    dispatch({
      type: LIGHTBOX_OPEN,
      data,
    });
  };
};

export const lightboxClose = () => {
  return async (dispatch, getState) => {
    dispatch({
      type: LIGHTBOX_CLOSE,
    });
  };
};

export const triggerReloadContentHubTab = () => {
  return async (dispatch, getState) => {
    dispatch({
      type: TRIGGER_RELOAD_CONTENT_HUB_TAB,
    });
  };
};

export const finishReloadContentHubTab = () => {
  return async (dispatch, getState) => {
    dispatch({
      type: FINISHED_RELOAD_CONTENT_HUB_TAB,
    });
  };
};

// @TODO
// re-write , map() and object recreation are not the fastest way
export const statsReceived = (stats) => ({
  type: STATS_RECEIVED,
  stats: stats.map((o) => {
    var start = moment(o.start);
    return {
      ...o,
      start,
    };
  }),
});

export const receiveOrders = (orders) => ({
  type: RECEIVE_ORDERS,
  orders: orders.reverse().map((o) => {
    var created_at = moment(o.created_at);
    return {
      ...o,
      created_at,
    };
  }),
});

export const receiveRefunds = (refunds) => ({
  type: RECEIVE_REFUNDS,
  refunds: refunds.map((o) => {
    var created_at = moment(o.created_at);
    return {
      ...o,
      created_at,
    };
  }),
});

export const initializing = () => ({
  type: INITIALIZING,
  //loading: true
});

export const toggleTopbarUserMenu = () => ({
  type: TOGGLE_TOPBAR_USER_MENU,
  //loading: true
});

export const mainDatePickerOnMonthChange = (month: number, year: number) => ({
  type: MAIN_DATE_PICKER_MONTH_CHANGE,
  month,
  year,
});

export const compareDatePickerOnMonthChange = (month: number, year: number) => ({
  type: COMPARE_DATE_PICKER_MONTH_CHANGE,
  month,
  year,
});

export const newCustomSpendStartDateOnMonthChange = (month, year) => ({
  type: NEW_CUSTOM_SPEND_START_DATE_PICKER_MONTH_CHANGE,
  month,
  year,
});
export const newCustomSpendEndDateOnMonthChange = (month, year) => ({
  type: NEW_CUSTOM_SPEND_END_DATE_PICKER_MONTH_CHANGE,
  month,
  year,
});

export const mainDatePickerOnSelectionChange = (range, isSilent = false) => {
  return async (dispatch, getState) => {
    if (!isSilent) {
      dispatch({
        type: MAIN_DATE_PICKER_SELECTION_CHANGE,
        range,
      });
    }
    if (moment().isSame(range.end, 'day')) {
      if (isLocalhost) {
        return;
      }
      const { lastUpdateToday } = (await db().get()).data()!;
      if (
        lastUpdateToday &&
        moment().utc().diff(lastUpdateToday, 'minutes') < REFRESH_TIME_INTERVAL
      ) {
        return;
      }
      dispatch(callToIntegrationsUpdateToday());

      // for now, don't reimport today data for pinterest and snapchat
      // dispatch(callToUpdateTodayPinterest());
      // dispatch(callToUpdateTodaySnapchat());
      await db().set({ lastUpdateToday: moment().utc().format() }, { merge: true });
    }
  };
};

export const mainDatePickerSpecialPeriodChange = (period) => {
  return (dispatch) => {
    dispatch({
      type: MAIN_DATE_PICKER_SPECIAL_PERIOD_CHANGE,
      period,
    });
  };
};

export const mainDatePickerSelectedOptionChange = (option: string | null) => {
  return (dispatch) => {
    dispatch({
      type: MAIN_DATE_PICKER_SELECTED_OPTION,
      option,
    });
  };
};

export const compareDatePickerSelectedOptionChange = (option: string) => {
  return (dispatch) => {
    dispatch({
      type: COMPARE_DATE_PICKER_SELECTED_OPTION,
      option,
    });
  };
};

export const durationFiltersValueChange = (duration: number) => {
  return (dispatch) => {
    dispatch({
      type: DURATION_FILTERS_VALUE_CHANGED,
      payload: duration,
    });
  };
};

export const customSelectedCompareOption = () => ({
  type: CUSTOM_SELECTED_COMPARE_OPTION,
});
export const resetSelectedCompareOption = () => ({
  type: RESET_SELECTED_COMPARE_OPTION,
});

export const newCustomSpendStartDateOnChange = (range) => {
  return {
    type: NEW_CUSTOM_SPEND_START_DATE_ON_CHANGE,
    range,
  };
};

export const newCustomSpendEndDateOnChange = (range) => {
  return {
    type: NEW_CUSTOM_SPEND_END_DATE_ON_CHANGE,
    range,
  };
};

export const toggleSaveIndicator = () => {
  var indicator = document.querySelector('.save-indicator')?.classList;
  indicator?.add('show');
  indicator?.remove('hide');
  setTimeout(() => {
    indicator?.add('hide');
  }, 50);
};

export const cogsUploadDialogCancelOnPress = () => ({
  type: COGS_UPLOAD_DIALOG_CANCEL_ON_PRESS,
});

export const shippingImportByOrderOnClose = () => ({
  type: SHIPPING_IMPORT_BY_ORDER_ON_CLOSE,
});

export const importShippingByOrderTableOnPress = () => {
  return {
    type: SHIPPING_IMPORT_BY_ORDER_ON_PRESS,
  };
};

export const productsTableProductOnChangeProductCost = (product, product_cost) => {
  return {
    type: PRODUCTS_TABLE_PRODUCT_ON_CHANGE_PRODUCT_COST,
    product,
    product_cost,
  };
};

export const importProductsTableOnPress = () => {
  return {
    type: IMPORT_PRODUCTS_TABLE_ON_PRESS,
  };
};

export const topbarMobileNavigationToggle = () => {
  return {
    type: TOPBAR_MOBILE_NAVIGATION_TOGGLE,
  };
};

export const topbarMobileNavigationClose = () => {
  return {
    type: TOPBAR_MOBILE_NAVIGATION_CLOSE,
  };
};

export const toggleNewCustomSpendIsOpen = () => {
  return {
    type: NEW_CUSTOM_SPEND_WINDOW_TOGGLE,
  };
};

export const toggleNewMetricBasedCustomSpendIsOpen = () => {
  return {
    type: NEW_METRIC_BASED_CUSTOM_SPEND_WINDOW_TOGGLE,
  };
};

export const toggleImportCustomSpendIsOpen = () => {
  return {
    type: IMPORT_CUSTOM_SPENDS_GS_MODAL_TOGGLE,
  };
};

export const newPaymentGatewayNameOnChange = (value) => {
  return {
    type: NEW_PAYMENT_GATEWAY_NAME_ON_CHANGE,
    value,
  };
};

export const newCustomSpendTitleOnChange = (value) => {
  return {
    type: NEW_CUSTOM_SPEND_TITLE_ON_CHANGE,
    value,
  };
};

export const newPaymentGatewayCostOnChange = (value) => {
  return {
    type: NEW_PAYMENT_GATEWAY_COST_ON_CHANGE,
    value,
  };
};

export const newCustomSpendCostOnChange = (value) => {
  return {
    type: NEW_CUSTOM_SPEND_COST_ON_CHANGE,
    value,
  };
};

export const newCustomSpendMetricOnSelect = (value) => {
  return {
    type: NEW_CUSTOM_SPEND_METRIC_ON_SELECT,
    value,
  };
};

export const newCustomSpendPercentOnChange = (value) => {
  return {
    type: NEW_CUSTOM_SPEND_PERCENT_ON_CHANGE,
    value,
  };
};

export const newCustomSpendRecurringOnChange = (value) => {
  return {
    type: NEW_CUSTOM_SPEND_RECURRING_ON_CHANGE,
    value,
  };
};

export const customSpendAdspendOnChange = (value) => {
  return {
    type: CUSTOM_SPEND_ADSPEND_ON_CHANGE,
    value,
  };
};

export const customSpendSave = ({ obj }) => {
  return async (dispatch, getState) => {
    const { currentShopId, customSpends } = getState();
    if (obj.start_date) obj.start_date = obj.start_date.toDate().getTime();
    if (obj.end_date) obj.end_date = obj.end_date.toDate().getTime();
    try {
      const res = await axiosInstance.post('v2/summary-page/custom-spend-save', {
        shopId: currentShopId,
        customSpend: obj,
        customSpendId: obj?.id,
      });
      if (!customSpends.length) dispatch(getOnboarding(currentShopId));
      dispatch({
        type: CUSTOM_SPEND_SAVE,
        customSpend: { ...obj, id: res.data.id },
      });
      toast.success('Custom Expense saved');
    } catch (error) {
      const message = `Error! Custom Expense not saved. ${
        error.data.includes('5000')
          ? 'There is a maximum limit of 5000 custom expenses per shop.'
          : ''
      }`;
      toast.error(message);
    }
  };
};

export const editCustomSpend = (customSpendId) => (dispatch, getState) => {
  const { customSpends } = getState();
  const initialData = customSpends.find((x) => x.id === customSpendId);
  dispatch({
    type: EDIT_CUSTOM_SPEND,
    payload: initialData,
  });
};

export const clearInitialData = () => {
  return {
    type: CLEAR_INITIAL_DATA,
  };
};

export const summaryIsExpandedToggle = () => {
  return {
    type: SUMMARY_IS_EXPANDED_TOGGLE,
  };
};

export const summaryChartPopupIsOpenToggle = () => {
  return {
    type: SUMMARY_CHART_POPUP_IS_OPEN_TOGGLE,
  };
};

export const onPressSummaryBox = (data: AbstractChart[], settings: SummaryPopupMetadata) => {
  return {
    type: SUMMARY_BOX_ON_PRESS,
    chartSettings: { data, ...settings },
  };
};

export const newCustomSpendStartDateWindowToggle = () => {
  return {
    type: NEW_CUSTOM_SPEND_START_DATE_WINDOW_TOGGLE,
  };
};

export const newCustomSpendEndDateWindowToggle = () => {
  return {
    type: NEW_CUSTOM_SPEND_END_DATE_WINDOW_TOGGLE,
  };
};

export const customSpendStartDateWindowToggle = (id) => {
  return {
    type: CUSTOM_SPEND_START_DATE_WINDOW_TOGGLE,
    id,
  };
};

export const summaryRefreshOnPress = () => {
  return (dispatch) => {
    dispatch(getLiveData());
    dispatch(summaryRefresh());
  };
};

export const summaryRefresh = () => ({
  type: SUMMARY_ON_REFRESH,
  timestamp: moment(),
});

export const customSpendOnDelete = (customSpend) => {
  return async (dispatch, getState) => {
    const { currentShopId } = getState();
    try {
      await axiosInstance.post('v2/summary-page/custom-spend-delete', {
        shopId: currentShopId,
        customSpend,
      });
      dispatch({
        type: CUSTOM_SPEND_ON_DELETE,
        customSpend,
      });
      toast.success('Custom Expense deleted');
    } catch (e) {
      toast.error('Error! Custom Expense not deleted');
    }
  };
};

export const setCustomSpendFlag = (payload) => ({
  type: SET_CUSTOM_SPENDS_FLAG,
  payload,
});

export const hideCustomSpendsBanner = () => {
  return (dispatch) => {
    db().set({ hideCustomSpendBanner: true }, { merge: true });
    dispatch({
      type: HIDE_CUSTOM_SPEND_BANNER,
    });
  };
};

export const customSpendsUploadDialogCancelOnPress = () => ({
  type: CUSTOM_SPENDS_UPLOAD_DIALOG_CANCEL_ON_PRESS,
});

export const customSpendsExportCsvOnPress = (product) => {
  return (dispatch, getState) => {
    var customSpends = getState().customSpends.map(
      ({
        id,
        title,
        cost,
        percent,
        start_date,
        end_date,
        recurring,
        recurringIntervalLength,
        recurringIntervalCadence = 'day',
        category,
        isAdSpend,
        metricId,
      }) => ({
        id,
        category: category || 'Uncategorized',
        title,
        start_date: moment(start_date).format('MMM DD, YYYY'),
        end_date: end_date ? moment(end_date).format('MMM DD, YYYY') : 'No end date',
        cost: cost ? cost : `${percent}% of ${metricId}`,
        recurring,
        recurringIntervalLength: recurringIntervalLength
          ? `every ${recurringIntervalLength} ${recurringIntervalCadence}`
          : '',
        isAdSpend,
      }),
    );
    CSVExport(customSpends, 'custom_spends_table');
    return {
      type: CUSTOM_SPENDS_EXPORT_CSV,
    };
  };
};

export const exportCustomExpenses = (spendsPerCategory: {}) => {
  return (dispatch, getState) => {
    const { mainDatePickerSelectionRange, currency } = getState();
    const { start, end } = mainDatePickerSelectionRange;
    let format = 'YYYY/MM/DD';
    let data: any = [];
    Object.keys(spendsPerCategory)
      .filter((x) => spendsPerCategory[x].total !== 0)
      .forEach((c) => {
        Object.keys(spendsPerCategory[c])
          .filter((x) => x !== 'total' && spendsPerCategory[c][x] !== 0)
          .forEach((e) => {
            data.push({
              category: c,
              expense: e,
              total: CURRENCIES[currency] + Intl.NumberFormat().format(spendsPerCategory[c][e]),
              start: start.format(format),
              end: end.format(format),
            });
          });
      });
    CSVExport(data, 'custom_expenses_table');
    return {
      type: EXPORT_CUSTOM_EXPENSES,
    };
  };
};

export const importCustomSpendsTableOnPress = () => {
  return {
    type: IMPORT_CUSTOM_SPENDS_TABLE_ON_PRESS,
  };
};

export const mobileNotificationsPermissionGiven = () => {
  return {
    type: MOBILE_NOTIFICATIONS_PERMISSION_GIVEN,
  };
};

export const showPrompt = (promptSettings) => {
  return {
    type: SHOW_PROMPT,
    promptSettings,
  };
};

export const hidePrompt = () => {
  return {
    type: HIDE_PROMPT,
    promptSettings: null,
  };
};
export const convertStatsCurrency = (statsCurrency) => {
  return {
    type: CONVERT_STATS_CURRENCY,
    payload: statsCurrency,
  };
};
export const getConversionRate = (originalCurrency, newCurrency) => {
  return async (dispatch, getState) => {
    const { mainDatePickerSelectionRange } = getState();
    const { start, end } = mainDatePickerSelectionRange;

    const isOneDay = moment(start).diff(moment(end), 'days') === 0;

    try {
      dispatch({
        type: LOADING_CONVERSION_RATE,
        payload: true,
      });

      const currencyConversionRate =
        originalCurrency === newCurrency
          ? 1
          : await fetchCurrenciesRate(originalCurrency, newCurrency, mainDatePickerSelectionRange);

      dispatch({
        type: LOADING_CONVERSION_RATE,
        payload: false,
      });
      return dispatch(setConversionRate(currencyConversionRate));
    } catch (error) {
      dispatch({
        type: LOADING_CONVERSION_RATE,
        payload: false,
      });
      return error;
    }
  };
};

export const setConversionRate = (currencyConversionRate) => {
  return {
    type: SET_CONVERSION_RATE,
    payload: currencyConversionRate,
  };
};

export const showErrorToast = (msg) => {
  return {
    type: SHOW_GENERAL_ERROR_TOAST,
    msg,
  };
};

export const hideErrorToast = () => {
  return {
    type: HIDE_GENERAL_ERROR_TOAST,
  };
};

export const setBidirectionalCogs = (bidirectionalCogs) => {
  db().set({ bidirectionalCogs }, { merge: true });
  return {
    type: SET_BIDIRECTIONAL_COGS,
    payload: bidirectionalCogs,
  };
};

// export const ADD_ALERT = 'ADD_ALERT';
// export const addAlert = (alertType, msg) => {
//   return {
//     type: ADD_ALERT,
//     page, alertType, msg
//   }
// }
export const removeAlert = (page, alertType, msg) => {
  db().set(
    {
      alerts: {
        [page]: {
          [alertType]: FieldValue.arrayRemove(msg),
        },
      },
    },
    { merge: true },
  );
  return {
    type: REMOVE_ALERT,
    page,
    alertType,
    msg,
  };
};

export const saveShopIndustry = ({ industry, role }) => {
  return async (dispatch, getState) => {
    let { currentShopId } = getState();
    await db().set(
      {
        industry,
      },
      { merge: true },
    );

    await userDb().set(
      {
        shops: {
          [currentShopId]: {
            role,
          },
        },
      },
      { merge: true },
    );
    dispatch({
      type: SAVE_SHOP_INDUSTRY,
      payload: { industry, role },
    });
  };
};
export const updateCustomExpenseAttributionType = (val) => {
  return async (dispatch, getState) => {
    const { currentShopId } = getState();
    dispatch({
      type: UPDATE_CUSTOM_EXPENSE_ATTRIBUTION_TYPE,
      payload: val,
    });
    await db().set({ customExpenseAttributionType: val }, { merge: true });
    const data = {
      shopId: currentShopId,
    };
    await axiosInstance.post('v2/summary-page/custom-spend-changed', data);
  };
};

export const changeActivePage = (page: ActivePage) => {
  return (dispatch, getState) => {
    return dispatch({
      type: CHANGE_ACTIVE_PAGE,
      page,
    });
  };
};

export const openGenerativeAi = (payload: GenerativeAiPopupData) => {
  return (dispatch: Dispatch) => {
    return dispatch({
      type: GENERATIVE_AI_POPUP_OPENED,
      payload,
    });
  };
};
export const closeGenerativeAi = () => {
  return (dispatch: Dispatch) => {
    return dispatch({
      type: GENERATIVE_AI_POPUP_CLOSED,
      payload: {},
    });
  };
};

export const openBackgroundDiffusionPopup = (payload: BackgroundDiffusionPopupData) => {
  return (dispatch: Dispatch, getState: () => RootState) => {
    genericEventLogger(analyticsEvents.BACKGROUND_DIFFUSION, {
      action: aiActions.POPUP_OPEN,
      shop: getState().currentShopId,
      image: payload.initImage.thumbnail,
    });
    return dispatch({
      type: BACKGROUND_DIFFUSION_POPUP_OPENED,
      payload,
    });
  };
};
export const closeBackgroundDiffusionPopup = () => {
  return (dispatch: Dispatch) => {
    return dispatch({
      type: BACKGROUND_DIFFUSION_POPUP_CLOSED,
      payload: {},
    });
  };
};

export const openAdPreviewPopup = (payload: BuiltAdType) => {
  return (dispatch: Dispatch) => {
    return dispatch({
      type: AD_PREVIEW_POPUP_OPENED,
      payload,
    });
  };
};
export const closeAdPreviewPopup = () => {
  return (dispatch: Dispatch) => {
    return dispatch({
      type: AD_PREVIEW_POPUP_CLOSED,
      payload: {},
    });
  };
};

export const loadingAdInfoForGai = (payload: {
  accountId: string;
  asset: string;
  assetType: string;
}) => {
  return (dispatch: Dispatch) => {
    return dispatch({
      type: GENERATIVE_LOADING_AD_INFO,
      payload,
    });
  };
};
export const finishLoadingAdInfoForGai = () => {
  return (dispatch: Dispatch) => {
    return dispatch({
      type: GENERATIVE_LOADING_AD_INFO_FINISHED,
      payload: {},
    });
  };
};

export const loadingProductInfoForGai = (payload: { id: string; shopId: string }) => {
  return (dispatch: Dispatch) => {
    return dispatch({
      type: GENERATIVE_LOADING_PRODUCT_INFO,
      payload,
    });
  };
};

export const finishLoadingProductInfoForGai = () => {
  return (dispatch: Dispatch) => {
    return dispatch({
      type: GENERATIVE_LOADING_PRODUCT_INFO_FINISHED,
      payload: {},
    });
  };
};

export const openGenerateCopyAi = (payload: GenerativeAiPopupData) => {
  return (dispatch: Dispatch) => {
    return dispatch({
      type: GENERATE_COPY_AI_POPUP_OPENED,
      payload,
    });
  };
};

export const closeGenerateCopyAi = () => {
  return (dispatch: Dispatch) => {
    return dispatch({
      type: GENERATE_COPY_AI_POPUP_CLOSED,
      payload: {},
    });
  };
};

export const loadingAdInfoForGCopy = (payload: {
  accountId: string;
  asset: string;
  assetType: string;
}) => {
  return (dispatch: Dispatch) => {
    return dispatch({
      type: GENERATE_COPY_LOADING_AD_INFO,
      payload,
    });
  };
};
export const finishLoadingAdInfoForGCopy = () => {
  return (dispatch: Dispatch) => {
    return dispatch({
      type: GENERATE_COPY_LOADING_AD_INFO_FINISHED,
      payload: {},
    });
  };
};

export const changeEntityGenerativeAi = (payload: GenerativeAiPopupData) => {
  return (dispatch: Dispatch) => {
    return dispatch({
      type: GENERATIVE_AI_CHANGE_ENTITY,
      payload,
    });
  };
};

export const changeEntityGenerativeCopy = (payload: GenerativeAiPopupData) => {
  return (dispatch: Dispatch) => {
    return dispatch({
      type: GENERATE_COPY_CHANGE_ENTITY,
      payload,
    });
  };
};

export const setTextFromGeneratedCopies = (payload: string) => {
  return (dispatch: Dispatch) => {
    return dispatch({
      type: SET_GENERATED_TEXT,
      payload: payload,
    });
  };
};

export const resetTextFromGeneratedCopies = () => {
  return (dispatch: Dispatch) => {
    return dispatch({
      type: UNSET_GENERATED_TEXT,
    });
  };
};

export const setContentHubSearchTerm = (input) => {
  return (dispatch: Dispatch) => {
    return dispatch({
      type: CHANGE_CONTENT_HUB_SEARCH_TERM,
      payload: input,
    });
  };
};

export const openContentHubUploadMedia = (folder = '/') => {
  return (dispatch: Dispatch) => {
    return dispatch({
      type: OPEN_UPLOAD_MEDIA,
      payload: folder,
    });
  };
};

export const closeContentHubUploadMedia = () => {
  return (dispatch: Dispatch) => {
    return dispatch({
      type: CLOSE_UPLOAD_MEDIA,
    });
  };
};

export const changeContentHubFolder = (folder = '/') => {
  return (dispatch: Dispatch) => {
    return dispatch({
      type: CHANGE_CONTENT_HUB_FOLDER,
      payload: folder,
    });
  };
};

export const updateSelectedStatsServcies = (services: ServicesIds[]) => {
  return async (dispatch, getState) => {
    await db().set({ selectedStatsServcies: services }, { merge: true });
    dispatch({
      type: SELECTED_STATS_SERVCIES_CHANGED,
      services,
    });
  };
};

export async function checkShopServicesConnection(currentShopId: string) {
  try {
    await axiosInstance.post(`/v2/summary-page/check-services-connection`, {
      shopDomain: currentShopId,
    });
  } catch (e) {
    console.error(`ERROR checkShopServicesConnection`);
  }
}

export const toggleIsNavigationOpen = (isOpen: boolean) => {
  return {
    type: IS_NAVIGATION_OPEN,
    payload: isOpen,
  };
};

export const changeAdBuilderSource = (source: 'roas' | 'product') => {
  return (dispatch) => {
    dispatch({
      type: CHANGE_AD_BUILDER_SOURCE,
      payload: source,
    });
  };
};

export const toggleBulkAdBuilders = (checked) => {
  return (dispatch) => {
    dispatch({
      type: TOGGLE_BULK_ADBUILDER,
      payload: checked,
    });
  };
};

export const addBrandColor = (color: string) => {
  return (dispatch) => {
    db().set(
      {
        brandColors: FieldValue.arrayUnion(color),
      },
      { merge: true },
    );
    dispatch({
      type: ADD_BRAND_COLOR,
      color,
    });
  };
};

export const removeBrandColor = (color: string) => {
  return (dispatch) => {
    db().set(
      {
        brandColors: FieldValue.arrayRemove(color),
      },
      { merge: true },
    );
    dispatch({
      type: REMOVE_BRAND_COLOR,
      color,
    });
  };
};

export const addBrandFont = (font: string) => {
  return (dispatch) => {
    db().set(
      {
        brandFonts: FieldValue.arrayUnion(font),
      },
      { merge: true },
    );
    dispatch({
      type: ADD_BRAND_FONT,
      font,
    });
  };
};

export const removeBrandFont = (font: string) => {
  return (dispatch) => {
    db().set(
      {
        brandFonts: FieldValue.arrayRemove(font),
      },
      { merge: true },
    );
    dispatch({
      type: REMOVE_BRAND_FONT,
      font,
    });
  };
};

export const initPrompts = () => {
  return (dispatch) => {
    db()
      .collection('prompts')
      .doc('prompts')
      .onSnapshot((doc) => {
        const prompts: any = doc.data() || [];
        dispatch({
          type: INIT_PROMPTS,
          prompts: prompts.prompts || [],
        });
      });
  };
};

export const addPrompt = (prompt: string) => {
  return (dispatch) => {
    const newPrompt = { text: prompt, pinned: false, id: window.btoa(prompt) };
    db()
      .collection('prompts')
      .doc('prompts')
      .set(
        {
          prompts: FieldValue.arrayUnion(newPrompt),
        },
        { merge: true },
      );
    dispatch({
      type: ADD_PROMPT,
      prompt: newPrompt,
    });
  };
};

export const removePrompt = (prompt: ShopPrompt) => {
  return (dispatch) => {
    db()
      .collection('prompts')
      .doc('prompts')
      .set(
        {
          prompts: FieldValue.arrayRemove(prompt),
        },
        { merge: true },
      );
    dispatch({
      type: REMOVE_PROMPT,
      prompt,
    });
  };
};

export const pinPrompt = (prompt: ShopPrompt) => {
  return (dispatch) => {
    db()
      .collection('prompts')
      .doc('prompts')
      .set(
        {
          prompts: FieldValue.arrayUnion({ ...prompt, pinned: true }),
        },
        { merge: true },
      );
    dispatch({
      type: PIN_PROMPT,
      prompt,
    });
  };
};

export const unpinPrompt = (prompt: ShopPrompt) => {
  return (dispatch) => {
    db()
      .collection('prompts')
      .doc('prompts')
      .set(
        {
          prompts: FieldValue.arrayUnion({ ...prompt, pinned: false }),
        },
        { merge: true },
      );
    dispatch({
      type: UNPIN_PROMPT,
      prompt,
    });
  };
};

export const changePromptOrder = (prompts: ShopPrompt[]) => {
  return (dispatch) => {
    db().collection('prompts').doc('prompts').set(
      {
        prompts,
      },
      { merge: true },
    );
    dispatch({
      type: CHANGE_PROMPT_ORDER,
      prompts,
    });
  };
};

export const setShopBillingInterval = (args?: {
  shopUrl: string;
  billingInterval: BillingInterval;
}) => {
  if (!args) return;

  return {
    type: SET_SHOP_BILLING_INTERVAL,
    payload: { shopUrl: args.shopUrl, billingInterval: args.billingInterval },
  };
};
export const initShopBillingInterval = () => {
  // fill in billing interval for a specific shop if it hasn't been filled in
  return (dispatch: AppDispatch, getState: () => RootState) => {
    const { agencySignUpStores, currentShopId, signupJourney, billingIntervalsPerShop } =
      getState();

    const shopUrl: string = currentShopId || signupJourney?.storeURL;
    if (shopUrl && !billingIntervalsPerShop[shopUrl]) {
      return dispatch({
        type: SET_SHOP_BILLING_INTERVAL,
        payload: { shopUrl, billingInterval: 'year' },
      });
    }

    if (agencySignUpStores && Array.isArray(agencySignUpStores)) {
      return dispatch({
        type: INIT_MULTIPLE_SHOPS_BILLING_INTERVAL,
        payload: agencySignUpStores.reduce((acc, { value }) => {
          acc[value] = billingIntervalsPerShop[value] || 'year';
          return acc;
        }, {}),
      });
    }
  };
};

export const activeCopyToClipboard = () => {
  return {
    type: COPY_TO_CLIPBOARD_ACTIVE,
  };
};
export const inactiveCopyToClipboard = () => {
  return {
    type: COPY_TO_CLIPBOARD_INACTIVE,
  };
};

export const checkPpsStatus = () => {
  return async (dispatch: AppDispatch, getState: () => RootState) => {
    const { currentShopId } = getState();
    try {
      const { data } = await axiosInstance.post('/v2/attribution/pps-distribution-status', {
        shop: currentShopId,
        startDate: '2023-01-01',
        endDate: moment().format('YYYY-MM-DD'),
      });
      dispatch({
        type: PPS_STATUS,
        payload: data,
      });
    } catch (error) {
      console.error(error);
    }
  };
};

export const goOnline = () => ({
  type: ONLINE,
});

export const goOffline = () => ({
  type: OFFLINE,
});

export const hideOfflineBanner = () => ({
  type: HIDE_OFFLINE_BANNER,
});

export const setUseShippingCharges = (bool) => {
  return (dispatch, getState) => {
    const { costSettingsCompletion, currentShopId } = getState();
    db().set({ useShippingChargesAsCost: bool }, { merge: true });
    dispatch({ type: SET_USE_SHIPPING_CHARGES, payload: bool });
    const { tasks } = costSettingsCompletion;
    if (tasks.find((task) => task.name === 'Shipping')?.status !== TASK_STATUS.COMPLETE)
      dispatch(getOnboarding(currentShopId));
  };
};
