import { selectedWorkspaceSelector } from 'ducks/auth';
import { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { SignUpStep } from 'routes/auth/SignUp/signUpStep.enum';
import { SubSettingsPopover } from '@tw/ui-components';
import { useAppDispatch } from 'index';
import { setAddToWorkspaceInfo, setIsAgencyPod, setIsFromPodView } from 'ducks/signupJourney';

export interface IStoreAdder {
  withIcon?: boolean;
}

export const StoreAdder: React.FC<IStoreAdder> = ({ withIcon = true }) => {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const selectedWorkspace = useSelector(selectedWorkspaceSelector);

  const addShopClick = useCallback(
    (isFree: boolean) => {
      if (isFree) {
        dispatch(setIsFromPodView(true));
        dispatch(setIsAgencyPod(!!selectedWorkspace.isAgency));
        dispatch(
          setAddToWorkspaceInfo({
            workspaceId: selectedWorkspace.id,
            isAdminOnWorkspace: selectedWorkspace?.roles?.includes('admin'),
          }),
        );
      }

      history.push({
        pathname: `/signup${isFree ? '-free' : ''}`,
        ...(!isFree && {
          state: {
            currentIndex: SignUpStep.ConnectStore,
            workspaceId: selectedWorkspace.id,
            isAdminOnWorkspace: selectedWorkspace?.roles?.includes('admin'),
            isFromAddStore: true,
            isAgency: !!selectedWorkspace.isAgency,
          },
        }),
      });
    },
    [dispatch, history, selectedWorkspace.id, selectedWorkspace.isAgency, selectedWorkspace?.roles],
  );

  const options = useMemo(
    () => [
      {
        label: 'Free Shop',
        onClick: () => addShopClick(true),
      },
      {
        label: 'Paid Shop',
        onClick: () => addShopClick(false),
      },
    ],
    [addShopClick],
  );

  return (
    <SubSettingsPopover
      options={options}
      targetLabel="Add Shop"
      textProps={{ fz: 'sm' }}
      {...(withIcon && { targetIcon: 'plus-circle' })}
    />
  );
};
