import { type IconName, Button, Tabs } from '@tw/ui-components';
import React, { useCallback } from 'react';
import { isDefined } from 'utils/isDefined';
import { useHistory } from 'react-router';

export type TabDescriptor = {
  value: string;
  label: string;
  link?: string;
  icon?: JSX.Element | IconName | null;
};

type WillyPanelTabsProps = {
  tabs: TabDescriptor[];
  value?: string;
  onTabChange: (selectedTabIndex: string) => void;
  className?: string;
};

export const WillyPanelTabs: React.FC<WillyPanelTabsProps> = ({
  tabs,
  value,
  onTabChange,
  className,
}) => {
  const history = useHistory();

  const handleTabChange = useCallback(
    (v: string | null) => {
      if (!isDefined(v)) return;
      const tabLink = tabs.find((tab) => tab.value === v)?.link;

      onTabChange(v);

      if (tabLink) {
        history.push(tabLink);
      }
    },
    [history, onTabChange, tabs],
  );

  return (
    <div
      className={`willy-panel-tabs flex gap-4 items-center justify-center md:justify-start h-full w-full nowrap overflow-auto p-4 ${
        className ? className : ''
      }`}
    >
      {tabs?.map((tab) => {
        const isActive = value === tab.value;
        return (
          <div
            key={tab.value}
            onClick={() => handleTabChange(tab.value)}
            className={`cursor-pointer flex items-center ${
              isActive ? 'bg-gray-200 text-black fill-black shadow' : 'text-gray-600 fill-gray-500'
            }  p-2.5 px-3 rounded-[4px]`}
          >
            {tab.icon}
            {tab.icon && <>&nbsp;</>}
            {tab.label}
          </div>
        );
      })}
    </div>
  );
};
