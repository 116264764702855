import { Checkbox, Flex, Text, TextInput } from '@tw/ui-components';
import { FC } from 'react';
import { EditorCollapse } from './EditorCollapse';
import { AxisDomain } from '../types/willyTypes';

type ChartCustomizeProps = {
  showPreviousPeriod?: boolean;
  setShowPreviousPeriod?: (val: boolean) => void;
  allowDataOverflow?: boolean;
  setAllowDataOverflow: (val: boolean) => void;
  yAxisDomain: AxisDomain;
  setYAxisDomain: (val: AxisDomain) => void;
  skinny: boolean;
  setSkinny: (val: boolean) => void;
  stacked: boolean;
  setStacked: (val: boolean) => void;
};

const minmax = [
  { value: 'min', label: 'Min' },
  { value: 'max', label: 'Max' },
] as const;

export const ChartCustomize: FC<ChartCustomizeProps> = ({
  showPreviousPeriod,
  setShowPreviousPeriod,
  allowDataOverflow,
  setAllowDataOverflow,
  yAxisDomain,
  setYAxisDomain,
  skinny,
  setSkinny,
  stacked,
  setStacked,
}) => {
  return (
    <Flex direction="column" gap="lg" px="md">
      <Checkbox
        label="Show previous period"
        checked={showPreviousPeriod}
        onChange={(v) => setShowPreviousPeriod?.(v)}
      />
      <EditorCollapse title="Y Axis Display">
        <Flex direction="column" gap="md" pt="sm">
          {minmax.map((m) => {
            return (
              <Flex direction="column" gap="sm" key={m.value}>
                <Text weight={500} size="sm">
                  {m.label}:{' '}
                </Text>
                <Checkbox
                  checked={yAxisDomain?.[m.value] === 'auto'}
                  label="Auto"
                  onChange={(checked) => {
                    setYAxisDomain({
                      ...yAxisDomain,
                      [m.value]: checked ? 'auto' : 0,
                    });
                  }}
                />
                <Checkbox
                  checked={yAxisDomain?.[m.value] === `data${m.label}`}
                  label={`Data ${m.label}`}
                  onChange={(checked) => {
                    setYAxisDomain({
                      ...yAxisDomain,
                      [m.value]: checked ? `data${m.label}` : 0,
                    });
                  }}
                />

                <TextInput
                  disabled={
                    yAxisDomain[m.value] === `data${m.label}` || yAxisDomain[m.value] === 'auto'
                  }
                  label="Custom"
                  type="number"
                  value={
                    typeof yAxisDomain[m.value] === 'number' ? yAxisDomain[m.value].toString() : '0'
                  }
                  onChange={(v) => {
                    setYAxisDomain({
                      ...yAxisDomain,
                      [m.value]: parseInt(v),
                    });
                  }}
                />
              </Flex>
            );
          })}
          <Checkbox
            checked={allowDataOverflow}
            label="Allow data overflow from the domain"
            onChange={(checked) => {
              setAllowDataOverflow(checked);
            }}
          />
        </Flex>
      </EditorCollapse>
      <EditorCollapse title="Bar Display">
        <Flex direction="column" gap="md" pt="sm">
          <Checkbox
            label="Skinny bars"
            checked={skinny}
            onChange={(v) => setSkinny(v)}
            description="Force Bars in the chart to be Skinny"
          />
          <Checkbox
            label="Stacked Left Y Axis"
            checked={stacked}
            onChange={(v) => setStacked?.(v)}
            description="Stacked bars on the left y axis will be stacked together"
          />
        </Flex>
      </EditorCollapse>
    </Flex>
  );
};
