import { useCallback, useMemo } from 'react';
import {
  BreakdownValue,
  EditMetricModalPayload,
  RawNlqData,
  WillyMetric,
  TileModes,
  GridOptions,
  GridColumnOptions,
} from './types/willyTypes';
import { ServicesIds } from '@tw/types/module/services';
import { formatNumber } from 'utils/formatNumber';
import { FieldValue, firestoreRef, userDb } from 'utils/DB';
import { Card, Icon, IconName, Skeleton } from '@tw/ui-components';
import { DndContext, DragOverlay, closestCenter } from '@dnd-kit/core';
import { useWillyDndMetrics } from './hooks/useWillyDndMetrics';
import { ReactComponent as DragHandle } from '../../icons/drag-handle.svg';
import { SortableContext, rectSortingStrategy, useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { Portal } from 'components/Portal';
import { usePreviousPeriodValue } from './hooks/usePreviousPeriodValue';
import { WillyComparisonValue } from './WillyComparisonValue';
import { EditMajor, FilterMajor } from '@shopify/polaris-icons';
import {
  breakDownRowDataWithoutTotal,
  clearKeyFromParameters,
  getCustomViewDashboardCollectionRef,
  keyIsSomeDate,
  totalRowData,
  visibleLength,
} from './utils/willyUtils';
import { NewSummaryChart } from 'components/charts/NewSummaryChart';
import { WillyDescriptionTooltip } from './WillyDescriptionTooltip';
import { useDarkMode } from 'dark-mode-control';
import { Tooltip } from '@shopify/polaris';
import { PINNED_SECTION_ID } from './constants';
import LockedFeatureIndicator from 'components/library/LockedFeatures/LockedFeatureIndicator';
import { FeatureFlag } from '@tw/feature-flag-system/module/types';
import { IconPicker } from './IconPicker';

const dummyData = [
  { x: 0, y: 4 },
  { x: 1, y: 1 },
  { x: 2, y: 8 },
  { x: 3, y: 5 },
  { x: 4, y: 10 },
];

type WillySingleValueProps = {
  data: RawNlqData;
  previousPeriodData?: RawNlqData;
  queryId: string;
  serviceIds?: ServicesIds[];
  loading?: boolean;
  loadingPreviousPeriod?: boolean;
  metrics: WillyMetric[];
  currency: string;
  metricsChanged: (id: string, v: WillyMetric[]) => Promise<void>;
  setEditMetricModalOpen?: EditMetricModalPayload;
  setFilterMetricModalOpen?: EditMetricModalPayload;
  isEditEnable?: boolean;
  isFiltered?: boolean;
  onMetricClicked: (metric: WillyMetric) => void;
  grid?: GridOptions;
  gridColumns?: GridColumnOptions;
  twoColumnMobile?: boolean;
  tileMode?: TileModes;
  disableContentEditable?: boolean;
  errorInQuery: Record<string, string>;
  errorMetricsData?: Record<string, string>;
  loadingMetricsData?: Record<string, { current: boolean; previous: boolean }>;
  dashboardId?: string;
  pinnedMetricKeys?: string[];
  isPinnable?: boolean;
};

export const WillySingleValue: React.FC<WillySingleValueProps> = ({
  data,
  previousPeriodData,
  queryId,
  serviceIds,
  loading,
  loadingPreviousPeriod,
  metrics,
  currency,
  onMetricClicked,
  metricsChanged,
  setEditMetricModalOpen,
  setFilterMetricModalOpen,
  isFiltered = false,
  isEditEnable = true,
  grid,
  gridColumns,
  twoColumnMobile,
  tileMode,
  disableContentEditable,
  errorInQuery,
  errorMetricsData,
  loadingMetricsData,
  dashboardId,
  pinnedMetricKeys,
  isPinnable = false,
}) => {
  const { sensors, handleDragStart, handleDragOver, handleDragEnd, activeId } = useWillyDndMetrics({
    queryId,
    metrics,
    metricsChanged,
  });

  const isTableMode = tileMode === 'table';

  return (
    <>
      {isTableMode && (
        <div className="grid grid-cols-3 gap-8 items-center p-4">
          <p className="font-medium">Metrics</p>
          <p className="font-medium justify-self-end">Amount</p>
          <p className="font-medium justify-self-end">Trend</p>
        </div>
      )}
      <div className={`w-full flex-auto dark:bg-transparent border-0 flex flex-col bg-white`}>
        <div
          className={`flex gap-4 w-full sm:gap-4 flex-wrap flex-auto ${
            !isTableMode ? 'p-6.5' : ''
          }`}
        >
          {!metrics?.length && queryId !== 'pinnedSection' && (
            <div className="flex items-center justify-center w-full h-20">No data to show</div>
          )}
          <DndContext
            id={`willy-legend-${queryId}`}
            onDragEnd={handleDragEnd}
            sensors={sensors}
            collisionDetection={closestCenter}
            onDragStart={handleDragStart}
          >
            <SortableContext
              items={metrics?.map((x) => x.key) ?? []}
              strategy={rectSortingStrategy}
            >
              <div
                className={
                  `w-full flex-wrap content-baseline justify-items-stretch` +
                  `${twoColumnMobile ? ' grid grid-cols-2 ' : ' flex flex-wrap '} ` +
                  `${grid === 'grid' ? ' sm:grid ' : ' sm:flex sm:flex-wrap'} ` +
                  `${tileMode === 'table' ? ' !block' : 'gap-4 sm:gap-6.5'}`
                }
                style={{
                  gridTemplateColumns:
                    grid === 'grid' ? `repeat(${gridColumns}, minmax(0, 1fr))` : '',
                }}
              >
                {metrics
                  ?.filter((x) => !x.isDimension)
                  ?.filter((x) => x.active === undefined || x.active)
                  ?.map((metric, i) => {
                    const k = `${metric.key}_${i}`;
                    return (
                      <SingleTile
                        key={k}
                        metric={metric}
                        currency={currency}
                        queryId={queryId}
                        rawData={data}
                        isFiltered={isFiltered}
                        setFilterMetricModalOpen={setFilterMetricModalOpen}
                        previousPeriodRawData={previousPeriodData}
                        totalValue={totalRowData(data, metric.key)}
                        breakDownValue={breakDownRowDataWithoutTotal(data, metric.key)}
                        previousPeriodTotalValue={
                          previousPeriodData
                            ? totalRowData(previousPeriodData, metric.key)
                            : undefined
                        }
                        previousPeriodBreakDownValue={
                          previousPeriodData
                            ? breakDownRowDataWithoutTotal(previousPeriodData, metric.key)
                            : undefined
                        }
                        serviceIds={serviceIds}
                        metrics={metrics}
                        errorMetricsData={errorMetricsData}
                        errorInQuery={errorInQuery}
                        loadingMetricsData={loadingMetricsData}
                        metricsChanged={metricsChanged}
                        loading={loading}
                        loadingPreviousPeriod={loadingPreviousPeriod}
                        setEditMetricModalOpen={({ open, metricId }) => {
                          if (setEditMetricModalOpen) {
                            setEditMetricModalOpen({ open, metricId, queryId });
                          }
                        }}
                        isEditEnable={isEditEnable}
                        onMetricClicked={onMetricClicked}
                        twoColumnMobile={twoColumnMobile}
                        tileMode={tileMode}
                        disableContentEditable={disableContentEditable}
                        dashboardId={dashboardId}
                        pinnedMetricKeys={pinnedMetricKeys}
                        isPinnable={isPinnable}
                      />
                    );
                  })}
              </div>
            </SortableContext>
            <Portal>
              <DragOverlay>
                {activeId ? (
                  <SingleTile
                    queryId={queryId}
                    rawData={data}
                    previousPeriodRawData={previousPeriodData}
                    currency={currency}
                    metric={metrics.find((x) => x.key === activeId)!}
                    previousPeriodTotalValue={
                      previousPeriodData ? totalRowData(previousPeriodData, activeId) : undefined
                    }
                    previousPeriodBreakDownValue={
                      previousPeriodData
                        ? breakDownRowDataWithoutTotal(previousPeriodData, activeId)
                        : undefined
                    }
                    errorInQuery={errorInQuery}
                    isEditEnable={isEditEnable}
                    totalValue={totalRowData(data, activeId)}
                    breakDownValue={breakDownRowDataWithoutTotal(data, activeId)}
                    isFiltered={isFiltered}
                    setFilterMetricModalOpen={setFilterMetricModalOpen}
                    serviceIds={serviceIds}
                    metrics={metrics}
                    metricsChanged={async () => {}}
                    loading={false}
                    loadingPreviousPeriod={false}
                    onMetricClicked={onMetricClicked}
                    twoColumnMobile={twoColumnMobile}
                    tileMode={tileMode}
                    disableContentEditable={disableContentEditable}
                    dashboardId={dashboardId}
                    pinnedMetricKeys={pinnedMetricKeys}
                    isPinnable={isPinnable}
                  />
                ) : null}
              </DragOverlay>
            </Portal>
          </DndContext>
        </div>
      </div>
    </>
  );
};

type SingleTileProps = {
  loading?: boolean;
  queryId: string;
  loadingPreviousPeriod?: boolean;
  rawData: RawNlqData;
  previousPeriodRawData?: RawNlqData;
  metric: WillyMetric;
  currency: string;
  totalValue?: (string | number)[];
  breakDownValue?: BreakdownValue;
  previousPeriodTotalValue?: (string | number)[];
  previousPeriodBreakDownValue?: BreakdownValue;
  metrics: WillyMetric[];
  serviceIds?: ServicesIds[];
  metricsChanged: (id: string, v: WillyMetric[]) => Promise<void>;
  setEditMetricModalOpen?: (params: { open: boolean; metricId?: string }) => void;
  isEditEnable?: boolean;
  isFiltered?: boolean;
  setFilterMetricModalOpen?: (params: { open: boolean; metricId?: string }) => void;
  onMetricClicked: (metric: WillyMetric) => void;
  grid?: GridOptions;
  twoColumnMobile?: boolean;
  tileMode?: TileModes;
  disableContentEditable?: boolean;
  errorInQuery: Record<string, string>;
  errorMetricsData?: Record<string, string>;
  loadingMetricsData?: Record<string, { current: boolean; previous: boolean }>;
  dashboardId?: string;
  pinnedMetricKeys?: string[];
  isPinnable?: boolean;
};

const cardClass =
  'group/tile h-max min-h-full bg-white gap-4 sm:gap-5 flex flex-col sm:min-w-[250px] flex-auto dark:bg-surface';

const SingleTile: React.FC<SingleTileProps> = (props) => {
  const { metric, onMetricClicked, metrics, tileMode } = props;
  const { setNodeRef, transform, transition, isDragging } = useSortable({
    id: metric.key,
  });

  const shouldShowCard = useMemo(() => {
    const dimensions = metrics.filter((x) => x.isDimension);
    const nonDateDimensions = dimensions.filter((x) => !keyIsSomeDate(x.key));
    return false;
    // return nonDateDimensions.length > 1;
  }, [metrics]);

  const style: React.CSSProperties = useMemo(
    () => ({
      transform: CSS.Transform.toString(transform),
      transition,
      zIndex: isDragging ? '100' : 'auto',
      opacity: isDragging ? 0.3 : 1,
      touchAction: 'manipulation',
    }),
    [isDragging, transform, transition],
  );

  return (
    <div
      ref={setNodeRef}
      style={style}
      className={`flex-auto max-w-full w-full sm:w-auto flex-basis-[250px]`}
    >
      {tileMode === 'table' ? (
        <InnerSingleTile {...props} onMetricClicked={onMetricClicked} />
      ) : (
        <>
          {shouldShowCard && (
            <Card shadow={'sm'} padding={'xs'} radius={'sm'} className={cardClass}>
              <WillyCard {...props} />
            </Card>
          )}
          {!shouldShowCard && (
            <Card
              shadow={'sm'}
              padding={'xs'}
              radius={'sm'}
              className={cardClass}
              bg={metric.isBlocked ? 'gray.1' : 'white'}
            >
              <InnerSingleTile {...props} onMetricClicked={onMetricClicked} />
            </Card>
          )}
        </>
      )}
    </div>
  );
};

type InnerSingleTileProps = {} & SingleTileProps;

const InnerSingleTile: React.FC<InnerSingleTileProps> = ({
  loading,
  loadingPreviousPeriod,
  metric,
  totalValue,
  currency,
  breakDownValue,
  previousPeriodTotalValue,
  previousPeriodBreakDownValue,
  metrics,
  serviceIds,
  queryId,
  onMetricClicked,
  metricsChanged,
  setEditMetricModalOpen,
  isEditEnable = true,
  isFiltered = false,
  setFilterMetricModalOpen,
  twoColumnMobile,
  tileMode,
  disableContentEditable,
  errorInQuery,
  errorMetricsData,
  loadingMetricsData,
  dashboardId,
  pinnedMetricKeys,
  isPinnable,
}) => {
  const { deltaDirection, deltaPercentage } = usePreviousPeriodValue(
    metric.key,
    totalValue?.[0],
    previousPeriodTotalValue?.[0],
    metric?.valueIsNegative,
  );
  const doDarkMode = useDarkMode();

  const metricLoading = useMemo(() => {
    return loading || loadingMetricsData?.[metric.key]?.current;
  }, [loading, loadingMetricsData, metric.key]);

  const errorInMetric = useMemo(() => {
    if (errorMetricsData && errorMetricsData[metric.key]) {
      return errorMetricsData[metric.key];
    }
    if (errorInQuery && errorInQuery[queryId]) {
      return errorInQuery[queryId];
    }
  }, [errorInQuery, metric.key, errorMetricsData, queryId]);

  const metricLoadingPreviousPeriod = useMemo(() => {
    return loadingPreviousPeriod || loadingMetricsData?.[metric.key]?.previous;
  }, [loadingPreviousPeriod, loadingMetricsData, metric.key]);

  const createChartFromBreakdownValues = useCallback(() => {
    const chart = breakDownValue?.map((x, i) => {
      return {
        x: x.date,
        y: +x.value,
        z: previousPeriodBreakDownValue ? +previousPeriodBreakDownValue?.[i]?.value : undefined,
      };
    });
    const pointsWithValues = chart?.filter((point) => point.y !== 0 || point.z !== 0);
    if (pointsWithValues?.length === 1 && pointsWithValues[0].x === '0') {
      return pointsWithValues;
    }
    return chart;
  }, [breakDownValue, previousPeriodBreakDownValue]);

  const chart = useMemo(() => {
    if (breakDownValue?.length === 0) {
      return undefined;
    }
    return createChartFromBreakdownValues();
  }, [breakDownValue, createChartFromBreakdownValues]);

  const totalValueIsNa = useMemo(() => {
    if (!totalValue) {
      return true;
    }

    const [value] = totalValue;

    if (value === null || value === undefined) {
      return true;
    }

    return false;
  }, [totalValue]);

  const previousPeriodTotalValueIsNa = useMemo(() => {
    if (!previousPeriodTotalValue) {
      return true;
    }

    const [value] = previousPeriodTotalValue;

    if (value === null || value === undefined) {
      return true;
    }

    return false;
  }, [previousPeriodTotalValue]);

  const v = useMemo(() => {
    if (totalValueIsNa) {
      return '-';
    }
    const valueStr =
      totalValue !== undefined
        ? formatNumber(+totalValue[0], {
            style: metric.format || 'decimal',
            currency,
            maximumFractionDigits: metric.toFixed ?? 2,
            minimumFractionDigits: metric.minimumFractionDigits || 0,
          })
        : '-';

    return valueStr;
  }, [totalValue, metric, currency, totalValueIsNa]);

  const previousV = useMemo(() => {
    if (previousPeriodTotalValueIsNa) {
      return '-';
    }
    const valueStr =
      previousPeriodTotalValue !== undefined
        ? formatNumber(+previousPeriodTotalValue[0], {
            style: metric.format || 'decimal',
            currency,
            maximumFractionDigits: metric.toFixed ?? 2,
            minimumFractionDigits: metric.minimumFractionDigits || 0,
          })
        : undefined;

    return valueStr;
  }, [previousPeriodTotalValue, metric, currency, previousPeriodTotalValueIsNa]);

  const { attributes, listeners, setNodeRef, transform, transition, isDragging } = useSortable({
    id: metric.key,
  });

  return tileMode === 'table' ? (
    <div
      style={{
        borderTop: !!doDarkMode ? '1px solid rgba(250,250,250,.1)' : '1px solid rgba(1,1,1,.1)',
      }}
      className={`group grid grid-cols-3 gap-8 items-center cursor-pointer p-4`}
    >
      <div className="flex gap-3 items-center">
        <div
          {...listeners}
          {...attributes}
          className="hidden sm:flex text-logo w-6 h-6 mt-[-2px] cursor-grab transition-opacity"
        >
          <DragHandle />
        </div>
        {!!metric.icon && (
          <IconPicker
            selectedIcon={metric.icon}
            onChange={async (icon) => {
              const newMetrics = metrics.map((m) => {
                return {
                  ...m,
                  icon: m.key === metric?.key ? icon : m.icon,
                };
              });
              metricsChanged(queryId, newMetrics);
            }}
            activator={
              <div className="flex w-6 h-auto">
                {visibleLength(metric.icon) > 1 ? (
                  <Icon name={metric.icon as IconName} />
                ) : (
                  metric.icon
                )}
              </div>
            }
          />
        )}
        <div
          contentEditable={!disableContentEditable}
          suppressContentEditableWarning
          className="font-medium text-xl sm:text-2xl whitespace-nowrap truncate"
          onBlur={(e) => {
            const newMetrics = metrics.map((m) => {
              return {
                ...m,
                name: m.key === metric?.key ? e.currentTarget.innerText : m.name,
              };
            });
            metricsChanged(queryId, newMetrics);
          }}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              e.preventDefault();
              e.currentTarget.blur();
            }
          }}
        >
          {metric?.name}
        </div>
        {!!errorInMetric && !loading && (
          <div className="flex w-6 flex-shrink-0">
            <Tooltip content={errorInMetric}>
              <div className="text-red-500">⚠️</div>
            </Tooltip>
          </div>
        )}
      </div>

      <div className="justify-self-end">
        <div className="flex justify-between w-full relative">
          {metric.isBlocked ? (
            <LockedFeatureIndicator
              featureFlag={FeatureFlag.LIMIT_METRICS_FF}
              iconSize={26}
              iconOnly
              upgradeButton={false}
              border={false}
            />
          ) : (
            <div className="font-medium text-xl sm:text-2xl whitespace-nowrap dark:text-gray-100">
              {metricLoading && <Skeleton width={50} height={25} />}
              {!metricLoading && (
                <div
                  className="cursor-pointer"
                  onClick={() => {
                    onMetricClicked(metric);
                  }}
                >
                  {v}
                </div>
              )}
            </div>
          )}
        </div>
      </div>

      <div className="justify-self-end flex gap-4 items-center">
        {previousV !== undefined && metric.isBlocked !== true && (
          <div
            className={`flex rounded-md px-2 text-base items-center gap-2 dark:font-bold ${
              metricLoading
                ? ''
                : deltaDirection && deltaDirection <= 0
                  ? 'text-green bg-green/16'
                  : deltaPercentage && deltaPercentage > 0
                    ? 'bg-primary/16 text-primary'
                    : ' text-dark-red bg-dark-red/16'
            }`}
          >
            <WillyComparisonValue
              deltaDirection={deltaDirection}
              deltaPercentage={deltaPercentage}
              previousValue={previousV}
              loading={metricLoadingPreviousPeriod}
            />
          </div>
        )}
        <div className="hidden sm:block flex-auto flex-shrink-0 ml-auto w-20">
          {chart && metricLoading && metric.isBlocked !== true && (
            <div>
              <NewSummaryChart
                className="flex-auto"
                data={[] as any}
                color={'#10F1A7'}
                gradientColor={'#1877F2'}
                height={25}
              />
            </div>
          )}
          {chart && !metricLoading && metric.isBlocked !== true && (
            <div
              onClick={() => {
                onMetricClicked(metric);
              }}
            >
              <NewSummaryChart
                className="flex-auto"
                data={chart as any}
                color={'#10F1A7'}
                gradientColor={'#1877F2'}
                height={25}
              />
            </div>
          )}
          {metric.isBlocked ? (
            <LockedFeatureIndicator
              featureFlag={FeatureFlag.LIMIT_METRICS_FF}
              iconSize={26}
              iconOnly
              upgradeButton={false}
              border={false}
            />
          ) : null}
        </div>
      </div>
    </div>
  ) : (
    <>
      <div className="flex items-center gap-2 sm:gap-4">
        <div className="flex items-center gap-2 sm:gap-4 flex-auto max-w-full">
          <div className="flex items-center gap-2 not-prose">
            {/* {servicesTemplate} */}
            {!!errorInMetric && !loading && (
              <div className="flex w-6 flex-shrink-0">
                <Tooltip content={errorInMetric}>
                  <div className="text-red-500">⚠️</div>
                </Tooltip>
              </div>
            )}
            {!!metric.icon && (
              <IconPicker
                activator={
                  visibleLength(metric.icon) > 1 ? (
                    <Icon name={metric.icon as IconName} />
                  ) : (
                    metric.icon
                  )
                }
                selectedIcon={metric.icon}
                onChange={async (icon) => {
                  const newMetrics = metrics.map((m) => {
                    return {
                      ...m,
                      icon: m.key === metric?.key ? icon : m.icon,
                    };
                  });
                  metricsChanged(queryId, newMetrics);
                }}
              />
            )}
          </div>
          <div
            contentEditable={!disableContentEditable}
            suppressContentEditableWarning
            className="text-secondary-text dark:text-gray-100 font-medium text-xl sm:text-xl truncate"
            onBlur={(e) => {
              const newMetrics = metrics.map((m) => {
                return {
                  ...m,
                  name: m.key === metric?.key ? e.currentTarget.innerText : m.name,
                };
              });
              metricsChanged(queryId, newMetrics);
            }}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                e.preventDefault();
                e.currentTarget.blur();
              }
            }}
          >
            {metric?.name}
          </div>
          {previousV !== undefined && metric.isBlocked !== true && (
            <WillyComparisonValue
              deltaDirection={deltaDirection}
              deltaPercentage={deltaPercentage}
              previousValue={previousV}
              loading={metricLoadingPreviousPeriod}
            />
          )}
        </div>
        <div className="absolute right-6.5 flex items-center gap-2 bg-white opacity-0 group-hover/tile:opacity-100 transition-opacity">
          {!!dashboardId && isPinnable && (
            <div
              className="flex group-hover:opacity-100 transition-opacity cursor-pointer"
              onClick={async () => {
                const batch = firestoreRef().batch();
                const dashboardRef = getCustomViewDashboardCollectionRef().doc(dashboardId);

                if (pinnedMetricKeys?.includes(metric.key)) {
                  batch.set(
                    dashboardRef,
                    {
                      pinnedMetricKeys: FieldValue.arrayRemove(metric.key),
                    },
                    {
                      merge: true,
                    },
                  );

                  batch.set(
                    dashboardRef.collection('widgets').doc(PINNED_SECTION_ID),
                    {
                      dataMetricIds: FieldValue.arrayRemove(metric.key),
                      metrics: metrics.filter((m) => m.key !== metric.key),
                    },
                    {
                      merge: true,
                    },
                  );

                  await batch.commit();
                  return;
                }

                batch.set(
                  dashboardRef,
                  {
                    pinnedMetricKeys: FieldValue.arrayUnion(metric.key),
                  },
                  {
                    merge: true,
                  },
                );

                batch.set(
                  dashboardRef.collection('widgets').doc(PINNED_SECTION_ID),
                  {
                    dataMetricIds: FieldValue.arrayUnion(metric.key),
                    metrics: FieldValue.arrayUnion(metric),
                  },
                  {
                    merge: true,
                  },
                );

                await batch.commit();
              }}
            >
              <Icon
                name="pin-1"
                width={12}
                color={pinnedMetricKeys?.includes(metric.key) ? 'one.6' : 'gray.4'}
                height={12}
              />
            </div>
          )}
          {isEditEnable && metric.isBlocked !== true && (
            <EditMajor
              className="fill-logo w-6 cursor-pointer flex"
              onClick={() => {
                if (setEditMetricModalOpen) {
                  setEditMetricModalOpen({ open: true, metricId: metric?.key });
                }
              }}
            />
          )}
          {isFiltered && (
            <FilterMajor
              className="fill-logo w-6 cursor-pointer flex"
              onClick={() => {
                if (setFilterMetricModalOpen) {
                  setFilterMetricModalOpen({ open: true, metricId: metric?.key });
                }
              }}
            />
          )}
          <WillyDescriptionTooltip metric={metric} />
          <div
            {...listeners}
            {...attributes}
            className="text-logo flex w-6 h-6 mt-[-2px] cursor-grab opacity-0 transition-opacity group-hover/tile:opacity-100"
          >
            <DragHandle />
          </div>
        </div>
      </div>

      {metric.isBlocked ? (
        <LockedFeatureIndicator
          featureFlag={FeatureFlag.LIMIT_METRICS_FF}
          iconSize={26}
          layout="vertical"
          upgradeButton={false}
          border={false}
        />
      ) : (
        <div
          className={`flex sm:flex-col ${
            twoColumnMobile ? 'flex-wrap' : 'sm:flex-wrap'
          } justify-between items-center sm:gap-5 max-w-full overflow-hidden`}
        >
          <div className="flex justify-between w-full relative">
            <div
              className="font-semibold text-4xl sm:text-4/5xl dark:text-gray-100"
              style={{ minWidth: `${v?.length ?? 10 - 1}ch` }}
            >
              {metricLoading && <Skeleton width={50} height={25} />}
              {!metricLoading && (
                <div
                  className="cursor-pointer"
                  onClick={() => {
                    onMetricClicked(metric);
                  }}
                >
                  {v}
                </div>
              )}
            </div>
          </div>
          <div
            className={`block flex-auto flex-shrink-0 ml-auto cursor-pointer min-w-[50%] w-auto sm:w-full min-h-16 pb-5 ${
              twoColumnMobile ? 'ml-0 mt-3 sm:mt-0' : 'ml-auto'
            }`}
            onClick={() => {
              onMetricClicked(metric);
            }}
          >
            {metricLoading && (
              <NewSummaryChart
                // showGraphTooltip
                className="flex-auto"
                data={dummyData}
                color={'var(--nsbChartLineLoadingColor)'}
                gradientColor={'var(--nsbChartLineLoadingColor)'}
                // type={chartType}
                height={40}
                // onValuesChange={(props) => { }}
              />
            )}
            {chart && !metricLoading && (
              <NewSummaryChart
                showGraphTooltip
                isDashboardTile
                className="flex-auto"
                data={chart as any}
                color={'#10F1A7'}
                gradientColor={'#1877F2'}
                // type={chartType}
                height={40}
                metric={metric}
                // onValuesChange={(props) => { }}
              />
            )}
          </div>
        </div>
      )}
    </>
  );
};

export const WillyCard: React.FC<SingleTileProps> = (props) => {
  const {
    loading,
    loadingPreviousPeriod,
    rawData,
    previousPeriodRawData,
    metrics,
    metric,
    serviceIds,
    onMetricClicked,
    metricsChanged,
    setEditMetricModalOpen,
  } = props;

  console.log('metric', metric);

  return <div className="flex items-center gap-4">Hey</div>;
};
