import QueryString from 'qs';
import { $derived, $mutableDerived, $observer, $store, ReadableStore } from '@tw/snipestate';
import { Dialect, Shop } from '@tw/types';
import { isInIframe, isProduction } from 'config';
import { $history } from './$history';
import { $redux } from './$redux';
import { $dialect, $userId } from './$user';
import _db, { userDb } from 'utils/DB';
import { $globalConfig } from './$globalConfig';
import Cookies from 'utils/Cookies';

const getInitialData = () => {
  const search = new URLSearchParams(location.search);
  const shopDomain = search.get('shop-domain') || search.get('shop-id') || null;
  let initialData = shopDomain ?? Cookies.get('currentShopId');

  if (isInIframe) {
    const idParam = new URLSearchParams(window.location.search).get('id');
    initialData = idParam?.length ? idParam : null;
  }

  return initialData;
};

/** Only use as a readable store, because to properly set this, other things need to be done */
export const $currentShopId: ReadableStore<string | null> = $store(getInitialData());

export async function set_$currentShopId(shopId: string | null) {
  //@ts-ignore
  $currentShopId.set(shopId);

  if (!shopId) {
    console.log('clearing current shop id');
    Cookies.remove('currentShopId');
  } else {
    Cookies.set('currentShopId', shopId + '');
  }
  // userDb depends on user existing in state.
  // want to try replacing using userDb with $user store and having db
  // track all side effects
  if ($userId.get()) {
    await userDb().update({ activeAccounts: [] });
  }
}

const ssrShopData: Partial<Shop> | undefined = window.twShop;

export const $shop = $observer<
  Partial<
    Shop & {
      useClickhouse: boolean;
      summaryWilly: boolean;
      dialect: Dialect;
      forceBQPixelPage: boolean;
    }
  >
>(ssrShopData || { shopName: '', subscription: {} }, async (get, set) => {
  const userId = get($userId);
  const currentShopId = get($currentShopId);
  if (!currentShopId || !userId) return;

  const history = get($history);

  const prevQuery = QueryString.parse(location.search, { ignoreQueryPrefix: true });
  history.push?.({
    pathname: location.pathname,
    search: QueryString.stringify({ 'shop-id': currentShopId, ...prevQuery }),
  });
  // continue to listen for updates
  return _db(currentShopId).onSnapshot((shopDataSnapshot) => {
    set(shopDataSnapshot.data() || get());
  });
});

export const $isSaas = $derived((get) => get($shop).msp === 'stripe');
export const $msp = $derived((get) => get($shop).msp);
export const $mspConnected = $derived((get) => !!get($shop).mspConnected);
export const $timezone = $derived((get) => get($shop).timezone);
export const $industry = $derived((get) => get($shop).industry);

export const $isBetaShop = $derived(
  (get) =>
    !!get($shop)['isBetaShop'] || !isProduction || window.location.search?.includes('beta=1'),
);

const $shopSwitcherAccountList = $derived((get) => get($redux)?.shopSwitcherAccountList || null);
export const $activeAccounts = $derived((get) => {
  const currentShopId = get($currentShopId);
  const userId = get($userId);
  if (!currentShopId || !userId) return null;

  const shopSwitcherAccountList = get($shopSwitcherAccountList) || [];
  const savedShops = [...new Set([...shopSwitcherAccountList, currentShopId])];
  return savedShops;
});

export const $shopClickHouseInSummary = $derived((get) => !!get($shop).useClickhouse);
export const $shopSummaryWillyWay = $derived((get) => !!get($shop).summaryWilly);
export const $useSummaryWillyWay = $mutableDerived((get) => {
  const shopSummaryWillyWay = get($shopSummaryWillyWay);
  const globalConfig = get($globalConfig);
  const dialect = get($dialect);
  if (globalConfig.find((x) => x.id === 'willyWay')?.state) {
    return true;
  } else {
    return shopSummaryWillyWay ?? false;
  }
});

export const $forceBQPixelPage = $derived((get) => !!get($shop).forceBQPixelPage);
export const $shopPixelWillyWay = $derived((get) => !!get($shop).summaryWilly);
export const $usePixelWillyWay = $mutableDerived((get) => {
  const shopPixelWillyWay = get($shopPixelWillyWay);
  const globalConfig = get($globalConfig);
  const dialect = get($dialect);
  const forceBQPixelPage = get($forceBQPixelPage);

  if (forceBQPixelPage) return false;

  if (globalConfig.find((x) => x.id === 'willyWay')?.state || dialect === 'clickhouse') {
    // if (globalConfig.find((x) => x.id === 'willyWay')?.state) {
    return true;
  } else {
    return shopPixelWillyWay ?? false;
  }
});

export const $useClickHouseInSummary = $mutableDerived((get) => {
  const shopClickHouseInSummary = get($shopClickHouseInSummary);
  const globalConfig = get($globalConfig);
  if (globalConfig.find((x) => x.id === 'adsOnClickhouse')?.state) {
    return true;
  } else {
    return shopClickHouseInSummary ?? false;
  }
});
