import { useEffect, useRef } from 'react';

/**
 * use like this
 * ``` ts
 * useTraceUpdate({ mainDatePickerSelectionRange, currentShopId, currency, /* ... *\/ });
 * ```
 */
export function useTraceUpdate(props) {
  const prev = useRef(props);
  useEffect(() => {
    const changedProps = Object.entries(props).reduce((ps, [k, v]) => {
      if (prev.current[k] !== v) {
        ps[k] = [prev.current[k], v];
      }
      return ps;
    }, {});
    if (Object.keys(changedProps).length > 0) {
      console.trace('Changed props:', changedProps);
    }
    prev.current = props;
  });
}
