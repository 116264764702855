import 'firebase/compat/firestore';

import firebase from 'firebase/compat/app';
import { type RootState } from 'reducers/RootType';
import { combineReducers } from 'redux';
import { Stripe } from 'stripe';
import { userDb } from 'utils/DB';

import {
  subscriptionPlan,
  subscriptionTypes,
} from '@tw/types/module/services/subscription-manager';

import { SignUpStep } from '../routes/auth/SignUp/signUpStep.enum';
import axiosInstance from '../utils/axiosInstance';
import Cookies from '../utils/Cookies';
import { GET_CURRENT_USER } from './constants';
import { SalesPlatform } from '@tw/types/module/services';

const firestore = firebase.firestore;
const PAYMENT_ID_SESSION_KEY = 'PAYMENT_ID_SESSION_KEY';
const SELECT_AGENCY_STORES_PLANS_SESSION_KEY = 'SELECT_AGENCY_STORES_PLANS_SESSION_KEY';
const SELECT_REVENUE_SESSION_KEY = 'SELECT_REVENUE_SESSION_KEY';
export const SHOPIFY_APP_INSTALLED_SHOP_ID_KEY = 'SHOPIFY_APP_INSTALLED_SHOP_ID_KEY';
const SELECT_SALES_PLATFORM_SESSION_KEY = 'SELECT_SALES_PLATFORM_SESSION_KEY';
const SELECT_PLANS_SESSION_KEY = 'SELECT_PLANS_SESSION_KEY';
const STORE_URL_SESSION_KEY = 'STORE_URL_SESSION_KEY';
export type ACCOUNT_TYPE = 'AGENCY' | 'BRAND';
export type ACCOUNT_TYPE_OBJECT = {
  accountType: ACCOUNT_TYPE;
  agencyUrl?: string;
  agencyName?: string;
  numOfStores?: number;
};

export type PERSONAL_DETAILS = {
  firstName: string;
  lastName: string;
  phone: string;
  password?: string;
  confirmPassword?: string;
};

const INCREMENT_INDEX = 'INCREMENT_INDEX';

export const incrementIndex = (steps = 1) => ({
  type: INCREMENT_INDEX,
  payload: steps,
});

const STRIPE_CONFIG_LOADED = 'STRIPE_CONFIG_LOADED';

export const loadStripeConfig = () => async (dispatch) => {
  const url = `v2/subscription-manager/management/stripePublisherKey`;
  const key = await axiosInstance.get(url, {});
  // const key = 'pk_live_Acye27mpfekyrQl1tQzHiPES00tFwrZmdj'
  dispatch(stripeConfigLoaded(key));
};

const IS_FIRST_SHOP = 'IS_FIRST_SHOP';

export const setIsFirstShopAvailable = (val) => ({
  type: IS_FIRST_SHOP,
  payload: val,
});

const isFirstShopAvailable = (state = false, action) => {
  switch (action.type) {
    case IS_FIRST_SHOP:
      return action.payload;
    default:
      return state;
  }
};

const SET_SHOP = 'SET_SHOP';

export const setShop = (val) => ({
  type: SET_SHOP,
  payload: val,
});

const shop = (state = '', action) => {
  switch (action.type) {
    case SET_SHOP:
      return action.payload;
    default:
      return state;
  }
};

const UPDATE_INDEX = 'UPDATE_INDEX';

export const updateIndex = (val) => ({
  type: 'UPDATE_INDEX',
  payload: val,
});

const PAYMENT_LINK_SESSIONID = 'PAYMENT_LINK_SESSIONID';
export const setPaymentLinkSessionId = (val) => ({
  type: PAYMENT_LINK_SESSIONID,
  payload: val,
});

const SET_IS_FROM_POD_VIEW = 'SET_IS_FROM_POD_VIEW';
export const setIsFromPodView = (val) => ({
  type: SET_IS_FROM_POD_VIEW,
  payload: val,
});

const SET_IS_AGENCY_POD = 'SET_IS_AGENCY_POD';
export const setIsAgencyPod = (val) => ({
  type: SET_IS_AGENCY_POD,
  payload: val,
});

const SET_ADD_TO_WORKSPACE_INFO = 'SET_ADD_TO_WORKSPACE_INFO';
export const setAddToWorkspaceInfo = (val) => ({
  type: SET_ADD_TO_WORKSPACE_INFO,
  payload: val,
});

const SET_SELECTED_PRODUCTS = 'SET_SELECTED_PRODUCTS';
export const setSelectedProducts = (val) => ({
  type: SET_SELECTED_PRODUCTS,
  payload: val,
});

const SET_SHOPIFY_APP_INSTALLED_SHOP_ID = 'SET_SHOPIFY_APP_INSTALLED_SHOP_ID';
export const setShopifyAppInstalledShopId = (val) => {
  if (val) {
    localStorage.setItem(SHOPIFY_APP_INSTALLED_SHOP_ID_KEY, val);
  } else {
    localStorage.removeItem(SHOPIFY_APP_INSTALLED_SHOP_ID_KEY);
  }
  return {
    type: SET_SHOPIFY_APP_INSTALLED_SHOP_ID,
    payload: val,
  };
};

const SET_FROM_UPGRADE_CLICK = 'SET_FROM_UPGRADE_CLICK';
export const setIsFromUpgradeClick = (val) => ({
  type: SET_FROM_UPGRADE_CLICK,
  payload: val,
});

const SET_FREE_SHOP_REVENUE = 'SET_FREE_SHOP_REVENUE';
export const setFreeShopRevenue = (val) => ({
  type: SET_FREE_SHOP_REVENUE,
  payload: val,
});

export const stripeConfigLoaded = (data) => ({
  type: STRIPE_CONFIG_LOADED,
  payload: data,
});
const STRIPE_PM = 'STRIPE_PM';
export const setPaymentMethod = (data: Stripe.PaymentMethod) => ({
  type: STRIPE_PM,
  payload: data,
});

const stripePaymentMethod = (state = null, action) => {
  switch (action.type) {
    case STRIPE_PM:
      return action.payload;
    default:
      return state;
  }
};

const isFromPodView = (state = false, action) => {
  switch (action.type) {
    case SET_IS_FROM_POD_VIEW:
      return action.payload;
    default:
      return state;
  }
};

const isAgencyPod = (state = false, action) => {
  switch (action.type) {
    case SET_IS_AGENCY_POD:
      return action.payload;
    default:
      return state;
  }
};

const selectedProducts = (state = null, action) => {
  switch (action.type) {
    case SET_SELECTED_PRODUCTS:
      return action.payload;
    default:
      return state;
  }
};

const isFromUpgradeClick = (state = false, action) => {
  switch (action.type) {
    case SET_FROM_UPGRADE_CLICK:
      return action.payload;
    default:
      return state;
  }
};

const shopifyAppInstalledShopId = (
  state = localStorage.getItem(SHOPIFY_APP_INSTALLED_SHOP_ID_KEY) ?? '',
  action,
) => {
  switch (action.type) {
    case SET_SHOPIFY_APP_INSTALLED_SHOP_ID:
      return action.payload;
    default:
      return state;
  }
};

const freeShopRevenue = (state = 0, action) => {
  switch (action.type) {
    case SET_FREE_SHOP_REVENUE:
      return action.payload;
    default:
      return state;
  }
};

const addToWorkspaceInfo = (state = {}, action) => {
  switch (action.type) {
    case SET_ADD_TO_WORKSPACE_INFO:
      return action.payload;
    default:
      return state;
  }
};

const index = (state = SignUpStep.Email, action) => {
  switch (action.type) {
    case INCREMENT_INDEX:
      return state + action.payload;
    case UPDATE_INDEX:
      return action.payload;
    default:
      return state;
  }
};

const SET_PRICES = 'SET_PRICES';
export const selectPrices = (pricesIds: string[]) => ({
  type: SET_PRICES,
  payload: pricesIds,
});

const prices = (state = [], action) => {
  switch (action.type) {
    case SET_PRICES:
      return action.payload;
    default:
      return state;
  }
};

const paymentLink = (state = null, action) => {
  switch (action.type) {
    case PAYMENT_LINK_SESSIONID:
      return action.payload;
    default:
      return state;
  }
};

const stripePublisherKey = (state = null, action) => {
  switch (action.type) {
    case STRIPE_CONFIG_LOADED:
      return action.payload;
    default:
      return state;
  }
};

const isEmailSubscribe = (state = true, action) => {
  switch (action.type) {
    case 'SET_EMAIL':
      return action.payload.isEmailSubscribe || state;
    default:
      return state;
  }
};

const UPDATE_ACCOUNT_TYPE = 'UPDATE_ACCOUNT_TYPE';
export const updateAccountType = (accType: ACCOUNT_TYPE_OBJECT) => ({
  type: UPDATE_ACCOUNT_TYPE,
  payload: accType,
});

const UPDATE_PERSONAL_DETAILS = 'UPDATE_PERSONAL_DETAILS';
export const updatePersonalDetails = (personalDetails: PERSONAL_DETAILS) => {
  return async (dispatch, getState: () => RootState) => {
    let { accountType, isEmailSubscribe, clientSecret } = getState().signupJourney;
    if (accountType.accountType === 'BRAND') {
      accountType = { accountType: accountType.accountType };
    }
    const details = {
      ...accountType,
      ...personalDetails,
      isEmailSubscribe,
      isCreatedFromSignUp: true,
      isCreatedFromInvitation: false,
      clientSecret,
    };
    await userDb().set(JSON.parse(JSON.stringify(details)), { merge: true }); // remove undefined
    dispatch({
      type: UPDATE_PERSONAL_DETAILS,
      payload: personalDetails,
    });
  };
};

const SET_HUBSPOT_CONTACT_ID = 'SET_HUBSPOT_CONTACT_ID';
export const setHubspotContactId = (id: string) => {
  return async (dispatch) => {
    try {
      // await userDb().set({ hubspot_contact_id: id }, { merge: true });
      dispatch({
        type: SET_HUBSPOT_CONTACT_ID,
        payload: id,
      });
    } catch (error) {
      console.log(error);
    }
  };
};

const SET_HUBSPOT_COMPANY_ID = 'SET_HUBSPOT_COMPANY_ID';
export const setHubspotCompanyId = (id: string) => {
  return async (dispatch, getState: () => RootState) => {
    let { shop } = getState().signupJourney;
    await firestore()
      .collection('shops')
      .doc(shop)
      .set({ hubspot_company_id: id }, { merge: true });
    dispatch({
      type: SET_HUBSPOT_COMPANY_ID,
      payload: id,
    });
  };
};

const SET_SUB_TYPE = 'SET_SUB_TYPE';
export const setSubType = (subType: subscriptionTypes) => ({
  type: SET_SUB_TYPE,
  payload: subType,
});

const SELECT_SALES_PLATFORM = 'SELECT_SALES_PLATFORM';
export const selectSalesPlatform = (salesPlatform: SalesPlatform) => (dispatch) => {
  sessionStorage.setItem(SELECT_SALES_PLATFORM_SESSION_KEY, salesPlatform);
  dispatch({
    type: SELECT_SALES_PLATFORM,
    payload: salesPlatform,
  });
};

const SELECT_PLANS = 'SELECT_PLANS';
export const selectPlans = (plans: subscriptionPlan[]) => (dispatch) => {
  sessionStorage.setItem(SELECT_PLANS_SESSION_KEY, JSON.stringify(plans));
  dispatch({
    type: SELECT_PLANS,
    payload: plans,
  });
};

const selectedSalesPlatform = (
  state = sessionStorage.getItem(SELECT_SALES_PLATFORM_SESSION_KEY) ?? 'shopify',
  action,
) => {
  switch (action.type) {
    case SELECT_SALES_PLATFORM:
      return action.payload;
    default:
      return state;
  }
};

const selectedPlans = (
  state = JSON.parse(sessionStorage.getItem(SELECT_PLANS_SESSION_KEY) ?? '[]'),
  action,
) => {
  switch (action.type) {
    case SELECT_PLANS:
      return action.payload;
    default:
      return state;
  }
};
const SELECT_REVENUE = 'SELECT_REVENUE';
export const selectRevenue = (revenue) => (dispatch) => {
  sessionStorage.setItem(SELECT_REVENUE_SESSION_KEY, JSON.stringify(revenue));
  dispatch({
    type: SELECT_REVENUE,
    payload: revenue,
  });
};

const SELECT_AGENCY_STORES_PLANS = 'SELECT_AGENCY_STORES_PLANS';
export const selectAgencyStoresPlans = (stores) => (dispatch) => {
  sessionStorage.setItem(SELECT_AGENCY_STORES_PLANS_SESSION_KEY, JSON.stringify(stores));
  dispatch({
    type: SELECT_AGENCY_STORES_PLANS,
    payload: stores,
  });
};

const selectedAgencyStoresPlans = (
  state = JSON.parse(sessionStorage.getItem(SELECT_AGENCY_STORES_PLANS_SESSION_KEY) || '[]'),
  action,
) => {
  switch (action.type) {
    case SELECT_AGENCY_STORES_PLANS:
      return action.payload;
    default:
      return state;
  }
};

const SET_UTMS = 'SET_UTMS';
export const setUTMs = (utms) => ({
  type: SET_UTMS,
  payload: utms,
});

const utms = (state = false, action) => {
  switch (action.type) {
    case SET_UTMS:
      return action.payload;
    default:
      return state;
  }
};

const SET_SHOP_TO_REACTIVATE = 'SET_SHOP_TO_REACTIVATE';
export const setShopToReactivate = (shopId) => {
  return (dispatch) => {
    dispatch({
      type: SET_SHOP_TO_REACTIVATE,
      payload: shopId,
    });
  };
};

const shopToReactivate = (state = '', action) => {
  switch (action.type) {
    case SET_SHOP_TO_REACTIVATE:
      return action.payload;
    default:
      return state;
  }
};

const selectedRevenue = (
  state = sessionStorage.getItem(SELECT_REVENUE_SESSION_KEY)
    ? JSON.parse(sessionStorage.getItem(SELECT_REVENUE_SESSION_KEY) ?? '{}')
    : '',
  action,
) => {
  switch (action.type) {
    case SELECT_REVENUE:
      return action.payload;
    default:
      return state;
  }
};

const SET_PAYMENT_METHOD_ID = 'SET_PAYMENT_METHODE_ID';
export const setPaymentMethodId = (paymentId: string) => (dispatch) => {
  sessionStorage.setItem(PAYMENT_ID_SESSION_KEY, paymentId);
  dispatch({
    type: SET_PAYMENT_METHOD_ID,
    payload: paymentId,
  });
};

const paymentMethodId = (state = sessionStorage.getItem(PAYMENT_ID_SESSION_KEY) || '', action) => {
  switch (action.type) {
    case SET_PAYMENT_METHOD_ID:
      return action.payload;
    default:
      return state;
  }
};

const SET_AGENCY_LINK_EXIST = 'SET_AGENCY_LINK_EXIST';
export const setAgencyLinkExist = (exist: boolean) => ({
  type: SET_AGENCY_LINK_EXIST,
  payload: exist,
});

const isAgencyLinkExist = (state = false, action) => {
  switch (action.type) {
    case SET_AGENCY_LINK_EXIST:
      return action.payload;
    case GET_CURRENT_USER:
      const { agencyLinkExist } = action.user;
      return agencyLinkExist || state;
    default:
      return state;
  }
};

const SET_PROMO_CODE_ID = 'SET_PROMO_CODE_ID';
export const setPromoCodeId = (promoCodeId: string) => ({
  type: SET_PROMO_CODE_ID,
  payload: promoCodeId,
});

const SET_TRIPLE_UTM = 'SET_TRIPLE_UTM';
export const setTripleUTM = async (tripleUtm: string) => {
  return {
    type: SET_TRIPLE_UTM,
    payload: tripleUtm,
  };
};

const promoCodeId = (state = '', action) => {
  switch (action.type) {
    case SET_PROMO_CODE_ID:
      return 'promo_' + action.payload;
    default:
      return state;
  }
};

const tripleUtm = (state = '', action) => {
  switch (action.type) {
    case SET_TRIPLE_UTM:
      return action.payload;
    case GET_CURRENT_USER:
      const { signupTriplePixelUTM } = action.user;
      return signupTriplePixelUTM || state;
    default:
      return state;
  }
};

export const setUserEmail = (emailObj: { email: string; isEmailSubscribe?: boolean }) => ({
  type: 'SET_EMAIL',
  payload: emailObj,
});

const email = (state = '', action) => {
  switch (action.type) {
    case 'SET_EMAIL':
      return action.payload.email;
    case GET_CURRENT_USER: {
      const { email } = action.user;
      return email;
    }
    default:
      return state;
  }
};

export const setErrorMessage = (error: string) => ({
  type: VALIDATION_ERROR_MESSAGE,
  payload: error,
});

const VALIDATION_ERROR_MESSAGE = 'VALIDATION_ERROR_MESSAGE';
const validationErrorMessage = (state = '', action) => {
  switch (action.type) {
    case VALIDATION_ERROR_MESSAGE:
      return action.payload;
    default:
      return state;
  }
};

const stripeCustomerId = (state = null, action) => {
  switch (action.type) {
    //console.log(action);
    case GET_CURRENT_USER: {
      const { stripe_customer_id } = action.user;
      return stripe_customer_id ?? state;
    }
    default:
      return state;
  }
};

const accountType = (
  state: ACCOUNT_TYPE_OBJECT = {
    accountType: 'AGENCY',
    agencyName: '',
    numOfStores: 1,
  },
  action,
) => {
  switch (action.type) {
    case UPDATE_ACCOUNT_TYPE:
      return action.payload;
    case GET_CURRENT_USER: {
      const {
        accountType: at,
        numOfStores,
        agencyUrl,
        url,
        isAgency,
        storeRun,
        createdAt,
      } = action.user;
      if (!createdAt) {
        //in the middle of creation, ignore
        return state;
      }
      return {
        accountType: at ?? (isAgency !== undefined ? (isAgency ? 'AGENCY' : 'BRAND') : 'BRAND'),
        numOfStores: numOfStores ?? storeRun ?? 1,
        agencyUrl: agencyUrl ?? url ?? 'unknown - old user',
      };
    }
    default:
      return state;
  }
};

const personalDetails = (
  state: PERSONAL_DETAILS = {
    firstName: '',
    lastName: '',
    phone: '',
    password: '',
  },
  action,
) => {
  switch (action.type) {
    case UPDATE_PERSONAL_DETAILS:
      return action.payload;
    case GET_CURRENT_USER: {
      const { firstName, lastName, phone, referrer, clientSecret } = action.user;
      return {
        firstName: firstName ?? 'unknown - old user',
        lastName: lastName ?? 'unknown - old user',
        referrer: referrer ?? 'unknown - old user',
        phone: phone ?? 'unknown - old user',
        clientSecret: clientSecret ?? '',
      };
    }
    default:
      return state;
  }
};

const hubspotContactId = (state = '', action) => {
  switch (action.type) {
    case SET_HUBSPOT_CONTACT_ID:
      return action.payload;
    case GET_CURRENT_USER: {
      const { hubspot_contact_id } = action.user;
      return { hubspot_contact_id: hubspot_contact_id ?? null };
    }
    default:
      return state;
  }
};

const hubspotCompanyId = (state = '', action) => {
  switch (action.type) {
    case SET_HUBSPOT_COMPANY_ID:
      return action.payload;
    default:
      return state;
  }
};

const subscriptionType = (state = '', action) => {
  switch (action.type) {
    case SET_SUB_TYPE: {
      return action.payload;
    }
    default:
      return state;
  }
};

const SET_STORE_URL = 'SET_STORE_URL';
export const setStoreUrl = (store) => (dispatch) => {
  sessionStorage.setItem(STORE_URL_SESSION_KEY, store);
  dispatch({
    type: SET_STORE_URL,
    payload: store,
  });
};

const storeURL = (state = sessionStorage.getItem(STORE_URL_SESSION_KEY) || '', action) => {
  switch (action.type) {
    case SET_STORE_URL: {
      return action.payload;
    }
    default:
      return state;
  }
};

const SET_IS_FREE_SIGNUP = 'SET_IS_FREE_SIGNUP';

export const setIsFreeSignup = (isFree) => (dispatch) => {
  dispatch({
    type: SET_IS_FREE_SIGNUP,
    payload: isFree,
  });
};

const isFreeSignup = (state = false, action) => {
  switch (action.type) {
    case SET_IS_FREE_SIGNUP: {
      return action.payload ?? state;
    }
    default:
      return state;
  }
};

const SET_FREE_SIGNUP_INDEX = 'SET_FREE_SIGNUP_INDEX';

export const setFreeSignupIndex = (index) => (dispatch) => {
  dispatch({
    type: SET_FREE_SIGNUP_INDEX,
    payload: index,
  });
};

const freeSignupIndex = (state = 0, action) => {
  switch (action.type) {
    case SET_FREE_SIGNUP_INDEX: {
      return action.payload ?? state;
    }
    default:
      return state;
  }
};

const SET_CLIENT_SECRET = 'SET_CLIENT_SECRET';
export const setClientSecret = (clientSecret: string) => (dispatch) => {
  dispatch({
    type: SET_CLIENT_SECRET,
    payload: clientSecret,
  });
};

const clientSecret = (state = '', action) => {
  switch (action.type) {
    case SET_CLIENT_SECRET: {
      return action.payload;
    }
    default:
      return state;
  }
};

export const reducers = combineReducers({
  index,
  email,
  isEmailSubscribe,
  prices,
  accountType,
  stripePublisherKey,
  personalDetails,
  isFirstShopAvailable,
  isAgencyLinkExist,
  shop,
  paymentLink,
  isFromPodView,
  selectedProducts,
  addToWorkspaceInfo,
  isAgencyPod,
  selectedAgencyStoresPlans,
  validationErrorMessage,
  paymentMethodId,
  tripleUtm,
  stripePaymentMethod,
  stripeCustomerId,
  selectedPlans,
  selectedSalesPlatform,
  subscriptionType,
  promoCodeId,
  selectedRevenue,
  storeURL,
  hubspotContactId,
  hubspotCompanyId,
  utms,
  shopifyAppInstalledShopId,
  isFreeSignup,
  freeSignupIndex,
  isFromUpgradeClick,
  shopToReactivate,
  clientSecret,
  freeShopRevenue,
});
