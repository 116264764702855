import React, { FC, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { type RootState } from 'reducers/RootType';
import TWTable from 'components/library/TWTable/TWTable';
import { DraggableProvider } from 'components/DraggableProvider/DraggableProvider';
import { CancelMinor, FilterMinor } from '@shopify/polaris-icons';
import { SelectableCreative } from 'types/creativeCockpit';
import { getProductsForAnalytics } from 'ducks/productAnalytics';
import { useAppDispatch } from 'index';
import _ from 'lodash';
import { orderBy } from 'lodash';
import { SortDirection } from '@tw/types';
import ProductAdsPopupHeader from './ProductAdsPopupHeader';
import { Banner, Collapsible, Icon, TextField } from '@shopify/polaris';
import SettingsButton from 'components/SettingsButton';
import { GOOGLE_SHEETS_BANNER_METADATA } from 'ducks/googleSheets';
import { useExport } from 'hooks/useExport';
import { CreativesCockpitContext } from 'components/CreativesCockpit/context';
import { productSkusStats } from 'utils/selectors';
import { CreativeColumn } from 'components/CreativesCockpit/CreativeTable/columns';
import ExportModalForm from './ExportModalForm';
import { getSettingsItems } from './exportSettingsItems';
import moment from '@tw/moment-cached';
import DropDown from 'components/ltv/DropDown';
const Handle = ProductAdsPopupHeader;
interface ProductSkusProps {
  action: React.Dispatch<React.SetStateAction<SelectableCreative | null>>;
  product: SelectableCreative;
}

const filterSkusData = (skuData: SelectableCreative[], searchTerm: string) => {
  if (searchTerm === '') return skuData;
  return skuData?.filter((sku: any) => {
    const skuName = sku?.name?.toLowerCase() || '';
    const searchTermLower = searchTerm.toLowerCase();
    return skuName.includes(searchTermLower);
  });
};

const ProductSkusPopup: FC<ProductSkusProps> = ({ action, product }) => {
  const dispatch = useAppDispatch();
  const productSkuStats = useSelector(productSkusStats) as SelectableCreative[];
  const { isGoogleSheetsConnected, isSavingGoogleCSV, googleSheetsBannerMetadata } =
    useSelector((state: RootState) => state) || {};
  const { isLoadingProductSkus, skuAdImgs, isLoadingSkuAdStats } =
    useSelector((state: RootState) => state.productAnalytics) || {};
  const currency = useSelector((state: RootState) => state.activeCurrency);
  const exportSheet = useExport();
  const [filteredSkusData, setFilteredSkusData] = useState(productSkuStats || []);
  const [sortColumnIndex, setSortColumnIndex] = useState('ItemsSoldTotal');
  const [sortOrder, setSortOrder] = useState<SortDirection>('descending');
  const [columns, setColumns] = useState<CreativeColumn[]>([]);
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [showExport, setShowExport] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [sheetsAccount, setSheetsAccount] = useState<string>('');
  const [exportTitle, setExportTitle] = useState<string>('Export CSV');
  const [fileName, setFileName] = useState<string>('');
  const [viewBy, setViewBy] = useState<'sku' | 'variant'>('variant');

  const { text, link, bannerTitle, showBanner, status } = googleSheetsBannerMetadata;
  const { pageSize, selectedColumns, type, setCreativeInModal, setAdsModalProduct, creatives } =
    useContext(CreativesCockpitContext);

  const { start, end } = useSelector((state: RootState) => state.mainDatePickerSelectionRange) || {
    start: null,
    end: null,
  };
  const numDays = moment(end).diff(moment(start), 'days') + 1;

  const tableData = useMemo(() => {
    return filteredSkusData.map((product) => ({
      ...product,
      name: viewBy === 'sku' ? product['sku'] : product['variantName'],
    }));
  }, [filteredSkusData, viewBy]);

  useEffect(() => {
    setIsLoading(isLoadingProductSkus || isLoadingSkuAdStats);
  }, [isLoadingProductSkus, isLoadingSkuAdStats]);

  useEffect(() => {
    if (selectedColumns) {
      const productColumns = selectedColumns
        ?.map((x) => {
          if (x.key === 'showInGraph') return null;
          else
            return {
              ...x,
              sortable: true,
            };
        })
        .filter((x) => x !== null);
      setColumns(productColumns as CreativeColumn[]);
    }
  }, [selectedColumns]);

  useEffect(() => {
    if (product.id)
      dispatch(
        getProductsForAnalytics({ skuProductId: product.id, limit: 100000, itemType: 'variants' }),
      );
  }, [product.id, dispatch]);

  useEffect(() => {
    setFilteredSkusData(filterSkusData(productSkuStats, searchTerm));
  }, [searchTerm, productSkuStats]);

  useEffect(() => {
    const order = sortOrder === 'ascending' ? 'asc' : 'desc';
    setFilteredSkusData((filteredSkusData) => {
      const isMetric = sortColumnIndex !== 'product' && sortColumnIndex !== 'numberOfAds';
      const isAds = sortColumnIndex === 'numberOfAds';
      const property = isMetric ? `metrics[${sortColumnIndex}]` : isAds ? 'numberOfAds' : 'name';
      return orderBy(filteredSkusData, [property], [order]);
    });
  }, [sortColumnIndex, sortOrder]);

  const onSort = useCallback(
    (columnIndex: number, order: SortDirection) => {
      if (!isLoading) {
        const column = columns[columnIndex];
        setSortColumnIndex(column?.key);
        setSortOrder(order);
      }
    },
    [columns, isLoading],
  );

  const closeExport = useCallback(() => {
    setFileName('');
    setShowExport(false);
    dispatch({
      type: GOOGLE_SHEETS_BANNER_METADATA,
      payload: { ...googleSheetsBannerMetadata, showBanner: false },
    });
  }, [dispatch, googleSheetsBannerMetadata]);

  const onClose = useCallback(() => {
    action(null);
    setSortOrder('descending');
    setSortColumnIndex('ItemsSoldTotal');
    closeExport();
  }, [action, closeExport]);

  if (!productSkuStats) return null;

  const exportCsvParams = {
    data: filteredSkusData,
    isExportCsv: exportTitle?.includes('CSV'),
    columns,
    fileName,
    sheetsAccount,
  };

  return (
    <DraggableProvider
      usingDragger={true}
      initialPosition="center 100"
      className="draggable-card scroll-smooth bg-[#11283E]/95 
                 shadow-slate-500 rounded-lg shadow-2xl 
                 max-h-[calc(80vh-50px)] max-w-[calc(80vw-50px)] overflow-auto"
    >
      <CancelMinor
        className={`absolute right-0 top-0 mt-2 mr-2 w-12 h-12 fill-[#C0D6EA] 
                    cursor-pointer p-1 rounded-md outline outline-1 outline-light-blue/10 
                    hover:outline-light-blue/20 hover:bg-[#c0d6ea]/5 
                    hover:shadow-[1px_6px_9px_0_rgba(0,0,0,0.13)]`}
        onClick={onClose}
      />
      <div className="flex items-end justify-between">
        <div className="w-3/4 pr-4">
          <Handle name={product?.name || product.id} thumbnail={product?.thumbnail} />
        </div>
        <div className="pb-4 flex gap-4 pr-4">
          <DropDown
            options={[
              { value: 'variant', label: 'Variant' },
              { value: 'sku', label: 'SKU' },
            ]}
            handleSelect={setViewBy}
            value={viewBy}
          ></DropDown>
          <TextField
            clearButton
            onClearButtonClick={() => setSearchTerm('')}
            value={searchTerm}
            onChange={(searchTerm) => setSearchTerm(searchTerm)}
            label="Filter"
            labelHidden
            placeholder={`Filter by SKU`}
            autoComplete="off"
            prefix={<Icon source={FilterMinor} />}
          />

          <SettingsButton
            items={getSettingsItems({
              setExportTitle,
              setShowExport,
              isGoogleSheetsConnected,
            })}
          />
        </div>
      </div>
      <div
        id="att-product-analytics-product-skus-popup"
        className="flex flex-col sm:rounded-[8px] text-white w-full overflow-scroll draggable-card pb-4 px-4"
      >
        <Collapsible open={showExport} id="export" transition={{ timingFunction: 'ease-in-out' }}>
          {showBanner ? (
            <div className="flex flex-col justify-end text-black pb-4">
              <Banner status={status} title={bannerTitle} onDismiss={closeExport}>
                {link && (
                  <a href={link} target="_blank" rel="noopener noreferrer">
                    {text}
                  </a>
                )}
              </Banner>
            </div>
          ) : (
            <div className="flex flex-col justify-end w-2/5">
              <ExportModalForm
                fileName={fileName}
                setFileName={setFileName}
                exportType={`${exportTitle?.includes('CSV') ? 'CSV' : 'Google Sheet'}`}
                isLoading={isSavingGoogleCSV}
                onSave={() => exportSheet(exportCsvParams)}
                onClose={closeExport}
                sheetsAccount={sheetsAccount}
                setSheetsAccount={setSheetsAccount}
              />
            </div>
          )}
        </Collapsible>
        <TWTable
          id="product-skus-table"
          loading={isLoadingProductSkus}
          columns={columns?.length ? columns : (selectedColumns as any)}
          data={tableData}
          metadata={{
            loadingColumn: isLoading,
            setAdsModalProduct,
            currency,
            type,
            setCreativeInModal,
            isDarkMode: true,
            viewBy,
            adImgs: skuAdImgs,
            numDays,
            pageSize,
          }}
          totalsName={{ singular: 'Summary', plural: 'Summary' }}
          totals={tableData}
          stickyColumnIndex={1}
          stickyHeader
          padding="0.5rem"
          dark
          height={400}
          onSort={onSort}
          sortBy={sortColumnIndex}
          sortDirection={sortOrder}
        />
      </div>
    </DraggableProvider>
  );
};

export default ProductSkusPopup;
