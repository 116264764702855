import React, {
  Component,
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from 'react';
import {
  AnswerNlqQuestionParams,
  ChatSources,
  Conversation,
  Message,
  MessageData,
} from './types/willyTypes';
import { AlanLoaderGray } from 'components/AlanLoader';
import { v4 as uuidV4 } from 'uuid';
import {
  analyticsEvents,
  genericEventLogger,
  willyActions,
  chatActions,
  dashboardsActions,
  sqwhaleActions,
} from 'utils/dataLayer';
import {
  answerNlqQuestion,
  createMessageFromDb,
  createWillyMetricsFromRawData,
  getMetricsDictionary,
} from './utils/willyUtils';
import { RootState, useAppSelector } from 'reducers/RootType';
import { useHistory, useLocation } from 'react-router';
import { useDarkMode } from 'dark-mode-control';
import { ChevronDownMinor, ChevronUpMinor, SendMajor } from '@shopify/polaris-icons';
import { useWindowSize } from 'utils/useWindowSize';
import { Mention, MentionsInput, MentionsInputProps } from 'react-mentions';
import axiosInstance from 'utils/axiosInstance';
import { User } from 'components/UserProfileManagment/User/constants';
import _db from 'utils/DB';
import { checkIfUserTwAdmin } from 'ducks/user';
import { useWillySocket } from './WillySocket';
import { WillyMessageTemplate } from './WillyMessageTemplate';
import { CodeAction } from './WillySimpleText';
import { isMobileApp } from 'utils/Device';
import ScrollToBottom from 'react-scroll-to-bottom';
import { WillyScrollToBottom, WillyScrollToBottomRef } from './WillyScrollToBottom';
import { WillySuggestQuestions } from './WillySuggestQuestions';
import { useSelector } from 'react-redux';
import { guessDefaultType } from './hooks/useDefaultType';
import { ActionIcon, Tooltip, Text, Anchor } from '@tw/ui-components';
import { setCDPSegmentsFromServer } from 'ducks/cdp/segments';
import { useAppDispatch } from 'index';
import { ChatIcon } from 'components/Icons/ChatIcon';
import { WillySequenceSettingsModal } from './dashboardManagment/WillySeqSettingsModal';
import { $activeAccounts, $industry } from '../../$stores/$shop';
import { $dialect } from '$stores/$user';
import { fromPairs } from 'lodash';
import { useChatSocket } from './hooks/useChatSocket';
import { useStoreValue, useWritableStore } from '@tw/snipestate';
import { PromptLibraryPopup } from './PromptsLibrary/PromptLibraryPopup';
import { ChatHistoryPopup } from './ChatHistoryPopup';
import { PreloadDashboardData, SummaryData, PixelData } from 'components/Willy/types/willyTypes';
import { formatNumber } from 'utils/formatNumber';
import { RoundingOptionsMapper } from 'ducks/summary';
import { SequencesListModal } from './SequencesList';
import { BaseSummaryMetric, SummaryMetricIdsTypes } from '@tw/types/module/SummaryMetrics';
import { emptyArray } from 'utils/emptyArray';
import { $sequencesSchedules, $shopSequences } from '$stores/willy/$sequences';
import { useSequenceSocket } from './hooks/useSequenceSocket';
import { SequenceRunsModal } from './utils/SequenceRuns';
import { $shouldShowMobyUpgradeCtas, openFreeTrialPopup } from '$stores/willy/$upgradePopupManager';
import { useFoundersDashUpgradeButton } from 'components/UpgradePlan/components/FDUpgradeButton';

export const chatTitleCarouselOptions = [
  'What can we do for you today?',
  'Analyze your data',
  'Discover insights',
  'Get recommendations',
  'Write new copy',
];

const carouselHeight = 30;

export const mainChatPillPrompts = [
  {
    name: 'Prompt Guide',
    link: 'https://kb.triplewhale.com/en/articles/9117737-prompt-guide',
    prompt:
      'Give me tips and best practices on how to prompt Moby, including:\n1) What categories of information should I include in my prompts?\n2) How can I specify metrics from the Triple Whale Metric Dictionary?\n3) What sources are available for getting inspiration for prompts?\n4) What should I do if I get a bad response?\n5) When should I start a new conversation?\n6) What’s the best way to ask for analysis, insights, or recommendations?\n7) Can I ask questions about Triple Whale, the pixel, or attribution?\n8) How can I get forecasts?\n\nGet information from the URL https://kb.triplewhale.com/en/articles/9117737-prompt-guide',
  },
  {
    name: 'Getting Started',
    link: 'https://kb.triplewhale.com/en/articles/9118137-getting-started',
    prompt:
      'Give me guidance on how to start using Moby, including:\n1) What to expect\n2) How to ask for data\n3) How to validate/check the data\n4) How to make visualizations\n5) How to add widgets to boards\n6) How to get analysis, insights, and recommendations\n7) What are sequences, why are they so valuable, how to run them and how to save them\n8) How to generate forecasts\n9) Where to find inspiration for prompts.\n\nGet information from the URL https://kb.triplewhale.com/en/articles/9118137-getting-started',
  },
];

type MentionedUser = {
  id: string;
  display: string;
};

type MetricDict = {
  '#': string;
  Name: string;
  Abbreviation: string;
  About: string;
  Formula: string;
  Category: string;
  'Learn More': string;
};

type WillyMainChatProps = {
  source: ChatSources;
  showLogo?: boolean;
  messagesWrapperClassName?: string;
  messages: Message[];
  setMessages: React.Dispatch<React.SetStateAction<Message[]>>;
  conversationId: string;
  initialQuery?: string;
  isReadyForChat?: boolean;
  returnQueryOnly?: boolean;
  codeActions?: CodeAction[];
  withoutInputBackground?: boolean;
  withoutInputShadow?: boolean;
  hideSuggestions?: boolean;
  setConversationId: React.Dispatch<React.SetStateAction<string>>;
  chatSourceIds?: { dashboardId: string; widgetId: string };
  activeVersion?: number;
  hidePills?: boolean;
  isDashboardPage?: boolean;
  dashboardData?: any;
  summaryData?: SummaryData;
  pixelData?: PixelData;
};

export type WillyMainChatRef = {
  clearConversation: () => void;
  handleSubmit: (text: string, skipUserMessage?: boolean, userMessage?: string) => void;
  setLastMessageId: React.Dispatch<React.SetStateAction<string>>;
  lastMessageId: string;
  focusTextInput: () => void;
  setLoadingConversation: React.Dispatch<React.SetStateAction<boolean>>;
  shouldSetConversationRef: React.MutableRefObject<boolean>;
};

export const WillyMainChat = forwardRef<WillyMainChatRef, WillyMainChatProps>(
  (
    {
      showLogo = true,
      messagesWrapperClassName = '',
      messages,
      conversationId,
      initialQuery,
      isReadyForChat = true,
      returnQueryOnly = false,
      codeActions,
      withoutInputBackground,
      withoutInputShadow,
      source,
      hideSuggestions,
      isDashboardPage,
      setConversationId,
      setMessages,
      chatSourceIds,
      activeVersion,
      hidePills = false,
      dashboardData,
      summaryData,
      pixelData,
    },
    ref,
  ) => {
    const inputRef = useRef<Component<MentionsInputProps, any, any>>(null);
    const scrollToBottomRef = useRef<WillyScrollToBottomRef>(null);
    const shouldSetConversationRef = useRef(true);

    const { search } = useLocation();
    const history = useHistory();
    const doDarkMode = useDarkMode();
    const windowSize = useWindowSize();
    const dispatch = useAppDispatch();

    const dialect = useStoreValue($dialect);
    const industry = useStoreValue($industry);
    const activeAccounts = useStoreValue($activeAccounts);
    const [sequences] = useWritableStore($shopSequences);
    const isFreeShop = useAppSelector((state) => state.isFreeShop);
    const foundersDashUpgradeButton = useFoundersDashUpgradeButton('chat');

    const currentShopId = useAppSelector((state) => state.currentShopId);
    const user = useAppSelector((state) => state.user);
    const currency = useAppSelector((state) => state.activeCurrency);
    const shopTimezone = useAppSelector((state) => state.shopTimezone);
    const { isSegmentsListInitialized } = useSelector((state: RootState) => state.CDPSegments);
    const currencyConversionRate = useSelector((state: RootState) => state.currencyConversionRate);
    const defaultRoundingOption = useSelector((state: RootState) => state.defaultRoundingOption);
    const isUserAdmin = useSelector(checkIfUserTwAdmin);
    const shouldShowMobyUpgradeCtas = useStoreValue($shouldShowMobyUpgradeCtas);

    const [value, setValue] = useState<string>('');
    const [isSequenceMode, setIsSequenceMode] = useState(false);
    const [loadingSequence, setLoadingSequence] = useState(false);
    const [loadingSequenceText, setLoadingSequenceText] = useState('');
    const [sequenceId, setSequenceId] = useState<string>('');
    const [followUpQuestions, setFollowUpQuestions] = useState<string[]>([]);
    const [showFollowUpQuestions, setShowFollowUpQuestions] = useState(true);
    const [conversationUser, setConversationUser] = useState<string>(user.uid || '');
    const [shopUsers, setShopUsers] = useState<MentionedUser[]>([
      {
        id: user.uid!,
        display: user.email!,
      },
    ]);
    const [loadingShopUsers, setLoadingShopUsers] = useState(false);
    const [mentionedUsers, setMentionedUsers] = useState<MentionedUser[]>([]);
    const [loadingConversation, setLoadingConversation] = useState(false);
    const [conversationNotFound, setConversationNotFound] = useState(false);
    const [userName, setUserName] = useState<string>('');
    const [lastMessageId, setLastMessageId] = useState<string>('');
    const [sequencesListModalOpen, setSequencesListModalOpen] = useState(false);
    const [willyLoading, setWillyLoading] = useState(false);
    const [currentConversation, setCurrentConversation] = useState<Conversation>();
    const [metricsDict, setMetricsDict] = useState<Record<string, MetricDict>>();
    const [runId, setRunId] = useState<string>('');
    const [runsOpen, setRunsOpen] = useState<string>();

    const conversationMembers = useMemo(() => {
      const mentionedUsers = currentConversation?.users || [];
      return [conversationUser, ...mentionedUsers];
    }, [conversationUser, currentConversation]);

    const lastMessageWithData = useMemo(() => {
      return messages?.findLast((x) => !!x.data?.length);
    }, [messages]);

    const currentAnalyticsEvent = useMemo(() => {
      return source === 'chat'
        ? analyticsEvents.CHAT
        : source === 'editor'
          ? analyticsEvents.SQWHALE
          : analyticsEvents.DASHBOARDS;
    }, [source]);

    const currentAnalyticsActionSet = useMemo(() => {
      return source === 'chat' || source === 'sequence'
        ? chatActions
        : source === 'editor'
          ? sqwhaleActions
          : dashboardsActions;
    }, [source]);

    const ExpandOrCollapse = showFollowUpQuestions ? ChevronDownMinor : ChevronUpMinor;

    const isEmptyState = useMemo(() => messages.length === 0, [messages]);

    const { socket, isConnected } = useWillySocket();

    const streamingMode = useMemo(() => {
      return isConnected;
    }, [isConnected]);

    const lastMessageFromUser = useMemo(() => {
      return messages.findLast((message) => message.role === 'user');
    }, [messages]);

    const inputPlaceholder = useMemo(() => {
      if (isSequenceMode) {
        return 'Run a sequence to start';
      }
      if (!conversationMembers.includes(user.uid!) && !isUserAdmin) {
        return `You are not part of this conversation`;
      }

      return undefined;
    }, [conversationMembers, user.uid, isSequenceMode, isUserAdmin]);

    const metricsData = useMemo(() => {
      if (!metricsDict) {
        return [];
      }
      return Object.values(metricsDict)
        .map((colInfo) => {
          return {
            id: colInfo.Name,
            display: colInfo.Name,
          };
        })
        .sort((a, b) => a.display.localeCompare(b.display));
    }, [metricsDict]);

    const addMessage = useCallback(
      (message: Message, appendText?: boolean) => {
        setMessages((messages) => {
          if (messages.some((m) => m.id === message.id)) {
            return messages.map((m) => {
              if (m.id === message.id) {
                return {
                  ...m,
                  ...message,
                  text: appendText ? `${m.text}${message.text}` : message.text || m.text,
                };
              }
              return m;
            });
          } else {
            return messages.concat(message);
          }
        });
        // scrollToBottomRef.current?.scrollToBottom();
      },
      [setMessages],
    );

    useChatSocket({
      setMessages,
      setWillyLoading,
      source,
      currentConversationId: conversationId || runId,
    });

    useSequenceSocket({
      sequenceId,
      runId,
      setMessages,
      setLastMessageId,
      setLoadingSequence,
      setLoadingSequenceText,
    });

    const resetLoading = useCallback(() => {
      setWillyLoading(false);
    }, []);

    const uniqueMentioned = useMemo(() => {
      const mentioned = mentionedUsers.filter((x) => value.includes(`${x.display}`));
      const uniqueById = mentioned.reduce((acc, current) => {
        const x = acc.find((item) => item.id === current.id);
        if (!x) {
          return acc.concat([current]);
        } else {
          return acc;
        }
      }, emptyArray<MentionedUser>());

      return uniqueById;
    }, [mentionedUsers, value]);

    const showSuggestions = useMemo(() => {
      if (!isEmptyState) {
        return false;
      }

      if (loadingConversation) {
        return false;
      }

      if (loadingSequence) {
        return false;
      }

      if (hideSuggestions) {
        return false;
      }

      return true;
    }, [isEmptyState, loadingConversation, loadingSequence, hideSuggestions]);

    const computedBackground = useMemo(() => {
      return {
        background: withoutInputBackground ? 'transparent' : '#ffffff',
      };
    }, [withoutInputBackground]);

    const formattedDashboardData = useMemo(() => {
      const dd = {};
      for (let key in dashboardData) {
        const element = dashboardData[key] as PreloadDashboardData;
        const { generatedQuery, ...rest } = element;
        dd[key] = rest;
      }
      return JSON.stringify(dd);
    }, [dashboardData]);

    const currencyRounding = useCallback(
      (metric: BaseSummaryMetric<SummaryMetricIdsTypes>) => {
        if (metric?.type === 'currency') {
          return RoundingOptionsMapper[defaultRoundingOption];
        }
        return null;
      },
      [defaultRoundingOption],
    );

    const formattedSummaryData = useMemo(() => {
      const newData = Object.keys(summaryData?.calculatedStats || {})
        ?.map((s) => {
          const stat = summaryData?.activeMetrics?.find((m) => m.id === s);
          if (stat) {
            const rawValue = summaryData?.calculatedStats[s];
            const value = formatNumber(
              (stat.type === 'percent' ? rawValue / 100 : rawValue) * currencyConversionRate,
              {
                style: stat.type || 'decimal',
                currency,
                minimumFractionDigits:
                  currencyRounding({ id: stat.id, type: stat.type } as any) ??
                  stat.valueToFixed ??
                  0,
                maximumFractionDigits:
                  currencyRounding({ id: stat.id, type: stat.type } as any) ??
                  stat.valueToFixed ??
                  0,
              },
            );

            const prevRawValue = summaryData?.previousPeriodCalculatedStats[s];
            const prevValue = formatNumber(
              (stat.type === 'percent' ? prevRawValue / 100 : prevRawValue) *
                currencyConversionRate,
              {
                style: stat.type || 'decimal',
                currency,
                minimumFractionDigits:
                  currencyRounding({ id: stat.id, type: stat.type } as any) ??
                  stat.valueToFixed ??
                  0,
                maximumFractionDigits:
                  currencyRounding({ id: stat.id, type: stat.type } as any) ??
                  stat.valueToFixed ??
                  0,
              },
            );

            return {
              id: s,
              sqlConfig: stat.willyConfig,
              title: stat.title,
              value,
              previousPeriodValue: prevValue,
            };
          }

          return null;
        })
        .filter((v) => v?.id);

      return JSON.stringify(newData);
    }, [currency, currencyConversionRate, currencyRounding, summaryData]);

    const formattedPixelData = useMemo(() => {
      const filteredData = pixelData
        ?.filter((d) => {
          return d.active && d.pixelAov && d.pixelAov !== 0;
        })
        .map((d) => {
          const { metricsBreakdown, ...rest } = d;
          return rest;
        });

      return JSON.stringify(filteredData);
    }, [pixelData]);

    const activeSequence = useMemo(() => {
      return sequences.find((c) => c.id === sequenceId);
    }, [sequenceId, sequences]);

    const activeSequenceDialect = useMemo(() => {
      return activeSequence?.dialect === 'both' ? 'clickhouse' : $dialect.get();
    }, [activeSequence?.dialect]);

    const handleSubmit = useCallback(
      async (text: string, skipUserMessage?: boolean, userMessage?: string) => {
        const v = text;
        if (!v?.trim()?.length) {
          return;
        }
        if (willyLoading || !dialect) {
          return;
        }
        if (!activeAccounts) {
          return;
        }
        let newConversationId = conversationId || uuidV4();
        if (!conversationId) {
          shouldSetConversationRef.current = false;

          setConversationId(newConversationId);

          if (!isDashboardPage) {
            const params = new URLSearchParams(search);
            params.set('conversationId', newConversationId);
            history.push({
              pathname: location.pathname,
              search: params.toString(),
            });
          }

          setTimeout(() => {
            shouldSetConversationRef.current = true;
          }, 500);
        }

        setWillyLoading(true);
        setShowFollowUpQuestions(false);

        let currentMessageId = uuidV4();

        setLastMessageId(currentMessageId);
        setValue('');

        if (!skipUserMessage) {
          // add the message user submitted
          addMessage({
            id: uuidV4(),
            text: userMessage || v,
            userId: user.uid,
            role: 'user',
            conversationId: newConversationId,
          });
        }

        genericEventLogger(analyticsEvents.WILLY, {
          action: willyActions.SEND_MESSAGE,
          prompt: v,
        });

        genericEventLogger(currentAnalyticsEvent, {
          action: currentAnalyticsActionSet.SEND_MESSAGE,
          prompt_text: v,
          is_first_message: !messages.length,
          conversationId: newConversationId,
        });

        try {
          if (streamingMode) {
            addMessage({
              id: currentMessageId,
              role: 'assistant',
              conversationId: newConversationId,
              progress: undefined,
              progressText: undefined,
              loading: true,
            });
          } else {
            addMessage({
              id: currentMessageId,
              progress: 0.5,
              progressText: 'Generating response, please wait...',
              role: 'assistant',
              conversationId: newConversationId,
            });
          }

          const requestParams: AnswerNlqQuestionParams = {
            source: source === 'summary' || source === 'pixel' ? 'dashboard' : source,
            shopId: currentShopId,
            additionalShopIds: activeAccounts,
            messageId: currentMessageId,
            question: v,
            conversationId: newConversationId,
            generateInsights: true,
            stream: true,
            returnQueryOnly,
            dialect,
            query: initialQuery || lastMessageWithData?.data?.[0]?.query,
            metrics: lastMessageWithData?.data?.[0]?.data?.map((x) => x.name),
            widgetId: lastMessageWithData?.data?.[0]?.queryId,
            dashboardId: 'chat',
            mentionedUsers: uniqueMentioned.map((x) => x.id),
            currency,
            timezone: shopTimezone,
            industry: industry || 'other',
            dashboardData:
              source === 'pixel'
                ? formattedPixelData
                : source === 'summary'
                  ? formattedSummaryData
                  : formattedDashboardData,
          };

          if (streamingMode) {
            await answerNlqQuestion({ ...requestParams, stream: true });
          } else {
            const response = await answerNlqQuestion({ ...requestParams, stream: false });
            console.log('response', response);

            setWillyLoading(false);

            const messagesData: MessageData[] = response.data.map((d) => {
              return {
                data: d.data,
                dataColumns: d.dataColumns,
                serviceIds: d.serviceIds,
                verified: d.verified,
                parameters: d.parameters,
                originalQuestion: d.originalQuestion,
                query: d.generatedQuery || '',
                type: guessDefaultType(d.data, d.dataColumns, d.originalQuestion, d.dataType),
                metrics: createWillyMetricsFromRawData(
                  d.data,
                  d.generatedQuery || '',
                  [],
                  d.serviceIds || [],
                  d.dataType,
                ),
              };
            });

            addMessage({
              id: currentMessageId,
              data: messagesData,
              role: 'assistant',
              progress: undefined,
              progressText: undefined,
              conversationId: newConversationId,
              text: response.text,
              actions: response.actions,
              loading: false,
            });
          }
        } catch (error) {
          console.error('ERROR', error);
        }
      },
      [
        willyLoading,
        dialect,
        activeAccounts,
        conversationId,
        currentAnalyticsEvent,
        currentAnalyticsActionSet.SEND_MESSAGE,
        messages.length,
        setConversationId,
        isDashboardPage,
        search,
        history,
        addMessage,
        user.uid,
        streamingMode,
        source,
        currentShopId,
        returnQueryOnly,
        initialQuery,
        lastMessageWithData?.data,
        uniqueMentioned,
        currency,
        shopTimezone,
        formattedPixelData,
        formattedSummaryData,
        formattedDashboardData,
        industry,
      ],
    );

    const onInputKeyDown = useCallback(
      (e: React.KeyboardEvent<HTMLDivElement>) => {
        const { shiftKey, metaKey, key } = e;
        const inputId = inputRef.current?.props.id;
        const input = document.getElementById(inputId || '');

        if (key === 'Enter' && !(metaKey || shiftKey)) {
          e.preventDefault();
          isReadyForChat && handleSubmit(value);
          return;
        } else if (key === 'Enter' && (metaKey || shiftKey)) {
          e.preventDefault();
          setValue((prev) => `${prev}\n`);

          if (!inputId || !input) {
            return;
          }
          input.scrollTop = input.scrollHeight + 100;
          return;
        }
      },
      [handleSubmit, isReadyForChat, value],
    );

    const clearConversation = useCallback(() => {
      setMessages([]);
      setLastMessageId('');

      setShowFollowUpQuestions(false);
      setFollowUpQuestions([]);

      setWillyLoading(false);
      setConversationNotFound(false);
      setLoadingConversation(false);
      setLoadingSequence(false);

      setUserName(user.firstName || user.lastName || user.email || user.uid!);
      setConversationUser(user.uid || '');

      if (currentAnalyticsEvent !== 'dashboards') {
        const params = new URLSearchParams(search);
        params.delete('conversationId');
        params.delete('sequenceId');
        params.delete('runId');
        history.push({
          pathname: location.pathname,
          search: params.toString(),
        });
      }

      setValue('');
      genericEventLogger(analyticsEvents.WILLY, {
        action: willyActions.CLEAR_CHAT,
      });
      genericEventLogger(currentAnalyticsEvent, {
        action: currentAnalyticsActionSet.CLEAR_CHAT,
        conversationId,
      });
    }, [
      setMessages,
      user.firstName,
      user.lastName,
      user.email,
      user.uid,
      search,
      history,
      currentAnalyticsEvent,
      currentAnalyticsActionSet.CLEAR_CHAT,
      conversationId,
    ]);

    const runSequence = useCallback(async () => {
      const id = activeSequence?.id;

      if (!currentShopId) {
        return;
      }
      if (!id) {
        return;
      }
      if (!activeAccounts) {
        return;
      }
      if (runId) {
        return;
      }

      clearConversation();

      shouldSetConversationRef.current = false;
      let currentMessageId = uuidV4();
      const newRunId = uuidV4();
      setSequenceId(id);
      setRunId(newRunId);

      const params = new URLSearchParams(search);

      params.set('sequenceId', id);
      params.set('runId', newRunId);
      params.delete('conversationId');
      history.push({
        search: params.toString(),
      });

      setTimeout(() => {
        shouldSetConversationRef.current = true;
      }, 500);

      const requestParams: AnswerNlqQuestionParams = {
        source: 'sequence',
        shopId: currentShopId,
        conversationId: newRunId,
        additionalShopIds: activeAccounts,
        messageId: currentMessageId,
        question: '<question will generate from sequence>',
        generateInsights: true,
        stream: true,
        currency,
        timezone: shopTimezone,
        dialect: activeSequenceDialect,
        industry: industry || 'other',
      };

      setLoadingSequence(true);

      socket.emit('run-sequence', {
        ...requestParams,
        sequenceId: id,
      });
    }, [
      currency,
      currentShopId,
      shopTimezone,
      socket,
      activeSequence?.id,
      activeSequenceDialect,
      runId,
      activeAccounts,
      search,
      history,
      shouldSetConversationRef,
      clearConversation,
      industry,
    ]);

    useEffect(() => {
      runSequence();
    }, [runSequence]);

    const focusTextInput = useCallback(() => {
      (inputRef as any)?.current?.inputElement?.focus();
    }, []);

    useEffect(() => {
      (async () => {
        if (!conversationId) {
          return;
        }
        const conversationDoc = await _db().collection('conversations').doc(conversationId).get();
        const conversation = conversationDoc.data() as Conversation;
        if (conversationDoc.exists) {
          setCurrentConversation({ ...conversation, id: conversationDoc.id });
        } else {
          setCurrentConversation(undefined);
        }
      })();
    }, [conversationId]);

    useEffect(() => {
      (async () => {
        const metricsDict: MetricDict[] = await getMetricsDictionary();

        const asObject = fromPairs(
          metricsDict.map((x) => {
            return [x.Name, x];
          }),
        );
        setMetricsDict(asObject);
      })();
    }, []);

    useEffect(() => {
      (async () => {
        if (!currentShopId || !sequenceId) {
          return;
        }
        const seqDoc = await _db().collection('data_sequences').doc(sequenceId).get();

        if (!seqDoc.exists) {
          return;
        }
        try {
          const globalDashboardId = seqDoc.data()?.globalDashboardId;
          if (globalDashboardId) {
            await axiosInstance.post('v2/willy/update-stats', {
              uid: user?.uid,
              shopId: currentShopId,
              sequenceId: globalDashboardId,
              actionType: 'viewed',
            });
          }
        } catch (e) {
          console.error('Error updating views counter', e);
        }
      })();
    }, [currentShopId, sequenceId, user?.uid]);

    useEffect(() => {
      (async () => {
        const searchParams = new URLSearchParams(search);
        const conversationId = searchParams.get('conversationId');
        const sequenceId = searchParams.get('sequenceId');
        const runId = searchParams.get('runId');

        if (!shouldSetConversationRef.current) {
          return;
        }

        if (!conversationId) {
          setConversationId('');
          setCurrentConversation(undefined);
        }
        if (!sequenceId) {
          setSequenceId('');
          setIsSequenceMode(false);
        }
        if (!runId) {
          setRunId('');
        }

        if (runId && sequenceId) {
          setRunId(runId);
          setSequenceId(sequenceId);
          setIsSequenceMode(true);
          setLoadingConversation(true);
          const runDoc = await _db()
            .collection('data_sequences')
            .doc(sequenceId)
            .collection('runs')
            .doc(runId)
            .get();

          if (!runDoc.exists) {
            setLoadingConversation(false);
            return;
          }

          const runData = runDoc.data();

          if (!runData) {
            setLoadingConversation(false);
            return;
          }

          const messages: Message[] = createMessageFromDb(runData, runId);
          setMessages(messages);
          setLoadingConversation(false);
        } else if (sequenceId) {
          setSequenceId(sequenceId);
          setIsSequenceMode(true);
        } else if (conversationId && conversationId != currentConversation?.id) {
          setLoadingConversation(true);
          setUserName('');
          const conversationDoc = await _db().collection('conversations').doc(conversationId).get();
          if (!conversationDoc.exists) {
            setMessages([]);
            setConversationId('');
            setLoadingConversation(false);
            setConversationNotFound(true);
            return;
          }
          const conversation = conversationDoc.data() as Conversation;
          if (conversation?.history) {
            const messages: Message[] = createMessageFromDb(conversation, conversationId);
            setMessages(messages);
            setConversationId(conversationId);
          }
          if (conversation?.user) {
            setConversationUser(conversation.user);
            try {
              const { data } = await axiosInstance.get<User>(
                `/v2/willy/get-user-name?shopId=${currentShopId}&userId=${conversation.user}`,
              );
              const { firstName, lastName, email } = data;
              setUserName(firstName || lastName || email || conversation.user);
            } catch (e) {
              console.error('Could not fetch user name', e);
            }
          }
          setLoadingConversation(false);
        }
      })();
    }, [
      currentShopId,
      search,
      history,
      user,
      currentConversation?.id,
      setConversationId,
      setMessages,
    ]);

    useEffect(() => {
      setUserName(user.firstName || user.lastName || user.email || user.uid!);
    }, [user]);

    useEffect(() => {
      setFollowUpQuestions([]);
    }, [conversationId]);

    useEffect(() => {
      (async () => {
        setLoadingShopUsers(true);
        const res = (
          await axiosInstance.get(`/v2/account-manager/shops/users/${currentShopId}?noFilter=true`)
        ).data;

        setLoadingShopUsers(false);

        setShopUsers(
          res.map((x) => {
            return {
              id: x.id,
              display: x.email,
            };
          }),
        );
      })();
    }, [currentShopId]);

    useEffect(() => {
      // clear the conversationId query param when user navigates away
      return () => {
        const searchParams = new URLSearchParams(window.location.search);
        searchParams.delete('conversationId');
        searchParams.delete('sequenceId');
        searchParams.delete('runId');

        history.replace({
          search: searchParams.toString(),
        });
      };
    }, [history]);

    useEffect(() => {
      if (!isSegmentsListInitialized) {
        dispatch(setCDPSegmentsFromServer());
      }
    }, [dispatch, isSegmentsListInitialized]);

    useImperativeHandle(ref, () => ({
      clearConversation,
      handleSubmit,
      setLastMessageId,
      setLoadingConversation,
      lastMessageId,
      focusTextInput,
      shouldSetConversationRef,
    }));

    const clearConversationButton = (
      <div
        className="cursor-pointer"
        onClick={() => {
          genericEventLogger(currentAnalyticsEvent, {
            action: chatActions.CLEAR_CONVERSATION,
            conversationId,
          });
          clearConversation();
        }}
      >
        <span className="text-black/50 dark:text-white/50 font-semibold [text-shadow:_0_0_0_white]">
          {isSequenceMode ? 'Clear sequence' : 'Clear conversation'}
        </span>
      </div>
    );

    const [activeChatTitleCarouselIndex, setActiveChatTitleCarouselIndex] = useState(0);
    useEffect(() => {
      let timer = setTimeout(
        () =>
          setActiveChatTitleCarouselIndex(
            activeChatTitleCarouselIndex + 1 >= chatTitleCarouselOptions.length
              ? 0
              : activeChatTitleCarouselIndex + 1,
          ),
        4000,
      );

      return () => {
        clearTimeout(timer);
      };
    }, [activeChatTitleCarouselIndex]);
    const chatTitleCarouselStyles = useMemo(() => {
      return {
        transform: `translateY(-${carouselHeight * activeChatTitleCarouselIndex}px)`,
      };
    }, [activeChatTitleCarouselIndex]);

    return (
      <ScrollToBottom
        debug={false}
        followButtonClassName="hidden"
        className={`h-full w-full overflow-auto flex flex-col ${messagesWrapperClassName}`}
        scrollViewClassName="h-full w-full overflow-auto flex flex-col"
      >
        <WillyScrollToBottom ref={scrollToBottomRef} showArrow>
          <div className="w-full flex flex-col flex-auto @container" style={computedBackground}>
            {!hidePills && (
              <div className="sticky top-0 bg-white z-10 p-4 flex items-center gap-4">
                <div className="flex items-center gap-4 flex-auto">
                  <div className="@3xl:flex top-8 z-10 mr-auto">
                    <Tooltip label="New chat" position="bottom">
                      <ActionIcon
                        icon="chat"
                        display="block"
                        iconColor="gray.5"
                        iconSize={20}
                        onClick={() => {
                          clearConversation();
                          genericEventLogger(currentAnalyticsEvent, {
                            action: chatActions.NEW_CONVERSATION,
                            conversationId,
                          });
                        }}
                      />
                    </Tooltip>
                  </div>
                  <PromptLibraryPopup
                    onSelectPrompt={(prompt) => {
                      setValue(prompt);
                      genericEventLogger(analyticsEvents.CHAT, {
                        action: chatActions.CHOOSE_PROMPT_LIBRARY_PROMPT,
                        prompt,
                      });
                    }}
                    isSmall={windowSize.isSmall}
                  />
                  <ChatHistoryPopup
                    conversationId={conversationId}
                    clearConversation={clearConversation}
                  />
                  <SequencesListModal
                    opened={sequencesListModalOpen}
                    setOpened={setSequencesListModalOpen}
                    sequenceId={sequenceId}
                    isSmall={windowSize.isSmall}
                    setRunsOpen={setRunsOpen}
                  />
                </div>
              </div>
            )}
            {showLogo && isEmptyState && !loadingConversation && !loadingSequence && (
              <div className="w-full flex flex-col justify-center items-center m-auto mb-auto md:mb-auto p-">
                <div
                  className={`text-gray-800 w-full md:h-full md:flex md:flex-col p-4 dark:text-gray-100 overflow-hidden ${isDashboardPage && 'mt-8'}`}
                >
                  <h1 className="text-4xl font-semibold md:text-center ml-auto mr-auto mb-5 md:mb-8 flex gap-4 items-center justify-center flex-col">
                    {/* <Alan className=" @[600px]:w-[150px] w-20 max-w-[100%] h-auto" /> */}
                    <ChatIcon
                      className="@[600px]:w-[150px] w-20 max-w-[100%] h-auto"
                      onClick={(e) => {
                        dispatch({
                          type: 'FLOPPY_WHALE_MODAL_OPENED',
                        });
                      }}
                    />
                  </h1>
                </div>
                {!isSequenceMode && (
                  <>
                    <div
                      className="overflow-hidden shrink-0"
                      style={{ height: carouselHeight + (windowSize.isSmall ? 0 : 7) }}
                    >
                      <div
                        className="transition ease-in-out duration-300 flex flex-col items-center justify-start text-center shrink-0"
                        style={{
                          ...chatTitleCarouselStyles,
                          height: carouselHeight + (windowSize.isSmall ? 0 : 7),
                        }}
                      >
                        {chatTitleCarouselOptions.map((option, index) => (
                          <div
                            key={index}
                            className="font-medium text-3xl flex items-center justify-center shrink-0"
                            style={{ lineHeight: '30px' }}
                          >
                            {option}
                          </div>
                        ))}
                      </div>
                    </div>

                    {!hidePills && !windowSize.isSmall && (
                      <div className="flex gap-4 mt-8">
                        {mainChatPillPrompts.map(({ prompt, name }) => (
                          <div
                            role="button"
                            className="willy-suggest-question px-6 py-2 flex flex-col overflow-hidden cursor-pointer animateFadeToRight rounded-2xl border border-solid border-[#D1D4DB] hover:bg-[#e5e7eb] transition-colors"
                            onClick={() => {
                              handleSubmit?.(prompt);
                              genericEventLogger(analyticsEvents.CHAT, {
                                action: chatActions.SELECT_DEFAULT_PILL,
                                prompt,
                              });
                            }}
                            key={name}
                          >
                            {name}
                          </div>
                        ))}
                      </div>
                    )}
                  </>
                )}
                {conversationNotFound && (
                  <div className="text-gray-800 dark:text-gray-100 font-semibold text-lg mt-4">
                    Conversation not found, you can start a new one or change the shop
                  </div>
                )}
              </div>
            )}
            {(loadingConversation || loadingSequence) && showLogo && (
              <div className="flex flex-col gap-2 w-full h-full justify-center items-center">
                <AlanLoaderGray />
                {!!loadingSequenceText && (
                  <Text color="gray.5" size="lg">
                    {loadingSequenceText}
                  </Text>
                )}
              </div>
            )}
            {!isEmptyState && !loadingConversation && !loadingSequence && (
              <div className="flex-auto flex flex-col">
                {(windowSize.isSmall || isMobileApp) && <div className="mt-auto"></div>}
                {messages.map((message, i, arr) => (
                  <WillyMessageTemplate
                    message={message}
                    firstOfAssistant={message.role !== 'user' && arr[i - 1]?.role === 'user'}
                    lastOfAssistant={
                      (message.role !== 'user' && arr[i + 1]?.role === 'user') ||
                      i === arr.length - 1
                    }
                    lastMessageFromUser={lastMessageFromUser}
                    canEdit={conversationMembers.includes(user.uid!)}
                    userName={userName}
                    handleSubmit={handleSubmit}
                    key={message.id + '_element'}
                    loading={willyLoading}
                    codeActions={codeActions}
                    isLast={i === messages.length - 1}
                    conversationUser={conversationUser}
                    isSequenceMode={isSequenceMode}
                    chatSourceIds={chatSourceIds}
                    conversationMessages={messages}
                  />
                ))}
              </div>
            )}
            <div
              className="sticky bottom-0 z-[200] w-full flex flex-col p-6 @3xl:!px-90 @3xl:!pb-16"
              style={computedBackground}
            >
              {showSuggestions && (
                <div
                  className={`${
                    source === 'dashboard' &&
                    !willyLoading &&
                    (!!conversationId || messages?.length > 0)
                      ? ' mb-8'
                      : ''
                  }`}
                >
                  <WillySuggestQuestions
                    onQuestionClick={(q) => {
                      if (!q) {
                        return;
                      }
                      setValue(q);
                      (inputRef?.current as any)?.inputElement?.focus();
                      genericEventLogger(currentAnalyticsEvent, {
                        action: currentAnalyticsActionSet.CHOOSE_SUGGESTED_MESSAGE,
                        conversationId,
                        suggested_prompt: q,
                      });
                    }}
                    source={source}
                    inputValue={value}
                  />
                </div>
              )}
              <div className="flex flex-col gap-1 items-end relative">
                <div className="w-full relative sm:flex items-end gap-4 justify-between pb-2 sm:pb-4">
                  {followUpQuestions?.length > 0 && (
                    <div
                      className={`max-w-full self-start fadein overflow-hidden transition-[height] duration-300 ${
                        showFollowUpQuestions ? 'h-64' : 'h-10'
                      }`}
                    >
                      <div
                        className={`rounded-lg overflow-hidden ${
                          showFollowUpQuestions ? 'bg-white dark:bg-gray-800' : 'bg-transparent'
                        }`}
                      >
                        <div
                          className="sm:px-4 py-2 flex gap-1 items-center cursor-pointer"
                          onClick={() => {
                            setShowFollowUpQuestions((x) => !x);
                          }}
                        >
                          <ExpandOrCollapse className="w-6 h-6" />
                          <h1 className="text-gray-900 dark:text-white font-semibold text-lg">
                            Follow-up questions
                          </h1>
                        </div>
                        <div
                          className={`flex flex-col gap-2 p-4 pt-0 ${
                            showFollowUpQuestions ? 'opacity-100' : 'opacity-0'
                          }`}
                        >
                          {followUpQuestions.map((q) => {
                            return (
                              <div
                                onClick={() => {
                                  setValue(q);
                                  handleSubmit(q);
                                  genericEventLogger(currentAnalyticsEvent, {
                                    action: currentAnalyticsActionSet.CLICK_FOLLOWUP,
                                    conversationId,
                                    followup_prompt: q,
                                  });
                                }}
                                className="flex flex-col gap-2 p-4 bg-gray-100 dark:bg-gray-700 rounded-lg cursor-pointer hover:bg-gray-200 dark:hover:bg-gray-600 transition-colors"
                                key={q}
                              >
                                <div className="text-gray-900 dark:text-white font-semibold text-lg line-clamp-1">
                                  {q}
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    </div>
                  )}
                  {!willyLoading && (!!conversationId || messages?.length > 0) && (
                    <div
                      className={`whitespace-nowrap absolute right-1 bottom-2 sm:relative ${
                        showFollowUpQuestions ? 'hidden sm:block' : ''
                      }`}
                    >
                      {clearConversationButton}
                    </div>
                  )}
                </div>
                <div className="flex items-center w-full">
                  <div
                    className={`relative flex-auto ${doDarkMode ? ' tw-nice-dark' : ''}`}
                    onKeyDown={onInputKeyDown}
                  >
                    {!streamingMode && (
                      <Tooltip label="Streaming mode is temporarily disabled, responses might take longer than usual">
                        <div className="absolute flex-shrink-0 top-1/2 -left-12 translate-x-1/2 -translate-y-1/2 w-4 h-4 bg-red-500 rounded-full" />
                      </Tooltip>
                    )}

                    <MentionsInput
                      ref={inputRef}
                      id="willy-input"
                      value={value}
                      onChange={(e) => {
                        setValue(e.target.value);
                      }}
                      className="w-full"
                      style={{
                        control: {
                          backgroundColor: '#fff',
                          fontSize: 14,
                          fontWeight: 'normal',
                          border: 'none',
                          boxShadow: withoutInputShadow
                            ? 'none'
                            : '0px 0px 15px var(--p-dark-border)',
                          borderRadius: '10px',
                        },
                        highlighter: {
                          padding: 16,
                          paddingRight: 50,
                          paddingLeft: 40,
                          border: 'none',
                          fontSize: '16px',
                          fontWeight: 600,
                        },
                        input: {
                          padding: 16,
                          paddingRight: 50,
                          paddingLeft: 16,
                          border: 'none',
                          backgroundColor: 'transparent',
                          boxShadow: 'none',
                          fontSize: '16px',
                          fontWeight: 500,
                          outline: 'none',
                        },
                        suggestions: {
                          list: {
                            backgroundColor: 'white',
                            // border: '1px solid rgba(0,0,0,0.15)',
                            fontSize: 14,
                            maxHeight: '200px',
                            overflow: 'auto',
                          },
                          item: {
                            padding: '5px 15px',
                            '&focused': {
                              backgroundColor: '#cee4e5',
                            },
                          },
                        },
                      }}
                      placeholder={inputPlaceholder}
                      disabled={
                        (!isUserAdmin && !conversationMembers.includes(user.uid!)) ||
                        isSequenceMode ||
                        (!!activeVersion && activeVersion < 6)
                      }
                      autoFocus={!windowSize.isSmall && !isMobileApp}
                      // inputRef={inputRef}
                      forceSuggestionsAboveCursor={true}
                    >
                      <Mention
                        trigger="@"
                        // style={{}}
                        isLoading={loadingShopUsers}
                        data={shopUsers}
                        // displayTransform={(id, display) => {
                        //   return display.split('@')[0].trim();
                        // }}
                        markup='@"__display__"'
                        onAdd={(id, display) => {
                          setMentionedUsers((old) => {
                            return old.concat({ id: id.toString(), display });
                          });
                        }}
                      />
                      <Mention
                        trigger="#"
                        appendSpaceOnAdd
                        data={metricsData}
                        markup='@"__display__"'
                        renderSuggestion={(suggestion, search) => {
                          const metric = metricsDict?.[suggestion.id];
                          if (!metric) {
                            return <Text fw="bold">{suggestion.display}</Text>;
                          }
                          return (
                            <div className="flex flex-col gap-1">
                              <Text fw="bold">{metric.Name}</Text>
                              <Text size="sm">{metric.About}</Text>
                              {!!metric.Formula && (
                                <Text size="sm" color="gray.5" fs="italic">
                                  <Text display="inline-flex" fw="bold">
                                    Formula:
                                  </Text>{' '}
                                  <Text display="inline-flex">{metric.Formula}</Text>
                                </Text>
                              )}
                              {!!metric['Learn More'] && (
                                <Text size="sm" color="gray.5">
                                  <a target="_blank" href={metric['Learn More']}>
                                    Learn more
                                  </a>
                                </Text>
                              )}
                            </div>
                          );
                        }}
                      />
                    </MentionsInput>
                    <div className="absolute flex-shrink-0 top-1/2 right-0 -translate-x-1/2 -translate-y-1/2">
                      {willyLoading ? (
                        <ActionIcon
                          icon="pause"
                          variant="transparent"
                          onClick={() => {
                            resetLoading();
                            setLastMessageId('');
                            socket?.emit('stop-answer-nlq-question', {
                              shopId: currentShopId,
                              messageId: lastMessageId,
                              conversationId,
                              question: value,
                            });
                            if (messages[messages.length - 1].progress) {
                              setMessages((messages) => {
                                return messages.slice(0, messages.length - 1);
                              });
                            }
                          }}
                        />
                      ) : (
                        <div className="flex">
                          <Tooltip
                            label={
                              !isReadyForChat
                                ? 'Warming up chat...'
                                : !value?.trim()
                                  ? 'Please enter value'
                                  : ''
                            }
                          >
                            <ActionIcon
                              size="md"
                              disabled={!value?.trim() || !isReadyForChat}
                              variant={!value?.trim() || !isReadyForChat ? 'light' : 'filled'}
                              radius="sm"
                              color="white"
                              onClick={(e) => {
                                handleSubmit(value);
                                genericEventLogger(analyticsEvents.WILLY, {
                                  action: willyActions.START_CHAT,
                                  prompt: value,
                                });
                                genericEventLogger(currentAnalyticsEvent, {
                                  action: currentAnalyticsActionSet.SEND_MESSAGE,
                                  prompt: value,
                                });
                              }}
                              icon="send-chat"
                              iconSize={16}
                            />
                          </Tooltip>
                        </div>
                      )}
                    </div>
                  </div>
                </div>

                {shouldShowMobyUpgradeCtas && (
                  <div className="flex items-center w-full">
                    <div className="w-full text-center pt-[40px]">
                      {isFreeShop ? (
                        <Anchor
                          underline="always"
                          fz="sm"
                          fw={500}
                          onClick={() => foundersDashUpgradeButton.action()}
                        >
                          {foundersDashUpgradeButton.buttonText} to get full access to Moby.
                        </Anchor>
                      ) : (
                        <Anchor
                          underline="always"
                          fz="sm"
                          fw={500}
                          onClick={() => {
                            openFreeTrialPopup('chat');
                          }}
                        >
                          Start a 30-day free trial with Moby Pro
                        </Anchor>
                      )}
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </WillyScrollToBottom>
        <WillySequenceSettingsModal context="chat" />
        <SequenceRunsModal
          opened={!!runsOpen}
          close={() => setRunsOpen(undefined)}
          sequenceId={runsOpen}
        />
      </ScrollToBottom>
    );
  },
);
