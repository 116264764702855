import { Dispatch } from 'redux';

import { CONVERT_STATS_CURRENCY, INIT_SHOP } from 'ducks/constants';

export const ACTIVE_CURRENCY_CHANGED = 'ACTIVE_CURRENCY_CHANGED';

export const activeCurrencyChanged = (currency: string) => ({
  type: ACTIVE_CURRENCY_CHANGED,
  currency,
});

export const changeActiveCurrency = (currencyCode: string) => {
  return (dispatch: Dispatch) => {
    return dispatch(activeCurrencyChanged(currencyCode));
  };
};

const activeCurrency = (state = 'USD', action): string => {
  switch (action.type) {
    case INIT_SHOP:
      return action.currency || 'USD';
    case ACTIVE_CURRENCY_CHANGED:
      return action.currency;
    case CONVERT_STATS_CURRENCY:
      return action.payload;
    default:
      return state;
  }
};

export const reducers = {
  activeCurrency,
};
