import { $combinedDashboard } from '$stores/willy/$combinedDashboards';
import { $combineShopAndCustomViewDashboards } from '$stores/willy/$customViews';
import { useStoreValue } from '@tw/snipestate';
import { Select } from '@tw/ui-components';
import { useMemo } from 'react';

type DashboardDropDownProps = {
  label?: string;
  labelHidden?: boolean;
  disabled?: boolean;
  selected?: string;
  hideGlobals?: boolean;
  onSelect: (id: string) => void;
};

export const DashboardsDropDown: React.FC<DashboardDropDownProps> = ({
  label,
  labelHidden,
  selected,
  disabled,
  hideGlobals,
  onSelect,
}) => {
  const dashboards = useStoreValue($combinedDashboard);
  const shopAndViewDashboards = useStoreValue($combineShopAndCustomViewDashboards);

  const dashboardsToShow = useMemo(() => {
    const dashboardsToSor = hideGlobals ? shopAndViewDashboards : dashboards;
    return dashboardsToSor.sort((a, b) => (a.name || '').localeCompare(b.name || ''));
  }, [dashboards, shopAndViewDashboards, hideGlobals]);

  return (
    <Select
      searchable
      label={labelHidden ? undefined : label || 'Select a dashboard'}
      disabled={disabled}
      value={selected}
      data={dashboardsToShow
        .filter((d) => d.name)
        .map((d) => {
          return { label: d.name ?? d.id, value: d.id };
        })}
      onChange={(v) => {
        if (!v) {
          return;
        }
        onSelect(v);
      }}
    />
  );
};
