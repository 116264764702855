import moment from '@tw/moment-cached';
import React, { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { TimeFrame } from '@tw/types/module/services/insights';
import { RootState } from 'reducers/RootType';
import { TWInfoTooltip } from 'components/library/TWTooltip/TWInfoTooltip';
import { CohortMetricsObj, CohortsBrekdown, CohortsMetric, TABLE_COLORS } from './constants';
import { FiltersContext, appliedInsightsFiltersIds } from 'ducks/insights/filterReducers';

type CohortTileProps = {
  value: any;
  breakdown: CohortsBrekdown;
  timeFrame?: TimeFrame;
};

export const CohortKeyTile: React.FC<CohortTileProps> = ({ value, breakdown, timeFrame }) => {
  const firstOrderAtValue = useCallback(
    (value: moment.MomentInput) => {
      switch (timeFrame) {
        case TimeFrame.YEAR:
          return moment(value).format('YYYY');
        case TimeFrame.MONTH:
          return moment(value).format('MMMM YYYY');
        case TimeFrame.WEEK:
          return `${moment(value).format('MMMM DD, YYYY')}`;
        case TimeFrame.DAY:
          return `${moment(value).format('MMMM DD, YYYY')}`;
        default:
          return `${moment(value).format('MMMM DD, YYYY')}`;
      }
    },
    [timeFrame],
  );

  const valueToDisplay = useMemo(() => {
    switch (breakdown) {
      case CohortsBrekdown.FIRST_ORDER_AT:
        return firstOrderAtValue(value);
    }
  }, [breakdown, firstOrderAtValue, value]);

  return (
    <div className="text-[#4B5563] text-left mr-[35px] whitespace-nowrap">
      {value === 'totals' && <div className="text-[#0C3B78] my-[6px]">Total</div>}
      {value === 'average' && <div className="text-[#0C3B78]">Average</div>}
      {!['average', 'totals'].includes(value) && valueToDisplay}
    </div>
  );
};

export const CohortSizeTile: React.FC<CohortTileProps> = ({ value }) => {
  return (
    <div className="text-right">
      {value.toLocaleString(undefined, {
        minimumFractionDigits: 0,
        maximumFractionDigits: 2,
      })}
    </div>
  );
};

export const CohortNCPATile: React.FC<CohortTileProps> = ({ value }) => {
  const currency = useSelector((state: RootState) => state.currency);
  const { ltvSelectedCDPSegment } = useSelector((state: RootState) => state.ltv);
  const { [FiltersContext.Cohorts]: appliedFilters } = useSelector(appliedInsightsFiltersIds);

  return (
    <div
      className={
        ltvSelectedCDPSegment || Object.keys(appliedFilters)?.length ? 'text-center' : 'text-right'
      }
    >
      {ltvSelectedCDPSegment || Object.keys(appliedFilters)?.length
        ? '-'
        : value.toLocaleString(undefined, {
            style: 'currency',
            currency,
            minimumFractionDigits: 0,
            maximumFractionDigits: 2,
          })}
    </div>
  );
};

export const CohortRPRTile: React.FC<CohortTileProps> = ({ value }) => {
  return (
    <div className="text-right mr-[10px]">
      {value.toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      })}
      %
    </div>
  );
};

export const CohortFrameHeader: React.FC<{ frameKey: any; frameBreakDown: TimeFrame }> = ({
  frameKey,
  frameBreakDown,
}) => {
  const title: string = useMemo(() => {
    if (frameKey === -1) {
      return '1st order';
    }

    switch (frameBreakDown) {
      case TimeFrame.YEAR:
        return `Year ${frameKey}`;
      case TimeFrame.MONTH:
        return `Month ${frameKey}`;
      case TimeFrame.WEEK:
        return `Week ${frameKey}`;
      case TimeFrame.DAY:
        return `Day ${frameKey}`;
      default:
        return frameKey;
    }
  }, [frameBreakDown, frameKey]);

  const tooltipContent: string = useMemo(() => {
    if (frameKey === -1) {
      return 'First order for customers in the cohort within the selected time period';
    } else
      switch (frameBreakDown) {
        case TimeFrame.YEAR:
          return `Year of first purchase`;
        case TimeFrame.MONTH:
          return `Month of first purchase`;
        case TimeFrame.WEEK:
          return `Week of first purchase`;
        case TimeFrame.DAY:
          return `Day of first purchase`;
        default:
          return '';
      }
  }, [frameBreakDown, frameKey]);

  return (
    <div className="mb-[10px]">
      {frameKey < 1 ? (
        <div className="flex flex-wrap gap-[5px] justify-center items-center">
          <span>{title}</span>
          <TWInfoTooltip tooltipContent={tooltipContent} />
        </div>
      ) : (
        <span>{title}</span>
      )}
    </div>
  );
};

export const CohortTile: React.FC<{
  metrics: CohortMetricsObj;
  metric: CohortsMetric;
  colorGroup?: number;
  showNCPAPaybackIndication?: boolean;
}> = ({ metrics, metric, colorGroup, showNCPAPaybackIndication }) => {
  const tileStyle = useMemo(() => {
    return colorGroup === undefined ? undefined : TABLE_COLORS[colorGroup];
  }, [colorGroup]);

  const valueToDisplay: string = useMemo(() => {
    switch (metric) {
      case CohortsMetric.TOTAL_SALES:
        return Math.round(metrics[metric]).toLocaleString();
      case CohortsMetric.RETENTION_RATE:
        return (
          metrics[metric].toLocaleString(undefined, {
            minimumFractionDigits: 0,
            maximumFractionDigits: 2,
          }) + '%'
        );
      case CohortsMetric.LTV:
        return metrics[metric].toLocaleString(undefined, {
          minimumFractionDigits: 0,
          maximumFractionDigits: 2,
        });
      case CohortsMetric.SIZE:
        return metrics[metric].toLocaleString(undefined, {
          minimumFractionDigits: 0,
          maximumFractionDigits: 2,
        });
    }
  }, [metric, metrics]);

  return (
    <div
      className="relative min-w-[62px] min-h-[32px] rounded-[4px] border border-solid border-1 flex justify-center items-center px-[4px] text-[#0C3B78] bg-[#F3F4F6] border-[#E5E7EB]"
      style={{
        backgroundColor: tileStyle?.background,
        borderColor: tileStyle?.border,
        color: tileStyle?.text,
      }}
    >
      {showNCPAPaybackIndication && (
        <div className="w-[4px] bg-[#D97706] absolute top-[-1px] bottom-[-1px] left-[-1px] rounded-l-[4px]"></div>
      )}
      {valueToDisplay}
    </div>
  );
};

export const constTiles = [
  {
    title: 'Cohort',
    metric: 'key',
    component: CohortKeyTile,
    headerStyle: 'text-left',
  },
  {
    title: 'Customers',
    metric: 'size',
    component: CohortSizeTile,
    headerStyle: 'text-right',
    tooltipContent: 'The total number of customers in the cohort',
  },
  {
    title: 'NCPA',
    metric: 'NCPA',
    component: CohortNCPATile,
    headerStyle: 'text-right',
    tooltipContent: (
      <span>
        <p>Estimate of the average cost of acquiring each customer in the cohort, measured by:</p>
        <p className="italic"> total marketing / number of new customers</p>
      </span>
    ),
  },
  {
    title: 'RPR',
    metric: 'RPR',
    component: CohortRPRTile,
    headerStyle: 'text-right pr-[10px]',
    tooltipContent:
      'The % of customers in the cohort who made at least one additional order within the selected time period',
  },
];
