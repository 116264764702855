import { $tables } from '$stores/willy/$tables';
import { useStoreValue } from '@tw/snipestate';
import { Button, Flex, Grow, Text } from '@tw/ui-components';
import { BqColumn, BuilderTable } from '@tw/willy-data-dictionary/module/columns/types';
import { FC, useCallback, useMemo, useState } from 'react';
import { createWillyMetricFromColumn, extractProvidersFromFilters } from '../../utils/willyUtils';
import { WillyMetric, WillyParameter } from '../../types/willyTypes';
import { ChooseTableSection } from './ChooseTableSection';
import { BuilderBadge } from './BuilderBadge';
import { FilterFieldModal } from './FilterFieldModal';
import { SelectWithSearchInput } from './SelectWithSearchInput';

type TableBuilderTabProps = {
  queryId?: string;
  builder: BuilderTable;
  setBuilder: (builder: BuilderTable) => void;
  updateEditedMetrics: (id: string, data: WillyMetric[]) => Promise<void>;
  editedMetrics: WillyMetric[];
};

export const TableBuilderTab: FC<TableBuilderTabProps> = ({
  queryId,
  builder,
  setBuilder,
  updateEditedMetrics,
  editedMetrics,
}) => {
  const tables = useStoreValue($tables);
  const [filterToEdit, setFilterToEdit] = useState<WillyParameter | undefined>();

  const currentTable = useMemo(() => {
    return tables.find((t) => t.id === builder?.table);
  }, [tables, builder]);

  const columns = useMemo(() => {
    return currentTable?.columns ?? [];
  }, [currentTable]);

  const columnsByType: WillyMetric[] = useMemo(() => {
    const cols = columns
      .map((c) => {
        return createWillyMetricFromColumn(c);
      })
      .map((m) => ({
        ...m,
        active: builder?.columns.some((b) => b.columnId === m.key),
      }));

    return cols;
  }, [builder?.columns, columns]);

  const addEventDateIfNoAnyDimension = () => {
    try {
      const apiColumns = builder?.columns
        .map((c) => {
          return columns.find((col) => col.id === c.columnId);
        })
        .filter((c) => c !== undefined) as BqColumn[];
      if (apiColumns.every((c) => c.type === 'numeric' || c.type === 'formula')) {
        builder?.columns.push({
          columnId: 'event_date',
          aggFunction: 'SUM',
        });
      }
      return builder?.columns;
    } catch (e) {
      console.error(e);
      return builder?.columns;
    }
  };

  const onHideMetricChanged = useCallback(
    (id: string) => {
      updateEditedMetrics(
        queryId!,
        editedMetrics.map((m) => (m.key === id ? { ...m, hidden: !m.hidden } : m)),
      );
    },
    [editedMetrics, queryId, updateEditedMetrics],
  );

  const removeColumnFromMetrics = useCallback(
    (columnId: string) => {
      updateEditedMetrics(
        queryId!,
        editedMetrics.filter((m) => m.key !== columnId),
      );
    },
    [editedMetrics, queryId, updateEditedMetrics],
  );

  const addColumnToMetrics = useCallback(
    (columnId: string) => {
      const services = extractProvidersFromFilters(builder.filters);
      const column = currentTable?.columns.find((c) => c.id === columnId);
      if (!column) {
        return;
      }
      const metric: WillyMetric = createWillyMetricFromColumn(
        column,
        builder.columns.length,
        services,
      );

      if (metric) {
        metric.active = true;
        updateEditedMetrics(queryId!, [...editedMetrics, metric]);
      }
    },
    [
      builder.columns.length,
      builder.filters,
      currentTable?.columns,
      editedMetrics,
      queryId,
      updateEditedMetrics,
    ],
  );

  return (
    <Flex direction="column">
      <TableBuilderSection number={1}>
        <ChooseTableSection
          value={builder?.table}
          data={tables.map((t) => ({ label: t.name, value: t.id }))}
          onChange={(val) => {
            if (!val) {
              return;
            }
            setBuilder({
              ...builder!,
              table: val,
              filters: [],
              columns: [],
            });
          }}
        />
      </TableBuilderSection>
      <TableBuilderSection number={2}>
        <Flex direction="column" gap="sm">
          <Flex justify="space-between" align="center">
            <Text weight={500} color="gray.8" size="sm">
              Add Metrics
            </Text>
            <AddMetricPopover
              metrics={columnsByType.filter((m) => !m.isDimension)}
              onAdd={(metric) => {
                metric.active
                  ? addColumnToMetrics(metric.key)
                  : removeColumnFromMetrics(metric.key);
                setBuilder({
                  ...builder!,
                  columns: metric.active
                    ? [
                        ...addEventDateIfNoAnyDimension(),
                        { columnId: metric.key, aggFunction: 'SUM' },
                      ]
                    : builder!.columns.filter((c) => c.columnId !== metric.key),
                });
              }}
            />
          </Flex>
          {columnsByType
            .filter((m) => m.active && !m.isDimension)
            .map((m) => (
              <BuilderBadge
                key={`metric-${m.key}`}
                text={m.name}
                type="metric"
                onRemove={() => {
                  m.active = false;
                  setBuilder({
                    ...builder!,
                    columns: builder!.columns.filter((c) => c.columnId !== m.key),
                  });
                  removeColumnFromMetrics(m.key);
                }}
                onHideChange={() => onHideMetricChanged(m.key)}
              />
            ))}
          {builder.columns.length > 0 && (
            <Flex justify="space-between" align="center">
              <Text weight={500} color="gray.6" tt="uppercase" size="xs">
                By Dimension
              </Text>
              <AddMetricPopover
                metrics={columnsByType.filter((m) => m.isDimension)}
                onAdd={(metric) => {
                  metric.active
                    ? addColumnToMetrics(metric.key)
                    : removeColumnFromMetrics(metric.key);
                  setBuilder({
                    ...builder!,
                    columns: metric.active
                      ? [...builder.columns, { columnId: metric.key, aggFunction: 'SUM' }]
                      : builder!.columns.filter((c) => c.columnId !== metric.key),
                  });
                }}
              />
            </Flex>
          )}
          {columnsByType.filter((m) => m.active && m.isDimension).length > 0
            ? columnsByType
                .filter((m) => m.active && m.isDimension)
                .map((m) => (
                  <BuilderBadge
                    key={`metric-dimension-${m.key}`}
                    text={m.name}
                    type="dimension"
                    onRemove={() => {
                      m.active = false;
                      setBuilder({
                        ...builder!,
                        columns: builder!.columns.filter((c) => c.columnId !== m.key),
                      });
                    }}
                    onHideChange={() => onHideMetricChanged(m.key)}
                  />
                ))
            : builder?.columns.length > 0 && (
                <Text color="gray.4" size="xs" weight={500}>
                  Example: by week, by country
                </Text>
              )}
        </Flex>
      </TableBuilderSection>
      <TableBuilderSection number={3}>
        <Flex direction="column" gap="md">
          <Flex justify="space-between" align="center">
            <Text weight={500} color="gray.8" size="sm">
              Add Filters
            </Text>
            <AddMetricPopover
              metrics={columnsByType.map((m) => ({
                ...m,
                active: builder?.filters.some((f) => f.column === m.key),
              }))}
              onAdd={(metric) =>
                setBuilder({
                  ...builder!,
                  filters: metric.active
                    ? [
                        ...builder.filters,
                        {
                          column: metric.key,
                          operator: '=',
                          value: '',
                          visible: true,
                        },
                      ]
                    : builder!.filters.filter((c) => c.column !== metric.key),
                })
              }
            />
            {/* <Button variant="white" size="xs" onClick={() => setFiltersOpen(true)}>
            Add
          </Button> */}
          </Flex>

          {builder?.filters.map((filter) => (
            <BuilderBadge
              key={`filter-${filter.column}`}
              text={`${columnsByType.find((m) => filter.column === m.key)?.name} ${
                filter.operator
              } ${filter.value}`}
              type="filter"
              onClick={() => setFilterToEdit(filter)}
              onRemove={() =>
                setBuilder({
                  ...builder!,
                  filters: builder!.filters.filter((f) => f.column !== filter.column),
                })
              }
            />
          ))}
        </Flex>
      </TableBuilderSection>
      <FilterFieldModal
        isOpen={!!filterToEdit}
        onClose={() => setFilterToEdit(undefined)}
        parameter={filterToEdit}
        parameters={builder.filters}
        column={columns.find((c) => c.id === filterToEdit?.column)}
        columns={columns}
        parametersChange={(parameters) => {
          setBuilder({
            ...builder,
            filters: parameters,
          });
        }}
      />
    </Flex>
  );
};

type TableBuilderSectionProps = {
  number: number;
  children: React.ReactNode;
};
const TableBuilderSection: FC<TableBuilderSectionProps> = ({ number, children }) => {
  return (
    <Flex borderBottom="1px solid var(--mantine-color-gray-2)">
      <Flex
        direction="column"
        align="center"
        justify="center"
        w={40}
        borderRight="1px solid var(--mantine-color-gray-2)"
      >
        <Text weight={500} color="gray.5" size="lg">
          {number}
        </Text>
      </Flex>
      <Grow p="md">{children}</Grow>
    </Flex>
  );
};

type AddMetricPopoverProps = {
  metrics: WillyMetric[];
  onAdd: (metric: WillyMetric) => void;
};

const AddMetricPopover: FC<AddMetricPopoverProps> = ({ metrics, onAdd }) => {
  return (
    <SelectWithSearchInput
      value={metrics.filter((m) => m.active).map((m) => m.key)}
      data={metrics.map((m) => ({ label: m.name, value: m.key }))}
      onChange={(val) => {
        const metric = metrics.find((m) => m.key === val);
        if (metric) {
          metric.active = !metric.active;
          onAdd(metric);
        }
      }}
      activator={(onClick) => (
        <div>
          <Button variant="white" size="xs" onClick={onClick}>
            Add
          </Button>
        </div>
      )}
      position="right-start"
      offset={25}
    />
  );
};
