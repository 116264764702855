import { DataTable, DropZone } from '@shopify/polaris';
import { TASK_STATUS } from '@tw/types/module/services/subscription-manager';
import { Button, Flex, Grid, Modal, Text } from '@tw/ui-components';
import CURRENCIES from 'constants/currencies';
import {
  hidePrompt,
  importShippingByOrderTableOnPress,
  shippingImportByOrderOnClose,
  showPrompt,
} from 'ducks/actions';
import { setOnboardingTaskDone } from 'ducks/shop';
import { useAppDispatch } from 'index';
import { FC, useState } from 'react';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { RootState } from 'reducers/RootType';
import CSVExport from 'utils/CSVExport';
import CSVImport from 'utils/CSVImport';
import axiosInstance from 'utils/axiosInstance';

interface Props {
  isOpen: boolean;
  onClose: () => void;
}

export const ImportShippingCostsModal: FC<Props> = ({ isOpen, onClose }) => {
  const dispatch = useAppDispatch();
  const currentShopId = useSelector((state: RootState) => state.currentShopId);
  const importShippingByOrderIsOpen = useSelector(
    (state: RootState) => state.importShippingByOrderTableIsOpen,
  );
  const currency = useSelector((state: RootState) => state.currency);
  const costSettingsCompletion = useSelector((state: RootState) => state.costSettingsCompletion);
  const saveShippingCostsByOrder = async (costs) => {
    try {
      const url = `/v2/shopify/import-shipping-by-order-id`;
      const res = await axiosInstance.post(url, {
        attributes: {
          shopId: currentShopId,
        },
        data: { costs },
      });
      const { updated, notUpdated } = res?.data?.results;
      if (updated > 0) {
        if (notUpdated > 0)
          toast.info(`File successfully imported! ${updated} updated, ${notUpdated} failed`);
        else toast.success(`File successfully imported! ${updated} updated`);
        const { tasks } = costSettingsCompletion;
        if (tasks.find((task) => task.name === 'Shipping')?.status !== TASK_STATUS.COMPLETE)
          dispatch(setOnboardingTaskDone(currentShopId, 'shippingCosts'));
      } else {
        toast.error(`Error! File import failed`);
      }
    } catch (e) {
      toast.error('Error! File import failed');
    }
  };
  return (
    <Modal opened={isOpen} onClose={onClose} title="Import Shipping Costs By Order ID CSV">
      <Flex direction="column">
        <div>
          <div className="w-full h-[1px] bg-[#E5E7EB] mb-5"></div>
          <div className="pb-5">
            <Text weight={600}>1. Download Template</Text>
          </div>
          <Button
            onClick={() =>
              CSVExport([{ order_id: '', shipping_cost: '' }], 'shipping_by_order_id', false)
            }
          >
            Export CSV File
          </Button>
        </div>
        <div className="w-full h-[1px] bg-[#E5E7EB] my-5"></div>
        <div>
          <div className="pb-5">
            <Text weight={600}>2. Edit Costs in the file</Text>
          </div>
          <img src="./shippingCSVByOrder.png" alt="CSV Format Instructions" />
        </div>
        <div className="w-full h-[1px] bg-[#E5E7EB] my-5"></div>
        <div>
          <div className="pb-5">
            <Text weight={600}>3. Upload modified file </Text>
          </div>
          <Button onClick={() => dispatch(importShippingByOrderTableOnPress())}>
            Import CSV File
          </Button>
        </div>
      </Flex>
      <div className="pointer-events-none opacity-0" style={{ height: '0px' }}>
        <DropZone
          openFileDialog={importShippingByOrderIsOpen}
          onDrop={(files) => {
            var reader = new FileReader();
            reader.onload = async function (e) {
              var importedData = CSVImport(reader.result);
              dispatch(
                showPrompt({
                  title: 'Import Shipping by Order ID CSV',
                  children:
                    importedData?.length > 0 ? (
                      <DataTable
                        columnContentTypes={['text', 'text']}
                        headings={['Order ID', 'Shipping Cost']}
                        rows={importedData?.map(({ order_id, shipping_cost }) => [
                          <div style={{ width: '100px' }}>{order_id}</div>,
                          <div style={{ width: '100px' }}>
                            {isNaN(+shipping_cost)
                              ? `Invalid cost`
                              : `${CURRENCIES[currency]}${shipping_cost}`}
                          </div>,
                        ])}
                      />
                    ) : (
                      <div>No data imported</div>
                    ),
                  primaryAction: {
                    content: 'Import',
                    onAction: () => {
                      saveShippingCostsByOrder(importedData);
                      dispatch(hidePrompt());
                      dispatch(shippingImportByOrderOnClose());
                      onClose();
                    },
                    disabled: importedData?.length === 0,
                  },
                }),
              );
              // toast
            };
            reader.readAsText(files[0]);
          }}
          onFileDialogClose={() => dispatch(shippingImportByOrderOnClose())}
        ></DropZone>
      </div>
    </Modal>
  );
};
