import { ReportsActionResult } from '@tw/types/module/willy/langchain/langchain';
import {
  GoogleSheetsReports,
  GoogleSheetsReportsList,
} from '../../../../Reports/GoogleSheetsReportsList';
import { createDefaultReports } from '../../../../Reports/GoogleSheetsReportBuilder';
import { useIntegrationsConfig } from '../../../../../routes/integrations/hooks/useIntegrationsConfig';
import { IntegrationWidget } from '../../../../../routes/integrations/components/IntegrationWidget';
import { ReportsComponent } from '../../../../Reports/ReportsSection';

export const WillyActionReports: React.FC<{ data: ReportsActionResult }> = ({ data }) => {
  const { reportsType, data: reportData } = data;
  const { isGoogleSheet } = reportData;
  const { title, frequency, startDate, endDate, oneTime, granularity } = reportData;
  const integrationsWidgets = useIntegrationsConfig();
  const w = integrationsWidgets.find((w) => w.id === 'google-sheets')!;
  w.isFromChat = true;
  const isConnected = w.providerState && w.providerState?.status != 'disconnected';

  return (
    <>
      {reportsType === 'create' && isGoogleSheet && (
        <>
          {isConnected && (
            <GoogleSheetsReports
              defaultReports={createDefaultReports({
                title,
                frequency: granularity,
                startDate,
                endDate,
                oneTime: !oneTime ? true : oneTime === 'one-time',
              })}
              isFromActions={true}
            />
          )}
          {!isConnected && (
            <div>
              <div className={'text-3xl'}>You need to connect to Google Sheets first</div>
              <IntegrationWidget {...w} />
            </div>
          )}
        </>
      )}
      {(reportsType === 'edit' || reportsType === 'delete') && isGoogleSheet && (
        <>
          {isConnected && reportData.reportId === 'unknown' && (
            <>
              <div className={'text-3xl mb-10'}>
                Could not find the report, here all the existing google sheets reports:
              </div>
              <GoogleSheetsReportsList />
            </>
          )}
          {isConnected && reportData.reportId !== 'unknown' && (
            <GoogleSheetsReportsList reportId={reportData.reportId} actionType={reportsType} />
          )}
          {!isConnected && (
            <div>
              <div className={'text-3xl'}>You need to connect to Google Sheets first</div>
              <IntegrationWidget {...w} />
            </div>
          )}
        </>
      )}
      {(reportsType === 'edit' || reportsType === 'delete' || reportsType === 'create') &&
        !isGoogleSheet && (
          <>
            {reportData.reportId === 'unknown' && (
              <>
                <div className={'text-3xl'}>
                  Could not find the report, here all the existing reports:
                </div>
                <ReportsComponent actionType={reportsType} isFromAction={true} />
              </>
            )}
            {reportData.reportId !== 'unknown' && (
              <ReportsComponent
                actionData={reportData}
                isFromAction={true}
                actionType={reportsType}
                reportId={reportData.reportId}
              />
            )}
          </>
        )}
    </>
  );
};
