import { $derived, $store, $observer, $debouncedEffect } from '@tw/snipestate';
import { $customViews } from '$stores/willy/$customViews';
import { $shopDashboards, $shopDashesLoading } from '$stores/willy/$shopDashboards';
import { $formattedGlobalCategories } from '$stores/willy/$globalDashboardCategories';
import { $currentShopId } from '$stores/$shop';
import { $userId } from '$stores/$user';
import type { AugmentedDashboard, CategoryListItem } from './types';
import type { WillyDashboardElement } from 'components/Willy/types/willyTypes';
import _db from 'utils/DB';
import { categoryDoc, createInitialCategoriesList, syncDashboardsAndCategories } from './utils';
import { isEqual } from 'lodash';

class DashboardCategoryManager {
  private _cleanupQueue = new Set<() => void>();

  private async fetchCurrentShopCategories() {
    const categories = (await categoryDoc().get()).data()?.categories;
    if (Array.isArray(categories)) return categories as CategoryListItem[];
  }

  private async waitForShopDashes(): Promise<boolean> {
    return new Promise((res) => {
      $debouncedEffect((unsub, get) => {
        if (get($shopDashesLoading)) return;
        unsub();
        res(true);
      });
    });
  }

  private async fetchRelevantGlobalCategories() {
    const globalCats = await new Promise<CategoryListItem[]>((res) => {
      $debouncedEffect((unsub, get) => {
        const { loading, data } = get($formattedGlobalCategories);

        if (!loading) {
          unsub();
          res(data);
        }
      });
    });

    const dashMap = this.$dashboardsMap.get();
    return globalCats.map((c) => {
      return {
        ...c,
        children: c.children.filter((child) => {
          // if the categories are equal, don't filter child
          return c.id === dashMap.get(child.id)?.category;
        }),
      };
    });
  }

  public async init() {
    try {
      // first try to see if this shop already has some config for categories
      const [initialShopCats] = await Promise.all([
        this.fetchCurrentShopCategories(),
        this.waitForShopDashes(),
      ]);
      if (initialShopCats?.length) return this.$initialized.set(true);

      // if no current categories for this shop, replace with relevant global categories as default
      // we might be dealing with shops that have a lot of custom dashboards, but they don't have
      // any category configuration yet b/c of migration - in this case, we need to make sure the
      // combination of global categories fits as best as possible with whatever new config they have.
      const globalCats = await this.fetchRelevantGlobalCategories();
      const dashMap = this.$dashboardsMap.get();
      const initialList = createInitialCategoriesList(dashMap, null, globalCats);

      await categoryDoc().set({ categories: initialList }, { merge: true });
    } catch (err) {
      console.error('Error initializing DashboardCategoryManager:', err);
    } finally {
      this.$initialized.set(true);
    }
  }

  private $initialized = $store(false);

  /** Map of dashboard id for shop dashboards and custom views to the dashboard itself */
  public readonly $dashboardsMap = $derived((get) => {
    const dashboards = get($shopDashboards);
    const customViews = get($customViews);

    const map = dashboards.reduce(
      (map, d, i) => (map.set(d.id, { ...d, index: i, customViews: [] }), map),
      new Map<string, AugmentedDashboard>(),
    );

    for (const cv of customViews) {
      const parentDashId = cv.isCustomViewOfDashboardId;
      const dashboard = parentDashId ? map.get(parentDashId) : null;
      if (dashboard) dashboard.customViews.push(cv);
    }

    return map;
  });

  public readonly $shopCategoriesSnapshot = $observer(
    null as CategoryListItem[] | null,
    async (get, set) => {
      const shopId = get($currentShopId);
      const uid = get($userId);
      if (!shopId || !uid) return;

      const unsub = categoryDoc().onSnapshot((snapshot) => {
        const data = snapshot.data()?.categories || null;
        set(data);
      });

      this._cleanupQueue.add(unsub);

      return () => {
        this._cleanupQueue.delete(unsub);
        unsub();
      };
    },
  );

  public readonly $categoriesList = $observer(
    {
      loading: true,
      list: [] as CategoryListItem[],
    },
    (get, set) => {
      if (!get(this.$initialized)) return;

      const dashMap = get(this.$dashboardsMap);
      const categories = get(this.$shopCategoriesSnapshot) || [];
      const list = syncDashboardsAndCategories(dashMap, categories || []);

      // if (isEqual(get().list, list)) return;
      set({ loading: false, list });
    },
  );

  private isSearchedItem(item: CategoryListItem, search: string) {
    if (!search) return true;

    if (!item.metadata?.isVisible) return false;

    if (item.type === 'item' && item.metadata?.isDefaultItem) return false;

    return !!item.value?.toLowerCase().trim().includes(search);
  }

  public readonly $filteredCategories = $derived((get) => {
    const { loading, list } = get(this.$categoriesList);
    const search = get($dashSearch).toLowerCase().trim();

    const filtered = list.reduce((acc, c) => {
      const isSearchedItem = this.isSearchedItem(c, search);

      const children = c.children.reduce((filteredChildren, child) => {
        const isSearchedChild = this.isSearchedItem(child, search);

        const filteredGrandChildren = child.children.filter((grandChild) =>
          this.isSearchedItem(grandChild, search),
        );

        if (isSearchedChild || filteredGrandChildren.length) {
          filteredChildren.push({ ...child, children: filteredGrandChildren });
        }

        return filteredChildren;
      }, [] as CategoryListItem[]);

      if (isSearchedItem || children.length) acc.push({ ...c, children });

      return acc;
    }, [] as CategoryListItem[]);

    return { filtered, loading };
  });

  public off() {
    this._cleanupQueue.forEach((f) => f());
  }
}

let dashboardCategoryManager: DashboardCategoryManager | null = null;
export function getDashboardCategoryManager() {
  if (!dashboardCategoryManager) {
    dashboardCategoryManager = new DashboardCategoryManager();
  }
  return dashboardCategoryManager;
}

export function initDashboardCategoryManager() {
  getDashboardCategoryManager().init();
}

export function turnOffDashboardCategoryManager() {
  getDashboardCategoryManager().off();
  dashboardCategoryManager = null;
}

/** Map of dashboard id for shop dashboards and custom views to the dashboard itself */
export const $dashboardsMap = $derived((get) => get(getDashboardCategoryManager().$dashboardsMap));

export const $filteredCategories = $derived((get) =>
  get(getDashboardCategoryManager().$filteredCategories),
);

export const $categoriesList = $derived((get) =>
  get(getDashboardCategoryManager().$categoriesList),
);

const MAIN_CATS = ['favorites', 'standard', 'custom'] as const;
type MainCat = (typeof MAIN_CATS)[number];
export const $dashSidePanelOpenMainCats = $store<MainCat[]>([...MAIN_CATS]);

/** Opens the categories specified in the array. Closes those that aren't. */
export const setMultipleSidePanelCats = (newCats: MainCat[]) => {
  $dashSidePanelOpenMainCats.set(newCats);
};

/** Toggles the open state of the category provided. */
export const toggleSidePanelMainCats = (cat: MainCat) => {
  $dashSidePanelOpenMainCats.set((cats) => {
    if (cats.includes(cat)) {
      return cats.filter((c) => c !== cat);
    }
    return [...cats, cat];
  });
};

export const $dashSearch = $store('');

export const $filteredDashboardsBySearch = $derived((get) => {
  const dashboardMapVals = get($dashboardsMap).values();
  const search = get($dashSearch).toLowerCase();
  const filtered: WillyDashboardElement[] = [];
  const searchFields = ['name', 'description'];

  for (const d of dashboardMapVals) {
    const isMatch = searchFields.some((field) => {
      return !!d[field]?.toLowerCase().trim().includes(search);
    });
    if (isMatch) filtered.push(d);
  }

  return filtered;
});
