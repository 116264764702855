import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { BqTable, CustomColumn } from './dataStuff/tables';
import { ActionList, Listbox, Popover } from '@shopify/polaris';
import {
  ChevronDownMinor,
  ChevronRightMinor,
  MobileVerticalDotsMajor,
} from '@shopify/polaris-icons';
import {
  Accordion,
  ActionIcon,
  Badge,
  Button,
  confirm,
  Flex,
  Icon,
  Modal,
  Select,
  Text,
  Tooltip,
} from '@tw/ui-components';
import { BqColumn, EVENT_DATE } from './dataStuff/columns/types';
import { buildQueryFromSchema } from './dataStuff/buildQuery';
import { useSelector } from 'react-redux';
import { RootState } from '../../reducers/RootType';
import {
  addFilterColumn,
  removeFilterColumnById,
  resetAllFilters,
  useActiveFilterBuilder,
  useIsFilteredByColumn,
} from './SqlFilterBuilder';
import { getSocket } from '../../components/Willy/WillySocket';
import { applyPivot } from './dataStuff/applyPivot';
import axiosInstance from 'utils/axiosInstance';
import { deleteCustomView } from './dataStuff/utils';
import { EditorInstance } from 'components/Willy/types/willyTypes';
import { editor } from 'monaco-editor';
import { $tabs } from '$stores/willy/$tables';
import useDebounce from 'utils/useDebounce';
import _db from 'utils/DB';
import { startCase } from 'lodash';
import { WillySearchInput } from 'components/Willy/WillySearchInput';
import { formatSqlSafely } from 'components/Willy/utils/willyUtils';
import { SyncModal } from './SyncModal';
import { useFeatureFlag } from 'feature-flag-system';
import { FeatureFlag } from '@tw/feature-flag-system/module/types';
import { createNewTab } from './createNewTab';
import { Monaco } from '@monaco-editor/react';
import { CreateCustomDerivedColumnModal } from './CreateCustomDerivedColumnModal';
import { $willyCustomMetrics } from '$stores/willy/$willyMetrics';
import { extractAllTablesFromCustomMetric } from 'components/Willy/WillyMetrics/utils';
import {
  AggregationFunction,
  AggregationFunctions,
  ColumnGroupType,
} from '@tw/willy-data-dictionary/module/columns/types';
import { useComputedValue, useStoreValue, useWritableStore } from '@tw/snipestate';

type updateTableData = {
  action: 'invert' | 'data';
  key?: string;
  data?: any;
};
const updateTables = (
  tableId: string,
  data: updateTableData,
  columnId?: string,
  parentColumn?: BqColumn,
) => {
  const activeEditorTab = $tabs.get().find((t) => t.active)?.model?.id;

  $tabs.set((oldTab) => {
    return oldTab.map((tab) => {
      if (tab.model.id === activeEditorTab) {
        return {
          ...tab,
          schema: tab.schema.map((t) => {
            const thisTable = t.id === tableId;

            const extractData = (entity: any, data: updateTableData) => {
              if (data.action === 'invert') {
                return { [data.key!]: !entity[data.key!] };
              } else {
                return data.data;
              }
            };

            if (!thisTable) {
              return t;
            }

            if (!columnId && !parentColumn?.id) {
              const d = extractData(t, data);
              return { ...t, ...d };
            }

            if (columnId && !parentColumn?.id) {
              return {
                ...t,
                columns: t.columns.map((c) => {
                  if (c.id === columnId) {
                    const d = extractData(c, data);
                    return { ...c, ...d };
                  }
                  return c;
                }),
              };
            }

            if (columnId && parentColumn) {
              return {
                ...t,
                columns: t.columns.map((c2) => {
                  if (c2.id === parentColumn.id) {
                    return {
                      ...c2,
                      columns:
                        c2.columns?.map((c3) => {
                          if (c3.id === columnId) {
                            const d = extractData(c3, data);
                            return { ...c3, ...d };
                          }
                          return c3;
                        }) ?? [],
                    };
                  }
                  return c2;
                }),
              };
            }
          }),
        };
      }
      return tab;
    });
  });
};

export const useActiveTables = () => {
  const activeEditor = useComputedValue($tabs, (tabs) => {
    return tabs.find((t) => t.active);
  });

  return useMemo(() => {
    if (!activeEditor) return [] as BqTable[];
    return activeEditor.schema;
  }, [activeEditor]);
};

export const useActiveQueryAI = () => {
  const tabs = useStoreValue($tabs);

  const activeEditor = useMemo(() => {
    return tabs.find((t) => t.active);
  }, [tabs]);

  return useMemo(() => {
    if (!activeEditor) return undefined;
    return activeEditor.sqlBuilderAI;
  }, [activeEditor]);
};

type SchemaTablesProps = {
  editorRef?: React.MutableRefObject<editor.IStandaloneCodeEditor | undefined>;
  monacoRef?: React.MutableRefObject<Monaco | undefined>;
  setCustomViews: React.Dispatch<React.SetStateAction<BqTable[]>>;
};

export const SchemaTables: React.FC<SchemaTablesProps> = ({
  editorRef,
  monacoRef,
  setCustomViews,
}) => {
  const tables = useActiveTables();
  // const willyCustomMetrics = useStoreValue($willyCustomMetrics)

  // const customMetricsWithTables = useMemo(() => {
  //   if (willyCustomMetrics.length === 0) {
  //     return willyCustomMetrics;
  //   }

  //   return willyCustomMetrics.map((metric) => {
  //     const a = extractAllTablesFromCustomMetric(metric);

  //     return {
  //       ...metric,
  //       tables: a,
  //     };
  //   });
  // }, [willyCustomMetrics]);

  // console.log('customMetricsWithTables', customMetricsWithTables);

  const [tabs, setTabs] = useWritableStore($tabs);
  const standardTables = tables.filter((x) => !x.type || x.type === 'standard');
  const views = tables.filter((x) => x.type === 'view');
  const customTables = tables.filter((x) => x.type === 'custom_table');
  const customViews = tables.filter((x) => x.type === 'custom_view');

  const tableFunctions = tables.filter((x) => x.type === 'table_function');

  const currentShopId = useSelector((state: RootState) => state.currentShopId);

  const [errorUploadFile, setErrorUploadFile] = useState<string | null>(null);
  const [showSyncModal, setShowSyncModal] = useState(false);
  const [addCustomDerivedColumnModalOpen, setAddCustomDerivedColumnModalOpen] = useState<boolean>();
  const [customDerivedColumn, setCustomDerivedColumn] = useState<CustomColumn>();
  const [freeSearch, setFreeSearch] = useState('');
  const fileInputRef = useRef(null);
  const debouncedSearch = useDebounce(freeSearch, 300);
  const { shouldNotBeSeen: isSyncWarehouseBlocked } = useFeatureFlag(FeatureFlag.WAREHOUSE_SYNC_FF);

  const [openedSections, setOpenedSections] = useState<string[]>([]);

  const openNewTab = useCallback(
    (view: BqTable, val: string) => {
      if (!monacoRef?.current || !editorRef?.current) {
        return;
      }
      const formattedVal = formatSqlSafely(val);

      const newTab = createNewTab(
        {
          index: tabs.length + 1,
          language: 'sql',
          schema: tables,
          editorRef: editorRef.current,
          monacoRef: monacoRef.current,
          initialCode: formattedVal,
          name: view.name,
          active: true,
          jsonData: '',
          savedQueryType: 'query',
          queryId: view.docId,
        },
        setTabs,
      );

      setTabs((old) => {
        if (view.docId && old.some((x) => x.queryId === view.docId)) {
          return old.map((x) => ({
            ...x,
            active: x.queryId === view.docId,
          }));
        }

        return old
          .map<EditorInstance>((x) => ({
            ...x,
            active: false,
          }))
          .concat(newTab);
      });
    },
    [monacoRef, editorRef, tabs.length, tables, setTabs],
  );

  const handleFileUploadClick = () => {
    if (fileInputRef.current) (fileInputRef.current as any).click();
  };

  const handleFileChange = async (event) => {
    const file = event.target.files[0];
    if (file && file.type === 'text/csv') {
      await uploadFile(file);
    } else {
      alert('Please select a CSV file.');
    }
  };

  const closeOptions = useCallback(
    (t: BqTable) => {
      setTabs((old) => {
        return old.map((oldTab) => {
          if (oldTab.active) {
            return {
              ...oldTab,
              schema: oldTab.schema.map((t2) => {
                const thisGuy = t.id === t2.id;
                const optionsOpen = thisGuy ? !t2.optionsOpen : false;
                if (t2.id === t.id) {
                  return { ...t2, optionsOpen: !!optionsOpen };
                }
                return t2;
              }),
            };
          }
          return oldTab;
        });
      });
    },
    [setTabs],
  );

  const uploadFile = async (file) => {
    const formData = new FormData();
    formData.append('file', file);
    formData.append('shopId', currentShopId);

    try {
      const { data } = await axiosInstance.post('/v2/willy/upload-csv-and-create-table', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
    } catch (e) {
      console.error(e);
      setErrorUploadFile('Error uploading file, try again');
    }
  };

  const sections = useMemo(
    () => [
      {
        title: 'Views',
        id: 'views',
        tables: views,
      },
      ...(tableFunctions.length > 0
        ? [
            {
              title: 'Table Functions',
              id: 'tableFunctions',
              tables: tableFunctions,
            },
          ]
        : []),
      {
        title: 'Tables',
        id: 'tables',
        tables: standardTables,
      },
      {
        title: 'Custom Tables',
        id: 'customTables',
        tables: customTables,
      },
      {
        title: 'Custom Views',
        id: 'customViews',
        tables: customViews,
      },
    ],
    [customTables, customViews, standardTables, views, tableFunctions],
  );

  const filteredSections = useMemo<typeof sections>(() => {
    const sectionsWithColumns = sections.map((section) => {
      return {
        ...section,
        tables: section.tables
          .map<BqTable>((t) => {
            return {
              ...t,
              expanded: t.expanded || !!debouncedSearch,
              columns: t.columns.filter((c) => {
                if (!debouncedSearch) {
                  return true;
                }
                return c.id?.toLowerCase?.().includes(debouncedSearch.toLowerCase());
              }),
            };
          })
          .filter(
            (t) =>
              t.columns.length > 0 ||
              !debouncedSearch ||
              t.name.toLowerCase().includes(debouncedSearch.toLowerCase()),
          ),
      };
    });

    return sectionsWithColumns.filter((section) => section.tables.length > 0);
  }, [debouncedSearch, sections]);

  return (
    <>
      <Accordion defaultValue={sections.map((x) => x.id)} multiple chevron={null}>
        <WillySearchInput
          value={freeSearch}
          onChange={(v) => setFreeSearch(v)}
          placeholder="Search table"
        />
        <div className="p-6.5 pt-0">
          <Button
            leftSection="plus-circle"
            variant="white"
            size="xs"
            fullWidth
            onClick={() => {
              setAddCustomDerivedColumnModalOpen(true);
            }}
          >
            Add custom derived column
          </Button>
        </div>
        {filteredSections.map((section, i) => (
          <Accordion.Item key={`${section.id + i}`} value={section.id}>
            <Accordion.Control
              onClick={() => {
                setOpenedSections((s) => {
                  if (s.includes(section.id)) return s.filter((o) => o !== section.id);
                  return [...s, section.id];
                });
              }}
              icon={
                <span
                  style={{
                    display: 'inline-block',
                    transform: openedSections.includes(section.id) ? 'rotate(270deg)' : undefined,
                  }}
                >
                  <Icon size={13} name="arrow-down-3" />
                </span>
              }
            >
              <span className="font-semibold text-2xl whitespace-nowrap">
                {section.title} ({section.tables.length})
              </span>
            </Accordion.Control>
            <Accordion.Panel>
              <Listbox
                onSelect={(e) => {
                  if (tables.some((x) => x.optionsOpen)) return;

                  updateTables(e, { action: 'invert', key: 'expanded' });
                }}
              >
                {section.tables.map((t, i) => (
                  <div
                    key={`${t.id}_${i}`}
                    className="flex flex-col gap-4 mb-4"
                    onClick={(e) => {
                      e.stopPropagation();
                      e.preventDefault();
                    }}
                  >
                    <Listbox.Option value={t.id}>
                      <div className="flex overflow-hidden w-full">
                        {t.expanded ? (
                          <div className="inline-block mr-2">
                            <Icon name="arrow-down-3" size={10} />
                          </div>
                        ) : (
                          <div
                            className="inline-block mr-2"
                            style={{ transform: 'rotate(-90deg)' }}
                          >
                            <Icon name="arrow-down-3" size={10} />
                          </div>
                        )}
                        <p className="cursor-pointer whitespace-nowrap text-ellipsis overflow-hidden dark:text-gray-400">
                          {t.name}
                        </p>
                        {t.isBeta && (
                          <div className="ml-2">
                            <Badge size="sm">Beta</Badge>
                          </div>
                        )}
                        <span className="w-6 ml-auto cursor-pointer flex-shrink-0">
                          <Popover
                            activator={
                              <MobileVerticalDotsMajor
                                className="dark:fill-gray-400 fill-[var(--mantine-color-gray-5)]"
                                onClick={(e) => {
                                  e.stopPropagation();
                                  e.preventDefault();

                                  closeOptions(t);
                                }}
                              />
                            }
                            active={!!t.optionsOpen}
                            onClose={() => {
                              updateTables(t.id, { action: 'invert', key: 'optionsOpen' });
                            }}
                          >
                            <ActionList
                              items={[
                                {
                                  content: 'Query',
                                  onAction: () => {
                                    const existsEventDate = t.columns.some(
                                      (column) => column.id === 'event_date',
                                    );
                                    const defaultQuery =
                                      t.defaultQuery ||
                                      `SELECT * FROM ${t.id} ${
                                        existsEventDate ? 'WHERE event_date = current_date()' : ''
                                      } LIMIT 100`;
                                    openNewTab(t, defaultQuery);
                                    editorRef?.current?.focus();
                                    closeOptions(t);
                                  },
                                },
                                ...(t.readmeUrl
                                  ? [
                                      {
                                        content: 'Schema',
                                        onAction: () => {
                                          window
                                            .open(
                                              `https://triplewhale.readme.io/reference/${t.readmeUrl}`,
                                            )
                                            ?.focus();
                                          closeOptions(t);
                                        },
                                      },
                                    ]
                                  : []),
                                ...(t.underlyingView
                                  ? [
                                      {
                                        content: 'Underlying View',
                                        onAction: () => {
                                          const val = t.underlyingView || '-- No underlying view';
                                          openNewTab(t, val);
                                          editorRef?.current?.focus();
                                          closeOptions(t);
                                        },
                                      },
                                    ]
                                  : []),
                                ...(t.isDeletable
                                  ? [
                                      {
                                        content: 'Delete',
                                        onAction: async () => {
                                          if (t.type === 'custom_table') {
                                            const { data } = await axiosInstance.delete(
                                              '/v2/willy/delete-custom-table/' +
                                                currentShopId +
                                                '/' +
                                                t.id,
                                            );
                                          } else if (t.type === 'custom_view') {
                                            deleteCustomView(t.docId!);
                                            setCustomViews((old) => {
                                              return old.filter((x) => x.docId !== t.docId);
                                            });
                                          }
                                          closeOptions(t);
                                        },
                                      },
                                    ]
                                  : []),
                              ]}
                            />
                          </Popover>
                        </span>
                      </div>
                    </Listbox.Option>
                    {t.expanded && (
                      <div className="flex flex-col gap-2 ml-4">
                        <ColumnsGroupTemplate
                          columns={t.columns.filter((x) => x.type === 'parameter')}
                          table={t}
                          type={'parameters'}
                          setCustomDerivedColumn={setCustomDerivedColumn}
                          openNewTab={openNewTab}
                        />
                        <ColumnsGroupTemplate
                          columns={t.columns.filter(
                            (x) =>
                              x.type !== 'numeric' &&
                              x.type !== 'formula' &&
                              x.type !== 'parameter',
                          )}
                          table={t}
                          type={'dimensions'}
                          setCustomDerivedColumn={setCustomDerivedColumn}
                          openNewTab={openNewTab}
                        />
                        <ColumnsGroupTemplate
                          columns={t.columns.filter((x) => x.type === 'numeric')}
                          table={t}
                          type={'measures'}
                          setCustomDerivedColumn={setCustomDerivedColumn}
                          openNewTab={openNewTab}
                        />
                        <ColumnsGroupTemplate
                          columns={t.columns.filter((x) => x.type === 'formula')}
                          table={t}
                          type={'derived'}
                          setCustomDerivedColumn={setCustomDerivedColumn}
                          openNewTab={openNewTab}
                        />
                      </div>
                    )}
                  </div>
                ))}
                {section.id === 'customTables' && (
                  <div className="flex flex-col gap-2">
                    <Tooltip label={isSyncWarehouseBlocked ? 'Upgrade to access this feature' : ''}>
                      <Button
                        variant="white"
                        size="xs"
                        onClick={() => {
                          setShowSyncModal(true);
                        }}
                        disabled={isSyncWarehouseBlocked}
                        leftSection={isSyncWarehouseBlocked ? 'lock' : null}
                      >
                        Sync Warehouse
                      </Button>
                    </Tooltip>
                    <Button
                      variant="white"
                      size="xs"
                      onClick={() => {
                        handleFileUploadClick();
                      }}
                    >
                      Sync CSV
                    </Button>
                    {errorUploadFile && <Text>{errorUploadFile}</Text>}
                  </div>
                )}
              </Listbox>
            </Accordion.Panel>
          </Accordion.Item>
        ))}
        <input
          type="file"
          ref={fileInputRef}
          onChange={handleFileChange}
          style={{ display: 'none' }}
          accept=".csv"
        />
        <SyncModal showSyncModal={showSyncModal} setShowSyncModal={setShowSyncModal} />
      </Accordion>
      <CreateCustomDerivedColumnModal
        opened={!!addCustomDerivedColumnModalOpen || !!customDerivedColumn}
        customDerivedColumn={customDerivedColumn}
        onClose={() => {
          setAddCustomDerivedColumnModalOpen(false);
          setCustomDerivedColumn(undefined);
        }}
      />
    </>
  );
};

const EventDateGroupTemplate: React.FC<{
  table: BqTable;
  columns: BqColumn[];
  parentColumn: BqColumn;
  setCustomDerivedColumn: React.Dispatch<React.SetStateAction<CustomColumn | undefined>>;
  openNewTab: (view: BqTable, val: string) => void;
}> = ({ table, columns, parentColumn, setCustomDerivedColumn, openNewTab }) => {
  return (
    <div className="ml-5 list-none">
      {columns.map((c) => (
        <ColumnTemplate
          type={'derived'}
          key={c.id}
          table={table}
          column={c}
          parentColumn={parentColumn}
          setCustomDerivedColumn={setCustomDerivedColumn}
          openNewTab={openNewTab}
        />
      ))}
    </div>
  );
};

type ColumnsGroupTemplateProps = {
  table: BqTable;
  columns: BqColumn[];
  type: ColumnGroupType;
  parentColumn?: BqColumn;
  setCustomDerivedColumn: React.Dispatch<React.SetStateAction<CustomColumn | undefined>>;
  openNewTab: (view: BqTable, val: string) => void;
};

const ColumnsGroupTemplate: React.FC<ColumnsGroupTemplateProps> = ({
  table,
  type,
  columns,
  parentColumn,
  setCustomDerivedColumn,
  openNewTab,
}) => {
  const [selectedAgg, setSelectedAgg] = useState<AggregationFunction>('SUM');

  const setGroupAgg = useCallback(
    (agg: AggregationFunction) => {
      setSelectedAgg(agg);
      columns.forEach((c) => {
        updateTables(table.id, { action: 'data', data: { agg } }, c.id);
      });
    },
    [columns, table.id],
  );

  if (type === 'measures' && !columns.some((x) => x.type === 'numeric')) {
    return null;
  }
  if (type === 'dimensions' && !columns.some((x) => x.type !== 'numeric')) {
    return null;
  }

  if (columns.length === 0) {
    return null;
  }

  return (
    <div className={'mb-3 ml-3'}>
      <div className="flex justify-between gap-2 items-center">
        <div className={'uppercase text-2xl dark:text-gray-200 mb-2 font-semibold truncate'}>
          {type}
        </div>
        {type === 'measures' && (
          <Tooltip label={AggregationFunctions[selectedAgg]?.description ?? ''}>
            <div className={'max-w-[100px]'}>
              <Select
                max={50}
                size="xs"
                height={20}
                data={Object.values(AggregationFunctions).map((x) => x.id)}
                value={selectedAgg}
                onChange={(val) => {
                  if (!val) return;
                  setGroupAgg(val as AggregationFunction);
                }}
                placeholder="- Func -"
              />
            </div>
          </Tooltip>
        )}
      </div>

      <div className="p-0 list-none">
        {columns
          .filter((x) => (type === 'measures' ? x.type === 'numeric' : x.type !== 'numeric'))
          .map((c) => (
            <ColumnTemplate
              type={type}
              key={c.id}
              table={table}
              column={c}
              parentColumn={parentColumn}
              setCustomDerivedColumn={setCustomDerivedColumn}
              openNewTab={openNewTab}
            />
          ))}
      </div>
    </div>
  );
};

const ColumnTemplate: React.FC<{
  table: BqTable;
  column: BqColumn;
  type?: ColumnGroupType;
  parentColumn?: BqColumn;
  setCustomDerivedColumn: React.Dispatch<React.SetStateAction<CustomColumn | undefined>>;
  openNewTab: (view: BqTable, val: string) => void;
}> = ({ table, column, parentColumn, type, setCustomDerivedColumn, openNewTab }) => {
  const [tabs, setTabs] = useWritableStore($tabs);
  const willySocket = useMemo(() => getSocket(), []);
  const [isHovered, setIsHovered] = useState(false);
  const isFilteredByColumn = useIsFilteredByColumn(table.id, column);
  const filterBuilder = useActiveFilterBuilder();
  const activeEditor = useMemo(() => {
    return tabs.find((t) => t.active);
  }, [tabs]);

  const tables = useMemo(() => {
    if (!activeEditor) return [];
    return activeEditor.schema;
  }, [activeEditor]);

  const columnClicked = useCallback(
    async (column: BqColumn) => {
      if (column.type === 'unknown') {
        return;
      }

      if (column.columns) {
        updateTables(table.id, { action: 'invert', key: 'expanded' }, column.id);
      } else {
        const queryHasOtherTable = tables.some((t) => {
          if (t.id === table.id) {
            return false;
          }
          const match = activeEditor?.query.match(new RegExp(`\\b${t.id}\\b`, 'g'));
          return match && match.length > 0;
        });
        if (queryHasOtherTable) {
          const confirmed = await confirm({
            title: 'Warning',
            message: (
              <div className={'mb-10'}>
                <Text>
                  We can only query one table at a time. this will clear all other selections, are
                  you sure you want to continue?
                </Text>
              </div>
            ),
          });
          if (!confirmed) {
            return;
          }
          setTabs((old) => {
            return old.map((oldTab) => {
              if (oldTab.active) {
                return {
                  ...oldTab,
                  schema: oldTab.schema.map((t2) => {
                    if (t2.id === table.id) {
                      return { ...t2, columns: t2.columns.map((c) => ({ ...c, selected: false })) };
                    }
                    return t2;
                  }),
                };
              }
              return oldTab;
            });
          });
        }

        updateTables(table.id, { action: 'invert', key: 'selected' }, column.id, parentColumn);
        const query = buildQueryFromSchema(table, column, parentColumn);
        $tabs.set((old) => {
          return old.map((t) => {
            if (t.model?.id === activeEditor?.model?.id) {
              return {
                ...t,
                query: query,
              };
            }
            return t;
          });
        });
      }
    },
    [table, parentColumn, tables, activeEditor?.query, activeEditor?.model?.id, setTabs],
  );

  const filterClicked = useCallback(
    (table: BqTable, column: BqColumn) => async (e) => {
      e.stopPropagation();
      e.preventDefault();
      const isWillReset =
        tables.filter((x) => x.id !== table.id).some((t) => t.columns.some((c) => c.selected)) ||
        (filterBuilder.selectedTableId && table.id !== filterBuilder.selectedTableId);
      if (isWillReset) {
        if (
          !(await confirm({
            title: 'Warning',
            message: (
              <div className={'mb-10'}>
                <Text>
                  We can only query one table at a time. this will clear all other selections, are
                  you sure you want to continue?
                </Text>
              </div>
            ),
          }))
        ) {
          return;
        }
      }
      if (isWillReset) {
        setTabs((old) => {
          return old.map((oldTab) => {
            if (oldTab.active) {
              return {
                ...oldTab,
                schema: oldTab.schema.map((t2) => {
                  if (t2.id === table.id) {
                    return { ...t2, columns: t2.columns.map((c) => ({ ...c, selected: false })) };
                  }
                  return t2;
                }),
              };
            }
            return oldTab;
          });
        });
        resetAllFilters();
      }
      if (isFilteredByColumn) {
        removeFilterColumnById(column.id);
      } else {
        addFilterColumn(table.id, column);
      }
    },
    [tables, filterBuilder.selectedTableId, isFilteredByColumn, setTabs],
  );

  const label = column.id;
  return (
    <div className={`flex flex-col gap-1 p-1  text-1xl pl-0`}>
      <div
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        className="flex justify-between gap-1 items-center w-full"
      >
        <div
          onClick={() => columnClicked(column)}
          className={`flex items-center gap-2 p-1  w-full ${
            column.selected
              ? 'dark:bg-gray-400 bg-gray-200 p-2'
              : 'hover:bg-gray-100 hover:dark:bg-gray-600 hover:p-1'
          } rounded-[4px]`}
        >
          <div className={`whitespace-nowrap flex items-center text-ellipsis overflow-hidden`}>
            {column.columns && (
              <>
                {column.expanded ? (
                  <ChevronDownMinor className="w-6 flex-shrink-0 cursor-pointer dark:fill-gray-400 mr-1" />
                ) : (
                  <ChevronRightMinor className="w-6 flex-shrink-0 cursor-pointer dark:fill-gray-400 mr-1" />
                )}
              </>
            )}
            {(column.docId || table.type === 'custom_view') && (
              <div className="flex items-center gap-1">
                <ActionIcon
                  icon="delete"
                  disabled={table.type === 'custom_view'}
                  onClick={async (e) => {
                    e.stopPropagation();
                    e.preventDefault();
                    const confirmed = await confirm({
                      title: 'Warning',
                      message: (
                        <div className={'mb-10'}>
                          <Text>Are you sure you want to delete this custom derived column?</Text>
                        </div>
                      ),
                    });
                    if (!confirmed) {
                      return;
                    }
                    await _db().collection('willy_custom_metrics_sql').doc(column.docId).delete();
                  }}
                />
                <ActionIcon
                  icon="edit"
                  onClick={(e) => {
                    console.log('column', column);

                    e.stopPropagation();
                    e.preventDefault();
                    setCustomDerivedColumn(column as CustomColumn);
                  }}
                />
              </div>
            )}
            <Tooltip
              label={
                type === 'derived'
                  ? column.description ?? column.id
                  : column.description ?? column.title
              }
            >
              <p
                className={`inline text-ellipsis dark:text-light-gray cursor-pointer ${
                  column.columns ? 'underline' : ''
                } ${column.selected ? 'font-semibold' : ''}`}
              >
                {label}
              </p>
            </Tooltip>
          </div>
          <div className="flex-shrink-0 flex justify-center">
            <Badge size="xs">{column.type}</Badge>
          </div>
        </div>
        {(isHovered || isFilteredByColumn || column.pivot) && !parentColumn && (
          <Flex>
            {(isFilteredByColumn || isHovered) && (
              <Tooltip label={`Filter by ${label}`}>
                <ActionIcon
                  variant={'transparent'}
                  size="sm"
                  onClick={filterClicked(table, column)}
                  icon={<Icon name={'filter'} color={isFilteredByColumn ? 'one.6' : 'gray.3'} />}
                />
              </Tooltip>
            )}
            {(column.pivot || isHovered) && column.options && (
              <Tooltip label={`Pivot by ${label}`}>
                <ActionIcon
                  variant={'transparent'}
                  size="sm"
                  onClick={async () => {
                    if (!column.pivot) {
                      await columnClicked(column);
                    }
                    updateTables(table.id, { action: 'invert', key: 'pivot' }, column.id);
                    // pivotByColumn(table.id, column.id, column.pivot, willySocket);
                    applyPivot(table.id, column);
                  }}
                  icon="pivot"
                  color={column.pivot ? 'one.6' : 'gray.3'}
                />
              </Tooltip>
            )}
          </Flex>
        )}
      </div>
      {column.expanded && (
        <>
          {column.type === 'record repeated' && (
            <>
              <ColumnsGroupTemplate
                columns={column.columns || []}
                table={table}
                type={'dimensions'}
                parentColumn={column}
                setCustomDerivedColumn={setCustomDerivedColumn}
                openNewTab={openNewTab}
              />
              <ColumnsGroupTemplate
                columns={column.columns || []}
                table={table}
                type={'measures'}
                parentColumn={column}
                setCustomDerivedColumn={setCustomDerivedColumn}
                openNewTab={openNewTab}
              />
            </>
          )}
          {column.id === EVENT_DATE && (
            <EventDateGroupTemplate
              table={table}
              columns={column.columns!}
              parentColumn={column}
              setCustomDerivedColumn={setCustomDerivedColumn}
              openNewTab={openNewTab}
            />
          )}
        </>
      )}
    </div>
  );
};
