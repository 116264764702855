import { AppShell, Box, Stack } from '@tw/ui-components';
import { BottomSection } from '../../common/BottomSection/BottomSection';
import { SettingsTogglerSection } from '../../common/SettingsTogglerSection';
import { TopSection } from './TopSectionDesktop';
import { MainLinksSection } from './MainLinksSection';
import { OptimizationsSection } from '../../common/OptimizationsSection';
import { useIsNavWideStyle } from '../../../stores';
import { NavResizer } from '../../blocks/NavResizer';
import { ToggleTW3Section } from '../../common/ToggleTW3Section';
import { $canToggleBetweenVersions } from '$stores/$v3_0_Enabled';
import { TOP_BAR_BORDER_VAR } from 'components/TopBar/constants';
import { useStoreValue } from '@tw/snipestate';

export const MainNavDesktop = () => {
  const canToggleBetweenVersions = useStoreValue($canToggleBetweenVersions);
  const isWide = useIsNavWideStyle();

  return (
    <>
      <Box w="100%" overflow="auto" pb="md" withScrollbar={false}>
        <Stack w="100%" h="100vh">
          <AppShell.Section px="lg" borderBottom={TOP_BAR_BORDER_VAR}>
            <TopSection />
          </AppShell.Section>

          <AppShell.Section grow px="md">
            <MainLinksSection />
          </AppShell.Section>

          <AppShell.Section px={isWide ? 'md' : undefined}>
            <BottomSection />
          </AppShell.Section>

          <AppShell.Section px={isWide ? 'md' : undefined} pt="md" borderTop={TOP_BAR_BORDER_VAR}>
            <OptimizationsSection />
          </AppShell.Section>

          <AppShell.Section px={isWide ? 'md' : undefined} pt="md" borderTop={TOP_BAR_BORDER_VAR}>
            <SettingsTogglerSection />
          </AppShell.Section>

          {canToggleBetweenVersions ? (
            <AppShell.Section px={isWide ? 'md' : undefined} py="md" borderTop={TOP_BAR_BORDER_VAR}>
              <ToggleTW3Section />
            </AppShell.Section>
          ) : (
            <Box />
          )}
        </Stack>
      </Box>
      <NavResizer />
    </>
  );
};
