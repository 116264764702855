import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { ActionList, Modal, OptionList, Popover, Spinner } from '@shopify/polaris';
import { goToPodsView } from 'utils/goToPodsView';
import { useSelector } from 'react-redux';
import { type RootState } from 'reducers/RootType';
import { useAppDispatch } from 'index';
import { useHistory } from 'react-router';
import { useIsSmall } from 'hooks/useDefaultWindowSizes';
import { $activeAppVersion } from '$stores/nav-config-stores';
import { updateActiveAccounts } from 'ducks/auth';
import { Badge, Loader, Checkbox, TextInput, Flex, Text, Size, Container } from '@tw/ui-components';
import { useFeatureFlag } from 'feature-flag-system';
import { FeatureFlag } from '@tw/feature-flag-system/module/types';
import { $activeAccounts } from '../../$stores/$shop';
import { useStoreValue } from '@tw/snipestate';

type DropDownProps = {
  value: string;
  handleSelect: (s: any) => void;
  isFromAllShops: Boolean;
  CustomActivator?: React.FC;
  customActivatorClassName?: string;
};

export const TopBarStoresSwitcher = (props: DropDownProps) => {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const {
    value,
    handleSelect,
    isFromAllShops,
    CustomActivator,
    customActivatorClassName = '',
  } = props;
  const isSmall = useIsSmall();
  const [popoverActive, setPopoverActive] = useState(false);
  const [showPopoverContent, setShowPopoverContent] = useState(false);
  const [searchShopsValue, setSearchShopsValue] = useState('');
  const [storesList, setStoresList] = useState<Array<any>>([]);

  const shopsAsArray = useSelector((state: RootState) => state.shopAsArrayFromPods);
  const currentShopId = useSelector((state: RootState) => state.currentShopId);
  const activeAccounts = useStoreValue($activeAccounts);
  const activeAppVersion = useStoreValue($activeAppVersion);

  const { shouldNotBeSeen: isMultiShopBlocked } = useFeatureFlag(FeatureFlag.MULTI_GEO_FF);

  useEffect(() => {
    let anotherStores = shopsAsArray;

    if (searchShopsValue) {
      anotherStores = anotherStores.filter((i) =>
        i.shopId.split('.')[0].includes(searchShopsValue.toLowerCase()),
      );
    }

    const allShopsItems = anotherStores.map((s) => ({
      label: s.shopName ?? s.shopId.split('.')[0],
      value: s.shopId,
    }));

    setStoresList(allShopsItems);
  }, [value, shopsAsArray, searchShopsValue]);

  const togglePopoverActive = useCallback(
    () => setPopoverActive((popoverActive) => !popoverActive),
    [],
  );
  const handleChange = (e) => {
    setPopoverActive(false);
    setSearchShopsValue('');
    return handleSelect(e[0]);
  };

  const activator = useMemo(() => {
    if (!activeAccounts) return <Loader />;
    if (CustomActivator) {
      return (
        <div
          className={`relative ${customActivatorClassName}`}
          onClick={() => {
            togglePopoverActive();
          }}
        >
          <CustomActivator />
          {activeAccounts.length > 1 && activeAppVersion === '3.0' && (
            <div className="absolute -bottom-2 -right-2 z-40 cursor-pointer select-none">
              <Badge size="xs" radius={'xl'} variant="filled">
                + {activeAccounts.length - 1}
              </Badge>
            </div>
          )}
        </div>
      );
    }
    return (
      <div className="stores-switcher-activator flex flex-auto">
        <button
          type="button"
          onClick={togglePopoverActive}
          className="rounded-md flex-auto cursor-pointer"
        >
          {value ? value.split('.')[0] : currentShopId ? currentShopId.split('.')[0] : 'Pods view'}
        </button>
      </div>
    );
  }, [
    activeAccounts,
    CustomActivator,
    togglePopoverActive,
    value,
    currentShopId,
    customActivatorClassName,
    activeAppVersion,
  ]);

  const storeSwitcherContent = (
    <div className={`min-w-max ${activeAppVersion === '3.0' ? 'v3' : 'v2'}`}>
      {activeAccounts && (
        <>
          {(shopsAsArray.length > 1 || isFromAllShops) && (
            <>
              <div className="text-primary/60 text-medium p-4 leading-loose">STORES</div>
              <div className="w-[95%] pl-[8px]">
                <TextInput
                  value={searchShopsValue}
                  onChange={setSearchShopsValue}
                  placeholder="Search stores"
                />
              </div>
              {activeAppVersion === '3.0' && !isMultiShopBlocked ? (
                <div className="active-account-option-list flex-col py-4 max-h-[150px] overflow-y-auto flex">
                  <Checkbox
                    p="xs"
                    checked={storesList.every((s) => activeAccounts.includes(s.value))}
                    label="Select all"
                    indeterminate={
                      activeAccounts.length > 0 && activeAccounts.length < storesList.length
                    }
                    onChange={(checked) => {
                      const newList = checked ? storesList.map((store) => store.value) : [value];
                      dispatch(updateActiveAccounts(newList));
                    }}
                  />
                  <Flex mih="1px" maw="100%" bg="gray.2" mx="xs" />

                  {storesList.map((s) => (
                    <Container
                      key={s.value}
                      className="flex items-center gap-2 hover:!bg-[#e5e7eb]"
                      bg={value === s.value ? 'gray.2' : 'transparent'}
                      px="xs"
                      py={5 as Size}
                      mx={0}
                    >
                      <Checkbox
                        disabled={currentShopId === s.value}
                        checked={activeAccounts.includes(s.value)}
                        onChange={(checked) => {
                          dispatch(
                            updateActiveAccounts(
                              checked
                                ? [...activeAccounts, s.value]
                                : activeAccounts.filter((a) => a !== s.value),
                            ),
                          );
                        }}
                      />
                      <Flex onClick={() => handleChange([s.value])} cursor="pointer">
                        <Text>{s.label}</Text>
                      </Flex>
                    </Container>
                  ))}
                </div>
              ) : (
                <div
                  className={`current-shop-option-list max-h-[150px] overflow-y-auto flex ${
                    isMultiShopBlocked ? 'pl-5' : ''
                  }`}
                >
                  <OptionList options={storesList} selected={[value]} onChange={handleChange} />
                </div>
              )}

              <Flex mih="1px" maw="100%" bg="gray.2" />
            </>
          )}
          <ActionList
            items={[
              {
                content: 'Pods view',
                onAction: () => {
                  setPopoverActive(false);
                  goToPodsView(history, dispatch);
                },
              },
            ]}
          />
        </>
      )}
      {!activeAccounts && (
        <div className="flex justify-center items-center h-[200px] w-[190px]">
          <Spinner accessibilityLabel="Spinner example" />
        </div>
      )}
    </div>
  );

  // achieve auto focus on the the search stores input
  useEffect(() => {
    const popoverState = popoverActive;
    setTimeout(() => {
      setShowPopoverContent(popoverState);
    }, 100);
  }, [popoverActive]);
  return (
    <>
      {isSmall ? (
        <Modal title={''} activator={activator} open={popoverActive} onClose={togglePopoverActive}>
          {storeSwitcherContent}
        </Modal>
      ) : (
        <Popover
          active={popoverActive}
          fluidContent
          activator={activator}
          onClose={togglePopoverActive}
          preferredAlignment={'right'}
        >
          {showPopoverContent ? storeSwitcherContent : null}
        </Popover>
      )}
    </>
  );
};
