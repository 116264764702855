type FeaturesForProduct = Record<
  string,
  {
    description: string;
    includedFeatures: string[];
    missedFeatures: {
      from: string[];
      features: string[];
    }[];
  }
>;

export const ProductsMetaData: FeaturesForProduct = {
  Growth: {
    description:
      'Comprehensive tools to monitor profitability and marketing performance across all your key channels and integrations.',
    includedFeatures: [
      'Attribution',
      'Core Analytics Boards',
      'Core Ad Channels',
      'Core Integrations',
    ],
    missedFeatures: [
      {
        from: ['Pro'],
        features: [
          'Amazon Marketplace',
          '5 credits for Incremental Reports & Templates',
          'Advanced Ad Channels',
          'Advanced Integrations',
        ],
      },
      {
        from: ['Premium'],
        features: [
          'Sonar',
          '2 credits for Incremental Reports & Templates ',
          'Premium Ad Channels',
          'Premium Integrations',
          'Amazon Marketplace',
          'Advanced Ad Channels',
          'Advanced Integrations',
        ],
      },
      {
        from: ['Premium Plus'],
        features: [
          'AI - Moby',
          'Unlimited Credits for Incremental Reports & Templates ',
          'Forecasting Automated Workflows',
          'Sonar',
          'Premium Ad Channels',
          'Premium Integrations',
          'Amazon Marketplace',
          'Advanced Ad Channels',
          'Advanced Integrations',
        ],
      },
      {
        from: ['Triple Whale', 'Pixel', 'Creative Cockpit'],
        features: ['Activity Feed', 'Insights', 'Creative Cockpit'],
      },
      {
        from: ['Triple Whale', 'Pixel'],
        features: ['Activity Feed', 'Insights'],
      },
      {
        from: ['Triple Whale'],
        features: ['Activity Feed', 'Insights'],
      },
    ],
  },
  Pro: {
    description:
      'Advanced insights and visibility with the flexibility to scale your business using enhanced data.',
    includedFeatures: [
      'Everything in Growth, plus:',
      'Amazon Marketplace',
      '5 Credits for Incremental Reports & Templates',
      'Advanced Ad Channels',
      'Advanced Integrations',
    ],
    missedFeatures: [
      {
        from: ['Growth'],
        features: [],
      },
      {
        from: ['Premium'],
        features: [
          'Sonar',
          '2 credits for Incremental Reports & Templates',
          'Premium Ad Channels',
          'Premium Integrations',
        ],
      },
      {
        from: ['Premium Plus'],
        features: [
          'AI - Moby',
          'Unlimited Credits for Incremental Reports & Templates',
          'Forecasting Automated Workflows',
          'Sonar',
          'Premium Ad Channels',
          'Premium Integrations',
        ],
      },
      {
        from: ['Triple Whale', 'Pixel', 'Creative Cockpit'],
        features: ['Product Analytics', 'Creative Cockpit'],
      },
      {
        from: ['Triple Whale', 'Pixel'],
        features: ['Product Analytics'],
      },
      {
        from: ['Triple Whale'],
        features: ['Product Analytics'],
      },
    ],
  },
  Premium: {
    description:
      'Complete access to all channels, integrations, and additional boards, with data passback to your ad channels.',
    includedFeatures: [
      'Everything in Pro, Plus:',
      'Sonar',
      '7 Credits for Incremental Reports & Templates',
      'Premium Ad Channels',
      'Premium Integrations',
    ],
    missedFeatures: [],
  },
  'Premium Plus': {
    description:
      'Unlimited flexibility, personalized analytics, and instant data insights from AI across every piece of your business.',
    includedFeatures: [
      'Everything in Premium, plus:',
      'AI - Moby',
      'Unlimited Credits for Incremental Reports & Templates',
      'Forecasting',
      'Automated Workflows',
    ],
    missedFeatures: [
      {
        from: ['Premium'],
        features: [
          'AI - Moby',
          'Unlimited Credits for Incremental Reports & Templates',
          'Forecasting Automated Workflows',
        ],
      },
    ],
  },
  Enterprise: {
    description:
      'Complete access to all channels, integrations, and additional boards, with data passback to your ad channels.',
    includedFeatures: [
      'Amazon Marketplace',
      'Sonar',
      '10 Credits for Incremental Reports & Templates ',
      'Premium Ad Channels',
      'Premium Integrations',
    ],
    missedFeatures: [],
  },
  'Enterprise Plus': {
    description:
      'Complete access to all channels, integrations, and additional boards, with data passback to your ad channels.',
    includedFeatures: [
      'Everything In Enterprise, plus:',
      'AI - Moby',
      'Unlimited Credits for Incremental Reports & Templates',
      'Forecasting',
      'Automated Workflows',
    ],
    missedFeatures: [],
  },
};
