import { FC, useCallback, useMemo, useState } from 'react';
import { Anchor, Title, Text } from '@tw/ui-components';
import { useHistory } from 'react-router';
import { NavLink } from 'react-router-dom';
import Card1 from 'components/Icons/new-card-1.png';
import Card2 from 'components/Icons/new-card-2.png';
import Card3 from 'components/Icons/new-card-3.png';
import { QueryBuilderModal } from './QueryBuilder/QueryBuilderModal';
import {
  createNewWidget,
  createWillyMetricFromColumn,
  extractProvidersFromFilters,
} from './utils/willyUtils';
import {
  WillyDashboardElement,
  WillyMetric,
  WillyParameter,
  WillyWidgetElement,
} from './types/willyTypes';
import { $currentShopId } from '$stores/$shop';
import { $dialect, $isAdminClaim } from '$stores/$user';
import { $tables } from '$stores/willy/$tables';
import { v4 as uuidV4 } from 'uuid';
import { useDashContext } from './dashContext';
import { useStoreValue } from '@tw/snipestate';
import { WillyWidgetEditor } from './WillyWidgetEditor/WillyWidgetEditor';
import { QueryBuilderCreator } from './WillyWidgetEditor/TableBuilder/QueryBuilderCreator';
import { cx } from 'utils/cx';
import { useFeatureFlagComputer, useFeatureFlagValue } from 'feature-flag-system';
import { FeatureFlag } from '@tw/feature-flag-system/module/types';

type WillyEmptyCardsProps = {
  onClickNoCode?: () => void;
  onClickChat?: () => void;
  onClickSql?: () => void;
  widgetId?: string;
  dashboard?: WillyDashboardElement;
};

export const WillyEmptyMarkup: FC<WillyEmptyCardsProps> = ({
  onClickNoCode,
  onClickChat,
  onClickSql,
  widgetId,
  dashboard,
}) => {
  const chatIsBlocked = useFeatureFlagValue(FeatureFlag.CHAT_DATA_FF, 'shouldNotBeSeen');
  const history = useHistory();
  const [queryBuilderOpen, setQueryBuilderOpen] = useState(false);
  const { $widgets, fetchWidgets } = useDashContext();
  const currentShopId = useStoreValue($currentShopId);
  const dialect = useStoreValue($dialect);
  const tables = useStoreValue($tables);
  const isAdmin = useStoreValue($isAdminClaim);

  const emptyCards = useMemo(() => {
    const cards = [
      {
        id: 'chat',
        image: Card1,
        title: 'By chatting with Moby',
        onClick: onClickChat ?? (() => {}),
        link: onClickChat ? undefined : '/chat',
        disabled: chatIsBlocked,
      },
      {
        id: 'no-code',
        image: Card3,
        title: 'With Table Builder',
        onClick: () => setQueryBuilderOpen(true),
        link: undefined,
        disabled: false,
      },
      {
        id: 'sql',
        image: Card2,
        title: 'With SQL Builder',
        onClick: onClickSql ?? (() => {}),
        link: onClickSql ? undefined : '/sql-editor',
        disabled: false,
      },
    ];

    return cards;
  }, [onClickChat, onClickSql, chatIsBlocked]);

  const emptyCardInner = useMemo(
    () => (
      <div className={`text-center p-4 sm:p-8 sm:pb-12 m-auto`}>
        <Title size="18" fw={500} color="gray.8">
          How would you like to build your Data Visualization?
        </Title>
        <div className="grid lg:grid-cols-3 gap-8 xl:gap-16 flex-wrap py-8 xl:p-14 xl:pt-10 max-w-[1000px] mx-auto">
          {emptyCards.map(({ id, title, image, link, onClick, disabled }) => {
            link = disabled ? '/plans' : link;

            const children = (
              <div className="flex flex-col items-center justify-center text-center">
                <Text fz="lg" fw="500" color="white">
                  {title}
                </Text>
                {disabled && (
                  <Text fz="sm" color="one.5">
                    Upgrade
                  </Text>
                )}
                <img src={image} alt={title} className="block w-full" />
              </div>
            );

            const commonProps = {
              key: id,
              className: cx(
                'rounded shadow border-solid border border-gray-300 p-8',
                'text-left cursor-pointer no-underline',
                {
                  'bg-[var(--mantine-color-gray-2)] hover:bg-[var(--mantine-color-gray-2)]':
                    disabled,
                  'bg-[#2770CE] hover:bg-[#1F5CAB]': !disabled,
                },
              ),
            };

            return link ? (
              <NavLink to={link} {...commonProps}>
                {children}
              </NavLink>
            ) : (
              <div onClick={onClick} {...commonProps}>
                {children}
              </div>
            );
          })}
        </div>

        <Anchor
          underline="never"
          onClick={() => {
            history.push('/templates');
          }}
        >
          <Text color="one.5" fz="16">
            None of the above?{' '}
            <Text color="one.5" display="inline-block" fw="600">
              Explore templates
            </Text>
          </Text>
        </Anchor>
      </div>
    ),
    [emptyCards, history],
  );

  const generateWidgetTitle = useCallback(
    (metrics: WillyMetric[], parameters: WillyParameter[]) => {
      const providers = parameters?.find((p) => p.column === 'channel')?.value;
      let providersString = '';
      if (Array.isArray(providers)) {
        providersString = providers.join(', ');
      } else if (providers) {
        providersString = providers;
      }

      if (metrics.length > 0) {
        return `${metrics.map((m) => m.name).join(', ')} ${
          providersString ? `by ${providersString}` : ''
        }`;
      }
      return 'New Widget';
    },
    [],
  );

  return (
    <>
      {widgetId ? (
        emptyCardInner
      ) : (
        <div
          className={`willy-widget h-full relative w-full max-w-full sm:rounded dark:border-lighter flex flex-col`}
        >
          <div
            className={`flex flex-col flex-auto h-full max-w-full group dark:bg-transparent bg-white flex-shrink-0 loaded overflow-auto sm:rounded shadow`}
          >
            <div
              className={`
                w-full py-4 sticky top-0 flex flex-col justify-center z-[110] overflow-auto 
                flex-shrink-0 min-w-[150px] px-4 
                border-0 border-solid border-b-[1px] border-black/10 bg-white
                whitespace-nowrap font-semibold
              `}
            >
              Untitled Section
            </div>
            {emptyCardInner}
          </div>
        </div>
      )}
      <QueryBuilderCreator
        dashboard={dashboard}
        widgetId={widgetId}
        open={queryBuilderOpen}
        setOpen={setQueryBuilderOpen}
      />
      {/* <QueryBuilderModal
        open={queryBuilderOpen}
        onClose={() => setQueryBuilderOpen(false)}
        onSave={async (builder) => {
          if (!dashboard || !currentShopId || !dialect) {
            return;
          }

          const services = extractProvidersFromFilters(builder.filters);

          const metrics: WillyMetric[] = builder.columns
            .map((col, index) => {
              const table = tables.find((t) => t.id === builder.table);
              const column = table?.columns.find((c) => c.id === col.columnId);
              if (!column) {
                return null;
              }
              return createWillyMetricFromColumn(column, index, services);
            })
            .filter((m): m is WillyMetric => !!m)
            .map((m) => ({
              ...m,
              active: true,
            }));
          const queryId = widgetId || builder.queryId || uuidV4();
          const widget: WillyWidgetElement = {
            queryId: queryId,
            title: generateWidgetTitle(metrics, builder.filters),
            dialect,
            incrementedStacked: false,
            metrics: metrics,
            parameters: builder.filters,
            stacked: false,
            type: 'table',
            mode: 'builder',
            builderSetup: builder,
            queries: [
              {
                id: queryId,
                query: '', // TODO: generate query
                fromMetricBuilder: true,
                question: '',
              },
            ],
          };
          const w = await createNewWidget(currentShopId, dashboard, widget);
          const widgets = await fetchWidgets();
          $widgets.set(widgets);
          setQueryBuilderOpen(false);
        }}
      /> */}
    </>
  );
};
