import React, { useEffect } from 'react';
import { v4 as uuid } from 'uuid';
import { DragDropContext, DraggableLocation } from 'react-beautiful-dnd';
import { ExpressionElementBag } from './ExpressionElementsBag';
import { ExpressionOptions } from './ExpressionOptions';
import { useSelector } from 'react-redux';
import { RootState } from 'reducers/RootType';
import { useIsSmall } from 'hooks/useDefaultWindowSizes';
import {
  ElementTypes,
  ExpressionElement,
  ExpressionElementsMap,
} from 'components/Willy/types/willyTypes';
import { OPTION_METRICS } from './Constants';
import {
  WillyExpressionOrCustomMetric,
  WillyCustomMetric,
  WillyExpressionMetric,
} from 'components/Willy/types/willyTypes';

export const ExpressionBuilder: React.FC<{
  onExpressionChanged: (exp: ExpressionElement[]) => void;
  expression: ExpressionElement[];
  isGlobal?: boolean;
  setIsDirty?: React.Dispatch<React.SetStateAction<boolean>>;
}> = ({ onExpressionChanged, expression, isGlobal, setIsDirty }) => {
  const isSmall = useIsSmall();
  const [expressionElementsMap, setExpressionElementsMap] = React.useState<ExpressionElementsMap>(
    {},
  );

  useEffect(() => {
    if (expressionElementsMap[0]?.length > 0) {
      setIsDirty?.(true);
    }
  }, [expressionElementsMap, setIsDirty]);

  const isFromMobileApp = useSelector((state: RootState) => state.mobileApp.isFromMobileApp);

  const removeExpressionElement = (id: any) => {
    const index = expression.findIndex((s) => s.id === id);
    if (index > -1) {
      expression.splice(index, 1);
    }

    onExpressionChanged([...expression]);
  };

  const updateMetricItem = (id: any, metric: WillyExpressionOrCustomMetric) => {
    const index = expression.findIndex((s) => s.id === id);
    if (index > -1) {
      const item: ExpressionElement = {
        ...expression[index],
        value: metric.id,
        isGlobal: !!metric.isGlobal,
        isCustomMetric: !!metric.isCustomMetric,
        title: metric.name,
        type: ElementTypes.METRIC,
      };
      expression[index] = item;
      onExpressionChanged([...expression]);
    }
  };

  const updateIntegerItem = (id: any, value: number) => {
    const index = expression.findIndex((s) => s.id === id);
    if (index > -1) {
      expression[index] = {
        ...expression[index],
        value: value,
      };
      onExpressionChanged([...expression]);
    }
  };

  useEffect(() => {
    const resultArray: ExpressionElementsMap = { 0: [] };
    let index: number = 0;
    let rowSize: number = 0;
    const maxElementsSize: number = isFromMobileApp || isSmall ? 5 : 10;
    expression.forEach((element: ExpressionElement) => {
      const elementSize =
        element.type == ElementTypes.INTEGER
          ? 4
          : [ElementTypes.METRIC].includes(element.type) && element.title
            ? element.title.length / 6 + 1
            : 2;
      if (rowSize + elementSize <= maxElementsSize) {
        rowSize += elementSize;
      } else {
        index++;
        resultArray[index] = [];
        rowSize = elementSize;
      }
      resultArray[index].push(element);
    });
    if (expression.length == 0) {
      resultArray[0] = [];
    }
    setExpressionElementsMap(resultArray);
  }, [expression, isFromMobileApp, isSmall]);

  const onDragEnd = (result) => {
    const { source, destination } = result;

    if (!destination) return;

    const elementMap = reorderExpressionElements(expressionElementsMap, source, destination);
    const res = Object.keys(elementMap).reduce((acc: any, key) => {
      return [...acc, ...elementMap[key]];
    }, []);
    onExpressionChanged(res);
  };

  const addMetricItem = (item) => {
    expression.push({ ...item, id: uuid() });
    onExpressionChanged([...expression]);
  };

  const reorder = (
    list: ExpressionElement[],
    startIndex: number,
    endIndex: number,
  ): ExpressionElement[] => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
  };

  const copy = (
    source: any[],
    destination: ExpressionElement[],
    droppableSource: DraggableLocation,
    droppableDestination: DraggableLocation,
  ) => {
    const item = source[droppableSource.index];
    destination.splice(droppableDestination.index, 0, { ...item, id: uuid() });
    return destination;
  };

  const reorderExpressionElements = (
    elementsMap: ExpressionElementsMap,
    source: DraggableLocation,
    destination: DraggableLocation,
  ): ExpressionElementsMap => {
    const next = [...elementsMap[destination.droppableId]];

    if (source.droppableId == 'ExpressionOptions') {
      const res = copy(OPTION_METRICS, next, source, destination);
      return {
        ...elementsMap,
        [destination.droppableId]: res,
      };
    }
    const current = [...elementsMap[source.droppableId]];
    const target = current[source.index];
    // moving to same list
    if (source.droppableId === destination.droppableId) {
      const reordered = reorder(current, source.index, destination.index);
      return {
        ...elementsMap,
        [source.droppableId]: reordered,
      };
    }
    // remove from original
    current.splice(source.index, 1);
    // insert into next
    next.splice(destination.index, 0, target);

    return {
      ...elementsMap,
      [source.droppableId]: current,
      [destination.droppableId]: next,
    };
  };

  return (
    <div className="bg-gray-100  pb-[16px] rounded-[4px]">
      <DragDropContext onDragEnd={onDragEnd}>
        <ExpressionOptions
          isGlobal={isGlobal}
          removeExpressionElement={removeExpressionElement}
          updateIntegerItem={updateIntegerItem}
          updateMetricItem={updateMetricItem}
          items={OPTION_METRICS}
          addMetricItem={(item) => addMetricItem(item)}
        />
        <div className=" bg-white border-dashed border-[2px] rounded-[4px] border-gray-200 mt-[8px] mx-[16px]">
          {Object.entries(expressionElementsMap).map(([key, elementChunk]) => (
            <ExpressionElementBag
              isGlobal={isGlobal}
              removeExpressionElement={removeExpressionElement}
              updateIntegerItem={updateIntegerItem}
              updateMetricItem={updateMetricItem}
              items={elementChunk}
              listId={key}
              key={key}
            />
          ))}
        </div>
      </DragDropContext>
    </div>
  );
};
