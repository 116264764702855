import { $derived, $effect, $observer, $store, useStoreValue } from '@tw/snipestate';
import {
  NARROW_NAV,
  PREFERS_WIDE_NAV,
  LS_STORES_NAV_WIDTH,
  WIDE_NAV,
  ALAN_MAIN_NAV,
} from './constants/sizes';
import { WindowWidth } from 'constants/windowSizes';
import { $activeNav } from '$stores/nav-config-stores';

export const $windowWidth = $observer<number>(window.innerWidth, (_, set) => {
  const handleResize = () => set(window.innerWidth);
  window.addEventListener('resize', handleResize);
  return () => window.removeEventListener('resize', handleResize);
});

/* NAV OPEN STATE - used when switching between desktop and mobile navs */
export const $navOpen = $store(false);

/* OPEN NAV SECTION - holds title of current open nav section */
export const $openNavSection = $store('');

/* LAST SETTINGS ROUTE */
export const $lastSettingsRoute = $store('/store-settings');

/* NAV WIDTH PREFERENCE - used to track if user prefers wide or narrow nav */
export const $prefersWideNav = $store<boolean>(
  (() => {
    const pref = localStorage.getItem(PREFERS_WIDE_NAV);
    return pref === null ? false : pref === 'true';
  })(),
);
$effect((_, get) => {
  const pref = get($prefersWideNav);
  localStorage.setItem(PREFERS_WIDE_NAV, pref.toString());
});

export const $storesNavWidthPref = $store(Number(localStorage.getItem(LS_STORES_NAV_WIDTH) || 50));

const $storesNavWidth = $derived((get) => {
  const newWidth = get($activeNav) === 'alan' ? 0 : get($storesNavWidthPref);
  localStorage.setItem(LS_STORES_NAV_WIDTH, newWidth.toString());
  return newWidth;
});

const $innerNavWidth = $derived((get) => {
  const pref = get($prefersWideNav);
  const activeNav = get($activeNav);
  const windowWidth = get($windowWidth);

  if (windowWidth < WindowWidth.SMALL || activeNav === 'pods' || activeNav === 'settings')
    return WIDE_NAV - 50;
  if (activeNav === 'alan') return ALAN_MAIN_NAV;
  return (pref ? WIDE_NAV : NARROW_NAV) - 50;
});

/* NAV WIDTH - used during collapsed/collapsing state */
export const $navWidth = $derived((get) => get($storesNavWidth) + get($innerNavWidth));
$effect((_, get) => {
  document.documentElement.style.setProperty('--nav-width', get($navWidth) + 'px');
});

const $isNavWide = $derived((get) => get($navWidth) > WIDE_NAV / 2);

/** Used to see if nav should switch to wide style - not whether or not nav is of size `WIDE_NAV` (aka 300) */
export const useIsNavWideStyle = () => useStoreValue($isNavWide);
