import { SubscriptionFeature } from '@tw/types';
import {
  onboardingTaskInfo,
  type onboardingResponse,
  TASK_STATUS,
} from '@tw/types/module/services/subscription-manager';
import 'firebase/auth';
import firebase from 'firebase/compat/app';
import { createSelector } from 'reselect';
import axiosInstance from 'utils/axiosInstance';
import { getShopById } from 'utils/DB';
import {
  APP_STARTED,
  INIT_SHOP,
  UPDATE_SHOP,
  EXTERNAL_PROVIDER_FAUTURE_FLAGS_LOADED,
  RECEIVED_SENSORY_INTEGRATIONS,
  USER_DATA_ON_LOAD,
  SET_MSP_CONNECTED,
  REMOVE_LOCAL_SHOP_PROP,
} from 'ducks/constants';
import { appsDb, toArray } from 'utils/DB';

import { APPS_COLLECTION_RECEIVED, LOADING_APPS } from './apps';
import { type ExternalApp } from 'types/ExternalApp';
import { Dispatch, Reducer } from 'redux';

import { initUI, initializing, setShopBillingInterval } from 'ducks/actions';

import { setCurrentCustomUiIfNotExist } from 'ducks/summary';
import { SalesPlatform, ServicesIds } from '@tw/types/module/services';
import { initFeatureFlagComputer } from 'feature-flag-system';
import { COST_SETTINGS_ONBOARDING_TASKS } from 'constants/costSettings';
import { omit } from 'lodash';
import { $shop } from '$stores/$shop';
import { RootState } from 'reducers/RootType';
import { $shopSubscription } from '../$stores/willy/$subscription';
import { toast } from 'react-toastify';

export function appStarted() {
  return (dispatch) => {
    dispatch({ type: APP_STARTED });
  };
}

export function startUi(remoteData) {
  return (dispatch) => {
    dispatch(initializing());
    dispatch(setCurrentCustomUiIfNotExist(remoteData.shops));
    dispatch(initUI(remoteData));
  };
}

export const getAppsForShop = () => async (dispatch: Dispatch) => {
  dispatch({
    type: LOADING_APPS,
  });
  try {
    const data = await appsDb().get();
    const apps: ExternalApp[] = toArray(data);
    dispatch({
      type: APPS_COLLECTION_RECEIVED,
      apps,
    });
  } catch (e) {
    console.error('error loading apps', e);
  }
};

const SAVE_SETTINGS = 'SAVE_SETTINGS';
export const saveSettings = (
  shopId: string,
  shopName: string,
  ownerEmail: string,
  shopUrl: string,
  newCurrency: string,
) => {
  return async (dispatch) => {
    const shopRef = firebase.firestore().collection('shops').doc(shopId);
    await shopRef.update({
      shopName,
      ownerEmail,
      shopUrl,
      currency: newCurrency,
    });
    toast('Settings saved', { type: 'success' });
    dispatch({
      type: SAVE_SETTINGS,
      payload: { shopName, ownerEmail },
    });
  };
};

const GET_EMAIL = 'GET_EMAIL';

export const fetchOwnerEmail = (shopDomain: string) => {
  return async (dispatch) => {
    const ownerEmail = (
      await axiosInstance.get(`/v2/account-manager/shops/getShopOwner/${shopDomain}`)
    ).data;
    ownerEmail &&
      dispatch({
        type: GET_EMAIL,
        payload: ownerEmail,
      });
  };
};

const CLEAR_SHOP = 'CLEAR_SHOP';

export const clearShopData = () => {
  return (dispatch) => {
    dispatch({
      type: CLEAR_SHOP,
    });
  };
};

const GET_SHOP = 'GET_SHOP';
export const getShop = (shopId: string) => {
  return (dispatch) => {
    getShopById(shopId).then((data: any) => {
      if (!data.ownerEmail) {
        dispatch(fetchOwnerEmail(shopId));
      }
      dispatch({
        type: GET_SHOP,
        payload: data,
      });
    });
  };
};

const STOP_SUBSCRIPTION_LOADING = 'STOP_SUBSCRIPTION_LOADING';
const START_SUBSCRIPTION_LOADING = 'START_SUBSCRIPTION_LOADING';

const isSubscriptionLoading = (state = true, action) => {
  switch (action.type) {
    case START_SUBSCRIPTION_LOADING:
      return true;
    case STOP_SUBSCRIPTION_LOADING:
      return false;
    default:
      return state;
  }
};

const GET_ONBOARDING = 'GET_ONBOARDING';
export const getOnboarding = (shopId: string) => {
  return async (dispatch) => {
    const url = `/v2/subscription-manager/onboarding/shop-user/${shopId}`;
    try {
      let { data } = await axiosInstance.get<onboardingResponse>(url);
      // console.log("Onoarding:",data)

      dispatch({
        type: GET_ONBOARDING,
        payload: {
          completePercentage: data.completePercentage,
          completeCount: data.completeCount,
          taskCount: data.taskCount,
          criticalTasks: data?.criticalTasks?.sort((a, b) => a.order - b.order),
          recomandedTtasks: data?.recomendedTasks?.sort((a, b) => a.order - b.order),
        },
      });

      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({ event: 'getShopOnboarding', getShopOnboarding: data });
    } catch (err) {
      console.log(err);
    }
  };
};

const SKIP_SHOP_ONBOARDING_TASK = 'SKIP_SHOP_ONBOARDING_TASK';
export const skipShopOnboardingTask = (shopId: string, taskId: string, skipped: boolean) => {
  return async (dispatch) => {
    const url = `/v2/subscription-manager/onboarding/skip_task/${shopId}`;
    try {
      dispatch({
        type: SKIP_SHOP_ONBOARDING_TASK,
        payload: { taskId, skipped },
      });
      await axiosInstance.post(url, { task: taskId, skipped });
    } catch (err) {
      console.log(err);
      dispatch({
        type: SKIP_SHOP_ONBOARDING_TASK,
        payload: { taskId, skipped: !skipped },
      });
    }
  };
};

const SET_ONBOARDING_TASK_DONE = 'SET_ONBOARDING_TASK_DONE';
export const setOnboardingTaskDone = (shopId: string, taskId: string) => {
  return async (dispatch) => {
    const url = `/v2/subscription-manager/onboarding/set-task-done/${shopId}`;
    try {
      await axiosInstance.post(url, { task: taskId });
      dispatch({
        type: SET_ONBOARDING_TASK_DONE,
        taskId,
      });
    } catch (err) {
      console.log(err);
    }
  };
};

export const GET_SHOP_SUBSCRIPTION = 'GET_SHOP_SUBSCRIPTION';
export const GET_SHOP_FEATURES = 'GET_SHOP_FEATURES';
export const getShopFeatures = (shopId: string) => {
  return async (dispatch) => {
    dispatch({ type: START_SUBSCRIPTION_LOADING });
    const url = `/v2/subscription-manager/subscriptions/shop/${shopId}?redis=false`;
    try {
      let { data } = await axiosInstance.get(url);
      if (data.data) {
        data = data.data;
      }
      const billingInterval =
        data.source === 'stripe' ? data.items?.[0]?.billing_interval || 'year' : '';
      dispatch(setShopBillingInterval({ shopUrl: data.shop, billingInterval }));
      dispatch({
        type: GET_SHOP_SUBSCRIPTION,
        payload: data,
      });
      $shopSubscription.set(data);
      dispatch({
        type: GET_SHOP_FEATURES,
        payload: {
          features: data.features || [],
          subscriptionId: data.subscription,
          completePercentage: data.completePercentage,
          completeCount: data.subscription,
          shopId: data.shop,
          revenue_id: data.revenue,
          coupon: data.coupon,
          promo: data.promotion_code,
          card_brand: data.card_brand,
          last_four: data.last_four,
          payment_method: data.payment_method,
          email: data.email,
          items: data.items,
          customerId: data.customer,
          source: data.source,
          contract_type: data.contract_type,
          contract_end_date: data.contract_end_date,
        },
      });
      dispatch({ type: STOP_SUBSCRIPTION_LOADING });

      // Initialize feature flag config and metadata
      await initFeatureFlagComputer(shopId, data.features || []);

      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({ event: 'getShopFeatures', getShopFeatures: data });
    } catch (err) {
      console.log(err);
    }

    dispatch({ type: STOP_SUBSCRIPTION_LOADING });
  };
};

const currentShop = (state = {}, action) => {
  switch (action.type) {
    case GET_EMAIL: {
      const ownerEmail = action.payload;
      return { ...state, ownerEmail };
    }
    case GET_SHOP: {
      return action.payload ? { ...state, ...action.payload } : state;
    }
    case SAVE_SETTINGS: {
      return { ...state, ...action.payload };
    }
    case GET_SHOP_FEATURES: {
      return { ...state, ...action.payload };
    }
    case CLEAR_SHOP: {
      return {};
    }
    default: {
      return state;
    }
  }
};

const subscription = (state = {}, action) => {
  switch (action.type) {
    case GET_SHOP_SUBSCRIPTION: {
      return action.payload || { features: [] };
    }
    default: {
      return state;
    }
  }
};

const activeShippingProvider = (state: ServicesIds = 'shopify', action): ServicesIds => {
  switch (action.type) {
    case RECEIVED_SENSORY_INTEGRATIONS:
      const ship = action.integrations
        .filter((x) => !['deleted'].includes(x.status))
        .find((x) => x.domain === 'shipping');
      if (ship) return ship.provider_id;
      else return 'shopify';
    default:
      return state;
  }
};

const features = (state = [], action) => {
  switch (action.type) {
    case GET_SHOP_FEATURES: {
      return action.payload.features || [];
    }
    default: {
      return state;
    }
  }
};

// TODO: See if we can remove this constant totally
// export const SHOP_PROP_UPDATE = 'SHOP_PROP_UPDATE';
// const shop = (state = { shopName: '', subscription: {}, ownerEmail: '' }, action) => {
const shop = (state = $shop.get(), action) => {
  switch (action.type) {
    case INIT_SHOP:
    case UPDATE_SHOP:
      const { type, ...rest } = action;
      return { ...state, ...rest };
    // case SHOP_PROP_UPDATE:
    //   return { ...state, [action.payload.key]: action.payload.value };
    case REMOVE_LOCAL_SHOP_PROP:
      return omit(state, action.payload);
    default:
      return state;
  }
};

const internalGradualReleaseFeatures = (state = {}, action) => {
  switch (action.type) {
    case INIT_SHOP:
      return action.gradualReleaseKeys || {};
    default:
      return state;
  }
};

const isServer = (state = false, action) => {
  switch (action.type) {
    case INIT_SHOP:
      const urlParams = new URLSearchParams(window.location.search);
      return urlParams.get('isGraphComingFromClient') === 'true';
    default:
      return state;
  }
};

const hasRechargeInCrossbeam = (state = false, action) => {
  switch (action.type) {
    case INIT_SHOP:
      return !!action.hasRechargeInCrossbeam;
    default:
      return state;
  }
};

const msp: Reducer<SalesPlatform> = (state = 'shopify', action) => {
  switch (action.type) {
    case INIT_SHOP:
      return action.msp || state;
    default:
      return state;
  }
};

const isSupportMultiPlatformMsp = (state: boolean = true, action) => {
  return true;
};

const mspConnected = (state: boolean = false, action) => {
  switch (action.type) {
    case INIT_SHOP:
      return action.mspConnected || state;
    case SET_MSP_CONNECTED:
      return true;
    default:
      return state;
  }
};

const forceMarkPixelAsInstalled = (state: boolean = false, action) => {
  switch (action.type) {
    case INIT_SHOP:
      return action.forceMarkPixelAsInstalled || state;
    default:
      return state;
  }
};

const externalProviderFeatures = (state = {}, action) => {
  switch (action.type) {
    case EXTERNAL_PROVIDER_FAUTURE_FLAGS_LOADED:
      return action.payload || {};
    default:
      return state;
  }
};

export const gradualReleaseFeatures = createSelector(
  [
    (state: RootState) => state?.internalGradualReleaseFeatures,
    (state: RootState) => state?.externalProviderFeatures,
  ],
  // combined feature-flags object, based on user/shop, firestore/posthog flags.
  (internal, external) => {
    return { ...internal, ...external };
  },
);

export const SET_STEP_STATUS = 'SET_STEP_STATUS';

export const setOnboardingStatus = (shopDomain: string, stepName, status) => {
  return async (dispatch) => {
    dispatch({
      type: SET_STEP_STATUS,
      payload: {
        shopDomain,
        stepName,
        status,
      },
    });
  };
};

const onboardingStepsStatus = (state: any[] = [], action) => {
  switch (action.type) {
    case SET_STEP_STATUS:
      const shopObjCopy = state.find((step) => step.shopDomain === action.payload.shopDomain) || {
        shopDomain: action.payload.shopDomain,
      };
      shopObjCopy[action.payload.stepName] = action.payload.status;
      return [
        ...state.filter((step) => step.shopDomain !== action.payload.shopDomain),
        shopObjCopy,
      ];
    default:
      return state;
  }
};

const onboarding = (
  state: onboardingResponse = {
    shop: '',
    user: '',
    completePercentage: 0,
    completeCount: 0,
    taskCount: 0,
    recomendedTasks: [],
    criticalTasks: [],
  },
  action,
) => {
  switch (action.type) {
    case GET_ONBOARDING:
      return action.payload;
    default:
      return state;
  }
};

const onboardingLoaded = (state = false, action) => {
  switch (action.type) {
    case GET_ONBOARDING:
      return true;
    default:
      return state;
  }
};

const costSettingsCompletion = (
  state: { tasks: onboardingTaskInfo[]; isComplete: boolean } = { tasks: [], isComplete: false },
  action,
) => {
  switch (action.type) {
    case GET_ONBOARDING:
      const recomandedTtasks: onboardingTaskInfo[] = action.payload.recomandedTtasks;
      const costSettingsTasks: onboardingTaskInfo[] = recomandedTtasks?.filter((task) =>
        COST_SETTINGS_ONBOARDING_TASKS.includes(task.id as any),
      );
      return {
        tasks: costSettingsTasks,
        isComplete: costSettingsTasks.every(
          (task) => task?.status === TASK_STATUS.COMPLETE || task?.skipped,
        ),
      };
    case SKIP_SHOP_ONBOARDING_TASK:
      const skippedTask = state.tasks.find((task) => task.id === action.payload.taskId);
      if (skippedTask) {
        skippedTask.skipped = action.payload.skipped;
      }
      return {
        tasks: [...state.tasks],
        isComplete: state.tasks.every(
          (task) => task?.status === TASK_STATUS.COMPLETE || task?.skipped,
        ),
      };
    case SET_ONBOARDING_TASK_DONE:
      let completedTask = state.tasks.find((task) => task.id === action.taskId);
      if (completedTask) completedTask.status = TASK_STATUS.COMPLETE;
      return {
        tasks: [...state.tasks],
        isComplete: state.tasks.every(
          (task) => task?.status === TASK_STATUS.COMPLETE || task?.skipped,
        ),
      };
    default:
      return state;
  }
};

const isFreeShop = (state = false, action: { type: string; payload: any }): boolean => {
  switch (action.type) {
    case GET_SHOP_FEATURES:
      if (!Array.isArray(action.payload.features)) return false;

      return (
        action.payload.features.length >= 1 &&
        (action.payload.features.includes(SubscriptionFeature.CONF_FREE) ||
          !!action.payload.items?.some(
            (x) => x.plan_preview_start_date === null && x.product_name === 'Founders Dash',
          ))
      );
    default:
      return state;
  }
};

export const reducers = {
  currentShop,
  shop,
  hasRechargeInCrossbeam,
  msp,
  mspConnected,
  isSubscriptionLoading,
  onboarding,
  forceMarkPixelAsInstalled,
  externalProviderFeatures,
  internalGradualReleaseFeatures,
  subscription,
  activeShippingProvider,
  isServer,
  isSupportMultiPlatformMsp,
  onboardingStepsStatus,
  isFreeShop,
  onboardingLoaded,
  features,
  costSettingsCompletion,
};
