import React, { Dispatch, SetStateAction, useMemo } from 'react';
import { rulesActionDropdown, EditRule } from './types';
import Select from 'react-select';

import { Stack, Card, ButtonGroup, Button } from '@shopify/polaris';
import { cloneDeep } from 'lodash';
import { useSelector } from 'react-redux';
import { type RootState } from 'reducers/RootType';
import { capitalize } from 'lodash';
import CURRENCIES from 'constants/currencies';
import { isStatusAction } from '@tw/types';

const ACTIVE_TYPES = {
  dollar: 'dollar',
  percentage: 'percentage',
};

type Props = {
  rule: EditRule;
  setRule: Dispatch<SetStateAction<EditRule | undefined>>;
  disabled: boolean;
  showActionWarning?: (rule) => void;
  showWarning?: string;
};

export const RuleAction: React.FC<Props> = ({
  rule,
  setRule,
  disabled,
  showActionWarning = (rule) => {},
  showWarning = '',
}) => {
  const setStyles = (width) => {
    return {
      container: (provided) => ({
        ...provided,
        width: width,
      }),
    };
  };

  const { currency } = useSelector((state: RootState) => state);

  const ruleActionList = useMemo(() => {
    return Object.values(rulesActionDropdown)
      .filter((actionList) => {
        return actionList.show?.includes(rule?.level);
      })
      .map((action) => ({
        label: action.label?.replace('%entity%', capitalize(rule?.level || '')),
        value: action.value,
      }));
  }, [rule]);

  const currencySymbol = CURRENCIES[currency];
  const needToInsertVal = useMemo(
    () => rule.event.type !== 'pause' && rule.event.type !== 'start',
    [rule],
  );

  const handleChangeTypeEvent = (value) => {
    const type = value.value || value;
    const ruleCopy = cloneDeep(rule);

    if (isStatusAction(type)) {
      delete ruleCopy.event.params;
    }

    setRule({ ...ruleCopy, event: { ...ruleCopy.event, type } });
    showActionWarning({ ...ruleCopy, event: { ...ruleCopy.event, type } });
  };

  const handleChangeValueEvent = (value: string) => {
    const ruleCopy = cloneDeep(rule);

    setRule({
      ...ruleCopy,
      event: { ...ruleCopy.event, params: { ...ruleCopy.event?.params, value } },
    });
    showActionWarning({
      ...ruleCopy,
      event: { ...ruleCopy.event, params: { ...ruleCopy.event.params, value } },
    });
  };

  const handleChangeValueTypeEvent = (valueType) => {
    const ruleCopy = cloneDeep(rule);

    setRule({
      ...ruleCopy,
      event: { ...ruleCopy.event, params: { ...ruleCopy.event?.params, valueType } },
    });
    showActionWarning({
      ...ruleCopy,
      event: { ...ruleCopy.event, params: { ...ruleCopy.event.params, valueType } },
    });
  };

  return (
    <>
      <Card>
        <div style={{ background: '#f8f9fa' }}>
          <Card.Section>
            <div style={{ marginBottom: '10px' }}>
              <p className="font-semibold">THEN TAKE THE FOLLOWING ACTION:</p>
            </div>

            <Stack alignment="center">
              <Stack.Item>
                <Select
                  isDisabled={disabled}
                  styles={setStyles(300)}
                  options={ruleActionList}
                  placeholder="Action  "
                  onChange={(val) => handleChangeTypeEvent(val)}
                  value={ruleActionList.find((option) => option.value === rule?.event?.type) || ''}
                />
              </Stack.Item>
              {needToInsertVal && (
                <Stack.Item>
                  <div
                    style={{ position: 'relative', zIndex: '0' }}
                    className={`${disabled ? 'disabled' : ''}`}
                  >
                    <ButtonGroup segmented>
                      <Button
                        pressed={rule.event.params?.valueType !== ACTIVE_TYPES.percentage}
                        onClick={() => handleChangeValueTypeEvent(ACTIVE_TYPES.dollar)}
                      >
                        {currencySymbol}
                      </Button>
                      <Button
                        pressed={rule.event.params?.valueType === ACTIVE_TYPES.percentage}
                        onClick={() => handleChangeValueTypeEvent(ACTIVE_TYPES.percentage)}
                      >
                        %
                      </Button>
                    </ButtonGroup>
                  </div>
                </Stack.Item>
              )}
              {needToInsertVal && (
                <Stack.Item>
                  <>
                    <div className="inputWrapper">
                      <span className={`prefix ${disabled ? 'disabled' : ''}`}>
                        {rule?.event?.params?.valueType === 'percentage' ? '%' : currencySymbol}
                      </span>
                      <input
                        disabled={disabled}
                        placeholder="Enter value"
                        value={rule?.event?.params ? rule?.event?.params?.value?.toString() : ''}
                        onChange={(ev) => {
                          handleChangeValueEvent(ev.target.value);
                        }}
                      />
                    </div>
                  </>
                </Stack.Item>
              )}
            </Stack>

            {showWarning && (
              <div className="flex items-center text-red-600 mt-5">{showWarning}</div>
            )}
          </Card.Section>
        </div>
      </Card>
    </>
  );
};
