import { Stack, Button, TextContainer, Text } from '@shopify/polaris';
import { MinusMinor, MobilePlusMajor, EditMinor } from '@shopify/polaris-icons';
import ReorderList from './ReorderList';
import { useFeatureFlagComputer } from 'feature-flag-system';
import { FeatureFlag } from '@tw/feature-flag-system/module/types';
import { Button as TwButton } from 'components/BaseComponents/Button';
import { useAppDispatch } from 'index';
import { upgradePlanClicked } from 'ducks/subscription';
import LockedFeatureIndicator from './library/LockedFeatures/LockedFeatureIndicator';
import { snakeCase } from 'lodash';
import { ConnectedListItem } from './library/TWTable/types';
import { useHistory } from 'react-router';

// Map of column id to feature flag id
const BLOCK_MAP = new Map([['overlap', FeatureFlag.PIXEL_CHANNEL_OVERLAP_FF]]);

type ConnectedListsProps<T = any> = {
  items1: ConnectedListItem[];
  items2: ConnectedListItem[];
  title1?: string;
  title2?: string;
  items1orderOnChange: (...props: any) => void;
  itemMoveFrom1to2onPress: (item: T) => void;
  itemMoveFrom2to1onPress: (item: T) => void;
  currentSectionId?: string;
};

const ConnectedLists: React.FC<ConnectedListsProps> = ({
  items1,
  items2,
  title1,
  title2,
  items1orderOnChange,
  itemMoveFrom1to2onPress,
  itemMoveFrom2to1onPress,
  currentSectionId,
}) => {
  const ffComputer = useFeatureFlagComputer();
  const dispatch = useAppDispatch();
  const history = useHistory();
  return (
    <>
      {title1 && <TextContainer>{title1}</TextContainer>}
      <br />
      <div className="summaryOrderingSection">
        <ReorderList
          currentSectionId={currentSectionId}
          items={items1}
          orderOnChange={items1orderOnChange}
          itemHasLeftButton={true}
          itemLeftButtonIcon={MinusMinor}
          itemLeftButtonClick={itemMoveFrom1to2onPress}
        />
        <br />
        {title2 && <TextContainer>{title2}</TextContainer>}
        <br />
        {items2.map((i) => {
          const featureFlag = BLOCK_MAP.get(i.id);

          if (ffComputer.getConfigById(featureFlag).shouldNotBeSeen) {
            return (
              <Stack alignment="center" distribution="equalSpacing" key={i.id}>
                <div className="flex justify-start items-center gap-7 whitespace-nowrap w-8">
                  <LockedFeatureIndicator
                    styles={{ position: 'relative', left: '2px' }}
                    onlyIndicate
                    iconOnly
                    iconSize={25}
                    featureFlag={FeatureFlag.CUSTOM_METRICS_FF}
                  />
                  <Text as="h2" variant="headingMd">
                    {i.title}
                  </Text>
                </div>
                <TwButton
                  id={typeof i.title === 'string' ? snakeCase(i.title) : undefined}
                  onClick={() => dispatch(upgradePlanClicked(history))}
                  primary
                >
                  Upgrade
                </TwButton>
              </Stack>
            );
          }

          return (
            <Stack alignment="center" key={i.id}>
              <Button
                id={snakeCase(typeof i.title === 'string' ? i.title : undefined)}
                plain
                disabled={i.disabled}
                size="large"
                icon={MobilePlusMajor}
                onClick={() => {
                  itemMoveFrom2to1onPress(i);
                }}
              />
              <div className="flex flex-col">
                <Text as="h2" variant="headingMd">
                  {i.title}
                </Text>
                <div>{i.subTitle}</div>
              </div>
              {!!i.onEdit && (
                <EditMinor
                  width={12}
                  height={12}
                  className="fill-logo cursor-pointer"
                  onClick={() => dispatch(i.onEdit?.())}
                />
              )}
            </Stack>
          );
        })}
      </div>
    </>
  );
};

export default ConnectedLists;
