import React, { useState } from 'react';
import { CheckIcon, Combobox, Group, Input, InputBase, useCombobox } from '@mantine/core';
import { SelectProps } from '@tw/ui-components/module/components/Select/Select';
import { Icon, IconName } from '@tw/ui-components';
import { ChevronDownMinor } from '@shopify/polaris-icons';
import { ComboboxItem } from '@mantine/core/lib/components/Combobox';

export const WillySelect: React.FC<{
  data: Array<{ value: string; label: string; icon?: IconName }>;
  value: string;
  targetClassName?: string;
  onChange: (val: string) => void;
  dropdownMinWidth?: string;
}> = ({ data, value, onChange, targetClassName, dropdownMinWidth }) => {
  const combobox = useCombobox({
    onDropdownClose: () => combobox.resetSelectedOption(),
    onDropdownOpen: (eventSource) => {
      if (eventSource === 'keyboard') {
        combobox.selectActiveOption();
      } else {
        combobox.updateSelectedOptionIndex('active');
      }
    },
  });

  const options =
    data?.map((item) => (
      <Combobox.Option value={item.value} key={item.value} active={item.value === value}>
        <Group gap="xs">
          {item.value === value && <CheckIcon size={12} />}
          <div className={'flex gap-2'}>
            {item.icon && <Icon name={item.icon} />}
            <span>{item.label}</span>
          </div>
        </Group>
      </Combobox.Option>
    )) ?? [];

  return (
    <Combobox
      store={combobox}
      resetSelectionOnOptionHover
      withinPortal={false}
      styles={{
        dropdown: { minWidth: dropdownMinWidth ?? '300px' },
      }}
      onOptionSubmit={(val) => {
        onChange(val);
        combobox.closeDropdown();
        combobox.updateSelectedOptionIndex('active');
      }}
    >
      <Combobox.DropdownTarget>
        <div
          onClick={() => {
            combobox.toggleDropdown();
          }}
          className={`flex items-center  cursor-pointer ${targetClassName ?? ''}`}
        >
          <div className={'flex items-center gap-3  text-gray-800 font-medium'}>
            {data?.find((x) => x.value === value)?.icon && (
              <Icon name={data.find((x) => x.value === value)?.icon!} />
            )}
            <div>
              {(data?.find((x) => (x as ComboboxItem).value === value) as ComboboxItem)?.label ??
                ''}
            </div>
            <div
              className={`flex text-gray-500 w-5 h-5 items-center cursor-pointer transition-transform ${
                combobox.dropdownOpened ? 'rotate-180' : ''
              }`}
            >
              <Icon name={'chevron-down'} />
            </div>
          </div>
        </div>
      </Combobox.DropdownTarget>

      <Combobox.Dropdown>
        <Combobox.Options>{options}</Combobox.Options>
      </Combobox.Dropdown>
    </Combobox>
  );
};
