import { type AppDispatch } from './../../index';
import moment from '@tw/moment-cached/module/timezone';
import { Dispatch } from 'redux';
import {
  AttributionData,
  AttributionMainResponse,
  AttributionStatsRequest,
  AttributionWindow,
  PixelColumn,
  AttributionPpsLookBackWindow,
} from 'types/attribution';
import axiosInstance from 'utils/axiosInstance';
import { AttributionDateModels, AttributionModels } from '@tw/types';
import { getAttributionData } from 'utils/attributions';
import { getDefaultColumns } from 'utils/getDefaultColumns';
import { localStoragePixelColumnsKeys, TW_LS_ATTRIBUTION_MODEL_KEY } from 'constants/attribution';
import {
  ADD_ONE_DAY_VIEW,
  ATTRIBUTION_CHART_OPEN_CHANGED,
  ATTRIBUTION_FREE_SEARCH_CHANGED,
  ATTRIBUTION_LAST_REFRESH,
  ATTRIBUTION_MODEL_CHANGED,
  ATTRIBUTION_WINDOW_CHANGED,
  DATE_MODEL_CHANGED,
  DEFAULT_SOURCE_CHANGED,
  PPS_LOOK_BACK_WINDOW_CHANGED,
  SELECTED_COLUMNS_CHANGED,
  SHOW_ACTIVE_CAMPAIGNS_ONLY,
  WRAP_TABLE_LINES,
  SHOW_TOTAL_IMPACT_MODAL,
  USE_NEW_MODELS,
  ATTRIBUTION_SUBSCRIPTION_CHANGED,
  ATTRIBUTION_INCLUDE_CUSTOM_AD_SPEND,
  INIT_ATTRIBUTION,
} from './constants';
import { metrics as allMetrics } from '@tw/constants/module/Metrics/allMetrics';
import { userDb } from 'utils/DB';
import { type RootState } from 'reducers/RootType';
import { isUsingPixel2 } from 'components/attribution/utils';

export const changeAttributionFreeSearch = (freeSearch: string) => ({
  type: ATTRIBUTION_FREE_SEARCH_CHANGED,
  freeSearch,
});

export const changeDefaultSource = (source: string) => ({
  type: DEFAULT_SOURCE_CHANGED,
  source,
});

export const initAttributionPage = (allPixelColumns: PixelColumn[]) => {
  return (dispatch: AppDispatch) => {
    dispatch(setDefaultColumns(allPixelColumns));
    dispatch(setDefaultModel('lastPlatformClick'));
  };
};

export const changeSelectedColumns = (selectedColumns: PixelColumn[]) => ({
  type: SELECTED_COLUMNS_CHANGED,
  columns: selectedColumns,
});

export const setDefaultColumns = (columns: PixelColumn[]) => {
  return async (dispatch: Dispatch, getState: () => RootState) => {
    const key = localStoragePixelColumnsKeys.all;
    const order = (a: PixelColumn, b: PixelColumn) => a.index! - b.index!;
    let columnsFromDb: string[] | undefined = undefined;
    const userData = (await userDb().get()).data();

    if (userData) {
      const { currentShopId } = getState();
      columnsFromDb = userData.shops?.[currentShopId]?.ui?.attribution?.selectedColumns;
    }
    const defaultColumns = getDefaultColumns(key!, columns, order, columnsFromDb);
    return dispatch(changeSelectedColumns(defaultColumns));
  };
};

export const setUseNewModels = (useNewModels: boolean) => {
  return async (dispatch: Dispatch, getState: () => RootState) => {
    const { shop, attribution, currentShopId } = getState();
    const model = attribution.attributionModel;
    if (
      useNewModels &&
      shop.attributionWindowDefault &&
      attribution.attributionWindow === 'lifetime'
    ) {
      dispatch({
        type: ATTRIBUTION_WINDOW_CHANGED,
        window: '28',
      });
    }
    if (!useNewModels) {
      let window = 'lifetime';
      if (model === 'ppsViews') {
        window = '7';
      } else if (model.includes('lastPlatformClick')) {
        window = attribution.attributionWindow;
      }
      dispatch({
        type: ATTRIBUTION_WINDOW_CHANGED,
        window,
      });
    }
    await userDb().set(
      {
        shops: {
          [currentShopId]: {
            useOldModels: !useNewModels,
          },
        },
      },
      { merge: true },
    );
    dispatch(useNewModelsChanged(useNewModels));
  };
};

export const setDefaultModel = (model: AttributionModels) => {
  return async (dispatch: Dispatch, getState: () => RootState) => {
    let useNewModels = false;
    let modelLS = (localStorage.getItem(TW_LS_ATTRIBUTION_MODEL_KEY) as AttributionModels) || model;
    const userData = (await userDb().get()).data();
    if (userData) {
      const { currentShopId } = getState();
      useNewModels = true;
    }
    if (modelLS === 'ppsViews') {
      // do nothing
    } else if (useNewModels && !modelLS.includes('-v2')) {
      modelLS = (modelLS + '-v2') as AttributionModels;
    } else if (!useNewModels && modelLS.includes('-v2')) {
      modelLS = modelLS.replace('-v2', '') as AttributionModels;
    }
    localStorage.setItem(TW_LS_ATTRIBUTION_MODEL_KEY, modelLS);
    return dispatch(attributeModelChanged(modelLS));
  };
};

export const chartOpenChanged = (opened: boolean) => ({
  type: ATTRIBUTION_CHART_OPEN_CHANGED,
  opened,
});

export const attributionLastRefresh = (timestamp: moment.Moment) => ({
  type: ATTRIBUTION_LAST_REFRESH,
  timestamp,
});

export const attributeModelChanged = (model: AttributionModels) => ({
  type: ATTRIBUTION_MODEL_CHANGED,
  model,
});

export const dateModelChanged = (model: AttributionDateModels) => ({
  type: DATE_MODEL_CHANGED,
  model,
});

export const addOneDayView = (addOneDayView: boolean) => ({
  type: ADD_ONE_DAY_VIEW,
  addOneDayView,
});

export const showTotalImpactModal = (show: boolean) => ({
  type: SHOW_TOTAL_IMPACT_MODAL,
  show,
});

export const changeAttributionModel = (model: AttributionModels) => {
  return (dispatch: Dispatch) => {
    return dispatch(attributeModelChanged(model));
  };
};

export const changeDateModel = (model: AttributionDateModels) => {
  return (dispatch: Dispatch) => {
    return dispatch(dateModelChanged(model));
  };
};

export const changeAttributionWindow = (window: AttributionWindow) => {
  return (dispatch: Dispatch) => {
    return dispatch({
      type: ATTRIBUTION_WINDOW_CHANGED,
      window,
    });
  };
};

export const changeSubscriptios = (subscription: string[]) => {
  return (dispatch: Dispatch) => {
    return dispatch({
      type: ATTRIBUTION_SUBSCRIPTION_CHANGED,
      subscription,
    });
  };
};

export const changePpsLookBackWindow = (window: AttributionPpsLookBackWindow) => {
  return (dispatch: Dispatch) => {
    return dispatch({
      type: PPS_LOOK_BACK_WINDOW_CHANGED,
      window,
    });
  };
};

export const wrapTableLines = (wrap: boolean) => ({
  type: WRAP_TABLE_LINES,
  wrap,
});

export const showActiveCampaignsOnly = (only: boolean) => ({
  type: SHOW_ACTIVE_CAMPAIGNS_ONLY,
  only,
});

export const getAttributionComparisonDates = (
  start: moment.Moment,
  end: moment.Moment,
  mainDatePickerSpecialPeriod?: moment.unitOfTime.DurationConstructor,
  datesToCompare?: { id: string; start: string; end: string },
) => {
  let daysDiff = end.diff(start, 'days');
  daysDiff += daysDiff ? 1 : 1;

  let start2: string;
  let end2: string;
  if (datesToCompare?.id !== 'default') {
    start2 = datesToCompare?.start!;
    end2 = datesToCompare?.end!;
  } else {
    if (mainDatePickerSpecialPeriod) {
      start2 = moment(start).subtract(1, mainDatePickerSpecialPeriod).format();
      end2 = moment(end).subtract(1, mainDatePickerSpecialPeriod).format();
    } else {
      start2 = moment(start).subtract(daysDiff, 'days').format();
      end2 = moment(end).subtract(daysDiff, 'days').format();
    }
  }

  return { start: start2, end: end2 };
};

export const getAttributionComparison = async (
  params: AttributionStatsRequest,
  start: string,
  end: string,
) => {
  try {
    const today = moment().tz(params?.timezone || 'UTC');
    let lastDay = moment(end);
    const includesToday = moment(params.endDate).isSame(today, 'day');
    const onlyOneDay = moment(end).isSame(moment(start), 'day');
    if (includesToday && !onlyOneDay) {
      end = moment(end).subtract(1, 'days').format();
    }
    const { data } = await getAttributionData({
      ...params,
      startDate: start,
      endDate: end,
    });
    if (includesToday) {
      if (!onlyOneDay) {
        const { data: lastDayData } = await getAttributionData(
          {
            ...params,
            startDate: moment(lastDay).startOf('day').format(),
            endDate: moment(lastDay).format(),
          },
          true,
        );
        const lastDayStats = calRelevantStats(lastDayData, today);
        const summedStats = lastDayStats.reduce(
          (allStats, lastDayStat) => {
            const index = allStats.findIndex(
              (x) =>
                x.id === lastDayStat.id &&
                x.entity === lastDayStat.entity &&
                x.name === lastDayStat.name,
            );
            if (index === -1) {
              allStats.push(lastDayStat);
              return allStats;
            }
            // add lastDayStats to allStats
            allStats[index] = Object.values(allMetrics).reduce(
              (current, metric) => {
                current[metric.key] = metric?.calculateSum?.([{ ...current }, { ...lastDayStat }]);
                return current;
              },
              { ...allStats[index] },
            );
            return allStats;
          },
          [...data.data.stats],
        );
        data.data.stats = summedStats;
      } else {
        const stats = calRelevantStats(data, today);
        data.data.stats = stats;
      }
    }

    let attributions: AttributionData[] = (data?.data?.stats || []).map((sourceStats) => ({
      ...sourceStats,
      active: true,
    }));

    if (params.sources?.find((x) => x === 'klaviyo')) {
      attributions = attributions.filter((campaign) => campaign.id !== 'Everyone');
    }

    if (params.breakdown === 'source') {
      attributions = attributions.map((entity) => {
        return entity.name === 'Klaviyo' ? { ...entity, conversionValue: 0 } : entity;
      });
    }

    return attributions;
  } catch (e) {
    console.error('Error attribution', e);
  }
};

function calRelevantStats(data, today) {
  const currentHour = today.format().split('T')[1].split(':')[0];
  const stats = data.data.stats.map((entityStats) => {
    const metrics = entityStats.metricsBreakdown
      .filter((metric) => metric.date.split('T')[1] <= currentHour)
      .map((m) => m.metrics);

    const calculatedStats = Object.values(allMetrics).reduce(
      (acc, metric) => {
        acc[metric.key] = metric?.calculateSum?.(metrics);
        return acc;
      },
      { ...entityStats },
    );

    return { ...calculatedStats };
  });
  return stats;
}

export const useNewModelsChanged = (useNewAttributionModels: boolean) => ({
  type: USE_NEW_MODELS,
  useNewAttributionModels,
});

export const changeIncludeCustomAdSpend = (includeCustomAdSpend: boolean) => ({
  type: ATTRIBUTION_INCLUDE_CUSTOM_AD_SPEND,
  includeCustomAdSpend,
});

export const initAttribution = (attributions: AttributionData[]) => ({
  type: INIT_ATTRIBUTION,
  attributions,
});
