import Mexp from 'math-expression-evaluator';
import {
  Dialect,
  ElementTypes,
  ExpressionElement,
  MetricTree,
  WillyExpressionOrCustomMetricToServer,
  WillyMetric,
} from '../types/willyTypes';
import {
  $willyCustomMetrics,
  $willyMetrics,
  $willyMetricsCombine,
  $globalMetricsCombine,
} from '../../../$stores/willy/$willyMetrics';
import {
  WillyExpressionOrCustomMetric,
  WillyCustomMetric,
  WillyExpressionMetric,
} from '../types/willyTypes';
import { ServicesIds } from '@tw/types/module/services';
import axiosInstance from '../../../utils/axiosInstance';
import { $currentShopId } from '$stores/$shop';
import { isEqual, uniq } from 'lodash';
import uniqWith from 'lodash/uniqWith';
import { FilterGroup, FilterRow } from '@tw/willy-data-dictionary/module/columns/types';

export const validateExpressionElements = (
  customMetric: WillyCustomMetric,
  isGlobal,
): [isValid: boolean, error: string[]] => {
  const expressionElements = customMetric.expression;
  const globalMetricCombine = $globalMetricsCombine.get();
  let isValid = true;
  let error: string[] = [];
  const elementGroupSideBySideInvalid = [ElementTypes.INTEGER, ElementTypes.METRIC];

  const isMissingMetrics =
    !expressionElements ||
    expressionElements.length === 0 ||
    expressionElements.some((x) => !x.value);
  if (isMissingMetrics) {
    isValid = false;
    error.push('Add metric(s) or integer(s) to complete this custom metric');
  }

  const isMissingOperator = !expressionElements.some((x) => x.type === ElementTypes.OPERATOR);

  if (isMissingOperator) {
    isValid = false;
    error.push('Add at least one operator to complete this custom metric');
  }

  const hasParensElements = expressionElements.some((x) => x.type === ElementTypes.PARENTHESES);

  if (hasParensElements) {
    const filteredItems = expressionElements.filter((x) => x.type === ElementTypes.PARENTHESES);
    if (filteredItems && filteredItems.length > 0 && filteredItems.length % 2 !== 0) {
      isValid = false;
      error.push('Open or close parentheses to complete this custom metric');
    }
  }

  const hasOperators = expressionElements.some((x) => x.type === ElementTypes.OPERATOR);
  if (hasOperators) {
    for (let i = 0; i < expressionElements.length; i++) {
      const element = expressionElements[i];
      if (element.type === ElementTypes.OPERATOR) {
        const nextElement = expressionElements[i + 1];
        if (nextElement && nextElement.type === ElementTypes.OPERATOR) {
          isValid = false;
          error.push('Add metric or integer between two operators to complete this custom metric');
        }
      }
    }
  }

  const hasMetrics = expressionElements.some((x) => x.type === ElementTypes.METRIC);
  if (hasMetrics) {
    for (let i = 0; i < expressionElements.length; i++) {
      const element = expressionElements[i];
      if (elementGroupSideBySideInvalid.includes(element.type)) {
        const nextElement = expressionElements[i + 1];
        if (nextElement && elementGroupSideBySideInvalid.includes(nextElement.type)) {
          isValid = false;
          error.push('Add operator between two metrics/integers to complete this custom metric');
        }
      }
    }
  }

  if (
    isGlobal &&
    expressionElements.some(
      (x) =>
        x.type === ElementTypes.METRIC &&
        x.value !== undefined &&
        !globalMetricCombine.find((y) => y.id === x.value),
    )
  ) {
    isValid = false;
    error.push('Global custom metric can only contain global metrics');
  }

  //Check circular dependencies on custom metrics

  const allCustomMetrics = $willyCustomMetrics.get().filter((x) => x.id !== customMetric.id);
  allCustomMetrics.push(customMetric);
  const dependencyMap = buildMetricDependencyMap(allCustomMetrics);
  const circularDetected = hasCircularDependency(customMetric.id, dependencyMap);
  if (circularDetected) {
    isValid = false;
    error.push('Circular metrics dependency detected');
  }

  let expression = getExpressionByElements(expressionElements);
  if (!isValidExpression(expression)) {
    isValid = false;
    error.push('The expression is not valid');
  }

  return [isValid, error];
};

export const getExpressionByElements = (expressionElements: ExpressionElement[]): string => {
  let expression = '';
  expressionElements.forEach((item) => {
    expression += item.type === ElementTypes.METRIC ? 1 : item.value;
  });
  return expression;
};

export const isValidExpression = (expression: string): boolean => {
  try {
    const mexp = new Mexp();
    mexp.eval(expression, [], {});
    return true;
  } catch (e) {
    return false;
  }
};

function buildMetricDependencyMap(customMetrics: WillyCustomMetric[]): Map<string, string[]> {
  const metricMap = new Map<string, string[]>();

  customMetrics.forEach((metric) => {
    const dependencies = metric.expression
      .filter(
        (element) => element.type === ElementTypes.METRIC && typeof element.value === 'string',
      )
      .map((element) => element.value as string); // casting 'value' to string because we know it's a metric ID

    metricMap.set(metric.id, dependencies);
  });

  return metricMap;
}

function hasCircularDependency(
  metricId: string,
  metricMap: Map<string, string[]>,
  visited: Set<string> = new Set(),
): boolean {
  if (visited.has(metricId)) {
    return true; // Circular dependency detected
  }

  visited.add(metricId);

  const dependencies = metricMap.get(metricId) || [];
  for (const dep of dependencies) {
    if (hasCircularDependency(dep, metricMap, new Set(visited))) {
      return true;
    }
  }

  return false;
}

type MetricMap = Map<string, WillyExpressionOrCustomMetricToServer>;

function replaceIdsWithObjectsInExpression(
  expression: ExpressionElement[],
  metricMap: MetricMap,
): ExpressionElement[] {
  return expression.map((element) => {
    if (element.type === ElementTypes.METRIC && typeof element.value === 'string') {
      const metric = metricMap.get(element.value);
      if (metric && 'expression' in metric) {
        // It's a WillyCustomMetric, so we replace its ID with its full expression
        return {
          ...element,
          value: replaceIdsWithObjectsInExpression(metric.expression, metricMap),
          isCustomMetric: true,
          key: metric.key || metric.id || metric.name,
        };
      } else if (metric) {
        // It's a WillyMetric, replace the ID with the metric object
        return {
          ...element,
          isCustomMetric: false,
          key: metric.key || metric.id || metric.name,
          value: metric,
        };
      }
    }
    return element;
  });
}

export function extractAllTablesFromCustomMetric(metric: WillyCustomMetric): string[] {
  try {
    const tables: string[] = [];

    const allMetrics = $willyMetricsCombine.get();
    const metricMap: MetricMap = new Map();

    // Populate the map with all metrics (both WillyMetric and WillyCustomMetric)
    allMetrics.forEach((metric) =>
      metricMap.set(metric.id, metric as WillyExpressionOrCustomMetricToServer),
    );

    const expression = replaceIdsWithObjectsInExpression(metric.expression, metricMap);

    expression.forEach((element) => {
      if (element.type === ElementTypes.METRIC && element.value) {
        if (metricIsCustom(element.value)) {
          tables.push(...extractAllTablesFromCustomMetric(element.value).flat());
        } else {
          tables.push(element.value.tableId);
        }
      }
    });

    return [...new Set(tables.filter((x) => x))];
  } catch (e) {
    console.error(e);
    return [];
  }
}

export function formatDate(date) {
  const year = date.getFullYear();
  const month = (date.getMonth() + 1).toString().padStart(2, '0');
  const day = date.getDate().toString().padStart(2, '0');
  return `${year}-${month}-${day}`;
}

export function extractFullMetricTree(
  entryMetric: WillyCustomMetric,
  allMetrics: WillyExpressionOrCustomMetricToServer[],
): MetricTree {
  const metricMap: MetricMap = new Map();

  // Populate the map with all metrics (both WillyMetric and WillyCustomMetric)
  allMetrics.forEach((metric) => metricMap.set(metric.id, metric));

  // Replace IDs in the entry metric's expression with actual metric objects
  const { expression, ...rest } = entryMetric;
  return {
    ...rest,
    value: replaceIdsWithObjectsInExpression(entryMetric.expression, metricMap),
    key: entryMetric.key || entryMetric.id || entryMetric.name,
  };
}

export const convertToServerMetrics = (
  metrics: WillyExpressionOrCustomMetric[],
): WillyExpressionOrCustomMetricToServer[] => {
  return metrics.map((x) => {
    if (metricIsNonCustomExpression(x)) {
      let filtered = x.filter?.filter((x) => x.enabled) ?? [];
      const isShopOverride = filtered.some((x) => x.isOverride);
      if (isShopOverride) {
        const basicFilter = filtered.find((x) => !x.isOverride); // assume we have only one basic filter (only one OR statement)

        filtered = filtered
          .filter((x) => x.isOverride)
          .map(
            (x) =>
              ({
                ...x,
                filters: uniqWith([...(basicFilter?.filters ?? []), ...x.filters], isEqual),
              }) as FilterGroup,
          );
      }
      return { ...x, filter: filtered.map((x) => x.filters) };
    } else {
      return x;
    }
  });
};

export function extractMetricTrees(
  metricIds: string[],
): (MetricTree | WillyExpressionOrCustomMetricToServer)[] {
  const allMetrics: WillyExpressionOrCustomMetricToServer[] = convertToServerMetrics(
    $willyMetricsCombine.get(),
  );

  const metricsToCheck: WillyExpressionOrCustomMetricToServer[] = allMetrics.filter((x) => {
    return metricIds.includes(x.id);
  });

  return metricsToCheck.map((metric) => {
    if (metricIsCustom(metric)) {
      return extractFullMetricTree(metric, allMetrics);
    } else {
      return { ...metric, isCustomMetric: false, key: metric.key || metric.id || metric.name };
    }
  });
}

export function getAdTableFilters() {
  const willyMetricsCombine = $willyMetricsCombine.get();
  const adMetrics = willyMetricsCombine.filter(
    (x) => !metricIsCustom(x) && x?.tableId === 'ads_table',
  );
  const filters = adMetrics
    .filter(metricIsNonCustomExpression)
    .map((x) => x.filter)
    .filter((x): x is NonNullable<typeof x> => !!x);

  const uniqueFilters = filters.reduce(
    (acc, val) => {
      let channel = '';
      val.forEach((group) => {
        const c = group.filters.find((y) => y.column.name === 'channel')?.value[0];
        if (c && group.filters.length > 1) {
          channel = c;
        }
      });
      if (channel) {
        acc[channel] = val.filter((x) => x.enabled).map((x) => x.filters);
      }
      return acc;
    },
    {} as Record<string, FilterRow[][]>,
  );

  const a = Object.values(uniqueFilters).flat();
  return a;
}

export const findAllGlobalMetricsForProvider = (providerId: ServicesIds) => {
  return $willyMetrics.get().filter((x) => x.isGlobal && x.relatedProvider === providerId);
};

export function metricIsCustom(
  metric: WillyExpressionOrCustomMetric | WillyExpressionOrCustomMetricToServer,
): metric is WillyCustomMetric {
  return !!metric && 'expression' in metric;
}

export function metricIsNonCustomExpression(
  metric: WillyExpressionOrCustomMetric,
): metric is WillyExpressionMetric {
  return !metricIsCustom(metric);
}

export function metricIsExpression(
  metric: WillyMetric | WillyExpressionOrCustomMetric | undefined | null,
): metric is WillyExpressionOrCustomMetric {
  return !!metric && 'id' in metric;
}

export const fetchMetricById = async (
  metricId: string,
  currency: string,
  start: string,
  end: string,
  dialect: Dialect,
  metricForTest?: WillyExpressionOrCustomMetricToServer,
) => {
  if (!metricId && !metricForTest) return;
  const willyMetricsCombine = convertToServerMetrics($willyMetricsCombine.get());
  const metric = metricForTest ?? willyMetricsCombine.find((m) => m.id === metricId);
  if (!metric) return;

  const metricTrees = metricIsCustom(metric)
    ? extractFullMetricTree(metric as WillyCustomMetric, willyMetricsCombine)
    : ({ ...metric, isCustomMetric: false } as WillyExpressionMetric);
  const data = (
    await axiosInstance.post('/v2/willy/get-metrics-data', {
      metricTrees: [metricTrees],
      start,
      end,
      shopId: $currentShopId.get(),
      currency,
      dialect,
    })
  ).data?.[0]?.data;

  return data;
};

export const getMetricSqlMetadata = (
  metricKey: string,
  depth = 0,
): { sqlExpression: string; sqlRelatedTables: string[] } => {
  const allMetrics = $willyMetricsCombine.get();
  const metric = allMetrics.find((m) => m.key === metricKey);

  // Prevent infinite recursion of circular dependencies
  if (depth > 50 || !metric) {
    return {
      sqlExpression: '',
      sqlRelatedTables: [],
    };
  }

  let sqlRelatedTables: string[] = [];
  let sqlExpression: string;
  let isValidSqlExpression = true;

  if (metricIsNonCustomExpression(metric)) {
    // Ideally, it's best practice to use {tableId}.{columnId} to prevent ambiguity.
    // However, due to a backend bug, we're currently unable to use table names without aliases in the query.
    sqlExpression = `${metric.aggFunction}(${metric.columnId})`;
    sqlRelatedTables.push(metric.tableId);
  } else {
    sqlExpression = '';
    for (let i = 0; i < metric.expression.length; i++) {
      const element = metric.expression[i];
      switch (element.type) {
        case ElementTypes.OPERATOR:
        case ElementTypes.INTEGER:
        case ElementTypes.PARENTHESES:
          sqlExpression += element.value;
          break;
        case ElementTypes.METRIC:
          const relatedMetric = allMetrics.find((m) => m.key === element.value);
          isValidSqlExpression = isValidSqlExpression && !!relatedMetric;
          if (relatedMetric) {
            const {
              sqlExpression: relatedSqlExpression,
              sqlRelatedTables: relatedSqlRelatedTables,
            } = getMetricSqlMetadata(element.value, depth + 1);
            sqlExpression += relatedSqlExpression;
            sqlRelatedTables.push(...(relatedSqlRelatedTables ?? []));
          }
          break;
      }
    }

    sqlExpression = addSafeDivideToExpression(sqlExpression);
  }

  return {
    sqlExpression: depth ? sqlExpression : `${sqlExpression} AS \`${metric.key}\``,
    sqlRelatedTables: uniq(isValidSqlExpression ? sqlRelatedTables : ['']),
  };
};

function addSafeDivideToExpression(expression) {
  // Regular expression to match division operations
  const regex =
    /(\b(?:AVG|COUNT|SUM|MAX|MIN|COUNT_DISTINCT)\([^()]*\)|\b\w+|\([^()]+\))\s*\/\s*(\b(?:AVG|COUNT|SUM|MAX|MIN|COUNT_DISTINCT)\([^()]*\)|\b\w+|\([^()]+\))/g;

  // Replace division operations with safe_divide function
  const modifiedExpression = expression.replace(regex, 'safe_divide($1, $2)');

  return modifiedExpression;
}
