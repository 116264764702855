import { Button, Checkbox, Collapsible, Modal, RadioButton, TextField } from '@shopify/polaris';
import { MobileAcceptMajor, MobileCancelMajor } from '@shopify/polaris-icons';
import { BillingInterval, subscriptionTypes } from '@tw/types/module/services/subscription-manager';
import { ActivePMSection } from 'components/UserProfileManagment/Shop/ActivePMSection';
import { getShopFeatures } from 'ducks/shop';
import { getPlans, getRevenues, upgradePlanClicked, upgradePlanClosed } from 'ducks/subscription';
import { useAppDispatch } from 'index';
import { sum } from 'lodash';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { RootState } from 'reducers/RootType';
import axiosInstance from 'utils/axiosInstance';
import formatPrice from 'utils/formatPrice';
import content from 'routes/auth/content';
import { ProductsMetaData } from './UpgradePlanData';
import { useHistory, useLocation } from 'react-router';
import { isDefined } from 'utils/isDefined';
import ToggleSwitch from 'components/ToggleSwitch/ToggleSwitch';
import { setShopBillingInterval } from 'ducks/actions';
import { useFeatureFlagValue } from 'feature-flag-system';
import { FeatureFlag } from '@tw/feature-flag-system/module/types';
import { isProduction } from 'config';

const PLANNER_PRODUCT_ID = isProduction ? 'prod_Ov5K76C7ajPnIj' : 'prod_Ov59S62Zo1P04Z';

export const UpgradePlanModal: React.FC = () => {
  const [submitting, setSubmitting] = useState(false);
  const prices = useSelector((state: RootState) => state.plans);
  const revenues = useSelector((state: RootState) => state.revenues);
  const { search } = useLocation();
  const { isModalOpen, productId } = useSelector((state: RootState) => state.upgradePlanModalOpen);
  const subscription = useSelector((state: RootState) => state.subscription);
  const { revenue, items, promotion_code, coupon, shop, features, uncapped_tier } = useSelector(
    (state: RootState) => state.subscription,
  );
  const shopBillingInterval: BillingInterval = useSelector(
    (state: RootState) => state.billingIntervalsPerShop[shop],
  );
  const history = useHistory();
  const [selectedProduct, setSelectedProduct] = useState<any>(null);
  const [allSelectedAddons, setAllSelectedAddons] = useState<any[]>([]);
  const dispatch = useAppDispatch();
  const [changeCoupon, setChangeCoupon] = useState(false);
  const [newPromotionCode, setNewPromotionCode] = useState('');
  const [newPromotionCodeId, setNewPromotionCodeId] = useState('');
  const [newCoupon, setNewCoupon] = useState<any>(null);
  const [useNewCoupon, setUseNewCoupon] = useState(false);
  const [uncappedTierId, setUncappedTierId] = useState<number | null>(null);
  const couponInUse = useMemo(() => {
    return useNewCoupon ? newCoupon : coupon;
  }, [coupon, newCoupon, useNewCoupon]);
  const [newBillingInterval, setNewBillingInterval] = useState<BillingInterval>('year');
  const { suffix } = content.billingInterval[newBillingInterval] ?? {};
  const queryParams = new URLSearchParams(location.search);
  const premiumFoundersDash = queryParams.get('premium-founders-dash');
  const [subscriptionType, setSubscriptionType] = useState(subscription?.contract_type);

  const notHavePlanner = useFeatureFlagValue(FeatureFlag.FORECASTING_MENU_FF, 'shouldNotBeSeen');

  // useEffect(() => {
  //   setNewBillingInterval(shopBillingInterval);
  // }, [shopBillingInterval]);

  const toggleBillingInterval = useCallback(() => {
    setNewBillingInterval((prev) => (prev === 'month' ? 'year' : 'month'));
  }, []);

  useEffect(() => {
    if (
      subscription?.contract_type === subscriptionTypes['12 Month Contract'] &&
      newBillingInterval === 'month'
    ) {
      setSubscriptionType(subscriptionTypes['12 Month Contract']);
    } else {
      if (newBillingInterval === 'month') {
        if (subscription?.contract_type == 'Annual' && subscription?.revenue_id > 9) {
          setSubscriptionType(subscriptionTypes['12 Month Contract']);
        } else {
          setSubscriptionType(subscriptionTypes.Monthly);
        }
      }
      if (newBillingInterval === 'year') {
        setSubscriptionType(subscriptionTypes.Annual);
      }
    }
  }, [newBillingInterval]);

  const shopId = useMemo(() => {
    const params = new URLSearchParams(search);
    const shopId = params.get('shopId') || '';
    return shopId;
  }, [search]);

  const user = useSelector((state: RootState) => state.user);
  const discountedProducts: Set<string> | null = useMemo(
    () => (Array.isArray(couponInUse?.applies_to) ? new Set<string>(couponInUse.applies_to) : null),
    [couponInUse],
  );

  const handleNewPromotionCodeChange = useCallback((newValue: string) => {
    setNewPromotionCode(newValue);
    setUseNewCoupon(false);
  }, []);

  const isDiscounted = useCallback(
    (prodId: string = '') => {
      return (
        (discountedProducts === null && couponInUse) ||
        (discountedProducts !== null && discountedProducts.has(prodId))
      );
    },
    [discountedProducts, couponInUse],
  );

  const findPrices = useCallback(
    (type: string) => {
      const relevantPrices: any[] = [];
      const rev = uncappedTierId || revenue;

      if (newBillingInterval === shopBillingInterval) {
        relevantPrices?.push(
          ...(items ?? [])
            .filter((si) => si.product_type == type && !si.plan_preview_start_date)
            ?.map((si) => prices?.find((plan) => plan.price_id === si.price_id)),
        );
      }
      const currentProductIds = relevantPrices?.map((p) => p?.product_id);

      const filteredPrices = prices?.filter(
        (price) =>
          price.product_type === type &&
          price.billing_interval === newBillingInterval &&
          (price.for_sale ||
            subscription.items?.find(
              (item) =>
                (item.price_id === price.price_id || item.product_id === price.product_id) &&
                !item.plan_preview_start_date,
            ) ||
            (!notHavePlanner && price.product_id === PLANNER_PRODUCT_ID)),
      );
      relevantPrices.push(
        ...filteredPrices?.filter(
          (p) =>
            !currentProductIds?.includes(p.product_id) &&
            p.revenue_id == rev &&
            p.contract_type == subscriptionType,
        ),
      );
      return relevantPrices?.sort((a, b) => a.product_sort - b.product_sort);
    },
    [
      uncappedTierId,
      revenue,
      newBillingInterval,
      shopBillingInterval,
      prices,
      items,
      subscription.items,
      subscriptionType,
    ],
  );

  const productsList = useMemo(() => {
    const products = findPrices('package');
    return products?.map((product) => {
      return {
        ...product,
        addons: findPrices('addon').filter(
          (addon) => !product.features?.includes(addon.product_flag),
        ),
      };
    });
  }, [findPrices]);

  useEffect(() => {
    setSelectedProduct(null);
    setAllSelectedAddons([]);
  }, [isModalOpen]);

  /////products
  const handleProductChange = useCallback(
    (product) => {
      selectedProduct?.product_id === product.product_id
        ? setSelectedProduct(null)
        : setSelectedProduct(product);
    },
    [selectedProduct?.product_id],
  );
  useEffect(() => {
    if (uncapped_tier) {
      const tier = revenues?.find((r) => r.description === uncapped_tier);
      if (tier) {
        setUncappedTierId(tier?.id || null);
      }
    }
  }, [uncapped_tier, revenues]);

  const isSelectedProduct = useCallback(
    (product) => {
      return selectedProduct?.product_id === product.product_id;
    },
    [selectedProduct?.product_id],
  );

  useEffect(() => {
    const product = productId ? productsList.find((p) => p.product_id === productId) : undefined;
    if (product) {
      setSelectedProduct(product);
    }
  }, [productsList, productId]);

  /////addons
  useEffect(() => {
    const selectedList: any[] = [];
    productsList.forEach((product) => {
      let selectedProductAddons = [];
      selectedProductAddons = product.addons.filter(
        (addon) =>
          items?.find((item) => item.price_id === addon.price_id) &&
          selectedList.find((s) => s.price_id === addon.price_id) === undefined,
      );
      if (selectedProductAddons.length) {
        selectedList.push(...selectedProductAddons);
      }
      setAllSelectedAddons(selectedList);
    });
  }, [items, productsList]);

  const handleSelectAddon = useCallback((addon, checked: boolean) => {
    setAllSelectedAddons((prev) =>
      checked ? [...prev, addon] : prev.filter((s) => s.price_id !== addon.price_id),
    );
  }, []);

  const isSelectedAddon = useCallback(
    (addon) => {
      return !!allSelectedAddons.find((s) => s.price_id === addon.price_id);
    },
    [allSelectedAddons],
  );

  const selectedAddons = useMemo(
    () => allSelectedAddons.filter((addon) => selectedProduct?.addons?.includes(addon)),
    [allSelectedAddons, selectedProduct],
  );

  /////prices
  const calculatePriceAfterDiscount = useCallback(
    (price: number, product_id: string = '') => {
      if (!couponInUse || !isDiscounted(product_id)) return price;

      const { percent_off, amount_off } = couponInUse;
      if (isDefined(percent_off)) return price * ((100 - percent_off) / 100);
      if (isDefined(amount_off)) return price - amount_off;

      return price;
    },
    [couponInUse, isDiscounted],
  );

  const totalPrice = useMemo(() => {
    let total = 0;
    if (selectedProduct) {
      total += +selectedProduct.price;
    }
    if (selectedAddons.length) {
      total += selectedAddons.reduce((acc, addon) => acc + +addon.price, 0);
    }
    return total;
  }, [selectedProduct, selectedAddons]);

  const totalPriceAfterDiscount = useMemo(() => {
    let total = 0;
    if (selectedProduct) {
      const { price, product_id } = selectedProduct;
      total += calculatePriceAfterDiscount(+price, product_id);
    }
    if (selectedAddons.length) {
      total += selectedAddons.reduce((acc, { price, product_id }) => {
        return acc + calculatePriceAfterDiscount(+price, product_id);
      }, 0);
    }
    return +total.toFixed(0); // needed to handle weird floating point behavior
  }, [selectedProduct, selectedAddons, calculatePriceAfterDiscount]);

  const findMissedFeatures = useCallback(
    (productMetaData) => {
      const currentProducts = items?.map((item) => item.product_name) || [];
      return productMetaData?.missedFeatures?.find((missedFeature) =>
        missedFeature?.from?.every((fromFeature) => currentProducts?.includes(fromFeature)),
      )?.features;
    },
    [items],
  );

  const tryNewPromotionCode = useCallback(async () => {
    if (useNewCoupon) {
      setUseNewCoupon((prev) => !prev);
      return;
    }
    if (!newPromotionCode) {
      toast.error('No promotion code entered');
      return;
    }
    try {
      const res = await axiosInstance.get(
        `/v2/subscription-manager/promotions/code/${newPromotionCode}`,
      );
      if (!res.data.length) {
        throw new Error('No promotion code found');
      }
      setNewPromotionCodeId(res.data[0]?.id);
      setNewCoupon({
        ...res.data[0]?.coupon,
        applies_to: res.data[0]?.coupon.applies_to?.products,
      });
      setUseNewCoupon(true);
    } catch (err) {
      toast.error(`Something went wrong: ${err.message}`);
      console.log(err);
    }
  }, [newPromotionCode, useNewCoupon]);

  const handleSubmit = async () => {
    if (!selectedProduct) {
      toast.error('No product selected. please select a product');
      return;
    }
    try {
      setSubmitting(true);
      const allSelected = [...selectedAddons, selectedProduct];
      const subscribePrices = allSelected
        .filter(
          (s) =>
            !subscription.items?.find(
              (item) =>
                item.price_id === s.price_id &&
                !item.plan_preview_start_date &&
                !item.plan_preview_end_date,
            ),
        )
        ?.map((s) => s.price_id);
      const unsubscribeDiff = subscription.items
        ?.filter((item) => {
          if (
            !subscribePrices.find((price_id) => price_id === item.price_id) &&
            item.price_id !== selectedProduct.price_id &&
            !selectedAddons.find((s) => s.price_id === item.price_id)
          )
            return item.subscription_item;
        })
        ?.map((item) => item.subscription_item);

      let body: any = {
        subscribe: subscribePrices,
        unsubscribe: unsubscribeDiff,
      };
      if (useNewCoupon) {
        body['promotionCodeId'] = newPromotionCodeId;
      }
      if (subscriptionType !== subscription.contract_type) {
        body['contractType'] = subscriptionType;
        if (subscriptionType === subscriptionTypes['12 Month Contract']) {
        } else {
          body['contractEndDate'] = new Date('1900-01-01');
        }
      }
      await axiosInstance.patch(
        '/v2/subscription-manager/subscriptions/' + subscription.subscription,
        body,
      );
      dispatch(getShopFeatures(shop));
      dispatch(setShopBillingInterval({ shopUrl: shopId, billingInterval: newBillingInterval }));
      toast.success('The subscription has been updated successfully');
      dispatch(upgradePlanClosed());
    } catch (err) {
      toast.error(`Something went wrong - ${err.data?.message}`);
      console.log(err);
    } finally {
      setSubmitting(false);
    }
  };

  useEffect(() => {
    const params = new URLSearchParams(search);
    const upgradePopupOpen = params.get('upgradePlanPopupOpen');
    if (upgradePopupOpen && user.shops) dispatch(upgradePlanClicked(history));
  }, [dispatch, search, user.shops, history]);

  useEffect(() => {
    if (isModalOpen) {
      dispatch(getPlans());
      dispatch(getRevenues());
    }
  }, [dispatch, isModalOpen]);

  return (
    <Modal
      open={isModalOpen}
      onClose={() => {
        !submitting && dispatch(upgradePlanClosed());
      }}
      title={'Change plan'}
      primaryAction={{
        content: 'Confirm',
        onAction: handleSubmit,
        loading: submitting,
        disabled: !selectedProduct || submitting,
      }}
      secondaryActions={[
        {
          content: 'Cancel',
          onAction: () => dispatch(upgradePlanClosed()),
          disabled: submitting,
        },
      ]}
    >
      <div className="p-10 flex flex-col gap-9">
        <div className="p-6.5 border border-solid border-[#E5E7EB] rounded-lg font-medium">
          <span className="text-2xl">Current plan</span>
          <div className="py-6.5">
            {items &&
              items?.length > 0 &&
              items
                ?.filter((item) => !item.plan_preview_start_date)
                .map((item) => (
                  <span
                    key={`current_${item.price_id}`}
                    className="flex items-center justify-between"
                  >
                    <span className="flex items-center gap-3">
                      <MobileAcceptMajor width={12} fill="#2B86FD" />
                      {item.product_name}
                    </span>
                    <span className="font-semibold">
                      <span
                        className={
                          isDiscounted(item.product_id) ? 'line-through text-gray-400' : ''
                        }
                      >
                        ${formatPrice(item.price)}
                        <span className="font-normal">
                          {' '}
                          {content.billingInterval[shopBillingInterval]?.suffix}
                        </span>
                      </span>
                      {isDiscounted(item.product_id) && (
                        <span className="pl-6.5">
                          ${formatPrice(calculatePriceAfterDiscount(item.price, item.product_id))}
                        </span>
                      )}
                    </span>
                  </span>
                ))}
          </div>
          <span className="flex items-center justify-between text-[#2B86FD]">
            <span>Total</span>
            <span className="font-semibold">
              $
              {formatPrice(
                sum(items?.map((item) => calculatePriceAfterDiscount(item.price, item.product_id))),
              )}
            </span>
          </span>
        </div>
        <div className="p-6.5 border border-solid border-[#E5E7EB] rounded-lg">
          <div className="flex justify-center items-center gap-5 ">
            <div>Billed monthly</div>
            <ToggleSwitch
              onChange={toggleBillingInterval}
              checked={newBillingInterval === 'month'}
              className="cursor-pointer"
              width={48}
              height={24}
              handleDiameter={16}
              color="#1877F2"
            />
            <div>Billed yearly</div>
          </div>
        </div>
        <div className="px-6.5 border border-solid border-[#E5E7EB] rounded-lg">
          {productsList &&
            productsList?.length > 0 &&
            productsList?.map((product, index) => {
              const productMetaData = ProductsMetaData[product.product_name];
              return (
                <div key={product.product_name}>
                  <div
                    className="flex justify-between py-6.5"
                    style={{
                      color: isSelectedProduct(product) ? '#2B86FD' : undefined,
                      borderTop: index !== 0 ? 'solid 1px #E5E7EB' : undefined,
                    }}
                  >
                    <span className="flex gap-6.5">
                      <RadioButton
                        label=""
                        labelHidden
                        checked={isSelectedProduct(product)}
                        onChange={() => handleProductChange(product)}
                      />
                      <p className="font-medium">{product.product_name}</p>
                    </span>
                    <span className="font-semibold">
                      <span
                        className={
                          isDiscounted(product.product_id) ? 'line-through text-gray-400' : ''
                        }
                      >
                        ${formatPrice(product.price)}
                        <span className="font-normal"> {suffix}</span>
                      </span>
                      {isDiscounted(product.product_id) && (
                        <span className="pl-6.5">
                          $
                          {formatPrice(
                            calculatePriceAfterDiscount(product.price, product.product_id),
                          )}
                        </span>
                      )}
                    </span>
                  </div>
                  <Collapsible open={isSelectedProduct(product)} id={`product-plan-${index}`}>
                    <div className="flex flex-col gap-6.5 pb-6.5">
                      <p className="pl-[36px]">{productMetaData?.description}</p>
                      <div className="pl-[36px]">
                        {productMetaData?.includedFeatures?.map((feature) => (
                          <span
                            key={`included_${feature}`}
                            className="flex items-center gap-3 leading-6"
                          >
                            <MobileAcceptMajor width={12} fill="#2B86FD" />
                            {feature}
                          </span>
                        ))}
                      </div>
                      {product?.addons && product?.addons?.length > 0 && (
                        <div className="pl-[36px]">
                          <span className="font-medium">ADD-ONS</span>
                          {product?.addons?.map((addon) => (
                            <div
                              className="flex items-center justify-between"
                              key={`add-on_${addon.price_id}_${addon.contract_type}_${addon.revenue_id}`}
                            >
                              <Checkbox
                                label={addon.product_name}
                                checked={isSelectedAddon(addon)}
                                onChange={(checked) => handleSelectAddon(addon, checked)}
                              />
                              <span className="font-semibold">
                                <span
                                  className={
                                    isDiscounted(addon.product_id)
                                      ? 'line-through text-gray-400'
                                      : ''
                                  }
                                >
                                  ${formatPrice(addon.price)}
                                  <span className="font-normal"> {suffix}</span>
                                </span>
                                {isDiscounted(addon.product_id) && (
                                  <span className="pl-6.5">
                                    $
                                    {formatPrice(
                                      calculatePriceAfterDiscount(addon.price, addon.product_id),
                                    )}
                                  </span>
                                )}
                              </span>
                            </div>
                          ))}
                        </div>
                      )}
                      <Attention missedFeatures={findMissedFeatures(productMetaData)} />
                    </div>
                  </Collapsible>
                </div>
              );
            })}
        </div>
        <ActivePMSection wrapperClass="bg-[#EDF7FF] rounded-lg" updateButtonPlain={true} />
        <div className="flex flex-col gap-6.5">
          <span className="flex justify-between font-medium">
            <span>Subtotal</span>
            <span>${formatPrice(totalPrice)}</span>
          </span>
          {couponInUse && (
            <span className="flex justify-between font-medium">
              <span className="flex items-center gap-2">
                <span>
                  Discount
                  <span className="text-gray-400">
                    ({useNewCoupon ? newPromotionCode : promotion_code ?? couponInUse.name})
                  </span>
                </span>
                <Button
                  onClick={() => {
                    setChangeCoupon(true);
                  }}
                  plain
                >
                  Change Coupon
                </Button>
              </span>
              <span className="text-[#10B981]">
                -${formatPrice(totalPrice - totalPriceAfterDiscount, 2)}
              </span>
            </span>
          )}
          <Collapsible open={changeCoupon || !couponInUse} id="change-coupon-collapse">
            <span className="flex items-center gap-2">
              <TextField
                label=""
                labelHidden
                placeholder="Enter a promotion code"
                autoComplete="off"
                value={newPromotionCode}
                onChange={handleNewPromotionCodeChange}
              />
              <Button onClick={tryNewPromotionCode}>{useNewCoupon ? 'Remove' : 'Apply'}</Button>
            </span>
          </Collapsible>
          <span className="flex justify-between font-medium">
            <span>Total per {newBillingInterval}</span>
            <span>${formatPrice(totalPriceAfterDiscount, 2)}</span>
          </span>
        </div>
      </div>
    </Modal>
  );
};

type AttentionProps = {
  missedFeatures?: string[];
};

const Attention: React.FC<AttentionProps> = ({ missedFeatures }) => {
  return (
    <div className="p-6.5 rounded bg-[#FFFBEB] flex flex-col gap-3">
      <span className="font-medium">Attention</span>
      <p>{`Upon changing your plan, you will be charged the pro-rated amount for your additional product/s. your billing cycle will otherwise remain the same. ${
        missedFeatures?.length ? `With this plan change you will lose access to:` : ''
      }`}</p>
      <div>
        {missedFeatures?.map((feature) => (
          <span key={`missed_${feature}`} className="flex items-center gap-3 leading-6">
            <MobileCancelMajor width={12} fill="#B91C1C" />
            {feature}
          </span>
        ))}
      </div>
    </div>
  );
};
