import { $currentShopId } from '$stores/$shop';
import {
  $isAdminClaim,
  $isShopAdmin,
  $isTwGlobalDashboardCreatorClaim,
  $user,
  $userId,
} from '$stores/$user';
import { $derived, $mutableDerived, $observer, $store } from '@tw/snipestate';
import {
  DashboardDataWithSequenceHistory,
  ScheduleItem,
  WillyDataSequence,
  WillyElementType,
} from 'components/Willy/types/willyTypes';
import { getScheduleCollection } from 'components/Willy/utils/willyUtils';
import _db, { firestoreRef, toArray } from 'utils/DB';
import { $ffStore } from '../../feature-flag-system';
import { $shopWithSensory } from '../$shopWithSensory';
import { $combinedDashboard } from './$combinedDashboards';
import { $globalDashboardsStatistics } from './$globalDashboardsStatistics';
import { WillyDashboardElement } from '../../components/Willy/types/willyTypes';
import { services } from '@tw/types/module/services';
import { FeatureFlag, FeatureFlagConfigKey } from '@tw/feature-flag-system/module/types';

export const $loadingGlobalSequences = $store(true);

export const $globalSequences = $observer<WillyDataSequence[]>([], (get, set) => {
  const ffComputer = get($ffStore);
  if (!ffComputer.isReady) return;

  const shopWithSensory = get($shopWithSensory);
  const globalDashboardsStatistics = get($globalDashboardsStatistics);
  const isTwGlobalDashboardCreatorClaim = get($isTwGlobalDashboardCreatorClaim);
  const isAdminClaim = get($isAdminClaim);
  const { allowList } = ffComputer.getConfigById(FeatureFlag.TEMPLATES_FF);
  const allowedSeqIds = new Set(allowList);

  return firestoreRef()
    .collection('global_data_sequences')
    .onSnapshot((querySnapshot) => {
      let arr: WillyDataSequence[] = toArray(querySnapshot)
        .sort((a, b) => {
          if (a.name < b.name) return -1;
          return 1;
        })
        .map((sequence) => {
          let isProviderLocked = false;
          const { providers, providersBlockingCombination } = sequence;
          if (providers?.length > 0) {
            const providersIsConnected = providers.map(
              (x) => services[x]?.getIsConnected?.(shopWithSensory) ?? true,
            );
            if (providersBlockingCombination === 'OR') {
              isProviderLocked = providersIsConnected.every((x) => !x);
            } else {
              isProviderLocked = providersIsConnected.includes(false);
            }
          }

          const pkgMap = ffComputer.ffPackagesConfig;

          const packages: FeatureFlagConfigKey[] = [];
          if (pkgMap) {
            for (let pkgName in pkgMap) {
              const _pkgName = pkgName as FeatureFlagConfigKey;
              const val = pkgMap?.[_pkgName]?.[FeatureFlag.TEMPLATES_FF]?.value;
              if (Array.isArray(val) && (val as any[]).includes(sequence.id))
                packages.push(_pkgName);
            }
          }

          const defaultPackages: FeatureFlagConfigKey[] = [];
          if (pkgMap) {
            for (let pkgName in pkgMap) {
              const _pkgName = pkgName as FeatureFlagConfigKey;
              const val = pkgMap?.[_pkgName]?.[FeatureFlag.WILLY_DEFAULT_TEMPLATES_FF]?.value;
              if (Array.isArray(val) && (val as any[]).includes(sequence.id))
                defaultPackages.push(_pkgName);
            }
          }

          return {
            ...sequence,
            type: 'sequence',
            isGlobal: true,
            installCount: globalDashboardsStatistics[sequence.id]?.installed ?? 0,
            packages,
            defaultPackages,
            canEdit: isTwGlobalDashboardCreatorClaim,
            isLocked: !!(allowedSeqIds.size && !allowedSeqIds.has(sequence.id)),
            isProviderLocked,
          };
        });
      arr = arr.filter(({ isBeta }) => (isAdminClaim ? true : !isBeta));
      arr = arr.filter(({ isHide }) => !isHide);

      set(arr);
      $loadingGlobalSequences.set(false);
    });
});

export const $shopSequencesRaw = $observer<WillyDataSequence[]>([], (get, set) => {
  if (!get($userId)) return;

  const shopId = get($currentShopId);
  if (!shopId) return set([]);

  const globalSequences = get($globalSequences);
  const isUserTwAdmin = get($isAdminClaim);
  const isShopAdmin = get($isShopAdmin);

  return _db(shopId)
    .collection('data_sequences')
    .onSnapshot((querySnapshot) => {
      let data: WillyDataSequence[] = toArray(querySnapshot);
      data = data
        .filter((x) => x.name)
        .map((seq) => {
          let isLocked = false;
          let isProviderLocked = false;

          if (
            seq.globalDashboardId &&
            globalSequences.map((x) => x.id).includes(seq.globalDashboardId)
          ) {
            const globalSeq = globalSequences.find((d) => d.id === seq.globalDashboardId);
            if (globalSeq) {
              isLocked = globalSeq.isLocked ?? false;
              isProviderLocked = globalSeq.isProviderLocked ?? false;
            }
          }

          return {
            ...seq,
            isLocked,
            isProviderLocked,
            canEdit: isUserTwAdmin || isShopAdmin, //TODO @Yitzchak Sviridyuk permission
            isGlobal: false,
            type: 'sequence' as WillyElementType,
          };
        })
        .sort((a, b) => {
          if (a.name < b.name) return -1;
          return 1;
        });

      set(data);
    });
});

export const $sequencesSchedules = $observer<ScheduleItem[]>([], (get, set) => {
  const userId = get($userId);
  const shopId = get($currentShopId);
  if (!userId || !shopId) return set([]);

  return getScheduleCollection(userId, shopId).onSnapshot((querySnapshot) => {
    const data: ScheduleItem[] = toArray(querySnapshot);
    set(data.map((schedule) => ({ ...schedule, isGlobal: false, type: 'schedule' })));
  });
});

export const $shopSequences = $mutableDerived<WillyDataSequence[]>((get) => {
  const shopSequencesRaw = get($shopSequencesRaw);
  const sequencesSchedules = get($sequencesSchedules);
  const sequencesWithScheduleId = shopSequencesRaw.map((sequence) => {
    return {
      ...sequence,
      schedule: sequencesSchedules.find((schedule) => schedule.sequenceId === sequence.id),
    };
  });
  return sequencesWithScheduleId;
});

export const $hasSequenceAccess = $derived((get) => !!get($user)?.hasSequencesAccess);

export const $reportsWithSequences = $observer(
  [] as DashboardDataWithSequenceHistory[],
  (get, set) => {
    const shopId = get($currentShopId);
    const userId = get($userId);
    if (!shopId || !userId) return;

    const dashboards = get($combinedDashboard);
    return firestoreRef()
      .collectionGroup('sequences_dashboard_reports')
      .where('shopId', '==', shopId)
      .orderBy('createdAt', 'desc')
      .onSnapshot((querySnapshot) => {
        const ds = dashboards
          .map((dash) => {
            const seqs = querySnapshot.docs
              .map((r) => {
                const dashboardId = r.ref.parent.parent?.id;
                return dashboardId === dash.id || dashboardId === dash.globalDashboardId
                  ? r.data()
                  : false;
              })
              .filter((d) => !!d)
              .sort((a, b) => {
                return a.createdAt < b.createdAt ? 1 : -1;
              });
            return seqs.length > 0
              ? { ...(dash as WillyDashboardElement), history: seqs as WillyDataSequence[] }
              : false;
          })
          .filter((d) => !!d);

        set(ds);
      });
  },
);

export const $sequencesUnreadReportsPerDashboard = $observer<Record<string, number>>(
  {},
  (get, set) => {
    const shopId = get($currentShopId);
    const userId = get($userId);
    if (!shopId || !userId) return;

    return firestoreRef()
      .collectionGroup('sequences_dashboard_reports')
      .where('shopId', '==', shopId)
      .onSnapshot((querySnapshot) => {
        const data = querySnapshot.docs.reduce(
          (acc, doc) => {
            const { read } = doc.data();
            if (read) {
              return acc;
            }
            const dashboardId = doc.ref.parent.parent?.id;
            if (!dashboardId) return acc;
            acc[dashboardId] = (acc[dashboardId] || 0) + 1;
            return acc;
          },
          {} as Record<string, number>,
        );
        set(data);
      });
  },
);
