import { TimeFrame } from '@tw/types/module/services/insights';
import { isTimeFrameEnabled } from 'components/Insights/isTimeFrameEnabled';
import SummaryDatePicker from 'components/SummaryDatePicker';
import { DatePickerTimePeriods } from 'components/useDatePickerSelectedOptions';
import { mainDatePickerOnSelectionChange, mainDatePickerSelectedOptionChange } from 'ducks/actions';
import { setInsightsDateRange, setInsightsTimeframe } from 'ducks/insights/actions';

import { useAppDispatch } from 'index';
import moment from '@tw/moment-cached';
import { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'reducers/RootType';
import { useStoreValue } from '@tw/snipestate';
import { $activeAppVersion } from '$stores/nav-config-stores';
import { Flex, Icon, Text } from '@tw/ui-components';

export const InsightsHeader = () => {
  const dispatch = useAppDispatch();
  const mainDatePickerSelectionRange = useSelector(
    (state: RootState) => state.mainDatePickerSelectionRange,
  );
  const timeframe = useSelector((state: RootState) => state.insights.timeframe);
  const isFirstRender = useRef(true);
  const activeAppVersion = useStoreValue($activeAppVersion);

  // set default date-range on page load
  useEffect(() => {
    const id = DatePickerTimePeriods.LAST_365_DAYS;
    const start = moment().subtract(1, 'year').startOf('day');
    const end = moment().subtract(1, 'day').endOf('day');
    dispatch(mainDatePickerSelectedOptionChange(id));
    const range = {
      id,
      start,
      end,
    };
    dispatch(setInsightsDateRange(start, end));
    dispatch(setInsightsTimeframe(TimeFrame.MONTH));
    dispatch(mainDatePickerOnSelectionChange(range));
  }, []);

  // update instights date-range when main date picker date-range changed
  useEffect(() => {
    const start = mainDatePickerSelectionRange?.start;
    const end = mainDatePickerSelectionRange?.end;

    if (!start || !end || mainDatePickerSelectionRange?.id === DatePickerTimePeriods.TODAY) return;

    if (isFirstRender.current) {
      isFirstRender.current = false;
    } else {
      if (!isTimeFrameEnabled(timeframe, start, end)) {
        const newTimeframe = Object.values(TimeFrame).find((i) =>
          isTimeFrameEnabled(i, start, end),
        );
        dispatch(setInsightsTimeframe(newTimeframe!));
      }
      dispatch(setInsightsDateRange(start, end));
    }
  }, [
    mainDatePickerSelectionRange?.start,
    mainDatePickerSelectionRange?.end,
    mainDatePickerSelectionRange?.id,
    dispatch,
  ]);

  return (
    <Flex align="center" gap="md">
      {activeAppVersion === '3.0' && (
        <Flex align="center" pl="sm" gap="sm">
          <Icon name="widget-editor" color={'gray.5'} size={18} />
          <Text color="gray.7" weight={500} size="lg">
            Retention
          </Text>
        </Flex>
      )}
      <SummaryDatePicker
        buttonSize="xs"
        showGroupByButton={false}
        unSuportedOptions={[DatePickerTimePeriods.TODAY, DatePickerTimePeriods.YESTERDAY]}
      />
    </Flex>
  );
};

export default InsightsHeader;
