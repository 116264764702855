import { useSelector } from 'react-redux';
import { useCallback, useState, useEffect, useMemo } from 'react';
import firebase from 'firebase/compat/app';
import 'firebase/auth';

import { Button, Card, Spinner, TextField, Checkbox, Select, Icon, Tabs } from '@shopify/polaris';
import { ChevronLeftMinor, EmailMajor } from '@shopify/polaris-icons';
import ReactSelect from 'react-select';

import validateEmail from 'utils/validateEmail';
import { amplitudeTracker } from 'utils/AmplitudeTracker';
import { type RootState } from 'reducers/RootType';

import SlackIconBase64 from 'components/SlackIconBase64';

import { MetricsSelector } from './ReportMetricsSelection';
import { useHistory } from 'react-router';
import { ShopifySegmentType } from 'types/shopify';
import { useIsSmall } from 'hooks/useDefaultWindowSizes';
import { ReportFrequency } from './ReportFrequency';
import {
  $shopCustomViewAndDefaultDashboards,
  $allowedShopCustomViewAndDefaultDashboards,
} from '$stores/willy/$customViews';
import { datePickerOptions } from './utils';
import { useStoreValue } from '@tw/snipestate';
import { selectAllActiveOrderSegmentMetrics } from 'utils/selectors';
import { UpgradePageContent } from 'feature-flag-system/components';

import { $v3_0_Enabled } from '$stores/$v3_0_Enabled';

const auth = firebase.auth;

export const generateEmptyReport = ({ hasLighthouse = false }) => {
  return {
    title: '',
    frequency: null,
    metrics: [],
    recipients: [auth().currentUser?.email],
    hours: [],
    isSendToLighthouse: hasLighthouse,
    format: 'pdf',
  };
};

export const ReportForm: React.FC<{
  onSubmit: (report: any) => void;
  item?: any;
  isEditing?: boolean;
  isFromAction?: boolean;
  actionData?: {
    email: string;
    title: string;
    hour: string;
    frequency: string;
  };
  onCancel?: () => void;
  onTryItNow: (report) => void;
  isSlackConnected?: boolean;
}> = ({
  onSubmit,
  item,
  isEditing,
  onCancel,
  actionData,
  onTryItNow,
  isSlackConnected,
  isFromAction = false,
}) => {
  const isSmall = useIsSmall();
  const history = useHistory();
  const hasWilly = $v3_0_Enabled.get();
  const urlParams = new URLSearchParams(window.location.search);
  const willyDashId = urlParams.get('willyDashId') || '';
  const allDashboards = useStoreValue($shopCustomViewAndDefaultDashboards);
  const allowedDashboards = useStoreValue($allowedShopCustomViewAndDefaultDashboards);
  const hasLighthouse = useSelector((state: RootState) => state.hasLighthouse);
  const report = item ? { ...item } : generateEmptyReport({ hasLighthouse });
  const [selectedMetrics, setSelectedMetrics] = useState(report.metrics || []);
  const [selectedDash, setSelectedDash] = useState<any[]>([]);
  const [selectedHours, setSelectedHours] = useState(
    actionData?.hour ?? report.hours?.length > 0 ? report.hours : [9],
  );
  const [frequency, setFrequency] = useState(actionData?.frequency ?? (report.frequency || 'day'));
  const [selectedDateRange, setSelectedDateRange] = useState(
    datePickerOptions.find((r) => r.id == report.selectedDateRangeId) ?? datePickerOptions[0],
  );
  const [title, setTitle] = useState(actionData?.title ?? report.title);
  const [isNewDesign, setIsNewDesign] = useState(report.isNewDesign || false);
  const [isSendToEmail, setIsSendToEmail] = useState(
    report.isSendToEmail != null ? report.isSendToEmail : true,
  );

  const formatOptions = [
    // { label: 'Screenshot', value: 'screenshot' },
    { label: 'PDF', value: 'pdf' },
    { label: 'JSON', value: 'json' },
  ];

  const [format, setFormat] = useState(report.sendAsData ? 'data' : report.format || 'pdf');
  const [isSendToSlack, setIsSendToSlack] = useState(report.isSendToSlack || false);
  const [isSendToLighthouse, setIsSendToLighthouse] = useState(report.isSendToLighthouse);
  const [recipients, setRecipients] = useState(actionData?.email ?? report.recipients?.join(', '));
  const [sending, setSending] = useState(false);

  const onSelectMetric = useCallback((allSelectedMetrics) => {
    setSelectedMetrics(allSelectedMetrics);
    setSelectedDash([]);
  }, []);

  const { activeOrderSegment } = useSelector(selectAllActiveOrderSegmentMetrics);

  const getWillyDashboardName = useCallback(
    (dash) => {
      const dashboard = allDashboards.find((d) => d.id || d === (dash?.id || dash));
      return dashboard ? dashboard.name ?? dash.id ?? '' : '';
    },
    [allDashboards],
  );

  const tabs = useMemo(
    () => [
      {
        id: 'metrics',
        content: 'Metrics',
        accessibilityLabel: 'Metrics',
        panelID: 'metrics',
        disabled: isFromAction,
      },
      {
        id: 'dashboards',
        content: 'Reports',
        accessibilityLabel: 'Moby Reports',
        panelID: 'dashboards',
        disabled: !hasWilly,
      },
    ],
    [hasWilly, isFromAction],
  );

  const [selectedTab, setSelectedTab] = useState(0);

  const isDashboard = useMemo(
    () => tabs[selectedTab]?.id === 'dashboards' || false,
    [selectedTab, tabs],
  );
  const isTabDisabled = useMemo(() => tabs[selectedTab]?.disabled || false, [selectedTab, tabs]);

  useEffect(() => {
    if (willyDashId) {
      setSelectedDash([{ value: willyDashId, label: getWillyDashboardName(willyDashId) }]);
      setSelectedTab(tabs.findIndex((item) => item.id === 'dashboards'));
      const queryParams = new URLSearchParams(location.search);
      queryParams.delete('willyDashId');
      history.replace({
        search: queryParams.toString(),
      });
    }
  }, [willyDashId, getWillyDashboardName, tabs, history, hasWilly]);

  useEffect(() => {
    if (report?.dashboards?.length > 0) {
      setSelectedTab(tabs.findIndex((item) => item.id === 'dashboards'));
    }
  }, [hasWilly, report?.dashboards?.length, tabs]);

  const onSelectDash = useCallback((allSelectedDash) => {
    setSelectedDash(allSelectedDash), setSelectedMetrics([]);
  }, []);

  useEffect(() => {
    if (report?.dashboards?.length > 0) {
      const selectedDashboards = report.dashboards.map((dash) => {
        return { value: dash, label: getWillyDashboardName(dash) };
      });
      setSelectedDash(selectedDashboards);
    }
  }, [report.dashboards, getWillyDashboardName]);

  const onSelectHour = useCallback((hours) => setSelectedHours(hours), []);

  const onSelectFrequency = useCallback(
    (selectedFrequency) => {
      const defaultHourlyHours = [8, 9, 10, 11, 12, 13, 14, 15, 16, 17];
      setFrequency(selectedFrequency);
      if (selectedFrequency === 'hour')
        setSelectedHours(report.hours ?? defaultHourlyHours.slice());
      else setSelectedHours(report.hours ?? [9]);
    },
    [report.hours],
  );

  const handleSendAsChange = useCallback((format) => {
    setFormat(format);
  }, []);

  const handleDesignSelectionChange = useCallback((isNewDesign) => setIsNewDesign(isNewDesign), []);

  const recipientsList = useMemo(() => {
    return recipients?.split(',').map((r) => r.trim());
  }, [recipients]);

  const handleTabChange = useCallback(
    (selectedTabIndex: number) => setSelectedTab(selectedTabIndex),
    [],
  );

  const _onSubmit = useCallback(() => {
    const obj = {
      title: title,
      metrics: selectedMetrics,
      activeOrderSegment,
      recipients: recipientsList.filter(validateEmail),
      frequency,
      hours: selectedHours,
      isNewDesign: typeof isNewDesign === 'undefined' ? false : isNewDesign,
      isSendToEmail,
      isSendToSlack,
      isSendToLighthouse: typeof isSendToLighthouse === 'undefined' ? false : isSendToLighthouse,
      selectedDateRangeId: selectedDateRange.id,
      id: isEditing ? report.id : null,
      dashboards:
        selectedDash?.map((d) => {
          const dash = allDashboards.find((f) => f.id === (d.value?.id || d.value));
          if (!dash) return { id: d.value, isStandardDashboard: false };
          return dash;
        }) || [],
      format,
    };

    if (!isEditing) {
      amplitudeTracker('REPORT ADDED', isSendToSlack && 'Slack');
    }
    onSubmit(obj);
  }, [
    title,
    selectedMetrics,
    activeOrderSegment,
    recipientsList,
    frequency,
    selectedHours,
    isNewDesign,
    isSendToEmail,
    isSendToSlack,
    isSendToLighthouse,
    selectedDateRange.id,
    isEditing,
    report.id,
    selectedDash,
    format,
    onSubmit,
    allDashboards,
  ]);

  const _onTryItNow = useCallback(async () => {
    setSending(true);

    const reportData = {
      id: report.id,
      title: title,
      metrics: selectedMetrics,
      recipients: recipientsList.filter(validateEmail),
      frequency,
      isNewDesign: isNewDesign,
      isSendToEmail,
      isSendToSlack,
      isSendToLighthouse,
      hours: selectedHours,
      selectedDateRangeId: selectedDateRange?.id,
      startDate: isDashboard ? selectedDateRange?.start?.format('YYYY-MM-DD') : null,
      endDate: isDashboard ? selectedDateRange?.end?.format('YYYY-MM-DD') : null,
      dashboards:
        selectedDash?.map((d) => {
          const dash = allDashboards.find((f) => f.id === (d.value?.id || d.value));
          if (!dash) return { id: d.value, isStandardDashboard: false };
          return dash;
        }) || [],
      activeOrderSegment,
      format,
    };
    console.log(reportData);
    await onTryItNow(reportData);
    setSending(false);
  }, [
    report.id,
    title,
    selectedMetrics,
    recipientsList,
    frequency,
    isNewDesign,
    isSendToEmail,
    isSendToSlack,
    isSendToLighthouse,
    selectedHours,
    selectedDateRange?.id,
    selectedDateRange?.start,
    selectedDateRange?.end,
    isDashboard,
    selectedDash,
    activeOrderSegment,
    format,
    onTryItNow,
    allDashboards,
  ]);

  const isValid = useMemo(() => {
    if (!frequency) {
      return false;
    }
    if (!selectedMetrics[0] && !selectedDash[0]) {
      return false;
    }
    if (!isSendToEmail && !isSendToSlack && !isSendToLighthouse) {
      return false;
    }
    if (isSendToEmail && !recipientsList.filter(validateEmail).length) {
      return false;
    }
    return true!;
  }, [
    frequency,
    isSendToEmail,
    isSendToLighthouse,
    isSendToSlack,
    recipientsList,
    selectedMetrics,
    selectedDash,
  ]);

  const [popoverActive, setPopoverActive] = useState(false);

  const togglePopoverActive = useCallback(
    () => setPopoverActive((popoverActive) => !popoverActive),
    [],
  );

  return (
    <Card>
      <div className="lg:px-[65px] py-[40px]">
        <section className="flex">
          {!isEditing && !isFromAction && (
            <div className="sm:w-[30px] lg:w-[223px] inline-block">
              <div className="text-[12px] font-medium">
                <Button icon={ChevronLeftMinor} plain size="slim" onClick={onCancel}>
                  {isSmall ? '' : 'Back to Reports'}
                </Button>
              </div>
            </div>
          )}
          <div className="report-form lg:w-6/12 inline-block pr-[30px] lg:pr-0">
            <div className="text-[20px] font-semibold mb-[8px]">
              {isEditing ? 'Edit' : 'New'} Report
            </div>
            <div className="text-[14px] leading-loose mb-[40px]">
              Regular, custom updates direct to your inbox
            </div>
            <div className="text-[18px] font-medium mb-[16px]">General</div>
            <div className="mb-[32px] lg:w-6/12">
              <div className="text-[12px] font-medium mb-[8px]">Report title</div>
              <TextField label="" value={title} onChange={setTitle} autoComplete="off" />
            </div>

            {(isSlackConnected || hasLighthouse) && (
              <div className="mb-[32px]">
                <div className="text-[12px] font-medium mb-[8px]"> Send report to</div>
                <div className="lg:inline-flex gap-[35px] delivery-methods">
                  <div className="flex items-center max-w-fit">
                    <Icon source={EmailMajor} color="base" />
                    <Checkbox
                      label="Email"
                      checked={isSendToEmail}
                      value={isSendToEmail}
                      onChange={(checked) => {
                        setIsSendToEmail(checked);
                      }}
                    />
                  </div>
                  <div className="flex items-center">
                    <SlackIconBase64 small={false} />
                    <Checkbox
                      label="Slack"
                      disabled={!isSlackConnected}
                      checked={isSendToSlack}
                      value={isSendToSlack}
                      onChange={(checked) => {
                        setIsSendToSlack(checked);
                      }}
                    />
                  </div>
                  {/* {hasLighthouse && !selectedDash[0] && (
                    <div className="flex items-center">
                      <LighthouseImage className="flex items-center gap-6.5 w-8 h-auto" />
                      <Checkbox
                        label="Lighthouse"
                        disabled={!isSlackConnected}
                        checked={isSendToLighthouse}
                        value={isSendToLighthouse}
                        onChange={(checked) => {
                          setIsSendToLighthouse(checked);
                        }}
                      />
                    </div>
                  )} */}
                </div>
              </div>
            )}
            <div className="mb-[32px]">
              <ReportFrequency
                frequency={frequency}
                onSelectFrequency={onSelectFrequency}
                selectedHours={selectedHours}
                onSelectHour={onSelectHour}
                popoverActive={popoverActive}
                togglePopoverActive={togglePopoverActive}
              />
            </div>

            {isSendToEmail && (
              <div className="mb-[30px]">
                <div className="text-[12px] font-medium mb-[8px]">
                  {' '}
                  Email recipients{' '}
                  <span className="font-normal"> (insert a comma in between recipients)</span>
                </div>
                <TextField
                  label=""
                  value={recipients}
                  onChange={setRecipients}
                  autoComplete="off"
                />
              </div>
            )}

            {!isDashboard && (
              <div className="mb-[20px]">
                <Checkbox
                  label="Use expanded design for reports"
                  checked={isNewDesign}
                  value={isNewDesign}
                  onChange={handleDesignSelectionChange}
                />
                <div className="text-[12px] font-medium"></div>
              </div>
            )}

            <hr className="h-px bg-[#c1c1c1] opacity-40 border-0" />
          </div>
        </section>

        {!isEditing && (
          <div className="lg:w-[223px] inline-block">{/* TODO side Navigation */}</div>
        )}

        <section className="report-form mt-[40px] mx-[30px] lg:mx-0 lg:w-6/12 inline-block">
          <Tabs tabs={tabs} selected={selectedTab} onSelect={handleTabChange}></Tabs>
          <div className={`relative ${isTabDisabled && 'min-h-[300px]'}`}>
            {tabs[selectedTab]?.id === 'metrics' && (
              <MetricsSelector value={selectedMetrics} onChange={onSelectMetric} />
            )}
            {isTabDisabled && (
              <div className="absolute z-10 w-full h-full bg-[rgba(255,255,255,0.75)] top-0 left-0 flex flex-col">
                <UpgradePageContent
                  title={tabs[selectedTab]?.content}
                  description={`You don't have access to ${tabs[selectedTab]?.accessibilityLabel}`}
                  showButtons={true}
                />
              </div>
            )}
            {isDashboard && (
              <>
                <div className="my-[1rem] lg:flex lg:justify-start lg:items-center flex flex-col gap-4 sm:flex-row w-full">
                  <div className="relative z-0">
                    <Select
                      label=""
                      value={selectedDateRange.label}
                      options={datePickerOptions.map((f) => f.label)}
                      onChange={(sel) =>
                        setSelectedDateRange(
                          datePickerOptions.find((d) => d.label === sel) || datePickerOptions[0],
                        )
                      }
                    />
                  </div>
                  <div className="min-w-[50%]">
                    <ReactSelect
                      classNames={{
                        control: () => 'autocomplete-control',
                        valueContainer: () => 'autocomplete-value-container',
                        multiValue: () => 'autocomplete-multi-value',
                        singleValue: () => 'autocomplete-single-value',
                      }}
                      options={allowedDashboards.map((d) => ({
                        ...d,
                        label: d.name + (d.isStandardDashboard ? ' (Standard)' : ''),
                        value: d.id,
                      }))}
                      isMulti={true}
                      // closeMenuOnSelect={!!props.closeMenuOnSelect}
                      // onInputChange={setInputValue}
                      hideSelectedOptions={false}
                      onChange={(sel: any) => {
                        onSelectDash(sel);
                      }}
                      value={selectedDash}
                      isLoading={false}
                      // className={`autocomplete ${hasValue ? 'has-value' : ''}`}
                      isClearable={true}
                      noOptionsMessage={() => 'No Reports'}
                    />
                  </div>
                </div>
                <div className="relative z-0">
                  <Select
                    label="How would you like to send the report?"
                    value={format}
                    options={formatOptions}
                    onChange={handleSendAsChange}
                  />
                </div>
              </>
            )}
            <button
              className="bg-[#F6FAFD] text-[#156BD9] my-[12px] px-[24px] py-[8px] rounded-[4px] border-none cursor-pointer disabled:opacity-60 disabled:pointer-events-none"
              onClick={_onTryItNow}
              disabled={!isValid || sending || tabs[selectedTab]?.disabled}
            >
              {sending && <Spinner size="small" />} Send now
            </button>
            <div className="text-[12px] mb-8">
              Click ‘Send now’ to receive your first report immediately.
            </div>
          </div>
          <div className="text-right">
            <button
              className="bg-white hover:bg-slate-200 text-[#1877F2] px-[24px] py-[8px] mr-[8px] rounded-[4px] border cursor-pointer"
              onClick={onCancel}
            >
              Cancel
            </button>
            <button
              className="bg-[#1877F2] hover:bg-[#156BD9] text-white px-[24px] py-[8px] rounded-[4px] border-none cursor-pointer disabled:opacity-60 disabled:pointer-events-none"
              onClick={_onSubmit}
              disabled={!isValid}
            >
              {isEditing ? 'Save' : 'Submit'}
            </button>
          </div>
        </section>
      </div>
    </Card>
  );
};
