import { $activeRoute, $navigationSections } from '$stores/nav-config-stores';
import { useComputedValue, useStoreValue } from '@tw/snipestate';
import { Flex, NavSectionLink, Size } from '@tw/ui-components';
import { useFilterMenu } from '../../../hooks/useFilterMenu';
import { $navOpen } from 'components/Nav/stores';
import { NavSectionRoute } from 'constants/routes/types';
import { useMemo } from 'react';
import { useHistory } from 'react-router';

export const LinksSection = () => {
  const navigationSections = useStoreValue($navigationSections);
  const settingsSections = useFilterMenu(navigationSections, 'settings');

  const mainLinks = useMemo(
    () =>
      settingsSections
        .flatMap((x) => x.routes)
        .map((route, i) => (
          <WrappedLink key={`nav-section-link-${route.label}-${i}`} route={route} />
        )),
    [settingsSections],
  );

  return <Flex direction="column">{mainLinks}</Flex>;
};

const WrappedLink = ({ route }: { route: NavSectionRoute }) => {
  const history = useHistory();
  const isActive = useComputedValue($activeRoute, (r) => r.isActiveRoute(route));

  return (
    <NavSectionLink
      label={route.label}
      isActive={isActive}
      onClick={() => {
        history.push(route.url);
        $navOpen.set(false);
      }}
      style={{
        pl: 'xs',
        // TODO: This should be one of the acceptable parameters
        py: 5 as unknown as Size,
      }}
    />
  );
};
