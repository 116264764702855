import React, { useCallback, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { type RootState } from 'reducers/RootType';

import { Button, Modal, OptionList } from '@shopify/polaris';
import {
  amazonConfigCancelPress,
  amazonConfigSavePress,
  toggleAmazonConfigScreenOpen,
} from 'ducks/amazon';
import { getShopData, shopsAmazonDb } from 'utils/DB';
import { type AppDispatch } from 'index';
import {
  AMAZON_MARKETPLACE_IDS,
  AmazonAccountMarketplaceIds,
  AmazonRegions,
} from '@tw/types/module/services/amazon';

import { Shop } from '@tw/types';
const AmazonConfig = () => {
  // toggleIsOpen: () => dispatch(toggleAmazonConfigScreenOpen()),
  // save: (settings) => dispatch(knoConfigSavePress(settings)),
  const dispatch: AppDispatch = useDispatch();
  const amazonConnectConfigIsOpen = useSelector(
    (state: RootState) => state.amazonConnectConfigIsOpen,
  );
  const isFreeShop = useSelector((state: RootState) => state.isFreeShop);

  var [isLoading, setIsLoading] = useState(true);
  var [isOpen, setIsOpen] = useState(false);
  var [sellerAccountId, setSellerAccountId] = useState('');
  var [isInitial, setIsInitial] = useState(true);
  var [marketplaceIds, setMarketplaceIds] = useState<string[]>([]);
  var [selectedMarketplaceIds, setSelectedMarketplaceIds] = useState<string[]>([]);
  var [isLoadingBtn, setIsLoadingBtn] = useState(false);
  var [region, setRegion] = useState<AmazonRegions>('na');

  const getMarketplaceIds = useCallback(async () => {
    try {
      const { open, region: _region } = amazonConnectConfigIsOpen as any;
      const amazonAccountsInConfigProcess =
        ((await getShopData()) as Shop)?.amazon?.accountsInConfigProcess || {};
      const amazonAccountsInConfigProcessKeys = Object.entries(amazonAccountsInConfigProcess)
        .filter(([key, val]) => !_region || !val.region || val.region === _region)
        .map(([key, val]) => ({ sellerAccountId: key, ...val }));
      if (
        open &&
        amazonAccountsInConfigProcessKeys?.length &&
        (!_region || amazonAccountsInConfigProcess)
      ) {
        const { sellerAccountId } = amazonAccountsInConfigProcessKeys[0];
        setSellerAccountId(sellerAccountId);
        let { initial = true, region = 'na' } = amazonAccountsInConfigProcess[sellerAccountId];
        setIsInitial(initial);
        setRegion(region);
        const amazonShopRef = shopsAmazonDb(sellerAccountId);
        const amazonShop: AmazonAccountMarketplaceIds = (
          await amazonShopRef.get()
        ).data() as AmazonAccountMarketplaceIds;
        const _marketplaceIds: string[] = Object.values(amazonShop?.marketplace_ids || {});
        setMarketplaceIds(_marketplaceIds || []);
        const shop: Shop = (await getShopData()) as Shop;
        const _selectedMarketplaceIds: string[] =
          shop?.amazon?.sellerAccounts?.[sellerAccountId]?.marketplaceIds || [];
        setSelectedMarketplaceIds(_selectedMarketplaceIds || []);
        setIsLoading(false);
        setIsLoadingBtn(false);
      }
      setIsOpen(open);
    } catch (error) {
      console.log(error);
    }
  }, [amazonConnectConfigIsOpen]);

  useEffect(() => {
    if (amazonConnectConfigIsOpen.open) getMarketplaceIds();
    setIsOpen(amazonConnectConfigIsOpen.open);
  }, [getMarketplaceIds, amazonConnectConfigIsOpen]);

  return (
    <>
      {' '}
      {isInitial && (
        <Modal
          title={'Amazon configuration'}
          open={isOpen}
          onClose={() => {
            setIsLoadingBtn(false);
            dispatch(toggleAmazonConfigScreenOpen(region));
          }}
          primaryAction={{
            content: isInitial ? 'Continue to Amazon Advertising API setup' : 'Save',
            onAction: () => {
              setIsLoadingBtn(true);
              dispatch(
                amazonConfigSavePress({
                  sellerAccountId,
                  marketplaceIds: selectedMarketplaceIds,
                  isInitial,
                  continueToAds: isInitial,
                  region,
                }),
              );
            },
            disabled: !selectedMarketplaceIds?.length,
            loading: isLoadingBtn,
          }}
          secondaryActions={[
            {
              content: 'Cancel',
              onAction: () => {
                setIsLoadingBtn(false);
                dispatch(amazonConfigCancelPress(sellerAccountId));
              },
            },
            // isInitial? {
            //   content: 'Continue to Amazon Advertising API setup',
            //   onAction: () => {
            //     setIsLoadingBtn(true)
            //     dispatch(
            //       amazonConfigSavePress({ sellerAccountId, marketplaceIds: selectedMarketplaceIds, isInitial: false, continueToAds: true })
            //     );
            //   },
            //   disabled: !selectedMarketplaceIds?.length || isInitial,
            //   loading: isLoadingBtn,
            // }:{}
          ]}
          loading={isLoading}
        >
          <Modal.Section>
            <OptionList
              /*
              // onChange={(selected) => {
              //   selected.map((v) => {
              //     if (selectedMarketplaceIds[v]) {
              //       setSelectedMarketplaceIds(selectedMarketplaceIds.filter((val) => val != v));
              //     } else {
              //       setSelectedMarketplaceIds(selectedMarketplaceIds.concat([v]));
              //     }
              //   });
              // }}
              */
              onChange={setSelectedMarketplaceIds}
              options={
                marketplaceIds.length
                  ? marketplaceIds
                      .filter((val: any) => AMAZON_MARKETPLACE_IDS[val?.id])
                      .sort((a: any, b: any) =>
                        AMAZON_MARKETPLACE_IDS[a.id] > AMAZON_MARKETPLACE_IDS[b.id] ? 1 : -1,
                      )
                      .map((val: any) => {
                        return {
                          value: val?.id,
                          label: (
                            <div style={{ marginTop: 'auto', marginBottom: 'auto' }}>
                              <div>
                                <p>{AMAZON_MARKETPLACE_IDS[val?.id]}</p>
                                <p>{` ${val?.marketplace?.name} `}</p>
                                <p className="text-xl text-secondary-text">{` ${val?.marketplace?.domainName} (${val?.id}) `}</p>
                              </div>
                            </div>
                          ),
                          // media: need to get icon for regions
                        };
                      })
                  : []
              }
              selected={selectedMarketplaceIds}
              allowMultiple
            />
            {!isFreeShop && (
              <div>
                <br />
                Need help?{' '}
                <Button plain monochrome onClick={() => window.Intercom('show')}>
                  Click here and chat with us!
                </Button>
              </div>
            )}
          </Modal.Section>
        </Modal>
      )}
      {!isInitial && (
        <Modal
          title={'Amazon configuration'}
          open={isOpen}
          onClose={() => {
            setIsLoadingBtn(false);
            dispatch(toggleAmazonConfigScreenOpen(region));
          }}
          primaryAction={{
            content: 'Save',
            onAction: () => {
              setIsLoadingBtn(true);
              dispatch(
                amazonConfigSavePress({
                  sellerAccountId,
                  marketplaceIds: selectedMarketplaceIds,
                  isInitial,
                  continueToAds: isInitial,
                  region,
                }),
              );
            },
            disabled: !selectedMarketplaceIds?.length,
            loading: isLoadingBtn,
          }}
          secondaryActions={[
            {
              content: 'Cancel',
              onAction: () => {
                setIsLoadingBtn(false);
                dispatch(amazonConfigCancelPress(sellerAccountId));
              },
            },
            {
              content: 'Continue to Amazon Advertising API setup',
              onAction: () => {
                setIsLoadingBtn(true);
                dispatch(
                  amazonConfigSavePress({
                    sellerAccountId,
                    marketplaceIds: selectedMarketplaceIds,
                    isInitial: false,
                    continueToAds: true,
                    region,
                  }),
                );
              },
              disabled: !selectedMarketplaceIds?.length || isInitial,
              loading: isLoadingBtn,
            },
          ]}
          loading={isLoading}
        >
          <Modal.Section>
            <OptionList
              /*
                // onChange={(selected) => {
                //   selected.map((v) => {
                //     if (selectedMarketplaceIds[v]) {
                //       setSelectedMarketplaceIds(selectedMarketplaceIds.filter((val) => val != v));
                //     } else {
                //       setSelectedMarketplaceIds(selectedMarketplaceIds.concat([v]));
                //     }
                //   });
                // }}
                */
              onChange={setSelectedMarketplaceIds}
              options={
                marketplaceIds.length
                  ? marketplaceIds
                      .filter((val: any) => AMAZON_MARKETPLACE_IDS[val?.id])
                      .sort((a: any, b: any) =>
                        AMAZON_MARKETPLACE_IDS[a.id] > AMAZON_MARKETPLACE_IDS[b.id] ? 1 : -1,
                      )
                      .map((val: any) => {
                        return {
                          value: val?.id,
                          label: (
                            <div style={{ marginTop: 'auto', marginBottom: 'auto' }}>
                              <div>
                                <p>{AMAZON_MARKETPLACE_IDS[val?.id]}</p>
                                <p>{` ${val?.marketplace?.name} `}</p>
                                <p className="text-xl text-secondary-text">{` ${val?.marketplace?.domainName} (${val?.id}) `}</p>
                              </div>
                            </div>
                          ),
                          // media: need to get icon for regions
                        };
                      })
                  : []
              }
              selected={selectedMarketplaceIds}
              allowMultiple
            />
            {!isFreeShop && (
              <div>
                <br />
                Need help?{' '}
                <Button plain monochrome onClick={() => window.Intercom('show')}>
                  Click here and chat with us!
                </Button>
              </div>
            )}
          </Modal.Section>
        </Modal>
      )}
    </>
  );
};

export default AmazonConfig;
