import _db, { firestoreRef } from 'utils/DB';
import {
  AssistantMessage,
  Conversation,
  HistoryItem,
  WillyBaseMainElement,
  WillyDataSequence,
} from '../types/willyTypes';
import axiosInstance from 'utils/axiosInstance';
import { getConversationDialect } from './willyUtils';
import { Dialect } from '@tw/types';
import { DEFAULT_DIALECT } from '../constants';
import { DatePickerTimePeriods } from 'components/useDatePickerSelectedOptions';

type CreateNewSequenceProps = {
  baseMainElement: WillyBaseMainElement;
  shopId: string;
  userId: string;
  messages: HistoryItem[];
  startingDashboard?: string;
  startingDashboardDate: DatePickerTimePeriods | null;
  conversationId?: string;
  dialect?: Dialect;
};

export async function createNewSequence(props: CreateNewSequenceProps) {
  const {
    baseMainElement,
    shopId,
    userId,
    conversationId,
    dialect,
    messages,
    startingDashboard,
    startingDashboardDate,
  } = props;

  const {
    id,
    name,
    description,
    isGlobal,
    emoji,
    image,
    isBeta,
    isHide,
    roles,
    category,
    providers,
    providersBlockingCombination,
    createdAt,
    updatedAt,
  } = baseMainElement;

  let conversationData: Conversation | null = null;
  let conversationDialect: Dialect | null = null;
  let userMessages: HistoryItem[] | undefined = [];
  let assistantMessages: AssistantMessage[] | undefined = [];

  if (conversationId) {
    const conversation = await _db().collection('conversations').doc(conversationId).get();
    if (!conversation.exists) {
      return {
        error: 'Conversation not found',
      };
    }
    conversationData = conversation.data() as Conversation;

    const { data } = await axiosInstance.post<
      any,
      {
        data: {
          userMessages?: HistoryItem[];
          assistantMessages?: AssistantMessage[];
          error?: string;
        };
      }
    >('/v2/willy/create-messages-from-conversation', {
      shopId,
      conversationId,
    });
    const { error } = data;
    ({ userMessages, assistantMessages } = data);
    if (error || !userMessages || !assistantMessages) {
      return {
        error: 'Could not create messages from conversation',
      };
    }

    conversationDialect = getConversationDialect(conversationData);
  } else {
    userMessages = messages;
  }

  let newSequence: WillyDataSequence = {
    id: id,
    name: name,
    description: description ?? '',
    updatedAt,
    isGlobal: isGlobal,
    type: 'sequence',
    canEdit: false,
    history: userMessages,
    assistantMessages,
    createdAt,
    emoji: emoji,
    user: userId,
    image: image,
    fromConversationId: conversationId || 'no_conversation',
    v: conversationData?.v ?? 6,
    dialect: conversationDialect ?? dialect ?? DEFAULT_DIALECT,
    startingDashboard: startingDashboard || '',
    startingDashboardDate: startingDashboardDate || null,
  };

  if (isGlobal) {
    newSequence = {
      ...newSequence,
      providers,
      providersBlockingCombination,
      category,
      roles,
      isBeta,
      isHide,
    };
  }

  try {
    const ref = isGlobal
      ? firestoreRef().collection('global_data_sequences')
      : _db().collection('data_sequences');

    await ref.doc(id).set(newSequence, { merge: true });

    return {
      success: true,
      message: 'Sequence created successfully',
      conversationData,
      sequence: newSequence,
    };
  } catch (e) {
    console.error(e);
    return {
      error: 'Could not save sequence',
    };
  }
}

export async function updateSequence() {}
