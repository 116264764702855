import { useEffect, useRef } from 'react';
import { mainDatePickerOnSelectionChange, mainDatePickerSelectedOptionChange } from 'ducks/actions';
import moment from '@tw/moment-cached/module/timezone';
import { useHistory, useLocation } from 'react-router';
import { useSelector } from 'react-redux';
import { type RootState } from 'reducers/RootType';
import { useAppDispatch } from 'index';
import { pushFullDateToQS } from '$stores/willy/$dateRange';
import { DatePickerTimePeriods } from 'components/useDatePickerSelectedOptions';
import { PreviousPeriodIds } from '@tw/types/module/datePicker/datePicker';

export const useLastSevenDays = () => {
  const isChanged = useRef(false);
  const mainDatePickerSelectionRange = useSelector(
    (state: RootState) => state.mainDatePickerSelectionRange,
  );
  const dispatch = useAppDispatch();
  const location = useLocation();
  const history = useHistory();

  useEffect(() => {
    if (!mainDatePickerSelectionRange || isChanged.current) {
      return;
    }

    const { start, end } = mainDatePickerSelectionRange;
    const now = moment();
    const isToday = now.diff(start, 'days') === 0 && start.isSame(end, 'day');
    if (!isToday) {
      return;
    }

    const newStart = moment().subtract(7, 'days').startOf('day');
    const newEnd = moment().subtract(1, 'day').endOf('day');
    const newId = DatePickerTimePeriods.LAST_7_DAYS;

    const newPrevId: PreviousPeriodIds = 'previousPeriod';
    const newPrevStart = moment().subtract(14, 'days').startOf('day');
    const newPrevEnd = moment().subtract(8, 'days').endOf('day');

    dispatch(mainDatePickerSelectedOptionChange(newId));
    dispatch(mainDatePickerOnSelectionChange({ start: newStart, end: newEnd, newId }));
    pushFullDateToQS(history, newStart, newEnd, newPrevStart, newPrevEnd, newId, newPrevId);

    isChanged.current = true;
  }, [dispatch, mainDatePickerSelectionRange, location.search, history]);
};
