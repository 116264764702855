import shopifyIcon from '../icons/shopify.svg';
import bigCommerceIcon from '../icons/bigCommerce.svg';
import wooCommerceIcon from '../icons/wooCommerce.svg';
import magentoIcon from '../icons/magento.svg';
import stripeIcon from '../icons/stripe.svg';
import { SalesPlatform } from '@tw/types/module/services';

type SalesPlatformItem<T extends SalesPlatform> = {
  id: T;
  title: string;
  icon: string;
  validateShopURL: string;
  isBeta?: boolean;
  shouldNotUseRealUrl?: boolean;
  adminPath?: string;
  domain: string | null;
  backgroundColor: string;
};

type SalesPlatformDictionary = {
  [platform in SalesPlatform]: SalesPlatformItem<platform>;
};

const SALES_PLATFORMS: SalesPlatformDictionary = {
  shopify: {
    id: 'shopify',
    title: 'Shopify',
    validateShopURL: '/v2/account-manager/shops/validateAndGetShopifyShop',
    icon: shopifyIcon,
    adminPath: 'admin',
    shouldNotUseRealUrl: true,
    domain: 'myshopify.com',
    backgroundColor: '#95bf47',
  },

  woocommerce: {
    id: 'woocommerce',
    title: 'WooCommerce',
    validateShopURL: '/v2/account-manager/shops/validateAndGetWooShop',
    isBeta: true,
    adminPath: 'wp-login.php',
    icon: wooCommerceIcon,
    domain: null,
    backgroundColor: '#EFD3E2',
  },

  magento: {
    id: 'magento',
    title: 'Magento',
    validateShopURL: '/v2/account-manager/shops/validateAndGetMagentoShop',
    isBeta: true,
    icon: magentoIcon,
    domain: null,
    backgroundColor: '#EF43E2',
  },

  bigcommerce: {
    id: 'bigcommerce',
    title: 'BigCommerce',
    validateShopURL: '/v2/account-manager/shops/validateAndGetBigShop',
    icon: bigCommerceIcon,
    domain: 'mybigcommerce.com',
    backgroundColor: '#D8D2EB',
  },

  stripe: {
    id: 'stripe',
    title: 'Stripe',
    validateShopURL: '/v2/account-manager/shops/validateAndGetStripeShop',
    icon: stripeIcon,
    isBeta: true,
    domain: null,
    backgroundColor: '#F5A623',
  },
};

export default SALES_PLATFORMS;
