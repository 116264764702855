import { PreviousPeriodIds } from '@tw/types/module/datePicker/datePicker';
import moment from 'moment-timezone';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'reducers/RootType';
import { DatePickerPreviousPeriodOption } from 'types/general';
import { getPrevDates } from 'utils/getPreviousDate';
import { $store, useWritableStore } from '@tw/snipestate';
import { Moment } from '@tw/moment-cached';

export const $compareOptions = $store<DatePickerPreviousPeriodOption[]>([]);

export const buildDatePickerCompareOptions = (
  currentDateRange: { start: Moment; end: Moment },
  prevDateRange: { start: Moment; end: Moment; id: PreviousPeriodIds },
): DatePickerPreviousPeriodOption[] => [
  {
    value: {
      start: getPrevDates(currentDateRange).start,
      end: getPrevDates(currentDateRange).end,
      id: 'none',
      label: 'None',
    },
    label: (
      <div className="flex gap-4 items-center">
        <span>None</span>
      </div>
    ),
    active: prevDateRange.id === 'none',
  },
  {
    value: {
      start: getPrevDates(currentDateRange).start,
      end: getPrevDates(currentDateRange).end,
      id: 'previousPeriod',
      label: 'Previous period',
    },
    label: (
      <div className="flex gap-4 items-center">
        <span>Previous period</span>
        <span className="text-secondary-text text-xl">
          {getPrevDates(currentDateRange).start.format('MMM D')} -{' '}
          {getPrevDates(currentDateRange).end.format('MMM D')}
        </span>
      </div>
    ),
    active: prevDateRange.id === 'default' || prevDateRange.id === 'previousPeriod',
  },
  {
    value: {
      start: getPrevDates(currentDateRange, 'week').start,
      end: getPrevDates(currentDateRange, 'week').end,
      id: 'previousWeek',
      label: 'Previous week',
    },
    label: (
      <div className="flex gap-4 items-center">
        <span>Previous week</span>
        <span className="text-secondary-text text-xl">
          {getPrevDates(currentDateRange, 'week').start.format('MMM D')} -{' '}
          {getPrevDates(currentDateRange, 'week').end.format('MMM D')}
        </span>
      </div>
    ),
    active: prevDateRange.id === 'previousWeek',
  },
  {
    value: {
      start: getPrevDates(currentDateRange, 'month').start,
      end: getPrevDates(currentDateRange, 'month').end,
      id: 'previousMonth',
      label: 'Previous month',
    },
    label: (
      <div className="flex gap-4 items-center">
        <span>Previous month</span>
        <span className="text-secondary-text text-xl">
          {getPrevDates(currentDateRange, 'month').start.format('MMM D')} -{' '}
          {getPrevDates(currentDateRange, 'month').end.format('MMM D')}
        </span>
      </div>
    ),
    active: prevDateRange.id === 'previousMonth',
  },
  {
    value: {
      start: getPrevDates(currentDateRange, 'quarter').start,
      end: getPrevDates(currentDateRange, 'quarter').end,
      id: 'previousQuarter',
      label: 'Previous quarter',
    },
    label: (
      <div className="flex gap-4 items-center">
        <span>Previous quarter</span>
        <span className="text-secondary-text text-xl">
          {getPrevDates(currentDateRange, 'quarter').start.format('MMM D')} -{' '}
          {getPrevDates(currentDateRange, 'quarter').end.format('MMM D')}
        </span>
      </div>
    ),
    active: prevDateRange.id === 'previousQuarter',
  },
  {
    value: {
      start: getPrevDates(currentDateRange, 'year').start,
      end: getPrevDates(currentDateRange, 'year').end,
      id: 'previousYear',
      label: 'Previous year',
    },
    label: (
      <div className="flex gap-4 items-center">
        <span>Previous year</span>
        <span className="text-secondary-text text-xl">
          {getPrevDates(currentDateRange, 'year').start.format('MMM D, YYYY')} -{' '}
          {getPrevDates(currentDateRange, 'year').end.format('MMM D, YYYY')}
        </span>
      </div>
    ),
    active: prevDateRange.id === 'previousYear',
  },
  {
    value: {
      start: getPrevDates(currentDateRange).start,
      end: getPrevDates(currentDateRange).end,
      id: 'custom',
      label: 'Custom',
    },
    label: (
      <div className="flex gap-4 items-center">
        <span>Custom</span>
        <span className="text-secondary-text text-xl">
          {prevDateRange?.start.format('MMM D') + ' - ' + prevDateRange?.end.format('MMM D')}
        </span>
      </div>
    ),
    active: prevDateRange.id === 'custom',
  },
];
export const useDatePickerCompareOptions = () => {
  const mainDatePickerSelectedCompareOption = useSelector(
    (state: RootState) => state.mainDatePickerSelectedCompareOption,
  );
  const mainDatePickerSelectionRange = useSelector(
    (state: RootState) => state.mainDatePickerSelectionRange,
  );
  const datesToCompare = useSelector((state: RootState) => state.datesToCompare);
  const [compareOptions, setCompareOptions] = useWritableStore($compareOptions);

  useEffect(() => {
    if (!mainDatePickerSelectionRange) {
      setCompareOptions([]);
      return;
    }
    const { start, end } = mainDatePickerSelectionRange || {};
    const momentObj = { start, end };
    const { start: prevStart, end: prevEnd, id } = datesToCompare;

    const options: DatePickerPreviousPeriodOption[] = buildDatePickerCompareOptions(momentObj, {
      start: moment(prevStart),
      end: moment(prevEnd),
      id,
    });

    setCompareOptions(options);
  }, [
    mainDatePickerSelectedCompareOption,
    datesToCompare,
    mainDatePickerSelectionRange,
    setCompareOptions,
  ]);

  return compareOptions;
};
