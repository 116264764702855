import { Icon, TextField } from '@shopify/polaris';
import { cogsPercentOnChange } from '../../ducks/products';
import { useAppDispatch } from 'index';
import { useAppSelector } from 'reducers/RootType';
import { ClockMinor } from '@shopify/polaris-icons';
import { useEffect, useState } from 'react';
import CogsHistory from 'pages/cogs/CogsHistory';
import { getCurrencySymbol } from '@tw/constants';
import { TWInfoTooltip } from 'components/library/TWTooltip/TWInfoTooltip';

const ProductsCogsPercent = () => {
  const dispatch = useAppDispatch();

  const customCogsPercent = useAppSelector((state) => state.customCogsPercent);
  const currency = useAppSelector((state) => state.currency);
  const [isCostHistoryOpen, setIsCostHistoryOpen] = useState(false);
  const [selectedHistoryRow, setSelectedHistoryRow] = useState();
  const [percent, setPercent] = useState(customCogsPercent?.cogs_percent?.[0]?.value ?? '');

  useEffect(() => {
    setPercent(customCogsPercent?.cogs_percent?.[0]?.value ?? '');
  }, [customCogsPercent]);

  const showCostHistory = (fee) => {
    setIsCostHistoryOpen(true);
    setSelectedHistoryRow(fee);
  };

  const updateHistory = () => {
    let history = customCogsPercent?.cogs_percent ?? [];
    if (+percent !== history[0]?.value) {
      history.unshift({
        value: +percent,
        date: new Date(),
      });
      dispatch(cogsPercentOnChange(percent, history));
    }
  };

  return (
    <>
      <TextField
        placeholder="Set COGS as a % of Gross Sales"
        type="number"
        label="Fixed COGS %"
        value={percent}
        onChange={setPercent}
        onBlur={updateHistory}
        prefix={percent ? '%' : ' '}
        inputMode="numeric"
        autoComplete="off"
        suffix={
          <div
            className="cost-history"
            color="grey"
            title="Show Cost History"
            onClick={() => showCostHistory(customCogsPercent)}
          >
            <Icon source={ClockMinor} />
          </div>
        }
      />
      <CogsHistory
        selectedRow={selectedHistoryRow}
        getCurrencySymbol={getCurrencySymbol}
        defaultCurrency={currency}
        isOpen={isCostHistoryOpen}
        onClose={() => setIsCostHistoryOpen(false)}
        service={'shopify'}
        type={'tw'}
        costType={'cogs_percent'}
        title={'Custom COGS Percent History'}
      />
    </>
  );
};

export default ProductsCogsPercent;
