import { useEffect, useState } from 'react';
import { InAppContextBanner } from 'components/InAppContextBanner';
import CircleLockIcon from 'components/library/LockedFeatures/CircleLockIcon';
import { Button, Container, Flex, Text, Title } from '@tw/ui-components';
import { IN_APP_CONTEXT_BANNERS_DATA } from 'utils/InAppContextBanners';
import { FeatureFlag } from '@tw/feature-flag-system/module/types';
import { upgradePlanClicked } from 'ducks/subscription';
import { useAppDispatch } from 'index';
import { useHistory } from 'react-router';
import { isInIframe } from 'config';
import { useAppSelector } from 'reducers/RootType';
import { useFeatureFlagSelector } from '../featureFlagComputer';
import { useMutableQueryParams } from 'hooks/useMutableQueryParams';
import { toast } from 'react-toastify';
export interface UpgradePageFallBackProps {
  InAppContextBannerEnabled?: boolean;
  InAppContextBannerPage?: string;
  featureFlag: FeatureFlag;
  title?: string;
  description?: string;
  classWrapper?: string;
  showButtons?: boolean;
}

export const UpgradePageFallBack: React.FC<UpgradePageFallBackProps> = ({
  InAppContextBannerEnabled = true,
  InAppContextBannerPage = 'creativeCockpit',
  title,
  description,
  classWrapper = '',
  featureFlag,
  showButtons = true,
}) => {
  const { title: bannerTitle, description: bannerDescription } =
    IN_APP_CONTEXT_BANNERS_DATA[InAppContextBannerPage];

  const userLimitation = useFeatureFlagSelector(featureFlag, (c) => !!c.sources?.includes('user'));

  description = (() => {
    if (!userLimitation) return description;
    return `You don't have access to this feature. Speak to your admin.`;
  })();

  return (
    <div className={`p-5 flex flex-col h-[calc(100%-60px)] ${classWrapper}`}>
      {InAppContextBannerEnabled && !isInIframe && (
        <InAppContextBanner
          allowDismiss={false}
          page={InAppContextBannerPage}
          featureFlag={featureFlag}
        />
      )}
      <div className={'flex justify-center items-center flex-1'}>
        <UpgradePageContent
          title={title}
          bannerTitle={bannerTitle}
          description={description}
          bannerDescription={bannerDescription}
          showButtons={showButtons}
          userLimitation={userLimitation}
        />
      </div>
    </div>
  );
};

//
// UTILS
//
const ADMIN_FORCE_ALLOW_SELF_UPGRADE_PARAM = 'gt1-self-upgrade';

export const UpgradeButtons = ({ showButtons }) => {
  const shop = useAppSelector((state) => state.shop);
  const dispatch = useAppDispatch();
  const history = useHistory();
  const [params] = useMutableQueryParams();
  const [isLoading, setIsLoading] = useState(false);
  const [canSeeUpgradeButton, setCanSeeUpgradeButton] = useState(true);
  const [revenue, setRevenue] = useState<number | null>(null);

  useEffect(() => {
    const { revenue } = shop?.annualRevenue || {};
    if (!revenue) return;
    setRevenue(revenue);
    const adminForced = params[ADMIN_FORCE_ALLOW_SELF_UPGRADE_PARAM] === 'true';
    setCanSeeUpgradeButton(adminForced || revenue < 500000);
  }, [params, shop]);

  const bookDemo = async () => {
    setIsLoading(true);
    if (!revenue) {
      toast.info('We are still calculating your revenue. Please try again in a few minutes.', {
        position: 'top-center',
        autoClose: false,
      });
      setIsLoading(false);
      return;
    }
    await dispatch(upgradePlanClicked(history, true));
    setIsLoading(false);
  };

  const upgradeClicked = async () => {
    setIsLoading(true);
    if (!revenue) {
      toast.info('We are still calculating your revenue. Please try again in a few minutes.', {
        position: 'top-center',
        autoClose: false,
      });
      setIsLoading(false);
      return;
    }
    await dispatch(upgradePlanClicked(history));
    setIsLoading(false);
  };

  if (!showButtons) return null;

  return (
    <Flex mt="xl" gap="xs" justify="center">
      {canSeeUpgradeButton ? (
        <Button loading={isLoading} onClick={upgradeClicked} variant="primary">
          Upgrade
        </Button>
      ) : (
        <Button loading={isLoading} onClick={bookDemo} variant="white">
          Book a demo
        </Button>
      )}
    </Flex>
  );
};

export const UpgradePageContent = ({
  title,
  bannerTitle,
  description,
  bannerDescription,
  showButtons,
  userLimitation,
}: {
  title?: string;
  bannerTitle?: string;
  description?: string;
  bannerDescription?: string;
  showButtons: boolean;
  userLimitation?: boolean;
}) => {
  return (
    <Container fluid>
      <Flex justify="center" mb="md">
        <CircleLockIcon borderColor="#C3DDFF" size={48} />
      </Flex>
      <Title order={3} mb="sm" ta="center" fw={600} fz="xl">
        {title ?? bannerTitle}
      </Title>
      <Text ta="center">{description ?? bannerDescription}</Text>
      <UpgradeButtons showButtons={showButtons && !userLimitation} />
    </Container>
  );
};
