import { CustomerJourney } from 'components/attribution/components';
import { getJourney } from 'components/attribution/utils';
import { AttributionPageContext } from 'constants/attribution';
import { loadOrder } from 'ducks/orders';
import { getOrdersByUtmId } from 'ducks/shopify';
import moment from '@tw/moment-cached/module/timezone';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { type RootState } from 'reducers/RootType';
import { AttributionData } from 'types/attribution';
import { formatNumber } from 'utils/formatNumber';
import { Icon, Link, Text } from '@shopify/polaris';
import { ExternalSmallMinor } from '@shopify/polaris-icons';
import { mapEntityToBreakdown } from 'utils/attributions';
import { gradualReleaseFeatures } from 'ducks/shop';
import TWTable from 'components/library/TWTable/TWTable';
import { useFeatureFlag } from 'feature-flag-system';
import { FeatureFlag } from '@tw/feature-flag-system/module/types';
import {
  CustomerProfileModal,
  CustomerProfileModalProps,
} from 'components/Insights/CustomerProfile/Activities/CustomerProfileModal';
import { LoadingOverlay, Modal } from '@tw/ui-components';
import { services } from '@tw/types/module/services';
import { useStoreValue } from '@tw/snipestate';
import { $shopSummaryWillyWay } from '../../$stores/$shop';

type AttributionOrdersProps = {
  item: AttributionData;
  onClose: () => void;
  metricKey?: string;
};

const AttributionOrders: React.FC<AttributionOrdersProps> = ({ onClose, item, metricKey = '' }) => {
  const [orders, setOrders] = useState<any[]>([]);
  const [order, setOrder] = useState<any>(null);
  const [loading, setLoading] = useState(true);
  const [loadingPixel, setLoadingPixel] = useState(false);
  const [journeyOpen, setJourneyOpen] = useState(false);
  const [journey, setJourney] = useState([]);
  const [orderId, setOrderId] = useState(null);
  const [customerProfileProps, setCustomerProfileProps] = useState<CustomerProfileModalProps>();
  const { metaPurchases = 0, metaConversionValue = 0 } = item || {};
  const { shouldNotBeSeen: notSeeOrderIdAndCustomerName } = useFeatureFlag(
    FeatureFlag.PIXEL_PURCHASE_POPUP_FF,
  );
  const { new_activities_timeline: newActivitiesTimeline } = useSelector(gradualReleaseFeatures);
  const currencyConversionRate = useSelector((state: RootState) => state.currencyConversionRate);

  const useNexus = true;
  const isClickhouse = useStoreValue($shopSummaryWillyWay);

  let { sourceId, includeOneDayView } = useContext(AttributionPageContext);

  if (!sourceId && item.serviceId) {
    sourceId = item.serviceId;
  }

  const mainDatePickerSelectionRange = useSelector(
    (state: RootState) => state.mainDatePickerSelectionRange,
  );
  const currency = useSelector((state: RootState) => state.activeCurrency);
  const sensoryIntegrations = useSelector((state: RootState) => state.sensory.sensoryIntegrations);
  const { id: integrationId } =
    sensoryIntegrations.filter((integration) => integration.provider_id === 'recharge')?.[0] ?? '';
  const { dateModel, attributionModel, attributionWindow, useNewModels, attributionSubscription } =
    useSelector((state: RootState) => state.attribution);

  const type = mapEntityToBreakdown[item.entity!];
  const source = item.entity === 'channel' ? item.id : sourceId;
  const oneTimeFilter =
    attributionSubscription.includes('oneTime') || !attributionSubscription.length;

  const _onClose = () => {
    onClose();
  };

  const formatName = useCallback((order) => {
    const { first_name, last_name } = order;
    if (!first_name && !last_name) return '';
    return `${first_name ? first_name : ''} ${last_name ? last_name : ''}`;
  }, []);

  const {
    id,
    unmatchedIds,
    unmatchedAdsets,
    unmatchedCampaigns,
    esKey,
    name,
    campaignId,
    adsetId,
  } = item;
  useEffect(() => {
    const func = async () => {
      setLoading(true);
      if (!mainDatePickerSelectionRange) return;
      const { start, end } = mainDatePickerSelectionRange;
      const stat = metricKey.includes('pixelNc') ? 'newCustomerOrder' : 'order';
      const results = await getOrdersByUtmId(
        dateModel,
        attributionModel,
        source,
        type,
        id,
        unmatchedIds,
        start.format(),
        end.format(),
        [],
        name,
        '',
        attributionWindow,
        useNewModels,
        attributionSubscription,
        integrationId,
        [...(campaignId ? [campaignId] : []), ...(unmatchedCampaigns || [])],
        [...(adsetId ? [adsetId] : []), ...(unmatchedAdsets || [])],
        esKey || '',
        stat,
        useNexus,
        isClickhouse,
      );
      setOrders(results);
      setLoading(false);
    };
    func();
  }, [
    attributionModel,
    dateModel,
    id,
    mainDatePickerSelectionRange,
    sourceId,
    type,
    source,
    attributionWindow,
    name,
    useNewModels,
    attributionSubscription,
    integrationId,
    unmatchedIds,
    campaignId,
    adsetId,
    esKey,
    metricKey,
    useNexus,
    unmatchedCampaigns,
    unmatchedAdsets,
    isClickhouse,
  ]);

  useEffect(() => {
    async function fetchData() {
      if (orderId) {
        setLoadingPixel(true);
        const order = orders.find((x) => x.orderId === orderId);
        const orderPromise = loadOrder(orderId);
        const journeyPromise = getJourney(order, useNewModels);
        const [fullOrder, journey] = await Promise.all([orderPromise, journeyPromise]);
        setJourney(journey);
        setOrder(fullOrder);
        setCustomerProfileProps((prev = {}) => ({
          ...prev,
          customerCreatedDate: fullOrder?.customer?.created_at,
        }));
        setLoadingPixel(false);
      } else {
        setJourney([]);
      }
    }
    fetchData();
  }, [orderId, orders, useNewModels]);

  const openCustomerJourney = useCallback(
    (customer, order) => {
      if (orderId === order.orderId) {
        setCustomerProfileProps(undefined);
        setJourneyOpen(false);
        setOrderId(null);
      } else {
        setCustomerProfileProps({
          shopifyCustomerId: order.customerId.toString(),
          customerName: formatName(order),
          startDate: order.eventDate,
          customerCreatedDate: undefined,
        });
        setOrderId(order.orderId);
        setJourneyOpen(true);
      }
    },
    [formatName, orderId],
  );

  let ordersIncludingView = orders;
  if (source === 'facebook-ads' && includeOneDayView) {
    const { oneDayViewConversionValue, oneDayViewPurchasesValue } = item;
    if (oneDayViewConversionValue !== undefined && oneDayViewPurchasesValue !== undefined) {
      ordersIncludingView = [
        ...orders,
        {
          total_price: oneDayViewConversionValue,
          order_name: 'FB View Data',
          first_name: `${oneDayViewPurchasesValue}`,
          last_name: 'Orders',
          customer_id: 'fake',
        },
      ];
    }
  }
  if (metaPurchases && oneTimeFilter) {
    ordersIncludingView = [
      ...ordersIncludingView,
      {
        order_name: `${services[sourceId]?.name || source || ''} Shop Purchases`,
        total_price: metaConversionValue,
        first_name: `${metaPurchases}`,
        last_name: 'Orders',
        customer_id: 'fake',
      },
    ];
  }
  const totalOrdersValue = useMemo(() => {
    return ordersIncludingView.reduce((acc, o) => acc + (Number(o.total_price) || 0), 0);
  }, [ordersIncludingView]);

  return (
    <>
      <Modal
        lockScroll={false}
        opened={true}
        onClose={_onClose}
        size="xl"
        title={
          <>
            <p>{item.name}</p>
            <p style={{ fontSize: '10px', color: 'var(--p-text-subdued)' }}>
              {!loading &&
                `(${
                  orders.length +
                  metaPurchases +
                  (includeOneDayView ? item?.oneDayViewPurchasesValue || 0 : 0)
                } orders)`}
            </p>
          </>
        }
      >
        <LoadingOverlay
          visible={loading}
          zIndex={10000}
          overlayProps={{ blur: '2px', radius: 'sm', bg: 'named.0' }}
        />
        {!loading && (
          <div>
            <TWTable
              id="attribution-orders-table"
              height="500px"
              stickyHeader
              data={ordersIncludingView}
              columns={[
                {
                  key: 'order',
                  dataType: 'text',
                  name: 'Order',
                  sortable: false,
                  Heading: () => (
                    <Text as="p" variant="bodyMd" fontWeight="bold">
                      Order
                    </Text>
                  ),
                  Value: (row) => {
                    const { orderId, order_name } = row;
                    const linkText = notSeeOrderIdAndCustomerName ? 'View order' : order_name;

                    return orderId ? (
                      <Link external removeUnderline url={`/orders/${orderId}`} key={order_name}>
                        <Text as="p" variant="bodyMd" fontWeight="bold">
                          {linkText}
                        </Text>
                      </Link>
                    ) : (
                      <Text as="p" variant="bodyMd" fontWeight="bold">
                        {linkText}
                      </Text>
                    );
                  },
                },
                {
                  key: 'date',
                  dataType: 'text',
                  name: 'Date',
                  sortable: false,
                  Heading: () => (
                    <div className="w-[170px]">
                      <Text as="p" variant="bodyMd" fontWeight="bold">
                        Date
                      </Text>
                    </div>
                  ),
                  Value: (row) => {
                    const { created_at } = row;
                    return created_at ? moment(created_at).calendar() : '';
                  },
                },
                {
                  key: 'customer',
                  dataType: 'text',
                  name: 'Customer',
                  sortable: false,
                  Heading: () => (
                    <Text as="p" variant="bodyMd" fontWeight="bold">
                      Customer
                    </Text>
                  ),
                  Value: (row) => {
                    const { customer_id } = row;
                    const linkText = notSeeOrderIdAndCustomerName
                      ? 'View customer journey'
                      : formatName(row);

                    const val =
                      customer_id !== 'fake' ? (
                        <Link
                          external
                          removeUnderline
                          key={customer_id}
                          onClick={() => openCustomerJourney(customer_id, row)}
                        >
                          <p className="flex font-semibold">
                            {linkText}
                            {customer_id && (
                              <Icon color="interactive" source={ExternalSmallMinor} />
                            )}
                          </p>
                        </Link>
                      ) : (
                        <p className="flex font-semibold">{linkText}</p>
                      );

                    return <div className="w-[150px]">{val}</div>;
                  },
                },
                {
                  key: 'total',
                  dataType: 'text',
                  name: 'Total Price',
                  sortable: false,
                  Heading: () => (
                    <Text as="p" variant="bodyMd" fontWeight="bold">
                      Total Price
                    </Text>
                  ),
                  Value: (row) => {
                    const { total_price } = row;
                    return (
                      <Text as="p" variant="bodyMd">
                        {formatNumber(total_price * currencyConversionRate, {
                          style: 'currency',
                          currency,
                          minimumFractionDigits: 0,
                          maximumFractionDigits: 2,
                        })}
                      </Text>
                    );
                  },
                  Total: () => {
                    return formatNumber(totalOrdersValue * currencyConversionRate, {
                      style: 'currency',
                      currency,
                      minimumFractionDigits: 0,
                      maximumFractionDigits: 2,
                    });
                  },
                },
              ]}
            />
          </div>
        )}
      </Modal>
      {!newActivitiesTimeline && (
        <CustomerJourney
          loading={loadingPixel}
          order={order}
          journey={journey}
          isOpen={journeyOpen}
          campaign={item}
          onClose={() => setJourneyOpen(false)}
        />
      )}
      {newActivitiesTimeline && journeyOpen && (
        <CustomerProfileModal
          onClose={() => setJourneyOpen(false)}
          {...customerProfileProps}
        ></CustomerProfileModal>
      )}
    </>
  );
};

export default AttributionOrders;
