import { useMemo } from 'react';
import { type PopoverLink as PopoverConfig } from '@tw/ui-components';
import { PopoverLink } from '../PopoverLink';
import { ThemeChanger } from './ThemeChanger';
import { StoreAdder } from './StoreAdder';
import { useHistory } from 'react-router';
import { useAppDispatch } from 'index';
import { logoutOnPress } from 'ducks/auth';
import { $activeAppVersion } from '$stores/nav-config-stores';
import { useStoreValue } from '@tw/snipestate';

export const useSettingsConfig = () => {
  const activeAppVersion = useStoreValue($activeAppVersion);
  const history = useHistory();
  const dispatch = useAppDispatch();

  return useMemo<PopoverConfig[]>(
    () =>
      [
        {
          children: (
            <PopoverLink
              rawIconName="user-profile-circle"
              label="My Profile"
              onClick={() => history.push('/user-profile')}
            />
          ),
        },
        activeAppVersion === '2.0'
          ? {
              children: <ThemeChanger />,
            }
          : undefined,
        {
          children: <StoreAdder />,
        },
        {
          children: <PopoverLink label="Sign Out" onClick={() => dispatch(logoutOnPress())} />,
          topBorder: true,
        },
      ].filter((i) => i !== undefined),
    [history, dispatch, activeAppVersion],
  );
};
