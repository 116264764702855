import { Button, HoverCard, Icon, Menu, Text } from '@tw/ui-components';
import React, { FC, PropsWithChildren } from 'react';
import { useStoreValue } from '@tw/snipestate';
import { useDashContext } from '../dashContext';
import { ConditionalWrapper } from 'components/library/TwConditionalWrapper';

type WillyEditDashboardMenuProps = {
  addNewWidget: () => Promise<void>;
  onResizeReorder: () => void;
  onClickImage: () => void;
  onClickText: () => void;
};

type editReportOption = {
  content: string | React.ReactNode;
  onClick: () => void;
  leftSection?: React.ReactNode;
};

export function MaxSectionsReachedTooltip({ children }: PropsWithChildren) {
  const { MAX_WIDGET_COUNT } = useDashContext();

  return (
    <HoverCard shadow="xl" width={400} styles={{ dropdown: { border: 'none' } }} position="right">
      <HoverCard.Target>
        <div>{children}</div>
      </HoverCard.Target>
      <HoverCard.Dropdown>
        <Text fz="sm" c="gray.8" fw={500}>
          You have reached the maximum limit of {MAX_WIDGET_COUNT} sections in this report. Add a
          template or create new reports to add sections.{' '}
        </Text>
      </HoverCard.Dropdown>
    </HoverCard>
  );
}

export const WillyEditDashboardMenu: FC<WillyEditDashboardMenuProps> = ({
  addNewWidget,
  onResizeReorder,
  onClickImage,
  onClickText,
}) => {
  const { $dashboardDialect, $isGlobal, $widgetsLeft } = useDashContext();
  const widgetsLeft = useStoreValue($widgetsLeft);
  const dashboardDialect = useStoreValue($dashboardDialect);
  const isGlobal = useStoreValue($isGlobal);

  const menuSections: editReportOption[] = [
    {
      content: 'Add Data Visualization',
      onClick: addNewWidget,
      leftSection: <Icon name="combo-chart" size={16} />,
    },
    {
      content: 'Add Text',
      onClick: onClickText,
      leftSection: <Icon name="text" size={16} />,
    },
    {
      content: 'Add Image',
      onClick: onClickImage,
      leftSection: <Icon name="image" size={16} />,
    },
  ];

  return (
    <Menu shadow="md">
      <Menu.Target>
        <Button size="xs">Edit Report</Button>
      </Menu.Target>
      <Menu.Dropdown>
        <Menu.Item
          color="#1F2737"
          leftSection={<Icon name="edit" size={16} />}
          onClick={onResizeReorder}
          disabled={isGlobal && dashboardDialect !== 'both'}
        >
          Edit Layout
        </Menu.Item>
        <Menu.Divider />
        <ConditionalWrapper
          condition={!widgetsLeft}
          wrapper={(x) => <MaxSectionsReachedTooltip>{x}</MaxSectionsReachedTooltip>}
        >
          <div>
            {menuSections.map((section, i) => {
              const { content, ...rest } = section;

              return (
                <Menu.Item
                  key={`edit-report-item-${i}`}
                  color="#1F2737"
                  {...rest}
                  disabled={!widgetsLeft}
                >
                  {content}
                </Menu.Item>
              );
            })}
          </div>
        </ConditionalWrapper>
      </Menu.Dropdown>
    </Menu>
  );
};
