import { Integration, ProviderCredential } from '@tw/types/module/services/account-manager';
import { Box, confirm, Flex, Menu, Text } from '@tw/ui-components';
import { IntegrationStatusIcon } from './IntegrationStatusIcon';
import { MobileVerticalDotsMajor } from '@shopify/polaris-icons';
import { useCallback, useMemo } from 'react';
import { ConnectionMenuItem } from 'routes/integrations/types/ConnectionMenuItem';
import { useAppDispatch } from 'index';
import { toast } from 'react-toastify';
import { sensoryAccountDisconnect, sensoryAuthReconnect } from 'ducks/sensory';
import { useSelector } from 'react-redux';
import { RootState } from 'reducers/RootType';

type CredentialItemProps = {
  credential: ProviderCredential;
  integrations: Integration[];
};

export const CredentialItem = ({ credential, integrations }: CredentialItemProps) => {
  const dispatch = useAppDispatch();
  const sensoryProviderInfo = useSelector((state: RootState) =>
    state.sensory.providers.find((p) => p.id === credential.provider_id),
  );
  const handleReconnectCreds = useCallback(async () => {
    try {
      if (
        await confirm({
          title: <Text weight="bold">{'Reconnect'}</Text>,
          message: <Text pb={'md'}>{'Are you sure you want to reconnect this credential?'}</Text>,
        })
      ) {
        dispatch(async () => {
          await dispatch(sensoryAuthReconnect(sensoryProviderInfo, credential.id));
        });
      }
    } catch (err) {
      console.error(err);
      toast.error(`Error connecting account: ${err.message}`);
    }
  }, [credential, dispatch, sensoryProviderInfo]);

  const handleDelete = useCallback(async () => {
    try {
      if (
        await confirm({
          title: <Text weight="bold">{'Disconnect'}</Text>,
          message: <Text pb={'md'}>{'Are you sure you want to disconnect this credential?'}</Text>,
        })
      ) {
        dispatch(async () => {
          await dispatch(sensoryAccountDisconnect(credential.id));
        });
      }
    } catch (err) {
      console.error(err);
      toast.error(`Error connecting account: ${err.message}`);
    }
  }, [credential.id, dispatch]);

  const menuActions: ConnectionMenuItem[] = useMemo(() => {
    const items: ConnectionMenuItem[] = [];

    items.push({
      content: 'Reconnect',
      onClick: handleReconnectCreds,
    });

    items.push({
      content: 'Disconnect',
      onClick: handleDelete,
    });

    return items;
  }, [handleDelete, handleReconnectCreds]);
  return (
    <Flex key={`credential-${credential.id}`} pt="sm" justify={'space-between'}>
      <Flex>
        <Text fz="sm">Connection Status: </Text>
        {integrations?.length ? (
          <IntegrationStatusIcon
            status={['active', 'open'].includes(credential?.status) ? 'active' : 'inactive'}
          ></IntegrationStatusIcon>
        ) : (
          integrations && (
            <Text fz="sm" color="red.6" ml={'sm'}>
              {`No accounts found`}
            </Text>
          )
        )}
      </Flex>
      <Flex
        hiddenFrom={
          sensoryProviderInfo.auth_method === 'oauth' &&
          !sensoryProviderInfo.auth_config?.fields?.length
            ? ''
            : 'xs'
        }
      >
        <Menu>
          <Menu.Target>
            <span>
              <MobileVerticalDotsMajor className="block w-8 cursor-pointer fill-gray-600 dark:fill-gray-300" />
            </span>
          </Menu.Target>
          <Menu.Dropdown>
            {menuActions.map((action, i) => {
              return (
                <Menu.Item key={i} onClick={action.onClick}>
                  {action.content}
                </Menu.Item>
              );
            })}
          </Menu.Dropdown>
        </Menu>
      </Flex>
    </Flex>
  );
};
