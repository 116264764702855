import { Modal } from '@shopify/polaris';
import { HideMinor, ViewMinor } from '@shopify/polaris-icons';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../reducers/RootType';
import { sensoryAuthApply, toggleSensoryAuthConfigScreenOpen } from '../ducks/sensory';
import { useAppDispatch } from '../index';
import { Checkbox, PasswordInput, Text, TextInput } from '@tw/ui-components';

export const SensoryAuthConfig = () => {
  const dispatch = useAppDispatch();
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [fields, setFields] = React.useState<any[]>([]);
  const [note, setNote] = React.useState<string>();
  const sensoryAccountConfigScreenIsOpen = useSelector(
    (state: RootState) => state.sensory.sensoryAuthConfigScreenIsOpen,
  );
  const sensoryProviderConfigData = useSelector(
    (state: RootState) => state.sensory.sensoryProviderConfigData,
  );

  useEffect(() => {
    if (sensoryAccountConfigScreenIsOpen) {
      setFields(sensoryProviderConfigData.auth_config.fields);
      setNote(sensoryProviderConfigData.auth_config.note);
    }
  }, [sensoryAccountConfigScreenIsOpen, sensoryProviderConfigData]);

  return (
    <Modal
      title={`Connect ${sensoryProviderConfigData.name}`}
      open={sensoryAccountConfigScreenIsOpen}
      onClose={() => dispatch(toggleSensoryAuthConfigScreenOpen())}
      loading={isLoading}
      primaryAction={{
        content: 'Save',
        onAction: async () => {
          try {
            setIsLoading(true);
            await dispatch(
              sensoryAuthApply(
                sensoryProviderConfigData,
                fields.reduce((acc, x) => ({ ...acc, [x.field]: x.value }), {}),
              ),
            );
          } catch (err) {
            console.error('sensoryAuthApply error:>>', err);
          } finally {
            setIsLoading(false);
          }
        },
        disabled: fields.some((x) => !x.value),
      }}
      sectioned
    >
      {fields.map((f: any, index) => (
        <div className={'mt-2'}>
          {f.valueType === 'string' && (
            <TextInput
              forceColorScheme="light"
              label={f.label}
              value={f.value}
              placeholder={f.label}
              onChange={(val) => {
                const list = fields.slice();
                list[index] = { ...list[index], value: val };
                setFields(list);
              }}
            />
          )}
          {f.valueType === 'password' && (
            <PasswordInput
              forceColorScheme="light"
              label={f.label}
              value={f.value}
              placeholder={f.label}
              onChange={(val) => {
                const list = fields.slice();
                list[index] = { ...list[index], value: val };
                setFields(list);
              }}
            />
          )}
          {f.valueType === 'approval' && (
            <Checkbox
              label={<Text>{f.label}</Text>}
              checked={f.value}
              onChange={(val) => {
                const list = fields.slice();
                list[index] = { ...list[index], value: val };
                setFields(list);
              }}
            />
          )}
          {f.note && (
            <div
              className="Polaris-TextContainer"
              dangerouslySetInnerHTML={{ __html: f.note }}
            ></div>
          )}
        </div>
      ))}
      {note && (
        <div className="Polaris-TextContainer" dangerouslySetInnerHTML={{ __html: note }}></div>
      )}
    </Modal>
  );
};
