import React, { useContext } from 'react';

import { WizardContext } from '../../WizardContext';

export const WelcomeInstallWooPixel: React.FC = () => {
  const { nextStep } = useContext(WizardContext);

  return (
    <div>
      {'TODO'}
      <button className="step-button" onClick={nextStep}>
        Next
      </button>
    </div>
  );
};
