import {
  ActionIcon,
  Alert,
  Button,
  Container,
  Flex,
  Group,
  Icon,
  IconName,
  MobileDrawer,
  Modal,
  Select,
  Size,
  Text,
  Title,
  Tooltip,
  useCombobox,
} from '@tw/ui-components';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { WillySearchInput } from '../WillySearchInput';
import { PromptLibrarySideMenu } from './PromptLibrarySideMenu';
import { WillyPrompt } from '../types/willyTypes';
import { getPromptsList } from '../utils/willyUtils';
import { PromptsLibraryInner } from './PromptLibraryInner';
import { useStoreValue } from '@tw/snipestate';
import { $user } from '$stores/$user';
import { $favoritePrompts } from '$stores/willy/$favoritePrompts';
import { genericEventLogger, analyticsEvents, chatActions } from 'utils/dataLayer';
import { useHistory } from 'react-router';
import { PromptLibraryUpgradeMoby } from './PromptLibraryUpgradeMoby';

export const PROMPT_SUBCATS: { name: string; icon?: IconName }[] = [
  { name: 'Featured', icon: 'stars' },
  { name: 'All Prompts' },
  { name: 'Popular' },
  { name: 'My Prompts' },
  { name: "Shop's Prompts" },
];

type PromptLibraryPopupProps = {
  isSmall: boolean;
  onSelectPrompt?: (value: string) => void;
};

export type PromptSubCategory = {
  subCategory: string;
  prompts: WillyPrompt[];
};

export type PromptsCategory = {
  name: string;
  subCategories: { name: string; icon?: IconName }[];
};

export const PromptLibraryPopup: FC<PromptLibraryPopupProps> = ({ onSelectPrompt, isSmall }) => {
  const [opened, setOpened] = useState<boolean>(false);
  const [searchPrompt, setSearchPrompt] = useState<string>('');
  const [activeSubCategory, setActiveSubCategory] = useState<string>('All Prompts');
  const [promptsList, setPromptList] = useState<WillyPrompt[]>([]);
  const history = useHistory();
  const favoritePrompts = useStoreValue($favoritePrompts);
  const user = useStoreValue($user);

  useEffect(() => {
    (async () => {
      const allPrompts = await getPromptsList();
      const formattedPromptsList: WillyPrompt[] = [
        ...allPrompts,
        ...favoritePrompts,
      ].map<WillyPrompt>((prompt) => {
        const isFav = favoritePrompts.some((fav) => fav.prompt === prompt.prompt);
        const isUserFav = !!prompt.userId && prompt.userId === user.uid;
        return {
          ...prompt,
          isPopular: (prompt.isPopular as any) === 'TRUE' ? true : false,
          isFeatured: (prompt.isFeatured as any) === 'TRUE' ? true : false,
          category: isFav ? '' : prompt.category,
          subCategory:
            isFav && isUserFav ? 'My Prompts' : isFav ? "Shop's Prompts" : prompt.subCategory,
        };
      });

      setPromptList(formattedPromptsList);
    })();
  }, [user, favoritePrompts]);

  const doOpen = useCallback((opened) => {
    setOpened(opened);

    if (!opened) {
      genericEventLogger(analyticsEvents.CHAT, {
        action: chatActions.OPEN_PROMPT_LIBRARY,
      });
    }
  }, []);

  const highlightPrompt = useCallback((prompt: string) => {
    const content = prompt
      .replaceAll(
        /`([^`]*)`/g,
        (match) =>
          `<span class="group-hover:bg-[#D6E7FF] group-hover:shadow-lg group-hover:shadow-[#D6E7FF] rounded-md">${match}</span>`,
      )
      .replaceAll('`', '');
    return `<span>${content}</span>`;
  }, []);

  const popularPrompts = useCallback((list: WillyPrompt[]) => {
    return list.filter((prompt) => prompt.isPopular);
  }, []);

  const featuredPrompts = useCallback((list: WillyPrompt[]) => {
    return list.filter((prompt) => prompt.isFeatured);
  }, []);

  const promptsToShow: PromptSubCategory[] = useMemo(() => {
    const filteredList = promptsList.filter(
      (prompt) =>
        prompt.title.toLowerCase().includes(searchPrompt.toLowerCase()) ||
        prompt.prompt.toLowerCase().includes(searchPrompt.toLowerCase()),
    );
    const popularList = popularPrompts(filteredList);
    const featuredList = featuredPrompts(filteredList);
    if (activeSubCategory === 'All Prompts') {
      const list = filteredList.reduce((acc: PromptSubCategory[], prompt) => {
        const subCategory = acc.find((sub) => sub.subCategory === prompt.subCategory);
        if (subCategory) {
          if (subCategory.prompts.length < 3) {
            subCategory.prompts.push(prompt);
          }
        } else {
          acc.push({ subCategory: prompt.subCategory, prompts: [prompt] });
        }
        return acc;
      }, []);
      if (featuredList.length)
        list.unshift({ subCategory: 'Featured', prompts: featuredList.slice(0, 3) });
      if (popularList.length)
        list.unshift({ subCategory: 'Popular', prompts: popularList.slice(0, 3) });
      return list;
    }
    if (activeSubCategory === 'Featured') {
      return [{ subCategory: activeSubCategory, prompts: featuredList }];
    }
    if (activeSubCategory === 'Popular') {
      return [{ subCategory: activeSubCategory, prompts: popularList }];
    }
    return [
      {
        subCategory: activeSubCategory,
        prompts: filteredList.filter((prompt) => prompt.subCategory === activeSubCategory),
      },
    ];
  }, [promptsList, activeSubCategory, searchPrompt, featuredPrompts, popularPrompts]);

  const promptsCategories: PromptsCategory[] = useMemo(() => {
    const list = promptsList
      .filter((p) => !p.userId) // Filter out prompts from DB :/
      .reduce((acc: PromptsCategory[], prompt) => {
        const category = acc.find((cat) => cat.name === prompt.category);
        if (category) {
          if (!category.subCategories.find((sub) => sub.name === prompt.subCategory)) {
            category.subCategories.push({ name: prompt.subCategory });
          }
        } else {
          acc.push({ name: prompt.category, subCategories: [{ name: prompt.subCategory }] });
        }
        return acc;
      }, []);
    list.unshift({
      name: '',
      subCategories: PROMPT_SUBCATS,
    });
    return list;
  }, [promptsList]);
  // const openMobilePromptLibrary = () => {
  //   openMobileDrawer({
  //     title: 'Prompt Library',
  //     children: () => (
  //       <Flex direction="column" gap="lg" overflow="auto" h="100%">
  //         <WillySearchInput
  //           value={searchPrompt}
  //           onChange={(v) => setSearchPrompt(v)}
  //           placeholder="Search Prompts"
  //           className="!p-0 overflow-visible"
  //         />
  //         <Select
  //           comboboxProps={{ withinPortal: false }}
  //           // styles={{ options: { zIndex: 5000000 }, dropdown: { zIndex: 500000 } }}
  //           onChange={(val) => {
  //             setActiveSubCategory(val as string);
  //           }}
  //           allowDeselect={false}
  //           value={activeSubCategory}
  //           data={promptsCategories.map((cat) => {
  //             return {
  //               group: cat.name,
  //               items: cat.subCategories.map((subCat) => {
  //                 return { value: subCat.name, label: subCat.name };
  //               }),
  //             };
  //           })}
  //         />
  //         <PromptsLibraryInner
  //           setOpened={toggleLibrary}
  //           promptsToShow={promptsToShow}
  //           activeSubCategory={activeSubCategory}
  //           setActiveSubCategory={setActiveSubCategory}
  //           onSelectPrompt={onSelectPrompt}
  //           highlightPrompt={(v) => highlightPrompt(v)}
  //         />
  //       </Flex>
  //     ),
  //   });
  // };

  const combobox = useCombobox({
    onDropdownOpen() {
      combobox.focusSearchInput();
    },
    onDropdownClose: () => {
      combobox.resetSelectedOption();
      combobox.focusTarget();
    },
  });

  const renderSelectOption = ({ option }) => {
    const optionObj = PROMPT_SUBCATS.find((subCat) => subCat.name === option.value);
    return (
      <Group flex="1" gap="xs">
        {option.label}
        {optionObj?.icon && <Icon name={optionObj.icon} size={16} color="gray.8" />}
      </Group>
    );
  };

  const activator = (
    <ActionIcon iconSize={20} icon="prompts-library" onClick={() => setOpened((x) => !x)} />
  );

  if (isSmall) {
    return (
      <>
        {activator}
        <MobileDrawer
          title="Prompt Library"
          onClose={() => setOpened(false)}
          opened={opened}
          position="bottom"
          withinPortal
        >
          <Flex direction="column" gap="lg" overflow="auto" h="100%">
            <WillySearchInput
              value={searchPrompt}
              onChange={(v) => setSearchPrompt(v)}
              placeholder="Search Prompts"
              className="!p-0 overflow-visible"
            />
            <Select
              comboboxProps={{ withinPortal: false }}
              onChange={(val) => {
                setActiveSubCategory(val as string);
              }}
              allowDeselect={false}
              value={activeSubCategory}
              data={promptsCategories.map((cat) => {
                return {
                  group: cat.name,
                  items: cat.subCategories.map((subCat) => {
                    return { value: subCat.name, label: subCat.name };
                  }),
                };
              })}
              renderOption={renderSelectOption}
            />
            <PromptLibraryUpgradeMoby />
            <PromptsLibraryInner
              setOpened={doOpen}
              promptsToShow={promptsToShow}
              activeSubCategory={activeSubCategory}
              setActiveSubCategory={setActiveSubCategory}
              onSelectPrompt={onSelectPrompt}
              highlightPrompt={(v) => highlightPrompt(v)}
            />
          </Flex>
        </MobileDrawer>
      </>
    );
  }

  return (
    <>
      <div>
        <Tooltip label="Prompt Library">{activator}</Tooltip>
      </div>
      <Modal
        opened={opened}
        onClose={() => setOpened(false)}
        overlayProps={{ opacity: 0 }}
        withCloseButton={false}
        padding={0}
        size={'80%' as Size}
        xOffset="10vw 5vw"
        yOffset="14dvh"
      >
        <Flex
          direction="column"
          p="md"
          border="1px solid var(--mantine-color-gray-1)"
          borderRadius={6}
          h="100%"
          bg="white"
        >
          <Flex justify="space-between" align="center">
            <Title order={5} fw={600} color="gray.8">
              Prompts Library
            </Title>
            <ActionIcon
              variant="transparent"
              iconSize={14}
              icon="close"
              onClick={() => setOpened(false)}
            />
          </Flex>
          <Flex h="100%">
            <PromptLibrarySideMenu
              promptsCategories={promptsCategories}
              activeSubCategory={activeSubCategory}
              onActiveSubCategoryChange={setActiveSubCategory}
            />
            <Container flex={3} className="flex flex-col gap-12 h-full overflow-auto" p="lg">
              <WillySearchInput
                value={searchPrompt}
                onChange={setSearchPrompt}
                placeholder="Search Prompts"
                className="!p-0 overflow-visible"
              />
              <PromptsLibraryInner
                setOpened={setOpened}
                promptsToShow={promptsToShow}
                activeSubCategory={activeSubCategory}
                setActiveSubCategory={(v) => setActiveSubCategory(v)}
                onSelectPrompt={onSelectPrompt}
                highlightPrompt={(v) => highlightPrompt(v)}
              />
            </Container>
          </Flex>
        </Flex>
      </Modal>
    </>
  );
};
