import React, { useCallback, useEffect, useMemo, useRef, useState, Suspense } from 'react';
import { useAppSelector } from 'reducers/RootType';
import { WillyChart } from './WillyChart';
import { WillyTable } from './table/WillyTable';
import { WillySingleValue } from './WillySingleValue';
import {
  ChatSources,
  EditMetricModalPayload,
  WidgetQuery,
  MessageTypes,
  NlqResponse,
  WillyMetric,
  WillyParameter,
  GridOptions,
  GridColumnOptions,
  TileModes,
  AxisDomain,
  WidgetPermissions,
  WillyExpressionMetric,
  CodeInterpreterResponse,
  FileWithData,
  NlqResponseDataType,
  WillyDashboardElement,
  CodeExecutionResponse,
  Dialect,
  RawNlqData,
  WillyWidgetElement,
} from './types/willyTypes';
import { WillySimpleText } from './WillySimpleText';
import { getSocket, openAiStopSequence } from './WillySocket';
import _db from 'utils/DB';
import { WillyWidgetHeader } from './WidgetHeader/WillyWidgetHeader';
import { WillyJson } from './WillyJson';
import {
  breakDownRowDataWithoutTotal,
  cleanQuestion,
  convertDataToJson,
  convertExpressionMetricToWillyMetric,
  createWillyMetricsFromRawData,
  emptyArray,
  executeRce,
  fetchQueryBuilderData,
  fetchWidgetQueries,
  getCurrentAnalyticsActionSet,
  getDataLayerEventByContext,
  keyIsEntityId,
  keyIsEntityName,
  keyIsService,
  parseCodeSnippet,
  reorderPreviousDataByKeys,
  totalRowData,
} from './utils/willyUtils';
import { WillyDynamicFields } from './WillyDynamicFields';
import { WillySingleValueChart } from './WillySingleValueChart';
import lazyWithRetries from 'utils/lazyWithRetries';
import { useDeepCompareMemoize } from 'hooks/useDeepCompareMemoize';
import { Flex, Text } from '@tw/ui-components';
import { ServicesIds } from '@tw/types/module/services';
import { uniqBy } from 'lodash';
import { useFeatureFlagValue } from '../../feature-flag-system';
import { FeatureFlag } from '@tw/feature-flag-system/module/types';
import LockedFeatureIndicator from '../library/LockedFeatures/LockedFeatureIndicator';
import { WillyWidgetEditor } from './WillyWidgetEditor/WillyWidgetEditor';
import { $willyMetricsCombine } from '../../$stores/willy/$willyMetrics';
import { $currentDateRange, $granularity, $prevDateRange } from '../../$stores/willy/$dateRange';
import { getMetricSqlMetadata } from './WillyMetrics/utils';
import { $activeAccounts } from '../../$stores/$shop';
import { WillyEmptyMarkup } from './WillyEmptyMarkup';
import { WillyMetricCustomComponent, isCustomeComponentMetric } from './WillyMetricCustomComponent';
import { useStoreValue } from '@tw/snipestate';
import { BuilderTable } from '@tw/willy-data-dictionary/module/columns/types';
import { genericEventLogger } from 'utils/dataLayer';

const WillyMap = lazyWithRetries(() => import('./WillyMap'));

export type WillyWidgetProps = {
  context: ChatSources;
  queryId?: string;
  withoutMainQuery?: boolean;
  dashboard?: WillyDashboardElement;
  isGlobalDashboard?: boolean;
  isCustomView?: boolean;
  type: MessageTypes;
  metrics: WillyMetric[];
  dataMetricIds: string[];
  parameters?: WillyParameter[];
  queries?: WidgetQuery[];
  stacked: boolean;
  permission: WidgetPermissions;
  incrementedStacked: boolean;
  title: string;
  wrapText?: boolean;
  initialRawData?: NlqResponse;
  initialCodeInterpreterData?: FileWithData[];
  verified?: boolean;
  loadInitialData?: boolean;
  dataMetricIdsChanged: (id: string, metricsIds: string[]) => Promise<void>;
  setWrapText: (v: boolean) => void;
  parametersChanged?: (v: WillyParameter[]) => void;
  stackedChanged: (v: boolean) => void;
  permissionChanged: (v: ServicesIds[]) => void;
  incrementedStackedChanged: (v: boolean) => void;
  builderSetupChanged: (v: BuilderTable) => Promise<void>;
  metricsChanged: (id: string, v: WillyMetric[]) => Promise<void>;
  typeChanged: (v: MessageTypes) => void;
  titleChanged: (v: string) => void;
  queriesChanged: (queries: WidgetQuery[]) => Promise<void>;
  setEditMetricModalOpen?: EditMetricModalPayload;
  isDynamic?: boolean;
  question?: string;
  hideHeader?: boolean;
  currency: string;
  shouldReplaceTablesWithNlq?: boolean;
  suppressMetricClick?: boolean;
  grid: GridOptions;
  setGrid: (v: GridOptions) => void;
  gridColumns: GridColumnOptions;
  setGridColumns: (v: GridColumnOptions) => void;
  twoColumnMobile: boolean;
  setTwoColumnMobile: (v: boolean) => void;
  tileMode: TileModes;
  setTileMode: (v: TileModes) => void;
  skinny: boolean;
  setSkinny: (v: boolean) => void;
  yAxisDomain: AxisDomain;
  setYAxisDomain: (domain: AxisDomain) => void;
  allowDataOverflow: boolean;
  setAllowDataOverflow: (v: boolean) => void;
  dimension: string;
  setDimension: (v: string) => void;
  secondaryToolbarOpen?: boolean;
  queryVariablesForPopup?: Record<string, any>;
  metricsData?: NlqResponse;
  metricsDataPrevious?: NlqResponse;
  errorMetricsData?: Record<string, string>;
  loadingMetricsData?: Record<string, { current: boolean; previous: boolean }>;
  pinnedSection?: boolean;
  pinnedMetricKeys?: string[];
  setHidePinnedSection?: (v: boolean) => void;
  CDPSegmentId?: string;
  hasGlobalConditionalFormatting: boolean;
  globalConditionalFormattingColor: string;
  setHasGlobalConditionalFormatting: (v: boolean) => void;
  setGlobalConditionalFormattingColor: (v: string) => void;
  breakdownMode?: boolean;
  breakdownModeChanged: (breakdown: boolean) => Promise<void>;
  isDnd?: boolean;
  editLayout?: boolean;
  inWidgetEditor?: boolean;
  codeResult?: CodeInterpreterResponse;
  codeResultChanged?: (v: CodeExecutionResponse) => Promise<void>;
  initialShowPreviousPeriod?: boolean;
  initialPreviousPeriodRawData?: NlqResponse;
  loadInitialPreviousPeriodData?: boolean;
  updateDashWidgetData?: (id: string, data: NlqResponse) => void;
  dataType?: NlqResponseDataType;
  supportMetricsFromLibrary?: boolean;
  functionName?: string;
  openChatWithQuery?: (widgetSourceId?: string) => void;
  openSqlWithWidget?: (widgetSourceId?: string) => void;
  historicalQueryIds?: string[];
  dialect: Dialect;
  contextSourceIds?: { dashboardId: string; widgetId: string; onAdd?: () => void };
  mode?: WillyWidgetElement['mode'];
  builderSetup?: BuilderTable;
  filtersOpen: boolean;
  setFiltersOpen: (v: boolean) => void;
  inMobileDrawer?: boolean;
};

export const WillyWidget: React.FC<WillyWidgetProps> = React.memo(
  ({
    context,
    queryId,
    functionName,
    withoutMainQuery,
    isDynamic,
    shouldReplaceTablesWithNlq,
    type,
    title,
    metrics,
    queries,
    stacked = false,
    verified = false,
    isGlobalDashboard = false,
    isCustomView = false,
    incrementedStacked = false,
    question = '',
    initialRawData,
    initialCodeInterpreterData,
    parameters,
    wrapText,
    hideHeader,
    currency,
    suppressMetricClick,
    dataMetricIds,
    dataMetricIdsChanged,
    stackedChanged,
    permissionChanged,
    parametersChanged,
    incrementedStackedChanged,
    builderSetupChanged,
    metricsChanged,
    typeChanged,
    titleChanged,
    queriesChanged,
    setEditMetricModalOpen,
    setWrapText,
    dashboard,
    loadInitialData,
    grid = 'flex',
    setGrid = () => {},
    gridColumns = 2,
    setGridColumns = () => {},
    twoColumnMobile = false,
    setTwoColumnMobile = () => {},
    tileMode = 'tile',
    setTileMode = () => {},
    skinny = false,
    setSkinny = () => {},
    yAxisDomain,
    setYAxisDomain,
    allowDataOverflow,
    setAllowDataOverflow,
    dimension,
    permission,
    setDimension,
    secondaryToolbarOpen = false,
    queryVariablesForPopup,
    metricsData,
    metricsDataPrevious,
    errorMetricsData,
    loadingMetricsData,
    pinnedSection,
    pinnedMetricKeys,
    setHidePinnedSection,
    CDPSegmentId,
    hasGlobalConditionalFormatting,
    globalConditionalFormattingColor,
    setHasGlobalConditionalFormatting,
    setGlobalConditionalFormattingColor,
    breakdownMode,
    breakdownModeChanged,
    isDnd = false,
    editLayout = false,
    inWidgetEditor,
    codeResult,
    codeResultChanged,
    updateDashWidgetData,
    initialShowPreviousPeriod,
    initialPreviousPeriodRawData,
    loadInitialPreviousPeriodData,
    dataType,
    supportMetricsFromLibrary,
    openChatWithQuery,
    openSqlWithWidget,
    historicalQueryIds,
    dialect,
    contextSourceIds,
    mode,
    builderSetup,
    filtersOpen,
    setFiltersOpen,
    inMobileDrawer,
  }) => {
    const generateInsightsStartedRef = useRef(false);
    const widgetContainerRef = useRef<HTMLDivElement>(null);

    const currentShopId = useAppSelector((state) => state.currentShopId);
    const blockedIntegrations = useFeatureFlagValue(FeatureFlag.LIMIT_INTEGRATIONS_FF, 'blockList');

    const prevDateRange = useStoreValue($prevDateRange);
    const currentDateRange = useStoreValue($currentDateRange);
    const activeAccounts = useStoreValue($activeAccounts);
    const granularity = useStoreValue($granularity);
    const willyMetricsCombine = useStoreValue($willyMetricsCombine);

    const [rawData, setRawData] = useState<NlqResponse>();
    const [textData, setTextData] = useState<string>('');
    const [previousPeriodRawData, setPreviousPeriodRawData] = useState<NlqResponse>();
    const [loading, setLoading] = useState(!!loadInitialData);
    const [loadingPreviousPeriod, setLoadingPreviousPeriod] = useState(false);
    const [loadingText, setLoadingText] = useState(false);
    const [defaultMetrics, setDefaultMetrics] = useState<WillyMetric[]>(metrics || []);
    const [showPreviousPeriod, setShowPreviousPeriod] = useState(false);
    const [singleValuePopupOpened, setSingleValuePopupOpened] = useState(false);
    const [activeMetric, setActiveMetric] = useState<WillyMetric>();
    const [activeMetricData, setActiveMetricData] = useState<Record<string, any>>();
    const [newMetricIds, setNewMetricIds] = useState<string[]>(dataMetricIds);
    const [errorInQuery, setErrorInQuery] = useState<Record<string, string>>({});
    const [codeInterpreterInputData, setCodeInterpreterInputData] = useState<string>();
    const [widgetEditorOpen, setWidgetEditorOpen] = useState(false);
    const [metricLibraryOpened, setMetricLibraryOpened] = useState(false);
    const [activeMetricCustomComponent, setActiveMetricCustomComponent] = useState<any>(null);
    const [showQuickEditing, setShowQuickEditing] = useState(false);

    const memoizedParameters = useDeepCompareMemoize(parameters || emptyArray<WillyParameter>());
    const willySocket = useMemo(() => getSocket(), []);
    const allExpressionsMetrics = useMemo(() => {
      const dateMetric: WillyExpressionMetric = {
        id: 'date',
        isDimension: true,
        color: '#000000',
        description: 'Date',
        icon: 'calendar-1',
        tableId: '',
        columnId: '',
        key: 'date',
        name: 'Date',
        format: 'string',
        toFixed: 0,
        isGlobal: true,
        filter: [],
      };
      const allMetrics = [dateMetric, ...willyMetricsCombine];
      return allMetrics.filter((x) => dataMetricIds.includes(x.id));
    }, [dataMetricIds, willyMetricsCombine]);

    const isBlocked = useMemo(() => {
      if (!blockedIntegrations.length) {
        return false;
      }
      return permission.providers.some((x) => blockedIntegrations.includes(x));
    }, [blockedIntegrations, permission]);

    const reorderedPreviousPeriodData = useMemo(() => {
      if (prevDateRange?.id === 'none') {
        return previousPeriodRawData;
      }
      if (!previousPeriodRawData?.data) {
        return previousPeriodRawData;
      }
      if (!rawData?.data || !rawData?.dataColumns?.x) {
        return previousPeriodRawData;
      }

      const reorderedData = reorderPreviousDataByKeys(
        rawData.data,
        previousPeriodRawData.data,
        rawData.dataColumns.x,
      );

      return {
        ...previousPeriodRawData,
        data: reorderedData,
      };
    }, [previousPeriodRawData, rawData?.data, rawData?.dataColumns?.x, prevDateRange?.id]);

    const metricsQueries = useMemo(() => {
      if (!metricsData?.queries) {
        return emptyArray<WidgetQuery>();
      }

      const allExpressionsMetricsKeys = allExpressionsMetrics.map((m) => m.key);

      return metricsData.queries
        .filter((q) => allExpressionsMetricsKeys.includes(q.id))
        .map((q) => {
          return {
            ...q,
            fromMetricBuilder: true,
          };
        });
    }, [metricsData, allExpressionsMetrics]);

    const rawNlqData = useMemo(() => {
      return rawData?.data || emptyArray<RawNlqData[number]>();
    }, [rawData?.data]);

    const previousPeriodRawNlqData = useMemo(() => {
      return reorderedPreviousPeriodData?.data || emptyArray<RawNlqData[number]>();
    }, [reorderedPreviousPeriodData?.data]);

    const dataWithMetricsData = useMemo(() => {
      if (!metricsData?.data) {
        return rawNlqData;
      }

      const allData = rawNlqData.concat(metricsData.data);
      return uniqBy(allData, 'name');
    }, [rawNlqData, metricsData]);

    const previousPeriodDataWithMetricsData = useMemo(() => {
      if (!metricsDataPrevious?.data) {
        return previousPeriodRawNlqData;
      }

      const allData = previousPeriodRawNlqData.concat(metricsDataPrevious.data);
      return uniqBy(allData, 'name');
    }, [previousPeriodRawNlqData, metricsDataPrevious?.data]);

    const memoizedQueries = useDeepCompareMemoize(queries);

    const queriesWithoutBuilder = useMemo(() => {
      return (memoizedQueries || []).filter((q) => !q.fromMetricBuilder);
    }, [memoizedQueries]);

    const queriesToFetch = useMemo(() => {
      let queries = (queriesWithoutBuilder || []).map((x) => x.id);
      if (withoutMainQuery) {
        queries = queries.filter((x) => x !== queryId);
      } else {
        queries = queries.concat([queryId || '']);
      }

      return queries;
    }, [queriesWithoutBuilder, queryId, withoutMainQuery]);

    const mainQuery = useMemo(() => {
      const q = queries?.find((x) => x.id === queryId);
      return q?.query;
    }, [queries, queryId]);

    const memoizedQueriesToFetch = useDeepCompareMemoize(queriesToFetch);

    const rawDataColumns = useMemo(() => {
      return rawData?.dataColumns || { x: [], y: [] };
    }, [rawData?.dataColumns]);

    const dataColumnsWithMetricsColumns = useMemo(() => {
      if (!metricsData?.dataColumns) {
        return rawDataColumns;
      }

      const allColumns = {
        x: rawDataColumns.x || [],
        y: rawDataColumns.y || [],
      };

      if (metricsData.dataColumns) {
        allColumns.x = [...allColumns.x, ...metricsData.dataColumns.x];
        allColumns.y = [...allColumns.y, ...metricsData.dataColumns.y];
      }

      return allColumns;
    }, [rawDataColumns, metricsData?.dataColumns]);

    const activeMetricIsFromBuilder = useMemo(() => {
      if (!activeMetric) {
        return false;
      }
      return !!activeMetric.expressionMetric;
    }, [activeMetric]);

    const totalValue = useMemo(() => {
      if (!activeMetric?.key) {
        return undefined;
      }

      const data = activeMetricIsFromBuilder ? metricsData?.data : rawData?.data;

      if (!data) {
        return undefined;
      }
      return totalRowData(data, activeMetric.key);
    }, [rawData?.data, activeMetric?.key, activeMetricIsFromBuilder, metricsData?.data]);

    const breakdownValue = useMemo(() => {
      if (!activeMetric?.key) {
        return undefined;
      }
      const data = activeMetricIsFromBuilder ? metricsData?.data : rawData?.data;
      if (!data) {
        return undefined;
      }
      return breakDownRowDataWithoutTotal(data, activeMetric.key);
    }, [rawData?.data, activeMetric?.key, activeMetricIsFromBuilder, metricsData?.data]);

    const previousPeriodTotalValue = useMemo(() => {
      if (!activeMetric?.key) {
        return undefined;
      }
      const data = activeMetricIsFromBuilder
        ? metricsDataPrevious?.data
        : reorderedPreviousPeriodData?.data;

      if (!data) {
        return undefined;
      }
      return totalRowData(data, activeMetric.key);
    }, [
      reorderedPreviousPeriodData?.data,
      metricsDataPrevious?.data,
      activeMetric?.key,
      activeMetricIsFromBuilder,
    ]);

    const previousPeriodBreakDownValue = useMemo(() => {
      if (!activeMetric?.key) {
        return undefined;
      }
      const data = activeMetricIsFromBuilder
        ? metricsDataPrevious?.data
        : reorderedPreviousPeriodData?.data;

      if (!data) {
        return undefined;
      }

      return breakDownRowDataWithoutTotal(data, activeMetric.key);
    }, [
      reorderedPreviousPeriodData?.data,
      metricsDataPrevious?.data,
      activeMetric?.key,
      activeMetricIsFromBuilder,
    ]);

    const widgetMainResource = useMemo(() => {
      if (defaultMetrics?.find(({ key }) => key === 'customer_id')) {
        return 'customers';
      }
      return 'unknown';
    }, [defaultMetrics]);

    const dataHasBreakdown = useMemo(() => {
      if (!defaultMetrics.length) {
        return false;
      }

      const hasProvider = defaultMetrics.some((m) => keyIsService(m.key));
      const hasEntityId = defaultMetrics.some((m) => keyIsEntityId(m.key));
      const hasEntityName = defaultMetrics.some((m) => keyIsEntityName(undefined, m.key));

      return hasProvider && hasEntityId && hasEntityName;
    }, [defaultMetrics]);

    const convertRawDataToJson = useCallback((responses: NlqResponse[], limit = Infinity) => {
      const asObject = responses.map((response, i) => {
        return {
          file_name: `${cleanQuestion(response.question!)}_${i}.json`,
          data: convertDataToJson(response.data),
        };
      }, {});

      const stringifiedData = JSON.stringify(asObject, null, 2);

      return stringifiedData;
    }, []);

    const onMetricsChanged = useCallback(
      async (id: string, metrics: WillyMetric[]) => {
        setDefaultMetrics(metrics);
        await metricsChanged(id, metrics);
      },
      [metricsChanged],
    );

    const closeSingleValuePopup = useCallback(() => {
      setSingleValuePopupOpened(false);
    }, []);

    const metricClicked = useCallback(
      (metric: WillyMetric, metricValues?: Record<string, any>) => {
        if (suppressMetricClick) {
          return;
        }
        if (isCustomeComponentMetric(metric.functionName)) {
          setActiveMetricCustomComponent(
            <WillyMetricCustomComponent
              data={metricValues || {}}
              customComponent={metric.functionName}
              onClose={() => setActiveMetricCustomComponent(null)}
            />,
          );
          return;
        }

        setActiveMetric(metric);
        setSingleValuePopupOpened(true);

        setActiveMetricData(metricValues);
      },
      [suppressMetricClick],
    );

    const removeExpressionMetrics = useCallback(
      (metricsToRemove: WillyMetric[]) => {
        return defaultMetrics.filter((m) => {
          return !metricsToRemove.some((dm) => dm.key === m.key);
        });
      },
      [defaultMetrics],
    );

    // add or remove metrics from the widget based on the expression metrics
    // if metric added add it to the widget with the flag expressionMetric
    // if metric removed remove it from the widget
    useEffect(() => {
      (async () => {
        if (!queryId) {
          return;
        }

        if (!defaultMetrics?.length && !allExpressionsMetrics?.length) {
          return;
        }

        // if there are no metrics in the widget, remove all expression metrics
        if (!allExpressionsMetrics?.length) {
          const metricsFromExpression = defaultMetrics?.filter((m) => m.expressionMetric);
          if (!metricsFromExpression.length) {
            return;
          }

          const newMetrics = removeExpressionMetrics(metricsFromExpression);
          await onMetricsChanged(queryId, newMetrics);
          return;
        }

        const newMetrics = allExpressionsMetrics
          .filter((m) => {
            return !defaultMetrics?.some((dm) => dm.key === m.key);
          })
          .map((m, i) => {
            return convertExpressionMetricToWillyMetric(m, i);
          });

        const removedExpressionMetrics = defaultMetrics?.filter((m) => {
          return m.expressionMetric && !allExpressionsMetrics.some((dm) => dm.key === m.key);
        });

        const metricsToSet = defaultMetrics
          ?.filter((m) => !removedExpressionMetrics.some((rm) => rm.key === m.key))
          .concat(newMetrics);

        if (!metricsToSet?.length) {
          return;
        }

        if (
          defaultMetrics?.length === metricsToSet.length &&
          metricsToSet.every((m) => defaultMetrics?.some((dm) => dm.key === m.key))
        ) {
          return;
        }

        await onMetricsChanged(queryId, metricsToSet);
      })();
    }, [allExpressionsMetrics, defaultMetrics, onMetricsChanged, queryId, removeExpressionMetrics]);

    useEffect(() => {
      setNewMetricIds(dataMetricIds);
    }, [dataMetricIds]);

    useEffect(() => {
      const activeDefaultMetric = defaultMetrics?.find((m) => m.key === activeMetric?.key);
      if (activeDefaultMetric) {
        setActiveMetric(activeDefaultMetric);
      }
    }, [defaultMetrics, activeMetric?.key]);

    useEffect(() => {
      let defaultMetrics: WillyMetric[] = metrics;

      const hasNoDataFromBackend = !rawData?.data?.length;

      if (hasNoDataFromBackend) {
        defaultMetrics = metrics;
      } else {
        const calculated = createWillyMetricsFromRawData(
          rawData.data,
          mainQuery || '',
          metrics,
          rawData?.serviceIds || [],
          dataType,
        );
        defaultMetrics = calculated;
      }
      setDefaultMetrics(defaultMetrics);
    }, [rawData?.data, mainQuery, rawData?.serviceIds, metrics, dataType]);

    useEffect(() => {
      if (initialRawData) {
        setRawData(initialRawData);
        updateDashWidgetData?.(queryId!, initialRawData);
      }
    }, [initialRawData, queryId, updateDashWidgetData]);

    useEffect(() => {
      setShowPreviousPeriod(initialShowPreviousPeriod ?? false);
    }, [initialShowPreviousPeriod]);

    useEffect(() => {
      if (!initialCodeInterpreterData) {
        return;
      }
      const dataWithoutQuery = initialCodeInterpreterData.map((d) => {
        return {
          file_name: d.file_name,
          data: d.data,
        };
      });
      const stringifiedData = JSON.stringify(dataWithoutQuery, null, 2);
      setCodeInterpreterInputData(stringifiedData);
    }, [initialCodeInterpreterData]);

    const deselectedMetrics = useMemo(() => {
      if (!defaultMetrics) {
        return [];
      }
      return defaultMetrics
        .filter((m) => m.active === false)
        .filter((m) => !m.isDimension)
        .map((m) => m.key)
        .sort();
    }, [defaultMetrics]);

    const selectedCustomMetrics = useMemo(() => {
      if (!defaultMetrics) {
        return [];
      }

      return defaultMetrics
        .filter((m) => m.expressionMetric && m.active)
        .map((m) => getMetricSqlMetadata(m.key).sqlExpression)
        .sort();
    }, [defaultMetrics]);

    const memoizedDeselectedMetrics = useDeepCompareMemoize(deselectedMetrics);
    const memoizedSelectedCustomMetrics = useDeepCompareMemoize(selectedCustomMetrics);

    const DATE_FORMAT = 'YYYY-MM-DD';
    const loadData = useCallback(
      async (abortSignal: AbortSignal, forceReload = false, parameters?: WillyParameter[]) => {
        if (initialRawData && !forceReload) {
          return;
        }
        if (loadInitialData) {
          return;
        }
        if (!currentDateRange) {
          return;
        }

        if (!activeAccounts) {
          return;
        }
        setLoading(true);

        generateInsightsStartedRef.current = false;
        setTextData('');

        let { start, end } = currentDateRange;
        try {
          let nlqResponses: NlqResponse[] = [];
          if (mode === 'builder' && builderSetup) {
            const shouldAddDate = !builderSetup.filters.some(
              (f) => f.column === 'event_date' && f.value,
            );
            const builderData: BuilderTable = {
              ...builderSetup,
              filters: [
                ...builderSetup.filters.filter((f) => f.visible || f.visibleInDashboard),
                ...(shouldAddDate
                  ? [
                      {
                        column: 'event_date',
                        operator: 'between',
                        value: [start.format(DATE_FORMAT), end.format(DATE_FORMAT)],
                      } as any,
                    ]
                  : []),
              ],
            };
            let res = await fetchQueryBuilderData(builderData, currentShopId);
            res = {
              ...res,
              queries: [
                {
                  id: queryId || '',
                  query: res.generatedQuery || '',
                  question: question,
                },
              ],
            };
            nlqResponses = [res];
          } else {
            nlqResponses = await fetchWidgetQueries({
              abortSignal,
              queryIds: memoizedQueriesToFetch,
              functionName,
              parameters: (parameters || memoizedParameters).filter((p) => !p.isQueryParameter),
              shopId: currentShopId,
              additionalShopIds: activeAccounts,
              start,
              end,
              currency,
              isDynamic,
              shouldReplaceTablesWithNlq,
              queryVars: queryVariablesForPopup,
              granularity,
              deselectedMetrics: memoizedDeselectedMetrics,
              // metrics: memoizedSelectedMetrics,
              selectedCustomMetrics: memoizedSelectedCustomMetrics,
              queryParameters: memoizedParameters.filter((p) => p.isQueryParameter),
              dataType,
            });
          }

          const errors = nlqResponses.filter((x) => !!x.error);

          if (errors?.length > 0) {
            const newErrors = errors.reduce((acc, e) => {
              if (!e.queryId) {
                return acc;
              }
              return {
                ...acc,
                [e.queryId]: e.error,
              };
            }, {});
            setErrorInQuery((prev) => {
              return {
                ...prev,
                ...newErrors,
              };
            });
          } else {
            setErrorInQuery((prev) => {
              return {
                ...prev,
                [queryId || '']: '',
              };
            });
          }

          if (codeResult?.pythonCode) {
            const stringifiedData = convertRawDataToJson(nlqResponses);
            const stringifiedSampleData = convertRawDataToJson(nlqResponses, 30);
            setCodeInterpreterInputData(stringifiedSampleData);

            const codeRes = await executeRce(
              abortSignal,
              parseCodeSnippet(codeResult.pythonCode),
              stringifiedData,
            );

            codeResultChanged?.(codeRes);

            const { filesOutput, executionError, output, dataFiles } = codeRes;
            if (executionError) {
              setErrorInQuery((prev) => {
                return {
                  ...prev,
                  [queryId || '']: executionError,
                };
              });
            }

            setRawData(filesOutput);
            if (filesOutput) {
              updateDashWidgetData?.(queryId!, filesOutput);
            }
          } else {
            setRawData(nlqResponses[0]);
            updateDashWidgetData?.(queryId!, nlqResponses[0]);
          }
          setLoading(false);
        } catch (error: any) {
          console.error('error', error);
          if (error.code !== 'ERR_CANCELED') {
            setLoading(false);
            updateDashWidgetData?.(queryId!, {
              question: question,
              error,
              data: [],
              dataColumns: { x: [], y: [] },
            });
            setErrorInQuery((prev) => {
              return {
                ...prev,
                [queryId || '']:
                  'We met an error while fetching the data, please contact support team.',
              };
            });
          }
        }
      },
      [
        memoizedQueriesToFetch,
        functionName,
        shouldReplaceTablesWithNlq,
        currency,
        currentShopId,
        queryId,
        currentDateRange,
        isDynamic,
        initialRawData,
        loadInitialData,
        memoizedParameters,
        activeAccounts,
        queryVariablesForPopup,
        mode,
        builderSetup,
        memoizedSelectedCustomMetrics,
        memoizedDeselectedMetrics,
        // memoizedSelectedMetrics,
        codeResult?.pythonCode,
        dataType,
        question,
        granularity,
        codeResultChanged,
        convertRawDataToJson,
        updateDashWidgetData,
      ],
    );

    useEffect(() => {
      setLoading(!!loadInitialData);
    }, [loadInitialData]);

    useEffect(() => {
      const controller = new AbortController();
      const signal = controller.signal;
      loadData(signal);

      return () => {
        controller.abort();
      };
    }, [loadData]);

    useEffect(() => {
      if (initialPreviousPeriodRawData) {
        setPreviousPeriodRawData(initialPreviousPeriodRawData);
      }
    }, [initialPreviousPeriodRawData]);

    const loadPreviousPeriodData = useCallback(
      async (
        abortSignal: AbortSignal,
        qId?: string,
        forceReload = false,
        parameters?: WillyParameter[],
      ) => {
        if (loadInitialData) {
          return;
        }
        if (initialRawData && !forceReload) {
          return;
        }
        if (initialPreviousPeriodRawData || loadInitialPreviousPeriodData) {
          return;
        }
        if (!queryId) {
          return;
        }
        if (!currentShopId) {
          return;
        }
        if (!isDynamic) {
          return;
        }
        if (!activeAccounts) {
          return;
        }

        const { start, end, id } = prevDateRange ?? {};

        if (id === 'none' || !end || !start) {
          return;
        }

        setLoadingPreviousPeriod(true);
        let queriesToFetch = memoizedQueriesToFetch;
        if (qId) {
          queriesToFetch = [...queriesToFetch, qId];
        }

        let nlqResponses: NlqResponse[] = [];

        if (mode === 'builder' && builderSetup) {
          const shouldAddDate = !builderSetup.filters.some(
            (f) => f.column === 'event_date' && f.value,
          );

          const builderData: BuilderTable = {
            ...builderSetup,
            filters: [
              ...builderSetup.filters,
              ...(shouldAddDate
                ? [
                    {
                      column: 'event_date',
                      operator: 'between',
                      value: [start.format(DATE_FORMAT), end.format(DATE_FORMAT)],
                    } as any,
                  ]
                : []),
            ],
          };
          const res = await fetchQueryBuilderData(builderData, currentShopId);
          nlqResponses = [res];
        } else {
          nlqResponses = await fetchWidgetQueries({
            abortSignal,
            queryIds: queriesToFetch,
            parameters: (parameters || memoizedParameters).filter((p) => !p.isQueryParameter),
            shopId: currentShopId,
            additionalShopIds: activeAccounts,
            start,
            end,
            currency,
            isDynamic,
            isPreviousPeriod: true,
            shouldReplaceTablesWithNlq,
            queryVars: queryVariablesForPopup,
            granularity,
            deselectedMetrics: memoizedDeselectedMetrics,
            // metrics: memoizedSelectedMetrics,
            // selectedCustomMetrics: memoizedSelectedCustomMetrics,
            queryParameters: memoizedParameters.filter((p) => p.isQueryParameter),
            dataType,
          });
        }

        setLoadingPreviousPeriod(false);

        if (codeResult?.pythonCode) {
          const stringifiedData = convertRawDataToJson(nlqResponses);
          const stringifiedSampleData = convertRawDataToJson(nlqResponses, 30);
          setCodeInterpreterInputData(stringifiedSampleData);

          const codeRes = await executeRce(
            abortSignal,
            parseCodeSnippet(codeResult.pythonCode),
            stringifiedData,
          );

          const { filesOutput } = codeRes;
          setPreviousPeriodRawData(filesOutput);
          return nlqResponses[0];
        } else {
          setPreviousPeriodRawData(nlqResponses[0]);
          return nlqResponses[0];
        }
      },
      [
        memoizedQueriesToFetch,
        shouldReplaceTablesWithNlq,
        currency,
        initialRawData,
        memoizedParameters,
        currentShopId,
        prevDateRange,
        queryId,
        isDynamic,
        activeAccounts,
        loadInitialData,
        queryVariablesForPopup,
        mode,
        builderSetup,
        // memoizedSelectedCustomMetrics,
        memoizedDeselectedMetrics,
        // memoizedSelectedMetrics,
        initialPreviousPeriodRawData,
        loadInitialPreviousPeriodData,
        dataType,
        codeResult?.pythonCode,
        granularity,
        convertRawDataToJson,
      ],
    );

    useEffect(() => {
      const controller = new AbortController();
      const signal = controller.signal;
      loadPreviousPeriodData(signal);

      return () => {
        controller.abort();
      };
    }, [loadPreviousPeriodData]);

    useEffect(() => {
      const acceptInsights = (msg: any) => {
        const { shopId, messageId, text } = msg;
        if (text === openAiStopSequence || messageId !== queryId || shopId !== currentShopId) {
          return;
        }

        setLoadingText(false);

        setTextData((oldText) => {
          if (!oldText) {
            return text;
          }
          return oldText + text;
        });
      };

      const socketIo = willySocket?.on('insight', acceptInsights);
      return () => {
        socketIo?.off('insight', acceptInsights);
      };
    }, [willySocket, currentShopId, queryId]);

    const containerClasses = useMemo(() => {
      const classes = [
        `flex flex-col flex-auto h-full max-w-full group dark:bg-transparent bg-white`,
      ];

      if (context !== 'chat') {
        classes.push('flex-shrink-0');
      }

      if (!loading) {
        classes.push('loaded');
      }

      if (type === 'table') {
        if (context !== 'chat') {
          classes.push('overflow-auto w-full h-auto');
        } else {
          classes.push('overflow-hidden w-full h-full');
        }
      } else {
        classes.push('overflow-auto');
      }

      if (context === 'summary') {
        if (type === 'pie') {
          classes.push('!h-[300px]');
        } else if (type == 'chart') {
          classes.push('!h-[400px]');
        } else {
          classes.push('!rounded shadow overflow-hidden');
        }
      } else if (context !== 'editor') {
        classes.push('sm:rounded shadow');
      }

      if (isDnd) {
        classes.push('!overflow-hidden');
      }

      return classes.join(' ');
    }, [context, isDnd, loading, type]);

    const visibleParameters = useMemo(() => {
      if (builderSetup) {
        return builderSetup.filters;
      }
      return memoizedParameters;
    }, [memoizedParameters, builderSetup]);

    return (
      <div ref={widgetContainerRef} className={containerClasses}>
        <div className={`pb-0 min-h-[20px] transition-[padding] h-full`}>
          {!hideHeader && (
            <div
              className={`
                w-full sticky top-0 flex flex-col justify-center z-[110] overflow-auto 
                flex-shrink-0 min-w-[150px] px-4 
                border-0 border-solid border-b-[1px] border-black/10 bg-white
              `}
            >
              <WillyWidgetHeader
                title={title}
                loading={loading}
                type={type}
                currency={currency}
                queryId={queryId}
                dashboard={dashboard}
                withoutMainQuery={withoutMainQuery}
                isGlobalDashboard={isGlobalDashboard}
                isCustomView={isCustomView}
                serviceIds={rawData?.serviceIds}
                parameters={parameters}
                isDynamic={isDynamic}
                parametersChanged={(p) => {
                  parametersChanged?.(p);
                }}
                rawData={rawData!}
                titleChanged={titleChanged}
                queriesChanged={queriesChanged}
                typeChanged={typeChanged}
                metrics={defaultMetrics}
                metricsQueries={metricsQueries}
                queries={queries}
                stacked={stacked}
                stackedChanged={stackedChanged}
                verified={verified}
                incrementedStacked={incrementedStacked}
                permissionChanged={permissionChanged}
                metricsChanged={onMetricsChanged}
                incrementedStackedChanged={incrementedStackedChanged}
                showPreviousPeriod={showPreviousPeriod}
                setShowPreviousPeriod={setShowPreviousPeriod}
                wrapText={wrapText}
                setWrapText={setWrapText}
                context={context}
                grid={grid}
                setGrid={setGrid}
                twoColumnMobile={twoColumnMobile}
                setTwoColumnMobile={setTwoColumnMobile}
                gridColumns={gridColumns}
                setGridColumns={setGridColumns}
                tileMode={tileMode}
                setTileMode={setTileMode}
                skinny={skinny}
                setSkinny={setSkinny}
                yAxisDomain={yAxisDomain}
                setYAxisDomain={setYAxisDomain}
                allowDataOverflow={allowDataOverflow}
                setAllowDataOverflow={setAllowDataOverflow}
                dimension={dimension}
                setDimension={setDimension}
                secondaryToolbarOpen={secondaryToolbarOpen}
                permission={permission}
                pinnedSection={pinnedSection}
                setHidePinnedSection={setHidePinnedSection}
                errorInQuery={errorInQuery}
                widgetMainResource={widgetMainResource}
                CDPSegmentId={CDPSegmentId}
                hasGlobalConditionalFormatting={hasGlobalConditionalFormatting}
                setHasGlobalConditionalFormatting={setHasGlobalConditionalFormatting}
                globalConditionalFormattingColor={globalConditionalFormattingColor}
                setGlobalConditionalFormattingColor={setGlobalConditionalFormattingColor}
                dataHasBreakdown={dataHasBreakdown}
                breakdownMode={breakdownMode}
                breakdownModeChanged={breakdownModeChanged}
                isDnd={isDnd}
                editLayout={editLayout}
                dataMetricIdsChanged={dataMetricIdsChanged}
                newMetricIds={newMetricIds}
                setNewMetricIds={setNewMetricIds}
                setWidgetEditorOpen={setWidgetEditorOpen}
                inWidgetEditor={inWidgetEditor}
                codeResult={codeResult}
                codeInterpreterInputData={codeInterpreterInputData}
                codeResultChanged={codeResultChanged}
                supportMetricsFromLibrary={supportMetricsFromLibrary}
                dataType={dataType}
                metricLibraryOpened={metricLibraryOpened}
                setMetricLibraryOpened={setMetricLibraryOpened}
                historicalQueryIds={historicalQueryIds}
                dialect={dialect}
                contextSourceIds={contextSourceIds}
                showQuickEditing={showQuickEditing}
                setShowQuickEditing={setShowQuickEditing}
                mode={mode}
                builderSetup={builderSetup}
                builderSetupChanged={builderSetupChanged}
                showFilters={filtersOpen}
                setShowFilters={(show) => setFiltersOpen(show)}
                inMobileDrawer={inMobileDrawer}
              />
            </div>
          )}
          {pinnedSection && !pinnedMetricKeys?.length && (
            <Flex w="100%" p="xl" direction="column" gap="xl" align="center" justify="center">
              <Text align="center" fw="bold">
                You can pin metrics from every section in this board. Click on the metric to pin it.
              </Text>
            </Flex>
          )}
          {!isBlocked && (
            <>
              {filtersOpen && (
                <div className="border-0 border-solid border-b-[1px] border-black/10 bg-white h-28 items-center flex">
                  <WillyDynamicFields
                    parameters={parameters || emptyArray<WillyParameter>()}
                    visibleParameters={visibleParameters.map((p) => p.column)}
                    query={rawData?.generatedQuery}
                    parametersChanged={async (params) => {
                      if (parametersChanged) {
                        await parametersChanged(params);
                        if (initialRawData) {
                          const controller = new AbortController();
                          const promises = [
                            loadData(controller.signal, true, params),
                            loadPreviousPeriodData(controller.signal, undefined, true, params),
                          ];
                          await Promise.all(promises);
                        }
                      }
                    }}
                  />
                </div>
              )}
              <div
                className={`w-[100vw] sm:w-auto ${type === 'tile' ? '' : 'translate-x-0'} ${
                  context === 'summary' && type === 'chart'
                    ? isDnd
                      ? '!min-h-[350px]'
                      : '!min-h-[300px]'
                    : ''
                } ${tileMode === 'table' ? '!block' : ''} ${
                  type === 'table'
                    ? filtersOpen
                      ? 'h-[calc(100%-114px)]'
                      : 'h-[calc(100%-43px)]'
                    : type === 'chart' || type === 'funnel'
                      ? filtersOpen
                        ? 'h-[calc(100%-111px)]'
                        : 'h-[calc(100%-43px)]'
                      : type === 'pie'
                        ? `h-[300px] ${isDnd ? '' : 'h-300'}`
                        : 'h-[calc(100%-43px)]'
                }`}
              >
                <>
                  {!defaultMetrics?.length && withoutMainQuery ? (
                    <WillyEmptyMarkup
                      onClickNoCode={() => {
                        setMetricLibraryOpened(true);
                      }}
                      onClickChat={() => openChatWithQuery?.(queryId!)}
                      onClickSql={() => openSqlWithWidget?.(queryId!)}
                      widgetId={queryId}
                      dashboard={dashboard}
                    />
                  ) : type === 'chart' || type === 'pie' || type === 'funnel' ? (
                    <WillyChart
                      queryId={queryId!}
                      rawData={rawNlqData}
                      errorInQuery={errorInQuery}
                      previousPeriodData={previousPeriodRawNlqData}
                      dataColumns={rawDataColumns}
                      serviceIds={rawData?.serviceIds}
                      loading={loading}
                      loadingPreviousPeriod={loadingPreviousPeriod}
                      context={context}
                      currency={currency}
                      metrics={defaultMetrics}
                      incrementedStacked={incrementedStacked}
                      stacked={stacked}
                      metricsChanged={onMetricsChanged}
                      setEditMetricModalOpen={setEditMetricModalOpen}
                      type={type}
                      showPreviousPeriod={showPreviousPeriod}
                      skinny={skinny}
                      yAxisDomain={yAxisDomain}
                      allowDataOverflow={allowDataOverflow}
                      dimension={dimension}
                      dashboard={dashboard}
                      allowLegendDnd={!!inWidgetEditor}
                      showQuickEdit={showQuickEditing || inWidgetEditor}
                    />
                  ) : type === 'table' ? (
                    <WillyTable
                      rawData={rawNlqData}
                      dataColumns={rawDataColumns}
                      errorInQuery={errorInQuery}
                      queryId={queryId!}
                      currency={currency}
                      query={rawData?.generatedQuery}
                      previousPeriodData={previousPeriodRawNlqData}
                      loading={loading}
                      loadingPreviousPeriod={loadingPreviousPeriod}
                      context={context}
                      metrics={defaultMetrics}
                      metricsChanged={onMetricsChanged}
                      setEditMetricModalOpen={setEditMetricModalOpen}
                      wrapText={wrapText}
                      loadPreviousPeriodData={loadPreviousPeriodData}
                      onMetricClicked={metricClicked}
                      hasGlobalConditionalFormatting={hasGlobalConditionalFormatting}
                      globalConditionalFormattingColor={globalConditionalFormattingColor}
                      breakdownMode={breakdownMode}
                      widgetDialect={dialect}
                      parameters={memoizedParameters}
                    />
                  ) : type === 'summaryBox' || type === 'tile' ? (
                    <WillySingleValue
                      data={dataWithMetricsData}
                      previousPeriodData={previousPeriodDataWithMetricsData}
                      errorMetricsData={errorMetricsData}
                      errorInQuery={errorInQuery}
                      loadingMetricsData={loadingMetricsData}
                      queryId={queryId!}
                      currency={currency}
                      serviceIds={rawData?.serviceIds}
                      loading={loading}
                      loadingPreviousPeriod={loadingPreviousPeriod}
                      metrics={defaultMetrics}
                      metricsChanged={onMetricsChanged}
                      setEditMetricModalOpen={setEditMetricModalOpen}
                      onMetricClicked={metricClicked}
                      grid={grid}
                      twoColumnMobile={twoColumnMobile}
                      gridColumns={gridColumns}
                      tileMode={tileMode}
                      dashboardId={dashboard?.id}
                      pinnedMetricKeys={pinnedMetricKeys}
                      isPinnable={dashboard?.isCustomView}
                    />
                  ) : type === 'text' ? (
                    <div className="p-2 md:p-4">
                      <WillySimpleText
                        text={textData!}
                        loading={loading}
                        loadingText={loadingText}
                      />
                    </div>
                  ) : type === 'map' ? (
                    <Suspense>
                      <WillyMap
                        resData={rawData!}
                        showAsWidget
                        question={rawData?.question}
                        loading={loading}
                      />
                    </Suspense>
                  ) : type === 'json' ? (
                    <WillyJson nlqData={rawData!} />
                  ) : null}

                  {singleValuePopupOpened && !!queryId && (
                    <WillySingleValueChart
                      onClose={closeSingleValuePopup}
                      queryId={queryId}
                      metric={activeMetric!}
                      metrics={defaultMetrics}
                      metricsChanged={onMetricsChanged}
                      totalValue={totalValue}
                      breakDownValue={breakdownValue}
                      previousPeriodTotalValue={previousPeriodTotalValue}
                      previousPeriodBreakDownValue={previousPeriodBreakDownValue}
                      loadingPreviousPeriod={loadingPreviousPeriod}
                      currency={currency}
                      metricData={activeMetricData}
                      type={type}
                    />
                  )}

                  {activeMetricCustomComponent}
                </>
              </div>
            </>
          )}
          {isBlocked && (
            <Flex w={'100%'} h={'100%'} justify={'center'} align={'center'}>
              <LockedFeatureIndicator
                featureFlag={FeatureFlag.LIMIT_INTEGRATIONS_FF}
                forceShow
                useDefaultText
                layout="vertical"
                upgradeButton={true}
                border={true}
              />
            </Flex>
          )}
        </div>

        <WillyWidgetEditor
          open={widgetEditorOpen}
          setOpen={setWidgetEditorOpen}
          permission={permission}
          permissionChanged={permissionChanged}
          isDynamic={isDynamic}
          //ref={ref}
          currency={currency}
          context={context}
          titleChanged={titleChanged}
          typeChanged={typeChanged}
          question={question}
          queryId={queryId}
          title={title}
          type={type}
          stacked={stacked}
          parameters={memoizedParameters}
          parametersChanged={parametersChanged}
          incrementedStacked={incrementedStacked}
          stackedChanged={stackedChanged}
          incrementedStackedChanged={incrementedStackedChanged}
          metrics={defaultMetrics}
          metricsChanged={onMetricsChanged}
          queries={queries}
          initialRawData={rawData}
          wrapText={wrapText}
          setWrapText={setWrapText}
          dashboard={dashboard}
          //setEditMetricModalOpen={setEditMetricModalOpen}
          // autoHeight={true}
          grid={grid}
          setGrid={setGrid}
          gridColumns={gridColumns}
          setGridColumns={setGridColumns}
          twoColumnMobile={twoColumnMobile}
          setTwoColumnMobile={setTwoColumnMobile}
          tileMode={tileMode}
          setTileMode={setTileMode}
          skinny={skinny}
          setSkinny={setSkinny}
          yAxisDomain={yAxisDomain}
          setYAxisDomain={setYAxisDomain}
          allowDataOverflow={allowDataOverflow}
          setAllowDataOverflow={setAllowDataOverflow}
          dimension={dimension}
          setDimension={setDimension}
          dataMetricIds={dataMetricIds}
          dataMetricIdsChanged={dataMetricIdsChanged}
          CDPSegmentId={CDPSegmentId}
          globalConditionalFormattingColor={globalConditionalFormattingColor}
          hasGlobalConditionalFormatting={hasGlobalConditionalFormatting}
          setHasGlobalConditionalFormatting={setHasGlobalConditionalFormatting}
          setGlobalConditionalFormattingColor={setGlobalConditionalFormattingColor}
          breakdownMode={breakdownMode}
          breakdownModeChanged={breakdownModeChanged}
          queriesChanged={queriesChanged}
          dataHasBreakdown={dataHasBreakdown}
          showPreviousPeriod={showPreviousPeriod}
          setShowPreviousPeriod={setShowPreviousPeriod}
          initialPreviousPeriodRawData={reorderedPreviousPeriodData}
          loadInitialPreviousPeriodData={loadingPreviousPeriod}
          dialect={dialect}
          builderSetupChanged={builderSetupChanged}
          mode={mode}
          builderSetup={builderSetup}
          filtersOpen={filtersOpen}
          setFiltersOpen={setFiltersOpen}
        />
      </div>
    );
  },
);
