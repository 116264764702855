import { Popover } from '@shopify/polaris';
import { useState, useEffect } from 'react';

const RecipientsTooltip = ({ emails }) => (
  <div>
    <div className="opacity-60 mb-[2px] text-[14px]">Recipients:</div>
    {emails.map((email, i) => (
      <div key={i} className="text-[14px]">
        {email}
      </div>
    ))}
  </div>
);

interface EmailProps {
  emails: string[];
}

export const RecipientsList: React.FC<EmailProps> = ({ emails }) => {
  const [displayEmails, setDisplayEmails] = useState('');
  const [numberOfOverflowEmails, setNumberOfOverflowEmails] = useState(0);
  const [recipientsListOpen, setRecipientsListOpen] = useState(false);

  useEffect(() => {
    const div = document.querySelector('.email-list') as HTMLDivElement;
    const width = div.offsetWidth;
    let totalWidth = 0;
    let displayEmails: string[] = [];
    let isEmailCropped = false;

    for (const email of emails) {
      const emailSpan = document.createElement('span');
      emailSpan.textContent = email + ' ,';
      document.body.appendChild(emailSpan);
      const emailWidth = emailSpan.offsetWidth;

      try {
        document.body.removeChild(emailSpan);
      } catch {}

      if (totalWidth + emailWidth > width) {
        const ellipsisSpan = document.createElement('span');
        const overflow = emails.length - displayEmails.length;
        ellipsisSpan.textContent = `... +${overflow}`;
        document.body.appendChild(ellipsisSpan);
        const ellipsisWidth = ellipsisSpan.offsetWidth;
        try {
          document.body.removeChild(ellipsisSpan);
        } catch {}
        const charsToDisplay = Math.floor(
          (width - totalWidth - ellipsisWidth) / (emailWidth / email.length),
        );
        displayEmails.push(email.slice(0, charsToDisplay) + '...');
        isEmailCropped = true;
        break;
      }

      displayEmails.push(email);
      totalWidth += emailWidth;
    }

    const overflow = emails.length - displayEmails.length + (isEmailCropped ? 1 : 0);
    setNumberOfOverflowEmails(overflow);
    // const overFlowElement = overflow > 0 ? <span className='text-[#1877F2]'>+${overflow}</span> : '';
    setDisplayEmails(displayEmails.join(', '));
  }, [emails]);

  return (
    <div className="email-list flex items-center gap-2">
      {displayEmails}
      {numberOfOverflowEmails > 0 && (
        <Popover
          sectioned
          active={recipientsListOpen}
          onClose={() => setRecipientsListOpen(false)}
          activator={
            <span
              className="text-[#1877F2] cursor-pointer"
              onClick={() => {
                setRecipientsListOpen((x) => !x);
              }}
            >
              +{numberOfOverflowEmails}
            </span>
          }
        >
          <RecipientsTooltip emails={emails} />
        </Popover>
      )}
    </div>
  );
};
