import { useMemo } from 'react';
import { useRouteMatch } from 'react-router';
import { SourceTypesArr, SourceTypesWithExtra } from 'types/services';

export const useAttributionActivePage = () => {
  const match = useRouteMatch<{ channelId: string }>([
    '/attribution/**/:channelId',
    '/dashboards/attribution/**/:channelId',
  ]);

  const attributionPage: SourceTypesWithExtra | '' = useMemo(() => {
    if (!match) {
      return '';
    }

    const { url } = match;
    const pathParts = url.split('/');
    const page = pathParts[pathParts.length - 2] as SourceTypesWithExtra;

    if (!SourceTypesArr.includes(page as any)) {
      return '';
    }

    return page;
  }, [match]);

  return attributionPage;
};
