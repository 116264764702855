import { Button, Flex, Modal, MultiSelect, Select, isDefined } from '@tw/ui-components';
import { MessageTypes, WillyMetric, WillyWidgetElement } from '../types/willyTypes';
import { FC, useCallback, useMemo, useState } from 'react';
import { MetricEditor } from './MetricEditor';
import { DndContext, DragOverlay, closestCenter, useDroppable } from '@dnd-kit/core';
import { useWillyDndMetrics } from '../hooks/useWillyDndMetrics';
import { SortableContext, rectSortingStrategy } from '@dnd-kit/sortable';
import { Portal } from 'components/Portal';
import { EditorCollapse } from './EditorCollapse';
import { WillyMetricUrls } from '../WillyMetricUrls';
import ReactSyntaxHighlighter from 'react-syntax-highlighter';
import { vscDarkPlus } from 'react-syntax-highlighter/dist/esm/styles/prism';
import { WillyCustomQueryModal } from '../WillyCustomQueryModal';
import { capitalize } from 'lodash';
import { formatSqlSafely, keyIsSomeDate, saveNewQuery } from '../utils/willyUtils';
import { useAppSelector } from 'reducers/RootType';
import { useSelector } from 'react-redux';
import { $activeAccounts } from '../../../$stores/$shop';
import { $dialect } from '$stores/$user';
import { useStoreValue } from '@tw/snipestate';

type MetricsEditorProps = {
  metrics: WillyMetric[];
  updateMetrics: (id: string, data: WillyMetric[]) => void;
  queryId?: string;
  customize?: boolean;
  widgetType: MessageTypes;
  stacked?: boolean;
  setStacked?: (stacked: boolean) => void;
};

export const onClickMetricActionsOptions = [
  { label: 'Do Nothing', value: 'none' },
  { label: 'Run a Query', value: 'query' },
  { label: 'Open a Dashboard', value: 'url' },
];

const orientations = ['left', 'right'] as const;

export const MetricsEditor: React.FC<MetricsEditorProps> = ({
  metrics,
  updateMetrics,
  queryId,
  customize,
  widgetType,
  stacked,
  setStacked,
}) => {
  const [addActionToMetric, setAddActionToMetric] = useState<WillyMetric | null>(null);
  const [customQueryModalOpened, setCustomQueryModalOpened] = useState(false);
  const [errorSavingQuery, setErrorSavingQuery] = useState('');

  const currency = useAppSelector((state) => state.activeCurrency);
  const shopTimezone = useAppSelector((state) => state.shopTimezone);
  const currentShopId = useAppSelector((state) => state.currentShopId);
  const activeAccounts = useStoreValue($activeAccounts);
  const dialect = useStoreValue($dialect);

  const { sensors, handleDragStart, handleDragEnd, handleDragOver, activeId } = useWillyDndMetrics({
    queryId,
    metrics,
    metricsChanged: async (id: string, data: WillyMetric[]) => {
      await updateMetrics(id, data);
    },
  });
  const activeMetrics = useMemo(() => metrics.filter((m) => m.active), [metrics]);
  const updateMetric = useCallback(
    (metric: WillyMetric) => {
      if (!queryId) {
        return;
      }
      const newMetrics = metrics.map((m) => {
        if (m.key === metric.key) {
          return metric;
        }
        return m;
      });
      updateMetrics(queryId, newMetrics);
    },
    [metrics, queryId, updateMetrics],
  );

  const metricsForPossibleParameters = useMemo(() => {
    return metrics.filter((x) => x.isDimension).filter((x) => !keyIsSomeDate(x.key));
  }, [metrics]);

  const formattedSql = useMemo(() => {
    if (!addActionToMetric) {
      return '';
    }
    let queryToFormat = addActionToMetric.popupWidget?.queries?.[0]?.query || '';
    try {
      return formatSqlSafely(queryToFormat);
    } catch (e) {
      return queryToFormat;
    }
  }, [addActionToMetric]);

  const saveNewQueryFromPopup = useCallback(
    async (
      query: Omit<WillyWidgetElement, 'queryId'>,
      variables?: Record<string, any> | undefined,
    ) => {
      if (!activeAccounts || !dialect) {
        setErrorSavingQuery(`Some data didn't load yet, please try again later`);
        return false;
      }
      const res = await saveNewQuery({
        widgetWithoutQuery: query,
        metric: addActionToMetric!,
        shopId: currentShopId,
        timezone: shopTimezone,
        currency,
        activeAccounts,
        variables,
        dialect,
      });
      if (res.error) {
        setErrorSavingQuery(res.error);
        return false;
      } else if (res.metric) {
        setAddActionToMetric(res.metric);
        setErrorSavingQuery('');
        return true;
      } else {
        setErrorSavingQuery('Unknown error');
        return false;
      }
    },
    [activeAccounts, currency, currentShopId, addActionToMetric, shopTimezone, dialect],
  );

  const metricsEditorElement = useMemo(
    () => (
      <Flex direction="column">
        <DndContext
          id={`willy-editor-${queryId}`}
          onDragEnd={handleDragEnd}
          sensors={sensors}
          collisionDetection={closestCenter}
          onDragOver={handleDragOver}
          onDragStart={handleDragStart}
          autoScroll={{ canScroll: () => false }}
        >
          {widgetType === 'chart' ? (
            <Flex direction="column" gap="md" pt="sm">
              {orientations.map((orientation) => {
                return (
                  <MetricsGroupEditor
                    key={orientation}
                    orientation={orientation}
                    metrics={activeMetrics.filter((m) => {
                      if (orientation === 'right') {
                        return m.yAxisId === 'right';
                      }
                      return m.yAxisId === 'left' || !m.yAxisId;
                    })}
                    updateMetric={updateMetric}
                    customize={customize}
                    setAddActionToMetric={setAddActionToMetric}
                    widgetType={widgetType}
                    stacked={stacked}
                    setStacked={setStacked}
                  />
                );
              })}
            </Flex>
          ) : (
            <SortableContext
              items={activeMetrics?.map((x) => x.key) ?? []}
              strategy={rectSortingStrategy}
            >
              {activeMetrics.map((metric) => (
                <Flex direction="column" gap="sm" pt="sm" key={metric.key}>
                  <MetricEditor
                    key={metric.key}
                    metric={metric}
                    customize={customize}
                    updateMetric={updateMetric}
                    setAddActionToMetric={setAddActionToMetric}
                    widgetType={widgetType}
                  />
                </Flex>
              ))}
            </SortableContext>
          )}

          <Portal>
            <DragOverlay>
              {activeId ? (
                <MetricEditor
                  metric={metrics.find((x) => x.key === activeId)!}
                  updateMetric={() => {}}
                  customize={customize}
                  setAddActionToMetric={setAddActionToMetric}
                />
              ) : null}
            </DragOverlay>
          </Portal>
        </DndContext>
        <Modal
          opened={!!addActionToMetric}
          onClose={() => setAddActionToMetric(null)}
          title="Action"
        >
          {addActionToMetric ? (
            <Flex direction="column" gap="sm">
              <Select
                label="When I Click this metric I want to:"
                allowDeselect={false}
                value={addActionToMetric.onClickAction || 'none'}
                data={onClickMetricActionsOptions}
                onChange={(v) => {
                  if (!isDefined(v)) return;
                  setAddActionToMetric({
                    ...addActionToMetric,
                    onClickAction: v as 'none' | 'query' | 'url',
                  });
                }}
              />
              {addActionToMetric.onClickAction === 'url' && (
                <WillyMetricUrls metric={addActionToMetric} onChange={setAddActionToMetric} />
              )}
              {addActionToMetric.onClickAction === 'query' && (
                <div className="prose dark:prose-invert flex flex-col gap-4">
                  {addActionToMetric.popupWidget?.queries?.length && (
                    <div>
                      <ReactSyntaxHighlighter language={'sql'} style={vscDarkPlus} showLineNumbers>
                        {formattedSql}
                      </ReactSyntaxHighlighter>
                    </div>
                  )}
                  <div className="flex items-center gap-4">
                    {addActionToMetric.popupWidget?.queries?.length && (
                      <Button
                        variant="white"
                        onClick={async () => {
                          const confirmed = confirm('Are you sure you want to delete this query?');
                          if (confirmed) {
                            setAddActionToMetric({
                              ...addActionToMetric,
                              popupWidget: null,
                            });
                          }
                        }}
                      >
                        Delete Query
                      </Button>
                    )}
                    {metricsForPossibleParameters.length > 0 && (
                      <MultiSelect
                        size="sm"
                        placeholder="Pass parameters to the query"
                        value={addActionToMetric.queryVars || []}
                        onChange={async (value) => {
                          ({ ...addActionToMetric, queryVars: value });
                        }}
                        data={metricsForPossibleParameters.map((x) => {
                          return {
                            value: x.key,
                            label: x.key,
                          };
                        })}
                      />
                    )}
                    <Button
                      variant="primary"
                      onClick={() => {
                        setCustomQueryModalOpened(true);
                      }}
                    >
                      {!!addActionToMetric.popupWidget ? 'Edit Query' : 'Add Query'}
                    </Button>
                  </div>
                </div>
              )}
            </Flex>
          ) : null}
          <Modal.Footer>
            <Flex justify="end" gap="md">
              <Button onClick={() => setAddActionToMetric(null)} variant="activator">
                Cancel
              </Button>
              <Button
                variant="primary"
                onClick={() => {
                  if (!addActionToMetric) return;
                  updateMetric(addActionToMetric);
                  setAddActionToMetric(null);
                }}
              >
                Save
              </Button>
            </Flex>
          </Modal.Footer>
        </Modal>
        {!!addActionToMetric && (
          <WillyCustomQueryModal
            metric={addActionToMetric}
            opened={customQueryModalOpened}
            onClose={() => setCustomQueryModalOpened(false)}
            querySaved={saveNewQueryFromPopup}
            errorSavingQuery={errorSavingQuery}
            queryVars={addActionToMetric.queryVars}
          />
        )}
      </Flex>
    ),
    [
      activeId,
      activeMetrics,
      addActionToMetric,
      customQueryModalOpened,
      customize,
      errorSavingQuery,
      formattedSql,
      handleDragEnd,
      handleDragOver,
      handleDragStart,
      metrics,
      metricsForPossibleParameters,
      queryId,
      saveNewQueryFromPopup,
      sensors,
      setStacked,
      stacked,
      updateMetric,
      widgetType,
    ],
  );

  return !customize ? (
    <Flex direction="column">{metricsEditorElement}</Flex>
  ) : (
    <EditorCollapse title="Customize Metrics" initialOpened={!customize} px="md">
      {metricsEditorElement}
    </EditorCollapse>
  );
};

type MetricsGroupEditorProps = {
  orientation: (typeof orientations)[number];
  metrics: WillyMetric[];
  updateMetric: (metric: WillyMetric) => void;
  customize?: boolean;
  setAddActionToMetric: (metric: WillyMetric | null) => void;
  widgetType?: MessageTypes;
  stacked?: boolean;
  setStacked?: (stacked: boolean) => void;
};

const MetricsGroupEditor: FC<MetricsGroupEditorProps> = ({
  orientation,
  metrics,
  updateMetric,
  customize,
  setAddActionToMetric,
  widgetType,
  stacked,
  setStacked,
}) => {
  const { setNodeRef } = useDroppable({ id: orientation });

  return (
    <EditorCollapse title={`${capitalize(orientation)} Y-Axis`} initialOpened>
      <div ref={setNodeRef}>
        <SortableContext
          id={orientation}
          items={metrics.map((x) => x.key)}
          strategy={rectSortingStrategy}
        >
          <Flex direction="column" gap="md" pt="sm">
            {metrics.map((metric) => (
              <MetricEditor
                key={metric.key}
                metric={metric}
                customize={customize}
                updateMetric={updateMetric}
                setAddActionToMetric={setAddActionToMetric}
                widgetType={widgetType}
                stacked={stacked}
                setStacked={setStacked}
              />
            ))}
          </Flex>
        </SortableContext>
      </div>
    </EditorCollapse>
  );
};
