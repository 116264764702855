import { Button, OptionList, Popover, Select } from '@shopify/polaris';
import { FREQUENCY, Frequency, amPm, dailyHoursOptions } from './utils';

type ReportFrequencyProps = {
  popoverActive: boolean;
  frequency: Frequency;
  selectedHours: number[];
  onSelectFrequency: (frequency: Frequency) => void;
  togglePopoverActive: () => void;
  onSelectHour: (hours: number[]) => void;
};
export const ReportFrequency: React.FC<ReportFrequencyProps> = ({
  popoverActive,
  frequency,
  selectedHours,
  onSelectFrequency,
  togglePopoverActive,
  onSelectHour,
}) => {
  return (
    <div className="grid gap-x-4 gap-y-0 lg:w-6/12" style={{ gridTemplateColumns: '1fr 1fr' }}>
      <div className="text-[12px] font-medium">Report Frequency</div>
      <div className="text-[12px] font-medium">Time of Day</div>
      <div>
        <Select
          labelInline
          label=""
          options={FREQUENCY}
          value={frequency}
          onChange={onSelectFrequency}
        />
      </div>
      <div className="frequency-timing">
        {frequency === 'hour' ? (
          <Popover
            active={popoverActive}
            activator={
              <Button onClick={togglePopoverActive} disclosure>
                Options
              </Button>
            }
            onClose={togglePopoverActive}
          >
            <OptionList
              options={Array.from({ length: 24 }, (_, index) => ({
                label: amPm(index),
                value: '' + index,
              })).map(({ label, value }) => ({
                label,
                value,
              }))}
              onChange={(hours) => onSelectHour(hours.map(Number))}
              selected={selectedHours?.map((h) => h.toString())}
              allowMultiple
            />
          </Popover>
        ) : (
          <Select
            label=""
            options={dailyHoursOptions.map((hour) => ({
              label: amPm(hour),
              value: hour + '',
            }))}
            onChange={(hour) => onSelectHour([Number(hour)])}
            value={selectedHours[0]?.toString()}
          ></Select>
        )}
      </div>
    </div>
  );
};
