import { ActionIcon, Flex, StoresNav as StyledStoresNav, Tooltip } from '@tw/ui-components';
import { switchStore } from 'ducks/auth';
import { useAppDispatch } from 'index';
import { useEffect, useRef } from 'react';
import classes from './StoresNav.module.scss';
import { AddShopPopover } from './AddShopPopover';
import { resetStats } from 'ducks/newStats';
import { SearchStorePopover } from '../../blocks/SearchStorePopover';
import { $storesNavWidthPref } from 'components/Nav/stores';
import { useStoresNav } from './useStoresNav';
import { TOP_BAR_BORDER_VAR } from 'components/TopBar/constants';
import Cookies from 'utils/Cookies';
import { $currentShopImage } from '$stores/$currentShopImage';
import { useStoreValue } from '@tw/snipestate';

const EXPANDED_WIDTH = 50;

export const StoresNav = () => {
  const activeShopImageRef = useRef<HTMLImageElement>(document.createElement('img'));
  const currentShopImage = useStoreValue($currentShopImage);
  const storesNavWidth = useStoreValue($storesNavWidthPref);
  const isOpen = storesNavWidth === EXPANDED_WIDTH;
  const { handleShopsOrderChanged, shops } = useStoresNav();
  const dispatch = useAppDispatch();

  const toggleWidth = () => {
    $storesNavWidthPref.set((prev) => (prev === EXPANDED_WIDTH ? 0 : EXPANDED_WIDTH));
  };

  useEffect(() => {
    activeShopImageRef.current.src = currentShopImage;
  }, [currentShopImage]);

  return (
    // Need outer wrapper to allow correct width when overflow: hidden;
    <div className="resizable-wrapper relative max-w-[50px]" style={{ width: storesNavWidth }}>
      <div className={classes.wrapper}>
        <div className={classes.addStoreIcon}>
          <Flex h={54} justify="center" align="center" w="100%" borderBottom={TOP_BAR_BORDER_VAR}>
            <SearchStorePopover />
          </Flex>
          <Tooltip label="Pods View">
            <div>
              <ActionIcon
                variant="activator"
                radius="sm"
                size="lg"
                icon="pods-view"
                onClick={() => {
                  // don't do updateCurrentShopId here, since ShopRoute listens to it and causes a double reload
                  console.log('clearing current shop id');
                  Cookies.remove('currentShopId');
                  window.location.replace('/pods-view');
                }}
              />
            </div>
          </Tooltip>
          <AddShopPopover />
        </div>

        <StyledStoresNav
          activeShopImageRef={activeShopImageRef}
          shops={shops}
          onStoreClicked={(shopId) => {
            dispatch(resetStats());
            dispatch(switchStore(shopId, true));
          }}
          onDragEnd={handleShopsOrderChanged}
        />

        <div
          onClick={toggleWidth}
          className={`border-0 border-solid
              absolute top-0 -right-[8px]
              h-full w-[10px] cursor-pointer z-[100000]
              flex flex-col justify-center items-center`}
        >
          <Tooltip label={(isOpen ? 'Collapse' : 'Expand') + ' stores side nav'}>
            <span className="w-[2px] h-[35px] rounded-sm relative -top-[30px] -right-[5px] bg-[var(--mantine-color-one-5)]" />
          </Tooltip>
        </div>
      </div>
    </div>
  );
};
