import './billing.scss';
import { useAppDispatch } from 'index';
import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { components } from 'react-select';
import { toast } from 'react-toastify';
import { Stripe } from 'stripe';
import { Button, Checkbox, Icon, Modal, Spinner, Tooltip } from '@shopify/polaris';
import { BankMajor, InfoMinor } from '@shopify/polaris-icons';
import { PaymentElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { setPaymentMethod, setPaymentMethodId } from '../../../ducks/signupJourney';
import { type RootState } from '../../../reducers/RootType';
import axiosInstance from '../../../utils/axiosInstance';
import PMSelectSettings from './PMSelectSettings';
import { getShopFeatures } from 'ducks/shop';

type AppProps = {
  setIsModalOpen: Dispatch<SetStateAction<boolean>>;
  isOpen: boolean;
  isUnpaid?: boolean;
};

const CardOptionTemplate = (props) => (
  <div className={'flex-container card-select-settings-dropdown'}>
    {props.data.type === 'card' && (
      <>
        <img
          className={'card-icon-template-settings'}
          src={`/signupJourney/cardIcons/${props.data?.card?.brand}.svg`}
          alt=""
        />
        <div className={'digit4-settings'}> {` **** ${props.data?.card?.last4}`}</div>
        <div className={'exp-settings'}>
          {' '}
          {`EXP. ${props.data?.card?.exp_month}/ ${props.data?.card?.exp_year}`}
        </div>
      </>
    )}
    {props.data.type === 'us_bank_account' && (
      <>
        <div className={'card-icon-template-settings'}>
          <Icon source={BankMajor} color="subdued" />
        </div>
        <div className={'digit4-settings'}> {` **** ${props.data?.us_bank_account?.last4}`}</div>
        <div className={'exp-settings'}> {` ${props.data?.us_bank_account?.bank_name}`}</div>
      </>
    )}
  </div>
);

const { Option, SingleValue } = components;
const IconOption = (props) => (
  <Option {...props}>
    <CardOptionTemplate {...props} />
  </Option>
);
const customSingleValue = (props) => (
  <SingleValue {...props}>
    <CardOptionTemplate {...props} />
  </SingleValue>
);

const ChangePaymentMethodModal: React.FC<AppProps> = ({ setIsModalOpen, isOpen, isUnpaid }) => {
  const stripe = useStripe();
  const elements = useElements();
  const dispatch = useAppDispatch();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isLoadingPage, setIsLoadingPage] = useState<boolean>(true);
  const [customerPaymentsMethod, setCustomerPaymentsMethod] = useState<Stripe.PaymentMethod[]>([]);
  const [paymentInfoComplete, setPaymentInfoComplete] = useState<boolean>(false);
  const [newSelected, setNewSelected] = useState<boolean>(true);
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState<Stripe.PaymentMethod>(
    null as any,
  );
  const currentShop = useSelector((state: RootState) => state.currentShop);
  const subscription = useSelector((state: RootState) => state.subscription);

  useEffect(() => {
    if (!currentShop?.customerId) return;
    const getPaymentMethodeById = async () => {
      setIsLoadingPage(true);
      try {
        const url = `v2/subscription-manager/payments/customer/${currentShop.customerId}`;
        const response = await axiosInstance.get<Stripe.PaymentMethod[]>(url);
        if (response.status < 400 && response.data.length > 0) {
          setCustomerPaymentsMethod(response.data);
          setSelectedPaymentMethod(response.data[0]);
          setNewSelected(false);
        }
      } catch (e) {
        console.error(`error getting customer payments`);
      }
      setIsLoadingPage(false);
    };
    getPaymentMethodeById();
  }, [currentShop?.customerId]);

  const stripeElementChange = (e: any) => {
    setPaymentInfoComplete(!e.empty && e.complete);
  };

  const handleSubmit = async () => {
    try {
      if (newSelected && paymentInfoComplete) {
        if (!stripe || !elements) {
          toast.error('Something went wrong');
          return;
        }
        setIsLoading(true);
        const { error, setupIntent }: any = await stripe.confirmSetup({
          elements,
          confirmParams: {
            return_url: `${window.location.origin}`,
          },
          redirect: 'if_required',
        });
        if (error) {
          console.log(error);
          setIsLoading(false);
          toast.error(`${error.message}`);
        } else if (setupIntent && setupIntent?.status === 'succeeded') {
          const url = `/v2/subscription-manager/subscriptions/${subscription.subscription}`;
          const response = await axiosInstance.patch(url, {
            subscribe: [],
            unsubscribe: [],
            paymentMethodId: setupIntent.payment_method,
          });

          if (!isUnpaid) {
            dispatch(getShopFeatures(currentShop.shopId));
            // dispatch(setPaymentMethod(res.paymentMethod as Stripe.PaymentMethod));
            dispatch(setPaymentMethod({ id: setupIntent.payment_method } as Stripe.PaymentMethod));
            dispatch(setPaymentMethodId(setupIntent.payment_method));
            toast.success('Your payment method was updated successfully!!');
            setIsLoading(false);
            setIsModalOpen(false);
          } else if (isUnpaid) {
            await handleUnpaidSubscription(setupIntent.payment_method);
          }
        }
      } else {
        setIsLoading(true);
        const url = `/v2/subscription-manager/subscriptions/${subscription.subscription}`;
        const response = await axiosInstance.patch(url, {
          subscribe: [],
          unsubscribe: [],
          paymentMethodId: selectedPaymentMethod.id,
        });

        if (!isUnpaid) {
          dispatch(setPaymentMethodId(selectedPaymentMethod.id));
          dispatch(setPaymentMethod(selectedPaymentMethod));
          toast.success('Your payment method was successfully updated!!');
          setIsLoading(false);
          setIsModalOpen(false);
        }

        if (isUnpaid) {
          await handleUnpaidSubscription(selectedPaymentMethod.id);
        }
      }
    } catch (error) {
      setIsLoading(false);
      console.log({ error });

      toast.error(
        `An error occured while trying to update you payment method - ${
          error.message || ' please try again soon or contact Customer Success'
        }`,
      );
    } finally {
      setIsLoading(false);
      dispatch(getShopFeatures(currentShop.shopId));
    }
  };

  const handleUnpaidSubscription = async (newPM) => {
    try {
      const res: any = await chargeOpenInvoice(newPM);
      if (res?.data?.status === 'succeeded') {
        await resetBillingCycle();
      }
    } catch (err) {
      console.log(err);
    } finally {
      dispatch(getShopFeatures(currentShop.shopId));
    }
  };

  const chargeOpenInvoice = async (newPM) => {
    try {
      if (subscription?.payment_intent && newPM) {
        const url = `/v2/subscription-manager/invoices/recharge-open`;
        const res = await axiosInstance.post(url, {
          paymentIntentId: subscription.payment_intent,
          paymentMethodId: newPM,
        });
        return res;
      } else
        toast.error('We are missing data in order to complete the process. Please contact us.');
    } catch (err) {
      toast.error(`Something went wrong - ${err?.data?.message}`);
      setIsLoading(false);
      return { err };
    }
  };

  const resetBillingCycle = async () => {
    try {
      await axiosInstance.post(
        `/v2/subscription-manager/subscriptions/reset-billing-cycle/${subscription.subscription}`,
      );
      dispatch(setPaymentMethodId(selectedPaymentMethod.id));
      dispatch(setPaymentMethod(selectedPaymentMethod));
      toast.success('Your payment method was successfully updated!!');
      setIsModalOpen(false);
    } catch (err) {}
  };

  const selectPM = (value) => {
    setSelectedPaymentMethod(value);
  };

  const disable = () => {
    if (isLoading) return true;
    if (!newSelected) {
      if (!selectedPaymentMethod) return true;
    }
    if (newSelected && !paymentInfoComplete) {
      return true;
    }
    return false;
  };

  return (
    <Modal
      title={
        <>
          <div className={'title'}>Change Payment Method</div>{' '}
          <div className={'subTitle'}>
            Update the payment method with which you are paying for this store’s subscription.
            Invoices will continue to be sent to the billing user on file.
          </div>
        </>
      }
      open={isOpen}
      onClose={() => {
        setIsModalOpen(false);
      }}
    >
      <Modal.Section>
        <div className={'modal-update-con'}>
          {!isLoadingPage && (
            <>
              <div className="payment-details-content-settings">
                {customerPaymentsMethod.length > 0 && (
                  <div>
                    <div className={'check-box-margin avb-pm-chk'}>
                      <Checkbox
                        checked={!newSelected}
                        onChange={() => setNewSelected(false)}
                        label={
                          <div className="txt-tooltip">
                            Available payment methods:{' '}
                            <Tooltip
                              content={
                                'Payment methods listed are those associated with that billing user'
                              }
                            >
                              {' '}
                              <Icon source={InfoMinor} color="subdued"></Icon>
                            </Tooltip>
                          </div>
                        }
                      />
                    </div>
                    {!newSelected && (
                      <PMSelectSettings
                        isSearchable={false}
                        getOptionLabel={() => ''}
                        options={customerPaymentsMethod}
                        value={selectedPaymentMethod}
                        onChange={selectPM}
                        components={{ Option: IconOption, SingleValue: customSingleValue }}
                      />
                    )}

                    <div className={'check-box-margin-settings add-pm'}>
                      <Checkbox
                        checked={newSelected}
                        onChange={() => setNewSelected(true)}
                        label={'Add new payment method:'}
                      />
                    </div>
                  </div>
                )}
                {(customerPaymentsMethod.length > 0 && newSelected) ||
                customerPaymentsMethod.length === 0 ? (
                  <div>
                    <PaymentElement onChange={stripeElementChange} />
                  </div>
                ) : (
                  ''
                )}
              </div>
              <div>
                <div
                  className={`flex-container center rounded-button bg-btn-blue ${
                    isLoading && 'loading-settings'
                  }`}
                >
                  <Button onClick={handleSubmit} disabled={disable()}>
                    {isLoading ? 'Updating...' : 'Submit'}
                  </Button>
                </div>
                <div className={'flex-container middle center stripe-icon-settings'}>
                  <div className={'pow-by-settings'}>{'Powered by'}</div>
                  <img
                    onClick={() => window.open('https://stripe.com', '_blank')}
                    alt={''}
                    src={'/signupJourney/stripe_icon2.svg'}
                  />
                </div>
              </div>
            </>
          )}
          {isLoadingPage && (
            <div
              style={{
                position: 'absolute',
                top: 0,
                left: 0,
                alignItems: 'center',
                display: 'flex',
                justifyContent: 'center',
                height: '100%',
                width: '100%',
              }}
            >
              <Spinner accessibilityLabel="App is loading" size="large" />
            </div>
          )}
        </div>
      </Modal.Section>
    </Modal>
  );
};

export default ChangePaymentMethodModal;
