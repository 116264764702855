import React, { useCallback, useState } from 'react';
import { Card, Page, Tabs } from '@shopify/polaris';
import Products from 'components/Products';
import AmazonCogs from './AmazonCogs';
import { useSelector } from 'react-redux';
import { type RootState } from 'reducers/RootType';
import { UpgradePageFallBack } from 'feature-flag-system/components';
import { FeatureFlag } from '@tw/feature-flag-system/module/types';
import { computeFeatureFlags } from 'feature-flag-system';

function CogsPageComponent() {
  const [selectedTab, setSelectedTab] = useState(0);
  const isAmazonConnected = useSelector(
    (state: RootState) =>
      state.isAmazonConnectedNA || state.isAmazonConnectedEU || state.isAmazonConnectedFE,
  );
  const handleTabChange = useCallback((selectedTabIndex) => setSelectedTab(selectedTabIndex), []);

  const tabs = [
    {
      id: 'shopify-cogs',
      content: 'Shopify COGs',
      panelID: 'shopify-cogs',
    },
    {
      id: 'amazon-cogs',
      content: 'Amazon COGs',
      panelID: 'amazon-cogs',
    },
  ];

  return (
    <div>
      {!isAmazonConnected && (
        <div className="p-6.5">
          <Products />
        </div>
      )}
      {isAmazonConnected && (
        <div className="p-6.5">
          <div className="px-[15px]">
            <div className="flex">
              {tabs.map((tab, index) => (
                <div
                  key={`tab-${tab.id}`}
                  onClick={() => handleTabChange(index)}
                  className="cursor-pointer px-6.5 py-6.5"
                  style={{
                    color: selectedTab === index ? '#1877f2' : 'black',
                    borderBottom: selectedTab === index ? '2px solid #1877f2' : '',
                  }}
                >
                  {tab.content}
                </div>
              ))}
            </div>
            <Card.Section>
              {selectedTab === 0 && <Products />}
              {selectedTab === 1 && <AmazonCogs />}
            </Card.Section>
          </div>
        </div>
      )}
    </div>
  );
}

export default computeFeatureFlags(FeatureFlag.COGS_PAGE_FF, CogsPageComponent, () => (
  <UpgradePageFallBack
    featureFlag={FeatureFlag.COGS_PAGE_FF}
    InAppContextBannerEnabled={false}
    title="Cogs"
  />
));
