import { transformActivityToClientActivity } from 'components/ActivityFeed/activitiesTransfrmer';
import { ClientActivitiesGroup } from 'components/ActivityFeed/Types';

import { type AppDispatch } from 'index';
import moment from '@tw/moment-cached';
import { toast } from 'react-toastify';
import { RootState } from 'reducers/RootType';
import { combineReducers, Reducer } from 'redux';
import axiosInstance from 'utils/axiosInstance';

const ACTIVITY_FEED_HAS_MORE_ACTIVITIES = 'ACTIVITY_FEED_HAS_MORE_ACTIVITIES';
const ACTIVITY_FEED_ACTIVITIES_RECEIVED = 'ACTIVITY_FEED_ACTIVITIES_RECEIVED';
const ACTIVITY_FEED_FETCH_ACTIVITIES = 'ACTIVITY_FEED_FETCH_ACTIVITIES';
const ACTIVITY_FEED_REAL_TIME_ACTIVITIES_RECEIVED = 'ACTIVITY_FEED_REAL_TIME_ACTIVITIES_RECEIVED';
const OPEN_CREATE_ACTIVITY_MODAL = 'OPEN_CREATE_ACTIVITY_MODAL';
const CLOSE_CREATE_ACTIVITY_MODAL = 'CLOSE_CREATE_ACTIVITY_MODAL';
const OPEN_EDIT_ACTIVITY_MODAL = 'OPEN_EDIT_ACTIVITY_MODAL';
const ACTIVITY_PORTAL_FETCH_ACTIVITIES = 'ACTIVITY_PORTAL_FETCH_ACTIVITIES';

export const fetchActivities = (page: number, shopId: string) => {
  return async (dispatch: AppDispatch, getState: () => RootState) => {
    const { currency, shopTimezone } = getState();

    dispatch({
      type: ACTIVITY_FEED_FETCH_ACTIVITIES,
    });

    const { data } = await axiosInstance.post<any[]>('/v2/activities/get-activities', {
      shopId: shopId,
      page,
      timezone: shopTimezone,
    });
    if (!data) {
      return;
    }

    let newItems: any[] = data
      .map((group) => {
        return {
          activities:
            group.activities?.map((x) => transformActivityToClientActivity(x, currency)) || [],
          date: group.date,
        };
      })
      .flat();

    newItems = newItems
      .sort((group1, group2) => moment(group2.date).valueOf() - moment(group1.date).valueOf())
      .map((x, i, arr) => ({
        ...x,
        entity: x.activities[0].entity,
        type: x.activities[0].type,
        serviceId: x.activities[0].serviceId,
        isFirstInDate: i === 0 || !moment(x.date).isSame(arr[i - 1].date, 'day'),
      }))
      .filter((item) => item.serviceId !== 'klaviyo');

    // console.log('activities', newItems);

    dispatch({
      type: ACTIVITY_FEED_ACTIVITIES_RECEIVED,
      payload: { newItems, shouldConcat: page > 0 },
    });

    if (data?.length < 20) {
      dispatch({
        type: ACTIVITY_FEED_HAS_MORE_ACTIVITIES,
        payload: false,
      });
    } else {
      dispatch({
        type: ACTIVITY_FEED_HAS_MORE_ACTIVITIES,
        payload: true,
      });
    }
  };
};

export const openCreateActivityModal = () => ({ type: OPEN_CREATE_ACTIVITY_MODAL });
export const closeCreateActivityModal = () => ({ type: CLOSE_CREATE_ACTIVITY_MODAL });
export const openEditActivityModal = (activity: any) => ({
  type: OPEN_EDIT_ACTIVITY_MODAL,
  payload: activity,
});

export const receiveRealTimeActivity = (activity: any) => ({
  type: ACTIVITY_FEED_REAL_TIME_ACTIVITIES_RECEIVED,
  payload: activity,
});

export const setLoadingActivityPortal = (bool) => ({
  type: ACTIVITY_PORTAL_FETCH_ACTIVITIES,
  payload: bool,
});

export const saveNewActivity = ({
  entity = '',
  title = '',
  description = '',
  annotation = false,
  platform = 'triple-whale',
  type = 'update',
  date = moment(),
  source = '',
  author = '',
}) => {
  return async (dispatch: AppDispatch, getState: () => RootState) => {
    const { currentShopId } = getState();
    const activity = {
      id: annotation
        ? `annotation-${platform}-${date.format()}`
        : `${platform}${entity ? '-' + entity : ''}${
            source ? '-' + source : ''
          }-${type}-${date.format()}`,
      type,
      date: date,
      shopId: currentShopId,
      annotation,
      serviceId: platform,
    };
    if (entity) activity['entity'] = entity;
    if (title) activity['title'] = title;
    if (description) activity['description'] = description;
    if (source) activity['source'] = source;
    if (author) activity['author'] = author;

    const res = await axiosInstance.post<any[]>('/v2/activities/save-new-activity', {
      shopId: currentShopId,
      activity,
    });
    if (res.status === 200) {
      toast.success(`Activity saved successfully`);
      dispatch(setLoadingActivityPortal(true));
      dispatch(fetchActivities(0, currentShopId));
      annotation && dispatch(setLastNewActivity(activity));
    }
  };
};

const SET_LAST_NEW_ACTIVITY = 'SET_LAST_NEW_ACTIVITY';
export const setLastNewActivity = (activity) => {
  return async (dispatch: AppDispatch, getState: () => RootState) => {
    dispatch({
      type: SET_LAST_NEW_ACTIVITY,
      payload: activity,
    });
  };
};

export const deleteActivity = (activity, currentShopId, isDelete) => {
  return async (dispatch: AppDispatch) => {
    const res = await axiosInstance.post('/v2/activities/delete-activity', {
      activity,
      shopId: currentShopId,
    });
    if (res.data?.deletedCount === 1) {
      isDelete && toast.success(`Activity deleted successfully`);
      dispatch(setLoadingActivityPortal(true));
      dispatch(fetchActivities(0, currentShopId));
    }
  };
};

// *** Reducers ***
const activities: Reducer<ClientActivitiesGroup[]> = (state = [], action) => {
  switch (action.type) {
    case ACTIVITY_FEED_ACTIVITIES_RECEIVED:
      const { shouldConcat, newItems = [] } = action.payload;
      if (shouldConcat) {
        return [...state, ...newItems.filter((item) => item.type !== 'timing')];
      }
      return newItems;
    case ACTIVITY_FEED_REAL_TIME_ACTIVITIES_RECEIVED:
      return [action.payload, ...state];
    default:
      return state;
  }
};

const hasMoreActivities = (state = true, action) => {
  switch (action.type) {
    case ACTIVITY_FEED_HAS_MORE_ACTIVITIES:
      return action.payload;
    default:
      return state;
  }
};

const loadingActivities = (state = false, action) => {
  switch (action.type) {
    case ACTIVITY_FEED_FETCH_ACTIVITIES:
      return true;
    case ACTIVITY_FEED_ACTIVITIES_RECEIVED:
      return false;
    default:
      return state;
  }
};
const showCreateActivityModal = (state = false, action) => {
  switch (action.type) {
    case OPEN_CREATE_ACTIVITY_MODAL:
    case OPEN_EDIT_ACTIVITY_MODAL:
      return true;
    case CLOSE_CREATE_ACTIVITY_MODAL:
      return false;
    default:
      return state;
  }
};

const currentCustomActivity = (state = {}, action) => {
  switch (action.type) {
    case OPEN_EDIT_ACTIVITY_MODAL:
      return action.payload;
    case CLOSE_CREATE_ACTIVITY_MODAL:
      return {};
    default:
      return state;
  }
};

const loadingActivityPortal = (state = false, action) => {
  switch (action.type) {
    case ACTIVITY_PORTAL_FETCH_ACTIVITIES:
      return action.payload;
    default:
      return state;
  }
};

const lastNewActivityId = (state = null, action) => {
  switch (action.type) {
    case SET_LAST_NEW_ACTIVITY:
      return action.payload.id ?? null;
    default:
      return state;
  }
};

export const reducers = combineReducers({
  activities,
  hasMoreActivities,
  loadingActivities,
  showCreateActivityModal,
  currentCustomActivity,
  loadingActivityPortal,
  lastNewActivityId,
});
