import { useState } from 'react';
import { Modal, TextContainer, TextField, Button } from '@shopify/polaris';
import { toggleKlaviyoConfigScreenOpen, klaviyoConfigSavePress } from '../ducks/klaviyo';
import { useAppSelector } from 'reducers/RootType';
import { useAppDispatch } from 'index';
import { useSelector } from 'react-redux';

const KlaviyoConfig = ({ isLoading }) => {
  const dispatch = useAppDispatch();
  const isOpen = useAppSelector((state) => state.klaviyoConnectConfigIsOpen);
  const isFreeShop = useAppSelector((state) => state.isFreeShop);
  var [klaviyoKey, setKlaviyoKey] = useState('');

  const isEmpty = (val) => !val || !val.length;
  const validateKlaviyoKey = () => {
    var flag = true;
    if (isEmpty(klaviyoKey)) flag = false;
    return flag;
  };

  return (
    <Modal
      title={'Klaviyo configuration'}
      open={isOpen}
      onClose={() => dispatch(toggleKlaviyoConfigScreenOpen())}
      primaryAction={{
        content: 'Save',
        onAction: () => dispatch(klaviyoConfigSavePress({ klaviyoKey })),
        disabled: !validateKlaviyoKey(),
      }}
      loading={isLoading}
    >
      <Modal.Section>
        <TextContainer>
          <p>Copy+Paste private key here:</p>
          <TextField
            label="Private Key"
            value={klaviyoKey}
            onChange={setKlaviyoKey}
            placeholder="Something like: pk_3f11e7f8..."
            autoComplete="off"
          />
        </TextContainer>

        <div className="Polaris-TextContainer">
          <br />
          <p>How to generate a new API Key in Klaviyo:</p>
          <ol>
            <li>
              Go to this link on Klaviyo to{' '}
              <a href="https://www.klaviyo.com/create-private-api-key" target="_blank">
                Create Private API Key
              </a>
              .
            </li>
            <li>Name the API key.</li>
            <li>
              Select the scope you want to give the API key. For your Triple Whale key, select
              “Full” scope.
            </li>
            <li>Select "Create".</li>
          </ol>
        </div>

        {!isFreeShop && (
          <div>
            <br />
            Need help?{' '}
            <Button plain monochrome onClick={() => window.Intercom('show')}>
              Click here and chat with us!
            </Button>
          </div>
        )}
      </Modal.Section>
    </Modal>
  );
};

export default KlaviyoConfig;
