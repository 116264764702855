import { useEffect, useRef } from 'react';
import { useHistory } from 'react-router';
import { useAppSelector } from 'reducers/RootType';
import { boomDataLayer, dataLayerPageView } from 'utils/dataLayer';

/**
 * @description This hook takes care of:
 *  - "garbage collection"
 *  - tracking router change
 *  - clear data layer beforehand
 *  - track pageview & set current shop
 */
export const useDataLayerTracking = (): void => {
  const currentShopId = useAppSelector((state) => state.currentShopId);
  const user = useAppSelector((state) => state.user);
  const { listen } = useHistory();
  const cachedShopId = useRef('');
  const initialLoad = useRef(false);

  useEffect(() => {
    const unlisten = listen((location) => {
      boomDataLayer();

      if (currentShopId && cachedShopId.current !== currentShopId) {
        cachedShopId.current = currentShopId;
      } else {
        dataLayerPageView(currentShopId, location.pathname, user);
      }
    });

    if (!initialLoad.current && Object.keys(user).length > 0) {
      initialLoad.current = true;
      dataLayerPageView(currentShopId, location.pathname, user);
    }

    return unlisten;
    // TODO: Ask Mike if these dependencies should be added
  }, []);
};
