import BaseChart from 'components/library/BaseChart/BaseChart';
import { lighthousePopupClose } from 'ducks/lighthouse';
import { useAppDispatch } from 'index';
import React, { useCallback, useMemo, useState } from 'react';
import { Bar, BarChart, Cell, Tooltip as ChartTooltip, TooltipProps } from 'recharts';
import { LighthouseClientNotification } from './types';
import services from '../../constants/services';
import { formatNumber } from 'utils/formatNumber';
import { useSelector } from 'react-redux';
import { type RootState } from 'reducers/RootType';
import { valueFormats } from '@tw/types';
import { getCurrencySymbol } from 'utils/getCurrencySymbol';
import { DraggableModal } from 'components/DraggableProvider/DraggableModal';
import { safeDivide } from '@tw/stats/module/generalUtils';
import LightTripleWhaleIconBase64 from 'components/LightTripleWhaleIconBase64';
import { MagicMinor, TimeDecayModelMinor } from '@shopify/polaris-icons';
import { Button } from '@shopify/polaris';
import { useHistory } from 'react-router';
import { useIsSmall } from 'hooks/useDefaultWindowSizes';

export const LighthouseBudgetSplit: React.FC<LighthouseClientNotification> = ({
  startDate,
  endDate,
  extraData,
  Icon,
}) => {
  const isSmall = useIsSmall();
  const dispatch = useAppDispatch();
  const currency = useSelector((state: RootState) => state.currency);
  const [dataFormat, setDataFormat] = useState<'currency' | 'percent'>('currency');
  const [activeTab, setActiveTab] = useState(0);

  const history = useHistory();

  const SERVICES = useMemo(
    () =>
      Object.fromEntries([
        ...Object.entries({
          ...services,
          other: {
            name: 'Other',
            icon: () => <LightTripleWhaleIconBase64 height={18} width={18} small />,
            title: (
              <Button
                plain
                monochrome
                onClick={() => {
                  dispatch(lighthousePopupClose());
                  history.push({
                    pathname: `/attribution/all/all`,
                    search: location.search,
                  });
                }}
              >
                Other
              </Button>
            ),
            color: '#1877F2',
          },
        }),
      ]),
    [],
  );

  const changeDataFormat = useCallback((format) => {
    console.log(SERVICES);
    setDataFormat(format);
  }, []);

  const totalSpend = useMemo(
    () => extraData?.reportData.reduce((prev, curr) => prev + curr.spend, 0),
    [extraData?.reportData],
  );
  const totalRevenue = useMemo(
    () => extraData?.reportData.reduce((prev, curr) => prev + curr.pixelConversionValue, 0),
    [extraData?.reportData],
  );

  const { story = '' } = extraData || {};

  const chartData = useMemo(
    () =>
      extraData?.reportData.map((serviceReport) => {
        return {
          service: serviceReport.service,
          spend: {
            currency: serviceReport.spend,
            percent: safeDivide(serviceReport.spend, totalSpend),
          },
          pixelConversionValue: {
            currency: serviceReport.pixelConversionValue,
            percent: safeDivide(serviceReport.pixelConversionValue, totalRevenue),
          },
          roas: safeDivide(serviceReport.pixelConversionValue, serviceReport.spend),
        };
      }),
    [extraData?.reportData, totalSpend, totalRevenue],
  );

  return (
    <DraggableModal
      onClose={() => {
        dispatch(lighthousePopupClose());
      }}
      open={true}
      width="medium"
      style={{
        backgroundColor: '#11283E',
        border: '1px solid rgba(192, 214, 234, 0.1)',
      }}
      className="py-6.5"
    >
      <div className="text-white lighthouse-chart w-full">
        <div
          className="overflow-auto flex flex-col gap-6.5 !rounded-none sm:!rounded-[8px] relative"
          style={{
            minWidth: 'unset',
          }}
        >
          <div className="flex items-center gap-4 px-6.5">
            <div className="border border-solid border-[#2E4E65] rounded-[7px] p-2 flex items-center justify-center bg-[#062940]">
              {Icon(40)}
            </div>
            <div className="flex flex-col">
              <span className="text-[20px] font-medium leading-[30px]">Total Impact</span>
              <span className="text-[12px] text-[#97B2D0]">
                {`${startDate.format('MMM DD YY')} - ${endDate.format('MMM DD YY')}`}
              </span>
            </div>
          </div>
          <div className="absolute right-[5%] top-[50px] flex justify-between items-center gap-4">
            <div className="flex border border-solid border-[#2E4E65] rounded-md bg-[var(--dark-bg)] p-1 text-xl leading-[14px]">
              <div
                className="fill-white p-[5px_11px] cursor-pointer rounded-md transition-colors duration-300 ease-in-out"
                style={{
                  backgroundColor: dataFormat === 'currency' ? '#1877F2' : '#062940',
                }}
                onClick={() => changeDataFormat('currency')}
              >
                {getCurrencySymbol(currency)}
              </div>
              <div
                className="fill-white p-[5px_11px] cursor-pointer rounded-md transition-colors duration-300 ease-in-out"
                style={{
                  backgroundColor: dataFormat === 'percent' ? '#1877F2' : '#062940',
                }}
                onClick={() => changeDataFormat('percent')}
              >
                %
              </div>
            </div>
            <div className="flex border border-solid border-[#2E4E65] rounded-md bg-[var(--dark-bg)] p-1">
              <TimeDecayModelMinor
                onClick={() => setActiveTab(0)}
                width={30}
                className="fill-white p-[5px_8px] cursor-pointer rounded-md transition-colors duration-300 ease-in-out"
                style={{ backgroundColor: activeTab === 0 ? '#1877F2' : 'var(--dark-bg)' }}
              />
              <MagicMinor
                onClick={() => setActiveTab(1)}
                width={30}
                className="fill-white p-[5px_8px] cursor-pointer rounded-md transition-colors duration-300 ease-in-out"
                style={{ backgroundColor: activeTab === 1 ? '#1877F2' : 'var(--dark-bg)' }}
              />
            </div>
          </div>
          <div
            className={`h-[330px] tw-scroll ${
              activeTab === 1 ? 'overflow-y-auto' : ''
            } border border-x-0 border-solid border-[#2E4E65] bg-[#0D1F31] px-6.5`}
          >
            {activeTab === 0 && (
              <div className="w-full h-full flex items-end">
                <div className="overflow-y-auto flex flex-col h-full justify-around transition-all duration-300 ease-in-out pb-[20px] pt-[35px]">
                  {chartData.map((data) => (
                    <div
                      key={`tick-${data.service}`}
                      className={`rounded-full flex items-center flex-row gap-2 text-[10px] h-[30px] px-3 sm:justify-between justify-center`}
                      style={{
                        backgroundColor: `${SERVICES[data.service]?.color}3b`,
                        color: SERVICES[data.service]?.color,
                      }}
                    >
                      <span className="flex shrink-0">{SERVICES[data.service]?.icon?.(true)}</span>
                      <span className="sm:flex items-center text-center hidden">
                        {`${formatNumber(data.roas, {
                          currency: currency,
                          minimumFractionDigits: 0,
                          maximumFractionDigits: 2,
                          style: 'decimal',
                        })}`}
                      </span>
                    </div>
                  ))}
                </div>
                <div className="flex-auto">
                  <BaseChart
                    height={290}
                    data={chartData}
                    ChartType={BarChart}
                    showTooltip={true}
                    margin={{ bottom: 0, left: -40, right: -40, top: 0 }}
                    layout="vertical"
                    verticalLines={true}
                    horizontalLines={false}
                    xAxis={[
                      {
                        type: 'number',
                        tickMargin: -25,
                        fontSize: 12,
                        tickFormatter: (value, index) => {
                          if (index % 2 !== 0) {
                            return '';
                          }
                          return formatNumber(value, {
                            style: dataFormat,
                            currency,
                            minimumFractionDigits: 0,
                            maximumFractionDigits: 0,
                            notation: 'compact',
                          });
                        },
                      },
                    ]}
                    yAxis={[
                      {
                        dataKey: 'service',
                        yAxisId: 'service',
                        type: 'category',
                        tick: false,
                      },
                      {
                        // this is just to keep some padding from right
                        yAxisId: 'right',
                        orientation: 'right',
                        tickFormatter: (value) => {
                          return value;
                        },
                        tick: false,
                      },
                    ]}
                  >
                    <>
                      <defs>
                        {chartData.map((point, index) => (
                          <linearGradient
                            key={'revenue-' + index}
                            id={`revenue-service-${index}`}
                            x1="1"
                            y1="0"
                            x2="0"
                            y2="0"
                          >
                            <stop offset="0" stopColor={'#39a952'} stopOpacity={1} />
                            <stop offset="1" stopColor={'#39a952'} stopOpacity={0} />
                          </linearGradient>
                        ))}
                      </defs>
                      <defs>
                        {chartData.map((point, index) => (
                          <linearGradient
                            key={'spend-' + index}
                            id={`spend-service-${index}`}
                            x1="1"
                            y1="0"
                            x2="0"
                            y2="0"
                          >
                            <stop offset="0" stopColor={'#EA643D'} stopOpacity={1} />
                            <stop offset="1" stopColor={'#EA643D'} stopOpacity={0} />
                          </linearGradient>
                        ))}
                      </defs>
                      <Bar
                        barSize={7}
                        yAxisId={'service'}
                        name={'Ad spend'}
                        dataKey={(data) => {
                          return data.spend[dataFormat] || 0;
                        }}
                        fill="#EA643D"
                      >
                        {chartData.map((point, index) => (
                          <Cell
                            key={`cell-gradient-${index}`}
                            fill={`url(#spend-service-${index})`}
                          />
                        ))}
                      </Bar>
                      <Bar
                        barSize={7}
                        yAxisId={'service'}
                        name={'Revenue'}
                        dataKey={(data) => {
                          return data.pixelConversionValue[dataFormat] || 0;
                        }}
                        fill="#39a952"
                      >
                        {chartData.map((point, index) => (
                          <Cell
                            key={`cell-gradient-${index}`}
                            fill={`url(#revenue-service-${index})`}
                          />
                        ))}
                      </Bar>
                      <ChartTooltip
                        content={<CustomBarTooltip currency={currency} styleFormat={dataFormat} />}
                        cursor={{ fill: 'none' }}
                      />
                    </>
                  </BaseChart>
                </div>
              </div>
            )}
            {activeTab === 1 && <p className="whitespace-pre-line py-6.5">{story}</p>}
          </div>
          <div className="flex justify-between items-center px-6.5">
            <div className="flex flex-col items-start">
              <span className="text-[#97B2D0] text-xl">Total Spend</span>
              <span className="sm:text-[16px]">
                {formatNumber(totalSpend, {
                  currency: currency,
                  minimumFractionDigits: 0,
                  maximumFractionDigits: 0,
                  style: 'currency',
                })}
              </span>
            </div>
            <Dots color="#EA643D" count={isSmall ? 3 : 10} className="flex-row-reverse" />
            <div className="flex flex-col items-center">
              <span className="text-[#97B2D0] text-xl">ROAS</span>
              <span className="sm:text-[16px]">
                {formatNumber(safeDivide(totalRevenue, totalSpend), {
                  minimumFractionDigits: 0,
                  maximumFractionDigits: 2,
                  style: 'decimal',
                })}
              </span>
            </div>
            <Dots color="#39a952" count={isSmall ? 3 : 10} />
            <div className="flex flex-col items-end">
              <span className="text-[#97B2D0] text-xl">Total Revenue</span>
              <span className="sm:text-[16px]">
                {formatNumber(totalRevenue, {
                  currency: currency,
                  minimumFractionDigits: 0,
                  maximumFractionDigits: 0,
                  style: 'currency',
                })}
              </span>
            </div>
          </div>
          <div className="max-h-[250px] overflow-auto tw-scroll px-6.5">
            <div className="table align-middle w-full">
              <div className="text-[#6F95B3] text-[14px] font-medium table-row h-[40px]">
                <div className="table-cell sticky top-0 align-middle px-4 bg-[#1a3046] rounded-[4px_0_0_4px]">
                  Channel
                </div>
                <div className="table-cell sticky top-0 align-middle px-2 text-end bg-[#1a3046]">
                  Ad Spend
                </div>
                <div className="table-cell sticky top-0 align-middle px-2 text-end bg-[#1a3046]">
                  Revenue
                </div>
                <div className="table-cell sticky top-0 align-middle px-4 text-end bg-[#1a3046] rounded-[0_4px_4px_0]">
                  ROAS
                </div>
              </div>
              <div className="table-row-group">
                {chartData.map((row, index) => (
                  <div key={row.service} className={`h-[40px] table-row text-[14px]`}>
                    <div
                      className={`overflow-hidden text-ellipsis whitespace-nowrap table-cell align-middle px-4 rounded-[4px_0_0_4px] ${
                        index % 2 ? 'bg-white/4' : ''
                      }`}
                    >
                      <div className="flex items-center gap-3">
                        {SERVICES[row.service]?.icon?.(true)}
                        {SERVICES[row.service]?.title}
                      </div>
                    </div>
                    <div
                      className={`table-cell align-middle px-2 text-end ${
                        index % 2 ? 'bg-white/4' : ''
                      }`}
                    >
                      {formatNumber(row.spend[dataFormat], {
                        currency: currency,
                        minimumFractionDigits: 0,
                        maximumFractionDigits: 0,
                        style: dataFormat,
                      })}
                    </div>
                    <div
                      className={`table-cell align-middle px-2 text-end ${
                        index % 2 ? 'bg-white/4' : ''
                      }`}
                    >
                      {formatNumber(row.pixelConversionValue[dataFormat], {
                        currency: currency,
                        minimumFractionDigits: 0,
                        maximumFractionDigits: 0,
                        style: dataFormat,
                      })}
                    </div>
                    <div
                      className={`table-cell align-middle px-4 text-end rounded-[0_4px_4px_0] ${
                        index % 2 ? 'bg-white/4' : ''
                      }`}
                    >
                      {formatNumber(row.roas, {
                        minimumFractionDigits: 0,
                        maximumFractionDigits: 2,
                        style: 'decimal',
                      })}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </DraggableModal>
  );
};

const CustomBarTooltip = (
  props: TooltipProps<any, any> & { currency: string; styleFormat: valueFormats },
) => {
  const { payload, currency, styleFormat, label } = props;

  const SERVICES = useMemo(
    () =>
      Object.fromEntries([
        ...Object.entries({
          ...services,
          other: {
            name: 'Other',
            icon: () => <LightTripleWhaleIconBase64 height={18} width={18} small />,
            title: 'Other',
            color: '#1877F2',
          },
        }),
      ]),
    [],
  );

  return (
    <div className="bg-white rounded-md font-bold p-2 text-black">
      <span className="text-[10px] font-normal leading-3 flex gap-4">
        <span>{SERVICES[label]?.title}</span>
        <span className="sm:hidden">
          {formatNumber(payload?.[0]?.payload?.roas, {
            style: 'decimal',
            currency,
            minimumFractionDigits: 0,
            maximumFractionDigits: 2,
          })}
        </span>
      </span>
      {payload?.map((barData, index) => {
        return (
          <div
            className="text-[10px] flex items-center gap-2 leading-4"
            key={barData['id'] + '_' + index}
          >
            <div
              style={{
                background: `linear-gradient(180deg, ${barData['fill']} 0%, rgba(234, 100, 61, 0) 100%)`,
              }}
              className="rounded-sm w-[8px] h-[8px]"
            ></div>
            <span>{`${barData.name}: ${formatNumber(barData.value, {
              style: styleFormat,
              currency,
              minimumFractionDigits: 0,
              maximumFractionDigits: 0,
            })}`}</span>
          </div>
        );
      })}
    </div>
  );
};

type dotsProps = {
  color: string;
  count: number;
  className?: string;
};

const Dots = (props: dotsProps) => {
  const { color, count, className } = props;
  const dots = useMemo(() => {
    const dotsArray: any = [];
    for (let i = 0; i < count; i++) {
      dotsArray.push(
        <div
          key={i}
          className="w-[6px] h-[6px] rounded-full"
          style={{ backgroundColor: color, opacity: (count - i + 1) / 10 }}
        ></div>,
      );
    }
    return dotsArray;
  }, [count, color]);

  return <div className={`flex gap-[8px] ${className}`}>{dots}</div>;
};
