import React, { useCallback, useEffect, useState } from 'react';
import { connect, useSelector } from 'react-redux';
import { type RootState } from 'reducers/RootType';

import { Button, Modal, TextContainer, TextField } from '@shopify/polaris';

import { knoConfigSavePress, toggleKnoConfigScreenOpen } from '../ducks/kno';
import { KNO_INTEGRATION } from 'constants/knowledgeBaseLinks';
import { IntegrationSaveErrors } from 'ducks/constants';

const KnoConfig = ({ isOpen, toggleIsOpen, isLoading, save }) => {
  const [knoClientId, setKnoClientId] = useState('');
  const handleChangeKnoClientId = useCallback(setKnoClientId, [setKnoClientId]);
  const [knoClientSecret, setKnoClientSecret] = useState('');
  const handleChangeKnoClientSecret = useCallback(setKnoClientSecret, [setKnoClientSecret]);
  const [saveError, setSaveError] = useState('');
  const isFreeShop = useSelector((state: RootState) => state.isFreeShop);

  const handleSave = () => {
    setKnoClientId(knoClientId.trim());
    setKnoClientSecret(knoClientSecret.trim());
    save({ shopId, knoClientId, knoClientSecret }, setSaveError);
  };

  const isEmpty = (val) => !val || !val.length;
  const shopId = useSelector((state: RootState) => state.currentShopId);

  useEffect(() => {
    setKnoClientId('');
    setKnoClientSecret('');
    setSaveError('');
  }, [shopId, isOpen]);

  return (
    <Modal
      title={'Kno configuration'}
      open={isOpen}
      onClose={toggleIsOpen}
      primaryAction={{
        content: 'Save',
        onAction: () => handleSave(),
        disabled: isEmpty(knoClientId) || isEmpty(knoClientSecret),
      }}
      loading={isLoading}
    >
      <Modal.Section>
        {saveError && (
          <TextContainer>
            <p style={{ color: 'red' }}>{saveError}</p>
          </TextContainer>
        )}
        <TextContainer>
          <p>
            Login to your Kno account{' '}
            <a
              href="https://app.knocommerce.com/main/settings/integrations"
              target="_blank"
              rel="noreferrer"
            >
              here
            </a>
            , to find the Triple Whale integration.
            <br />
            <br />
            Next, follow the directions outlined in our knowledge base article{' '}
            <a href={KNO_INTEGRATION} target="_blank" rel="noreferrer">
              here
            </a>
            , to connect your account.
          </p>

          <TextField
            label="Client ID:"
            value={knoClientId}
            onChange={handleChangeKnoClientId}
            placeholder="Something like: 245de4a8d5bc3a6475024310.tw-prod"
            autoComplete="off"
          />

          <TextField
            label="Client Secret:"
            value={knoClientSecret}
            onChange={handleChangeKnoClientSecret}
            placeholder="Something like: 4510193d710e35a5ae08216a3aa82eaa356ad7065ca038b3aa2ed8b1d60819e3"
            autoComplete="off"
          />
        </TextContainer>
        {!isFreeShop && (
          <div>
            <br />
            Need help?{' '}
            <Button plain monochrome onClick={() => window.Intercom('show')}>
              Click here and chat with us!
            </Button>
          </div>
        )}
      </Modal.Section>
    </Modal>
  );
};

const mapStateToProps = ({ knoConnectConfigIsOpen }) => ({
  isOpen: knoConnectConfigIsOpen,
});

const mapDispatchToProps = (dispatch) => ({
  toggleIsOpen: () => dispatch(toggleKnoConfigScreenOpen()),
  save: (settings, saveErrorFunction) => dispatch(knoConfigSavePress(settings, saveErrorFunction)),
});

export default connect(mapStateToProps, mapDispatchToProps)(KnoConfig);
