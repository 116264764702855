import { Icon, RingProgress } from '@tw/ui-components';
import { MessageData } from './types/willyTypes';
import { HTMLAttributes, useMemo, useState } from 'react';
import ScrollToBottom from 'react-scroll-to-bottom';
import { WillyScrollToBottom } from './WillyScrollToBottom';
import { WillySimpleText } from './WillySimpleText';
import { CodeMajor } from '@shopify/polaris-icons';
import { formatSqlSafely } from './utils/willyUtils';

type WillyMessageProgressProps = {
  message: MessageData;
};

export const WillyMessageProgress: React.FC<WillyMessageProgressProps> = ({ message }) => {
  const [detailsExpanded, setDetailsExpanded] = useState(false);

  const query = useMemo(() => {
    if (!message.query) {
      return '';
    }
    if (message.query.startsWith('```')) {
      return message.query;
    }

    const formatted = formatSqlSafely(message.query);

    return '```sql\n' + formatted + '\n```';
  }, [message.query]);

  const markdownClasses = useMemo(() => {
    const classes: HTMLAttributes<HTMLDivElement>['className'][] = [
      'transition-[height] overflow-auto',
    ];

    if (!detailsExpanded) {
      classes.push('h-0');
    } else {
      classes.push('h-90');
    }

    return classes.join(' ');
  }, [detailsExpanded]);

  return (
    <div className="flex flex-col gap-4">
      <div
        className="items-center inline-flex gap-2 cursor-pointer"
        onClick={() => {
          setDetailsExpanded((old) => !old);
        }}
      >
        <RingProgress
          sections={[
            {
              value: message.progressInfo?.progress ?? 100,
              color: 'blue',
            },
          ]}
          size={30}
          thickness={1}
          label={
            <span className="flex justify-center">
              <CodeMajor className="w-6 h-6 fill-blue-600 flex items-center justify-center" />
            </span>
          }
        />

        {!!message.progressInfo?.text && (
          <span className="ml-2 text-gray-700 dark:text-slate-400 text-[12px]">
            {message.progressInfo.text}
          </span>
        )}

        <span className={`w-4 h-4 flex ${detailsExpanded ? 'transform rotate-180' : ''}`}>
          <Icon name="chevron-down" size={10} />
        </span>
      </div>
      {query && (
        <ScrollToBottom className={markdownClasses} initialScrollBehavior="smooth">
          <WillyScrollToBottom showArrow={true} arrowClassName="!bottom-2 w-8 h-8">
            <WillySimpleText text={query} />
          </WillyScrollToBottom>
        </ScrollToBottom>
      )}
    </div>
  );
};
