import { Timestamp } from '../../../utils/DB';
import { SalesPlatform, ServicesIds } from '@tw/types/module/services';
import { FormattedColor, IconName } from '@tw/ui-components';
import { BaseColumn } from 'components/library/TWTable/types';
import { editor } from 'monaco-editor';
import { Socket } from 'socket.io-client';
import { MainActionResult } from '@tw/types/module/willy/langchain/langchain';
import { BqTable } from '../../../pages/FreeQuery/dataStuff/tables';
import { FilterBuilderType } from '../../../pages/FreeQuery/SqlFilterBuilder';
import { WillyEmoji } from './emojiTypes';
import { AxisDomainItem } from 'recharts/types/util/types';
import { BqColumn } from 'pages/FreeQuery/dataStuff/columns/types';
import { FeatureFlagConfigKey } from '@tw/feature-flag-system/module/types';
import firebase from 'firebase/compat/app';
import { CurrentDateRange, PrevDateRange } from '../../../$stores/willy/$dateRange';
import {
  AggregationFunction,
  FilterGroup,
  FilterRow,
  BuilderTable,
  FilterOperator,
  WillyOperatorItem,
} from '@tw/willy-data-dictionary/module/columns/types';
import { type valueFormats, type Granularity } from '@tw/types';
import { BaseSummaryMetric, SummaryMetricIdsTypes } from '@tw/types/module/SummaryMetrics';
import { type RootState } from 'reducers/RootType';
import { AttributionData } from 'types/attribution';
import { IndustryTypes } from '@tw/types/module/types/IndustryType';
import { DatePickerTimePeriods } from 'components/useDatePickerSelectedOptions';

export type SuggestionModes = 'title' | 'question';
export type ChatSources = 'chat' | 'dashboard' | 'editor' | 'summary' | 'sequence' | 'pixel';

export type RelatedSchema = {
  table: string;
  columns: string[];
};

export type UniformChatResponse = {
  type: 'text' | 'error';
  text: string;
  question: string;
  messageId: string;
  conversationId?: string;
  shopId: string;
  userId: string;
  data: NlqResponse[];
  messages: HistoryItem[];
  actions: MainActionResult[];
  latencies?: {
    [key: string]: number;
  };
};

export const Dialects = ['bigquery', 'clickhouse'] as const;
export type Dialect = (typeof Dialects)[number];
export type DialectWithBoth = Dialect | 'both';

export type AnswerNlqQuestionParams = {
  shopId: string;
  additionalShopIds: string[];
  conversationId: string;
  messageId: string;
  question: string;
  dialect: Dialect;
  generateInsights?: boolean;
  query?: string;
  metrics?: string[];
  widgetId?: string;
  dashboardId?: string;
  mentionedUsers?: string[];
  source: ChatSources;
  returnQueryOnly?: boolean;
  relatedSchema?: RelatedSchema[];
  currency: string;
  timezone: string;
  industry: IndustryTypes;
  stream?: boolean;
  dashboardData?: any;
};

export type WillyEmitEvents = {
  'answer-nlq-question': (msg: AnswerNlqQuestionParams) => void;
  'run-sequence': (msg: { sequenceId: string } & AnswerNlqQuestionParams) => void;
  'stop-nlq-question': (msg: BaseEventPayload) => void;
  'generate-insights': (
    msg: { question: string; data: any[]; currency: string } & BaseEventPayload,
  ) => void;
  'suggest-title': (msg: {
    shopId: string;
    queryId: string;
    mode: SuggestionModes;
    generatedQuery: string;
    question?: string;
  }) => void;
  'insight-sql': (msg: {
    shopId: string;
    query: string;
    id: string;
    conversationId: string;
  }) => void;
  'build-sql': (msg: {
    shopId: string;
    existingQuery: string;
    table: BqTable;
    filterBuilder?: FilterBuilderType;
    isPivot?: boolean;
    id: string;
  }) => void;
  'stop-answer-nlq-question': (msg: BaseEventPayload) => void;
  'get-metrics-data': (msg: {
    shopId: string;
    metricTrees: (MetricTree | WillyExpressionOrCustomMetricToServer)[];
    messageId: string;
    start: string;
    end: string;
    granularity: Granularity;
    previous: boolean;
    adTableFilters: FilterRow[][];
    dialect: Dialect;
  }) => void;
};

export type BaseEventPayload = {
  shopId: string;
  messageId: string;
  question?: string;
  originalQuestion?: string;
  conversationId?: string;
};

export type HelpCenterLink = {
  title: string;
  link: string;
};

export type ActionEventPayload = {
  question: string;
  data: MainActionResult;
  error?: string;
} & BaseEventPayload;

export type WillyListenEvents = {
  error: (error: string, messageId?: string) => void;
  progress: (
    progress: {
      message: string;
      progress: number;
    } & BaseEventPayload,
  ) => void;
  data: (
    data: {
      message: string;
      queryId: string;
      progress: number;
    } & BaseEventPayload,
  ) => void;
  insight: (insight: { text: string } & BaseEventPayload) => void;
  conversation: (
    conversation: {
      data: any;
      error: string;
      question: string;
    } & BaseEventPayload,
  ) => void;
  action: (action: ActionEventPayload) => void;
  help: (help: { text: string; links: HelpCenterLink[] } & BaseEventPayload) => void;
  title: (title: { title: string } & BaseEventPayload) => void;
  done: (
    data: {
      times: Record<any, any>;
    } & BaseEventPayload,
  ) => void;
  'metrics-data': (data: MetricsDataEvent) => void;
  'sequence-progress': (data: SequenceProgressEvent) => void;
  'chat-response': ChatResponse;
};

export type ChatResponseStarted = {
  type: 'started';
  processId: string;
} & BaseEventPayload;

export type ChatResponseDone = {
  type: 'done';
} & BaseEventPayload;

export type ChatResponseError = {
  type: 'error';
  error: string;
  processId: string;
} & BaseEventPayload;

export type ChatResponseInsight = {
  type: 'insight';
  text: string;
  processId: string;
} & BaseEventPayload;

export type Step = 'start' | 'write' | 'execute' | 'error' | 'done';

export type SupportedLanguages = 'python' | 'sql' | 'text';

export type ChatResponseToolBuild = {
  type: 'tool-build';
  tool: WillyToolName;
  progress: number;
  processId: string;
} & BaseEventPayload;

export type ChatResponseToolStep = {
  type: 'tool-step';
  tool: WillyToolName;
  processId: string;
  delta: {
    progress: number;
    reason: string;
    content?: string;
    contentLanguage?: SupportedLanguages;
  };
} & BaseEventPayload;

export type ChatResponseToolStarted = {
  type: 'tool-started';
  processId: string;
  tool: WillyToolName;
} & BaseEventPayload;

export type ChatResponseToolDone = {
  type: 'tool-done';
  processId: string;
  toolResults: ToolResult;
} & BaseEventPayload;

export type ChatResponses =
  | ChatResponseStarted
  | ChatResponseDone
  | ChatResponseError
  | ChatResponseInsight
  | ChatResponseToolBuild
  | ChatResponseToolStep
  | ChatResponseToolStarted
  | ChatResponseToolDone;

export type DataFromWarehouseResponse = {
  isSqlOnly: boolean;
  sqlGenerated?: string;
  nlqResponse?: NlqResponse;
  error?: boolean;
  errorMessages?: string[];
  errorForInterface?: string;
};

export type ToolFunctionsDictionary = {
  TextToSQL: DataFromWarehouseResponse;
  SQLModifier: DataFromWarehouseResponse;
  SQLExecutor: NlqResponse;
  SQLExplainer: Record<string, any>;
  HelpCenter: { text: string; links: HelpCenterLink[] };
  WebBrowsing: { text: string };
  TextToPython: CodeInterpreterResponse;
  Forecasting: LongForecastingViaNLQResponse;
  CausalImpact: Record<string, any>;
};

export type ToolResult =
  | ({
      name: 'TextToSQL';
    } & ToolFunctionsDictionary['TextToSQL'])
  | ({
      name: 'SQLModifier';
    } & ToolFunctionsDictionary['SQLModifier'])
  | ({
      name: 'SQLExecutor';
    } & ToolFunctionsDictionary['SQLExecutor'])
  | ({
      name: 'SQLExplainer';
    } & ToolFunctionsDictionary['SQLExplainer'])
  | ({
      name: 'HelpCenter';
    } & ToolFunctionsDictionary['HelpCenter'])
  | ({
      name: 'WebBrowsing';
    } & ToolFunctionsDictionary['WebBrowsing'])
  | ({
      name: 'TextToPython';
    } & ToolFunctionsDictionary['TextToPython'])
  | ({
      name: 'Forecasting';
    } & ToolFunctionsDictionary['Forecasting'])
  | ({
      name: 'CausalImpact';
    } & ToolFunctionsDictionary['CausalImpact']);

export type ToolProgress = {
  name: WillyToolName;
  progress: number;
  processId: string;
  step: string;
  body?: string;
  language?: SupportedLanguages;
};

export type WillySocketResponsePayload = (
  | NlqErrorResponse
  | NlqActionResponse
  | NlqDatabaseResponse
  | NlqDoneResponse
  | NlqInsightResponse
  | NlqProgressResponse
  | NlqRunCodeResponse
) &
  BaseEventPayload;

export type ChatResponse = (res: ChatResponses) => void;
export type WillySocketResponse = (res: WillySocketResponsePayload) => void;

export type SequenceProgressEvent = {
  type: 'step-started' | 'sequence-started' | 'sequence-done' | 'sequence-error' | 'progress';
  messageId: string;
  sequenceId: string;
  runId: string;
  text: string;
};

export type MetricsDataEvent = {
  messageId: string;
  metricId: string;
  data: NlqResponse;
  error?: string;
  previous?: boolean;
  shopId: string;
};

export type NlqErrorResponse = {
  error: string;
  type: 'error';
};

export type NlqActionResponse = {
  error?: string;
  data: MainActionResult;
  question: string;
  type: 'action';
};

export type NlqDatabaseResponse = {
  message?: string;
  data?: string;
  step: Step;
  queryId: string;
  type: 'data';
  processId: string;
};

export type NlqInsightResponse = {
  text: string;
  type: 'insight';
};

export type NlqDoneResponse = {
  type: 'done';
  data: {
    toolsNames: WillyToolName[];
    toolArgs: string[];
  };
  followupQuestions: string[];
};

export type NlqProgressResponse = {
  type: 'progress';
  progress?: number;
  progressMessage?: string;
};

export type NlqFileCodeResponse = {
  name: string;
  type: string;
  url: string;
  size: number;
};

export type FileWithData = {
  id: string;
  query: string;
  file_name: string;
  data: NlqCodeData[];
};

export type NlqCodeData = Record<string, any>;

export type NlqRunCodeResponse = {
  processId: string;
  text: string;
  type: 'code';
  step: Step;
  trial?: number;
  files?: NlqFileCodeResponse[];
  data?: NlqResponse;
  executionId?: string;
  queries?: FileWithData[];
};

export type NlqForecastResponse = {};

export class WillySocket extends Socket<WillyListenEvents, WillyEmitEvents> {}

export type WillyBaseMetric = {
  key: string;
  isDimension?: boolean;
  pinned?: 'left' | 'right' | boolean | null;
  name: string;
  description: string;
  format: valueFormats;
  dateFormat?: string;
  isDate?: boolean;
  toFixed: number;
  valueIsNegative?: boolean;
  color: string;
  icon: WillyEmoji;
  columnWidth?: number;
  mobileColumnWidth?: number;
  minimumFractionDigits?: number;
  colorName?: string;
  active?: boolean;
  hidden?: boolean;
  hiddenBecauseHasNoData?: boolean;
  chartType?: ChartType;
  yAxisId?: 'left' | 'right';
  showMean?: boolean;
  showMeanLabel?: boolean;
  showMode?: boolean;
  showModeLabel?: boolean;
  showMedian?: boolean;
  showMedianLabel?: boolean;
  sort?: 'asc' | 'desc';
  groupByColumn?: boolean;
  applyOnGlobalConditionalFormatting?: boolean;
  conditionalStyleType?: 'singleValue' | 'scale' | 'none';
  conditionalStyleValue?: string;
  conditionalStyleColor?: string;
  isBlocked?: boolean;
  v2metricId?: string;
};

export type WillyMetric = {
  onClickAction?: 'query' | 'url' | 'none' | 'function';
  onClickActionUrls?: { value: string; url: string; openInNewTab?: boolean }[];
  functionName?: string;
  popupWidget?: WillyWidgetElement | null;
  queryVars?: string[];
  expressionMetric?: boolean;
} & WillyBaseMetric;

export type ExpressionMetricData = {
  id: string;
  isGlobal?: boolean;
  relatedProvider?: ServicesIds;
  isNotConnected?: boolean;
  isBlocked?: boolean;
  isCustomMetric?: boolean;
} & WillyBaseMetric;

export type WillyExpressionMetric = {
  tableId: string;
  columnId: string;
  aggFunction?: AggregationFunction;
  formula?: string;
  filter?: FilterGroup[];
} & ExpressionMetricData;

export type WillyExpressionMetricToServer = {
  tableId: string;
  columnId: string;
  aggFunction?: AggregationFunction;
  formula?: string;
  filter?: FilterRow[][];
} & ExpressionMetricData;

export type WillyCustomMetric = {
  expression: ExpressionElement[];
} & ExpressionMetricData;

export type WillyExpressionOrCustomMetric = WillyExpressionMetric | WillyCustomMetric;

export type WillyExpressionOrCustomMetricToServer =
  | WillyExpressionMetricToServer
  | WillyCustomMetric;

export enum ElementTypes {
  OPERATOR = 'operator',
  INTEGER = 'integer',
  METRIC = 'metric',
  PARENTHESES = 'parentheses',
}

export type ExpressionElement = {
  id: string;
  title: string;
  value?: any;
  isGlobal?: boolean; // relevant only for metrics
  isCustomMetric?: boolean; // relevant only for metrics
  type: ElementTypes;
  isSelected?: boolean;
};

export type ExpressionElementsMap = { [key: number]: ExpressionElement[] };
// to here

export type WillyDataColumn = {
  x: string[];
  y: string[];
};

export type WillyParameter = {
  column: string;
  operator?: FilterOperator;
  value: string | string[];
  options?: string[];
  visible?: boolean;
  disabled?: boolean;
  visibleInDashboard?: boolean;
  isQueryParameter?: boolean;
  mustBeVisible?: boolean;
  query?: string;
  sourceType?: 'sql' | 'forecast';
};

export type RCERequest = {
  pyCode: string;
  inputs?: {
    data: any;
    name: string; // ./input/name.json
    type: 'json' | 'csv' | 'url';
  }[];
};

export type CodeExecutionResponse = {
  executionId: string;
  code: string;
  dataFiles?: NlqFileCodeResponse[];
  filesOutput?: NlqResponse;
  output?: string;
  executionError?: string;
};

export type CodeInterpreterResponse = {
  processId?: string;
  pythonCode?: string;
  executionId?: string;
  error?: string;
  codeResults?: string;
  files?: NlqFileCodeResponse[];
  queries?: FileWithData[];
  data?: NlqResponse;
  step?: string;
  progress?: number;
};

export type HistoryItem = {
  createdAt: string;
  role: 'user' | 'assistant' | 'tool';
  text: string;
  data?: NlqResponse[];
  originalQuestion?: string;
  actions?: MainActionResult[];
  messageId: string;
  error?: string;
  title?: string;
  notes?: string[];
  toolsNames?: WillyToolName[];
  userId?: string;
  codeInterpreterResponse?: CodeInterpreterResponse[];
  toolResults?: ToolResult & { nlqResponse?: MessageData };
  tool_calls?: { function: any; id: string; type: 'function' }[];
};

export type NlqResponseDataType = 'nlq' | 'forecast' | 'causal';

export type NlqResponse = {
  bq?: number;
  CDPSegmentId?: string;
  conversationId?: string;
  data: RawNlqData;
  dataColumns: WillyDataColumn;
  dataType?: NlqResponseDataType;
  error?: string;
  errorForInterface?: string;
  fromNlq?: boolean;
  generatedQuery?: string;
  datesRange?: string;
  id?: string;
  messageId?: string;
  originalQuestion?: string;
  parameters?: WillyParameter[];
  queryId?: string;
  question?: string;
  returnQueryOnly?: boolean;
  serviceIds?: ServicesIds[];
  shopOwner?: string;
  userOwner?: string;
  validSql?: boolean;
  verified?: boolean;
  dialect?: Dialect;
  tables?: string[] | string;

  // not from the response, enriched by the frontend
  queries?: WidgetQuery[];
};

export type LongForecastingResponse = {
  message: string;
  data_for_forecast: string;
  forecast_nlq_format: RawNlqData[];
  forecast: string;
  algorithm: string;
  mape_1la_val?: number | null | undefined;
  mape_1la_test?: number | null | undefined;
  mape_1la_val_sample_len?: number | null | undefined;
  mape_1la_test_sample_len?: number | null | undefined;
  forecast_1la_nlq_format?: RawNlqData[] | null | undefined;
};

export type LongForecastingViaNLQResponse = {
  forecastingResponse?: LongForecastingResponse;
  message?: NlqResponse;
  error?: string;
  mape_1la_val?: number | undefined;
  mape_1la_test?: number | undefined;
  mape_1la_val_sample_len?: number | undefined;
  mape_1la_test_sample_len?: number | undefined;
};

export type MessageTypes =
  | 'text'
  | 'tile'
  | 'table'
  | 'chart'
  | 'pie'
  | 'funnel'
  | 'map'
  | 'json'
  | 'summaryBox';

export type MessageTypeElement<T> = {
  id: T;
  title: string;
  icon: JSX.Element;
  widgetMinWidth: number;
  widgetMinHeight: number;
  widgetDefaultHeight: number;
  disabled?: boolean;
  tooltip?: string;
  decorativeIcon?: JSX.Element;
};

export type MessageTypesDictionary = {
  [key in MessageTypes]: MessageTypeElement<key>;
};

export type ChartDataItem = Record<
  string,
  {
    metric: string;
    value: number;
  }
>;

export interface ChartXAxisItem {
  x: string | number;
  sortingKey?: number;
}

export type ChartData = (ChartDataItem | ChartXAxisItem)[];

export type ChartInfo = {
  data: ChartData;
  stackedData?: ChartData;
};

export type TableData = { columns: BaseColumn<any, any>[]; data: any };

export type RawNlqData = {
  name: string;
  value: (number | string)[];
}[];

export type MessageData = {
  processId?: string;
  data?: RawNlqData;
  dataColumns?: WillyDataColumn;
  metrics?: WillyMetric[];
  parameters?: WillyParameter[];
  queryId?: string;
  question?: string;
  serviceIds?: ServicesIds[];
  query?: string;
  dataType?: NlqResponseDataType;
  progressInfo?: {
    progress: number;
    text: string;
  };
};

export type Message = {
  id: string;
  role: 'user' | 'assistant' | 'tool';
  data?: MessageData[];
  text?: string;
  question?: string;
  originalQuestion?: string;
  loading?: boolean;
  building?: boolean;
  progress?: number;
  progressText?: string;
  verified?: boolean;
  conversationId?: string;
  actions?: MainActionResult[];
  error?: string;
  hideWidgetHeader?: boolean;
  hideFeedback?: boolean;
  title?: string;
  notes?: string[];
  CDPSegmentId?: string;
  toolsNames?: WillyToolName[];
  userId?: string;
  codeInterpreterResponse?: CodeInterpreterResponse[];
  type?: MessageTypes;
  toolResults?: ToolResult;
  toolProgress?: ToolProgress;
  dialect?: Dialect;
};

export type ConversationUiOptions = {
  actionsMenuOpen?: boolean;
  renameMode?: boolean;
};

export type Conversation = {
  id: string;
  history: HistoryItem[];
  title?: string;
  user: string;
  v: number;
  createdAt: InstanceType<typeof Timestamp>;
  model: 'nlq';
  source: ChatSources;
  users?: string[];
} & ConversationUiOptions;

export type SequenceRun = {
  finishedAt: InstanceType<typeof Timestamp>;
  history: HistoryItem[];
  id: string;
  isGlobal?: boolean;
  runAt: InstanceType<typeof Timestamp>;
  sequenceId: string;
  success?: boolean;
  userId: string;
  trigger: 'schedule' | 'manual' | 'unknown';
};

export type AssistantMessageData = {
  generatedQuery: string;
  serviceIds?: string[];
  validSql?: boolean;
  dataType?: NlqResponseDataType;
};

export type CodeInterpreterData = {
  code?: string;
  executionId: string;
  error?: string | null;
};

export type AssistantMessage = {
  originalQuestion: string;
  role: 'assistant' | 'tool';
  data: AssistantMessageData[];
  codeInterpreter: CodeInterpreterData[];
  toolNames?: WillyToolName[];
};

export type WillyDataSequence = {
  id: string;
  history: HistoryItem[];
  assistantMessages: AssistantMessage[];
  name: string;
  user: string;
  createdAt: firebase.firestore.Timestamp;
  fromConversationId: string;
  startingDashboard?: string;
  startingDashboardDate: DatePickerTimePeriods | null;
  isGlobal?: boolean;
  schedule?: ScheduleItem;
  v: number;
} & ConversationUiOptions &
  WillyBaseMainElement;

export type WillySequenceInDashboard = {
  id: string;
  title: string;
  content: string;
  author: string;
  createdAt: string;
  updatedAt: string;
  read: boolean;
  sequenceId: string;
  scheduleId: string;
  shopId: string;
  dashboardId?: string;
};

export type DashboardDataWithSequenceHistory = WillyDashboardElement & {
  history: WillyDataSequence[];
};

export type SequencesReportsListProps = {
  dashboardId?: string;
  isSmall?: boolean;
  isAll?: boolean;
};

export type SequencesReportsListModalProps = SequencesReportsListProps & {
  open: boolean;
  onClose: () => void;
};

export type WidgetQuery = {
  id: string;
  question: string;
  query: string;
  explanation?: string;
  fromMetricBuilder?: boolean;
};

export type SqlSnippet = {
  id: string;
  snippet: string;
  name: string;
  isGlobal?: boolean;
  optionsOpen?: boolean;
  dialect: Dialect;
};

export type TileModes = 'tile' | 'table';

export type GridOptions = 'flex' | 'grid';

export type GridColumnOptions = 2 | 3 | 4;

export type WillyWidgetElement = {
  title: string;
  queryId: string;
  withoutMainQuery?: boolean;
  type: MessageTypes;
  stacked: boolean;
  incrementedStacked: boolean;
  metrics: WillyMetric[];
  dataMetricIds?: string[];
  wrapText?: boolean;
  queries?: WidgetQuery[];
  parameters?: WillyParameter[];
  verified?: boolean;
  grid?: GridOptions;
  gridColumns?: GridColumnOptions;
  twoColumnMobile?: boolean;
  tileMode?: TileModes;
  permission?: WidgetPermissions;
  skinny?: boolean;
  yAxisDomain?: AxisDomain;
  allowDataOverflow?: boolean;
  /**
   * Dimension for the chart x-axis if not provided, the first dimension will be used.
   */
  dimension?: string;
  /**
   * Custom views dashboards can have one pinned section.
   */
  pinnedSection?: boolean;
  hidden?: boolean;
  isProviderNotConnected?: boolean; // calculated should not store in DB
  isProviderLocked?: boolean; // calculated should not store in DB
  hasGlobalConditionalFormatting?: boolean;
  globalConditionalFormattingColor?: string;
  breakdownMode?: boolean;
  codeResult?: CodeInterpreterResponse;
  dataType?: NlqResponseDataType;
  supportMetricsFromLibrary?: boolean;
  historicalQueryIds?: string[];
  dialect: Dialect;
  updatedAt?: firebase.firestore.Timestamp;
  mode?: 'sql' | 'builder';
  builderSetup?: BuilderTable;
  filtersOpen?: boolean;
};

export type WillyFieldTypes = 'text' | 'image';

export type WillySection = {
  id: string;
  hidden?: boolean;
  dialect?: Dialect;
  isProviderNotConnected?: boolean;
  isProviderLocked?: boolean;
  title: string;
  sectionType: 'widget' | 'field';
  type: MessageTypes | WillyFieldTypes;
};

export type WillyFieldElement = {
  id: string;
  title: string;
  type: WillyFieldTypes;
  content: string;
  author: string;
  createdAt: string;
  updatedAt: string;
  hidden?: boolean;
  fromSequenceScheduleId?: string;
};

export type WillyDashboardVersionElement = {
  version: string;
  whatNew: string[];
  createdAt: string;
};

export type WillyElementType = 'dashboard' | 'sequence';

// TODO: Ask about "admin" permission
export type WillyDashboardPermission = 'owner' | 'editor' | 'viewer' | 'none';

export type WillyDashboardAccessType = 'owner' | 'shop' | 'invite';

export type WillyBaseMainElement = {
  /** calculate should not store in DB */
  type: WillyElementType;
  name: string;
  id: string;
  description: string;
  reportPrompt?: string;
  /** calculate should not store in DB */
  canView?: boolean;
  /** calculate should not store in DB */
  canEdit: boolean;
  /** calculate should not store in DB */
  canDelete?: boolean;
  users?: string[];
  admins?: string[];
  /** relevant only to global */
  isBeta?: boolean;
  /** relevant only to global */
  isFavoriteByDefault?: boolean;
  /** relevant only to global */
  order?: boolean;
  /** calculated should not store in DB */
  isGlobal: boolean;
  /** relevant only to global */
  image?: string;
  emoji?: WillyEmoji;
  dialect?: DialectWithBoth;
  createdAt: firebase.firestore.Timestamp;
  updatedAt: firebase.firestore.Timestamp;
  /** calculated should not store in DB - relevant only to global */
  installCount?: number;
  /** relevant only to global */
  category?: string;
  /** relevant only to global */
  roles?: string[];
  /** relevant only to global */
  isHide?: boolean;
  /** calculated should not store in DB - relevant only to global */
  packages?: FeatureFlagConfigKey[];
  /** calculated should not store in DB - relevant only to global */
  defaultPackages?: FeatureFlagConfigKey[];
  /** relevant only to global */
  providers?: ServicesIds[];
  /** relevant only to global */
  providersBlockingCombination?: 'AND' | 'OR';
  /** relevant only to shop, calculated should not store in DB */
  isFavorite?: boolean;
  version?: string;
  /** relevant only to shop //TODO need to rename it to something more global (not correct for sequence) */
  globalDashboardId?: string;
  /** The user doesn't want to be asked for further notification to update to this specific version of the dashboard. */
  noNotificationVersion?: string | null;
  information?: string;
  /** calculated should not store in DB */
  isLocked?: boolean;
  /** calculated should not store in DB */
  isProviderLocked?: boolean;
  versions?: WillyDashboardVersionElement[];
  msps?: SalesPlatform[];

  /**
   * Determines the base access necessary for this dashboard.
   * - `shop` means all users of the shop get base `permission`.
   * - `invite` means only users that were invited get access.
   */
  generalAccess?: Partial<{
    type: WillyDashboardAccessType;
    permission: WillyDashboardPermission;
  }>;

  /**
   * Record of user ids of users that have some sort of access to this
   * dashboard and the permissions each user has.
   */
  userPermissions?: Record<string, WillyDashboardPermission>;
  existsInStore?: boolean; // relevant for templates
  credits?: number;
};

export type WillyDashboardElement = {
  widgetIds: string[];
  isUseDashboardDefaultDate?: boolean;
  currentDateRange?: CurrentDateRange;
  prevDateRange?: PrevDateRange;
  isDnd?: boolean; //RGL vs Dnd-Kit
  isFullWidth?: boolean;
  layout?: string; // of type ReactGridLayout.Layout[];
  editing?: boolean;
  editor?: string;
  isDynamic?: boolean;

  widgets?: WillyWidgetElement[];
  fields?: WillyFieldElement[];
  history: any[];

  isCustomView?: boolean; // calculated should not store in DB - only to custom view
  isCustomViewOfDashboardId?: string; // relevant only to custom view
  isCustomViewOfShopId?: string; // relevant only to custom view
  pinnedMetricKeys?: string[];

  // relevant only to "standard dashboards"
  isStandardDashboard?: boolean;
  link?: string;
  isSqlTeamReport?: boolean;
} & WillyBaseMainElement;

export const ChartTypeArr = ['bar', 'stacked-bar', 'line', 'area', 'scatter'] as const;

export type ChartType = (typeof ChartTypeArr)[number];

export type ChartWithComboType = ChartType | 'combo';

export type ChartTypeItem<K> = {
  title: string;
  id: K;
  icon: IconName;
  decorativeIcon?: JSX.Element;
};

export type ChartTypeDict = {
  [key in ChartType]: ChartTypeItem<key>;
};

export type sqlBuilderAi = {
  messageId?: string;
  loading?: boolean;
  streaming?: boolean;
  error?: string;
  existingQuery?: string;
  text?: string;
};

export type LogItem = {
  log: string;
  time: Date;
  type: 'success' | 'error' | 'info';
};

export type SavedQueryType = 'query' | 'snippet' | 'customMetric' | 'rule';

export type EditorInstance = {
  model: editor.ITextModel;
  state: editor.ICodeEditorViewState | null;
  query: string;
  tabName: string;
  language: 'sql' | 'python';
  jsonData?: string;
  nameChanged: boolean;
  schema: BqTable[];
  filterBuilder: FilterBuilderType;
  sqlBuilderAI?: sqlBuilderAi;
  nameEditable?: boolean;
  queryId?: string;
  savedQueryType?: SavedQueryType;
  active?: boolean;
  touched?: boolean;
  loading?: boolean;
  loadingSave?: boolean;
  error?: string;
  logs?: LogItem[];
};

export type TableTab = {
  id: 'campaigns' | 'ad_sets' | 'ads';
  label: React.ReactNode;
  loading?: boolean;
  disabled?: boolean;
};

export type SavedQuery = {
  id: string;
  name: string;
  query: string;
  language: 'sql' | 'python';
  jsonData?: string;
  updatedAt: Date;
  columns?: BqColumn[];
};

export type BreakdownValue = {
  metric: string;
  value: string | number;
  date?: string;
  prevDate?: string;
}[];

export type GptMessage = {
  role: 'user' | 'assistant';
  content: string;
  type?: 'error' | 'data' | 'loading';
  id?: string;
};

export function typeIsChartType(t: string): t is ChartType {
  return ChartTypeArr.includes(t as ChartType);
}

export type Category = {
  id: string;
  title: string;
};

export type MainQueryChangedParams = {
  newQuery: NlqResponse;
  oldQueryId: string;
  dashboardId: string;
  isGlobalDashboard?: boolean;
  isCustomViewDashboard?: boolean;
  queryParameters?: WillyParameter[];
};

export type EditMetricModalPayload = (params: {
  open: boolean;
  queryId?: string;
  metricId?: string;
}) => void;

export type AxisDomain = { min: AxisDomainItem; max: AxisDomainItem };

export type MetricTree = {
  value: ExpressionElement[];
} & Omit<WillyCustomMetric, 'expression'>;

export type WidgetPermissions = {
  providers: Array<ServicesIds>;
};

export type WillyCustomTable = {
  name: string;
  description: string;
  table: BqTable['name'];
  metrics: WillyMetric[];
};

export const updatableFieldsArray = [
  'campaign_daily_budget',
  'campaign_lifetime_budget',
  'adset_daily_budget',
  'adset_lifetime_budget',
] as const;

export type UpdatableField = (typeof updatableFieldsArray)[number];

export type ScheduleType = 'email' | 'dashboard';
export type ScheduleRulesRow = { condition: 'OR' | 'AND'; ruleIds: string[] };

type ScheduleStatus = 'running' | 'paused' | 'error' | 'scheduled' | 'skipped';

export const ScheduleTriggers = ['time', 'rules'] as const;
export type ScheduleTrigger = (typeof ScheduleTriggers)[number];

export type ScheduleItem = {
  id: string;
  sequenceId: string;
  userId: string;
  shopId: string;
  name: string;
  email: string;
  scheduleType: ScheduleType[];
  scheduleTrigger: ScheduleTrigger;
  dashboardId: string;
  days: DayOfWeek[];
  hours: number[];
  interval: string | null; // Duration of "ms" (https://typescript.temporal.io/api/interfaces/client.IntervalSpec)
  rules: ScheduleRulesRow[];
  createdAt: Date;
  updatedAt: Date;
  status?: ScheduleStatus;
  error?: string;
};

export const DayOfWeekArr = [
  'SUNDAY',
  'MONDAY',
  'TUESDAY',
  'WEDNESDAY',
  'THURSDAY',
  'FRIDAY',
  'SATURDAY',
] as const;

export type DayOfWeek = (typeof DayOfWeekArr)[number];

export type ActionSendEmail = {
  type: 'sendEmail';
  title: string;
  emailAddress: string;
};

export type ActionUpdateDashboard = {
  type: 'updateDashboard';
  title: string;
  dashboardId: string;
  dashboardType: 'global' | 'shop' | 'customView';
};

export type ScheduleAction = ActionSendEmail | ActionUpdateDashboard;

export type ScheduleSequenceParams = {
  shopId: string;
  userId: string;
  scheduleId: string;
  sequenceId: string;
  trigger: ScheduleTrigger;
  schedule: {
    dayOfWeek: DayOfWeek[];
    hour: number[];
  }[];
  interval: string | null;
  rules: ScheduleRulesRow[];
  currency: string;
  timezone: string;
  actions: ScheduleAction[];
};

export type TemporalScheduleInfo = {
  info: {
    nextActionTimes: string[];
    recentActions: {
      takenAt: string;
    }[];
  };
};

export const willyToolNamesArr = [
  'TextToSQL',
  'SQLModifier',
  'SQLExecutor',
  'SQLExplainer',
  'HelpCenter',
  'WebBrowsing',
  'TextToPython',
  'Forecasting',
  'CausalImpact',
] as const;

export type WillyToolName = (typeof willyToolNamesArr)[number];

export type WillyTool<T extends WillyToolName> = {
  toolName: T;
  title: string;
  color?: FormattedColor;
  icon?: IconName;
};

export type WillyToolMap = {
  [K in WillyToolName]: WillyTool<K>;
};

export type ExecuteCustomQueryParams = {
  query: string;
  shopId: string;
  activeAccounts: string[];
  currency: string;
  timezone: string;
  queryParams?: Record<string, string>;
  usePreAgg?: boolean;
  dialect: Dialect;
};

export const ForecastAlgorithms = ['linear', 'seasonal', 'auto', 'prophet'] as const;

export type ForecastAlgorithms = (typeof ForecastAlgorithms)[number];

export type WillyPrompt = {
  category: string;
  subCategory: string;
  title: string;
  prompt: string;
  isPopular?: boolean;
  isFeatured?: boolean;
  /**
   * The prompt came from DB
   */
  id?: string;
  /**
   * The prompt came from DB
   */
  userId?: string;
};

export type WillyOperatorDict = {
  [key in FilterOperator]: WillyOperatorItem<key>;
};

export type PreloadDashboardData = {
  generatedQuery: string;
  question: string;
  data: any[];
};

export type SummaryData = {
  calculatedStats?: RootState['calculatedStats'];
  previousPeriodCalculatedStats?: RootState['previousPeriodCalculatedStats'];
  activeMetrics?: BaseSummaryMetric<SummaryMetricIdsTypes>[];
};

export type PixelData = AttributionData[];

export type SimilarResourceDocument = {
  header: string;
  mediaUrls: string[];
  url: string;
};

export type SimilarTemplateDocument = {
  id: string;
  globalDashboardId: string;
  name: string;
  description: string;
  image: string;
};

export type RelatedTemplateResponse = {
  error: string | null;
  prepared_text: string;
  similar_documents: SimilarTemplateDocument[];
};

export type RelatedResourceResponse = {
  error: string | null;
  prepared_text: string;
  similar_documents: SimilarResourceDocument[];
};

export const deprecatedToolNames = [
  'tryToGetDataFromBigQuery',
  'runQuery',
  'modifyQuery',
  'explainQuery',
  'helpCenter',
  'webSearch',
  'codeInterpreter',
  'forecaster',
  'causalImpact',
] as const;

export type DeprecatedToolNames = (typeof deprecatedToolNames)[number];
