import CircleLockIcon from 'components/library/LockedFeatures/CircleLockIcon';
import module_styles from './LockDecoratorWithTooltip.module.scss';
import { useFeatureFlagComputer } from '../../featureFlagComputer';
import { CSSProperties, PropsWithChildren, useLayoutEffect, useRef, useState } from 'react';
import { Button as TwButton } from 'components/BaseComponents/Button';
import { FeatureFlag } from '@tw/feature-flag-system/module/types';
import { useSelectByWindowResize } from 'hooks/useSelectByWindowResize';
import { useAppDispatch } from 'index';
import { upgradePlanClicked } from 'ducks/subscription';
import { useHistory } from 'react-router';

export interface ILockDecoratorWithTooltip extends PropsWithChildren {
  featureFlag: FeatureFlag;
  blockedFeatureText?: string;
  tooltipMessage?: string;
  lockSize?: number;
  lockPosition?: { top: number; left: number };
  FallbackComponent?: JSX.Element;
}

export const LockDecoratorWithTooltip: React.FC<ILockDecoratorWithTooltip> = ({
  children,
  featureFlag,
  blockedFeatureText,
  tooltipMessage,
  lockSize = 16,
  lockPosition = { top: -6, left: 10 },
  FallbackComponent = null,
}) => {
  const dispatch = useAppDispatch();
  const history = useHistory();

  // REFS
  const ref = useRef<HTMLDivElement>(null);
  const tooltipRef = useRef<HTMLDivElement>(null);
  const childrenRef = useRef<HTMLDivElement>(null);

  // FEATURE FLAG CONFIGURATION INFORMATION
  const ffComputer = useFeatureFlagComputer();
  const { shouldNotBeSeen } = ffComputer.getConfigById(featureFlag);
  tooltipMessage ??= (() => {
    const { name } = ffComputer.getRequiredPlanToUnhideFeature(featureFlag);
    const nextPlanText = name ? `to '${name.trim()}'` : '';
    return `Upgrade ${nextPlanText} to access ${blockedFeatureText}.`;
  })();

  // WINDOW STATE
  // TODO: Could we put the condition in the selectors to reduce rerenders?
  const windowWidth = useSelectByWindowResize(({ width }) => width);
  const windowHeight = useSelectByWindowResize(({ height }) => height);

  // VISUAL STATE
  const [x, setX] = useState<number>(0);
  const [y, setY] = useState<number>(0);
  const [scrolled, setScrolled] = useState<boolean>(false);

  // STYLES
  const dynamicStyles = { '--top': y + 'px', '--left': x + 'px' } as CSSProperties;

  // CALLBACKS
  const upgradeClicked = () => dispatch(upgradePlanClicked(history));

  // X AND Y POSITION EFFECTS
  useLayoutEffect(() => {
    if (!tooltipRef.current || !childrenRef.current) return;
    const { height: cHeight, y: cY } = childrenRef.current.getBoundingClientRect();
    const { height: tHeight } = tooltipRef.current.getBoundingClientRect();
    setY(windowHeight - cY > cY ? cHeight : -tHeight - cHeight / 2);
  }, [windowHeight, scrolled]);

  useLayoutEffect(() => {
    if (!tooltipRef.current || !childrenRef.current) return;
    const { width: cWidth, x: cX } = childrenRef.current.getBoundingClientRect();
    const { width: tWidth } = tooltipRef.current.getBoundingClientRect();
    setX(cX + tWidth + cWidth > windowWidth ? -tWidth + cWidth : 0);
  }, [windowWidth, scrolled]);

  useLayoutEffect(() => {
    // TODO: To make more efficient, find a way to save previous scroll values in refs and rerender only according to condition
    const func = () => setScrolled((x) => !x); // just to trigger a rerender
    document.addEventListener('scroll', func, true);
    return () => document.removeEventListener('scroll', func, true);
  }, []);

  // if feature is available, don't show decorator at all
  if (!shouldNotBeSeen) return <>{children}</>;

  // Lock decorator is supposed to "decorate" Fallback. W/o Fallback, no point showing lock.
  if (!FallbackComponent) return null;

  return (
    <div ref={ref} className={module_styles.lockIconDecorator} style={dynamicStyles}>
      <div
        style={{
          position: 'absolute',
          top: lockPosition.top + 'px',
          left: lockPosition.left + 'px',
          zIndex: 999,
        }}
      >
        <CircleLockIcon size={lockSize} />
      </div>

      <div ref={tooltipRef} className={module_styles.lockDecoratorTooltip}>
        {tooltipMessage}
        <TwButton onClick={upgradeClicked} primary>
          Upgrade
        </TwButton>
      </div>

      <div ref={childrenRef}>{FallbackComponent}</div>
    </div>
  );
};
