import { $activeAccounts, $currentShopId, $shop } from '$stores/$shop';
import { $activeAppVersion } from '$stores/nav-config-stores';
import { useComputedValue, useStoreValue } from '@tw/snipestate';
import { Badge, Icon, Image, Text } from '@tw/ui-components';
import { useAppSelector } from 'reducers/RootType';

export function ShopSelectItem({
  iconOnly = false,
  showArrows = true,
}: {
  iconOnly?: boolean;
  showArrows?: boolean;
}) {
  const shopId = useStoreValue($currentShopId);
  const shopName = useComputedValue($shop, (shop) => shop.shopName);
  const shopIcon = useAppSelector((state) => state.shopIcon);
  const activeAccounts = useStoreValue($activeAccounts);
  const activeAppVersion = useStoreValue($activeAppVersion);

  return (
    <div className="relative flex items-center justify-start gap-4">
      <Image src={shopIcon} w={!showArrows ? 28 : 22} h={!showArrows ? 28 : 22} />
      {activeAccounts && activeAccounts.length > 1 && activeAppVersion === '3.0' && (
        <div className="absolute left-[7px] bottom-[-7px]">
          <Badge size="xs" radius="xl" variant="filled">
            + {activeAccounts.length - 1}
          </Badge>
        </div>
      )}
      {!iconOnly && (
        <Text size="md" fw={500}>
          {(shopName || shopId || '').replace('.myshopify.com', '')}
        </Text>
      )}
      {showArrows && <Icon name="up-down-arrows" size={12} color="gray.4" />}
    </div>
  );
}
