import { base64ToFile } from '../../../utils/willyUtils';
import React from 'react';
import { WillyDashSettings } from 'components/Willy/dashboardManagment/WillyDashSettingsModal';

export const WillyActionCreateDashboard = ({ data }) => {
  const { name, description, isDynamic, imageData } = data;
  let defaultFile: File | undefined = undefined;
  if (imageData) {
    const fileName: string = 'dashboard.png';
    const file: File = base64ToFile(imageData, fileName, 'image/png');
    defaultFile = file;
  }

  return (
    <WillyDashSettings
      newDashboard={true}
      defaultData={{
        defaultName: name,
        defaultDescription: description,
        defaultIsDynamic: isDynamic,
        defaultFile,
      }}
    />
  );
};
