import { $isFullAccessToChat } from '$stores/willy/$subscription';
import {
  $unlock_moby_modal,
  closeUnlockMobyModal,
  openFreeTrialPopup,
} from '$stores/willy/$upgradePopupManager';
import { useStoreValue, useWritableStore } from '@tw/snipestate';
import { Button, Card, Flex, Icon, Modal, Text, Title } from '@tw/ui-components';
import { useFoundersDashUpgradeButton } from 'components/UpgradePlan/components/FDUpgradeButton';
import { useIsSmall } from 'hooks/useDefaultWindowSizes';
import { useCallback, useEffect } from 'react';
import { useHistory } from 'react-router';
import { useAppSelector } from 'reducers/RootType';

type UnlockMobyPopupProps = {};

export const UnlockMobyPopup: React.FC<UnlockMobyPopupProps> = ({}) => {
  const isFreeShop = useAppSelector((state) => state.isFreeShop);
  const isFullAccess = useStoreValue($isFullAccessToChat);
  const history = useHistory();

  const isSmall = useIsSmall();
  const { buttonText, action } = useFoundersDashUpgradeButton('unlock_moby_popup');
  const unlockMobyModal = useStoreValue($unlock_moby_modal);

  return (
    <Modal.Root
      size="90vw"
      maw="1140px"
      centered
      opened={unlockMobyModal.opened}
      onClose={() => closeUnlockMobyModal()}
      p={0}
    >
      <Modal.Overlay />
      <Modal.Content mah="95vh" p={0}>
        <div className="flex items-end p-3">
          <Modal.CloseButton />
        </div>
        <Modal.Body p={0}>
          <div className="flex justify-around p-[30px] pt-0 lg:pb-0 flex-col items-center gap-5 bg-[url('/upgrade_moby_bg.png')] overflow-y-scroll">
            <div className="text-blue-600 uppercase font-semibold text-xl	">
              {isFullAccess ? 'full access' : 'Unlock Moby Pro'}
            </div>

            <Text
              fz={isSmall ? 28 : 36}
              fw={700}
              color="gray.8"
              align={isSmall ? 'center' : 'left'}
            >
              Meet Moby, your AI-powered data analyst
            </Text>
            <Text fw="400" fz={16} maw={600} color="gray.6" align={'center'}>
              Unlock the flexibility to simply ask for the data you need and build workflows to
              accelerate your growth.
            </Text>
            {isFullAccess ? (
              <FullAccessCard />
            ) : (
              <div className="flex flex-col-reverse lg:flex-row w-full mt-10 gap-8 mb-10">
                <div className="w-full lg:w-1/2">
                  <Card withBorder w="100%">
                    <div className="flex flex-col gap-8">
                      <div className="flex flex-col gap-3">
                        <Text fw="700" fz={24} maw={600} color="gray.9">
                          Limited Access
                        </Text>
                        <Text fw="400" fz={16} maw={600} color="gray.6">
                          Intelligent insights to guide your marketing stratey and take your
                          campaigns and ads to the next level.
                        </Text>
                      </div>
                      <div className="flex flex-col gap-2 mt-8">
                        <div className="text-blue-600 uppercase font-semibold text-xl	">
                          What's included
                        </div>
                        <div className="w-full h-[1px] bg-gray-200"></div>
                        <div className="flex flex-col gap-5">
                          <div className="flex gap-5 items-center">
                            <Icon name="circle-check" color="named.6" size={17} />
                            <Text fw="400" fz={16} maw={600} color="gray.7" align={'start'}>
                              Help center for all of your e-commerce marketing questions
                            </Text>
                          </div>
                          <div className="flex gap-5 items-center">
                            <Icon name="circle-check" color="named.6" size={17} />
                            <Text fw="400" fz={16} maw={600} color="gray.7" align={'start'}>
                              Get ad recommendations based on product reviews
                            </Text>
                          </div>
                          <div className="flex gap-5 items-center">
                            <Icon name="circle-check" color="named.6" size={17} />
                            <Text fw="400" fz={16} maw={600} color="gray.7" align={'start'}>
                              Conduct analysis on competitor product
                            </Text>
                          </div>
                          <div className="flex gap-5 items-center">
                            <Icon name="circle-check" color="named.6" size={17} />
                            <Text fw="400" fz={16} maw={600} color="gray.7" align={'start'}>
                              Storyboard ad copy
                            </Text>
                          </div>
                          <div className="flex gap-5 items-center">
                            <Icon name="circle-check" color="named.6" size={17} />
                            <Text fw="400" fz={16} maw={600} color="gray.7" align={'start'}>
                              Get industry trends
                            </Text>
                          </div>
                        </div>
                      </div>
                      <Button variant="white" onClick={() => closeUnlockMobyModal()}>
                        Stay on Current Plan
                      </Button>
                    </div>
                  </Card>
                </div>
                <div className="w-full lg:w-1/2">
                  <Card w="100%" p={0} m={0}>
                    <div className="border-[3px] rounded-[6px] border-solid border-blue-600">
                      <Card>
                        <div className="flex flex-col gap-8">
                          <div className="flex flex-col gap-3">
                            <Text fw="700" fz={24} maw={600} color="gray.9">
                              Full Access
                            </Text>
                            <Text fw="400" fz={16} maw={600} color="gray.6">
                              Strategize for sustained growth with product analytics, creative
                              insights, and cutting edge AI.
                            </Text>
                          </div>
                          <div className="flex flex-col gap-2 mt-8">
                            <div className="text-blue-600 uppercase font-semibold text-xl	">
                              What's included
                            </div>
                            <div className="w-full h-[1px] bg-gray-200"></div>
                            <div className="flex flex-col gap-5">
                              <div className="flex gap-5 items-center">
                                <Icon name="circle-check" color="named.6" size={17} />
                                <Text fw="400" fz={16} maw={600} color="gray.7" align={'start'}>
                                  Quickly generate visualizations and add to your reports
                                </Text>
                              </div>
                              <div className="flex gap-5 items-center">
                                <Icon name="circle-check" color="named.6" size={17} />
                                <Text fw="400" fz={16} maw={600} color="gray.7" align={'start'}>
                                  Forecast your revenue based on past performance
                                </Text>
                              </div>
                              <div className="flex gap-5 items-center">
                                <Icon name="circle-check" color="named.6" size={17} />
                                <Text fw="400" fz={16} maw={600} color="gray.7" align={'start'}>
                                  Get creative recommendations based on past performance
                                </Text>
                              </div>
                              <div className="flex gap-5 items-center">
                                <Icon name="circle-check" color="named.6" size={17} />
                                <Text fw="400" fz={16} maw={600} color="gray.7" align={'start'}>
                                  Analyze product, channel and campaign performance
                                </Text>
                              </div>
                              <div className="flex gap-5 items-center">
                                <Icon name="circle-check" color="named.6" size={17} />
                                <Text fw="400" fz={16} maw={600} color="gray.7" align={'start'}>
                                  Summarize your reports and generate insights
                                </Text>
                              </div>
                            </div>
                          </div>
                          {isFreeShop ? (
                            <Button
                              variant="primary"
                              fullWidth
                              onClick={() => {
                                action();
                                closeUnlockMobyModal();
                              }}
                            >
                              {buttonText}
                            </Button>
                          ) : (
                            <div className="flex flex-col gap-6 items-center w-full">
                              <Button
                                variant="primary"
                                fullWidth
                                onClick={() => {
                                  openFreeTrialPopup('unlock_moby_popup');
                                  closeUnlockMobyModal();
                                }}
                              >
                                Start free trial
                              </Button>
                              <Text color="gray.6" fs="md" fw={400}>
                                Get free access for 30 days.
                              </Text>
                            </div>
                          )}
                        </div>
                      </Card>
                    </div>
                  </Card>
                </div>
              </div>
            )}
          </div>
        </Modal.Body>
      </Modal.Content>
    </Modal.Root>
  );
};

const FullAccessCard = () => {
  return (
    <Card withBorder mt="lg" mb="xl" p="lg">
      <Flex direction="column" gap="lg">
        <Flex direction="column">
          <Text color="gray.8" weight={600} size="lg">
            Get the answers you need
          </Text>
          <Text color="gray.6" weight={400} size="md">
            Moby understands your data. Want to see your LTV for customers coming from Meta? Just
            ask Moby.
          </Text>
        </Flex>
        <Flex direction="column">
          <Text color="gray.8" weight={600} size="lg">
            Build visualizations with Moby
          </Text>
          <Text color="gray.6" weight={400} size="md">
            Moby can build data visuals that you can customize and add to any dashboard.
          </Text>
        </Flex>
        <Flex direction="column">
          <Text color="gray.8" weight={600} size="lg">
            Chat with your dashboards
          </Text>
          <Text color="gray.6" weight={400} size="md">
            Access Moby within any report to get analysis, insights, and actionable recommendations.
          </Text>
        </Flex>
        <Flex direction="column">
          <Text color="gray.8" weight={600} size="lg">
            Get Data Forecasts
          </Text>
          <Text color="gray.6" weight={400} size="md">
            Build accurate forecasts with ease.
          </Text>
        </Flex>
        <Button mt="sm" variant="primary" fullWidth onClick={() => closeUnlockMobyModal()}>
          Start Chatting
        </Button>
      </Flex>
    </Card>
  );
};
