import { FC, useCallback } from 'react';
import { WillyBaseMainElement, WillyDashboardElement } from '../types/willyTypes';
import { baseURL } from 'config';
import { Icon, IconName } from '@tw/ui-components';
import { visibleLength } from '../utils/willyUtils';
import { iconColorMap } from '../constants';

type WillyDashImageProps = { dashboard: WillyBaseMainElement; rounded?: boolean; inCard?: boolean };

export const WillyDashImage: FC<WillyDashImageProps> = ({ dashboard, rounded, inCard }) => {
  const emoji = dashboard.emoji || '🐳';
  const vl = visibleLength(emoji);
  const isIcon = vl > 1;

  const getAvgHex = (color, total) =>
    Math.round(color / total)
      .toString(16)
      // @ts-ignore
      .padStart(2, 0);

  const avgColor = useCallback((emoji, isIcon = false) => {
    if (isIcon) {
      return iconColorMap[emoji as IconName] || '#000';
    } else {
      let totalPixels = 0;
      const colors = {
        red: 0,
        green: 0,
        blue: 0,
        alpha: 0,
      };

      const canvas = document.createElement('canvas');
      const ctx = canvas.getContext('2d');
      // @ts-ignore
      ctx.font = '30px Arial';
      // @ts-ignore
      ctx.fillText(emoji, 0, 28);
      // @ts-ignore
      const { data: imageData } = ctx.getImageData(0, 0, 30, 30);
      for (let i = 0; i < imageData.length; i += 4) {
        let [r, g, b, a] = imageData.slice(i, i + 4);
        if (a > 50) {
          totalPixels += 1;
          colors.red += r;
          colors.green += g;
          colors.blue += b;
          colors.alpha += a;
        }
      }
      const r = getAvgHex(colors.red, totalPixels);
      const g = getAvgHex(colors.green, totalPixels);
      const b = getAvgHex(colors.blue, totalPixels);

      return '#' + r + g + b;
    }
  }, []);

  return (
    <div className={'relative'}>
      <div
        style={{
          background:
            dashboard.image && dashboard?.image === dashboard.id
              ? `url(${baseURL}/v2/media/dashboard-image/${dashboard.id}) no-repeat center center/cover`
              : avgColor(emoji, isIcon),
        }}
        className={`block w-full h-0 pb-[60%] overflow-hidden ${
          rounded || inCard ? 'rounded-xl shadow ' : ' '
        } ${inCard ? 'rounded-b-none' : ''}`}
      />
      {emoji && (!dashboard.image || dashboard?.image !== dashboard.id) && (
        <div className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 text-6xl drop-shadow-lg">
          {isIcon ? <Icon name={emoji as IconName} size={40} id={`emoji-${emoji}`} /> : emoji}
        </div>
      )}
    </div>
  );
};
