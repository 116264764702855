import React, { Fragment, useCallback, useMemo, useState } from 'react';
import {
  $willyCustomMetrics,
  $willyMetrics,
  $willyMetricsCombine,
} from '../../../$stores/willy/$willyMetrics';
import {
  Anchor,
  Button,
  Combobox,
  extractCSSColor,
  Flex,
  Icon,
  IconName,
  Select,
  Text,
  useCombobox,
} from '@tw/ui-components';
import {
  WillyExpressionOrCustomMetric,
  WillyCustomMetric,
  WillyExpressionMetric,
} from 'components/Willy/types/willyTypes';
import { cx } from '../../../utils/cx';
import classes from '../../SummarySectionPicker/SummarySectionPicker.module.scss';
import { useDarkMode } from '../../../dark-mode-control';

export type ItemType = 'CLEAR' | 'NONE' | 'ROLE' | 'PLAN';

export type TemplateFilterItem = {
  type: ItemType;
  name: string;
  icon?: IconName;
  id: string;
};

type Props = {
  selected: TemplateFilterItem;
  data: TemplateFilterItem[];
  onSelect: (item: TemplateFilterItem) => void;
};

export const WillyTemplatesFilterComboBox: React.FC<Props> = ({ selected, onSelect, data }) => {
  const combobox = useCombobox({
    onDropdownOpen() {
      combobox.focusSearchInput();
    },
    onDropdownClose: () => {
      combobox.resetSelectedOption();
      combobox.focusTarget();
    },
  });
  const darkMode = useDarkMode();

  const comboboxOptions = useCallback(
    (types: ItemType[]) =>
      data
        .filter((m) => types.includes(m.type))
        .map((item) => (
          <Fragment key={item.id}>
            <Combobox.Option
              value={item.id}
              key={item.id}
              className={cx(
                'data-[combobox-selected=true]:!bg-[var(--mantine-color-named2-3)]',
                ':hover:!bg-[var(--mantine-color-named2-3)]',
                classes.selectItem,
              )}
            >
              <Flex gap={'xs'} align={'center'}>
                <Text>{item.name}</Text>
              </Flex>
            </Combobox.Option>
          </Fragment>
        )),
    [data],
  );

  return (
    <Combobox
      store={combobox}
      width={300}
      position="bottom-start"
      styles={{ options: { maxHeight: 400, overflowY: 'scroll' } }}
      onOptionSubmit={(id) => {
        if (!selected) return;
        const selectedOption = data.find((item) => item.id === id)!;
        onSelect(selectedOption);
        combobox.closeDropdown();
      }}
    >
      <Combobox.Target>
        <div
          onClick={() => combobox.toggleDropdown()}
          className={
            'flex justify-between bg-white rounded-[6px] border border-solid border-gray-300 items-center px-6 py-2 pt-3'
          }
        >
          <>
            {selected && (
              <>
                <div className={'flex items-center gap-4'}>
                  <Text color={'gray.8'}>{selected.name}</Text>
                </div>
                <div
                  className={`flex text-gray-500 w-5 h-5 items-center cursor-pointer transition-transform ${
                    combobox.dropdownOpened ? 'rotate-180' : ''
                  }`}
                >
                  <Icon name={'chevron-down'} />
                </div>
              </>
            )}
            {!selected && (
              <>
                <div className={'flex items-center gap-2'}>
                  <Text color={'gray.8'}>Select....</Text>
                </div>
                <div
                  className={`flex text-gray-500 w-5 h-5 items-center cursor-pointer transition-transform ${
                    combobox.dropdownOpened ? 'rotate-180' : ''
                  }`}
                >
                  <Icon name={'chevron-down'} />
                </div>
              </>
            )}
          </>
        </div>
      </Combobox.Target>

      <Combobox.Dropdown
        bg={darkMode ? 'gray.6' : 'white'}
        style={{ border: 'none', boxShadow: '0px 1px 2px rgba(0, 0, 0, 0.05)' }}
      >
        <Combobox.Options>
          <Combobox.Group label="Global">{comboboxOptions(['NONE', 'CLEAR'])}</Combobox.Group>
          <Combobox.Group label="Use Cases">{comboboxOptions(['ROLE'])}</Combobox.Group>
          <Combobox.Group label="Plans">{comboboxOptions(['PLAN'])}</Combobox.Group>
        </Combobox.Options>
      </Combobox.Dropdown>
    </Combobox>
  );
};
