import { isGroupBtnDisabled } from 'ducks/stats';
import { useAppDispatch } from 'index';
import moment from '@tw/moment-cached/module/timezone';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { type RootState } from 'reducers/RootType';
import { convertDateToMoment } from 'utils/convertDateToMoment';
import { Button, DatePicker, OptionList, Popover, Range } from '@shopify/polaris';
import { Size, Button as TwButton, Popover as TwPopover } from '@tw/ui-components';
import {
  changeDatesToCompare,
  compareDatePickerOnMonthChange,
  compareDatePickerSelectedOptionChange,
  customSelectedCompareOption,
  mainDatePickerOnMonthChange,
  mainDatePickerOnSelectionChange,
  mainDatePickerSelectedOptionChange,
  mainDatePickerSpecialPeriodChange,
} from '../ducks/actions';
import GroupStatsButtons from './GroupStatsButtons';
import LockedFeatureIndicator from './library/LockedFeatures/LockedFeatureIndicator';
import { useFeatureFlag } from 'feature-flag-system';
import { FeatureFlag } from '@tw/feature-flag-system/module/types';
import {
  DatePickerTimePeriods,
  useDatePickerSelectedOptions,
} from './useDatePickerSelectedOptions';
import { userDb } from 'utils/DB';
import { getPrevDates } from 'utils/getPreviousDate';
import { mapFromPeriodIdToTimeUnit } from '@tw/types/module/datePicker/datePicker';
import { DatePickerPreviousPeriodOption, RangeWithId } from 'types/general';
import { useEarliestDate } from './useEarliestDate';
import { $compareOptions, useDatePickerCompareOptions } from './useDatePickerCompareOptions';
import { DATE_PICKER_HEIGHT_VAR } from './TopBar/constants';
import { $prevDateRange, pushDateToQS, pushFullDateToQS } from '../$stores/willy/$dateRange';
import {
  useCurrentPopoverOpenerButtonTitle,
  usePrevPopoverOpenerButtonTitle,
} from './useGetPopoverOpenerButtonTitle';
import {
  genericEventLogger,
  analyticsEvents,
  generalActions,
  dashboardsActions,
} from 'utils/dataLayer';
import { useStoreValue } from '@tw/snipestate';
import { $activeAppVersion } from '$stores/nav-config-stores';
import { useIsSmall } from 'hooks/useDefaultWindowSizes';

export const TODAY = 'Today';
export const YESTERDAY = 'Yesterday';

type SummaryDatePickerProps = {
  showGroupByButton: boolean;
  disabled?: boolean;
  showCompareButton?: boolean;
  earliestDateProp?: Date | null;
  unSuportedOptions?: DatePickerTimePeriods[];
  skipLoadOnCompareChange?: boolean;
  compareSeparately?: boolean;
  isWillyDashboard?: boolean;
  buttonSize?: Exclude<Size, 0>;
};

const SummaryDatePicker: React.FC<SummaryDatePickerProps> = ({
  showGroupByButton,
  disabled = false,
  showCompareButton = false,
  earliestDateProp = '',
  unSuportedOptions = [],
  skipLoadOnCompareChange,
  compareSeparately = false,
  isWillyDashboard = false,
  buttonSize = 'sm',
}) => {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const location = useLocation();

  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const [isPreviousPopoverOpen, setIsPreviousPopoverOpen] = useState(false);
  const currentPopoverLabel = useCurrentPopoverOpenerButtonTitle();
  const prevPopoverLabel = usePrevPopoverOpenerButtonTitle();
  const month = useSelector((state: RootState) => state.mainDatePickerMonth);
  const year = useSelector((state: RootState) => state.mainDatePickerYear);
  const compareMonth = useSelector((state: RootState) => state.compareDatePickerMonth);
  const compareYear = useSelector((state: RootState) => state.compareDatePickerYear);
  const mainDatePickerSelectionRange = useSelector(
    (state: RootState) => state.mainDatePickerSelectionRange,
  );
  const datesToCompare = useSelector((state: RootState) => state.datesToCompare);
  const defaultOptions = useDatePickerSelectedOptions();
  const compareOptions = useDatePickerCompareOptions();
  const currentShopId = useSelector((state: RootState) => state.currentShopId);
  const timezone = useSelector((state: RootState) => state.shopTimezone);
  const prevDateRange = useStoreValue($prevDateRange);
  const earliestDate = useSelector((state: RootState) => state.earliestDate);
  const shopTimezone = useSelector((state: RootState) => state.shopTimezone);
  const activePage = useSelector((state: RootState) => state.activePage);
  const activeAppVersion = useStoreValue($activeAppVersion);
  const isSmall = useIsSmall();

  const mainDatePickerSelectedOption = useSelector(
    (state: RootState) => state.mainDatePickerSelectedOption,
  );

  const mainDatePickerSelectedCompareOption = useSelector(
    (state: RootState) => state.mainDatePickerSelectedCompareOption,
  );
  const regroupingStats = useSelector((state: RootState) => state.regroupingStats);
  const regroupingPreviousPeriodStats = useSelector(
    (state: RootState) => state.regroupingPreviousPeriodStats,
  );
  const groupStatsBy = useSelector((state: RootState) => state.groupStatsBy);

  const [val, setVal] = useState<RangeWithId | null>(null);
  const [compareVal, setCompareVal] = useState<RangeWithId | null>(null);
  const [showGroupButton, setShowGroupButton] = useState(showGroupByButton);
  const [showGroupPopover, setShowGroupPopover] = useState(false);
  const [showCompareSection, setShowCompareSection] = useState(false);

  const earliestDateForDisplay = useEarliestDate(earliestDateProp);

  const { numericLimit: datePickerLimitation } = useFeatureFlag(FeatureFlag.LIMIT_LOOKBACK_FF);

  const options = useMemo(() => {
    let options = defaultOptions.filter((option) => {
      return !unSuportedOptions.find((o) => o === option?.value?.id);
    });
    if (earliestDateForDisplay && datePickerLimitation) {
      const earliestDateFreeFeature = moment()
        .subtract(datePickerLimitation, 'month')
        .startOf('day');
      options = options.map((e) => {
        return { ...e, disabled: earliestDateFreeFeature?.isAfter(e.value.start) };
      });
    }
    return options;
  }, [datePickerLimitation, defaultOptions, earliestDateForDisplay, unSuportedOptions]);

  //make sure dates coming from url are not out of range for free users
  useEffect(() => {
    if (mainDatePickerSelectionRange && earliestDateForDisplay && datePickerLimitation! > 0) {
      if (earliestDateForDisplay.isAfter(mainDatePickerSelectionRange.end)) {
        dispatch(
          mainDatePickerOnSelectionChange({
            id: 'today',
            start: moment().startOf('day'),
            end: moment().endOf('day'),
          }),
        );
      }
    }
  }, [dispatch, earliestDateForDisplay, datePickerLimitation, mainDatePickerSelectionRange]);

  useEffect(() => {
    if (typeof showGroupByButton === 'undefined' || showGroupByButton === true) {
      const shouldShowWeek = !isGroupBtnDisabled({
        mainDatePickerSelectionRange: val,
        group: 'week',
      });
      const shouldShowMonth = !isGroupBtnDisabled({
        mainDatePickerSelectionRange: val,
        group: 'month',
      });
      setShowGroupButton(shouldShowWeek || shouldShowMonth);
    }
  }, [val, showGroupByButton]);

  useEffect(() => {
    if (!mainDatePickerSelectionRange) return;
    const { start, end } = mainDatePickerSelectionRange;
    setVal({
      start: new Date(start.year(), start.month(), start.date()),
      end: new Date(end.year(), end.month(), end.date()),
    });
  }, [mainDatePickerSelectionRange]);

  useEffect(() => {
    const { start, end } = val || {};
    if (
      prevDateRange &&
      prevDateRange.start &&
      prevDateRange.end &&
      prevDateRange.id === 'custom'
    ) {
      setCompareVal({
        start: prevDateRange.start.toDate(),
        end: prevDateRange.end.toDate(),
      });
    } else {
      const momentObj = { start: convertDateToMoment(start), end: convertDateToMoment(end) };
      // const { start: compareStart, end: compareEnd } = datesToCompare;
      const prev = getPrevDates(momentObj);
      setCompareVal({
        start: prev.start.toDate(),
        end: prev.end.toDate(),
      });
    }
  }, [datesToCompare, val, prevDateRange]);

  const toggleGroupPopover = useCallback(() => {
    setShowGroupPopover((show) => !show);
  }, []);

  const _onSelectionChange = useCallback((_val: Range) => {
    setVal(_val);
  }, []);

  const _onCompareSelectionChange = useCallback((_val) => {
    setCompareVal(_val);
  }, []);

  const toggleCompareSection = useCallback(() => {
    setShowCompareSection((show) => !show);
  }, []);

  const _onApply = useCallback(
    (compareOptions: any[]) => {
      let { start, end, id } = val || {};
      start = moment()
        .year(start!.getFullYear())
        .month(start!.getMonth())
        .date(start!.getDate())
        .startOf('day') as any;
      end = moment()
        .year(end!.getFullYear())
        .month(end!.getMonth())
        .date(end!.getDate())
        .endOf('day') as any;

      // highlight the relevant option if current selection much one of them
      const e = moment(end);
      const option = options.find((o) => {
        return (
          (o.value.start.isSame(start) && o.value.end.isSame(end)) ||
          (o.value.start.isSame(start) && o.value.end.isSame(e.startOf('day')))
        );
      });

      if (option) {
        const { specialPeriod, id } = option.value;
        dispatch(mainDatePickerSelectedOptionChange(id));
        if (specialPeriod) {
          dispatch(mainDatePickerSpecialPeriodChange(specialPeriod));
        } else {
          dispatch(mainDatePickerSpecialPeriodChange(null));
        }
      } else {
        dispatch(mainDatePickerSelectedOptionChange(null));
        dispatch(mainDatePickerSpecialPeriodChange(null));
      }

      dispatch(
        mainDatePickerOnSelectionChange({
          id,
          start,
          end,
        }),
      );

      // start - prev
      let prevStart: moment.Moment;
      let prevEnd: moment.Moment;
      const prevId = compareOptions.find((x) => x.active)?.value?.id;
      if (!prevId) {
        ({ start: prevStart, end: prevEnd } = getPrevDates({
          start: moment(val?.start),
          end: moment(val?.end),
        }));
      } else {
        const period = mapFromPeriodIdToTimeUnit[prevId];
        if (prevId === 'custom' && compareVal) {
          const { start: compareStart, end: compareEnd } = compareVal;
          prevStart = moment(compareStart);
          prevEnd = moment(compareEnd);
        } else if (!period) {
          ({ start: prevStart, end: prevEnd } = getPrevDates({
            start: moment(start),
            end: moment(end),
          }));
        } else {
          ({ start: prevStart, end: prevEnd } = getPrevDates(
            { start: moment(start), end: moment(end) },
            period,
          ));
        }
      }
      dispatch(changeDatesToCompare({ start: prevStart, end: prevEnd, id: prevId || 'default' }));
      // end - prev
      setIsPopoverOpen((x) => !x);
      pushFullDateToQS(
        history,
        moment(start),
        moment(end),
        prevStart,
        prevEnd,
        undefined,
        prevId || 'default',
        true,
      );
      // pushDateToQS(location, history, moment(start), moment(end), true, undefined, true);
      // pushDateToQS(location, history, prevStart, prevEnd, false, prevId || 'default');
      genericEventLogger(isWillyDashboard ? analyticsEvents.DASHBOARDS : analyticsEvents.GENERAL, {
        action: isWillyDashboard ? generalActions.CHANGE_DATE : dashboardsActions.CHANGE_DATE,
        version: isWillyDashboard ? '3.0' : '2.0',
        start: moment(start).toDate(),
        end: moment(end).toDate(),
        compareStart: moment(prevStart).toDate(),
        compareEnd: moment(prevEnd).toDate(),
      });
    },
    [val, options, dispatch, history, isWillyDashboard, compareVal],
  );

  const onApplyPrevious = useCallback(() => {
    const { start, end } = compareVal || {};

    if (!start || !end) {
      return;
    }
    dispatch(
      changeDatesToCompare({
        start: convertDateToMoment(start),
        end: convertDateToMoment(end),
        id: 'custom',
      }),
    );
    setIsPreviousPopoverOpen((x) => !x);
    genericEventLogger(isWillyDashboard ? analyticsEvents.DASHBOARDS : analyticsEvents.GENERAL, {
      action: isWillyDashboard
        ? generalActions.CHANGE_COMPARE_DATE
        : dashboardsActions.CHANGE_COMPARE_DATE,
      version: isWillyDashboard ? '3.0' : '2.0',
      start: moment(start).toDate(),
      end: moment(end).toDate(),
    });
  }, [compareVal, dispatch, isWillyDashboard]);

  const _onOptionSelect = useCallback(
    (val: any) => {
      const { start, end, specialPeriod, id } = val;
      let compareStart: moment.Moment;
      let compareEnd: moment.Moment;

      const prevPeriodOptionId = compareOptions.find((x) => x.active)?.value?.id;

      const period = mapFromPeriodIdToTimeUnit[prevPeriodOptionId!];
      if (prevPeriodOptionId === 'custom' && compareVal) {
        const { start, end } = compareVal;
        compareStart = convertDateToMoment(start);
        compareEnd = convertDateToMoment(end);
        dispatch(
          changeDatesToCompare({
            start: compareStart,
            end: compareEnd,
            id: prevPeriodOptionId,
          }),
        );
      } else if (!period) {
        ({ start: compareStart, end: compareEnd } = getPrevDates({
          start: start,
          end: end,
        }));
      } else {
        ({ start: compareStart, end: compareEnd } = getPrevDates({ start, end }, period));
      }
      setIsPopoverOpen((x) => !x);
      dispatch(mainDatePickerSelectedOptionChange(id));
      if (specialPeriod) {
        dispatch(mainDatePickerSpecialPeriodChange(specialPeriod));
      } else {
        dispatch(mainDatePickerSpecialPeriodChange(null));
      }
      dispatch(mainDatePickerOnSelectionChange({ start, end, id }));
      dispatch(
        changeDatesToCompare({
          start: compareStart,
          end: compareEnd,
          id: prevPeriodOptionId ?? 'default',
        }),
      );
      pushFullDateToQS(
        history,
        start,
        end,
        compareStart,
        compareEnd,
        id,
        prevPeriodOptionId ?? 'default',
      );
      genericEventLogger(isWillyDashboard ? analyticsEvents.DASHBOARDS : analyticsEvents.GENERAL, {
        action: isWillyDashboard ? generalActions.CHANGE_DATE : dashboardsActions.CHANGE_DATE,
        version: isWillyDashboard ? '3.0' : '2.0',
        start: moment(start).toDate(),
        end: moment(end).toDate(),
        compareStart: moment(compareStart).toDate(),
        compareEnd: moment(compareEnd).toDate(),
      });
    },
    [compareOptions, compareVal, dispatch, history, isWillyDashboard],
  );

  const _onOptionCompareSelect = useCallback(
    async (val: DatePickerPreviousPeriodOption['value']) => {
      const { start, end, id } = val;

      dispatch(compareDatePickerSelectedOptionChange(id));
      if (id === 'none') {
        pushDateToQS(history, undefined, undefined, false, id, false);
      }
      if (id === 'custom') {
        dispatch(changeDatesToCompare({ start, end, id }));
        setCompareVal({ start: start.toDate(), end: end.toDate() });
        await userDb().set(
          {
            compareDatePickerSelectedOption: id,
            shops: {
              [currentShopId]: {
                timezone: timezone,
              },
            },
          },
          { merge: true },
        );
        return;
      } else {
        dispatch(changeDatesToCompare({ start, end, id }));
        setCompareVal({ start: start.toDate(), end: end.toDate() });
        if (skipLoadOnCompareChange) {
          if (compareSeparately) {
            setIsPreviousPopoverOpen((x) => !x);
          } else {
            setIsPopoverOpen((x) => !x);
          }
        } else {
          // This weird code is necessary because we need to make sure _onApply is called with
          // data that is updated with the above `dispatch(compareDatePickerSelectedOptionChange(id));`,
          // and that data is only updated on the next render when we're already not calling this function.
          // We need to make sure that this _onApply gets called with the data only available on the
          // next render.  We can do that by listening to a change in the store instead of waiting for
          // the next render.  Once the function is fired, we unsubscribe right away to avoid extra triggers.
          const unsubscribe = $compareOptions.subscribe((newOptions) => {
            _onApply(newOptions);
            unsubscribe();
          });
        }
        await userDb().set(
          {
            compareDatePickerSelectedOption: id,
            shops: {
              [currentShopId]: {
                timezone: timezone,
              },
            },
          },
          { merge: true },
        );
      }

      genericEventLogger(isWillyDashboard ? analyticsEvents.DASHBOARDS : analyticsEvents.GENERAL, {
        action: isWillyDashboard
          ? generalActions.CHANGE_COMPARE_DATE
          : dashboardsActions.CHANGE_COMPARE_DATE,
        version: isWillyDashboard ? '3.0' : '2.0',
        start: moment(start).toDate(),
        end: moment(end).toDate(),
      });
    },
    [
      dispatch,
      isWillyDashboard,
      history,
      currentShopId,
      timezone,
      skipLoadOnCompareChange,
      compareSeparately,
      _onApply,
    ],
  );

  const compareDatePicker = useMemo(() => {
    return (
      <div className="flex gap-4 flex-col flex-auto" style={{ maxWidth: '375px' }}>
        {!compareSeparately && <p className="font-semibold mb-4">And compare to:</p>}
        <OptionList
          onChange={(updated) => {
            _onOptionCompareSelect(updated[0] as any);
          }}
          options={compareOptions as any}
          selected={[]}
        />
        {mainDatePickerSelectedCompareOption === 'custom' && (
          <div>
            <DatePicker
              multiMonth={false}
              month={compareMonth}
              year={compareYear}
              onChange={(date) => {
                dispatch(customSelectedCompareOption());
                _onCompareSelectionChange(date);
              }}
              onMonthChange={(month, year) => dispatch(compareDatePickerOnMonthChange(month, year))}
              selected={compareVal!}
              allowRange
              disableDatesAfter={
                new Date(
                  convertDateToMoment(val?.end)
                    .subtract(1, 'days')
                    .toDate()
                    .toLocaleString('en-US', {
                      timeZone: moment().tz(),
                    }),
                )
              }
              disableDatesBefore={
                mainDatePickerSelectedCompareOption !== 'custom'
                  ? moment.unix(999999999999).toDate()
                  : earliestDateForDisplay.toDate()
              }
            />
          </div>
        )}
      </div>
    );
  }, [
    _onCompareSelectionChange,
    _onOptionCompareSelect,
    compareMonth,
    compareOptions,
    compareVal,
    compareYear,
    dispatch,
    earliestDateForDisplay,
    mainDatePickerSelectedCompareOption,
    val?.end,
    compareSeparately,
  ]);

  if (!mainDatePickerSelectionRange || !mainDatePickerSelectionRange.start) {
    return (
      // <TwButton variant="activator" loading loaderPosition="center">
      <TwButton
        variant={isWillyDashboard ? 'activatorWithHover' : 'activator'}
        loading
        justify="center"
      >
        Today
      </TwButton>
    );
  }

  return (
    <div className={`flex gap-4 items-center ${activeAppVersion !== '2.0' ? '' : 'flex-wrap'}`}>
      <TwPopover
        shadow="sm"
        opened={isPopoverOpen}
        position="bottom"
        onClose={() => setIsPopoverOpen(false)}
      >
        <TwPopover.Target>
          <span>
            <TwButton
              id={activePage + '-date-picker'}
              variant={isWillyDashboard ? 'activatorWithHover' : 'activator'}
              onClick={() => setIsPopoverOpen((x) => !x)}
              disabled={disabled}
              leftSection="calendar-1"
              rightSection="caret-down"
              size={buttonSize}
            >
              {currentPopoverLabel}
            </TwButton>
          </span>
        </TwPopover.Target>
        <TwPopover.Dropdown
          bg="white"
          fz="sm"
          p="xs"
          mah={DATE_PICKER_HEIGHT_VAR}
          overflow="scroll"
        >
          <div
            className="flex flex-col sm:flex-row gap-20 justify-between"
            id={activePage + '-date-selections'}
            aria-label="Date Selections"
            style={{ maxWidth: '800px' }}
          >
            <div
              className="flex gap-4 flex-col"
              style={
                isSmall
                  ? { width: 'calc(100vw - 0.937rem - 0.937rem)', maxWidth: '370px' }
                  : { maxWidth: '370px' }
              }
            >
              <div className="max-h-[230px] sm:max-h-none overflow-auto">
                <OptionList
                  onChange={(updated) => {
                    _onOptionSelect(updated[0] as any);
                  }}
                  options={options as any}
                  selected={[]}
                />
              </div>
              {!!datePickerLimitation && (
                <LockedFeatureIndicator
                  featureFlag={FeatureFlag.LIMIT_LOOKBACK_FF}
                  layout="vertical"
                  extraData={{ targetToUnlock: 12 }}
                />
              )}
              <DatePicker
                multiMonth={false}
                month={month}
                year={year}
                onChange={(date) => {
                  _onSelectionChange(date);
                }}
                onMonthChange={(month, year) => dispatch(mainDatePickerOnMonthChange(month, year))}
                selected={val!}
                allowRange
                disableDatesAfter={
                  new Date(
                    moment().toDate().toLocaleString('en-US', {
                      timeZone: moment().tz(),
                    }),
                  )
                }
                disableDatesBefore={earliestDateForDisplay.toDate()}
              />
              <div className="flex flex-wrap justify-between items-end">
                <div>
                  <span
                    style={{ display: 'block', color: 'var(--p-text-subdued)', fontSize: '10px' }}
                  >
                    Timezone: {shopTimezone.replace(/_/g, ' ')}
                  </span>
                </div>
                <div className="flex items-center gap-4">
                  {showCompareButton && !compareSeparately && (
                    <Button
                      outline
                      onClick={toggleCompareSection}
                      id="tr-summary-page-date-picker-compare-to"
                    >
                      Compared to...
                    </Button>
                  )}
                  <Button
                    primary
                    onClick={() => _onApply(compareOptions)}
                    id="tr-date-picker-apply"
                  >
                    Apply
                  </Button>
                </div>
              </div>
            </div>

            {showCompareSection && showCompareButton && !compareSeparately && (
              <>{compareDatePicker}</>
            )}
          </div>
        </TwPopover.Dropdown>
      </TwPopover>
      {compareSeparately && showCompareButton && (
        <>
          {activeAppVersion === '2.0' && <div className="flex-shrink-0">Compared to</div>}
          <TwPopover
            shadow="sm"
            position="bottom-start"
            opened={isPreviousPopoverOpen}
            onClose={() => setIsPreviousPopoverOpen(false)}
          >
            <TwPopover.Target>
              <span>
                <TwButton
                  id={activePage + '-date-picker'}
                  variant={isWillyDashboard ? 'activatorWithHover' : 'activator'}
                  onClick={() => setIsPreviousPopoverOpen((x) => !x)}
                  disabled={disabled}
                  leftSection={activeAppVersion === '2.0' ? 'calendar-1' : undefined}
                  rightSection="caret-down"
                  size={buttonSize}
                >
                  {prevPopoverLabel}
                </TwButton>
              </span>
            </TwPopover.Target>
            <TwPopover.Dropdown
              bg="white"
              fz="sm"
              p="xs"
              mah={DATE_PICKER_HEIGHT_VAR}
              overflow="scroll"
            >
              {compareDatePicker}
              {mainDatePickerSelectedCompareOption === 'custom' && (
                <div className="flex items-center gap-4">
                  <Button primary onClick={onApplyPrevious}>
                    Apply
                  </Button>
                </div>
              )}
            </TwPopover.Dropdown>
          </TwPopover>
        </>
      )}
      {showGroupButton && (
        <div className="flex items-center gap-4 flex-wrap">
          {activeAppVersion === '2.0' && (
            <p className="dark:text-white whitespace-nowrap">Group by</p>
          )}
          <Popover
            active={showGroupPopover}
            onClose={toggleGroupPopover}
            sectioned
            fullHeight
            activator={
              <div style={{ width: '95px' }}>
                <TwButton
                  variant={isWillyDashboard ? 'activatorWithHover' : 'activator'}
                  loading={regroupingStats || regroupingPreviousPeriodStats}
                  onClick={toggleGroupPopover}
                  rightSection="caret-down"
                  size={buttonSize}
                >
                  {groupStatsBy}
                </TwButton>
              </div>
            }
          >
            <GroupStatsButtons onClose={toggleGroupPopover} />
          </Popover>
        </div>
      )}
      {/* {!isSmall &&
        <TextStyle variation="subdued">Timezone: {shopTimezone.replace(/_/g,' ')}</TextStyle>
      } */}
      {/*!isSmall &&
        <Tooltip content={"Your store' timezone on Shopify"} dismissOnMouseOut>
          <Button
            plain
            backdrop
            icon={QuestionMarkMinor}
            // onClick={(e) => {
            //   hideOnPress(id);
            //   e.preventDefault();
            // }}
          />
        </Tooltip>
      */}
    </div>
  );
};

export default SummaryDatePicker;
