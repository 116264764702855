import axiosInstance from 'utils/axiosInstance';
import {
  DayOfWeek,
  ScheduleAction,
  ScheduleItem,
  ScheduleRulesRow,
  ScheduleSequenceParams,
  ScheduleTrigger,
} from '../types/willyTypes';
import { getScheduleCollection } from './willyUtils';

export async function createSchedule({
  sequenceId,
  name,
  email,
  scheduleType,
  scheduleTrigger,
  dashboardId,
  days,
  hours,
  interval,
  rules,
  userId,
  shopId,
  currency,
  timezone,
  scheduleIdToUpdate,
  dashboardType,
}: {
  sequenceId: string;
  name: string;
  email: string;
  scheduleType: ('email' | 'dashboard')[];
  scheduleTrigger: ScheduleTrigger;
  dashboardId: string;
  days: DayOfWeek[];
  hours: number[];
  interval: string | null;
  rules: ScheduleRulesRow[];
  userId: string;
  shopId: string;
  currency: string;
  timezone: string;
  scheduleIdToUpdate?: string;
  dashboardType: 'customView' | 'shop';
}) {
  const scheduleId = scheduleIdToUpdate || getScheduleCollection(userId, shopId).doc().id;
  const originalSchedule = await getScheduleCollection(userId, shopId)
    .doc(scheduleIdToUpdate)
    .get();

  if (scheduleTrigger === 'rules' && rules.length === 0) {
    throw new Error('Rules are required when schedule trigger is rules');
  }

  if (scheduleTrigger === 'rules' && !interval) {
    throw new Error('Interval is required when schedule trigger is interval');
  }

  if (scheduleTrigger === 'time' && days.length === 0) {
    throw new Error('Days are required when schedule trigger is time');
  }

  if (scheduleTrigger === 'time' && hours.length === 0) {
    throw new Error('Hours are required when schedule trigger is time');
  }

  if (scheduleTrigger === 'time') {
    interval = null;
    rules = [];
  }

  if (scheduleTrigger === 'rules') {
    days = [];
    hours = [];
  }

  const item: ScheduleItem = {
    id: scheduleId,
    sequenceId,
    userId,
    shopId,
    name,
    email,
    scheduleType,
    scheduleTrigger,
    dashboardId,
    days,
    hours,
    interval,
    rules,
    createdAt: new Date(),
    updatedAt: new Date(),
  };

  await getScheduleCollection(userId, shopId).doc(scheduleId).set(item, {
    merge: true,
  });

  const actions: ScheduleAction[] = [];

  if (scheduleType.includes('email') && email) {
    actions.push({
      type: 'sendEmail',
      emailAddress: email,
      title: name,
    });
  }

  if (scheduleType.includes('dashboard') && dashboardId) {
    actions.push({
      type: 'updateDashboard',
      dashboardId,
      title: name,
      dashboardType: dashboardType,
    });
  }

  try {
    const method = scheduleIdToUpdate ? axiosInstance.put : axiosInstance.post;
    const { data } = await method<any, any, ScheduleSequenceParams>(
      `/v2/sequences/schedules${scheduleIdToUpdate ? `/${scheduleIdToUpdate}` : ''}`,
      {
        shopId,
        sequenceId,
        scheduleId,
        actions,
        currency,
        trigger: scheduleTrigger,
        schedule: [
          {
            dayOfWeek: days,
            hour: hours,
          },
        ],
        rules,
        interval,
        timezone,
        userId,
      },
    );
  } catch (e) {
    const data = originalSchedule.data();
    if (originalSchedule.exists && !!data) {
      await getScheduleCollection(userId, shopId).doc(scheduleId).set(data);
    }

    throw e;
  }

  return scheduleId;
}

export async function resumeSchedule(scheduleId: string, shopId: string, userId: string) {
  const originalSchedule = await getScheduleCollection(userId, shopId).doc(scheduleId).get();
  if (!originalSchedule.exists) {
    return;
  }
  try {
    await getScheduleCollection(userId, shopId)
      .doc(scheduleId)
      .set({ status: 'scheduled' }, { merge: true });

    await axiosInstance.post(`/v2/sequences/schedules/${scheduleId}/resume`, {
      shopId,
      userId,
    });
  } catch (e) {
    const data = originalSchedule.data();
    if (originalSchedule.exists && !!data) {
      await getScheduleCollection(userId, shopId).doc(scheduleId).set(data);
    }

    throw e;
  }
}

export async function pauseSchedule(scheduleId: string, shopId: string, userId: string) {
  const originalSchedule = await getScheduleCollection(userId, shopId).doc(scheduleId).get();
  if (!originalSchedule.exists) {
    return;
  }

  try {
    await getScheduleCollection(userId, shopId)
      .doc(scheduleId)
      .set({ status: 'paused' }, { merge: true });
    await axiosInstance.post(`/v2/sequences/schedules/${scheduleId}/pause`, {
      shopId,
      userId,
    });
  } catch (e) {
    const data = originalSchedule.data();
    if (originalSchedule.exists && !!data) {
      await getScheduleCollection(userId, shopId).doc(scheduleId).set(data);
    }

    throw e;
  }
}

export async function deleteSchedule(scheduleId: string, shopId: string, userId: string) {
  const originalSchedule = await getScheduleCollection(userId, shopId).doc(scheduleId).get();
  if (!originalSchedule.exists) {
    return;
  }

  try {
    await getScheduleCollection(userId, shopId).doc(scheduleId).delete();
    await axiosInstance.delete(`/v2/sequences/schedules/${scheduleId}`, {
      data: {
        shopId,
        userId,
      },
    });
  } catch (e) {
    // we still want to remove the document from firestore even if the request to the server fails
    // because maybe it failed because the schedule was already deleted from temporal
    // (I couldn't find a way to check if a schedule exists in temporal without making the server to throw an error)
    // const data = originalSchedule.data();
    // if (originalSchedule.exists && !!data) {
    //   await getScheduleCollection(userId, shopId).doc(scheduleId).set(data);
    // }
    // throw e;
  }
}

export async function executeSequence(scheduleId: string, shopId: string, userId: string) {
  await axiosInstance.post(`/v2/sequences/schedules/${scheduleId}/execute`, {
    shopId,
    userId,
  });
}

export async function getScheduleInfo(scheduleId: string, shopId: string, userId: string) {
  const { data } = await axiosInstance.post(`/v2/sequences/schedules/get-schedule/${scheduleId}`, {
    shopId,
    userId,
  });
  return data;
}
