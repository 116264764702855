import Attribution from 'pages/Attribution/Attribution';
import { FeatureFlag } from '@tw/feature-flag-system/module/types';
import { computeFeatureFlags } from 'feature-flag-system';
import { UpgradePageFallBack } from 'feature-flag-system/components';

const AttributionEmailSmsPageComponent = ({ setDashboardPixelData }) => {
  return <Attribution sourceCategory="email" setDashboardPixelData={setDashboardPixelData} />;
};

const AttributionEmailSmsPage = computeFeatureFlags(
  FeatureFlag.PIXEL_EMAIL_FF,
  AttributionEmailSmsPageComponent,
  () => (
    <UpgradePageFallBack
      InAppContextBannerEnabled={true}
      InAppContextBannerPage={'attribution'}
      featureFlag={FeatureFlag.PIXEL_EMAIL_FF}
    />
  ),
);

export default AttributionEmailSmsPage;
