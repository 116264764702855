import { FilterFieldAutocomplete } from './ltv/FilterFieldAutocomplete';
import { OptionDescriptor } from '@shopify/polaris/build/ts/latest/src/types';

type CustomSpendSourceProps = {
  relevantSources: OptionDescriptor[];
  source: OptionDescriptor;
  disabled: boolean;
  setSource: (source: OptionDescriptor) => void;
};

export const CustomSpendSource: React.FC<CustomSpendSourceProps> = ({
  relevantSources,
  source,
  disabled,
  setSource,
}) => {
  return (
    <FilterFieldAutocomplete
      disabled={disabled}
      placeholder="Source"
      initVal={source.label?.toString()}
      debounceMsTime={0}
      onSelectSearchResult={(selectedOption) => {
        setSource(selectedOption);
      }}
      searchFunc={async (id: any) => {
        if (disabled) {
          return [];
        }
        const fromList = relevantSources.filter((s) =>
          s.label?.toString().toLowerCase().includes(id.toLowerCase()),
        );

        if (fromList.length) {
          return fromList;
        }

        return fromList.concat({
          value: id,
          originalValue: id,
          label: id,
        } as any);
      }}
      initialOptions={relevantSources}
    />
  );
};
