import { useSelector } from 'react-redux';
import { useEffect, useMemo } from 'react';
import moment from '@tw/moment-cached';
import { chartOpenChanged } from 'ducks/cdp/segments';
import { useAppDispatch } from 'index';
import { type RootState } from 'reducers/RootType';
import { ActionIcon, Flex, Icon, Text, Tooltip } from '@tw/ui-components';
import SummaryDatePicker from 'components/SummaryDatePicker';
import { mainDatePickerOnSelectionChange, mainDatePickerSelectedOptionChange } from 'ducks/actions';
import { DatePickerTimePeriods } from 'components/useDatePickerSelectedOptions';
import { useStoreValue } from '@tw/snipestate';
import { $activeAppVersion } from '$stores/nav-config-stores';

const CDPHeader = () => {
  const shopCustomersCount: number = useSelector(
    (state: RootState) => state.customers.shopCustomersCount,
  );
  const { chartOpened, currentSegment } = useSelector((state: RootState) => state.CDPSegments);
  const dispatch = useAppDispatch();
  const activeAppVersion = useStoreValue($activeAppVersion);
  const isThreePointOh = activeAppVersion === '3.0';

  // set default date-range on page load
  useEffect(() => {
    const id = DatePickerTimePeriods.LAST_90_DAYS;
    const start = moment().subtract(1, 'year').startOf('day');
    const end = moment().subtract(1, 'day').endOf('day');
    dispatch(mainDatePickerSelectedOptionChange(id));
    const range = {
      id,
      start,
      end,
    };
    dispatch(mainDatePickerOnSelectionChange(range));
  }, [dispatch]);

  const customersCount = useMemo(() => {
    if (currentSegment) {
      return `${currentSegment.size?.toLocaleString()} customers`;
    } else if (shopCustomersCount) {
      return `${shopCustomersCount.toLocaleString()} customers`;
    } else return '';
  }, [currentSegment, shopCustomersCount]);

  return (
    <Flex align="center" wrap="wrap" gap="md">
      {isThreePointOh && (
        <Flex align="center" pl="sm" gap="sm">
          <Icon name="segmentation" color={'gray.5'} size={18} />
          <Text color="gray.7" weight={500} size="lg">
            Customer Segments
          </Text>
        </Flex>
      )}
      <Tooltip label={`${chartOpened ? 'Hide' : 'Show'} Graph`}>
        <div>
          <ActionIcon
            size="lg"
            radius="sm"
            onClick={() => dispatch(chartOpenChanged(!chartOpened))}
            variant="activator"
            icon="line-chart"
            iconSize={20}
          />
        </div>
      </Tooltip>
      {customersCount && <Text> {customersCount}</Text>}
      <SummaryDatePicker disabled={!chartOpened} showGroupByButton={false} />
    </Flex>
  );
};

export default CDPHeader;
