import { $store } from '@tw/snipestate';

type DashItemDrawerStore = {
  opened: boolean;
  activeItemId?: string | null;
  metricsData?: any;
  metricsDataPrevious?: any;
  errorMetricsData?: any;
  loadingMetricsData?: any;
  setEditMetricModalOpen?: any;
  editingFields?: any;
  setEditingFields?: any;
  localFiles?: any;
  setLocalFiles?: any;
  updateFiles?: any;
  updateDashWidgetData?: any;
  openChatWithQuery?: any;
  openSqlWithWidget?: any;
};

export const $dashItemDrawer = $store<DashItemDrawerStore>({
  opened: false,
  activeItemId: null,
  metricsData: null,
  metricsDataPrevious: null,
  errorMetricsData: null,
  loadingMetricsData: null,
  setEditMetricModalOpen: null,
  editingFields: null,
  setEditingFields: null,
  localFiles: null,
  setLocalFiles: null,
  updateFiles: null,
  updateDashWidgetData: null,
  openChatWithQuery: null,
  openSqlWithWidget: null,
});

export const openDashItemDrawer = (
  activeItemId,
  metricsData,
  metricsDataPrevious,
  errorMetricsData,
  loadingMetricsData,
  setEditMetricModalOpen,
  editingFields,
  setEditingFields,
  localFiles,
  setLocalFiles,
  updateFiles,
  updateDashWidgetData,
  openChatWithQuery,
  openSqlWithWidget,
) => {
  $dashItemDrawer.set({
    opened: true,
    activeItemId,
    metricsData,
    metricsDataPrevious,
    errorMetricsData,
    loadingMetricsData,
    setEditMetricModalOpen,
    editingFields,
    setEditingFields,
    localFiles,
    setLocalFiles,
    updateFiles,
    updateDashWidgetData,
    openChatWithQuery,
    openSqlWithWidget,
  });
};

export const closeDashItemDrawer = () => {
  $dashItemDrawer.set({
    opened: false,
    activeItemId: null,
    metricsData: null,
    metricsDataPrevious: null,
    errorMetricsData: null,
    loadingMetricsData: null,
    setEditMetricModalOpen: null,
    editingFields: null,
    setEditingFields: null,
    localFiles: null,
    setLocalFiles: null,
    updateFiles: null,
    updateDashWidgetData: null,
    openChatWithQuery: null,
    openSqlWithWidget: null,
  });
};
