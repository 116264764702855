import { $userId } from '$stores/$user';
import { useStoreValue } from '@tw/snipestate';
import { Conversation } from 'components/Willy/types/willyTypes';
import { useEffect, useMemo, useState } from 'react';
import _db, { toArray } from 'utils/DB';

export function useConversationsStore() {
  const [state, setState] = useState({ conversations: [] as Conversation[], loading: true });
  const userId = useStoreValue($userId);

  useEffect(() => {
    if (!userId) return;

    return (
      _db()
        .collection('conversations')
        .where('v', 'in', [6])
        // .where('source', '==', null)
        .where('source', 'in', ['chat'])
        .orderBy('createdAt', 'desc')
        .limit(200) // failed otherwise
        .onSnapshot((snapshot) => {
          const conversationsArray = toArray(snapshot);
          setState({
            loading: false,
            conversations: conversationsArray
              .map((x) => ({
                ...x,
                history: x.history?.map((y) => ({
                  ...y,
                  role: y.role,
                  text: y.text,
                })),
              }))
              .filter((x) => x.user !== 'fake_user'),
          });
        })
    );
  }, [userId]);

  return [state, setState] as const;
}
