import { INIT_SHOP, SET_USE_SHIPPING_CHARGES } from './constants';
import { ShopifySegmentType } from 'types/shopify';
import { RECEIVE_SHOPIFY_SEGMENTS } from './shopify/newShopify';
import axiosInstance from 'utils/axiosInstance';
import { Reducer } from 'redux';
import { TASK_STATUS } from '@tw/types/module/services/subscription-manager';
import { getOnboarding } from './shop';

export const shippingProfileOnSave = (profile) => {
  const { edit, ...data } = profile;
  return async (dispatch, getState) => {
    const { currentShopId, costSettingsCompletion } = getState();
    const url = `/v2/shopify/update-segment`;
    await axiosInstance.post(url, {
      attributes: {
        shopId: currentShopId,
        dataType: 'shipping_profiles',
      },
      data,
    });
    dispatch(updateShippingProfiles(data));
    const { tasks } = costSettingsCompletion;
    if (tasks.find((task) => task.name === 'Shipping')?.status !== TASK_STATUS.COMPLETE)
      dispatch(getOnboarding(currentShopId));
  };
};

export const shippingProfileOnDelete = (id, zones) => {
  return async (dispatch, getState) => {
    const { currentShopId } = getState();
    const url = `/v2/shopify/delete-segment`;
    await axiosInstance.post(url, {
      attributes: {
        shopId: currentShopId,
        dataType: 'shipping_profiles',
      },
      data: {
        id,
        zones,
      },
    });
    dispatch(deleteShippingProfile(id));
  };
};

const UPDATE_SHIPPING_PROFILES = 'UPDATE_SHIPPING_PROFILES';
export const updateShippingProfiles = (data) => ({
  type: UPDATE_SHIPPING_PROFILES,
  data,
});

const DELETE_SHIPPING_PROFILE = 'DELETE_SHIPPING_PROFILE';
export const deleteShippingProfile = (id) => ({
  type: DELETE_SHIPPING_PROFILE,
  id,
});

const CLEAR_SHIPPING_PROFILE_DATA = 'CLEAR_SHIPPING_PROFILE_DATA';
export const clearShippingProfileData = () => ({
  type: CLEAR_SHIPPING_PROFILE_DATA,
});

const SAVE_SHIPPING_PROFILE_DATA = 'SAVE_SHIPPING_PROFILE_DATA';
export const saveShippingProfileData = (data) => ({
  type: SAVE_SHIPPING_PROFILE_DATA,
  data,
});

const LOAD_SHIPPING_PROFILE_DATA = 'LOAD_SHIPPING_PROFILE_DATA';
export const loadShippingProfileData = (data) => ({
  type: LOAD_SHIPPING_PROFILE_DATA,
  data,
});

const useShippingChargesAsCost = (state = false, action) => {
  switch (action.type) {
    case INIT_SHOP:
      return action.useShippingChargesAsCost ?? state;
    case SET_USE_SHIPPING_CHARGES:
      return action.payload;
    default:
      return state;
  }
};

const shippingProfiles = (state = [], action) => {
  let newState;
  switch (action.type) {
    case RECEIVE_SHOPIFY_SEGMENTS:
      return action.shopifySegments?.[ShopifySegmentType.SHIPPING_PROFILES] ?? [];
    case UPDATE_SHIPPING_PROFILES:
      newState = state.filter((x) => x['name'] !== action.data.name);
      return [...newState, action.data];
    case DELETE_SHIPPING_PROFILE:
      return state.filter((x) => x['id'] !== action.id);
    default:
      return state;
  }
};

export type State = {
  name: string;
  code: string;
};
export type Zone = {
  name: string;
  code: string;
  region: string;
  states: State[];
};
type Cost = {
  value: number;
  date: string;
};
export type ShippingCost = {
  type: 'fixed' | 'weight_based';
  minWeight: number | null;
  maxWeight: number | null;
  costs: Cost[];
};
type ShippingMethod = {
  code: string;
  cost: number;
};
type ShippingProfileData = {
  id?: string;
  name: string;
  worldwide: boolean;
  zones: Zone[];
  shipping_costs: ShippingCost[];
  edit?: false;
  shipping_methods?: ShippingMethod[];
  weightType?: 'gr' | 'lbs';
};
const initialShippingProfileData: ShippingProfileData = {
  name: '',
  worldwide: false,
  zones: [],
  shipping_costs: [],
};
const shippingProfileData: Reducer<ShippingProfileData> = (
  state = initialShippingProfileData,
  action,
) => {
  switch (action.type) {
    case CLEAR_SHIPPING_PROFILE_DATA:
      return initialShippingProfileData;
    case SAVE_SHIPPING_PROFILE_DATA:
      return { ...state, ...action.data };
    case LOAD_SHIPPING_PROFILE_DATA:
      return { ...action.data, edit: true };
    default:
      return state;
  }
};

export const reducers = {
  useShippingChargesAsCost,
  shippingProfiles,
  shippingProfileData,
};
