import React, { useCallback, useMemo, useState, useEffect } from 'react';
import { useDarkMode } from 'dark-mode-control';

import { Box, OptionList, Popover, TextField, Tooltip } from '@shopify/polaris';
import { uniqBy } from 'lodash';
import { ButtonProps, Text, Button as TwButton } from '@tw/ui-components';

type DropDownProps = {
  id?: string;
  options?: any[];
  size?: ButtonProps['size'];
  value: any;
  handleSelect: any;
  title?: string;
  disabled?: boolean;
  hint?: string;
  theme?: 'light' | 'dark' | 'none';
  showFilter?: boolean;
  zIndexOverride?: number;
  sections?: any[];
  extraElements?: React.ReactNode[];
  hideChevron?: boolean;
  loading?: boolean;
  customActivator?: React.ReactNode;
  allowMultiple?: boolean;
  closeOnSelect?: boolean;
  preventCloseOnChildOverlayClick?: boolean;
  onChange?: (value: any) => void;
  hideLabel?: boolean;
};

const DropDown: React.FC<DropDownProps> = (props) => {
  const {
    options,
    size = 'sm',
    value,
    handleSelect,
    title = '',
    disabled = false,
    hint = '',
    theme = undefined,
    showFilter = false,
    zIndexOverride = 0,
    id,
    sections,
    extraElements,
    hideChevron = false,
    loading = false,
    customActivator,
    allowMultiple = false,
    closeOnSelect = true,
    preventCloseOnChildOverlayClick = false,
    onChange,
    hideLabel = false,
  } = props;

  const doDarkMode = useDarkMode();
  const darkTheme = theme === 'dark';
  const componentTheme = theme && theme !== 'none' ? theme : doDarkMode ? 'dark' : 'light';

  const [popoverActive, setPopoverActive] = useState(false);
  const [filter, setFilter] = useState('');
  const togglePopoverActive = useCallback(() => {
    setPopoverActive((popoverActive) => !popoverActive);
  }, []);

  useEffect(() => {
    if (onChange) {
      onChange(popoverActive);
    }
  }, [popoverActive, onChange]);

  const handleChange = useCallback(
    (selected: string[]) => {
      if (closeOnSelect) {
        setPopoverActive(false);
      }
      const selectedToChange = allowMultiple ? selected : selected[0];
      return handleSelect(selectedToChange);
    },
    [allowMultiple, handleSelect, closeOnSelect],
  );

  const activator = useMemo(() => {
    const allOptionsFromSections = uniqBy(
      (options || []).concat(sections?.map((x) => x.options).flat()),
      'value',
    ).filter(Boolean);
    const activeOption = allOptionsFromSections
      .filter((x) => {
        if (allowMultiple) {
          return value.includes(x.value);
        }
        return x.value === value;
      })
      .map((x) => x.shortLabel || x.label)
      .join(', ');
    const label = activeOption || '';

    return customActivator ? (
      <div
        onClick={() => {
          if (!disabled && !loading) {
            togglePopoverActive();
          }
        }}
      >
        {customActivator}
      </div>
    ) : (
      <TwButton
        size={size}
        variant="activator"
        forceColorScheme={componentTheme}
        onClick={togglePopoverActive}
        disabled={disabled || loading}
        loading={loading}
        rightSection="caret-down"
        fullWidth
      >
        <Text fz="sm" fw={500} color={componentTheme === 'light' ? 'gray.7' : 'gray.0'}>
          {title ? title + ' ' : ''}
          {!hideLabel && label}
        </Text>
      </TwButton>
    );
  }, [
    options,
    sections,
    customActivator,
    size,
    componentTheme,
    togglePopoverActive,
    disabled,
    loading,
    title,
    hideLabel,
    allowMultiple,
    value,
  ]);

  const filterCb = useCallback(
    (option) => {
      const trimmed = filter.toLowerCase().trim().trimStart();
      return (
        !showFilter ||
        !trimmed?.length ||
        option.label?.toLowerCase?.()?.indexOf(trimmed) > -1 ||
        option.value?.toLowerCase().indexOf(trimmed) > -1
      );
    },
    [filter, showFilter],
  );

  return (
    <div
      id={id}
      className={`tw-nice ${theme === 'light' ? 'tw-nice-light' : ''} ${
        darkTheme && doDarkMode ? 'tw-nice-dark' : ''
      } ${value !== undefined ? 'tw-nice--hasValue' : ''}${
        hideChevron ? ' tw-nice--hideChevron' : ''
      }`}
    >
      <Popover
        preferredAlignment="left"
        active={popoverActive}
        preventCloseOnChildOverlayClick={preventCloseOnChildOverlayClick}
        zIndexOverride={zIndexOverride}
        activator={hint ? <Tooltip content={hint}>{activator}</Tooltip> : activator}
        onClose={togglePopoverActive}
        fullHeight={true}
      >
        {showFilter && (
          <div className="tw-input-inside-drop-down">
            <TextField
              clearButton
              placeholder="Search"
              value={filter}
              onChange={setFilter}
              label=""
              labelHidden
              onClearButtonClick={() => setFilter('')}
              autoComplete="off"
            />
          </div>
        )}
        <OptionList
          allowMultiple={allowMultiple}
          options={options?.filter(filterCb)}
          selected={allowMultiple ? value : [value]}
          onChange={handleChange}
          sections={sections?.map((x) => ({ ...x, options: x.options.filter(filterCb) }))}
        />
        {extraElements && extraElements.length > 0 && (
          <Popover.Pane fixed>
            {extraElements.map((e, i) => (
              <Box
                padding="4"
                borderBlockStart={options?.length || sections?.length ? 'base' : undefined}
                key={`extra-element-${i}`}
              >
                {e}
              </Box>
            ))}
          </Popover.Pane>
        )}
      </Popover>
      <style>
        {hideChevron &&
          `
          .tw-nice--hideChevron .Polaris-Icon__Svg,
          .tw-nice--hideChevron .Polaris-Button__Icon {
            display: none; 
          }
        `}
      </style>
    </div>
  );
};

export default DropDown;
