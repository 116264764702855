import { Tooltip } from '@shopify/polaris';
import { FC, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { type RootState } from 'reducers/RootType';
import { useHistory } from 'react-router';
import CircleLockIcon from './CircleLockIcon';
import { selectHasSomePpsInstalled } from 'utils/selectors';
import { genericEventLogger, analyticsEvents, upgradeActions } from 'utils/dataLayer';
import { upgradePlanClicked } from '../../../ducks/subscription';
import { useAppDispatch } from '../../../index';
import { FeatureFlag } from '@tw/feature-flag-system/module/types';
import { useFeatureFlag } from 'feature-flag-system';

interface Props {
  featureFlag?: FeatureFlag;
  tooltipContent?: string;
  showBlur?: boolean;
  children?: JSX.Element | string | number | null;
  upgradeCallback?: () => void;
  iconSize?: number;
}

export const LockedElement: FC<Props> = ({
  featureFlag,
  tooltipContent = 'Upgrade to unlock',
  showBlur = false,
  children,
  upgradeCallback = () => {},
  iconSize = 26,
}) => {
  const isFreeShop = useSelector((state: RootState) => state.isFreeShop);
  const { features } = useSelector((state: RootState) => state.subscription);
  const hasPPS = useSelector(selectHasSomePpsInstalled);
  const history = useHistory();
  const dispatch = useAppDispatch();
  // const featuresArray = useMemo(
  //   () => (Array.isArray(feature) ? feature : [feature]),
  //   [feature]
  // ).filter((f) => f);
  const { shouldNotBeSeen: isLocked } = useFeatureFlag(featureFlag);

  // const isLocked: boolean = useMemo(() => {
  //   return (
  //     featuresArray?.length > 0 &&
  //     !featuresArray.every((f) => features?.includes(f) || (f === 'PPS' && hasPPS))
  //   );
  // }, [features, hasPPS, featuresArray]);

  const upgrade = useCallback(() => {
    genericEventLogger(analyticsEvents.UPGRADE, {
      action: upgradeActions.UPGRADE_CLICKED,
      feature: featureFlag,
      isFoundersDash: isFreeShop,
    });
    dispatch(upgradePlanClicked(history));
    upgradeCallback();
  }, [history, featureFlag, upgradeCallback, isFreeShop, dispatch]);

  if (!features) return <></>;
  const displayLock = (
    <Tooltip content={tooltipContent}>
      <div className="cursor-pointer" onClick={upgrade}>
        {showBlur && (
          <div className="blur-md text-blue-600 absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
            $1234.56
          </div>
        )}

        <div className={`h-[${iconSize}px] flex relative z-10`}>
          <CircleLockIcon borderColor="#C3DDFF" size={iconSize} />
        </div>
      </div>
    </Tooltip>
  );
  return <>{isLocked ? <>{displayLock}</> : <>{children}</>}</>;
};
