import { $book_demo_modal, closeBookDemoModal } from '$stores/willy/$upgradePopupManager';
import { useStoreValue } from '@tw/snipestate';
import { Modal } from '@tw/ui-components';
import { MeetingPlanner } from 'components/WizardWalkthrough/steps/MeetingPlanner';

export const BookDemoModal = () => {
  const bookDemoModal = useStoreValue($book_demo_modal);
  return (
    <Modal
      opened={bookDemoModal.opened}
      onClose={() => {
        closeBookDemoModal();
      }}
    >
      <MeetingPlanner />
    </Modal>
  );
};
