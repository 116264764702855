import { Button, DatePicker, OptionList, Popover, Stack } from '@shopify/polaris';
import { useState, useCallback, useMemo } from 'react';
import moment from '@tw/moment-cached/module/timezone';
import { TODAY, YESTERDAY } from '../../components/SummaryDatePicker';
import { useDatePickerSelectedOptions } from '../../components/useDatePickerSelectedOptions';
import { mainDatePickerSelectedOptionChange } from '../../ducks/actions';
import { useAppDispatch } from '../../index';
import { useDarkMode } from 'dark-mode-control';
import { Button as TwButton } from '@tw/ui-components';
import { cx } from 'utils/cx';

type DatePickerComponentProps = {
  className?: string;
  dateRange: { start?: moment.Moment; end?: moment.Moment };
  isFromPods?: boolean;
  disabled?: boolean;
  onApply: (range: DatePickerComponentProps['dateRange']) => void;
};

const DatePickerComponent: React.FC<DatePickerComponentProps> = ({
  dateRange,
  onApply,
  disabled = false,
  isFromPods = false,
  className,
}) => {
  const doDarkMode = useDarkMode();
  const [selected, setSelected] = useState(dateRange);
  const [selectedDateObject, setSelectedDateObject] = useState({
    start: dateRange.start ? dateRange.start.toDate() : new Date(),
    end: dateRange.end ? dateRange.end.toDate() : new Date(),
  });
  const dispatch = useAppDispatch();
  const [{ month, year }, setDate] = useState({ month: moment().month(), year: moment().year() });
  const [isOpen, setIsOpen] = useState(false);

  const isToday = useCallback((date) => {
    const midnight = moment().startOf('day');
    return date.isSame(midnight, 'day');
  }, []);

  const _onOptionSelect = (val) => {
    const { start, end, id } = val;
    dispatch(mainDatePickerSelectedOptionChange(id));
    setIsOpen(false);
    onSelectionChange({ start: start.toDate(), end: end.toDate() }, true);
  };

  const defaultOptions = useDatePickerSelectedOptions();

  const popoverOpenerButtonTitle = useMemo(() => {
    const { start, end } = selected;

    if (!start || !end) return 'Select...';

    if (isToday(start)) {
      return TODAY;
    }

    const midnightYesterday = moment().subtract(1, 'days').startOf('day');
    if (start.isSame(midnightYesterday, 'day')) {
      return YESTERDAY;
    }

    const format = 'YYYY/MM/DD';

    if (start.format(format) === end.format(format)) {
      return start.format(format);
    }

    return start.format(format) + ' - ' + end.format(format);
  }, [isToday, selected]);

  const onSelectionChange = useCallback(
    (range, apply = false) => {
      setSelectedDateObject(range);
      let { start, end } = range || {};
      if (isFromPods) {
        start = moment()
          .year(start.getFullYear())
          .month(start.getMonth())
          .date(start.getDate())
          .startOf('day');
        end = moment()
          .year(end.getFullYear())
          .month(end.getMonth())
          .date(end.getDate())
          .endOf('day');
      } else {
        start = moment(start).startOf('day');
        end = moment(end).endOf('day');
      }

      const newSelected = {
        start,
        end,
      };
      setSelected(newSelected);
      if (apply) {
        onApply(newSelected);
      }
    },
    [onApply, isFromPods],
  );

  const onMonthChange = useCallback((month, year) => {
    setDate({ month, year });
  }, []);

  return disabled ? (
    <div className="flex flex-col">
      <div className={cx(doDarkMode ? 'tw-nice-dark' : '', className ?? '')}>
        <TwButton
          disabled={true}
          variant="activator"
          onClick={() => setIsOpen((x) => !x)}
          leftSection="calendar-1"
          rightSection="caret-down"
        >
          {popoverOpenerButtonTitle}
        </TwButton>
      </div>
    </div>
  ) : (
    <Popover
      active={isOpen}
      activator={
        <div className="flex flex-col">
          <div className={cx(doDarkMode ? 'tw-nice-dark' : '', className ?? '')}>
            <TwButton
              variant="activator"
              onClick={() => setIsOpen((x) => !x)}
              leftSection="calendar-1"
              rightSection="caret-down"
            >
              {popoverOpenerButtonTitle}
            </TwButton>
          </div>
        </div>
      }
      onClose={() => setIsOpen(!isOpen)}
      sectioned
      fullHeight
    >
      <OptionList
        onChange={(updated) => {
          _onOptionSelect(updated[0] as any);
        }}
        options={defaultOptions as any}
        selected={[]}
      />
      <DatePicker
        multiMonth={false}
        month={month}
        year={year}
        allowRange
        onChange={onSelectionChange}
        onMonthChange={onMonthChange}
        selected={selectedDateObject}
        disableDatesAfter={moment().toDate()}
      />
      <Stack distribution="trailing" alignment="center">
        <Button
          disabled={disabled}
          primary
          onClick={() => {
            onApply(selected);
            setIsOpen(false);
          }}
        >
          Apply
        </Button>
      </Stack>
    </Popover>
  );
};

export default DatePickerComponent;
