import { $currentPlanName } from '$stores/willy/$subscription';
import { useStoreValue } from '@tw/snipestate';
import { Alert, Box, Button, ButtonProps, Icon, Text } from '@tw/ui-components';
import { useFoundersDashUpgradeButton } from 'components/UpgradePlan/components/FDUpgradeButton';
import { ReactNode, useMemo } from 'react';
import { useHistory } from 'react-router';

export function useGrowthUpgradeButton() {
  const history = useHistory();
  const buttonText = 'Upgrade';
  const action = () => {
    history.push('/plans');
  };
  return { action, buttonText };
}

export type PlanName = string & {};

export type UpgradeCTAOption = {
  text: ReactNode;
  buttonText?: string;
  action: () => unknown;
};

export type CustomMessage = string | Partial<Record<PlanName, string>>;

export function useUpgradeCTAOptions(customMessage?: CustomMessage) {
  const currentPlanName = useStoreValue($currentPlanName);
  const fd = useFoundersDashUpgradeButton('template_library');
  const growth = useGrowthUpgradeButton();

  return useMemo(() => {
    if (!currentPlanName) return null;

    const config: Record<PlanName, UpgradeCTAOption> = {
      'Founders Dash': {
        text:
          typeof customMessage === 'string'
            ? customMessage
            : customMessage?.['Founders Dash'] ||
              'Ready to go beyond this free plan? Upgrade to premium features.',
        buttonText: fd.buttonText,
        action: fd.action,
      },
      Growth: {
        text:
          typeof customMessage === 'string'
            ? customMessage
            : customMessage?.['Growth'] || "You don't have access to this feature on this plan.",
        buttonText: growth.buttonText,
        action: growth.action,
      },
    };

    return config[currentPlanName];
  }, [fd, growth, currentPlanName, customMessage]);
}

export type PlanUpgradeCTAProps = {
  withIcon?: boolean;
  buttonProps?: ButtonProps;
  customMessages?: CustomMessage;
};

/** Cta to upgrade for plans Growth and below */
export function PlanUpgradeCTA({
  withIcon = false,
  buttonProps,
  customMessages,
}: PlanUpgradeCTAProps) {
  const options = useUpgradeCTAOptions(customMessages);

  if (!options) return null;

  return (
    <Alert color="one.2" variant="light">
      <div className="flex flex-col items-center m-[5px] mt-[0px]">
        {withIcon && (
          <Box mb="sm">
            <Icon name="circle-arrow-up-1" size={30} color="one.5" />
          </Box>
        )}
        <Text c="one.7" fz="sm" fw={500} ta="center" mb="sm">
          {options.text}
        </Text>

        <Button fullWidth size="xs" {...buttonProps} onClick={options.action}>
          {options.buttonText}
        </Button>
      </div>
    </Alert>
  );
}
