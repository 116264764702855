import { $derived, $effect, $store } from '@tw/snipestate';
import { AppVersion } from '@tw/types/module/services';
import { User } from 'components/UserProfileManagment/User/constants';
import { NavSection, NavSectionRoute } from 'constants/routes/types';
import { $isAdminClaim, $user, $userId } from './$user';
import { userDb } from 'utils/DB';
import { V2_SECTIONS, V3_SECTIONS } from 'constants/routes/config';
import { $v3_0_Only, $v3_0_Enabled } from './$v3_0_Enabled';
import { $pathname } from './$location';
import { $shop } from './$shop';
import { $ffStore, FeatureFlagComputer, initFeatureFlagComputer } from 'feature-flag-system';
import { Shop } from '@tw/types';
import { $history } from './$history';
import { isLocal, isProd } from '@tw/constants';
import { isEqual } from 'lodash';

type LastRoutesRecord = Record<AppVersion, string>;

type ActiveRoute = {
  activeRoute?: NavSectionRoute;
  activeSection?: NavSection;
  isLocked: boolean;
  isActiveRoute: (activeRoute?: NavSectionRoute, pathname?: string) => boolean;
};

type AppConfigStore = {
  loading: boolean;
  activeAppVersion: AppVersion | null;
  navSections: NavSection[];
  lastRoutesPerVersion: LastRoutesRecord;
  activeRoute: ActiveRoute;
};

/**
 * Here is the string of dependencies:
 * - activeAppVersion -> navSections -> activeRoute -> lastRoutesPerVersion
 * - location -> activeRoute -> [activeAppVersion -> navSections, lastRoutesPerVersion]
 *
 * Nav sections shouldn't change unless either location or version changes.
 * Last routes per version ONLY changes when activeRoute changes, so no need to expose separate method for it.
 * Active route is also something that shouldn't be settable from the outside.
 */

export class AppConfigManager {
  private defaultSavedRoutes = { '2.0': '/summary', '3.0': '/chat' } as const;

  // just nice to have all sections in one place and already formatted for efficient computations
  private readonly _combinedSections = [...V2_SECTIONS, ...V3_SECTIONS];
  private readonly _combinedRoutes = this._combinedSections.flatMap((s) => s.routes);
  /** These are some routes that we don't want to save as the customer's "last route" */
  private readonly _nonSaveableRoutes = this._combinedRoutes.filter(
    (r) => r.isShoplessPage || r.isSettingsPage,
  );
  private _listenerUnsubs = new Set<() => void>();

  public constructor() {
    this._init();
  }

  /**
   * Main store containing globaly used important config data.
   * The fields in this store determine the state of the whole app.
   */
  public readonly $config = $store<AppConfigStore>({
    loading: true,
    activeAppVersion: null,
    navSections: this._combinedSections, // using combined initially, since init needs access to all routes to decide version
    lastRoutesPerVersion: this.defaultSavedRoutes,
    activeRoute: { isLocked: false, isActiveRoute: this.isMatchingRoute },
  });

  private readonly $activeAppVersion = $derived((g) => g(this.$config).activeAppVersion);

  private readonly $lastRoutesPerVersion = $derived((g) => g(this.$config).lastRoutesPerVersion);

  /**
   * Provides a safe way to get the correct route to switch to when
   * the app version changes or if you want to direct user to right
   * route for their current version when they're entering the app
   * via shops admin or pods view.
   *
   * If we're coming from the pods view where we don't have all the
   * data we need to determine a redirect, we need to init the config
   * manager here when a shop is provided.
   */
  public async gotoLastSavedRouteForVersion({
    method = 'push',
    search = '',
    shopId,
  }: {
    method?: 'push' | 'replace';
    search?: string;
    shopId: string;
  }) {
    if (this._isNonSaveableRoute(window.location.pathname)) {
      this.$config.set((x) => ({ ...x, loading: true }));
      await initFeatureFlagComputer(shopId, []);

      const newVersion = this._calculateVersion();
      const navSections = this._calculateNavSections({ activeAppVersion: newVersion });
      const savedNewPath = this.$config.get().lastRoutesPerVersion[newVersion];

      // if somehow a shopless route got saved, we fix it here
      const isRouteToAvoid = this._isNonSaveableRoute(savedNewPath);
      const newPath = isRouteToAvoid ? this.defaultSavedRoutes[newVersion] : savedNewPath;

      this.$config.set((x) => ({
        ...x,
        activeAppVersion: newVersion,
        navSections,
        activeRoute: this._calculateActiveRoute(newPath, navSections),
      }));

      $history.get()[method]?.(newPath + (search || ''));
      setTimeout(() => this.$config.set((x) => ({ ...x, loading: false })), 0);
    } else {
      const { lastRoutesPerVersion, activeAppVersion } = this.$config.get();
      const ver = activeAppVersion || '2.0';
      // if somehow a shopless route got saved, we fix it here
      const isRouteToAvoid = this._isNonSaveableRoute(lastRoutesPerVersion[ver]);
      const newPath = isRouteToAvoid ? this.defaultSavedRoutes[ver] : lastRoutesPerVersion[ver];
      $history.get()[method]?.(newPath + (search || ''));
    }
  }

  private get user() {
    // this gets initted in `_waitForRequiredEntities`, so coercion is ok here
    return $user.get() as User;
  }

  private get v3Enabled() {
    return $v3_0_Enabled.get();
  }

  private get v2Disabled() {
    return $v3_0_Only.get();
  }

  /**
   * Waits for important global data to be ready that is necessary
   * for us to have before attempting to calculate navigation global config.
   */
  private async _waitForRequiredEntities(): Promise<void> {
    const inShoplessRoute = this._isNonSaveableRoute(window.location.pathname);

    return new Promise((res) =>
      $effect((unsub, get) => {
        const userId = get($userId);
        const v3Enabled = get($v3_0_Enabled);
        const ffIsReady = get($ffStore).isReady;

        // wait for ff system if we're not in a route like /pods-view
        if (!userId || (v3Enabled === null && !inShoplessRoute) || !ffIsReady) return;
        unsub();
        res();
      }),
    );
  }

  private async _init() {
    // if we ever choose to "re-init" the config, we need to clean previous listeners
    this._cleanupListeners();

    await this._waitForRequiredEntities();

    const activeRoute = this._calculateActiveRoute(window.location.pathname);
    const newVersion = this._calculateVersion(activeRoute.activeRoute, true);
    const navSections = this._calculateNavSections({ activeAppVersion: newVersion });
    const needsRedirect = !this._doesRouteMatchVersion(newVersion, activeRoute.activeRoute);
    const newLastRoutes = this._calculateInitialLastRoutes(this.user.lastRoutesPerVersion);
    const newPath = needsRedirect ? newLastRoutes[newVersion] : window.location.pathname;

    this.$config.set({
      loading: needsRedirect,
      activeAppVersion: newVersion,
      navSections,
      activeRoute: this._calculateActiveRoute(newPath, navSections),
      lastRoutesPerVersion: newLastRoutes,
    });

    if (needsRedirect) {
      $history.get().push?.({ pathname: newPath, search: window.location.search });
      setTimeout(() => this.$config.set((x) => ({ ...x, loading: false })), 0);
    }

    this._initListeners();
  }

  /** Checks if provided `NavSectionRoute` matches the provided path */
  public isMatchingRoute(route?: NavSectionRoute, pathname: string = window.location.pathname) {
    return !!(
      (route?.url && pathname.startsWith(route.url)) ||
      (route?.urlToCheck &&
        (Array.isArray(route?.urlToCheck)
          ? route?.urlToCheck.some((r) => pathname.startsWith(r))
          : pathname.startsWith(route?.urlToCheck)))
    );
  }

  /**
   * Updates app version and other entities that are tied to it,
   * and redirects to last saved route for new version.
   * Toggle doesn't work if app version isn't initialized yet.
   */
  public toggleAppVersion(): void {
    const currentVersion = this.$config.get().activeAppVersion;
    if (!currentVersion) return;

    const newVersion = currentVersion === '2.0' ? '3.0' : '2.0';
    if (this.v3Enabled === false && newVersion === '3.0') return;
    if (this.v2Disabled === true && newVersion === '2.0') return;

    const savedPathForVersion = this.$config.get().lastRoutesPerVersion[newVersion];
    const navSections = this._calculateNavSections({ activeAppVersion: newVersion });
    const needsRedirect = savedPathForVersion !== window.location.pathname;
    const usedPath = needsRedirect ? savedPathForVersion : window.location.pathname;
    const activeRoute = this._calculateActiveRoute(usedPath, navSections);

    this.$config.set((x) => ({
      ...x,
      loading: needsRedirect,
      activeAppVersion: newVersion,
      navSections,
      activeRoute,
    }));

    if (needsRedirect) {
      $history.get().push?.({ pathname: usedPath, search: window.location.search });
      setTimeout(() => this.$config.set((x) => ({ ...x, loading: false })), 0);
    }
  }

  private _isNonSaveableRoute(path: string) {
    return this._nonSaveableRoutes.some((r) => path.startsWith(r.url));
  }

  private _isLastRoutesRecord(x: unknown): x is LastRoutesRecord {
    if (typeof x !== 'object' || !x) return false;
    return ['2.0', '3.0'].every((k) => typeof x[k] === 'string');
  }

  private async _saveToUser(
    payload: Partial<Pick<AppConfigStore, 'activeAppVersion' | 'lastRoutesPerVersion'>>,
  ) {
    try {
      await userDb(this.user.uid).update(payload);
    } catch (err) {
      const attemptedFields = Object.keys(payload).join();
      console.error(`Error updating fields "${attemptedFields}" to user:>>`, err);
    }
  }

  /**
   * Contains all the processes that need to be automated,
   * reacting to different entities.
   *
   * - Saves `activeAppVersion` and `lastRoutesPerVersion` in db whenever they're set in store
   * - Calculates, validates, and sets `lastRoutesPerVersion` whenever `pathname` changes
   * - Automatically updates `navSections` whenever shopDashboards, v3Enabled, or isAdminClaim change
   * - Updates `activeRoute` whenever `pathname`, `shop`, or `ffcomputer` get updated as long as
   *   the pathname matches the active version.
   */
  private _initListeners() {
    // saving effect - each time data connected to user changes, we save it
    this._listenerUnsubs.add(
      $effect((_, get, timesRun) => {
        const activeAppVersion = get(this.$activeAppVersion);
        const lastRoutesPerVersion = get(this.$lastRoutesPerVersion);
        if (timesRun === 0) return;

        this._saveToUser({ activeAppVersion, lastRoutesPerVersion });
      }),
    );

    // last route per version
    this._listenerUnsubs.add(
      // use subscribe to be lazy
      $pathname.subscribe((path) => {
        const { activeAppVersion: ver } = this.$config.get();

        // path shouldn't be set if it doesn't match version or is path that shouldn't be saved
        if (!ver || !this._doesRouteMatchVersion(ver, path) || this._isNonSaveableRoute(path))
          return;

        this.$config.set((x) => ({
          ...x,
          lastRoutesPerVersion: { ...x.lastRoutesPerVersion, [ver]: path },
        }));
      }),
    );

    // nav sections
    this._listenerUnsubs.add(
      $effect((_, get) => {
        const sections = this._calculateNavSections({
          v3Enabled: get($v3_0_Enabled),
          isAdmin: get($isAdminClaim),
        });
        this.$config.set((x) => ({ ...x, navSections: sections }));
      }),
    );

    // active route
    this._listenerUnsubs.add(
      $effect((_, get) => {
        const pathname = get($pathname);
        const { activeAppVersion, navSections } = this.$config.get();
        const isVersionMatch = this._doesRouteMatchVersion(activeAppVersion, pathname);
        if (!isVersionMatch) return;

        const activeRoute = this._calculateActiveRoute(
          pathname,
          navSections,
          get($shop),
          get($ffStore),
        );
        this.$config.set((x) => ({ ...x, activeRoute }));
      }),
    );
  }

  private _cleanupListeners() {
    this._listenerUnsubs.forEach((u) => u());
    this._listenerUnsubs.clear();
  }

  private _calculateVersion(activeRoute?: ActiveRoute['activeRoute'], isInitialCalc = false) {
    // careful to do "false" instead of falsey, since some values can be null - which doesn't mean anything
    if (this.v3Enabled === false) return '2.0';
    if (this.v2Disabled === true) return '3.0';

    // if there's an activeRoute, use it to determine version
    if (activeRoute) {
      if (activeRoute.isWillyPage) return '3.0';
      if (!activeRoute.isHybridPage) return '2.0';
    }

    // only use saved route on user for initial calculation,
    // since the data becomes stale after we do our updates
    // to activeAppVersion and lastRoutes
    if (isInitialCalc && this.user.activeAppVersion) {
      return this.user.activeAppVersion;
    }

    // finally default to saved version
    return this.$config.get().activeAppVersion || '2.0';
  }

  /**
   * Used to make sure all the saved routes for the versions still match the
   * current routes we have. This is useful if we've made a change to the route.
   */
  private _calculateInitialLastRoutes(userLastRoutes = this.user.lastRoutesPerVersion) {
    if (
      !this._isLastRoutesRecord(userLastRoutes) ||
      isEqual(userLastRoutes, this.defaultSavedRoutes)
    ) {
      return this.defaultSavedRoutes;
    }

    const newLastRoutes: LastRoutesRecord = { ...userLastRoutes };
    for (const version in newLastRoutes) {
      if (this._doesRouteMatchVersion(version as AppVersion, newLastRoutes[version])) continue;

      // override saved with default in case saved doesn't match version now
      newLastRoutes[version] = this.defaultSavedRoutes[version];
    }

    return newLastRoutes;
  }

  private _calculateNavSections({
    activeAppVersion = this.$config.get().activeAppVersion,
    v3Enabled = !!$v3_0_Enabled.get(),
    isAdmin = $isAdminClaim.get(),
  }: Partial<{
    activeAppVersion: AppVersion | null;
    v3Enabled: boolean | null;
    isAdmin: boolean;
  }>): NavSection[] {
    const sections = v3Enabled && activeAppVersion === '3.0' ? V3_SECTIONS : V2_SECTIONS;
    return sections.map(({ routes, ...s }) => ({
      ...s,
      routes: routes.filter((r) => (!!r.onlyAdmin ? isAdmin : true)),
    }));
  }

  /** Checks if provided route url (path) or NavSectionRoute exists in the routes for app version */
  private _doesRouteMatchVersion(version: AppVersion | null, route?: NavSectionRoute | string) {
    // if no route, it doesn't exist in any version
    if (!route) return false;

    const condition = (route: NavSectionRoute) => {
      return !!(
        route.isHybridPage ||
        (route.isWillyPage && version === '3.0') ||
        (!route.isWillyPage && version === '2.0')
      );
    };

    // Handle route as NavSectionRoute
    if (typeof route !== 'string') return condition(route);

    // Handle route as string
    const sections = this._calculateNavSections({ activeAppVersion: version });

    for (const { routes } of sections) {
      const foundRoute = routes.find((r) => this.isMatchingRoute(r, route));
      if (foundRoute) return condition(foundRoute);
    }

    return false;
  }

  private _calculateActiveRoute(
    pathname: string = $pathname.get(),
    sections: NavSection[] = this.$config.get().navSections,
    shop: Partial<Shop> = $shop.get(),
    ffComputer: FeatureFlagComputer = $ffStore.get(),
  ): ActiveRoute {
    const data: ActiveRoute = { isLocked: false, isActiveRoute: this.isMatchingRoute };

    if (!pathname?.trim()) return data;

    for (const section of sections) {
      const matchingRoute = section.routes.find((r) => this.isMatchingRoute(r, pathname));

      if (matchingRoute) {
        data.activeRoute = matchingRoute;
        data.activeSection = section;
        break;
      }
    }

    if (typeof data.activeRoute?.dependsOnFFSystemCallback === 'function') {
      data.isLocked = !data.activeRoute.dependsOnFFSystemCallback(ffComputer, shop);
    } else if (data.activeRoute?.dependsOnFFSystem) {
      const { shouldNotBeSeen } = ffComputer.getConfigById(data.activeRoute.dependsOnFFSystem);
      data.isLocked = shouldNotBeSeen;
    }

    return data;
  }
}

const appConfigManager = new AppConfigManager();

export function getAppConfigManager() {
  return appConfigManager;
}

if (!isProd || isLocal) window['appConfigManager'] = appConfigManager;
