import { Message } from '../../../types/willyTypes';
import React from 'react';
import { WillyActionCosts } from '../cost/WillyActionCots';
import {
  CostActionResult,
  DashboardActionResult,
  MainActionResult,
  ProviderActionResult,
  ReportsActionResult,
  GenericActionResult,
} from '@tw/types/module/willy/langchain/langchain';
import { WillyActionReports } from '../reports/WillyActionReports';
import { WillyActionProvider } from '../provider/WillyActionProvider';
import { WillyActionDashboard } from '../dashboard/WillyActionWillyDashboard';
import { WillyActionTheme } from '../lightActions/WillyActionTheme';

type WillyActionProps = {
  message: Message;
};

//example of response

// "data": {
//   "mainType": "cost",
//       "data": {
//     "costType": "cogs",
//         "crud": "create",
//         "data": {
//       "chainSuccess": true,
//           "cost": 46,
//           "name": "ssa"
//     }
//   }
// }

export const WillyActionMain: React.FC<{ message: Message }> = ({ message }) => {
  const { actions } = message;
  if (!actions?.length) return null;

  return (
    <>
      {actions.map((action, i) => (
        <SingleWillyAction key={i} action={action} />
      ))}
    </>
  );
};

export const SingleWillyAction: React.FC<{ action: MainActionResult }> = ({ action }) => {
  const { data, mainActionType, error } = action;

  if (error) return null;

  switch (mainActionType) {
    case 'cost':
      return <WillyActionCosts data={data as CostActionResult} />;
    case 'report':
      return <WillyActionReports data={data as ReportsActionResult} />;
    case 'dashboard':
      return <WillyActionDashboard data={data as DashboardActionResult} />;
    case 'provider_account':
      return <WillyActionProvider data={data as ProviderActionResult} />;
    case 'theme':
      return <WillyActionTheme data={data as GenericActionResult} />;
    default:
      return <>Action is not supported, please try again</>;
  }
};

// export const WillyActionMain: React.FC<WillyActionProps> = ({ message }) => {
//   const integrationsWidgets = useIntegrationsConfig();
//
//   const { action } = message;
//   if (!action) return null;
//
//   const { data, type } = action;
//
//   switch (type) {
//     case 'connect_provider_account':
//     case 'disconnect_provider_account':
//       const { provider } = data;
//       const w = integrationsWidgets.find((w) => w.serviceId === provider);
//       if (!w) {
//         return <>Integration not supported</>;
//       }
//       return <IntegrationWidget {...w} />;
//     case 'create_report':
//       return <WillyCreateReportForm message={message} />;
//     case 'edit_report':
//       const { action } = data;
//       if (action === 'delete' || action === 'edit') {
//         return <Reports />;
//       } else {
//         return <>Edit report</>;
//       }
//     case 'create_dashboard':
//       const { name, description, isDynamic, imageData } = data;
//       const defaultFiles: File[] = [];
//       if (imageData) {
//         const fileName: string = 'dashboard.png';
//         const file: File = base64ToFile(imageData, fileName, 'image/png');
//         defaultFiles.push(file);
//       }
//       return (
//         <NewDashModal
//           title={name}
//           newDashboardDefaultName={name}
//           newDashboardDefaultDescription={description}
//           newDashboardDefaultIsDynamic={isDynamic}
//           defaultFiles={defaultFiles}
//           parameters={[]}
//           inModal={false}
//           show={true}
//         />
//       );
//     case 'add_cost':
//       const { costType } = data;
//       switch (costType) {
//         case 'cogs':
//           return <CogsPage />;
//         case 'shipping_cost':
//           return <Shipping />;
//         case 'gateway_cost':
//           return <PaymentGatewayCosts />;
//         case 'custom_expenses':
//           return <CustomSpends />;
//         default:
//           return <CustomSpends />;
//       }
//     default:
//       return <>Action not supported</>;
//   }
// };
