import { CostActionResult } from '@tw/types/module/willy/langchain/langchain';
import { WillyActionCreateDashboard } from '../dashboard/WillyActionCreateDashboard';
import CogsPage from '../../../../../pages/cogs/CogsPage';
import { Shipping } from '../../../../Shipping';
import { PaymentGatewayCosts } from '../../../../PaymentGatewayCosts';
import CustomSpends from '../../../../../routes/CustomSpends';

export const WillyActionCosts: React.FC<{ data: CostActionResult }> = ({ data }) => {
  const { costType, data: dashboardData } = data;
  //TODO need to implement all the actions create/edit/delete for all the costs
  return (
    <>
      {costType === 'cogs' && <CogsPage />}
      {costType === 'shipping_cost' && <Shipping isWizard={false} setNextButton={() => {}} />}
      {costType === 'gateway_cost' && <PaymentGatewayCosts />}
      {costType === 'custom_expenses' && <CustomSpends />}
    </>
  );
};
