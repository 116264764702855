import { $derived, $mutableDerived, $store, useStoreValue } from '@tw/snipestate';
import {
  $isPlanPreview,
  $isOnPremiumPlusPlan,
  $isStartShowPlanPreviewCTA,
  $planPreviewDayLeft,
  $isFreeShop,
  $currentPlanName,
  $currentPlanPreviewPlanName,
  $isShopWasOnPlanPreviewPlusBefore,
  $isFullAccessToChat,
} from './$subscription';
import { $activeAppVersion, $activeRoute } from '$stores/nav-config-stores';
import { useIsSmall } from 'hooks/useDefaultWindowSizes';
import { $ffStore } from '../../feature-flag-system';
import { FeatureFlag } from '@tw/feature-flag-system/module/types';
import { userDb } from 'utils/DB';
import { $user } from '$stores/$user';
import { analyticsEvents, genericEventLogger, upgradeCtaActions } from 'utils/dataLayer';

const LOCAL_STORAGE_MOBY_POPUP_KEY = 'mobyPopupClosedByUser';
const LOCAL_STORAGE_FREETRIAL_POPUP_KEY = 'initialFreeTrialPopupClosedByUser';
const LOCAL_STORAGE_FREETRIAL_EXPIRED_BANNER_KEY = 'freeTrialExpiredBannerClosedByUser';

export const $shouldShowMobyUpgradeCtas = $derived((get) => {
  const isOnPremiumPlusPlan = get($isOnPremiumPlusPlan);
  const isPlanPreview = get($isPlanPreview);
  return !isPlanPreview && !isOnPremiumPlusPlan;
});

//MOBY POPUP
export const $showMobyPopup = $derived((get) => {
  const fullAccess = get($isFullAccessToChat);
  const shoulShow = get($shouldShowMobyUpgradeCtas) || fullAccess;
  const popupWasClosed = !!(
    localStorage.getItem(LOCAL_STORAGE_MOBY_POPUP_KEY) &&
    localStorage.getItem(LOCAL_STORAGE_MOBY_POPUP_KEY) === 'true'
  );
  const popupClosedUser = get($user)?.mobyPopupClosed;

  return !popupWasClosed && shoulShow && !popupClosedUser;
});

export const $unlock_moby_modal = $store<{ opened: boolean; initial: boolean }>({
  opened: false,
  initial: false,
});

export const openUnlockMobyModal = (initial = false) => {
  $unlock_moby_modal.set({ opened: true, initial: initial });
};

export const closeUnlockMobyModal = async () => {
  const isInitialPopup = $unlock_moby_modal.get().initial;
  if (isInitialPopup) {
    localStorage.setItem(LOCAL_STORAGE_MOBY_POPUP_KEY, 'true');
    await userDb().set({ mobyPopupClosed: true }, { merge: true });
  }
  $unlock_moby_modal.set({ opened: false, initial: false });
};

//FREE TRIAL BANNER
export const $trial_banner = $mutableDerived((get) => {
  const isPlanPreview = get($isPlanPreview);
  const isExpiredPlanPreview = get($isShopWasOnPlanPreviewPlusBefore);
  const currentPlanName = get($currentPlanPreviewPlanName);
  const daysLeft = get($planPreviewDayLeft);
  const bannerWasClosed = !!(
    localStorage.getItem(LOCAL_STORAGE_FREETRIAL_EXPIRED_BANNER_KEY) &&
    localStorage.getItem(LOCAL_STORAGE_FREETRIAL_EXPIRED_BANNER_KEY) === 'true'
  );
  const bannerClosedUser = get($user)?.trialBannerClosed;
  return {
    show: !bannerWasClosed && !bannerClosedUser && (isPlanPreview || isExpiredPlanPreview),
    daysLeft: isExpiredPlanPreview ? 0 : daysLeft,
    currentPlanName,
  };
});

export const closeTrialBanner = (addToLocalStorage: boolean) => async () => {
  if (addToLocalStorage) {
    localStorage.setItem(LOCAL_STORAGE_FREETRIAL_EXPIRED_BANNER_KEY, 'true');
    await userDb().set({ trialBannerClosed: true }, { merge: true });
  }
  $trial_banner.set({ show: false, daysLeft: 0, currentPlanName: '' });
};

export const FREE_TRIAL_BANNER_HEIGHT = 48;

export const FREE_TRIAL_BANNER_HEIGHT_VAR = '--free-trial-banner-height';

// export const $topBannerIsShowing = $observer((get) => {
//   const appVersion = get($activeAppVersion);
//   const activeRoute = get($activeRoute).activeRoute;
//   const isShoplessPage = activeRoute?.isShoplessPage;
//   const isSettingsPage = activeRoute?.isSettingsPage;
//   const isAppsPage = !isSettingsPage && !isShoplessPage && appVersion === '3.0';
//   console.log('isAppsPage', isAppsPage);
//   const trial_banner_should_show = get($trial_banner).show;
//   return trial_banner_should_show && isAppsPage;
// });

//FREE TRIAL POPUP
export const $showFreeTrialPopup = $derived((get) => {
  const isFreeShop = get($isFreeShop);
  const shouldShow = get($isStartShowPlanPreviewCTA);
  const popupWasClosed = !!(
    localStorage.getItem(LOCAL_STORAGE_FREETRIAL_POPUP_KEY) &&
    localStorage.getItem(LOCAL_STORAGE_FREETRIAL_POPUP_KEY) === 'true'
  );
  const popupWasClosedUser = get($user)?.freeTrialPopupClosed;
  return !popupWasClosed && shouldShow && !isFreeShop && !popupWasClosedUser;
});

export const openFreeTrialPopup = (source) => {
  $free_trial_modal.set({ opened: true, source: source });
  if (source === 'initial') {
    genericEventLogger(analyticsEvents.GENERAL, {
      action: upgradeCtaActions.FREE_TRIAL_POPUP_VIEWED,
    });
  } else {
    logUgradeEvent(source, upgradeCtaActions.UPGRADE_CTA_CLICKED);
  }
};

export const closeFreeTrialPopup = async (log = true) => {
  const source = $free_trial_modal.get().source;
  const isInitialPopup = source === 'initial';
  if (isInitialPopup) {
    await userDb().set({ freeTrialPopupClosed: true }, { merge: true });
    localStorage.setItem(LOCAL_STORAGE_FREETRIAL_POPUP_KEY, 'true');
    if (log) {
      genericEventLogger(analyticsEvents.GENERAL, {
        action: upgradeCtaActions.CLOSE_FREE_TRIAL_POUP,
      });
    }
  } else {
    if (log) {
      logUgradeEvent(source, upgradeCtaActions.CLOSE_FREE_TRIAL_POUP);
    }
  }
  $free_trial_modal.set({ opened: false, source: null });
};

export const $free_trial_modal = $store<{
  opened: boolean;
  source: null | string;
}>({
  opened: false,
  source: null,
});

//BOOK DEMO MODAL

export const $book_demo_modal = $store<{ opened: boolean }>({
  opened: false,
});

export const openBookDemoModal = () => {
  $book_demo_modal.set({ opened: true });
};

export const closeBookDemoModal = () => {
  $book_demo_modal.set({ opened: false });
};

export type UpgradeEventSource =
  | 'moby'
  | 'chat'
  | 'dashboards'
  | 'template_library'
  | 'prompt_library'
  | (string & {});

export const logUgradeEvent = (source: UpgradeEventSource | null, action) => {
  let SOURCE;
  switch (source) {
    case 'moby':
      SOURCE = 'moby_popup';
      break;
    case 'chat':
      SOURCE = analyticsEvents.CHAT;
      break;
    case 'dashboards':
      SOURCE = analyticsEvents.DASHBOARDS;
      break;
    case 'template_library':
      SOURCE = analyticsEvents.TEMPLATE_LIBRARY;
      break;
    case 'prompt_library':
      SOURCE = analyticsEvents.PROMPT_LIBRARY;
      break;
    default:
      SOURCE = source;
  }
  genericEventLogger(SOURCE, {
    action: action,
  });
};
