import { IntegrationWidget } from '../../../../../routes/integrations/components/IntegrationWidget';
import React from 'react';
import { useIntegrationsConfig } from '../../../../../routes/integrations/hooks/useIntegrationsConfig';
import { ShopProviderStatusEnum } from '@tw/types/module/types/ShopProviders';

export const WillyActionProviderShow: React.FC<{ data: any }> = ({ data }) => {
  const integrationsWidgets = useIntegrationsConfig();
  const { provider_id, operation } = data;
  return (
    <div className="list w-full mt-10 grid grid-cols-1 md:grid-cols-2 auto-rows-auto gap-6">
      {integrationsWidgets
        .filter((w) =>
          operation === 'connect'
            ? w.providerState && w.providerState?.status != ShopProviderStatusEnum.disconnected
            : !w.providerState || w.providerState?.status === ShopProviderStatusEnum.disconnected,
        )
        .map((w, i) => (
          <IntegrationWidget key={i} {...w} />
        ))}
    </div>
  );
};
