import Attribution from 'pages/Attribution/Attribution';
import { computeFeatureFlags } from 'feature-flag-system';
import { FeatureFlag } from '@tw/feature-flag-system/module/types';
import { UpgradePageFallBack } from 'feature-flag-system/components';

const AttributionAdsPageComponent = ({ setDashboardPixelData }) => {
  return <Attribution sourceCategory="ads" setDashboardPixelData={setDashboardPixelData} />;
};

const AttributionAdsPage = computeFeatureFlags(
  FeatureFlag.PIXEL_ALL_FF,
  AttributionAdsPageComponent,
  () => (
    <UpgradePageFallBack
      featureFlag={FeatureFlag.PIXEL_ALL_FF}
      InAppContextBannerEnabled={true}
      InAppContextBannerPage={'attribution'}
    />
  ),
);

export default AttributionAdsPage;
