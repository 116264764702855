import { $derived, $effect, $observer, $store } from '@tw/snipestate';
import _db, { firestoreRef, toArray } from 'utils/DB';
import { $currentShopId } from '$stores/$shop';
import { confirm } from '@tw/ui-components';
import {
  WillyCustomMetric,
  WillyExpressionMetric,
  WillyMetric,
} from 'components/Willy/types/willyTypes';
import {
  findAllGlobalMetricsForProvider,
  metricIsCustom,
  metricIsExpression,
} from '../../components/Willy/WillyMetrics/utils';
import { toast } from 'react-toastify';
import { FeatureFlag } from '@tw/feature-flag-system/module/types';
import { services, ServicesIds } from '@tw/types/module/services';
import { $ffStore } from '../../feature-flag-system';
import { $isAdminClaim, $userId } from '../$user';
import { $shopWithSensory } from '../$shopWithSensory';
import { FilterGroup } from '@tw/willy-data-dictionary/module/columns/types';

export type MetricModalState = {
  isOpen: boolean;
  isOpenCustom?: boolean;
  isFilterMode?: boolean;
  metricEdit?: WillyExpressionMetric | WillyCustomMetric;
};

const modalProps = {
  zIndex: 10000,
  portalProps: {
    style: {
      zIndex: 10000,
    },
  },
};
function removeUndefinedValuesRecursively(data) {
  // Check if the data is an array
  if (Array.isArray(data)) {
    return data
      .filter((item) => item !== undefined) // Remove undefined items in the array
      .map((item) => {
        // Recursively process each item
        if (item && typeof item === 'object') {
          return removeUndefinedValuesRecursively(item);
        }
        return item;
      });
  } else if (typeof data === 'object' && data !== null) {
    // If it's an object, construct a new object without undefined values
    return Object.entries(data).reduce((acc, [key, value]) => {
      if (value !== undefined) {
        // Recursively process each value
        acc[key] = typeof value === 'object' ? removeUndefinedValuesRecursively(value) : value;
      }
      return acc;
    }, {});
  }
  // Return the data as is if it's not an object or array
  return data;
}

export const $globalWillyMetricsRaw = $observer<WillyExpressionMetric[]>([], (get, set) => {
  const ffComputer = get($ffStore);
  if (!ffComputer.isReady) return;

  const isAdminClaim = get($isAdminClaim);
  const shopWithSensory = get($shopWithSensory);

  const { blockList } = ffComputer.getConfigById(FeatureFlag.LIMIT_INTEGRATIONS_FF);

  return firestoreRef()
    .collection('willy_global_metrics')
    .onSnapshot((querySnapshot) => {
      let arr: WillyExpressionMetric[] = toArray(querySnapshot).map((m: WillyExpressionMetric) => {
        let isNotConnected = false;
        let isBlocked = false;
        const { relatedProvider } = m;

        if (relatedProvider && !(['triple-whale'] as ServicesIds[]).includes(relatedProvider)) {
          const provider = services[relatedProvider];
          if (provider) {
            isNotConnected = !provider.getIsConnected?.(shopWithSensory) ?? false;
            isBlocked = blockList.includes(relatedProvider);
          }
        }

        return {
          ...m,
          isNotConnected,
          isBlocked: isAdminClaim ? false : isBlocked,
          isGlobal: true,
        };
      });

      set(arr.sort((a, b) => a.name.localeCompare(b.name)));
    });
});

export const $globalWillyCustomMetrics = $observer<WillyCustomMetric[]>([], (get, set) => {
  if (!get($userId)) return;

  const globalRaw = get($globalWillyMetricsRaw);
  if (globalRaw.length === 0) return;

  const isAdminClaim = get($isAdminClaim);

  return firestoreRef()
    .collection('willy_global_custom_metrics')
    .onSnapshot((querySnapshot) => {
      let arr: WillyCustomMetric[] = toArray(querySnapshot).map((m: WillyCustomMetric) => {
        let isNotConnected = false;
        let isBlocked = false;

        const { expression } = m;

        const metricsIds = expression
          .filter((x) => x.type === 'metric')
          .map((x) => x.value)
          .filter((x) => x);

        const globalMetricsFromExpressionMetrics = globalRaw.filter((x) =>
          metricsIds.some((y) => y === x.id),
        );
        isNotConnected = globalMetricsFromExpressionMetrics.some((x) => x.isNotConnected);
        isBlocked = globalMetricsFromExpressionMetrics.some((x) => x.isBlocked);

        return {
          ...m,
          isGlobal: true,
          isCustomMetric: true,
          isNotConnected,
          isBlocked: isAdminClaim ? false : isBlocked,
        };
      });

      set(arr.sort((a, b) => a.name.localeCompare(b.name)));
    });
});

export const deleteWillyMetric = async (
  id: string,
  isGlobal?: boolean,
  showWarning: boolean = true,
) => {
  if (showWarning) {
    if (
      !(await confirm({
        title: `Delete ${isGlobal ? 'global' : ''} metric?`,
        message: `Are you sure you want to delete this ${
          isGlobal ? 'global' : ''
        } metric permanently?`,
        modalProps,
      }))
    )
      return;
  }

  if (isGlobal) {
    return firestoreRef().collection('willy_global_metrics').doc(id.toString()).delete();
  } else {
    return _db().collection('willy_metrics').doc(id.toString()).delete();
  }
};

export const deleteWillyCustomMetric = async (id: string, isGlobal?: boolean) => {
  if (
    await confirm({
      title: `Delete ${isGlobal ? 'global' : ''} custom metric?`,
      message: `Are you sure you want to delete this ${
        isGlobal ? 'global' : ''
      } custom metric permanently?`,
      modalProps,
    })
  ) {
    if (isGlobal) {
      return firestoreRef().collection('willy_global_custom_metrics').doc(id).delete();
    } else {
      return _db().collection('willy_custom_metrics').doc(id).delete();
    }
  }
};

export const editWillyMetric = async (m: WillyExpressionMetric, forceNotGlobal = false) => {
  if (m.isGlobal && !forceNotGlobal) {
    if (
      await confirm({
        title: 'Edit global metric?',
        message: 'Are you sure you want to edit this global metric permanently??',
        modalProps,
      })
    ) {
      return firestoreRef()
        .collection('willy_global_metrics')
        .doc(m.id)
        .set({ ...m, filter: removeUndefinedValuesRecursively(m.filter) }, { merge: true });
    }
  } else {
    return _db()
      .collection('willy_metrics')
      .doc(m.id)
      .set({ ...m, filter: removeUndefinedValuesRecursively(m.filter) }, { merge: true });
  }
};

export const createWillyMetric = async (m: WillyExpressionMetric) => {
  if (!m.id) {
    throw new Error('Metric ID is required');
  }
  const metricWithKey = {
    ...m,
    key: m.id,
  };
  if (m.isGlobal) {
    const confirmed = await confirm({
      title: 'Create global metric?',
      message: 'Are you sure you want to create this global metric?',
      modalProps,
    });
    if (confirmed) {
      metricWithKey.id =
        metricWithKey.tableId +
        '_' +
        metricWithKey.columnId +
        (metricWithKey.relatedProvider
          ? '_' + metricWithKey.relatedProvider.replaceAll('-', '_')
          : '');
      return await firestoreRef()
        .collection('willy_global_metrics')
        .doc(metricWithKey.id)
        .set(
          { ...metricWithKey, filter: removeUndefinedValuesRecursively(metricWithKey.filter) },
          { merge: true },
        );
    }
  } else {
    return await _db()
      .collection('willy_metrics')
      .doc(metricWithKey.id)
      .set(
        { ...metricWithKey, filter: removeUndefinedValuesRecursively(metricWithKey.filter) },
        { merge: true },
      );
  }
};

export const editWillyCustomMetric = async (cm: WillyCustomMetric) => {
  if (cm.isGlobal) {
    if (
      await confirm({
        title: 'Edit global custom metric?',
        message: 'Are you sure you want to edit this global custom metric permanently?',
        modalProps,
      })
    ) {
      return firestoreRef()
        .collection('willy_global_custom_metrics')
        .doc(cm.id)
        .set(cm, { merge: true });
    }
  } else {
    return _db().collection('willy_custom_metrics').doc(cm.id).set(cm, { merge: true });
  }
};

export const createWillyCustomMetric = async (cm: WillyCustomMetric) => {
  if (!cm.id) {
    throw new Error('Metric ID is required');
  }
  cm.key = cm.id;
  if (cm.isGlobal) {
    if (
      await confirm({
        title: 'Create global custom metric?',
        message: 'Are you sure you want to create this global metric?',
        modalProps,
      })
    ) {
      return firestoreRef()
        .collection('willy_global_custom_metrics')
        .doc(cm.id)
        .set(cm, { merge: true });
    }
  } else {
    return _db().collection('willy_custom_metrics').doc(cm.id).set(cm, { merge: true });
  }
};

export const $willyShopMetricsRaw = $observer<WillyExpressionMetric[]>([], (get, set) => {
  if (!get($userId)) return;
  if (!get($currentShopId)) return;

  return _db()
    .collection('willy_metrics')
    .onSnapshot((snapshot) => {
      set(
        toArray(snapshot)
          .map((m) => ({ ...m, filter: removeUndefinedValuesRecursively(m.filter) }))
          .sort((a, b) => a.name.localeCompare(b.name)),
      );
    });
});

export const $willyShopCustomMetrics = $observer<WillyCustomMetric[]>([], (get, set) => {
  if (!get($userId)) return;
  if (!get($currentShopId)) return;

  return _db()
    .collection('willy_custom_metrics')
    .onSnapshot((snapshot) => {
      set(
        toArray(snapshot)
          .map((m) => ({ ...m, isCustomMetric: true }))
          .sort((a, b) => a.name.localeCompare(b.name)),
      );
    });
});

export const $willyShopMetrics = $derived((get) => {
  return get($willyShopMetricsRaw).filter(
    (s) => !get($globalWillyMetricsRaw).find((g) => g.id === s.id),
  );
});

export function overrideGlobalMetricsWithShopMetrics(
  shop: WillyExpressionMetric[],
  global: WillyExpressionMetric[],
) {
  if (!global.length) {
    return [];
  }
  if (!shop.length) {
    return global;
  }
  const globalCombineWithOverrides: WillyExpressionMetric[] = global.map((globalMetric) => {
    const shopOverride = shop.find((s) => s.id === globalMetric.id);
    if (shopOverride) {
      let shopFilter = shopOverride.filter ?? [];
      //Important!!:  assuming we will have in basic global metric only one filter (only one OR statement)
      // and here we are putting all the filters from the shop metric into the global metric
      let globalFilter = globalMetric.filter?.[0] ?? ({} as FilterGroup);

      if (globalMetric.filter?.length) {
        shopFilter = shopFilter.map((group) => {
          //for each OR statement in the shop metric, we add the global filter
          return { ...group, isOverride: true };
        });
      }
      return { ...globalMetric, filter: [globalFilter, ...shopFilter] } as WillyExpressionMetric;
    } else {
      return globalMetric;
    }
  });
  return globalCombineWithOverrides;
}

export const $globalWillyMetrics = $derived((get) => {
  return overrideGlobalMetricsWithShopMetrics(
    get($willyShopMetricsRaw),
    get($globalWillyMetricsRaw),
  );
});

export const $willyCustomMetrics = $derived((get) =>
  get($willyShopCustomMetrics).concat(get($globalWillyCustomMetrics)),
);

export const $willyMetrics = $derived((get) =>
  get($willyShopMetrics).concat(get($globalWillyMetrics)),
);

export const $metricBuilderModalOpened = $store<MetricModalState>({ isOpen: false });

export const $willyMetricsCombine = $derived((get) => [
  ...get($willyMetrics),
  ...get($willyCustomMetrics),
]);

export const $globalMetricsCombine = $derived((get) => [
  ...get($globalWillyMetrics),
  ...get($globalWillyCustomMetrics),
]);

export const saveMetric = async (metric: WillyMetric, isEdit: boolean, isFilterMode: boolean) => {
  if (!metricIsExpression(metric)) {
    return;
  }
  if (metricIsCustom(metric)) {
    try {
      if (isEdit) {
        await editWillyCustomMetric(metric);
      } else {
        await createWillyCustomMetric(metric);
      }
    } catch (e) {
      toast.error(`Error saving metric: ${e}`);
    }
  } else {
    try {
      if (isFilterMode) {
        if (metric.relatedProvider) {
          let metrics = findAllGlobalMetricsForProvider(metric.relatedProvider);
          metrics = metrics.map((met) => ({
            ...met,
            filter: removeUndefinedValuesRecursively(metric.filter?.filter((x) => x.isOverride)),
          }));
          await Promise.all(metrics.map((met) => editWillyMetric(met, true)));
        } else {
          await editWillyMetric(metric, true);
        }
      } else {
        if (isEdit) {
          await editWillyMetric(metric);
        } else {
          await createWillyMetric(metric);
        }
      }
    } catch (e) {
      toast.error(`Error saving metric: ${e}`);
    }
  }
};

export const deleteOverride = async (metric) => {
  if (!metricIsExpression(metric) || metricIsCustom(metric)) {
    return;
  }

  try {
    if (metric.relatedProvider) {
      const ids = findAllGlobalMetricsForProvider(metric.relatedProvider).map((x) => x.id);
      await Promise.all(ids.map((id) => deleteWillyMetric(id, false, false)));
      toast.success(`Filter successfully deleted form provider ${metric.relatedProvider}`);
    } else {
      await deleteWillyMetric(metric.id, false);
      toast.success(`Filter successfully deleted form metric ${metric.name}`);
    }
  } catch (e) {
    toast.error(`Error apply filter: ${e}`);
  }
};
