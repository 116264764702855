import { RingProgress } from '@tw/ui-components';
import { HTMLAttributes, useMemo } from 'react';
import ScrollToBottom from 'react-scroll-to-bottom';
import { WillyScrollToBottom } from './WillyScrollToBottom';
import { ChevronDownMinor, CodeMajor } from '@shopify/polaris-icons';
import { WillySimpleText } from './WillySimpleText';

type WillyCollapsibleCodeProps = {
  code: string;
  language?: string;
  progress?: number;
  step?: string;
  codeExpanded: boolean;
  setCodeExpanded: React.Dispatch<React.SetStateAction<boolean>>;
};

export const WillyCollapsibleCode: React.FC<WillyCollapsibleCodeProps> = ({
  code,
  progress,
  language = 'sql',
  step,
  codeExpanded,
  setCodeExpanded,
}) => {
  const markdownClasses = useMemo(() => {
    const classes: HTMLAttributes<HTMLDivElement>['className'][] = [
      'transition-[height] overflow-auto',
    ];

    if (!codeExpanded) {
      classes.push('h-0');
    } else {
      classes.push('h-90');
    }

    return classes.join(' ');
  }, [codeExpanded]);

  return (
    <div className="flex flex-col gap-4">
      <div
        className="items-center inline-flex gap-2 cursor-pointer"
        onClick={() => {
          setCodeExpanded((old) => !old);
        }}
      >
        <RingProgress
          sections={[
            {
              value: progress ?? 100,
              color: 'blue',
            },
          ]}
          size={30}
          thickness={1}
          label={
            <span className="flex justify-center">
              <CodeMajor className="w-6 h-6 fill-blue-600 flex items-center justify-center" />
            </span>
          }
        />
        {!!step && (
          <span className="ml-2 text-gray-700 dark:text-slate-400 text-[12px]">{step}</span>
        )}
        <ChevronDownMinor className={`w-6 h-6 ${codeExpanded ? 'transform rotate-180' : ''}`} />
      </div>
      <ScrollToBottom className={markdownClasses} initialScrollBehavior="smooth">
        <WillyScrollToBottom showArrow={true} arrowClassName="!bottom-2 w-8 h-8">
          <WillySimpleText text={code || ''} />
        </WillyScrollToBottom>
      </ScrollToBottom>
    </div>
  );
};
