import React from 'react';
import { Icon } from '@shopify/polaris';
import { CircleCancelMinor, DragHandleMinor } from '@shopify/polaris-icons';
import { ElementTypes, ExpressionElement } from 'components/Willy/types/willyTypes';
import { ChooseMetricDropdown } from './ChooseMetricDropdown';
import { WillyExpressionOrCustomMetric } from 'components/Willy/types/willyTypes';

export type ItemCallback = {
  updateIntegerItem: (id: any, newValue: number) => void;
  removeExpressionElement: (item: any) => void;
  updateMetricItem: (id: any, metric: WillyExpressionOrCustomMetric) => void;
  isGlobal?: boolean;
  onClick?: any;
};
export type MetricItemProps = ExpressionElement & ItemCallback;

export const MetricItem: React.FC<MetricItemProps> = (props: MetricItemProps) => {
  const { removeExpressionElement, updateMetricItem, updateIntegerItem } = props;
  const removeItem = (id) => {
    removeExpressionElement(id);
  };
  const setMetric = (id, metric: WillyExpressionOrCustomMetric) => {
    updateMetricItem(id, metric);
  };
  const handleIntegerValueChange = (event) => {
    if (event?.target?.value && Number(event.target.value))
      updateIntegerItem(props.id, Number(event.target.value));
  };

  return (
    <div
      onClick={props.onClick}
      className={`metric-item flex flex-row ml-[8px] mt-[8px]  sm:h-[34px] h-[48px] rounded-[4px] border border-solid ${
        props.isSelected ? 'bg-light-blue border-light-blue-border' : 'bg-white border-gray-200'
      }`}
    >
      <div className={`flex min-w-[78px] sm:min-w-[58px] flex-row `}>
        <div className="self-center flex-none w-[20px] ml-[3px] -mr-[5px]">
          <Icon source={DragHandleMinor} color="subdued" />
        </div>

        {props.type === ElementTypes.METRIC ? (
          <div
            className={`flex-grow flex flex-col justify-center items-center p-2 h-full text-2xl ${
              props.isSelected ? '' : 'opacity-50 '
            }`}
            style={{ pointerEvents: !props.isSelected ? 'none' : 'auto' }}
          >
            <ChooseMetricDropdown
              excludeShopMetrics={props.isGlobal}
              selected={{ value: props.value, label: props?.title }}
              setSelected={(metric) => setMetric(props.id, metric)}
            />
          </div>
        ) : props.type === ElementTypes.INTEGER ? (
          <div className={`flex-grow flex`}>
            {!props?.value && (
              <div
                className={`flex-grow flex flex-col justify-center items-center  h-full text-2xl  ${
                  props.isSelected ? 'pl-[8px]' : 'opacity-50  p-[13px]'
                }`}
              >
                <p>{props.title}</p>
              </div>
            )}
            {props.isSelected && (
              <input
                type="number"
                value={props?.value?.toString()}
                onChange={handleIntegerValueChange}
                placeholder="Enter Text"
                disabled={!props.isSelected}
                className={` ${
                  props?.value
                    ? props?.value.toString().length > 4
                      ? 'w-[80px]'
                      : 'w-[40px]'
                    : 'w-[80px]'
                }  text-center p-0 my-1 mx-2  border-0  focus:bg-opacity bg-transparent`}
              />
            )}
          </div>
        ) : (
          <div className="flex-grow flex flex-col justify-center items-center p-2 h-full text-2xl ">
            <p>{props.title}</p>
          </div>
        )}
      </div>
      {props.isSelected && (
        <div
          className=" flex  items-center justify-center cursor-pointer  sm:w-[32px] w-[48px]
                     h-full  border-0 border-solid border-l border-light-blue-border"
          onClick={() => removeItem(props.id)}
        >
          <div className=" sm:w-[16px] sm:h-[16px]  w-[20px] h-[20px] ">
            <Icon source={CircleCancelMinor} color="base" />
          </div>
        </div>
      )}
    </div>
  );
};
