import { useSelector } from 'react-redux';
import moment from 'moment';
import { FeatureFlag } from '@tw/feature-flag-system/module/types';
import { Icon } from '@tw/ui-components';
import { RootState, useAppSelector } from 'reducers/RootType';
import { NavSection } from '../types';
import { IconComponent } from '../components';

import SummaryHeader from 'components/headers/SummaryHeader';
import AttributionHeader from 'components/headers/AttributionHeader';
import AffluencerHubHeader from 'components/headers/AffluencerHubHeader';
import InsightsHeader from 'components/headers/InsightsHeader';
import ProductAnalyticsHeader from 'components/headers/ProductAnalyticsHeader';
import SummaryPage from 'routes/Summary';
import AttributionAllPage from 'components/attribution-new/AttributionAllPage';
import AttributionAdsPage from 'components/attribution-new/AttributionAdsPage';
import AttributionEmailSmsPage from 'components/attribution-new/AttributionEmailSmsPage';
import AttributionSocialPage from 'components/attribution-new/AttributionSocialPage';
import ProductAnalytics from 'pages/ProductAnalytics/ProductAnalytics';
import CreativeCockpitHeader from 'components/headers/CreativeCockpitHeader';
import CreativesCockpit from 'pages/CreativesCockpit/CreativesCockpit';
import InfluencersPage from 'pages/InfluencersInsights/InfluencersPage';
import CohortsPage from 'pages/Insights/CohortsPage';
import { $msp, $shop } from '$stores/$shop';

const SUMMARY_SECTION: NavSection = {
  title: 'Summary',
  icon: ({ mobile, selected }) => (
    <IconComponent
      mobile={mobile}
      selected={selected}
      icon={<Icon name="house" color={selected ? 'one.5' : 'gray.5'} size={16} />}
    />
  ),
  routes: [
    {
      url: '/summary',
      label: 'Summary',
      header: ({ chatOpen, setChatOpen, mainChatRef }) => (
        <SummaryHeader chatOpen={chatOpen} setChatOpen={setChatOpen} mainChatRef={mainChatRef} />
      ),
      isHybridPage: true,
      dependsOnFFSystem: FeatureFlag.SUMMARY_PAGE_FF,
      lastUpdate: () => {
        const InnerComponent = () => {
          const lastUpdate = useSelector((state: RootState) => state.summaryLastRefresh);
          return <> {moment(lastUpdate).local().format('LT')} </>;
        };
        return <InnerComponent />;
      },
      content: () => <SummaryPage />,
    },
  ],
};

const PIXEL_SECTION: NavSection = {
  title: 'Pixel',
  icon: ({ mobile, selected }) => (
    <IconComponent
      mobile={mobile}
      selected={selected}
      icon={<Icon name="pixel-cc" color={selected ? 'one.5' : 'gray.5'} size={16} />}
    />
  ),
  routes: [
    {
      url: '/attribution/all',
      dependsOnFFSystem: FeatureFlag.PIXEL_ALL_FF,
      label: 'All',
      isHybridPage: true,
      header: ({ chatOpen, setChatOpen, mainChatRef }) => (
        <AttributionHeader
          chatOpen={chatOpen}
          setChatOpen={setChatOpen}
          mainChatRef={mainChatRef}
        />
      ),
      lastUpdate: () => {
        const InnerComponent = () => {
          const lastUpdate = useSelector((state: RootState) => state.attribution.lastRefresh);
          return <> {moment(lastUpdate).local().format('LT')} </>;
        };
        return <InnerComponent />;
      },
      content: ({ setDashboardPixelData }) => (
        <AttributionAllPage setDashboardPixelData={setDashboardPixelData} />
      ),
    },
    {
      url: '/attribution/ads',
      dependsOnFFSystem: FeatureFlag.PIXEL_ALL_FF,
      isHybridPage: true,
      label: 'Ads',
      header: ({ chatOpen, setChatOpen, mainChatRef }) => (
        <AttributionHeader
          chatOpen={chatOpen}
          setChatOpen={setChatOpen}
          mainChatRef={mainChatRef}
        />
      ),
      lastUpdate: () => {
        const InnerComponent = () => {
          const lastUpdate = useSelector((state: RootState) => state.attribution.lastRefresh);
          return <> {moment(lastUpdate).local().format('LT')} </>;
        };
        return <InnerComponent />;
      },
      content: ({ setDashboardPixelData }) => (
        <AttributionAdsPage setDashboardPixelData={setDashboardPixelData} />
      ),
    },
    {
      url: '/attribution/email',
      dependsOnFFSystem: FeatureFlag.PIXEL_EMAIL_FF,
      isHybridPage: true,
      label: 'Email / SMS',
      header: ({ chatOpen, setChatOpen, mainChatRef }) => (
        <AttributionHeader
          chatOpen={chatOpen}
          setChatOpen={setChatOpen}
          mainChatRef={mainChatRef}
        />
      ),
      lastUpdate: () => {
        const InnerComponent = () => {
          const lastUpdate = useSelector((state: RootState) => state.attribution.lastRefresh);
          return <> {moment(lastUpdate).local().format('LT')} </>;
        };
        return <InnerComponent />;
      },
      content: ({ setDashboardPixelData }) => (
        <AttributionEmailSmsPage setDashboardPixelData={setDashboardPixelData} />
      ),
    },
    {
      url: '/attribution/social',
      dependsOnFFSystem: FeatureFlag.PIXEL_ORGANIC_FF,
      isHybridPage: true,
      label: 'Organic',
      header: ({ chatOpen, setChatOpen, mainChatRef }) => (
        <AttributionHeader
          chatOpen={chatOpen}
          setChatOpen={setChatOpen}
          mainChatRef={mainChatRef}
        />
      ),
      lastUpdate: () => {
        const InnerComponent = () => {
          const lastUpdate = useSelector((state: RootState) => state.attribution.lastRefresh);
          return <> {moment(lastUpdate).local().format('LT')} </>;
        };
        return <InnerComponent />;
      },
      content: ({ setDashboardPixelData }) => (
        <AttributionSocialPage setDashboardPixelData={setDashboardPixelData} />
      ),
    },
    {
      url: '/attribution/affluencer-hub',
      dependsOnFFSystem: FeatureFlag.PIXEL_AFFLUENCER_HUB_FF,
      isHybridPage: true,
      label: 'Affluencer Hub',
      header: () => <AffluencerHubHeader />,
      content: () => <InfluencersPage />,
    },
    {
      url: '/creative-cockpit',
      dependsOnFFSystem: FeatureFlag.CC_CREATIVE_ANALYSIS_FF,
      isHybridPage: true,
      label: 'Creative Cockpit',
      header: () => <CreativeCockpitHeader />,
      content: () => <CreativesCockpit />,
    },
  ],
};

const RETENTION_SECTION: NavSection = {
  title: 'Retention',
  icon: ({ mobile, selected }) => (
    <IconComponent
      mobile={mobile}
      selected={selected}
      icon={<Icon name="widget-editor" color={selected ? 'one.5' : 'gray.5'} size={16} />}
    />
  ),
  routes: [
    {
      url: '/insights/cohorts',
      dependsOnFFSystem: FeatureFlag.INSIGHT_GENERAL_FF,
      label: 'Cohorts',
      isHybridPage: true,
      header: () => <InsightsHeader />,
      content: () => <CohortsPage />,
    },
  ],
};

const PRODUCT_ANALYTICS_SECTION: NavSection = {
  title: 'Product Analytics',
  icon: ({ mobile, selected }) => (
    <IconComponent
      mobile={mobile}
      selected={selected}
      icon={<Icon name="circle-line-graph" color={selected ? 'one.5' : 'gray.5'} size={16} />}
    />
  ),
  routes: [
    {
      url: '/product-analytics',
      dependsOnFFSystem: FeatureFlag.PRODUCT_ANALYTICS_FF,
      label: 'Product Analytics',
      labs: true,
      header: () => <ProductAnalyticsHeader />,
      isHybridPage: true,
      content: () => <ProductAnalytics />,
    },
  ],
};

export const REPORTS_SUB_SECTION = () => {
  const shopMsp = $msp.get();
  const sections =
    shopMsp === 'bigcommerce'
      ? [SUMMARY_SECTION, PIXEL_SECTION]
      : [SUMMARY_SECTION, PIXEL_SECTION, RETENTION_SECTION, PRODUCT_ANALYTICS_SECTION];
  return sections.reduce((acc, x) => {
    const filteredRoutes = x.routes.filter((x) => !x.hidden && x.isHybridPage);
    if (filteredRoutes.length) acc.push({ ...x, routes: filteredRoutes });
    return acc;
  }, [] as NavSection[]);
};
