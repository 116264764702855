import { ALL_SOURCES_ID } from 'constants/types';
import { useLocation, useRouteMatch } from 'react-router';

export const useAttributionActiveSource = () => {
  const match = useRouteMatch<{ channelId: string }>([
    '/attribution/**/:channelId',
    '/dashboards/attribution/**/:channelId',
  ]);

  return match?.params.channelId || '';
};
