import {
  $globalWillyCustomMetrics,
  $globalWillyMetrics,
  $willyShopCustomMetrics,
  $willyShopMetrics,
} from '$stores/willy/$willyMetrics';
import {
  Flex,
  Icon,
  Modal,
  Text,
  TextInput,
  Checkbox,
  Button,
  IconName,
  Select,
  SelectProps,
  Group,
  Tooltip,
} from '@tw/ui-components';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { visibleLength } from '../utils/willyUtils';
import { WillyCustomMetric, WillyExpressionMetric } from '../types/willyTypes';
import { getMetricSqlMetadata } from './utils';
import { useHistory } from 'react-router';
import { useStoreValue } from '@tw/snipestate';

type MetricLibraryModalProps = {
  filterByTables?: string[];
  selectedMetrics: string[];
  setSelectedMetrics: (selectedMetrics: string[]) => void;
  onSave: () => void;
  opened: boolean;
  setOpened: (opened: boolean) => void;
  openCreateMetricModal: () => void;
};

type FilterOption = {
  value: 'all' | 'triple whale metrics' | 'user defined metrics';
  label: string;
};

const filterOptions: FilterOption[] = [
  { value: 'all', label: 'All' },
  { value: 'triple whale metrics', label: 'Triple Whale Metrics' },
  { value: 'user defined metrics', label: 'User Defined Metrics' },
];

const optionsIcons: Partial<Record<FilterOption['value'], IconName>> = {
  'triple whale metrics': 'triple-whale-logo',
  'user defined metrics': 'player',
};

export const MetricLibraryModal: React.FC<MetricLibraryModalProps> = ({
  filterByTables,
  selectedMetrics,
  setSelectedMetrics,
  onSave,
  opened,
  setOpened,
  openCreateMetricModal,
}) => {
  const [search, setSearch] = useState('');
  const globalMetrics = useStoreValue($globalWillyMetrics);
  const globalCustomMetrics = useStoreValue($globalWillyCustomMetrics);
  const shopMetrics = useStoreValue($willyShopMetrics);
  const shopCustomMetrics = useStoreValue($willyShopCustomMetrics);
  const [selectedFilterOption, setSelectedFilterOption] = useState<FilterOption['value']>('all');
  const [initialSelectedMetrics, setInitialSelectedMetrics] = useState<string[]>([]);
  const history = useHistory();

  const metricsToShow = useMemo(() => {
    const metricsToReturn: (WillyExpressionMetric | WillyCustomMetric)[] = [];
    switch (selectedFilterOption) {
      case 'all':
        metricsToReturn.push(
          ...[...globalMetrics, ...shopMetrics, ...globalCustomMetrics, ...shopCustomMetrics],
        );
        break;
      case 'triple whale metrics':
        metricsToReturn.push(...[...globalMetrics, ...shopMetrics]);
        break;
      case 'user defined metrics':
        metricsToReturn.push(...[...globalCustomMetrics, ...shopCustomMetrics]);
        break;
    }

    return metricsToReturn
      .map((metric) => ({
        ...metric,
        ...getMetricSqlMetadata(metric.key),
      }))
      .filter(
        (metric) =>
          !filterByTables?.length ||
          (metric.sqlRelatedTables?.length === 1 &&
            filterByTables.includes(metric.sqlRelatedTables[0])),
      );
  }, [
    selectedFilterOption,
    globalMetrics,
    shopMetrics,
    globalCustomMetrics,
    shopCustomMetrics,
    filterByTables,
  ]);

  const filteredMetrics = useMemo(
    () =>
      metricsToShow
        .filter((metric) =>
          search.trim().length > 0
            ? metric.name?.toLowerCase().trim().includes(search.toLowerCase().trim())
            : true,
        )
        .sort((a, b) => a.name.localeCompare(b.name)),
    [metricsToShow, search],
  );

  const renderSelectOption: SelectProps['renderOption'] = ({ option, checked }) => (
    <Group flex="1" gap="xs">
      {checked && <Icon name="check-thin" />}
      {optionsIcons[option.value] && <Icon name={optionsIcons[option.value]} />}
      {option.label}
    </Group>
  );

  const isMetricDisabled = useCallback(
    (metric: WillyExpressionMetric | WillyCustomMetric) =>
      metric.isBlocked || metric.isNotConnected,
    [],
  );

  const metricItem = useCallback(
    (metric: WillyExpressionMetric | WillyCustomMetric) => (
      <div key={`library-item-${metric.id}`}>
        <Checkbox
          disabled={isMetricDisabled(metric)}
          key={metric.id}
          label={
            <Flex align="center" gap="sm">
              {visibleLength(metric.icon) > 1 ? (
                <Icon name={metric.icon as IconName} />
              ) : (
                metric.icon
              )}
              <Text>{metric.name}</Text>
            </Flex>
          }
          checked={selectedMetrics?.includes(metric.id)}
          onChange={(checked) => {
            if (checked) {
              setSelectedMetrics([...selectedMetrics, metric.id]);
            } else {
              setSelectedMetrics(selectedMetrics.filter((m) => m !== metric.id) ?? []);
            }
          }}
        />
      </div>
    ),
    [isMetricDisabled, selectedMetrics, setSelectedMetrics],
  );

  useEffect(() => {
    setInitialSelectedMetrics(selectedMetrics);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Modal
      title={
        <Flex align="center" gap="sm">
          <Icon name="library" size={16} color="gray.5" />
          <Text weight={500} size="lg">
            Metric Library
          </Text>
        </Flex>
      }
      opened={opened}
      onClose={() => {
        setOpened(false);
      }}
    >
      <Flex direction="column" gap="md">
        <Flex align="center" gap="sm">
          <Flex onClick={openCreateMetricModal} ml="auto" cursor="pointer">
            <Text color="one.5" size="sm" weight={500}>
              Create Metric
            </Text>
          </Flex>
        </Flex>
        <Select
          allowDeselect={false}
          size={'sm'}
          value={selectedFilterOption}
          leftSection={
            optionsIcons[selectedFilterOption] && (
              <Icon name={optionsIcons[selectedFilterOption] ?? 'library'} />
            )
          }
          onChange={(val) => setSelectedFilterOption(val as FilterOption['value'])}
          data={filterOptions}
          renderOption={renderSelectOption}
          comboboxProps={{ withinPortal: false }}
        />
        <TextInput
          placeholder={'Search metrics'}
          value={search}
          onChange={setSearch}
          leadingIcon={<Icon name="search-major" size={12} />}
          maw={630}
        />
        <Flex direction="column" gap="sm" mah={200} overflow="auto" pb="md">
          {filteredMetrics.map((metric) =>
            isMetricDisabled(metric) ? (
              <Tooltip
                key={`library-${metric.id}`}
                label={
                  metric.isBlocked
                    ? 'Upgrade in order to add this metric to a board'
                    : 'Connect in order to add this metric to a board'
                }
              >
                {metricItem(metric)}
              </Tooltip>
            ) : (
              metricItem(metric)
            ),
          )}
        </Flex>
      </Flex>
      <Flex justify="flex-end" gap="sm" bg="gray.0" pt="md">
        <Button
          variant="activator"
          mr="auto"
          onClick={() => {
            history.push('/builder');
          }}
        >
          Go to Metric Library
        </Button>
        <Button
          variant="activator"
          onClick={() => {
            setSelectedMetrics(initialSelectedMetrics);
            setOpened(false);
          }}
        >
          Cancel
        </Button>
        <Button
          onClick={() => {
            onSave();
            setOpened(false);
          }}
        >
          Apply
        </Button>
      </Flex>
    </Modal>
  );
};
