import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { connect, useSelector } from 'react-redux';
import {
  TextField,
  Modal,
  FormLayout,
  DatePicker,
  Popover,
  Button,
  ActionList,
  Banner,
  Stack,
  TextStyle,
  Text,
} from '@shopify/polaris';
import {
  toggleNewMetricBasedCustomSpendIsOpen,
  newCustomSpendStartDateOnMonthChange,
  newCustomSpendEndDateOnMonthChange,
  customSpendSave,
  clearInitialData,
} from '../ducks/actions';
import moment from '@tw/moment-cached/module/timezone';
import { AdSpendCheckbox } from './AdSpendCheckbox';
import { CalendarMinor } from '@shopify/polaris-icons';
import { CustomSpendCategory } from './CustomSpendCategory';
import CURRENCIES from 'constants/currencies';
import { MetricsKeys } from 'types/metrics';
import { type RootState } from 'reducers/RootType';
import { useAppDispatch } from 'index';
import { ServicesIds } from '@tw/types/module/services';
import allServices from 'constants/services';
import { OptionDescriptor } from '@shopify/polaris/build/ts/latest/src/types';
import { CustomSpendSource } from './CustomSpendSource';
import { CustomSpendCampaign } from './CustomSpendCampaign';
import axiosInstance from 'utils/axiosInstance';

type Metric = {
  id: string;
  title: string;
  providerId: ServicesIds;
  metric: MetricsKeys;
};

export const variableExpenseMetric: Metric[] = [
  { id: 'sales', title: 'Total Revenue', providerId: 'shopify', metric: 'sales' },
  { id: 'blendedAds', title: 'Blended Ads', providerId: 'triple-whale', metric: 'spend' },
  { id: 'facebookAds', title: 'Ads (Facebook)', providerId: 'facebook-ads', metric: 'spend' },
  { id: 'googleAds', title: 'Ads (Google)', providerId: 'google-ads', metric: 'spend' },
  { id: 'snapchatAds', title: 'Ads (Snapchat)', providerId: 'snapchat-ads', metric: 'spend' },
  { id: 'tiktokAds', title: 'Ads (TikTok)', providerId: 'tiktok-ads', metric: 'spend' },
  { id: 'twitterAds', title: 'Ads (Twitter)', providerId: 'twitter-ads', metric: 'spend' },
  { id: 'pinterestAds', title: 'Ads (Pinterest)', providerId: 'pinterest-ads', metric: 'spend' },
  { id: 'bingAds', title: 'Ads (Microsoft)', providerId: 'bing', metric: 'spend' },
  { id: 'mountainAds', title: 'Ads (Mountain)', providerId: 'mountain', metric: 'spend' },
  { id: 'criteoAds', title: 'Ads (criteo)', providerId: 'criteo', metric: 'spend' },
  { id: 'cogs', title: 'COGS', providerId: 'shopify', metric: 'cogs' },
  { id: 'netSales', title: 'Net Sales', providerId: 'shopify', metric: 'netSales' },
  { id: 'totalRefunds', title: 'Total Refunds', providerId: 'shopify', metric: 'refunds' },
  { id: 'orders', title: 'Orders', providerId: 'shopify', metric: 'orders' },
];

const disabledProviders: ServicesIds[] = [
  'facebook-ads',
  'google-ads',
  'snapchat-ads',
  'tiktok-ads',
  'twitter-ads',
  'pinterest-ads',
  'bing',
  'mountain',
  'criteo',
  'shipstation',
  'gorgias',
  'smsbump',
  'shipbob',
  'postscript',
];

const NewCustomSpend = ({
  relevantSources,
  isOpen,
  startDateMonth,
  startDateYear,
  endDateMonth,
  endDateYear,
  onStartMonthChange,
  onEndMonthChange,
}) => {
  const [startDatePickerIsOpen, setStartDatePickerIsOpen] = useState(false);
  const [endDatePickerIsOpen, setEndDatePickerIsOpen] = useState(false);
  const [metricsListIsOpen, setMetricsListIsOpen] = useState(false);
  const [customSpendTitle, setCustomSpendTitle] = useState('');
  const [customSpendPercent, setCustomSpendPercent] = useState('');
  const [customSpendMetric, setCustomSpendMetric] = useState<Metric | undefined>(undefined);
  const [customSpendStartDate, setCustomSpendStartDate]: any = useState(undefined);
  const [customSpendEndDate, setCustomSpendEndDate]: any = useState(undefined);
  const [customSpendIsAdSpend, setCustomSpendIsAdSpend] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [customSpendId, setCustomSpendId]: any = useState(undefined);
  const [startDateLabel, setStartDateLabel] = useState('MM/DD/YYYY');
  const [endDateLabel, setEndDateLabel] = useState('MM/DD/YYYY');
  const [category, setCategory] = useState('');
  const [source, setSource] = useState<OptionDescriptor>({ label: '', value: '' });
  const [campaign, setCampaign] = useState<OptionDescriptor>({ label: '', value: '' });
  const [relevantCampaigns, setRelevantCampaigns] = useState<OptionDescriptor[]>([]);

  const dispatch = useAppDispatch();
  const reset = useCallback(() => {
    setCustomSpendTitle('');
    setCustomSpendMetric(undefined);
    setCustomSpendPercent('');
    setCustomSpendEndDate(undefined);
    setCustomSpendStartDate(undefined);
    setIsLoading(false);
    setIsEdit(false);
    setCustomSpendId(undefined);
    setCustomSpendIsAdSpend(false);
    setStartDateLabel('MM/DD/YYYY');
    setEndDateLabel('MM/DD/YYYY');
    setCategory('');
    setSource({ label: '', value: '' });
    setCampaign({ label: '', value: '' });
  }, []);

  const metricsOnPress = () => setMetricsListIsOpen(!metricsListIsOpen);
  const onSelectMetric = (m) => {
    setCustomSpendMetric(m);
    metricsOnPress();
  };
  const initialData = useSelector((state: RootState) => state.initialCustomSpendData);
  const shopTimezone = useSelector((state: RootState) => state.shopTimezone);
  const currency = useSelector((state: RootState) => state.currency);
  const currentShopId = useSelector((state: RootState) => state.currentShopId);
  const mainDatePickerSelectionRange = useSelector(
    (state: RootState) => state.mainDatePickerSelectionRange,
  );

  const customSpendPercentOnChange = (value) => {
    if (isNaN(value)) return;
    if (customSpendMetric?.id !== 'orders' && value > 100) return;
    setCustomSpendPercent(value);
  };

  const _onSubmit = useCallback(() => {
    setIsLoading(true);

    var obj = {
      title: customSpendTitle,
      metricId: customSpendMetric?.id,
      providerId: customSpendMetric?.providerId,
      metric: customSpendMetric?.metric,
      percent: Number(customSpendPercent),
      isAdSpend: customSpendIsAdSpend,
      start_date: moment(customSpendStartDate).tz(shopTimezone, true).startOf('day'),
      end_date: customSpendEndDate
        ? moment(customSpendEndDate).tz(shopTimezone, true).startOf('day')
        : null,
    };
    if (category) obj['category'] = category;
    if (isEdit) obj['id'] = customSpendId;
    if (source) obj['source'] = source.value;
    if (campaign) obj['campaignId'] = campaign.value;
    if (campaign) obj['campaignName'] = campaign.label;
    dispatch(customSpendSave({ obj }));
    dispatch(toggleNewMetricBasedCustomSpendIsOpen());

    dispatch(clearInitialData());
    reset();
  }, [
    category,
    customSpendEndDate,
    customSpendId,
    customSpendIsAdSpend,
    customSpendMetric,
    customSpendPercent,
    customSpendStartDate,
    customSpendTitle,
    dispatch,
    isEdit,
    reset,
    shopTimezone,
    source,
    campaign,
  ]);

  useEffect(() => {
    if (!initialData?.id) {
      reset();
      return;
    }
    const {
      title,
      metricId,
      percent,
      start_date,
      end_date,
      isAdSpend,
      id,
      category,
      source,
      campaignId,
      campaignName,
    } = initialData;
    setCustomSpendTitle(title);
    start_date && setCustomSpendStartDate(moment(start_date));
    end_date && setCustomSpendEndDate(moment(end_date));
    setCustomSpendPercent(String(percent));
    setCustomSpendMetric(variableExpenseMetric.find((x) => x.id === metricId));
    setIsLoading(false);
    setCustomSpendId(id);
    setIsEdit(true);
    setCustomSpendIsAdSpend(isAdSpend || false);
    start_date && setStartDateLabel(moment(start_date).format('MMM DD, YYYY'));
    end_date && setEndDateLabel(moment(end_date).format('MMM DD, YYYY'));
    category && setCategory(category);
    if (source) {
      const s = Object.values(allServices).find((service) => service.id === source);
      if (s) {
        setSource({ label: s.name, value: s.id });
      } else {
        setSource({ label: source, value: source });
      }
    }

    if (campaignId && campaignName) {
      setCampaign({ label: campaignName, value: campaignId });
    }
  }, [initialData, reset]);

  const startOnChange = (val) => {
    setCustomSpendStartDate(moment(val.start).clone().local());
    setStartDateLabel(moment(val.start).clone().local().format('MMM DD, YYYY'));
  };

  const endOnChange = (val) => {
    setCustomSpendEndDate(moment(val.start).clone().local());
    setEndDateLabel(moment(val.start).clone().local().format('MMM DD, YYYY'));
  };

  const saveEnabled =
    !!Number(customSpendPercent) &&
    Number(customSpendPercent) !== 0 &&
    customSpendTitle.trim().length &&
    customSpendMetric &&
    customSpendStartDate &&
    (customSpendEndDate
      ? moment(customSpendEndDate).isSameOrAfter(moment(customSpendStartDate), 'day')
      : true);

  useEffect(() => {
    if (!source.value) {
      setCampaign({ label: '', value: '' });
    }
  }, [source]);

  useEffect(() => {
    (async () => {
      if (!mainDatePickerSelectionRange || !source.value) return;
      const { data } = await axiosInstance.post('/v2/attribution/get-attributed-campaigns', {
        shopDomain: currentShopId,
        source: source.value,
        startDate: moment(mainDatePickerSelectionRange.start)
          .subtract(1, 'month')
          .startOf('day')
          .format('YYYY-MM-DD'),
        endDate: moment(mainDatePickerSelectionRange.end).endOf('day').format('YYYY-MM-DD'),
      });

      setRelevantCampaigns((data.campaigns || []).map((c) => ({ label: c, value: c })));
    })();
  }, [currentShopId, mainDatePickerSelectionRange, source]);

  return (
    <Modal
      title={isEdit ? 'Edit Variable Expense' : 'Add New Variable Expense'}
      open={isOpen}
      onClose={() => {
        reset();
        dispatch(toggleNewMetricBasedCustomSpendIsOpen());
        dispatch(clearInitialData());
      }}
      primaryAction={{
        content: 'Save',
        onAction: _onSubmit,
        disabled: !saveEnabled,
        loading: isLoading,
      }}
    >
      <Modal.Section>
        <FormLayout>
          {isEdit && (
            <Banner status="info">
              <p>
                Editing this variable expense will affect past data. If you don't want to affect
                past data, please create a new variable expense. You can end this expense by
                selecting today as the end date.
              </p>
            </Banner>
          )}
          <FormLayout.Group>
            <TextField
              label="Expense Name"
              labelHidden
              placeholder={'Name your custom variable expense'}
              onChange={setCustomSpendTitle}
              value={customSpendTitle}
              autoComplete="off"
            />
            <CustomSpendCategory val={category} onChange={setCategory} labelHidden={true} />
          </FormLayout.Group>
          <FormLayout.Group>
            <Popover
              active={metricsListIsOpen}
              onClose={metricsOnPress}
              activator={
                <Button onClick={metricsOnPress} outline disclosure fullWidth>
                  {customSpendMetric ? customSpendMetric.title : 'Set Metric'}
                </Button>
              }
            >
              <ActionList
                items={variableExpenseMetric.map((metric) => ({
                  content: metric.title,
                  onAction: () => {
                    onSelectMetric(metric);

                    const source = relevantSources.find((s) => s.value === metric.providerId);
                    if (source) {
                      setSource(source);
                    }
                  },
                }))}
              />
            </Popover>
            <TextField
              label="Expense Amount"
              placeholder={customSpendMetric?.id !== 'orders' ? 'Percent' : 'Expense per order'}
              labelHidden={true}
              type="number"
              onChange={customSpendPercentOnChange}
              value={customSpendPercent}
              prefix={customSpendMetric?.id !== 'orders' ? '%' : CURRENCIES[currency]}
              maxLength={9}
              autoComplete="off"
            />
            <CustomSpendSource
              source={source}
              disabled={
                !!customSpendMetric?.providerId &&
                disabledProviders.includes(customSpendMetric.providerId)
              }
              setSource={setSource}
              relevantSources={relevantSources}
            />
            <CustomSpendCampaign
              campaign={campaign}
              setCampaign={setCampaign}
              source={source}
              relevantCampaigns={relevantCampaigns}
              disabled
            />
            <Text as="p" variant="bodySm">
              * Campaign level attribution is not available for variable expenses yet. If you want
              to attribute this expense to a specific campaign, please create a fixed custom ad
              spend.
            </Text>
          </FormLayout.Group>

          <FormLayout.Group>
            <AdSpendCheckbox value={customSpendIsAdSpend} onChange={setCustomSpendIsAdSpend} />
            <div className="flex flex-col gap-4">
              <div className="flex justify-end items-center gap-4">
                <div>starting </div>
                <Popover
                  active={startDatePickerIsOpen}
                  activator={
                    <Button
                      outline
                      onClick={() => setStartDatePickerIsOpen(!startDatePickerIsOpen)}
                      disclosure
                      fullWidth
                      icon={CalendarMinor}
                    >
                      {startDateLabel}
                    </Button>
                  }
                  onClose={() => setStartDatePickerIsOpen(!startDatePickerIsOpen)}
                  sectioned
                  fullHeight
                >
                  <DatePicker
                    month={startDateMonth}
                    year={startDateYear}
                    onChange={startOnChange}
                    onMonthChange={onStartMonthChange}
                    selected={!!customSpendStartDate && customSpendStartDate.clone().toDate()}
                  />
                  <Stack distribution="trailing" alignment="center">
                    <Button
                      primary
                      onClick={() => {
                        setStartDatePickerIsOpen(false);
                      }}
                    >
                      Apply
                    </Button>
                  </Stack>
                  <div>
                    {' '}
                    <TextStyle variation="subdued">{shopTimezone}</TextStyle>
                  </div>
                </Popover>
              </div>
              <div className="flex justify-end gap-4 items-center">
                <div className="text-right">
                  <div>ending</div>
                  <div>(optional)</div>
                </div>
                <Popover
                  active={endDatePickerIsOpen}
                  activator={
                    <Button
                      outline
                      onClick={() => setEndDatePickerIsOpen(!endDatePickerIsOpen)}
                      disclosure
                      fullWidth
                      icon={CalendarMinor}
                    >
                      {endDateLabel}
                    </Button>
                  }
                  onClose={() => setEndDatePickerIsOpen(!endDatePickerIsOpen)}
                  sectioned
                  fullHeight
                >
                  <DatePicker
                    month={endDateMonth}
                    year={endDateYear}
                    onChange={endOnChange}
                    onMonthChange={onEndMonthChange}
                    selected={!!customSpendEndDate && customSpendEndDate.clone().toDate()}
                  />
                  <Stack distribution="trailing" alignment="center">
                    <Button
                      primary
                      onClick={() => {
                        setCustomSpendEndDate(null);
                        setEndDateLabel('MM/DD/YYYY');
                        setEndDatePickerIsOpen(false);
                      }}
                    >
                      Clear End Date
                    </Button>
                    <Button
                      primary
                      onClick={() => {
                        setEndDatePickerIsOpen(false);
                      }}
                    >
                      Apply
                    </Button>
                  </Stack>
                  <div>
                    {' '}
                    <TextStyle variation="subdued">{shopTimezone}</TextStyle>
                  </div>
                </Popover>
              </div>
            </div>
          </FormLayout.Group>
          {customSpendMetric?.id === 'blendedAds' && (
            <div>
              Blended Ads includes Facebook, Google, Snapchat, Tiktok and Pinterest Ad Spend, but
              does not include Custom Fixed or Variable Ad Spend{' '}
            </div>
          )}
        </FormLayout>
      </Modal.Section>
    </Modal>
  );
};

const mapStateToProps = ({
  newCustomSpendWindowIsOpen,
  newCustomSpendTitle,
  newCustomSpendCost,
  newCustomSpendPercent,
  newCustomSpendStartDateWindowIsOpen,
  newCustomSpendStartDateMonth,
  newCustomSpendStartDateYear,
  newCustomSpendStartDate,
  newCustomSpendEndDateWindowIsOpen,
  newCustomSpendEndDateMonth,
  newCustomSpendEndDateYear,
  newCustomSpendEndDate,
  currency,
  newCustomSpendRecurring,
  newMetricBasedCustomSpendWindowIsOpen,
  newCustomSpendMetric,
}) => {
  return {
    customSpendStartDate: newCustomSpendStartDate,
    customSpendEndDate: newCustomSpendEndDate,
    startDateMonth: newCustomSpendStartDateMonth,
    startDateYear: newCustomSpendStartDateYear,
    endDateMonth: newCustomSpendEndDateMonth,
    endDateYear: newCustomSpendEndDateYear,
    isOpen: newMetricBasedCustomSpendWindowIsOpen,
    customSpendCost: newCustomSpendCost,
    customSpendPercent: newCustomSpendPercent,
    customSpendTitle: newCustomSpendTitle,
    customSpendStartDateWindowIsOpen: newCustomSpendStartDateWindowIsOpen,
    customSpendEndDateWindowIsOpen: newCustomSpendEndDateWindowIsOpen,
    currency,
    customSpendRecurring: newCustomSpendRecurring,
    customSpendMetric: newCustomSpendMetric,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onStartMonthChange: (month, year) =>
      dispatch(newCustomSpendStartDateOnMonthChange(month, year)),
    onEndMonthChange: (month, year) => dispatch(newCustomSpendEndDateOnMonthChange(month, year)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(NewCustomSpend);
