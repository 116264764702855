import { ActionIcon, Anchor, Container, Flex, Text, Title, Tooltip } from '@tw/ui-components';
import { FC } from 'react';
import { PromptSubCategory } from './PromptLibraryPopup';
import { useStoreValue } from '@tw/snipestate';
import { $userId } from '$stores/$user';
import { removeFavoritePrompt } from '../utils/willyUtils';
type PromptLibraryInnerProps = {
  setOpened: (value: boolean) => void;
  promptsToShow: PromptSubCategory[];
  activeSubCategory: any;
  setActiveSubCategory: (value: string) => void;
  onSelectPrompt?: (value: string) => void;
  highlightPrompt: any;
};
export const PromptsLibraryInner: FC<PromptLibraryInnerProps> = ({
  setOpened,
  promptsToShow,
  activeSubCategory,
  setActiveSubCategory,
  onSelectPrompt,
  highlightPrompt,
}) => {
  const userId = useStoreValue($userId);

  return (
    <Flex direction="column" gap="xl" overflow="auto" h="100%" pb="xl">
      {promptsToShow.map((subCategory) => (
        <Flex direction="column" key={subCategory.subCategory}>
          <Flex align="center" justify="space-between" pl="sm" pb="md">
            <Title order={6} fw={600} color="gray.8">
              {subCategory.subCategory}
            </Title>
            {activeSubCategory === 'All Prompts' && (
              <Anchor
                color="one.5"
                fw={500}
                fz="sm"
                onClick={() => setActiveSubCategory(subCategory.subCategory)}
              >
                See all
              </Anchor>
            )}
          </Flex>
          {subCategory.prompts.map((prompt, index) => (
            <Container
              className="flex flex-col hover:bg-[--gray-light-mode-200] transition-all cursor-pointer group relative"
              pt="md"
              p={0}
              m={0}
              key={prompt.prompt}
              onClick={() => {
                const promptText = prompt.prompt.replaceAll('`', '');
                onSelectPrompt?.(promptText);
                setOpened(false);
              }}
            >
              <Text weight={500} size="md" color="gray.8" pl="sm">
                {prompt.title}
              </Text>
              <span className="pl-[12px] text-[--gray-light-mode-800] text-[14px] mb-[16px]">
                <span dangerouslySetInnerHTML={{ __html: highlightPrompt(prompt.prompt) }}></span>
              </span>
              {index !== subCategory.prompts.length - 1 && <Flex bg="gray.3" h="1px" w="100%" />}
              {!!prompt.id && !!prompt.userId && prompt.userId === userId && (
                <div className="absolute right-2 top-2">
                  <Tooltip label="Remove from favorites" position="right">
                    <ActionIcon
                      icon="star-plus"
                      color="one.5"
                      onClick={async (event) => {
                        event.stopPropagation();

                        if (!prompt.id || !prompt.userId || prompt.userId !== userId) {
                          return;
                        }
                        await removeFavoritePrompt(prompt.id);
                      }}
                    />
                  </Tooltip>
                </div>
              )}
            </Container>
          ))}
        </Flex>
      ))}
    </Flex>
  );
};
