import { $observer } from '@tw/snipestate';
import _db, { toArray } from 'utils/DB';
import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import 'firebase/compat/auth';
import firestore = firebase.firestore;
import { $userId } from './$user';

type GlobalConfig = {
  id:
    | 'metricsNexus'
    | 'shopifyNexus'
    | 'reportsNexus'
    | 'forceBQDialect'
    | 'adsOnClickhouse'
    | 'willyWay'
    | 'tw3';
  state?: string | boolean | number;
  planPreviewStartNotifyAfter: number;
  sqlTeamReportCredit?: number;
};

const ssrGlobalConfig: GlobalConfig[] = window.twGlobalConfig || [];

export const $globalConfig = $observer<GlobalConfig[]>(ssrGlobalConfig, (get, set) => {
  if (!get($userId)) return;

  return firestore()
    .collection('globalSettings')
    .onSnapshot(async (snapshot) => {
      const snippets = toArray(snapshot);
      set(snippets);
    });
});
