import DatePicker from 'components/SummaryDatePicker';
import { useAppDispatch } from 'index';
import { CHART_OPEN } from 'ducks/productAnalytics';
import { useSelector } from 'react-redux';
import { type RootState } from 'reducers/RootType';
import { DatePickerTimePeriods } from 'components/useDatePickerSelectedOptions';
import { useEffect } from 'react';
import moment from '@tw/moment-cached';
import { mainDatePickerOnSelectionChange, mainDatePickerSelectedOptionChange } from 'ducks/actions';
import { ActionIcon, Flex, Tooltip, Text, Icon } from '@tw/ui-components';
import { useStoreValue } from '@tw/snipestate';
import { $activeAppVersion } from '$stores/nav-config-stores';

export const ProductAnalyticsHeader = () => {
  const dispatch = useAppDispatch();
  const { chartOpen, isLoadingProducts } = useSelector(
    (state: RootState) => state.productAnalytics,
  );
  const activeAppVersion = useStoreValue($activeAppVersion);

  // set default date-range on page load
  useEffect(() => {
    const id = DatePickerTimePeriods.LAST_7_DAYS;
    const start = moment().subtract(7, 'days').startOf('day');
    const end = moment().subtract(1, 'day').endOf('day');
    dispatch(mainDatePickerSelectedOptionChange(id));
    const range = {
      id,
      start,
      end,
    };
    dispatch(mainDatePickerOnSelectionChange(range));
  }, [dispatch]);

  return (
    <Flex wrap="wrap" gap="md" align="center">
      {activeAppVersion === '3.0' && (
        <Flex align="center" pl="sm" gap="sm">
          <Icon name="circle-line-graph" color={'gray.5'} size={18} />
          <Text color="gray.7" weight={500} size="lg">
            Product Analytics
          </Text>
        </Flex>
      )}
      <div id="att-Product-Analytics-header-line-chart">
        <Tooltip label={chartOpen ? 'Hide Graph' : 'Show Graph'}>
          <div>
            <ActionIcon
              size={activeAppVersion === '3.0' ? 'md' : 'lg'}
              radius="sm"
              onClick={() => dispatch({ type: CHART_OPEN, payload: !chartOpen })}
              variant="activator"
              icon="line-chart"
              iconSize={activeAppVersion === '3.0' ? 14 : 20}
            />
          </div>
        </Tooltip>
      </div>
      <div id={`att-Product-Analytics-date-picker`}>
        <DatePicker
          buttonSize={activeAppVersion === '3.0' ? 'xs' : undefined}
          compareSeparately={activeAppVersion === '3.0'}
          showGroupByButton
          showCompareButton
          disabled={isLoadingProducts}
          unSuportedOptions={[DatePickerTimePeriods.TODAY]}
        />
      </div>
    </Flex>
  );
};

export default ProductAnalyticsHeader;
