import { Prettify, WithRequired } from '@tw/ui-components';
import { AugmentedDashboard, CategoryListItem } from './types';
import { v4 as uuidv4 } from 'uuid';
import { $currentShopId } from '$stores/$shop';
import _db from 'utils/DB';

export function categoryDoc() {
  const shopId = $currentShopId.get();
  if (!shopId) throw new Error('currentShopId not defined!');
  return _db(shopId).collection('willy_dashboard_categories').doc('categories');
}

/** Builder for category list item with or without id */
export function createCategoryListItem<
  T,
  R = T extends string ? CategoryListItem : Omit<CategoryListItem, 'id'>,
>(
  args: Prettify<
    { id?: T } & WithRequired<Partial<Omit<CategoryListItem, 'id'>>, 'value' | 'type'>
  >,
): R {
  return {
    value: args.value,
    depth: args.depth || 0,
    children: args.children || [],
    type: args.type,
    ...(args.id && { id: args.id }),
    ...(args.metadata && { metadata: createFormattedCategoryChildMetaData(args.metadata) }),
  } as R;
}

/**
 * Creates a formatted version of the category metadata
 * where undefined properties won't be on the document.
 *
 * This is very useful especially before saving a document in
 * firebase, which doesn't accept `undefined` as a field value.
 */
export function createFormattedCategoryChildMetaData(metadata: CategoryListItem['metadata']) {
  const newMetaData: CategoryListItem['metadata'] = { isOpen: false };

  for (const field in metadata) {
    const val = metadata[field];
    if (typeof val === 'undefined') continue;
    newMetaData[field] = val;
  }

  return newMetaData;
}

export type CreateCategoryItemArgs = {
  augmentedDash: AugmentedDashboard;
  type: CategoryListItem['type'];
  depth: number;
  metadata?: CategoryListItem['metadata'];
};
/** Helper/mapper function to convert dashboard into list item */
export function createCategoryItemFromDash({
  augmentedDash: { customViews = [], ...dashboard },
  type,
  depth,
  metadata,
}: CreateCategoryItemArgs): CategoryListItem {
  return {
    id: dashboard.id,
    type,
    value: dashboard.name || '',
    depth: depth,
    metadata: createFormattedCategoryChildMetaData({
      ...metadata,
      isOpen: false,
      isCustomView: !!dashboard.isCustomView,
      isVisible: !!dashboard.canView,
    }),
    children: customViews.map((cv, i) => ({
      id: cv.id,
      value: cv.name || '',
      children: [],
      type: 'item',
      depth: depth + 1,
      metadata: createFormattedCategoryChildMetaData({
        isOpen: false,
        isCustomView: !!cv.isCustomView,
        category: metadata?.category,
        isVisible: !!cv.canView,
      }),
    })),
  };
}

export function createDefaultChildItem(parentId: string) {
  return [
    {
      id: uuidv4(),
      type: 'item',
      value: 'No Reports Inside',
      metadata: { isOpen: false, category: parentId, isDefaultItem: true, isVisible: true },
      depth: 1,
      children: [],
    },
  ] satisfies CategoryListItem[];
}

/** Calculates if to show/create empty filler child for category  */
export function formatChildren(item: CategoryListItem): CategoryListItem[] | null {
  if (item.type === 'item') return null;

  const visibleItems = item.children.filter((c) => !!c.metadata?.isVisible);
  const nonDefaultItems = visibleItems.filter((c) => !c.metadata?.isDefaultItem);
  if (nonDefaultItems.length) return nonDefaultItems;
  if (visibleItems.length) return visibleItems;

  return createDefaultChildItem(item.id);
}

/** Merge separate lists of categories, so we have all of them in one map */
export function mergeCategoryShopAndGlobalCatsIntoMap(
  shopCats: CategoryListItem[],
  globalCats: CategoryListItem[],
) {
  shopCats = shopCats.filter((c) => c.type === 'folder');
  globalCats = globalCats.filter((c) => c.type === 'folder');

  const merged = new Map<string, CategoryListItem>();

  for (const category of shopCats) {
    merged.set(category.id, category);
  }

  for (const category of globalCats) {
    const existingCategory = merged.get(category.id);
    if (!existingCategory) {
      merged.set(category.id, category);
      continue;
    }

    for (const child of category.children) {
      existingCategory.children.push(child);
    }
  }

  return merged;
}

/** Put all dashboards into respective local and global categories */
export function organizeDashboardsIntoInitialCategories(
  dashMap: Map<string, AugmentedDashboard>,
  shopCats: CategoryListItem[],
  globalCats: CategoryListItem[],
) {
  const mergedCatsMap = mergeCategoryShopAndGlobalCatsIntoMap(shopCats, globalCats);
  const dashesWoCat: CategoryListItem[] = [];

  for (const dashboard of dashMap.values()) {
    if (!dashboard.category || !mergedCatsMap.has(dashboard.category)) {
      const newOrphan = createCategoryItemFromDash({
        augmentedDash: dashboard,
        type: 'item',
        depth: 0,
      });
      dashesWoCat.push(newOrphan);
      continue;
    }

    const category = mergedCatsMap.get(dashboard.category)!;
    const filteredChildren = category.children.filter((x) => !x.metadata?.isDefaultItem);
    const existingChild = filteredChildren.find((child) => child.id === dashboard.id);

    if (!existingChild) {
      const newChild = createCategoryItemFromDash({
        augmentedDash: dashboard,
        type: 'item',
        depth: 1,
      });
      filteredChildren.push(newChild);
    }

    category.children = filteredChildren;
  }

  return {
    dashboardsWithoutCategory: dashesWoCat,
    mergedCategories: [...mergedCatsMap.values()],
  };
}

/**
 * This function needs to do a few things:
 * 1. If a category has a child that doesn't exist in the dashboards map, this child
 *    has been deleted and must be removed from the list.
 * 2. Take dashboards that aren't in the categories list yet, and add them to the list -
 *    either as orphans or as children if they have an existing pre-specified category.
 * 3. The dashboard list has precendence over which dashboards exist.  The category list
 *    has precedence over which category a dashboard should be in.
 */
export function syncDashboardsAndCategories(
  dashMap: Map<string, AugmentedDashboard>,
  cats: CategoryListItem[],
) {
  // number 1
  const filterItems = (dashMap: Map<string, AugmentedDashboard>, items = cats) => {
    const newItems: CategoryListItem[] = [];

    for (const item of items) {
      // only ignore items that don't exist in dashMap
      if (item.type === 'item' && !dashMap.has(item.id)) continue;

      // everything else can get pushed - just need
      // to make sure folder children are checked too
      if (item.type === 'folder') {
        item.children = filterItems(dashMap, item.children);
        const formattedChildren = formatChildren(item);
        if (formattedChildren) item.children = formattedChildren;
      }

      newItems.push(item);
    }

    return newItems;
  };

  const filteredCats = filterItems(dashMap, cats);

  type CatWithTarget = Omit<CategoryListItem, 'children'> & { targetPath: number[] };

  // number 2
  const constructFlatMap = (
    items: CategoryListItem[],
    categoryId: string | null = null,
    map = new Map<string, CatWithTarget>(),
    parentPath = [] as number[],
  ) => {
    for (let i = 0; i < items.length; i++) {
      if (map.has(items[i].id)) {
        console.error('Circular dependency found in category list!!', items[i]);
        continue;
      }

      const { children, ..._item } = items[i];

      const item = { ..._item, targetPath: [...parentPath, i] };

      if (categoryId) {
        item.metadata = {
          ...item.metadata,
          isOpen: item.metadata?.isOpen || false,
          category: categoryId,
        };
      }

      map.set(item.id, item);
      constructFlatMap(children, item.id, map, [...item.targetPath]);
    }

    return map;
  };

  const augmentItems = (dashMap: Map<string, AugmentedDashboard>, items: CategoryListItem[]) => {
    const newItems: CategoryListItem[] = [];
    // this is a map of all current items - flattened for lookup speeed
    const flatMap = constructFlatMap(items);

    for (const [id, dash] of dashMap) {
      const existingItem = flatMap.get(id);

      // only augment with new info if id exists
      if (existingItem) {
        const { targetPath } = existingItem;
        const [first, ...rest] = targetPath;
        let item = items[first];
        let last = rest.pop()!;
        for (const p of rest) item = items[p];

        if (targetPath.length > 1) {
          item.children[last] = createCategoryItemFromDash({
            augmentedDash: dash,
            metadata: existingItem.metadata,
            depth: 1,
            type: 'item',
          });
        } else {
          item[first] = createCategoryItemFromDash({
            augmentedDash: dash,
            metadata: existingItem.metadata,
            depth: 0,
            type: 'item',
          });
        }
      }
      // new item that was put into a category
      else if (dash.category && flatMap.has(dash.category)) {
        const {
          targetPath: [index],
        } = flatMap.get(dash.category)!;

        items[index].children = [
          ...items[index].children,
          createCategoryItemFromDash({
            augmentedDash: dash,
            metadata: { isOpen: false, category: dash.category },
            depth: 1,
            type: 'item',
          }),
        ];

        const formattedChildren = formatChildren(items[index]);
        if (formattedChildren) items[index].children = formattedChildren;
      }
      // new orphan item
      else {
        const newItem = createCategoryItemFromDash({
          augmentedDash: dash,
          metadata: { category: dash.category, isOpen: false },
          depth: 0,
          type: 'item',
        });

        newItems.push(newItem);
      }
    }

    return [...newItems, ...items];
  };

  return augmentItems(dashMap, filteredCats);
}

/** Remove items that need to be filtered and sort according to saved index */
export function formatCategoriesList(categoriesList: CategoryListItem[]) {
  for (const item of categoriesList) {
    const formattedChildren = formatChildren(item);
    if (!formattedChildren) continue;
    item.children = formattedChildren;
  }

  return categoriesList;
}

export function createInitialCategoriesList(
  dashMap: Map<string, AugmentedDashboard>,
  sCats: CategoryListItem[] | null,
  gCats: CategoryListItem[] | null,
) {
  const res = organizeDashboardsIntoInitialCategories(dashMap, sCats || [], gCats || []);
  const orphans = res.dashboardsWithoutCategory;
  const categories = formatCategoriesList(res.mergedCategories);
  return [...orphans, ...categories];
}
