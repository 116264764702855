import { useActiveRoute } from '$stores/nav-config-stores';
import { Flex, Size, Text, Icon, Drawer, Box, Anchor, IconName } from '@tw/ui-components';
import { NavLink } from 'react-router-dom';
import { cx } from 'utils/cx';
import { $moreRoutes, $moreSection } from 'constants/routes/configs/more';
import { IconComponent } from 'constants/routes/components';
import { useIsSmall } from 'hooks/useDefaultWindowSizes';
import { $store, useStoreValue, useWritableStore } from '@tw/snipestate';
import { $navSectionCustomization } from './api/NavSectionCustomizer';
import { ReactNode, useMemo } from 'react';
import { TopSection } from '../../blocks/NewSearchStorePopover';
import { $customizeNavModal } from './CustomizeNavigationModal';
import { BASE_HEADER_HEIGHT } from 'components/TopBar/constants';
import { logoutOnPress } from 'ducks/auth';
import { useAppDispatch } from 'index';
import { $history } from '$stores/$history';
import { $lastSettingsRoute, $navOpen } from 'components/Nav/stores';
import { ToggleTW3Section } from '../../common/ToggleTW3Section';
import { toggleLauncherVisibility } from 'utils/intercom';
import { GetHelp } from '../../blocks/GetHelp';

const $mobileDrawerOpen = $store(false);

type BottomLinkProps = {
  text: string;
  icon: ReactNode | IconName;
  showArrow?: boolean;
  onClick?: () => void;
};
function BottomLink({ text, icon, showArrow = true, onClick }: BottomLinkProps) {
  return (
    <Flex
      justify="space-between"
      align="center"
      my={5 as unknown as Size}
      onClick={() => {
        $mobileDrawerOpen.set(false);
        onClick?.();
      }}
    >
      <Flex gap="xs" align="center">
        {typeof icon === 'string' ? (
          <Icon name={icon as IconName} size={14} color="gray.5" />
        ) : (
          icon
        )}
        <Text span c="gray.8" fz="sm" lh="21px" fw={500}>
          {text}
        </Text>
      </Flex>
      {showArrow && <Icon name="chevron-right-minor" color="gray.4" size={12} />}
    </Flex>
  );
}

let showIntercomForDrawer = false;
const BOTTOM_LINKS: BottomLinkProps[] = [
  {
    icon: 'user-profile-circle',
    text: 'Your Profile',
    onClick: () => $history.get().push?.('/user-profile'),
  },
  {
    icon: 'settings',
    text: 'Store Settings',
    onClick: () => {
      $history.get().push?.($lastSettingsRoute.get());
      $navOpen.set(true);
    },
  },
  {
    icon: <GetHelp iconSize={14} iconColor="gray.5" />,
    text: 'Help & Feedback',
    onClick: () => {
      () => {
        showIntercomForDrawer = !showIntercomForDrawer;
        toggleLauncherVisibility(showIntercomForDrawer);
      };
    },
  },
];

export function MoreSectionDrawer() {
  const dispatch = useAppDispatch();
  const { activeRoute } = useActiveRoute();
  const moreSection = useStoreValue($moreSection);
  const moreRoutes = useStoreValue($moreRoutes);
  const navSectionCustomization = useStoreValue($navSectionCustomization);
  const mobile = useIsSmall();
  const [opened, setDrawerOpened] = useWritableStore($mobileDrawerOpen);
  const isRouteInSideNav = !!(activeRoute?.url && navSectionCustomization[activeRoute.url]?.open);
  const selected = useMemo(
    () => opened || !!(!isRouteInSideNav && activeRoute?.url && moreRoutes.has(activeRoute?.url)),
    [opened, activeRoute?.url, isRouteInSideNav, moreRoutes],
  );

  return (
    <>
      <Drawer
        size="xs"
        opened={opened}
        shadow="0px 4px 6px -2px rgba(0, 0, 0, 0.05), 0px 10px 15px -3px rgba(0, 0, 0, 0.1)"
        closeOnClickOutside
        onClose={() => setDrawerOpened(false)}
        title={
          <Flex justify="space-between" align="center">
            <TopSection />
            <div className="ml-[50px]">
              <ToggleTW3Section switchSize="xs" hideLabel />
            </div>
          </Flex>
        }
      >
        <Flex mih="1px" maw="100%" bg="gray.2" />
        <Flex
          direction="column"
          justify="space-between"
          mih={`calc(95vh - ${BASE_HEADER_HEIGHT}px)`}
        >
          <div>
            <Flex direction="column" pt="sm" gap="sm">
              {moreSection.map((s, i) => {
                return (
                  <div key={(s.title || '') + i} className="cursor-pointer">
                    <Text p={5 as unknown as Size} tt="uppercase" fz={13} c="gray.5">
                      {s.title}
                    </Text>
                    {s.routes.map((r) => {
                      return (
                        <NavLink
                          key={r.url}
                          onClick={() => setDrawerOpened(false)}
                          to={{ pathname: r.url }}
                          className={cx(
                            'flex p-[5px] gap-4 items-center',
                            'hover:bg-[var(--gray-light-mode-300)] rounded-md no-underline',
                            activeRoute?.url === r.url && 'bg-[var(--gray-light-mode-300)]',
                          )}
                        >
                          {r.rawIconName && (
                            <div
                              className={
                                'flex justify-center items-center rounded-md' +
                                ' bg-[var(--gray-light-mode-200)] shadow-sm w-[24px] h-[24px]'
                              }
                            >
                              <Icon name={r.rawIconName} size={18} />
                            </div>
                          )}
                          <Text fz="sm" c="gray.8">
                            {r.label}
                          </Text>
                        </NavLink>
                      );
                    })}
                  </div>
                );
              })}
            </Flex>
            <Box mt="sm" ml="xs">
              <Anchor
                textProps={{ fz: 'xs', c: 'one.5' }}
                as="button"
                onClick={() => {
                  setDrawerOpened(false);
                  $customizeNavModal.set((x) => ({ ...x, opened: true }));
                }}
              >
                Customize navigation bar
              </Anchor>
            </Box>
          </div>
          <Flex mih="1px" maw="100%" bg="gray.2" />
          <Flex direction="column" justify="flex-end">
            {BOTTOM_LINKS.map((l, i) => (
              <BottomLink {...l} key={'bottom-link' + i} />
            ))}
          </Flex>
          <Flex mih="1px" maw="100%" bg="gray.2" />
          <BottomLink
            text="Log Out"
            icon="log-out"
            showArrow={false}
            onClick={() => dispatch(logoutOnPress())}
          />
        </Flex>
      </Drawer>
      <div
        className={cx(!mobile && 'm-auto', 'flex items-center flex-col cursor-pointer mt-2')}
        onClick={() => setDrawerOpened((x) => !x)}
      >
        <IconComponent
          selected={selected}
          mobile={mobile}
          icon={<Icon name="more" color={selected ? 'one.5' : 'gray.5'} size={mobile ? 18 : 20} />}
        />
        <Text fz={12} weight={500} ta="center">
          More
        </Text>
      </div>
    </>
  );
}
