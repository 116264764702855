import React, { ReactElement, useEffect, useRef } from 'react';
import { Droppable, Draggable, DraggableProvided, DraggingStyle } from 'react-beautiful-dnd';
import { ItemCallback, MetricItem } from './MetricItem';
import { createPortal } from 'react-dom';

type ExpressionElementBagProps = {
  listId: string;
  items: any[];
};
export const useDraggableInPortal = () => {
  const element = useRef<HTMLDivElement>(document.createElement('div')).current;

  useEffect(() => {
    if (element) {
      element.style.pointerEvents = 'none';
      element.style.position = 'absolute';
      element.style.height = '100%';
      element.style.width = '100%';
      element.style.top = '0';

      document.body.appendChild(element);

      return () => {
        document.body.removeChild(element);
      };
    }
  }, [element]);

  return (render: (provided: DraggableProvided) => ReactElement) =>
    (provided: DraggableProvided) => {
      const result = render(provided);
      const style = provided.draggableProps.style as DraggingStyle;
      if (style.position === 'fixed') {
        return createPortal(result, element);
      }
      return result;
    };
};

export const ExpressionElementBag: React.FC<ExpressionElementBagProps & ItemCallback> = (props) => {
  const renderDraggable = useDraggableInPortal();
  return (
    <Droppable droppableId={props.listId} direction="horizontal" isCombineEnabled={false}>
      {(provided) => (
        <div>
          <div
            ref={provided.innerRef}
            {...provided.droppableProps}
            className="flex flex-wrap min-h-[30px] bg-white  border-[2px]  border-gray-200 pb-[8px]"
          >
            {props.items.length ? (
              props.items.map((item, index) => (
                <Draggable key={item.id} draggableId={item.id} index={index}>
                  {renderDraggable((provided) => (
                    <div
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                    >
                      <MetricItem
                        updateMetricItem={props.updateMetricItem}
                        isGlobal={props.isGlobal}
                        removeExpressionElement={props.removeExpressionElement}
                        updateIntegerItem={props.updateIntegerItem}
                        isSelected={true}
                        {...item}
                      ></MetricItem>
                    </div>
                  ))}
                </Draggable>
              ))
            ) : (
              <div className="flex-grow flex flex-col justify-center min-h-[56px] items-center text-center text-gray-400 pt-[8px] ">
                Click or drop elements and operators here.
              </div>
            )}
          </div>
          {provided.placeholder}
        </div>
      )}
    </Droppable>
  );
};
