import { ChatSources, Message, WillyWidgetElement } from 'components/Willy/types/willyTypes';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Button } from '@tw/ui-components';
import { WillyMainChat, WillyMainChatRef } from 'components/Willy/WillyMainChat';
import { CodeAction } from 'components/Willy/WillySimpleText';
import { PreloadDashboardData } from 'components/Willy/types/willyTypes';
import { CancelMajor, IncomingMajor } from '@shopify/polaris-icons';
import { SummaryData, PixelData } from 'components/Willy/types/willyTypes';

type ChatWithQueryProps = {
  query?: string;
  chatFocus: boolean;
  chatMinimized?: boolean;
  chatOpen: boolean;
  messages: Message[];
  returnQueryOnly: boolean;
  codeActions?: CodeAction[];
  source: ChatSources;
  sourceIds?: { dashboardId: string; widgetId: string; onAdd?: () => void };
  setMessages: React.Dispatch<React.SetStateAction<Message[]>>;
  chatClosed: () => void;
  setChatMinimized?: React.Dispatch<React.SetStateAction<boolean>>;
  widgets?: WillyWidgetElement[];
  preloadData?: PreloadDashboardData;
  summaryData?: SummaryData;
  pixelData?: PixelData;
  mainChatRef?: React.RefObject<WillyMainChatRef>;
  conversationId: string;
  setConversationId: React.Dispatch<React.SetStateAction<string>>;
};

export const ChatWithQuery: React.FC<ChatWithQueryProps> = ({
  source,
  query,
  chatOpen,
  chatFocus,
  chatMinimized = false,
  messages,
  returnQueryOnly,
  codeActions = [],
  setChatMinimized,
  setMessages,
  chatClosed,
  widgets,
  preloadData,
  summaryData,
  pixelData,
  mainChatRef,
  conversationId,
  setConversationId,
  sourceIds,
}) => {
  const localMainChatRef = useRef<WillyMainChatRef>(null);

  const isSummary = useMemo(() => {
    return source === 'summary';
  }, [source]);

  const isPixel = useMemo(() => {
    return source === 'pixel';
  }, [source]);

  const isDashboard = useMemo(() => {
    return source === 'dashboard';
  }, [source]);

  const isSql = useMemo(() => {
    return source === 'editor';
  }, [source]);

  const loadingWidgetsData = useMemo(() => {
    return isPixel
      ? pixelData && pixelData?.filter((d) => d.active).length <= 0
      : isSummary
        ? summaryData?.activeMetrics &&
          summaryData?.activeMetrics?.length <= 0 &&
          Object.keys(summaryData?.calculatedStats).length <= 0 &&
          Object.keys(summaryData?.previousPeriodCalculatedStats).length <= 0
        : !widgets || !preloadData || !Object.keys(preloadData).every((d) => preloadData[d].data);
  }, [
    isPixel,
    isSummary,
    pixelData,
    preloadData,
    summaryData?.activeMetrics,
    summaryData?.calculatedStats,
    summaryData?.previousPeriodCalculatedStats,
    widgets,
  ]);

  const classes = useMemo(() => {
    const classes = [
      'sticky z-[999] !pb-4 sm:p-0 bottom-0 @4xl:w-10/12 transition-transform mx-auto rounded-t-lg overflow-hidden',
    ];

    if (chatMinimized) {
      classes.push('sm:w-1/3 ml-auto mr-8');
    }

    if (chatOpen) {
      classes.push('translate-y-0 opacity-100');
    } else {
      classes.push('translate-y-full opacity-0');
    }

    classes.push(
      'bg-white shadow-[0px_0px_20px_0px_rgba(0,0,0,0.06),0px_0px_20px_0px_rgba(0,0,0,0.60)]',
    );

    return classes.join(' ');
  }, [chatMinimized, chatOpen]);

  useEffect(() => {
    function keyupHandler(e: KeyboardEvent) {
      if (e.key === 'Escape' && !chatMinimized) {
        chatClosed();
      }
    }
    window.addEventListener('keyup', keyupHandler);
    // cleanup/unmount
    return () => window.removeEventListener('keyup', keyupHandler);
  }, [chatClosed, chatMinimized]);

  const readyToChat = useMemo(() => {
    return (
      widgets &&
      widgets.length > 0 &&
      preloadData &&
      chatOpen &&
      !loadingWidgetsData &&
      Object.keys(preloadData).length > 0 &&
      widgets.every((w) => preloadData[w.queryId]?.data)
    );
  }, [chatOpen, loadingWidgetsData, preloadData, widgets]);

  return (
    <>
      {chatOpen && !chatMinimized && (
        <div
          onClick={() => {
            if (!chatFocus) {
              chatClosed();
            }
          }}
          className={`absolute left-0 top-0 bottom-0 w-full h-full ${
            chatFocus
              ? 'bg-gradient-to-b from-[rgba(18,22,28,0.20)_97.63%] to-[rgba(18,22,28,0.20)_97.63%]'
              : 'bg-gradient-to-b from-[rgba(18,22,28,0.00)_0%] to-[rgba(18,22,28,0.20)_97.63%]'
          }`}
        ></div>
      )}
      <div className={classes}>
        <div
          className={`w-full flex items-center ${
            chatMinimized ? 'justify-between' : 'justify-start'
          } gap-4 border-b border-gray-300 p-4 border-0 border-solid bg-white`}
        >
          <IncomingMajor
            className={`w-8 h-8 bg-white border border-solid border-[#D1D4DB] fill-[#6B7280] shadow-[0px_1px_2px_0px_rgba(0,0,0,0.06),0px_1px_3px_0px_rgba(0,0,0,0.10)] rounded-full cursor-pointer flex-shrink-0 ${
              chatMinimized ? 'rotate-180' : ''
            }`}
            onClick={() => {
              setChatMinimized?.((x) => !x);
            }}
          />
          <div
            className={`whitespace-nowrap font-semibold overflow-hidden text-ellipsis ${
              !chatMinimized ? 'flex-grow' : ''
            }`}
          >
            {isPixel
              ? 'Chat With Moby | Pixel'
              : isSummary
                ? 'Chat With Moby | Summary'
                : `Build With Moby | ${
                    source === 'editor'
                      ? 'Chat with SQL'
                      : source === 'dashboard'
                        ? `Chat With Data`
                        : 'Untitled Section'
                  }`}
          </div>
          {chatMinimized ? (
            <CancelMajor
              className={`w-8 h-8 bg-white border border-solid border-[#D1D4DB] fill-[#6B7280] shadow-[0px_1px_2px_0px_rgba(0,0,0,0.06),0px_1px_3px_0px_rgba(0,0,0,0.10)] rounded-full cursor-pointer flex-shrink-0`}
              onClick={() => {
                chatClosed();
              }}
            />
          ) : (
            <Button
              variant="activator"
              onClick={() => {
                chatClosed();
              }}
            >
              Close
            </Button>
          )}
        </div>
        <WillyMainChat
          ref={mainChatRef ?? localMainChatRef}
          messagesWrapperClassName={`max-h-[60vh] ${!isSql && 'sm:max-h-[60vh]'} overflow-auto`}
          conversationId={conversationId}
          messages={messages}
          setMessages={setMessages}
          setConversationId={setConversationId}
          initialQuery={query}
          isReadyForChat={
            isSummary || isPixel ? !loadingWidgetsData : isDashboard ? readyToChat : true
          }
          returnQueryOnly={returnQueryOnly}
          source={source}
          codeActions={codeActions}
          showLogo
          hidePills
          isDashboardPage={!chatMinimized}
          dashboardData={preloadData}
          summaryData={summaryData}
          pixelData={pixelData}
          hideSuggestions={isSummary || chatMinimized}
          chatSourceIds={sourceIds}
        />
      </div>
    </>
  );
};
