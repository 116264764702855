import { WillyExpressionMetric } from '../types/willyTypes';
import { Flex, Select } from '@tw/ui-components';
import { $tables } from '../../../$stores/willy/$tables';
import { services, ServicesIds } from '@tw/types/module/services';
import { useMemo } from 'react';
import { FilterBuilder } from '../../../pages/FreeQuery/FilterBuilder/FilterBuilder';
import { SQLType } from '@tw/willy-data-dictionary/module/columns/types';
import { useStoreValue } from '@tw/snipestate';

export type MetricBuilderType = {
  metric: WillyExpressionMetric;
  isFilter?: boolean;
  isFilterMode?: boolean;
  setIsFilterValid: (valid: boolean) => void;
  setMetric: React.Dispatch<React.SetStateAction<WillyExpressionMetric>>;
};

const relevantColType: Array<SQLType> = [
  'string',
  'numeric',
  'record repeated',
  'repeated string',
  'boolean',
];

export const WillyMetricBuilder: React.FC<MetricBuilderType> = ({
  metric,
  isFilter = false,
  isFilterMode = false,
  setIsFilterValid,
  setMetric,
}) => {
  const tables = useStoreValue($tables);

  const columnsToFilter = useMemo(() => {
    return tables
      .find((x) => x.id === (metric as WillyExpressionMetric).tableId)
      ?.columns?.filter((x) => relevantColType.includes(x.type) && x.id !== 'event_hour')!;
  }, [metric, tables]);

  return (
    <div>
      <Flex gap={'xs'}>
        <Select
          allowDeselect={false}
          disabled={isFilter}
          size={'sm'}
          label={'Table'}
          placeholder="Choose a Table"
          searchable={true}
          value={(metric as WillyExpressionMetric)?.tableId}
          onChange={(val) =>
            setMetric((old) => {
              return {
                ...(old as WillyExpressionMetric),
                tableId: val!,
                columnId: '',
                filter: [],
              };
            })
          }
          data={tables.map((x) => ({ label: x.name, value: x.id }))}
        />
        <Select
          allowDeselect={false}
          size={'sm'}
          label={'Metric'}
          placeholder="Choose a Metric"
          searchable={true}
          disabled={isFilter}
          value={(metric as WillyExpressionMetric).columnId}
          onChange={(val) => {
            setMetric((old) => {
              const currentColumn = tables
                .find((x) => x.id === (metric as WillyExpressionMetric).tableId)
                ?.columns?.find((x) => x.id === val);

              const isFormulaColumn = currentColumn?.type === 'formula';

              let more = {};

              if (isFormulaColumn) {
                more = {
                  formula: currentColumn?.name,
                };
              }

              return {
                ...(old as WillyExpressionMetric),
                columnId: val!,
                ...more,
              };
            });
          }}
          data={
            tables
              .find((x) => x.id === (metric as WillyExpressionMetric).tableId)
              ?.columns?.filter((x) => relevantColType.includes(x.type))
              .map((c) => ({ label: c.title, value: c.id })) ?? []
          }
        />
        <Select
          allowDeselect={false}
          size={'sm'}
          disabled={isFilter}
          searchable={true}
          label={'Integration (optional)'}
          placeholder="Choose an Integration"
          value={(metric as WillyExpressionMetric).relatedProvider}
          onChange={(val) =>
            setMetric((old) => ({
              ...(old as WillyExpressionMetric),
              relatedProvider: val as ServicesIds,
            }))
          }
          data={
            Object.values(services)
              .filter((x) => x.providerType === 'data' || x.id == 'triple-whale')
              .map((c) => ({ label: c.name, value: c.id })) ?? []
          }
        />
      </Flex>

      <div className={'mt-10'}>
        <FilterBuilder
          filter={(metric as WillyExpressionMetric).filter?.filter((x) =>
            isFilterMode ? x.isOverride : !x.isOverride,
          )}
          table={tables.find((x) => x.id === (metric as WillyExpressionMetric).tableId)!}
          columns={columnsToFilter}
          onFilterChanged={(filter) =>
            setMetric((old) => ({
              ...(old as WillyExpressionMetric),
              filter: isFilterMode
                ? [
                    ...(old.filter?.filter((x) => !x.isOverride) ?? []),
                    ...filter.map((x) => ({
                      ...x,
                      isOverride: true,
                    })),
                  ]
                : filter,
            }))
          }
          onFilterValidChanged={setIsFilterValid}
          relatedProvider={metric.relatedProvider}
        />
      </div>
    </div>
  );
};
