import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Select } from '@shopify/polaris';
import type { RootState } from 'reducers/RootType';
import type { FC } from 'react';

interface IGoogleSheetsAccountSelectorProps {
  value: string;
  onChange: (value: string) => void;
  label?: React.ReactNode;
}

export default function GoogleSheetsAccountSelector({
  value,
  onChange,
  label,
}: IGoogleSheetsAccountSelectorProps) {
  const googleSheetsAccounts = useSelector((state: RootState) => state.googleSheetsAccounts);

  useEffect(() => {
    if (!value && googleSheetsAccounts?.[0]?.id) {
      onChange(googleSheetsAccounts[0].id);
    }
  });

  return (
    <section className="my-[1.5rem]">
      <Select
        label={label || 'Google Sheets Account'}
        options={googleSheetsAccounts.map((a) => ({
          label: `${a.name} ${a.email ? `(${a.email})` : ''}`,
          value: a.id,
        }))}
        onChange={onChange}
        value={value}
      />
    </section>
  );
}
