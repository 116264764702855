import { ProviderActionResult } from '@tw/types/module/willy/langchain/langchain';
import { WillyActionProviderConnect } from './WillyActionProviderConnect';
import { WillyActionProviderShow } from './WillyActionProviderShow';

export const WillyActionProvider: React.FC<{ data: ProviderActionResult }> = ({ data }) => {
  const { providerType, data: providerData } = data;

  return (
    <>
      {(providerType === 'connect' || providerType === 'disconnect') && (
        <WillyActionProviderConnect data={providerData} />
      )}
      {providerType === 'show-all' && <WillyActionProviderShow data={providerData} />}
    </>
  );
};
