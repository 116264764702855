import BubbleToggleButton from 'components/library/BubbleToggleButton/BubbleToggleButton';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useLocation, useParams } from 'react-router';
import { type RootState } from 'reducers/RootType';
import axiosInstance from 'utils/axiosInstance';

import {
  Breadcrumbs,
  Button,
  Collapsible,
  Modal,
  Spinner,
  TextField,
  Tooltip,
} from '@shopify/polaris';
import { ChevronDownMinor, MagicMajor } from '@shopify/polaris-icons';
import { ServicesIds } from '@tw/types/module/services';

import AdMediaCollection from '../ads/AdMediaCollection';
import CardThumbnail from '../assets/CardThumbnail';
import { ContentHubContext } from '../ContentHub';
import { emptyProductMediaItem, ProductItemType } from './productMedia';
import { useAppDispatch } from 'index';
import { GENERATE_COPY_AI_POPUP_OPENED } from 'ducks/constants';
import { GenerativeCopyPopupData, MediaType } from 'types/general';
import { confirmationModal, confirmationModalClose } from 'ducks/confirmationModal';
import { toast } from 'react-toastify';
import { ReactComponent as Link } from '../../../icons/link.svg';
import copyToClipboard from 'utils/copyToClipboard';
import { MediaItemType } from '@tw/types';

type Source = { name: string; id: any };

const sources: Source[] = [
  { name: 'Shopify', id: 'shopify' },
  { name: 'Facebook', id: 'facebook-ads' },
  { name: 'Google', id: 'google-ads' },
  { name: 'TikTok', id: 'tiktok-ads' },
  { name: 'Uploaded Content', id: 'triplewhale' },
];

type SingleProductMediaProps = {
  closeOpenProduct: () => void;
};

const SingleProduct: React.FC<SingleProductMediaProps> = ({ closeOpenProduct }) => {
  const dispatch = useAppDispatch();

  const history = useHistory();
  const location = useLocation();
  const params = useParams<{ customId: string }>();
  const { reloadData, setReloadData } = useContext(ContentHubContext);

  const currentShopId = useSelector((state: RootState) => state.currentShopId);
  const [selectedSourceFilter, setSelectedSourceFilter] = useState<Source>({
    name: 'Shopify',
    id: 'shopify',
  });
  const [product, setProduct] = useState<ProductItemType>(emptyProductMediaItem);
  const [uploadedProductMedia, setUploadedProductMedia] = useState<MediaItemType[]>([]);
  const [productAssets, setProductAssets] = useState<any>({});
  const [loading, setLoading] = useState(true);
  const [showDescription, setShowDescription] = useState(false);
  const [newDescription, setShowNewDescription] = useState<string | null>(null);
  const [showLink, setShowLink] = useState(false);
  const [publicLink, setPublicLink] = useState<string | null>(null);
  const [creatingLink, setCreatingLink] = useState(false);

  useEffect(() => {
    const loadProd = async (customId) => {
      setLoading(true);
      const url = `/v2/shopify/mongo/get-product-with-media`;
      const { data } = await axiosInstance.post(url, {
        shopId: currentShopId,
        prodHandle: customId,
      });
      setLoading(false);
      setProduct(data);
    };

    if ((!!params.customId && product.handle !== params.customId) || reloadData) {
      console.log('loading data');
      loadProd(params.customId);
    }
    setReloadData?.(false);
  }, [currentShopId, product.handle, params.customId, reloadData, setReloadData]);

  useEffect(() => {
    if (product) {
      const loadProdAssets = async () => {
        const { data } = await axiosInstance.post('/v2/media/get-media-assets-for-product', {
          productHandle: params?.customId,
          shopDomain: currentShopId,
          startDate: new Date(Date.now() - 100 * 24 * 60 * 60 * 1000),
          endDate: new Date(Date.now()),
        });
        setProductAssets(cleanProdAssets(data.assets));
      };
      const loadUploadedProductMedia = async () => {
        const { data } = await axiosInstance.post('/v2/media/get-product-media', {
          handles: [params?.customId],
          shopId: currentShopId,
          serviceIds: ['triplewhale'],
        });
        setUploadedProductMedia(data);
      };
      loadProdAssets();
      loadUploadedProductMedia();
    }
  }, [currentShopId, product, params?.customId]);

  const cleanProdAssets = (assets) => {
    let cleaned: any = {};
    assets.forEach((asset) => {
      const asset_name = Object.keys(asset)[0];
      cleaned[asset_name] = asset[asset_name];
    });
    return cleaned;
  };

  const getProductAdImages = (ads) => {
    let filteredAds = ads.filter((a) => a.images.length > 0);
    return filteredAds.map((a) => {
      return { ...a, thumbnail: a.imageUrl, contentType: 'image' };
    });
  };

  const updateShopifyDescription = useCallback(
    async (newDescription) => {
      setShowNewDescription(newDescription);
      try {
        await axiosInstance.post('/v2/shopify/edit-product-description', {
          shopId: currentShopId,
          description: newDescription,
          productId: product.id,
        });
      } catch (e) {
        toast.error('Product did not update');
      }
    },
    [currentShopId, product.id],
  );

  const confirmUpdateShopifyDescription = useCallback(
    async (newDescription) => {
      dispatch(
        confirmationModal({
          onConfirm: () => updateShopifyDescription(newDescription),
          onCancel: () => dispatch(confirmationModalClose()),
          text: `Are you sure you want to change the description for ${product.title}`,
          confirmButtonText: 'Yes',
          title: 'Confirm Product Change',
        }),
      );
    },
    [dispatch, product.title, updateShopifyDescription],
  );

  const openGenerativeAi = useCallback(async () => {
    dispatch({
      type: GENERATE_COPY_AI_POPUP_OPENED,
      payload: {
        serviceId: 'shopify',
        attemptFrom: 'product',
        entityId: product.id,
        isVideo: false,
        entityName: product.name,
        typeToAttempt: 'body',
        accountId: currentShopId,
        imageUrl: product.cover_images[0]?.url,
        onUse: (touse) => {
          confirmUpdateShopifyDescription(touse);
        },
      } as GenerativeCopyPopupData,
    });
  }, [
    dispatch,
    product.id,
    product.name,
    product.cover_images,
    currentShopId,
    confirmUpdateShopifyDescription,
  ]);

  const addImageToProductMedia = (newImage) => {
    let filename = /[^/]*$/.exec(newImage)?.[0];
    setProduct((prev) => {
      let media: MediaItemType[] = prev.cover_images;
      let newMedia: MediaItemType = {
        url: newImage,
        media_type: 'image' as MediaType,
        id: newImage,
        custom_id: product.id,
        thumbnail: newImage,
        service_id: 'triplewhale',
        name: filename || '',
        source: 'triplewhale',
        file_size: '0',
        gcs_path: '',
        shop_id: currentShopId,
      };
      media.push(newMedia);
      return { ...prev, cover_images: media };
    });
  };

  const variantCaption = (variant) => {
    return (
      <div className="flex flex-col gap-1">
        <div className="flex gap-4 items-center justify-between text-secondary-text">
          <p className="flex-1 truncate">{variant.media?.name}</p>
        </div>
        <div>
          <b>{variant.title}</b>
        </div>
        <div>Price: {variant.price}</div>
        <div>Inventory: {variant.inventory_quantity}</div>
      </div>
    );
  };

  const copyLinkToClipboard = () => {
    copyToClipboard(publicLink);
    toast.success('Link copied to clipboard');
  };

  if (loading) {
    return (
      <div className="w-full h-full justify-center items-center flex">
        <Spinner />
      </div>
    );
  }

  const createLink = async () => {
    setShowLink(true);
    setCreatingLink(true);
    try {
      const response = await axiosInstance.post('/v2/users/generate-public-upload-token', {
        shopId: currentShopId,
        productId: product.id,
      });
      let shareLink = response.data.url;
      setPublicLink(shareLink);
      setCreatingLink(false);
    } catch (e) {
      setCreatingLink(false);
      setShowLink(false);
      toast.error('Could not create link');
      console.log(`Error: `, JSON.stringify(e));
    }
  };

  return (
    <div className="h-[73vh] flex flex-col overflow-auto gap-6.5">
      <div className="flex items-center gap-4 w-full justify-between">
        <div className="flex items-center gap-4">
          <Breadcrumbs
            breadcrumbs={[
              {
                content: 'Creative Hub',
                onAction: () => {
                  history.push({ pathname: `/creative-hub/products`, search: location.search });
                  closeOpenProduct();
                },
              },
            ]}
          />
          <a
            href={`https://${currentShopId}/products/${product.handle}`}
            target="_blank"
            className="font-bold text-black"
            rel="noreferrer"
          >
            {product.title}
          </a>
          <span className="w-1 h-1 rounded-full bg-black"></span>
          <span className="text-secondary-text">
            {product.stockCount ? product.stockCount : 0} items in stock
          </span>
        </div>
        <div className="flex">
          <Tooltip content="Create a public link for uploading media to this product">
            <Button onClick={() => createLink()}>Generate Media Upload Link</Button>
          </Tooltip>
        </div>
      </div>

      <div className="flex gap-6.5">
        {sources.map((source) => (
          <BubbleToggleButton
            key={source.id}
            label={source.name}
            onClick={() => {
              setSelectedSourceFilter(source);
            }}
            active={selectedSourceFilter.id === source.id}
          />
        ))}
      </div>
      {selectedSourceFilter.id === 'shopify' ? (
        <div className="flex gap-12 flex-col">
          {product.body_html && (
            <div className="flex flex-col gap-6.5">
              <p
                onClick={() => setShowDescription((open) => !open)}
                className="text-2xl font-medium cursor-pointer flex items-center gap-2 select-none"
              >
                <ChevronDownMinor
                  className={`w-8 h-8 transition-transform ${showDescription ? ' rotate-180' : ''}`}
                />
                <span>Description</span>
              </p>
              <Collapsible open={showDescription} id={`${product.id}_body_collapsible`}>
                <div className="flex flex-col gap-4">
                  <div className="flex">
                    <Button icon={MagicMajor} onClick={() => openGenerativeAi()}>
                      Generate new Copy
                    </Button>
                  </div>
                  <div dangerouslySetInnerHTML={{ __html: newDescription ?? product.body_html }} />
                </div>
              </Collapsible>
            </div>
          )}
          <div className="flex flex-col gap-6.5">
            <p className="text-2xl font-medium">Product Photos</p>
            <div
              className={
                'grid gap-6.5 grid-cols-[repeat(auto-fill,minmax(240px,1fr))] auto-rows-[290px]'
              }
            >
              {product.cover_images.map((med) => {
                return (
                  <div
                    key={med.url}
                    className="rounded shadow-[rgb(0,0,0,8%)_0px_0px_8px] p-6.5 bg-white"
                  >
                    <CardThumbnail
                      media={med}
                      product={product}
                      onSaveEdited={addImageToProductMedia}
                    />
                  </div>
                );
              })}
            </div>
          </div>
          {product?.variants?.some((v) => !!v.media) && (
            <div className="text-2xl font-medium">Variants</div>
          )}
          <div
            className={
              'grid gap-6.5 grid-cols-[repeat(auto-fill,minmax(240px,1fr))] auto-rows-[360px]'
            }
          >
            {product?.variants?.map((variant) => {
              return variant.media ? (
                <div
                  key={variant.image_id}
                  className="rounded shadow-[rgb(0,0,0,8%)_0px_0px_8px] p-6.5 bg-white"
                >
                  <CardThumbnail
                    media={variant.media}
                    caption={variantCaption(variant)}
                    product={product}
                  />
                </div>
              ) : null;
            })}
          </div>
        </div>
      ) : selectedSourceFilter.id === 'triplewhale' ? (
        <div
          className={
            'grid gap-6.5 grid-cols-[repeat(auto-fill,minmax(240px,1fr))] auto-rows-[360px]'
          }
        >
          {uploadedProductMedia?.map((media) => {
            return media ? (
              <div
                key={media._id}
                className="rounded shadow-[rgb(0,0,0,8%)_0px_0px_8px] p-6.5 bg-white"
              >
                <CardThumbnail
                  media={media}
                  // caption={variantCaption(variant)}
                  product={product}
                />
              </div>
            ) : null;
          })}
        </div>
      ) : (
        <AdMediaCollection
          page={1}
          setPage={() => {}}
          hasNextPage={false}
          noData={
            productAssets[selectedSourceFilter.id]
              ? !getProductAdImages(productAssets[selectedSourceFilter.id]).length
              : false
          }
          loading={loading}
          media={
            productAssets[selectedSourceFilter.id]
              ? getProductAdImages(productAssets[selectedSourceFilter.id])
              : []
          }
        />
      )}
      <Modal open={showLink} onClose={() => setShowLink(false)} title="Public link" titleHidden>
        <div className="p-[30px] pt-[50px]">
          {!creatingLink ? (
            <TextField
              label="Product Media Upload Link"
              value={publicLink || ''}
              disabled={true}
              autoComplete="off"
              labelAction={{ content: 'Copy to clipboard', onAction: () => copyLinkToClipboard() }}
            ></TextField>
          ) : (
            <Spinner />
          )}
        </div>
      </Modal>
    </div>
  );
};
export default SingleProduct;
