// import { rechargeConnectOnPress } from "../ducks/recharge";
import AttentiveIconBase64 from 'components/AttentiveIconBase64';
// import RechargeIconBase64 from "components/Recharge/RechargeIconBase64";
import EnquirelabsIconBase64 from 'components/EnquirelabsIconBase64';
import FacebookIconBase64 from 'components/FacebookIconBase64';
import GoogleAdsIconBase64 from 'components/GoogleAdsIconBase64';
import GoogleAnalyticsIconBase64 from 'components/GoogleAnalyticsIconBase64';
import GoogleIconBase64 from 'components/GoogleIconBase64';
import GorgiasIconBase64 from 'components/GorgiasIconBase64';
import KnoLogoSVG from 'components/Icons/KnoLogoSVG';
import InstagramIconBase64 from 'components/InstagramIconBase64';
import KlaviyoIconBase64 from 'components/KlaviyoIconBase64';
import PinterestIconBase64 from 'components/PinterestIconBase64';
import ShopifyIconBase64 from 'components/ShopifyIconBase64';
import SnapchatIconBase64 from 'components/SnapchatIconBase64';
import TiktokIconBase64 from 'components/TiktokIconBase64';
import TwitterIconBase64 from 'components/TwitterIconBase64';
import TripleWhaleIconBase64 from 'components/TripleWhaleIconBase64';
import YoutubeIconBase64 from 'components/YoutubeIconBase64';
import { enquirelabsConnectOnPress } from 'ducks/enquirelabs';
import { gorgiasConnectOnPress } from 'ducks/gorgias';
import { klaviyoConnectOnPress } from 'ducks/klaviyo';
import { knoConnectOnPress } from 'ducks/kno';
import { ReactComponent as PixelIcon } from 'icons/pixel.svg';
import { ClientServiceRecord } from 'types/services';
import { getSourceExternalLink } from 'utils/attributions';
import Cookies from 'utils/Cookies';
import { services } from '@tw/types/module/services';
import PostscriptIconBase64 from 'components/PostscriptBase64';
import DripIconBase64 from 'components/DripBase64';
import SmsBumpIconBase64 from 'components/SmsBumpIconBase64';
import OmnisendIconBase64 from 'components/OmnisendIconBase64';
import MailchimpIconBase64 from 'components/MailchimpIconBase64';
import ViaIconBase64 from 'components/ViaIconBase64';
import { ReactComponent as SocialIcon } from '../icons/organic_social.svg';
import { ReactComponent as AffluencerIcon } from '../icons/affluencer-hub.svg';
import AmazonBase64 from 'components/AmazonBase64';
import { amazonConnectOnPress } from 'ducks/amazon';
import fbAnimationData from '../lotties/facebook-blue.json';
import googleAnimationData from '../lotties/google-orange.json';
import klaviyoAnimationData from '../lotties/klaviyo-green.json';
import shopifyAnimationData from '../lotties/shopify-green.json';
import MetaIconBase64 from 'components/MetaIconBase64';
import { facebookConnectOnPress } from '../ducks/facebook';
import { tiktokConnectOnPress } from '../ducks/tiktok';
import { twitterConnectOnPress } from 'ducks/twitter';
import { googleAdsConnectOnPress } from 'ducks/googleAds';
import { connectBing } from 'ducks/bing';
import MicrosoftIcon from 'components/Icons/MicrosoftSvg';
import RechargeIconBase64 from '../components/Recharge/RechargeIconBase64';
import CriteoIconBase64 from 'components/Criteo/CriteoIconBase64';
import { $currentShopId } from '$stores/$shop';
import { snapchatConnectOnPress } from '../ducks/snapchat';
import { Icon } from '@tw/ui-components';
import LinkedinIconBase64 from 'components/LinkedIn/LinkedinIconBase64';

const SERVICES: ClientServiceRecord = {
  shopify: {
    ...services['shopify']!,
    serviceId: 'shopify',
    title: 'Shopify',
    icon: ShopifyIconBase64,
    color: '#FFFC00',
    // accountConnection: {
    //   url: '/auth/snapchat/login?shop=' + $currentShopId.get(),
    // },
    //useNewApi: true,
    //allowReimport: true,
    type: 'shop',
    streams: {
      orders: {
        type: 'orders',
        title: 'Orders',
        icon: ShopifyIconBase64,
        color: '#FFFC00',
      },
      products: {
        type: 'products',
        title: 'Products',
        icon: ShopifyIconBase64,
        color: '#FFFC00',
      },
    },
  },
  amazon: {
    ...services['amazon']!,
    serviceId: 'amazon',
    title: 'Amazon',
    icon: AmazonBase64,
    color: '#FFFC00',
    accountConnection: {
      onAction: amazonConnectOnPress,
    },
    // accountConnection: {
    //   url: '/auth/snapchat/login?shop=' + $currentShopId.get(),
    // },
    useNewApi: true,
    allowReimport: true,
    streams: {
      orders: {
        type: 'orders',
        title: 'Orders',
        icon: AmazonBase64,
        color: '#FFFC00',
      },
    },
  },
  'facebook-ads': {
    ...services['facebook-ads']!,
    serviceId: 'facebook-ads',
    type: 'ads',
    allowReimport: true,
    hasCreative: true,
    icon: ({ small }) => FacebookIconBase64({ small }),
    iconOptions: {
      loop: true,
      autoplay: true,
      animationData: fbAnimationData,
      rendererSettings: {
        preserveAspectRatio: 'xMinYMin slice',
      },
    },
    title: 'Facebook',
    color: '#4267B2',
    sourceVariations: [
      'fb',
      'fbig',
      'facebook',
      'ig',
      'Facebook_Ads+ig',
      'Facebook_Ads+fb',
      'Facebook_Ads fb',
      'facebook_ads',
      'facebook-ads',
      'AD FB fb',
      'Facebook',
      'facebook.com',
      'Facebook_Mobile_Feed',
      'Instagram_Feed',
      'Instagram_Explore',
      'Facebook+Pd',
      'Facebook Pd',
      'Facebook_Ads',
      'Facebook_Desktop_Feed',
      'Instagram_Stories',
      'FBIG',
      'FB_Ads',
      'FBIG_AB',
      'fb_ig',
      'CTC+FB',
      'instagram',
      'AD+FB+fb',
      'facebook+instagram',
      'Facebook Ads',
      'FB-Paid',
      'Facebook+Ads',
      'FBPaid',
      'meta',
      'msg',
      'an',
    ],
    accountConnection: {
      onAction: facebookConnectOnPress,
    },
    useNewApi: true,
    streams: {
      'ads-metrics': {
        type: 'ads-metrics',
        icon: ({ small }) => FacebookIconBase64({ small }),
        title: 'Facebook Ads',
        color: '#4267B2',
      },
    },
    externalAdsUrl: getSourceExternalLink,
    hasInfluencers: true,
  },
  'google-ads': {
    ...services['google-ads']!,
    serviceId: 'google-ads',
    type: 'ads',
    allowReimport: true,
    icon: ({ small }) => GoogleAdsIconBase64({ small }),
    iconOptions: {
      loop: true,
      autoplay: true,
      animationData: googleAnimationData,
      rendererSettings: {
        preserveAspectRatio: 'xMinYMin slice',
      },
    },
    title: 'Google Ads',
    color: '#39a952',
    sourceVariations: ['google', 'google.com', 'google_ads', 'Google', 'google-ads'],
    accountConnection: {
      onAction: googleAdsConnectOnPress,
    },
    useNewApi: true,
    hasCreative: true,
    streams: {
      'ads-metrics': {
        type: 'ads-metrics',
        icon: ({ small }) => GoogleAdsIconBase64({ small }),
        title: 'Google Ads',
        color: '#39a952',
      },
    },
    externalAdsUrl: () => '',
  },
  bing: {
    ...services.bing!,
    serviceId: 'bing',
    type: 'ads',
    title: 'Microsoft',
    icon: MicrosoftIcon,
    iconOptions: {
      loop: true,
      autoplay: true,
      animationData: shopifyAnimationData,
      rendererSettings: {
        preserveAspectRatio: 'xMinYMin slice',
      },
    },
    color: '#FFFC00',
    sourceVariations: ['bing'],
    accountConnection: {
      onAction: connectBing,
    },
    useNewApi: true,
    useNewJobManager: true,
    allowReimport: true,
    streams: {
      'ads-metrics': {
        type: 'ads-metrics',
        title: 'Microsoft',
        icon: MicrosoftIcon,
        color: '#FFFC00',
      },
    },
    externalAdsUrl: () => '',
    hasInfluencers: true,
  },
  recharge: {
    ...services.recharge,
    serviceId: 'recharge',
    // type: 'ads',
    title: 'Recharge',
    icon: RechargeIconBase64,
    color: '#FFFC00',
    // sourceVariations: ['bing'],
    accountConnection: {
      hideConnect: true,
    },
    useNewApi: true,
    useNewJobManager: true,
    apiUrl: 'v2/summary-page/get-subscription-data',
    // allowReimport: true,
    // streams: {
    //   'ads-metrics': {
    //     type: 'ads-metrics',
    //     title: 'Microsoft',
    //     icon: MicrosoftIcon,
    //     color: '#FFFC00',
    //   },
    // },
    externalAdsUrl: () => '',
    hasInfluencers: false,
  },
  stripe: {
    ...services.stripe,
    serviceId: 'stripe',
    // type: 'ads',
    title: 'Stripe',
    icon: RechargeIconBase64,
    color: '#FFFC00',
    // sourceVariations: ['bing'],
    accountConnection: {
      hideConnect: true,
    },
    useNewApi: true,
    apiUrl: 'v2/data-bridge/get-order-stats',
    // allowReimport: true,
    // streams: {
    //   'ads-metrics': {
    //     type: 'ads-metrics',
    //     title: 'Microsoft',
    //     icon: MicrosoftIcon,
    //     color: '#FFFC00',
    //   },
    // },
    externalAdsUrl: () => '',
    hasInfluencers: false,
  },
  mountain: {
    ...services.mountain,
    title: 'Mountain',
    serviceId: 'mountain',
    type: 'ads',
    apiUrl: 'v2/summary-page/get-mountain-data',
  },
  criteo: {
    ...services.criteo,
    serviceId: 'criteo',
    type: 'ads',
    title: 'Criteo',
    icon: ({ small }) => <CriteoIconBase64 small={small} />,
    apiUrl: 'v2/summary-page/get-criteo-data',
  },
  linkedin: {
    ...services.linkedin,
    serviceId: 'linkedin',
    type: 'ads',
    title: 'LinkedIn',
    icon: ({ small }) => <LinkedinIconBase64 small={small} />,
    isBeta: true,
  },
  outbrain: {
    ...services.outbrain,
    serviceId: 'outbrain',
    type: 'ads',
    title: 'Outbrain',
    icon: ({ small }) => <Icon name="outbrain" size={small ? 15 : 20} />,
    apiUrl: 'v2/summary-page/get-outbrain-data',
  },
  taboola: {
    ...services.taboola,
    serviceId: 'taboola',
    type: 'ads',
    title: 'Taboola',
    icon: ({ small }) => <Icon name="taboola" size={small ? 15 : 20} />,
    apiUrl: 'v2/summary-page/get-taboola-data',
  },
  smsbump: {
    ...services.smsbump,
    serviceId: 'smsbump',
    type: 'email',
    icon: ({ small }) => <SmsBumpIconBase64 small={small} />,
    apiUrl: 'v2/summary-page/get-smsbump-data',
  },
  postscript: {
    ...services.postscript,
    serviceId: 'postscript',
    type: 'email',
    title: 'Postscript',
    color: 'rgb(51,21,139)',
    icon: ({ small }) => <PostscriptIconBase64 small={small} />,
    name: 'Postscript',
    apiUrl: 'v2/summary-page/get-postscript-data',
  },
  shipstation: {
    ...services.shipstation,
    serviceId: 'shipstation',
    apiUrl: 'v2/summary-page/get-shipstation-data',
  },
  shipbob: {
    ...services.shipbob,
    serviceId: 'shipbob',
    apiUrl: 'v2/summary-page/get-shipbob-data',
  },
  gorgias: {
    ...services.gorgias,
    serviceId: 'gorgias',
    apiUrl: 'v2/summary-page/get-gorgias-data',
  },
  'tiktok-ads': {
    ...services['tiktok-ads']!,
    serviceId: 'tiktok-ads',
    type: 'ads',
    title: 'TikTok',
    icon: ({ small }) => TiktokIconBase64({ small }),
    iconOptions: {
      loop: true,
      autoplay: true,
      animationData: shopifyAnimationData,
      rendererSettings: {
        preserveAspectRatio: 'xMinYMin slice',
      },
    },
    color: '#08FFF9',
    sourceVariations: ['tiktok', 'tiktok.com', 'TikTok', 'tiktok-ads'],
    accountConnection: {
      onAction: tiktokConnectOnPress,
    },
    allowReimport: true,
    hasCreative: true,
    useNewApi: true,
    streams: {
      'ads-metrics': {
        type: 'ads-metrics',
        title: 'TikTok',
        icon: ({ small }) => TiktokIconBase64({ small }),
        color: '#08FFF9',
      },
    },
    externalAdsUrl: getSourceExternalLink,
    hasInfluencers: true,
  },
  'twitter-ads': {
    ...services['twitter-ads']!,
    serviceId: 'twitter-ads',
    type: 'ads',
    title: 'Twitter',
    icon: ({ small }) => TwitterIconBase64({ small }),
    iconOptions: {
      loop: true,
      autoplay: true,
      animationData: shopifyAnimationData,
      rendererSettings: {
        preserveAspectRatio: 'xMinYMin slice',
      },
    },
    color: '#08FFF9',
    sourceVariations: ['twitter', 'twitter.com', 'Twitter', 'twitter-ads'],
    accountConnection: {
      onAction: twitterConnectOnPress,
    },
    allowReimport: true,
    hasCreative: true,
    useNewApi: true,
    useNewJobManager: true,
    streams: {
      'ads-metrics': {
        type: 'ads-metrics',
        title: 'Twitter',
        icon: ({ small }) => TwitterIconBase64({ small }),
        color: '#08FFF9',
      },
    },
    externalAdsUrl: getSourceExternalLink,
    hasInfluencers: true,
  },
  'pinterest-ads': {
    ...services['pinterest-ads']!,
    serviceId: 'pinterest-ads',
    type: 'ads',
    allowReimport: true,
    title: 'Pinterest',
    icon: ({ small }) => PinterestIconBase64({ small }),
    iconOptions: {
      loop: true,
      autoplay: true,
      animationData: shopifyAnimationData,
      rendererSettings: {
        preserveAspectRatio: 'xMinYMin slice',
      },
    },
    color: '#DB4437',
    sourceVariations: ['pinterest', 'pinterest.com', 'Pinterest', 'pinterest-ads'],
    accountConnection: {
      url: '/auth/pinterest/login?shop=' + $currentShopId.get(),
    },
    useNewApi: true,
    streams: {
      'ads-metrics': {
        type: 'ads-metrics',
        title: 'Pinterest',
        icon: ({ small }) => PinterestIconBase64({ small }),
        color: '#DB4437',
      },
    },
    externalAdsUrl: () => '',
  },
  'snapchat-ads': {
    ...services['snapchat-ads']!,
    serviceId: 'snapchat-ads',
    type: 'ads',
    title: 'Snapchat',
    icon: SnapchatIconBase64,
    iconOptions: {
      loop: true,
      autoplay: true,
      animationData: shopifyAnimationData,
      rendererSettings: {
        preserveAspectRatio: 'xMinYMin slice',
      },
    },
    color: '#FFFC00',
    sourceVariations: ['snapchat', 'snapchat.com', 'snapchat-ads'],
    accountConnection: {
      onAction: snapchatConnectOnPress,
    },
    useNewApi: true,
    allowReimport: true,
    streams: {
      'ads-metrics': {
        type: 'ads-metrics',
        title: 'Snapchat',
        icon: SnapchatIconBase64,
        color: '#FFFC00',
      },
    },
    externalAdsUrl: () => '',
    hasInfluencers: true,
  },
  influencers: {
    // Todo remove influencers key
    ...services.influencers,
    serviceId: 'influencers',
    title: 'Affluencer',
    icon: () => (
      <span className="fill-primary flex items-center">
        <AffluencerIcon width={16} height={16} />
      </span>
    ),
    color: '#FFFC00',
  },
};

const extraServices: ClientServiceRecord = {
  'bing-ads': {
    name: 'Microsoft',
    title: 'Microsoft',
    icon: MicrosoftIcon,
    getAccounts: () => [],
    dataTypes: {},
    internalUrl: '',
    id: 'bing-ads',
    serviceId: 'bing-ads',
  },
  pixel: {
    id: 'pixel',
    serviceId: 'pixel',
    title: 'Pixel',
    icon: () => <PixelIcon />,
    internalUrl: '',
    dataTypes: {},
    name: 'Pixel',
    getAccounts: () => [],
  },
  'triple-whale': {
    serviceId: 'TRIPLEWHALE',
    id: 'triple-whale',
    title: 'Triple Whale',
    icon: ({ small }) => (
      <TripleWhaleIconBase64 small={typeof small !== 'undefined' ? small : true} />
    ),
    name: 'Triple Whale',
    internalUrl: '',
    dataTypes: {},
    getAccounts: () => [],
  },
  klaviyo: {
    serviceId: 'klaviyo',
    type: 'email',
    ...services['klaviyo']!,
    allowReimport: true,
    sourceVariations: ['klaviyo'],
    title: 'Klaviyo',
    icon: ({ small, light }) => KlaviyoIconBase64({ small, light }),
    iconOptions: {
      loop: true,
      autoplay: true,
      animationData: klaviyoAnimationData,
      rendererSettings: {
        preserveAspectRatio: 'xMinYMin slice',
      },
    },
    accountConnection: {
      onAction: klaviyoConnectOnPress,
    },
    useNewApi: true,
    streams: {
      'ads-metrics': {
        type: 'ads-metrics',
        icon: ({ small }) => KlaviyoIconBase64({ small }),
        title: 'Klaviyo',
        color: '#4267B2',
      },
    },
  },
  attentive: {
    serviceId: 'ATTENTIVE',
    id: 'attentive',
    type: 'email',
    title: 'attentive',
    icon: ({ small }) => AttentiveIconBase64({ small }),
    accountConnection: {
      onAction: () => {},
    },
    name: 'Attentive',
    internalUrl: '',
    dataTypes: {},
    getAccounts: () => [],
  },
  GOOGLE_ANALYTICS: {
    serviceId: 'GOOGLE',
    id: 'GOOGLE_ANALYTICS',
    title: 'Google Analytics',
    icon: ({ small }) => GoogleAnalyticsIconBase64({ small }),
    color: '#DB4437',
    accountConnection: {
      url: '/auth/google/login?shop=' + $currentShopId.get(),
    },
    allowReimport: true,
    name: 'Google Analytics',
    internalUrl: '',
    dataTypes: {},
    getAccounts: () => [],
  },
  // "RECHARGE": {
  //   serviceId: 'RECHARGE',
  //   title: 'Recharge',
  //   allowReimport: true,
  //   icon: RechargeIconBase64,
  //   accountConnection: {
  //     onAction: rechargeConnectOnPress,
  //   }
  // },
  ENQUIRELABS: {
    serviceId: 'ENQUIRELABS',
    id: 'ENQUIRELABS',
    title: 'Fairing',
    icon: ({ small }) => EnquirelabsIconBase64({ small }),
    accountConnection: {
      onAction: enquirelabsConnectOnPress,
    },
    name: 'Fairing',
    internalUrl: '',
    dataTypes: {},
    getAccounts: () => [],
  },
  enquirelabs: {
    serviceId: 'ENQUIRELABS',
    id: 'enquirelabs',
    title: 'Fairing',
    type: 'survey',
    icon: ({ small }) => EnquirelabsIconBase64({ small }),
    accountConnection: {
      onAction: enquirelabsConnectOnPress,
    },
    name: 'Fairing',
    internalUrl: '',
    dataTypes: {},
    getAccounts: () => [],
  },
  kno: {
    serviceId: 'KNO',
    id: 'kno',
    title: 'Kno',
    type: 'survey',
    icon: ({ small }) => KnoLogoSVG({ small }),
    accountConnection: {
      onAction: knoConnectOnPress,
    },
    name: 'Kno',
    internalUrl: '',
    dataTypes: {},
    getAccounts: () => [],
  },
  triplesurvey: {
    id: 'triplesurvey',
    title: 'Triple Survey',
    type: 'survey',
    icon: ({ small }) => TripleWhaleIconBase64({ small }),
    serviceId: 'triplesurvey',
    accountConnection: {},
    name: 'Triple Survey',
    internalUrl: '',
    dataTypes: {},
    getAccounts: () => [],
  },
  triplesurvey_text: {
    id: 'triplesurvey_text',
    title: 'Text',
    // type: 'survey',
    icon: ({ small }) => TripleWhaleIconBase64({ small }),
    serviceId: 'triplesurvey',
    accountConnection: {},
    name: 'Triple Survey Text',
    internalUrl: '',
    dataTypes: {},
    getAccounts: () => [],
  },
  triplesurvey_email: {
    id: 'triplesurvey_email',
    title: 'Email',
    // type: 'survey',
    icon: ({ small }) => TripleWhaleIconBase64({ small }),
    serviceId: 'triplesurvey',
    accountConnection: {},
    name: 'Triple Survey Email',
    internalUrl: '',
    dataTypes: {},
    getAccounts: () => [],
  },
  organic: {
    id: 'organic',
    title: 'Referred by a friend',
    // type: 'survey',
    icon: ({ small }) => TripleWhaleIconBase64({ small }),
    serviceId: 'triplesurvey',
    accountConnection: {},
    name: 'Referred by a friend',
    internalUrl: '',
    dataTypes: {},
    getAccounts: () => [],
  },
  'triplesurvey-none': {
    id: 'triplesurvey-none',
    title: 'None of the above',
    // type: 'survey',
    icon: ({ small }) => TripleWhaleIconBase64({ small }),
    serviceId: 'triplesurvey-none',
    accountConnection: {},
    name: 'None of the above',
    internalUrl: '',
    dataTypes: {},
    getAccounts: () => [],
  },
  'None of the above': {
    id: 'None of the above',
    title: 'None of the above',
    // type: 'survey',
    icon: ({ small }) => TripleWhaleIconBase64({ small }),
    serviceId: 'triplesurvey-none',
    accountConnection: {},
    name: 'None of the above',
    internalUrl: '',
    dataTypes: {},
    getAccounts: () => [],
  },
  instagram: {
    serviceId: 'instagram',
    id: 'instagram',
    title: 'Instagram',
    icon: ({ small }) => InstagramIconBase64({ small }),
    name: 'Instagram',
    internalUrl: '',
    dataTypes: {},
    getAccounts: () => [],
    hasInfluencers: true,
  },
  youtube: {
    serviceId: 'YOUTUBE',
    id: 'youtube',
    title: 'Youtube',
    icon: ({ small }) => YoutubeIconBase64({ small }),
    name: 'Youtube',
    internalUrl: '',
    dataTypes: {},
    getAccounts: () => [],
    hasInfluencers: true,
  },
  tw_referrer: {
    serviceId: 'tw_referrer',
    type: 'social',
    id: 'tw_referrer',
    title: 'Organic and Social',
    icon: ({ small }) => (
      <span className="fill-primary flex items-center">
        <SocialIcon width={16} height={16} />
      </span>
    ),
    name: 'Organic and Social',
    internalUrl: '',
    dataTypes: {},
    getAccounts: () => [],
  },
  organic_and_social: {
    serviceId: 'organic_and_social',
    type: 'social',
    id: 'organic_and_social',
    title: 'Organic and Social',
    icon: ({ small }) => (
      <span className="fill-primary flex items-center">
        <SocialIcon width={16} height={16} />
      </span>
    ),
    name: 'Organic and Social',
    internalUrl: '',
    dataTypes: {},
    getAccounts: () => [],
  },
  // postscript: {
  //   id: 'postscript',
  //   serviceId: 'POSTSCRIPT',
  //   type: 'email',
  //   title: 'Postscript',
  //   color: 'rgb(51,21,139)',
  //   icon: ({ small }) => <PostscriptIconBase64 small={small} />,
  //   name: 'Postscript',
  //   internalUrl: '',
  //   getAccounts: () => [],
  //   dataTypes: {},
  // },
  drip: {
    id: 'drip',
    serviceId: 'DRIP',
    type: 'email',
    title: 'Drip',
    color: 'rgb(51,21,139)',
    icon: ({ small }) => <DripIconBase64 small={small} />,
    name: 'Drip',
    internalUrl: '',
    getAccounts: () => [],
    dataTypes: {},
  },
  via: {
    id: 'via',
    title: 'Via',
    name: 'Via',
    type: 'email',
    color: 'rgb(161,130,66)',
    icon: ({ small }) => <ViaIconBase64 small={small} />,
    serviceId: 'via',
    internalUrl: '',
    getAccounts: () => [],
    dataTypes: {},
  },
  mailchimp: {
    id: 'mailchimp',
    title: 'Mailchimp',
    name: 'Mailchimp',
    type: 'email',
    color: 'rgb(255,223,27)',
    icon: ({ small }) => <MailchimpIconBase64 small={small} />,
    serviceId: 'mailchimp',
    internalUrl: '',
    getAccounts: () => [],
    dataTypes: {},
  },
  omnisend: {
    id: 'omnisend',
    title: 'Omnisend',
    name: 'Omnisend',
    type: 'email',
    color: 'rgb(85,92,102)',
    icon: ({ small }) => <OmnisendIconBase64 small={small} />,
    serviceId: 'omnisend',
    internalUrl: '',
    getAccounts: () => [],
    dataTypes: {},
  },
  sms_bump: {
    id: 'sms_bump',
    title: 'Yotpo SMS and Email',
    name: 'Yotpo SMS and Email',
    type: 'email',
    color: 'rgb(120,211,84)',
    icon: ({ small }) => <SmsBumpIconBase64 small={small} />,
    serviceId: 'smsbump',
    internalUrl: '',
    getAccounts: () => [],
    dataTypes: {},
    sourceVariations: ['smsbump', 'sms_bump'],
  },
  meta_shop: {
    id: 'meta_shop',
    title: 'Meta Shop',
    name: 'Meta Shop',
    type: 'shop',
    color: 'rgb(120,211,84)',
    icon: ({ small }) => <MetaIconBase64 small={small} />,
    serviceId: 'metashop',
    internalUrl: '',
    getAccounts: () => [],
    dataTypes: {},
    sourceVariations: ['metashop', 'meta_shop'],
  },
  Excluded: {
    id: 'Excluded',
    title: 'Excluded',
    name: 'Excluded',
    // type: 'survey',
    color: 'rgb(120,211,84)',
    icon: ({ small }) => <TripleWhaleIconBase64 small={small} />,
    serviceId: 'excluded',
    internalUrl: '',
    getAccounts: () => [],
    dataTypes: {},
  },
};

const allServices = {
  ...SERVICES,
  ...extraServices,
};

export default allServices;
