import { Flex } from '@tw/ui-components';
import { useFilterMenu } from '../../../hooks/useFilterMenu';
import { useIsNavWideStyle } from 'components/Nav/stores';
import { useMemo } from 'react';
import { NarrowLink } from './NarrowLink';
import { ExpandedLink } from './ExpandedLink';
import { $navigationSections } from '$stores/nav-config-stores';
import { useStoreValue } from '@tw/snipestate';

export const MainLinksSection = () => {
  const navigationSections = useStoreValue($navigationSections);
  const mainSections = useFilterMenu(navigationSections, 'main');
  const isWide = useIsNavWideStyle();

  const narrowLinks = useMemo(
    () =>
      mainSections.map(({ icon, title }) => (
        <NarrowLink key={`nav-link-sm-${title}`} icon={icon} title={title} />
      )),
    [mainSections],
  );

  const expandedLinks = useMemo(
    () => mainSections.map((props) => <ExpandedLink key={`nav-link-${props.title}`} {...props} />),
    [mainSections],
  );

  return (
    <Flex direction="column" align="center">
      {isWide ? expandedLinks : narrowLinks}
    </Flex>
  );
};
