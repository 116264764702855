import moment from 'moment';
import { getDatePickerOptionValueOptions, TODAY, YESTERDAY } from './useDatePickerSelectedOptions';
import { $currentDateRange, $prevDateRange } from '../$stores/willy/$dateRange';
import { useSelector } from 'react-redux';
import { RootState } from '../reducers/RootType';
import { $compareOptions } from './useDatePickerCompareOptions';
import { useMemo } from 'react';
import { useStoreValue } from '@tw/snipestate';

export const getPopoverOpenerButtonTitle = (
  selected: {
    start: moment.Moment;
    end: moment.Moment;
  } | null,
  labelOfDateRange?: string,
) => {
  const { start, end } = selected || {};
  if (!start || !end) {
    return 'No Comparison';
  }
  if (labelOfDateRange) {
    return labelOfDateRange;
  }
  const midnight = moment().startOf('day');
  if (start.isSame(midnight, 'day')) {
    return TODAY;
  }

  const midnightYesterday = moment().subtract(1, 'days').startOf('day');
  if (start.isSame(midnightYesterday, 'day') && end.isSame(midnightYesterday, 'day')) {
    return YESTERDAY;
  }

  const format = 'MMM D, YYYY';

  const isOneDay = start.isSame(end, 'day');
  if (isOneDay) {
    return start.format(format);
  }

  const isSameYear = start.isSame(end, 'year');
  const startFormat = isSameYear ? 'MMM D' : format;

  return start.format(startFormat) + ' - ' + end.format(format);
};

export const useCurrentPopoverOpenerButtonTitle = () => {
  const mainDatePickerSelectionRange = useSelector(
    (state: RootState) => state.mainDatePickerSelectionRange,
  );
  const currentDateRange = useStoreValue($currentDateRange);
  const datePickerOptionValueOptions = useMemo(() => getDatePickerOptionValueOptions(), []);
  let labelOfCurrentDateRange;
  if (
    currentDateRange?.id &&
    currentDateRange.id !== 'custom' &&
    datePickerOptionValueOptions.find((option) => option.id === currentDateRange.id)
  ) {
    labelOfCurrentDateRange = datePickerOptionValueOptions.find(
      (option) => option.id === currentDateRange.id,
    )?.label;
  }

  return getPopoverOpenerButtonTitle(mainDatePickerSelectionRange, labelOfCurrentDateRange);
};

export const usePrevPopoverOpenerButtonTitle = () => {
  const datesToCompare = useSelector((state: RootState) => state.datesToCompare);
  const prevDateRange = useStoreValue($prevDateRange);
  const compareOptions = useStoreValue($compareOptions);
  let labelOfPrevDateRange;

  if (!prevDateRange?.id || prevDateRange.id === 'none') {
    labelOfPrevDateRange = 'No Comparison';
  } else if (
    prevDateRange?.id &&
    compareOptions.find((option) => option.value.id === prevDateRange.id)
  ) {
    labelOfPrevDateRange =
      prevDateRange.id === 'custom'
        ? compareOptions.find((option) => option.value.id === prevDateRange.id)!.label
        : compareOptions.find((option) => option.value.id === prevDateRange.id)!.value?.label;
  }
  if (!labelOfPrevDateRange) {
    labelOfPrevDateRange = compareOptions.find((x) => x.active)?.value?.label;
  }
  return getPopoverOpenerButtonTitle(
    {
      start: moment(datesToCompare?.start),
      end: moment(datesToCompare?.end),
    },
    labelOfPrevDateRange,
  );
};
