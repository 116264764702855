import React, { Fragment, useCallback, useMemo, useState } from 'react';
import { $willyMetricsCombine } from '../../../../$stores/willy/$willyMetrics';
import {
  Combobox,
  extractCSSColor,
  Flex,
  Icon,
  IconName,
  Text,
  useCombobox,
} from '@tw/ui-components';
import { WillyExpressionOrCustomMetric } from 'components/Willy/types/willyTypes';
import { cx } from '../../../../utils/cx';
import classes from '../../../SummarySectionPicker/SummarySectionPicker.module.scss';
import { useDarkMode } from '../../../../dark-mode-control';
import { visibleLength } from 'components/Willy/utils/willyUtils';
import { useStoreValue } from '@tw/snipestate';

type Props = {
  selected: { label: string; value?: any };
  excludeShopMetrics?: boolean;
  setSelected: (metric: WillyExpressionOrCustomMetric) => void;
};

export const ChooseMetricDropdown: React.FC<Props> = ({
  selected,
  setSelected,
  excludeShopMetrics = false,
}) => {
  const metrics = useStoreValue($willyMetricsCombine);
  const [search, setSearch] = useState('');
  const combobox = useCombobox({
    onDropdownOpen() {
      combobox.focusSearchInput();
    },
    onDropdownClose: () => {
      combobox.resetSelectedOption();
      combobox.focusTarget();
      setSearch('');
    },
  });
  const darkMode = useDarkMode();
  const selectData = useMemo(
    () => metrics.filter((s) => s.name.toLowerCase().includes(search)),
    [metrics, search],
  );

  const comboboxOptions = useCallback(
    (isCustomMetric, isGlobal) =>
      selectData
        .filter((m) => {
          let shouldFiltered = false;
          if (isCustomMetric && !m.isCustomMetric) shouldFiltered = true;
          if (!isCustomMetric && m.isCustomMetric) shouldFiltered = true;
          if (isGlobal && !m.isGlobal) shouldFiltered = true;
          if (!isGlobal && m.isGlobal) shouldFiltered = true;

          return !shouldFiltered;
        })
        .map((item) => (
          <Fragment key={item.id}>
            <Combobox.Option
              value={item.id}
              key={item.id}
              className={cx(
                'data-[combobox-selected=true]:!bg-[var(--mantine-color-named2-3)]',
                ':hover:!bg-[var(--mantine-color-named2-3)]',
                classes.selectItem,
              )}
            >
              <Flex gap={'xs'} align={'center'}>
                {visibleLength(item.icon) > 1 ? (
                  <Icon name={item.icon as IconName} size={20} color={'named2.1'} />
                ) : (
                  item.icon
                )}
                <Text>{item.name}</Text>
              </Flex>
            </Combobox.Option>
          </Fragment>
        )),
    [selectData],
  );

  return (
    <Combobox
      store={combobox}
      width={300}
      position="bottom-start"
      styles={{ options: { maxHeight: 400, overflowY: 'scroll' } }}
      onOptionSubmit={(id) => {
        if (!selected) return;
        const selectedOption = metrics.find((metric) => metric.id === id)!;
        setSelected(selectedOption);
        combobox.closeDropdown();
      }}
    >
      <Combobox.Target>
        <Flex gap={'xs'} mr={'xs'} align={'center'} onClick={() => combobox.toggleDropdown()}>
          <>{selected && <div>{selected.label}</div>}</>
          <>{!selected && <div>{'Metric'}</div>}</>
          <Icon name="caret-down" width={12} />
        </Flex>
      </Combobox.Target>

      <Combobox.Dropdown
        bg={darkMode ? 'gray.6' : 'white'}
        style={{ border: 'none', boxShadow: '0px 1px 2px rgba(0, 0, 0, 0.05)' }}
      >
        <Combobox.Search
          classNames={{
            input: classes.search,
          }}
          styles={{
            input: {
              backgroundColor: extractCSSColor(darkMode ? 'gray.6' : 'white'),
              borderBottom: `0.5px solid ${extractCSSColor(darkMode ? 'gray.3' : 'gray.4')}`,
              fontWeight: 500,
            },
          }}
          value={search}
          onChange={(event) => setSearch(event.currentTarget.value)}
          placeholder="Search metric"
        />
        <Combobox.Options>
          <Combobox.Group label="Global Basic Metrics">
            {comboboxOptions(false, true)}
          </Combobox.Group>
          {!excludeShopMetrics && (
            <Combobox.Group label="Shop Basic Metrics">
              {comboboxOptions(false, false)}
            </Combobox.Group>
          )}
          <Combobox.Group label="Global Custom Metrics">
            {comboboxOptions(true, true)}
          </Combobox.Group>

          {!excludeShopMetrics && (
            <Combobox.Group label="Shop Custom Metrics">
              {comboboxOptions(true, false)}
            </Combobox.Group>
          )}
        </Combobox.Options>
      </Combobox.Dropdown>
    </Combobox>
  );
};
