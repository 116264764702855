import { $isSimpleUser } from '$stores/$user';
import { $currentRevenue } from '$stores/willy/$subscription';
import {
  logUgradeEvent,
  openBookDemoModal,
  type UpgradeEventSource,
} from '$stores/willy/$upgradePopupManager';
import { useStoreValue } from '@tw/snipestate';
import { useHistory } from 'react-router';
import { upgradeCtaActions } from 'utils/dataLayer';

export const useFoundersDashUpgradeButton = (source: UpgradeEventSource) => {
  const history = useHistory();
  const isSimpleUser = useStoreValue($isSimpleUser);
  const shopRevenue = useStoreValue($currentRevenue);
  const isAboveMillion = shopRevenue > 1000000;
  const buttonText = isAboveMillion ? 'Book a Demo' : isSimpleUser ? 'View Plans' : 'Upgrade';
  const action = () => {
    isAboveMillion ? openBookDemoModal() : history.push('/plans');
    logUgradeEvent(source, upgradeCtaActions.UPGRADE_CTA_CLICKED);
  };
  const linkText = isAboveMillion ? 'book a demo' : 'upgrade';
  return { linkText, action, buttonText };
};
