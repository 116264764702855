import React, { useCallback, useEffect, useRef, useState, Fragment } from 'react';
import { connect, useSelector } from 'react-redux';
import { debounce } from 'lodash';
import {
  Card,
  DataTable,
  TextField,
  Link,
  DropZone,
  Checkbox,
  Button,
  Caption,
  TextStyle,
  Page,
  Layout,
  Banner,
  ButtonGroup,
  Stack,
  Select,
  Spinner,
  SkeletonBodyText,
  Tooltip,
  Icon,
  Modal,
  //DropZone,
} from '@shopify/polaris';
import {
  productsTableProductOnChangeProductCost,
  importProductsTableOnPress,
  cogsUploadDialogCancelOnPress,
  showPrompt,
  hidePrompt,
  setBidirectionalCogs,
} from '../ducks/actions';
import {
  productsSearchOnChange,
  productsTableOnSort,
  variantOnChangeInStats,
  // clearHandlingFeesOnPress,
  // clearProductCostsOnPress,
  productsOnClickPreviousPageButton,
  productsOnClickNextPageButton,
  cogsExportCsvOnPress,
  cogOnChangeHandlingFees,
  cogOnChangeProductCost,
  updateCustomCogsPercentisActive,
  updateFixedHandlingFeeisActive,
  updateInitialCustomCogsPercent,
  updateInitialFixedHandlingFee,
  // cogsOnImport,
  // loadProducts
} from '../ducks/products';
import {
  CancelSmallMinor,
  ChevronLeftMinor,
  ChevronRightMinor,
  ClockMinor,
} from '@shopify/polaris-icons';
import CURRENCIES from '../constants/currencies';
import ProductsCogsPercent from './products/ProductsCogsPercent';
import HandlingFeesFixedForOrder from './products/HandlingFeesFixedForOrder';
import Api from 'utils/Api';
import { useDispatch } from 'react-redux';
import UpdateShopifyPermissions from './UpdateShopifyPermissions';
import useDebounce from 'utils/useDebounce';
import _db from '../utils/DB';
import CogsHistory from 'pages/cogs/CogsHistory';
import { getCurrencySymbol } from '@tw/constants';
import { TWInfoTooltip } from './library/TWTooltip/TWInfoTooltip';
import { confirmationModal, confirmationModalClose } from 'ducks/confirmationModal';
import { RootState } from '../reducers/RootType';
import { useAppDispatch } from '../index';

var cellHeight = {
  height: 37,
};

var cell = {
  ...cellHeight,
  display: 'flex',
  alignItems: 'center',
};

var center = {
  ...cell,
  justifyContent: 'center',
};

const ProductTitle = ({ onPress, product, isOpen = false }) => (
  <div style={cell}>
    <Link onClick={() => onPress(product)}>
      {product['title']}&nbsp;{!isOpen ? '' : ''}
    </Link>
    {/*<Icon source="arrowDown" />*/}
  </div>
);

const VariantTitle = ({ shopifyDomain, variant }) => (
  <div style={cell}>
    {/*&nbsp;•&nbsp; */}
    &nbsp;&nbsp;
    <Link external monochrome url={'https://' + shopifyDomain + '/admin/variants/' + variant.id}>
      {variant.title}
    </Link>
  </div>
);

const VariantPriceTextField = ({ variant, currency }) => (
  <div style={cellHeight}>
    <TextField
      label={''}
      labelHidden={true}
      type="currency"
      value={'' + (variant['price'] || 0)}
      readOnly={true}
      disabled={true}
      prefix={CURRENCIES[currency]}
      maxLength={9}
      autoComplete="off"
    />
  </div>
);

const VariantHandlerFeesTextField = ({
  variant,
  onChange,
  currency,
  disabled,
  showCostHistory,
}) => {
  // Using state for performance,
  // first updating the textfield,
  // then sending to db
  var debounceOnChange = useCallback(debounce(onChange, 1500), []);
  var [val, setVal] = useState('' + (variant['handling_fees']?.[0]?.value ?? 0));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  var _onChange = useCallback((v) => {
    setVal(v);
    debounceOnChange(variant, v);
  }, []);
  return (
    <div style={cellHeight}>
      <TextField
        label={''}
        labelHidden={true}
        type="currency"
        value={val}
        onChange={_onChange}
        prefix={CURRENCIES[currency]}
        maxLength={9}
        disabled={disabled}
        autoComplete="off"
        suffix={
          <div
            className={`${disabled ? 'pointer-events-none opacity-20' : 'cost-history'}`}
            color="grey"
            title="Show Cost History"
            onClick={() => showCostHistory(variant, 'handling_fees')}
          >
            <Icon source={ClockMinor} />
          </div>
        }
      />
    </div>
  );
};

const VariantProductCostTextField = ({
  variant,
  onChange,
  currency,
  disabled,
  showCostHistory,
}) => {
  // Using state for performance,
  // first updating the textfield,
  // then sending to db
  var debounceOnChange = useCallback(debounce(onChange, 1500), []);

  var [val, setVal] = useState('' + (variant?.costs?.[0]?.value ?? 0));

  // eslint-disable-next-line react-hooks/exhaustive-deps
  var _onChange = useCallback((v) => {
    setVal(v);
    debounceOnChange(variant, v);
  }, []);

  return (
    <div style={cellHeight}>
      <TextField
        label={''}
        labelHidden={true}
        type="currency"
        value={val}
        onChange={_onChange}
        prefix={CURRENCIES[currency]}
        maxLength={9}
        disabled={disabled}
        autoComplete="off"
        suffix={
          <div
            className={`${disabled ? 'pointer-events-none opacity-20' : 'cost-history'}`}
            color="grey"
            title="Show Cost History"
            onClick={() => showCostHistory(variant, 'costs')}
          >
            <Icon source={ClockMinor} />
          </div>
        }
      />
    </div>
  );
};

const ProductPrice = ({ max, min, currency }) => (
  <div style={cellHeight}>
    <TextField
      label={''}
      labelHidden={true}
      type="currency"
      value={'' + (max === min ? max : min + ' - ' + max)}
      readOnly={true}
      disabled={true}
      prefix={CURRENCIES[currency]}
      maxLength={9}
      autoComplete="off"
    />
  </div>
);

const ProductInventoryQuantity = ({ total, onPress, product }) => (
  <div style={center}>
    <Link monochrome onClick={() => onPress(product)}>
      {total}
    </Link>
  </div>
);

const VariantInventoryQuantity = ({ total }) => <div style={center}>{total}</div>;

const ProductHandlingFees = ({ product, onChange, max, min, currency, disabled }) => {
  // Using state for performance,
  // first updating the textfield,
  // then sending to db
  var [val, setVal] = useState(max === min ? '' + max : '');
  const dispatch = useDispatch();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  var debouncedOnChange = debounce((v) => {
    dispatch(
      showPrompt({
        title: 'Update Product and All Variants',
        children:
          'Updating the product field will update all of the variants. Are you sure you want to continue?',
        primaryAction: {
          content: 'Update',
          onAction: () => {
            onChange({ id: product.variants.map((v) => v.id) }, v);
            dispatch(hidePrompt());
          },
        },
        secondaryAction: () => setVal(max === min ? '' + max : ''),
      }),
    );
  }, 2500);

  var _onChange = (e) => {
    setVal(e);
    debouncedOnChange(e);
  };
  return (
    <div style={cellHeight}>
      <TextField
        key={product.id}
        label={''}
        labelHidden={true}
        type="currency"
        value={val}
        placeholder={max !== min ? 'Multiple values' : ''}
        onChange={_onChange}
        prefix={CURRENCIES[currency]}
        maxLength={10}
        disabled={disabled}
        autoComplete="off"
      />
    </div>
  );
};

const ProductProductCost = ({ product, onChange, currency, disabled }) => {
  // Using state for performance,
  // first updating the textfield,
  // then sending to db
  var variantProductCost = product.variants.map((v) => v.costs?.[0]?.value ?? 0);
  const max = Math.max(...variantProductCost);
  const min = Math.min(...variantProductCost);

  const dispatch = useDispatch();

  var [val, setVal] = useState(max === min ? '' + max : '');

  var debouncedOnChange = debounce((v) => {
    dispatch(
      showPrompt({
        title: 'Update Product and All Variants',
        children:
          'Updating the product field will update all of the variants. Are you sure you want to continue?',
        primaryAction: {
          content: 'Update',
          onAction: () => {
            onChange(
              product.variants.map((v) => ({ id: v.id, inventory_item_id: v.inventory_item_id })),
              v,
              true,
            );
            dispatch(hidePrompt());
          },
        },
        secondaryAction: () => setVal(max === min ? '' + max : ''),
      }),
    );
  }, 2500);

  var _onChange = (e) => {
    setVal(e);
    debouncedOnChange(e);
  };

  useEffect(() => {
    setVal(max === min ? '' + max : '');
  }, [product]);

  return (
    <div style={cellHeight}>
      <TextField
        label={''}
        labelHidden={true}
        type="currency"
        value={val}
        placeholder={max !== min ? 'Multiple values' : ''}
        onChange={_onChange}
        prefix={CURRENCIES[currency]}
        maxLength={9}
        disabled={disabled}
        autoComplete="off"
      />
    </div>
  );
};

const ProductIsInStats = ({ product, isIn, onChange }) => {
  var [checked, setChecked] = useState(isIn);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  var handleChange = useCallback((val) => {
    setChecked(val);
    setTimeout(() => onChange({ id: product.variants.map((v) => v.id) }, val, true));
  }, []);

  return (
    <div style={center}>
      <Checkbox label="" checked={checked} onChange={handleChange} />
    </div>
  );
};

const VariantIsInStats = ({ variant, onChange, isIn }) => {
  var [checked, setChecked] = useState(isIn);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  var handleChange = useCallback((v) => {
    setChecked(v);
    onChange(variant, v);
  }, []);
  return (
    <div style={center}>
      <Checkbox label="" checked={checked} onChange={handleChange} />
    </div>
  );
};

const VariantSku = ({ v, cog }) => {
  var el;
  if (!cog) el = null;
  else {
    var { sku } = cog;
    el = (
      <Caption>
        <TextStyle variation="subdued">{sku}</TextStyle>
      </Caption>
    );
  }
  return <div style={cell}>{el}</div>;
};

const ProductSku = ({ product }) => {
  var skus = product.variants
    .filter((v) => !!v && v['sku'] && v['sku'].length)
    .map((v) => v['sku']);
  var el;
  var maxLetters = 20;
  if (skus.length === 0) {
    el = '-';
  } else {
    el = skus.join(',');
  }
  return (
    <div style={cell}>
      <div className="overflow-ellipsis overflow-hidden whitespace-nowrap w-[135px] text-[#919191] text-[12px] hover:whitespace-normal hover:break-all">
        {el}
      </div>
    </div>
  );
};

const ProductRow = ({
  index,
  product,
  shopifyDomain,
  rowOnChangeHandlingFees,
  rowOnChangeProductCost,
  variantOnChangeInStats,
  currency,
  isOpen,
  openRow,
  customCogsPercent,
  customFixedHandlingFeePerOrder,
  showCostHistory,
}) => {
  var expand = () => openRow(product, !isOpen);

  var visualProduct: any[] = [];
  var productTitle = <ProductTitle onPress={expand} product={product} isOpen={isOpen} />;

  var variantPrices = product.variants.map((v) => v.price || 0);
  var productPrice = (
    <ProductPrice
      max={Math.max(...variantPrices)}
      min={Math.min(...variantPrices)}
      currency={currency}
    />
  );
  var productInventoryQuantityTotal = product.variants.reduce(
    (t, v) => t + v.inventory_quantity,
    0,
  );
  var productInventoryQuantity = (
    <ProductInventoryQuantity
      total={productInventoryQuantityTotal}
      onPress={expand}
      product={product}
    />
  );

  var variantHandlingFees = product.variants.map((v) => v.handling_fees?.[0]?.value || 0);
  var productHandlingFees = (
    <ProductHandlingFees
      key={product.id + ':' + index + ':handling_fees'}
      max={Math.max(...variantHandlingFees)}
      min={Math.min(...variantHandlingFees)}
      product={product}
      onChange={rowOnChangeHandlingFees}
      currency={currency}
      disabled={!!customFixedHandlingFeePerOrder?.isActive}
    />
  );

  var productProductCost = (
    <ProductProductCost
      key={product.id + ':' + index + ':product_cost'}
      product={product}
      onChange={rowOnChangeProductCost}
      currency={currency}
      disabled={!!customCogsPercent?.isActive}
    />
  );
  const productAnyVariantIsInStats = product.variants.some((v) => v.inStats);
  const productIsIncludedInStats = (
    <ProductIsInStats
      product={product}
      isIn={productAnyVariantIsInStats}
      onChange={variantOnChangeInStats}
      key={product.id + ':' + index + ':inStats' + productAnyVariantIsInStats}
    />
  );

  var closeButton = (
    <div style={{ right: -6, top: 0, position: 'absolute' }}>
      <Button icon={CancelSmallMinor} onClick={expand} plain size="slim" />
    </div>
  );

  var productSkus = <ProductSku product={product} />;

  if (!isOpen) {
    visualProduct[0] = productTitle;
    visualProduct[1] = productSkus;
    visualProduct[2] = productPrice;
    visualProduct[3] = productProductCost;
    visualProduct[4] = productHandlingFees;
    visualProduct[5] = productInventoryQuantity;
    visualProduct[6] = productIsIncludedInStats;
  } else {
    visualProduct[0] = (
      <div style={{}}>
        {productTitle}
        {product.variants.map((v) => (
          <VariantTitle variant={v} shopifyDomain={shopifyDomain} key={v.id} />
        ))}
      </div>
    );
    visualProduct[1] = (
      <div style={{}}>
        {productSkus}
        {product.variants.map((v) => (
          <VariantSku v={v} key={v.id} cog={v} />
        ))}
      </div>
    );
    visualProduct[2] = (
      <div style={{}}>
        {productPrice}
        {product.variants.map((v) => (
          <VariantPriceTextField variant={v} key={v.id} currency={currency} />
        ))}
      </div>
    );
    visualProduct[3] = (
      <div style={{}}>
        {productProductCost}
        {product.variants.map((v) => (
          <VariantProductCostTextField
            variant={v}
            onChange={rowOnChangeProductCost}
            key={v.id + ':' + index + ':product_cost'}
            currency={currency}
            disabled={!!customCogsPercent?.isActive}
            showCostHistory={showCostHistory}
          />
        ))}
      </div>
    );
    visualProduct[4] = (
      <div style={{}}>
        {productHandlingFees}
        {product.variants.map((v) => (
          <VariantHandlerFeesTextField
            variant={v}
            onChange={rowOnChangeHandlingFees}
            key={v.id + ':' + index + ':handling_fees'}
            currency={currency}
            disabled={!!customFixedHandlingFeePerOrder?.isActive}
            showCostHistory={showCostHistory}
          />
        ))}
      </div>
    );
    visualProduct[5] = (
      <div style={{}}>
        {productInventoryQuantity}
        {product.variants.map((v) => (
          <VariantInventoryQuantity total={v.inventory_quantity} key={v.id} />
        ))}
      </div>
    );
    visualProduct[6] = (
      <div style={{ position: 'relative' }}>
        {closeButton}
        {productIsIncludedInStats}
        {product.variants.map((v) => (
          <VariantIsInStats
            variant={v}
            onChange={variantOnChangeInStats}
            isIn={v['inStats'] !== false}
            key={v.id + ':' + index + ':inStats' + v['inStats']}
          />
        ))}
      </div>
    );
  }

  return visualProduct;
};

const EmptyTableIndicator = () => (
  <Fragment>
    <Card.Section>
      <SkeletonBodyText lines={1} />
    </Card.Section>
    <Card.Section>
      <SkeletonBodyText lines={1} />
    </Card.Section>
    <Card.Section>
      <SkeletonBodyText lines={1} />
    </Card.Section>
    <Card.Section>
      <SkeletonBodyText lines={1} />
    </Card.Section>
    <Card.Section>
      <SkeletonBodyText lines={1} />
    </Card.Section>
    <Card.Section>
      <SkeletonBodyText lines={1} />
    </Card.Section>
    <Card.Section>
      <SkeletonBodyText lines={1} />
    </Card.Section>
    <Card.Section>
      <SkeletonBodyText lines={1} />
    </Card.Section>
    <Card.Section>
      <SkeletonBodyText lines={1} />
    </Card.Section>
    <Card.Section>
      <SkeletonBodyText lines={1} />
    </Card.Section>
    <Card.Section>
      <Stack distribution="center">
        <Spinner size="small" />
      </Stack>
      <br />
      <Stack distribution="center">
        <ButtonGroup segmented>
          <Button disabled={true} icon={ChevronLeftMinor} />
          <Button disabled={true} icon={ChevronRightMinor} />
        </ButtonGroup>
      </Stack>
    </Card.Section>
  </Fragment>
);

const TableComponent = ({
  products,
  filteredProducts,
  shopifyDomain,
  rowOnChangeHandlingFees,
  rowOnChangeProductCost,
  productOnChangeProductCost,
  onSort,
  variantOnChangeInStats,
  currency,
  customCogsPercent,
  customFixedHandlingFeePerOrder,
  isPreviousPageEnabled,
  onClickPreviousPageButton,
  isNextPageEnabled,
  onClickNextPageButton,
}) => {
  var [openedRows, setOpenedRows] = useState<any>([]);
  var [isSyncing, setIsSyncing] = useState<any>([]);
  const dispatch = useDispatch();

  const [isCostHistoryOpen, setIsCostHistoryOpen] = useState(false);
  const [selectedHistoryRow, setSelectedHistoryRow] = useState();
  const [historyType, setHistoryType] = useState('costs');

  const showCostHistory = (row, type) => {
    setIsCostHistoryOpen(true);
    setSelectedHistoryRow(row);
    setHistoryType(type);
  };

  var openRow = useCallback(
    (product: any, flag) => {
      const asyncFunc = async () => {
        // var allInventoryItemsAreSynced = product.variants.filter((v) => !v).length === 0;
        // if (!allInventoryItemsAreSynced) {
        //   if (isSyncing.includes(product.id)) {
        //     alert('Still synchronizing, try little later..');
        //     return;
        //   } else {
        //     setIsSyncing(isSyncing.concat(product.id));
        //     setTimeout(() => alert('Just a moment, synchronizing...'), 1);
        //     return;
        //   }
        // }
        // var arr = openedRows.slice();
        // if (flag) setOpenedRows(arr.concat(product.id));
        // else setOpenedRows(arr.filter((r) => r.id !== product.id));
        let arr: any[] = [];
        if (flag) arr.push(product.id);
        setOpenedRows(arr);
      };
      asyncFunc();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [products, isSyncing],
  );

  if (!products) return <EmptyTableIndicator />;

  return (
    <>
      <DataTable
        initialSortColumnIndex={0}
        verticalAlign="middle"
        columnContentTypes={['text', 'text', 'text', 'text', 'text', 'text', 'text']}
        headings={['Title', 'SKU', 'Price', 'Product Cost', 'Handling Fees', 'Qty', 'In stats']}
        rows={(filteredProducts || []).map((product, index) =>
          ProductRow({
            index,
            product,
            shopifyDomain,
            rowOnChangeHandlingFees,
            rowOnChangeProductCost,
            openRow,
            isOpen: openedRows.indexOf(product.id) >= 0,
            variantOnChangeInStats,
            currency,
            customCogsPercent,
            customFixedHandlingFeePerOrder,
            showCostHistory,
          }),
        )}
        sortable={[
          true,
          // true,
          // true,
          // true,
          // true,
          // true,
          // true,
        ]}
        onSort={onSort}
        //totals={['', '', '', 255, '$155,830.00']}
        footerContent={
          <Stack distribution="center">
            <ButtonGroup segmented>
              <Button
                disabled={!isPreviousPageEnabled}
                onClick={onClickPreviousPageButton}
                icon={ChevronLeftMinor}
              />
              <Button
                disabled={!isNextPageEnabled}
                onClick={onClickNextPageButton}
                icon={ChevronRightMinor}
              />
            </ButtonGroup>
          </Stack>
        }
      />
      <CogsHistory
        selectedRow={selectedHistoryRow}
        getCurrencySymbol={getCurrencySymbol}
        defaultCurrency={currency}
        isOpen={isCostHistoryOpen}
        onClose={() => setIsCostHistoryOpen(false)}
        service={'shopify'}
        type={historyType}
        title={`Shopify Product Cost History - ${historyType.replace('_', ' ')}`}
      />
    </>
  );
};

const mapStateToPropsTable = ({
  products,
  currentShopId,
  productsSearch,
  productsSortBy,
  productsSortOrder,
  currency,
  customCogsPercent,
  customFixedHandlingFeePerOrder,
  isProductsNextPageEnabled,
  isProductsPreviousPageEnabled,
}) => ({
  products,
  shopifyDomain: currentShopId,
  productsSearch,
  currency,
  customCogsPercent,
  customFixedHandlingFeePerOrder,
  isPreviousPageEnabled: isProductsPreviousPageEnabled,
  isNextPageEnabled: isProductsNextPageEnabled,
});

const mapDispatchToPropsTable = (dispatch) => {
  return {
    rowOnChangeHandlingFees: (cog, handling_fees, isSilent) =>
      dispatch(cogOnChangeHandlingFees(cog, handling_fees, isSilent)),
    rowOnChangeProductCost: (cog, product_cost, isSilent) =>
      dispatch(cogOnChangeProductCost(cog, product_cost, isSilent)),
    productOnChangeProductCost: (product, product_cost) =>
      dispatch(productsTableProductOnChangeProductCost(product, product_cost)),
    variantOnChangeInStats: (cog, flag) => dispatch(variantOnChangeInStats(cog, flag)),
    onSort: (column, order) => dispatch(productsTableOnSort(column, order)),
    onClickPreviousPageButton: () => dispatch(productsOnClickPreviousPageButton()),
    onClickNextPageButton: () => dispatch(productsOnClickNextPageButton()),
  };
};

const Table = connect(mapStateToPropsTable, mapDispatchToPropsTable)(TableComponent);

const optionsCalcMode = [
  { label: 'Use Current Cost', value: 'realtime' },
  { label: 'Use Historical Cost', value: 'timeline' },
];

const realtimeCogsInfo =
  'Realtime COGs are calculated based on current inventory items. To use the cost of the inventory items at the time of the order, use Timeline Mode.';
const timelineCogsInfo =
  "Timeline COGs are calculated based on the inventory items' cost at the time of the order. If you want to use the current inventory items' cost, use the Realtime Mode.";

const BidirectionalCheckbox = () => {
  const dispatch = useAppDispatch();
  const bidirectionalCogs = useSelector((state: RootState) => state.bidirectionalCogs);
  const handleChange = useCallback((newChecked) => {
    dispatch(setBidirectionalCogs(newChecked));
  }, []);
  const tooltipContent =
    'The default setting is that data edited in TW for COGS will not be reflected in Shopify and will be overwritten by Shopify when data imports. If Bi-Directional is selected, the Product Cost data that is entered in TW will update your Shopify data.';

  return (
    <div style={{ display: 'flex' }}>
      <Checkbox
        label="Bi-Directional"
        labelHidden
        checked={bidirectionalCogs}
        onChange={handleChange}
      />
      <div className="pl-2">
        Bi-Directional COGS <TWInfoTooltip iconSize={15} tooltipContent={tooltipContent} />
      </div>
    </div>
  );
};

const EnableFixedCostsModal = ({ isOpen, setIsOpen, type }) => {
  const dispatch = useAppDispatch();
  const [initialVal, setInitialVal] = useState(0);
  const currency = useSelector((state: RootState) => state.currency);
  return (
    <Modal
      open={isOpen}
      onClose={() => {
        setIsOpen(false);
        setInitialVal(0);
      }}
      title={`${type === 'cogs' ? 'Enable Custom COGS Percent' : 'Enable Fixed Handling Fee'}`}
      primaryAction={{
        content: 'Save',
        onAction: () => {
          if (type === 'cogs') dispatch(updateInitialCustomCogsPercent(initialVal));
          else if (type === 'handlingFees') dispatch(updateInitialFixedHandlingFee(initialVal));
          setIsOpen(false);
        },
        disabled: !initialVal || isNaN(initialVal) || initialVal < 0,
      }}
      secondaryActions={[
        {
          content: 'Cancel',
          onAction: () => {
            setIsOpen(false);
            setInitialVal(0);
          },
        },
      ]}
    >
      <Modal.Section>
        <div className="flex gap-5  align-items-center mb-3">
          <div>{type === 'cogs' ? 'Enter Custom COGS Percent' : 'Enter Fixed Handling Fee'}</div>
          <TextField
            labelHidden
            placeholder=""
            value={initialVal.toString()}
            onChange={(val) => setInitialVal(+val)}
            autoComplete="off"
            type="currency"
            prefix={type === 'cogs' ? '%' : CURRENCIES[currency]}
            label={''}
          />
        </div>
        <div>
          Please note it will take up to 24 hours to update all of your orders with this change.
        </div>
      </Modal.Section>
    </Modal>
  );
};

const Products = ({
  products,
  // exportCsvOnPress,
  // importOnPress,
  // importProductsTableIsOpen,
  // fileUploadDialogCancelOnPress,
  // cogsOnImport,
  searchOnChange,
  // productsSearch,
  productsDisabled,
  // clearHandlingFeesOnPress,
  // clearProductCostsOnPress,
  // exportingCogs,
  // importingCogs,
  bidirectionalCogs,
  // initLoadProducts,
  currency,
}) => {
  const [filteredProducts, setFilteredProducts] = useState(products || []);
  const [searchTerm, setSearchTerm] = useState('');
  const debouncedSearchTerm = useDebounce(searchTerm, 1000);
  const dispatch = useAppDispatch();
  const customFixedHandlingFeePerOrder = useSelector(
    (state: RootState) => state.customFixedHandlingFeePerOrder,
  );
  const customCogsPercent = useSelector((state: RootState) => state.customCogsPercent);
  const [fixedCostsModalIsOpen, setFixedCostsModalIsOpen] = useState(false);
  const [fixedCostsType, setFixedCostsType] = useState('');

  // useEffect(() => {
  //   dispatch(loadProducts());
  // }, []);

  useEffect(() => {
    const queryLowerCase = debouncedSearchTerm?.toLowerCase();
    searchOnChange(queryLowerCase);
  }, [debouncedSearchTerm]);

  useEffect(() => {
    setFilteredProducts(products);
  }, [products]);

  // useEffect(() => {
  //   console.log('Call initLoadProducts')
  //  // initLoadProducts();
  // });

  const changeIsActive = useCallback(
    (val, type) => {
      if (type === 'cogs') dispatch(updateCustomCogsPercentisActive(val));
      else if (type === 'handlingFees') dispatch(updateFixedHandlingFeeisActive(val));
    },
    [dispatch],
  );

  const openSetIsActiveModal = useCallback(
    async (val, type) => {
      if (
        !val ||
        (type === 'cogs' && customCogsPercent?.cogs_percent?.length > 0) ||
        (type === 'handlingFees' && customFixedHandlingFeePerOrder?.handling_fees?.length > 0)
      ) {
        dispatch(
          confirmationModal({
            onConfirm: () => changeIsActive(val, type),
            onCancel: () => dispatch(confirmationModalClose()),
            text: `Are you sure you want to ${val ? 'enable' : 'disable'} ${
              type === 'cogs' ? 'Custom COGS Percent' : 'Fixed Handling Fee'
            }? Please note it will take up to 24 hours to update all of your orders with this change.`,
            confirmButtonText: 'Yes',
            title: `${val ? 'Enable' : 'Disable'} ${
              type === 'cogs' ? 'Custom COGS Percent' : 'Fixed Handling Fee'
            }`,
          }),
        );
      } else {
        setFixedCostsModalIsOpen(true);
        setFixedCostsType(type);
      }
    },
    [customCogsPercent, customFixedHandlingFeePerOrder, dispatch, changeIsActive],
  );

  return (
    <div>
      {productsDisabled ? (
        <Card.Section>
          <Banner title="Under construction" status="info">
            <Button
              plain
              monochrome
              onClick={() =>
                window.Intercom(
                  'showNewMessage',
                  'Hey, how is the long products list feature going?',
                )
              }
            >
              Ask us about the progress
            </Button>
          </Banner>
        </Card.Section>
      ) : (
        <>
          {bidirectionalCogs && (
            <UpdateShopifyPermissions
              permission="write_inventory"
              message="In order to implement the bi-directional option and update your products in Shopify,
             you must update your Shopify Permissions."
            />
          )}

          <Card sectioned>
            <Card.Header
              actions={
                [
                  // {
                  //   content: !exportingCogs ? 'Export CSV' : 'Exporting...',
                  //   onAction: exportCsvOnPress,
                  //   disabled: exportingCogs,
                  // },
                  // {
                  //   content: !importingCogs ? 'Import CSV' : 'Importing...',
                  //   onAction: importOnPress,
                  //   disabled: importingCogs,
                  // },
                  // {
                  //   content: 'Clear handling fees',
                  //   onAction: clearHandlingFeesOnPress,
                  // },
                  // {
                  //   content: 'Clear product costs',
                  //   onAction: clearProductCostsOnPress,
                  // },
                ]
              }
              title={
                <>
                  <Layout>
                    <Layout.Section>
                      <Stack distribution="fill">
                        <div>
                          <div className="flex gap-4 pb-5">
                            <Checkbox
                              label="Custom COGS Percent"
                              labelHidden
                              checked={!!customCogsPercent?.isActive}
                              onChange={(checked) => {
                                openSetIsActiveModal(checked, 'cogs');
                              }}
                            />
                            <div>
                              Enable COGS as % of Gross Sales{' '}
                              <TWInfoTooltip
                                iconSize={15}
                                tooltipContent={
                                  'If a fixed COGS percentage is entered, any product costs entered on a product or variant level will be ignored.  The cost is set as the percent of the gross sales total. Please note it can take up to 24 hours to update all of your orders.'
                                }
                              />
                            </div>
                          </div>
                          {!!customCogsPercent?.isActive && <ProductsCogsPercent />}
                        </div>
                        <div>
                          <div className="flex gap-4 pb-5">
                            <Checkbox
                              label="Fixed Handling Fee per Order"
                              labelHidden
                              checked={!!customFixedHandlingFeePerOrder?.isActive}
                              onChange={(checked) => {
                                openSetIsActiveModal(checked, 'handlingFees');
                              }}
                            />
                            <div>
                              Enable Fixed Handling Fee per Order{' '}
                              <TWInfoTooltip
                                iconSize={15}
                                tooltipContent={
                                  'If a fixed handling fee cost is entered, any handling fee cost entered on a product or variant level will be ignored. Please note it can take up to 24 hours to update all of your orders.'
                                }
                              />
                            </div>
                          </div>
                          {!!customFixedHandlingFeePerOrder?.isActive && (
                            <HandlingFeesFixedForOrder />
                          )}
                        </div>
                      </Stack>
                    </Layout.Section>
                    <Layout.Section>
                      <Stack>
                        <BidirectionalCheckbox />
                      </Stack>
                    </Layout.Section>
                    <Layout.Section>
                      <Stack alignment="center">
                        <Stack.Item fill>
                          <TextField
                            label="Search Products"
                            value={searchTerm}
                            placeholder="Search by product name"
                            clearButton
                            onChange={setSearchTerm}
                            onClearButtonClick={() => setSearchTerm('')}
                            autoComplete="off"
                          />
                        </Stack.Item>
                      </Stack>
                    </Layout.Section>
                    <br />
                  </Layout>
                </>
              }
            >
              {/* <div style={{ opacity: 0 }}>
            <DropZone
              openFileDialog={importProductsTableIsOpen}
              onDrop={(files) => {
                var reader = new FileReader();
                reader.onload = function (e) {
                  var cogs = CSVImport(reader.result);
                  cogsOnImport(cogs);
                };

                reader.readAsText(files[0]);
              }}
              onFileDialogClose={fileUploadDialogCancelOnPress}
            ></DropZone>
          </div> */}
            </Card.Header>

            <Table filteredProducts={filteredProducts} />
          </Card>
        </>
      )}
      <EnableFixedCostsModal
        isOpen={fixedCostsModalIsOpen}
        setIsOpen={setFixedCostsModalIsOpen}
        type={fixedCostsType}
      />
    </div>
  );
};

const mapStateToProps = ({
  products,
  currentShopId,
  importProductsTableIsOpen,
  productsSearch,
  productsDisabled,
  exportingCogs,
  importingCogs,
  bidirectionalCogs,
  currency,
}) => ({
  products,
  shopifyDomain: currentShopId,
  importProductsTableIsOpen,
  productsSearch,
  productsDisabled,
  exportingCogs,
  importingCogs,
  bidirectionalCogs,
  currency,
});

const mapDispatchToProps = (dispatch) => {
  return {
    // initLoadProducts: () => dispatch(loadProducts()),
    exportCsvOnPress: () => dispatch(cogsExportCsvOnPress()),
    importOnPress: () => dispatch(importProductsTableOnPress()),
    // cogsOnImport: (cogs) => dispatch(cogsOnImport(cogs)),
    // fileUploadDialogCancelOnPress: (cogs) =>
    //   dispatch(cogsUploadDialogCancelOnPress(cogs)),
    searchOnChange: (val) => dispatch(productsSearchOnChange(val)),
    // clearHandlingFeesOnPress: () =>
    //   dispatch(
    //     showPrompt({
    //       title: 'Reset all handling fees?',
    //       //children: DisconnectFromGeneralExplanation,
    //       primaryAction: {
    //         content: 'OK',
    //         onAction: () => {
    //           dispatch(hidePrompt());
    //           dispatch(clearHandlingFeesOnPress());
    //         }
    //       }
    //     })
    //   ),
    // clearProductCostsOnPress: () =>
    //   dispatch(
    //     showPrompt({
    //       title: 'Reset all product costs?',
    //       //children: DisconnectFromGeneralExplanation,
    //       primaryAction: {
    //         content: 'OK',
    //         onAction: () => {
    //           dispatch(hidePrompt());
    //           dispatch(clearProductCostsOnPress());
    //         }
    //       }
    //     })
    //   )
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Products);
