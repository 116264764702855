import {
  RawNlqData,
  WillyExpressionMetric,
  WillyExpressionOrCustomMetric,
} from '../types/willyTypes';
import { forwardRef, useCallback, useEffect, useImperativeHandle, useMemo, useState } from 'react';
import { WillySingleValue } from '../WillySingleValue';
import { $dialect, $isTWDevClaim } from '../../../$stores/$user';
import { convertToServerMetrics, fetchMetricById } from './utils';
import {
  $willyMetricsCombine,
  deleteOverride,
  saveMetric,
} from '../../../$stores/willy/$willyMetrics';
import moment from 'moment';
import { WillyEditMetric } from '../WillyEditMetric';
import { CurrentDateRange, PrevDateRange } from '../../../$stores/willy/$dateRange';
import { useStoreValue } from '@tw/snipestate';

type MetricBoxProps = {
  currentDateRange?: CurrentDateRange;
  prevDateRange?: PrevDateRange;
  metricId: string;
  currency: string;
  metricUpdated?: (metric: WillyExpressionOrCustomMetric) => void;
};

export type WillySectionRef = {
  fetch: (metricForTest?: WillyExpressionOrCustomMetric) => void;
  fetchPreviousPeriod: (metricForTest?: WillyExpressionOrCustomMetric) => void;
  applyTestMetric: (metric: WillyExpressionOrCustomMetric) => void;
};

export const MetricBox = forwardRef<WillySectionRef, MetricBoxProps>(
  ({ currentDateRange, prevDateRange, metricId, currency, metricUpdated }, ref) => {
    const willyMetricsCombine = useStoreValue($willyMetricsCombine);
    const dialect = useStoreValue($dialect);
    const isTWDevClaim = useStoreValue($isTWDevClaim);
    const [editMetricModalOpen, setEditMetricModalOpen] = useState<{
      open: boolean;
      isFilterMode: boolean;
    }>({
      open: false,
      isFilterMode: false,
    });

    const [currentPeriodData, setCurrentPeriodData] = useState<RawNlqData>();
    const [previousPeriodData, setPreviousPeriodData] = useState<RawNlqData>();
    const [testMetric, setTestMetric] = useState<WillyExpressionOrCustomMetric>();
    const [currentLoading, setCurrentLoading] = useState(false);
    const [previousPeriodLoading, setPreviousPeriodLoading] = useState(false);

    const metric = useMemo(() => {
      return testMetric ?? willyMetricsCombine.find((m) => m.id === metricId);
    }, [metricId, willyMetricsCombine, testMetric]);

    const applyTestMetric = useCallback((metric: WillyExpressionOrCustomMetric) => {
      setTestMetric(metric);
    }, []);

    const fetch = useCallback(
      async (metricForTest?: WillyExpressionOrCustomMetric) => {
        if (!currentDateRange || !dialect) {
          return;
        }
        const { start, end } = currentDateRange;

        setCurrentLoading(true);
        const data = await fetchMetricById(
          metricId,
          currency,
          moment(start).format('YYYY-MM-DD'),
          moment(end).format('YYYY-MM-DD'),
          dialect,
          convertToServerMetrics(metricForTest ? [metricForTest] : [])?.[0],
        );

        setCurrentLoading(false);
        setCurrentPeriodData(data);
      },
      [currency, currentDateRange, metricId, dialect],
    );

    useEffect(() => {
      fetch();
    }, [fetch]);

    const fetchPreviousPeriod = useCallback(
      async (metricForTest?: WillyExpressionOrCustomMetric) => {
        if (!prevDateRange || prevDateRange.id === 'none' || !dialect) {
          return;
        }
        const { start, end } = prevDateRange;

        setPreviousPeriodLoading(true);
        const data = await fetchMetricById(
          metricId,
          currency,
          moment(start).format('YYYY-MM-DD'),
          moment(end).format('YYYY-MM-DD'),
          dialect,
          convertToServerMetrics(metricForTest ? [metricForTest] : [])?.[0],
        );

        setPreviousPeriodLoading(false);
        setPreviousPeriodData(data);
      },
      [currency, prevDateRange, metricId, dialect],
    );

    useEffect(() => {
      fetchPreviousPeriod();
    }, [fetchPreviousPeriod]);

    useImperativeHandle(ref, () => ({
      fetch,
      fetchPreviousPeriod,
      applyTestMetric,
    }));

    if (!metric) return null;

    return (
      <>
        <WillySingleValue
          currency={currency}
          queryId={''}
          metrics={[
            {
              key: metric.key,
              name: metric.name,
              color: '#000',
              description: metric.description,
              format: metric.format,
              toFixed: metric.toFixed,
              icon: metric.icon,
              valueIsNegative: metric.valueIsNegative,
              onClickAction: 'none',
            },
          ]}
          disableContentEditable
          metricsChanged={async (id, v) => {}}
          onMetricClicked={(metric) => {}}
          data={currentPeriodData ?? []}
          isEditEnable={metric.isGlobal ? isTWDevClaim : true}
          previousPeriodData={previousPeriodData}
          loading={currentLoading}
          errorInQuery={{}}
          loadingPreviousPeriod={previousPeriodLoading}
          setEditMetricModalOpen={() => setEditMetricModalOpen({ open: true, isFilterMode: false })}
          setFilterMetricModalOpen={() =>
            setEditMetricModalOpen({ open: true, isFilterMode: true })
          }
          isFiltered={(metric as WillyExpressionMetric)?.filter?.some((x) => x.isOverride)}
        />

        <WillyEditMetric
          metric={metric}
          availableMetrics={[]}
          onClose={() => {
            setEditMetricModalOpen({ open: false, isFilterMode: false });
          }}
          open={editMetricModalOpen.open}
          onSaved={async (metric, isEdit, isFilterMode) => {
            await saveMetric(metric, isEdit, isFilterMode);
            setEditMetricModalOpen({ open: false, isFilterMode: false });
          }}
          onRemoved={async () => {}}
          onResetOverride={async (metric) => {
            await deleteOverride(metric);
            setEditMetricModalOpen({ open: false, isFilterMode: false });
          }}
          fromExpressionBuilder
          isFilterMode={editMetricModalOpen.isFilterMode}
        />
      </>
    );
  },
);
