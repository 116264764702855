import { Autocomplete } from '@shopify/polaris';
import { CirclePlusMinor } from '@shopify/polaris-icons';
import { addNewCustomSpendCategory } from 'ducks/actions';
import { useAppDispatch } from 'index';
import { useState, useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { type RootState } from 'reducers/RootType';

export function CustomSpendCategory({ val, onChange, labelHidden = false }) {
  const dispatch = useAppDispatch();
  const categories = useSelector((state: RootState) => state.customSpendCategories);

  const [deselectedOptions, setDeselectedOptions] = useState<any[]>(
    categories?.map((c) => ({ value: c, label: c })),
  );

  useEffect(() => {
    setDeselectedOptions(categories?.map((c) => ({ value: c, label: c })));
  }, [categories]);

  useEffect(() => {
    setOptions(deselectedOptions);
  }, [deselectedOptions]);

  const [category, setCategory] = useState(val);
  const [inputValue, setInputValue] = useState(val);
  const [options, setOptions] = useState(deselectedOptions);

  useEffect(() => {
    setCategory(val);
    setInputValue(val);
  }, [val]);

  useEffect(() => {
    if (inputValue === '') onChange('');
  }, [inputValue, onChange]);

  const updateText = useCallback(
    (value) => {
      setInputValue(value);

      setTimeout(() => {
        if (value === '') {
          setOptions(deselectedOptions);
          return;
        }
        const filterRegex = new RegExp(value, 'i');
        const resultOptions = options.filter((option) => option.label.match(filterRegex));
        setOptions(resultOptions);
      }, 100);
    },
    [deselectedOptions, options],
  );

  const updateSelection = useCallback(
    (selected) => {
      const selectedText = selected.map((selectedItem) => {
        const matchedOption = options.find((option) => {
          return option.value === selectedItem;
        });
        return matchedOption && matchedOption.label;
      });
      setCategory(selected);
      setInputValue(selectedText[0]);
      onChange(selectedText[0]);
    },
    [onChange, options],
  );

  const textField = (
    <Autocomplete.TextField
      autoComplete="off"
      onChange={updateText}
      value={inputValue}
      placeholder="Type in category..."
      label="Category"
      labelHidden={labelHidden}
    />
  );
  return (
    <Autocomplete
      actionBefore={
        !categories.includes(inputValue)
          ? {
              accessibilityLabel: 'Add new category',
              content: (
                <div style={{ height: '75px' }}>
                  Enter new category and click plus to add to list
                </div>
              ) as any,
              icon: CirclePlusMinor,
              onAction: () => {
                if (inputValue === '' || inputValue.trim() === '') return;
                !categories.some((c) => c === inputValue) &&
                  dispatch(addNewCustomSpendCategory(inputValue, categories));
                setCategory(inputValue);
                onChange(inputValue);

                setOptions([...deselectedOptions, { value: inputValue, label: inputValue }]);
              },
            }
          : undefined
      }
      options={options}
      selected={category}
      onSelect={updateSelection}
      listTitle="Categories"
      textField={textField}
    />
  );
}
