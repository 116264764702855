import { Button, Modal } from '@shopify/polaris';
import React, { useState, useEffect, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'reducers/RootType';
import { connectToShopify } from 'utils/connectToShopify';

function UpdateShopifyPermissions({ permission, message }) {
  var [showPopup, setShowPopup] = useState(false);

  var currentShopId = useSelector((store: RootState) => store.currentShopId);
  const user = useSelector((state: RootState) => state.user);
  const shopifyScopes = useSelector((state: RootState) => state.shopifyScopesFromApi);

  useEffect(() => {
    if (currentShopId && user.uid && shopifyScopes) {
      const connected = shopifyScopes.includes(permission);
      if (!connected) {
        setShowPopup(true);
      }
    }
  }, [currentShopId, user.uid, shopifyScopes, permission]);

  return (
    <>
      <Modal
        title={'Update Your Shopify Permissions'}
        titleHidden={false}
        open={showPopup}
        onClose={() => setShowPopup(false)}
      >
        <Modal.Section>
          <div className="add_user_popup" style={{ textAlign: 'center' }}>
            <h4 style={{ marginBottom: '20px' }}>{message}</h4>
            <Button onClick={() => connectToShopify(currentShopId, false)}>
              Click here to continue
            </Button>
          </div>
        </Modal.Section>
      </Modal>
    </>
  );
}
export default UpdateShopifyPermissions;
