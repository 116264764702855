import {
  Box,
  Button,
  Checkbox,
  Flex,
  Grid,
  Icon,
  Loader,
  Menu,
  MultiSelect,
  Select,
  TextInput,
  Textarea,
} from '@tw/ui-components';
import React, { FC, useCallback, useMemo, useRef, useState } from 'react';
import { WillyDashAvatar } from './WillyDashAvatar';
import { WillyEmoji } from '../types/emojiTypes';
import { SalesPlatform, ServicesIds, services } from '@tw/types/module/services';
import { $ffStore } from 'feature-flag-system';
import { $isTwGlobalDashboardCreatorClaim } from '$stores/$user';
import { $categoriesList } from './category-management/stores';
import { FeatureFlagConfigKey } from '@tw/feature-flag-system/module/types';
import { $globalDashboardRoles } from '$stores/willy/$globalDashboardRoles';
import { Dialect, DialectWithBoth } from '../types/willyTypes';
import { useComputedValue, useStoreValue } from '@tw/snipestate';
import { initCategoryCreatorModal } from './category-management/api';
import SALES_PLATFORMS from 'constants/SalesPlatform';
import SalesPlatformName from 'components/SalesPlatform/SalesPlatformName';

type WillyDashAndSeqBaseSettingsProps = {
  id?: string;
  name: string;
  setName: (v: string) => void;
  description: string;
  setDescription: (v: string) => void;
  category: string;
  setCategory: (v: string) => void;
  imageSrc?: string;
  setImageSrc: (v: string | undefined) => void;
  emoji: WillyEmoji;
  setEmoji: (v: WillyEmoji) => void;
  roles: string[];
  setRoles: (v: string[]) => void;
  isBeta: boolean;
  setIsBeta: (v: boolean) => void;
  isHide: boolean;
  setIsHide: (v: boolean) => void;
  dialect: DialectWithBoth;
  setDialect: (v: DialectWithBoth) => void;
  isGlobal: boolean;
  providers: ServicesIds[];
  setProviders: (v: ServicesIds[]) => void;
  providersBlockingCombination: string;
  setProvidersBlockingCombination: (v: 'AND' | 'OR') => void;
  featureFlagConfigs: FeatureFlagConfigKey[];
  featureFlagDefaultCopyConfigs: FeatureFlagConfigKey[];
  setFeatureFlagConfigs: (v: FeatureFlagConfigKey[]) => void;
  setFeatureFlagDefaultCopyConfigs: (v: FeatureFlagConfigKey[]) => void;
  setFile: (v: File | undefined) => void;
  image?: string;
  removeDashImage: () => Promise<void>;
  isReportSettings?: boolean;
  loading?: Record<string, boolean>;
  msps: SalesPlatform[] | null;
  setMsps: (v: any) => void;
  credits: number | undefined;
  setCredits: (v: number) => void;
};
export const WillyDashAndSeqBaseSettings: FC<WillyDashAndSeqBaseSettingsProps> = ({
  id,
  name,
  setName,
  description,
  setDescription,
  category,
  setCategory,
  imageSrc,
  setImageSrc,
  emoji,
  setEmoji,
  roles,
  setRoles,
  isBeta,
  setIsBeta,
  isHide,
  setIsHide,
  dialect,
  setDialect,
  isGlobal,
  providers,
  setProviders,
  providersBlockingCombination,
  setProvidersBlockingCombination,
  featureFlagConfigs,
  featureFlagDefaultCopyConfigs,
  setFeatureFlagConfigs,
  setFeatureFlagDefaultCopyConfigs,
  setFile,
  image,
  removeDashImage,
  isReportSettings,
  loading,
  msps,
  setMsps,
  credits,
  setCredits,
}) => {
  const isTwGlobalDashboardCreatorClaim = useStoreValue($isTwGlobalDashboardCreatorClaim);
  const { list: customCategories } = useStoreValue($categoriesList);
  const rolesOptions = useStoreValue($globalDashboardRoles);

  const [loadingImage, setLoadingImage] = useState(false);
  const fileInputRef = useRef<HTMLInputElement>(null);

  const ffPackageNames = useComputedValue($ffStore, (ffComputer) => {
    return ffComputer.ffPackagesMetaData
      .filter((x) => (!x.sources?.length || x.sources.includes('subscription')) && !!x.isPackage)
      .map((x) => ({ label: x.name, value: x.id }));
  });

  const onFileChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      if (event.target.files) {
        setFile(event.target.files[0]);
        setImageSrc(URL.createObjectURL(event.target.files[0]));
      }
    },
    [setFile, setImageSrc],
  );

  const DropZ = useMemo(() => {
    return (
      <Flex direction="column" gap="md">
        <Flex borderRadius="10px" bg="gray.2" w="180px" h={120} align="center" justify="center">
          {imageSrc ? <img src={imageSrc} width="100%" /> : <Icon name="edit" />}
        </Flex>
        <input
          type="file"
          accept="image/png" // Accept only PNG files
          style={{ display: 'none' }} // Hide the input element
          ref={fileInputRef}
          onChange={onFileChange}
        />
        <Button
          variant="activator"
          leftSection={imageSrc ? 'edit' : null}
          loading={loadingImage}
          onClick={() => fileInputRef.current?.click()}
        >
          {imageSrc ? 'Edit Thumbnail' : 'Add Thumbnail'}
        </Button>
        {imageSrc ? (
          <Button
            leftSection={<Icon name="delete" size={20} />}
            variant="activator"
            loading={loadingImage}
            onClick={async () => {
              setLoadingImage(true);
              if (image && image === id) await removeDashImage();
              setFile(undefined);
              setImageSrc(undefined);
              setLoadingImage(false);
            }}
          >
            Remove
          </Button>
        ) : (
          ''
        )}
      </Flex>
    );
  }, [imageSrc, onFileChange, loadingImage, image, id, removeDashImage, setFile, setImageSrc]);

  const [folderSearch, setFolderSearch] = useState('');
  const [folderOptionsOpen, setFolderOptionsOpen] = useState(false);

  const filteredCategories = useMemo(() => {
    const filtered = customCategories.filter((c) => {
      if (c.type === 'item') return false;
      if (!folderSearch) return true;
      return c.value.toLowerCase().includes(folderSearch.toLowerCase());
    });
    return filtered;
  }, [folderSearch, customCategories]);

  const onCreateNewFolder = async () => {
    const newFolder = await initCategoryCreatorModal();
    if (newFolder) {
      setCategory(newFolder.id);
    }
  };

  const onSetMsps = (msps) => {
    const toSet = msps.length === 0 ? null : msps;
    setMsps(toSet);
  };

  return (
    <Grid>
      <Grid.Col span="auto" miw="60%">
        <Flex direction="column" gap="md">
          <Flex gap="sm" align="center">
            {!!isReportSettings && (
              <WillyDashAvatar
                emoji={emoji}
                setEmoji={setEmoji}
                showSubtext={false}
                canEdit={true}
                title="Update icon"
                dashName={name}
              />
            )}
            {!!loading?.['name'] && <Loader size="sm" />}
            <TextInput placeholder={'Name'} value={name} onChange={setName} w="100%" />
          </Flex>
          {((isReportSettings && isTwGlobalDashboardCreatorClaim) || !isReportSettings) && (
            <Textarea
              placeholder={'Description'}
              value={description}
              onChange={(v) => setDescription(v.target.value)}
              minRows={2}
              autosize
            />
          )}
          {isReportSettings && (
            <Menu
              width="target"
              shadow="md"
              closeOnItemClick={false}
              opened={folderOptionsOpen}
              onChange={setFolderOptionsOpen}
            >
              <Menu.Target>
                <div className="cursor-pointer">
                  <div className="pointer-events-none	">
                    <Select
                      value={category}
                      placeholder={category.length ? category : 'Folder'}
                      data={customCategories.map((c) => ({ label: c.value, value: c.id }))}
                    />
                  </div>
                </div>
              </Menu.Target>
              <Menu.Dropdown>
                <Menu.Label pos="sticky" p="md">
                  <TextInput
                    value={folderSearch}
                    onChange={setFolderSearch}
                    leadingIcon="search-major"
                    placeholder="Search Folders"
                  />
                </Menu.Label>
                <Box mah={200} overflow="auto" px="md">
                  {filteredCategories.map((item, index) => (
                    <Menu.Item
                      key={index}
                      onClick={() => {
                        setCategory(item.id);
                        setFolderOptionsOpen(false);
                      }}
                    >
                      {item.value}
                    </Menu.Item>
                  ))}
                </Box>
                <Menu.Divider my="sm" />
                <Box px="sm" pb="sm">
                  <Menu.Item
                    leftSection={<Icon name="union" size={14} color="one.4" />}
                    onClick={onCreateNewFolder}
                    color="one.4"
                  >
                    Create New Folder
                  </Menu.Item>
                </Box>
              </Menu.Dropdown>
            </Menu>
          )}
          {isTwGlobalDashboardCreatorClaim && isGlobal && (
            <MultiSelect
              placeholder={'Roles'}
              value={roles}
              onChange={(v) => {
                setRoles(v ?? []);
              }}
              data={rolesOptions.map((r) => ({ label: r.name, value: r.id }))}
              searchable
            />
          )}
          {isTwGlobalDashboardCreatorClaim && isGlobal && (
            <Checkbox label="Beta Template" checked={isBeta} onChange={setIsBeta} />
          )}

          {isTwGlobalDashboardCreatorClaim && isGlobal && (
            <Checkbox label="Hide From Templates List" checked={isHide} onChange={setIsHide} />
          )}

          {isTwGlobalDashboardCreatorClaim && (
            <Select
              label="Dialect"
              data={[
                { label: 'BigQuery', value: 'bigquery' },
                { label: 'ClickHouse', value: 'clickhouse' },
                ...(!isGlobal ? [{ label: 'Both', value: 'both' }] : []),
              ]}
              value={dialect}
              onChange={(value) => setDialect(value as Dialect)}
            />
          )}

          {isGlobal && isTwGlobalDashboardCreatorClaim && (
            <>
              <MultiSelect
                comboboxProps={{ withinPortal: false }}
                value={featureFlagConfigs}
                onChange={(val) => {
                  setFeatureFlagConfigs(val as FeatureFlagConfigKey[]);
                }}
                data={ffPackageNames}
                clearable
                label="Plans"
                placeholder="Plans that have access to this dashboard"
              />
              <MultiSelect
                comboboxProps={{ withinPortal: false }}
                value={featureFlagDefaultCopyConfigs}
                onChange={(val) => {
                  setFeatureFlagDefaultCopyConfigs(val as FeatureFlagConfigKey[]);
                }}
                data={ffPackageNames}
                clearable
                label="Default Copy Per Plan"
                placeholder="Will be copied to the shop when shop is created"
              />
              <MultiSelect
                searchable
                comboboxProps={{ withinPortal: false }}
                value={providers}
                onChange={(val) => {
                  setProviders(val as ServicesIds[]);
                }}
                data={Object.values(services)
                  .filter((x) => x.providerType !== 'none')
                  .map((s) => ({ label: s.name, value: s.id }))}
                clearable
                label="Providers"
                placeholder="Providers that have access to this dashboard..."
              />
              <Select
                allowDeselect={false}
                comboboxProps={{ withinPortal: false }}
                value={providersBlockingCombination}
                onChange={(val) => {
                  setProvidersBlockingCombination(val as any);
                }}
                data={[
                  { label: 'Block if one from the list is not connected', value: 'AND' },
                  {
                    label: 'Block if all from the list are not connected',
                    value: 'OR',
                  },
                ]}
                clearable
                label="Providers Blocking Rule"
              />
              <MultiSelect
                comboboxProps={{ withinPortal: false }}
                value={msps ?? []}
                onChange={(val) => {
                  onSetMsps(val);
                }}
                data={Object.values(SALES_PLATFORMS).map((x) => {
                  return { value: x.id, label: x.title };
                })}
                clearable
                label="MSPs"
                description="If empty dashboard will be available for all MSPs"
              />
              <TextInput
                required
                label="Credits"
                type="number"
                value={credits}
                onChange={(v) => setCredits(Number(v))}
              />
            </>
          )}
        </Flex>
      </Grid.Col>
      {isReportSettings && isTwGlobalDashboardCreatorClaim && (
        <Grid.Col span="content">{DropZ}</Grid.Col>
      )}
    </Grid>
  );
};
