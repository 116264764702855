import { WillyFieldTypes } from './types/willyTypes';
import { WillySectionHeader } from './WillySectionHeader';
import { TrixEditor } from 'react-trix';
import { useDashContext } from './dashContext';
import WillyDropZone from './WillyDropZone';
import { useComputedValue, useStoreValue } from '@tw/snipestate';

type WillySectionProps = {
  id: string;
  title: string;
  type: WillyFieldTypes;
  editingFields: string[];
  content: string;
  localFiles: File[];
  titleChanged: (id: string, title: string) => Promise<void>;
  localFilesChanged: (files: File[]) => Promise<void>;
  filesLoaded: (fieldId: string, files: File[]) => Promise<void>;
  contentChanged: (id: string, content: string, updatedAt) => Promise<void>;
  editingFieldsChanged: (fields: string[]) => Promise<void>;
  fieldRemoved: (id: string) => Promise<void>;
  isDnd: boolean;
  secondaryToolbarOpen?: boolean;
  editLayout?: boolean;
  inMobileDrawer?: boolean;
};

export const WillySection: React.FC<WillySectionProps> = ({
  id,
  editingFields,
  type,
  title,
  content,
  localFiles,
  localFilesChanged,
  contentChanged,
  titleChanged,
  editingFieldsChanged,
  fieldRemoved,
  filesLoaded,
  isDnd,
  secondaryToolbarOpen,
  editLayout,
  inMobileDrawer,
}) => {
  const { $images, $dashboard } = useDashContext();
  const images = useStoreValue($images);
  // NOTE: Using these derived stores instead of the $canEdit and $isDnd stores
  // that are in the context already, because those stores represent the permissions
  // of the original dashboard in the combinedDashboards map instead of the current dashboard
  const canEdit = useComputedValue($dashboard, (d) => !!d?.canEdit);
  const isDashboardDnd = useComputedValue($dashboard, (d) => !!d?.isDnd);

  return (
    <>
      <WillySectionHeader
        id={id}
        editingFields={editingFields}
        type={type}
        canEdit={canEdit}
        title={title}
        titleChanged={titleChanged}
        editingFieldChanged={editingFieldsChanged}
        secondaryToolbarOpen={secondaryToolbarOpen}
        fieldRemoved={fieldRemoved}
        isDnd={isDnd}
        editLayout={editLayout}
        inMobileDrawer={inMobileDrawer}
      />
      {type !== 'image' && (
        <div className="w-full h-auto p-4 overflow-x-hidden bg-white dark:bg-slate-800 flex flex-col flex-grow">
          {editingFields.includes(id) ? (
            <TrixEditor
              key={id}
              className="w-full h-full"
              autoFocus
              value={content}
              onChange={(val) => {
                const v = val.trim();
                if (v.length > 0) {
                  contentChanged(id, v, new Date().toISOString());
                }
              }}
              mergeTags={[]}
            />
          ) : (
            <div className="prose dark:prose-invert max-w-full">
              <div dangerouslySetInnerHTML={{ __html: content }} />
            </div>
          )}
        </div>
      )}
      {type === 'image' && (
        <>
          {(editingFields.includes(id) || content?.length === 0 || !images[id]) && canEdit && (
            <div className="w-full h-full p-4 overflow-x-hidden bg-white dark:bg-slate-800 flex flex-col">
              <div className="w-full">
                <h3>{content?.length > 0 && images[id] ? 'Update' : 'Add'} image</h3>
                <WillyDropZone
                  files={localFiles}
                  setFiles={localFilesChanged}
                  onDrop={(files) => filesLoaded(id, files)}
                />
              </div>
            </div>
          )}
          {(content?.length > 0 && images[id] && (
            <div className="w-full h-full overflow-x-hidden bg-white dark:bg-slate-800 flex flex-col">
              <div
                style={{ background: `url(${images[id]}) no-repeat` }}
                className={`block w-full h-full !bg-center pb-[100%] ${
                  isDashboardDnd ? '!bg-contain sm:pb-[30vh]' : '!bg-cover sm:pb-0'
                }`}
              ></div>
            </div>
          )) || (
            <div className="w-full h-full overflow-hidden bg-white dark:bg-slate-800 p-4">
              No Image, yet..
            </div>
          )}
        </>
      )}
    </>
  );
};
