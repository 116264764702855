import { logUgradeEvent } from '$stores/willy/$upgradePopupManager';
import { subscriptionTypes } from '@tw/types/module/services/subscription-manager';
import { getPlans } from 'ducks/subscription';
import { useAppDispatch } from 'index';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { RootState } from 'reducers/RootType';
import axiosInstance from 'utils/axiosInstance';
import { useStoreValue } from '@tw/snipestate';
import { $plans } from '../../../$stores/willy/$subscription';
import { analyticsEvents, upgradeCtaActions } from 'utils/dataLayer';

const PREMIUM_PLUS_PRODUCT_ID = 'prod_QcV7Vc3JDzXs3Y';

export const useStartFreeTrial = (source) => {
  const plans = useStoreValue($plans);
  const subscription = useSelector((state: RootState) => state.subscription);
  const shop = useSelector((state: RootState) => state.currentShopId);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (plans?.length) {
      setLoading(false);
    }
  }, [plans]);

  const startFreeTrial = async () => {
    logUgradeEvent(source === 'initial' ? 'general' : source, upgradeCtaActions.START_FREE_TRIAL);

    if (!plans) return;
    const enterpriseItem = subscription.items.find(
      (item) => item.product_id === PREMIUM_PLUS_PRODUCT_ID,
    );
    if (enterpriseItem) {
      toast.error(
        `Shop currently has Enterprise plan ${
          enterpriseItem?.plan_preview_start_date ? 'preview' : ''
        }`,
      );
      return;
    }
    if (subscription.items.some((item) => item.plan_preview_start_date)) {
      toast.error('Shop currently has a plan preview');
      return;
    }
    if (
      subscription.shop_items_history.find((item) => item.product_id === PREMIUM_PLUS_PRODUCT_ID)
    ) {
      toast.error('Shop already had Enterprise plan preview');
      return;
    }
    try {
      setLoading(true);
      const prices = plans
        ?.filter(
          (plan) =>
            plan.product_id === PREMIUM_PLUS_PRODUCT_ID &&
            plan.revenue_id == subscription.revenue &&
            plan.contract_type === subscriptionTypes['12 Month Contract'],
        )
        .map((plan) => plan.price_id);
      if (!prices || prices.length === 0) {
        toast.error(`No package found for ${shop} in their revenue tier`);
        return;
      }
      await axiosInstance.post(`/v2/subscription-manager/subscriptions/add-plan-preview`, {
        shopId: subscription.shop,
        start: moment().startOf('day').format('YYYY-MM-DD'),
        end: moment().add(30, 'days').endOf('day').format('YYYY-MM-DD'),
        prices,
      });
      toast.success('Plan preview started successfully');
      window.location.reload();
    } catch (error) {
      console.error('start free trial error', error);
      toast.error('Failed to start plan preview');
    } finally {
      setLoading(false);
    }
  };
  return { startFreeTrial, loading };
};
