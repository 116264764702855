import { useCallback, useState, useEffect, useRef } from 'react';
import { connect, useSelector } from 'react-redux';
import { Card, Popover, ActionList, Spinner, Text, Icon } from '@shopify/polaris';
import {
  DeleteMinor,
  EditMinor,
  MobileVerticalDotsMajor,
  EmailMajor,
  CircleAlertMajor,
} from '@shopify/polaris-icons';
import {
  createReport as createReportAction,
  reportOnEditSubmit,
  removeReport,
  tryItNow,
  createReport,
} from 'ducks/reports';

import { hidePrompt, showPrompt } from 'ducks/actions';
import { groupBy } from 'lodash';
import { selectAllMetrics, selectReportPixelConnectedServices } from 'utils/selectors';

import { amPm, FREQUENCY } from './utils';
import { RecipientsList } from './RecipientsList';
import { ReportForm } from './ReportForm';

import './ReportsSection.scss';
import SlackIconBase64 from 'components/SlackIconBase64';
import { FeatureFlag } from '@tw/feature-flag-system/module/types';
import { computeFeatureFlags } from 'feature-flag-system';
import { UpgradePageFallBack } from 'feature-flag-system/components';
import { Button } from 'components/BaseComponents/Button';
import { RootState } from 'reducers/RootType';
import { slackConnectOnPress } from '../../ducks/slack';
import { useIsSmall } from 'hooks/useDefaultWindowSizes';
import { useAppDispatch } from '../../index';
import { $shopCustomViewAndDefaultDashboards } from '$stores/willy/$customViews';
import { useStoreValue } from '@tw/snipestate';

export const ReportsComponent = (props) => {
  const { isFromAction = false, reportId = null, actionType = null, actionData = null } = props;

  const dispatch = useAppDispatch();

  const isSlackConnected = useSelector((state: RootState) => state.isSlackConnected);
  const loadingReports = useSelector((state: RootState) => state.loadingReports);
  const reports = useSelector((state: RootState) => state.reports);

  const isSmall = useIsSmall();

  const urlParams = new URLSearchParams(window.location.search);
  const create = urlParams.get('create') || '';

  const [isNewReportOpen, setNewReportIsOpen] = useState(actionType === 'create');

  useEffect(() => {
    if (!!create) {
      setNewReportIsOpen(true);
    }
  }, [create]);
  const newReportOnPress = useCallback(
    () => setNewReportIsOpen(!isNewReportOpen),
    [isNewReportOpen],
  );

  return (
    <section className={`reports-sections ${isNewReportOpen ? '' : 'py-[5%] px-[10%]'}`}>
      {isNewReportOpen ? (
        <div className="mt-[2rem]">
          <ReportForm
            onSubmit={(data) => {
              setNewReportIsOpen(false);
              dispatch(createReport(data));
            }}
            actionData={actionData}
            isFromAction={isFromAction}
            onCancel={() => setNewReportIsOpen(false)}
            onTryItNow={(r) => dispatch(tryItNow(r))}
            isSlackConnected={isSlackConnected}
          />
        </div>
      ) : (
        <>
          {!isFromAction && (
            <div className="mb-[30px] lg:mb-[50px]">
              <div className="text-[20px] mb-[8px] font-semibold">Reports</div>
              <div className="flex justify-between">
                <div className="text-[14px]	leading-loose">
                  Regular, custom updates direct to your inbox
                </div>
                <div className="flex flex-wrap items-center gap-4">
                  {!isSlackConnected && (
                    <button
                      className="bg-[#1877F2] hover:bg-[#156BD9] text-white px-[24px] py-[8px] rounded-[4px] border-none cursor-pointer"
                      onClick={slackConnectOnPress}
                    >
                      Connect to Slack
                    </button>
                  )}
                  <button
                    className="bg-[#1877F2] hover:bg-[#156BD9] text-white sm:px-[10px] lg:px-[24px] py-[8px] min-w-[75px] rounded-[4px] border-none cursor-pointer"
                    onClick={newReportOnPress}
                  >
                    + Create {!isSmall && 'new report'}
                  </button>
                </div>
              </div>
            </div>
          )}
          {loadingReports && <Spinner />}
          {!loadingReports &&
            reports
              .filter((x) => (reportId ? x.id === reportId : true))
              .map((data, i) => (
                <Report
                  key={i}
                  data={data}
                  onDeleteReport={(report) => {
                    dispatch(
                      showPrompt({
                        title: 'Remove Report: ' + report.title + '?',
                        primaryAction: {
                          content: 'Confirm',
                          onAction: () => {
                            dispatch(hidePrompt());
                            dispatch(removeReport(report));
                          },
                        },
                      }),
                    );
                  }}
                  onEditSubmit={(r) => dispatch(reportOnEditSubmit(r))}
                  onTryItNow={(r) => dispatch(tryItNow(r))}
                  actionType={actionType}
                  isSlackConnected={isSlackConnected}
                />
              ))}
        </>
      )}
    </section>
  );
};

const Report = ({
  data,
  onDeleteReport,
  onEditSubmit,
  onTryItNow,
  isSlackConnected,
  actionType = null,
}) => {
  const [settingsIsOpen, setSettingsIsOpen] = useState(false);
  const settingsOnPress = useCallback(() => setSettingsIsOpen(!settingsIsOpen), [settingsIsOpen]);

  const _onDeleteReport = () => {
    settingsOnPress();
    onDeleteReport(data);
  };
  const [isEditing, setIsEditing] = useState(actionType === 'edit');
  const _editOnPress = () => {
    settingsOnPress();
    setIsEditing(!isEditing);
  };

  const _onEditSubmit = (val) => {
    setIsEditing(!isEditing);
    onEditSubmit(val);
  };

  return isEditing ? (
    <Card>
      <ReportForm
        item={data}
        isEditing={isEditing}
        onCancel={() => setIsEditing(false)}
        onSubmit={_onEditSubmit}
        onTryItNow={onTryItNow}
        isSlackConnected={isSlackConnected}
      />
    </Card>
  ) : (
    <ReportDisplay
      data={data}
      actionType={actionType}
      settingsIsOpen={settingsIsOpen}
      settingsOnPress={settingsOnPress}
      _removeOnPress={_onDeleteReport}
      _editOnPress={_editOnPress}
    />
  );
};

const ReportTitle = (props) => {
  const { data } = props;
  const Icons = () => (
    <>
      {[
        {
          show: data.isSendToEmail,
          icon: <Icon source={EmailMajor} color="subdued" />,
        },
        { show: data.isSendToSlack, icon: <SlackIconBase64 small={false} /> },
        { show: !!data.creating && !data.error, icon: <Spinner size="small" /> },
        {
          show: !!data.creating && data.error,
          icon: <Icon source={CircleAlertMajor} color="warning" />,
        },
      ]
        .filter((i) => i.show)
        .map((i, index) => (
          <div key={index} className="ml-[10px]">
            {i.icon}
          </div>
        ))}
    </>
  );
  return (
    <div className="flex items-center">
      <Text as="h2" variant="headingLg">
        {data.title || '-'}
      </Text>
      <Icons />
    </div>
  );
};

const ReportDisplay = ({
  data,
  settingsIsOpen,
  settingsOnPress,
  _removeOnPress,
  _editOnPress,
  actionType,
}) => {
  const allDashboards = useStoreValue($shopCustomViewAndDefaultDashboards);
  const [isMetricsExpanded, setIsMetricsExpanded] = useState(false);
  const [isMetricsTruncated, setIsMetricsTruncated] = useState(false);
  const metricsWrapperRef = useRef(null);
  useEffect(() => {
    if (metricsWrapperRef.current) {
      const { offsetHeight, scrollHeight } = metricsWrapperRef.current;
      setIsMetricsTruncated(offsetHeight < scrollHeight);
    }
  }, [data.metrics, metricsWrapperRef]);

  const getWillyDashboardName = useCallback(
    (dash) => {
      const dashboard = allDashboards.find((d) => d.id || d === (dash?.id || dash));
      return dashboard ? dashboard.name ?? dash.id ?? '' : '';
    },
    [allDashboards],
  );

  return (
    <Card
      sectioned
      key={data.title}
      title={<ReportTitle data={data} />}
      subdued={true}
      actions={[
        ...(actionType !== 'delete'
          ? [
              {
                content: (
                  <Popover
                    active={settingsIsOpen}
                    onClose={settingsOnPress}
                    activator={
                      <MobileVerticalDotsMajor onClick={settingsOnPress} className="w-8 h-8" />
                    }
                  >
                    <ActionList
                      items={
                        [
                          {
                            content: 'Edit',
                            icon: EditMinor,
                            onAction: _editOnPress,
                          },
                          {
                            content: 'Remove',
                            icon: DeleteMinor,
                            onAction: _removeOnPress,
                          },
                        ].filter(Boolean) as any
                      }
                    />
                  </Popover>
                ) as any,
              },
            ]
          : []),
        ...(actionType === 'delete'
          ? [
              {
                content: <Button icon={DeleteMinor} outline onClick={_removeOnPress} />,
              },
            ]
          : []),
      ]}
    >
      <hr className="h-px mb-[15px] bg-[#c1c1c1] border-0" />
      <div className="lg:inline-flex justify-between w-full">
        <div className="flex-[3] mb-[12px] lg:mb-0">
          <div className="opacity-60 mb-[2px]">Store:</div>
          <div>{data.shopId || data.shopDomain}</div>
        </div>
        <div className="flex-[2] mb-[12px] lg:mb-0">
          <div className="opacity-60 mb-[2px]">Frequency:</div>
          <div>
            {FREQUENCY.find((f) => f.value === data.frequency)?.label}
            {data.frequency !== 'hour' && <span>, {amPm(data.hours[0])}</span>}
          </div>
        </div>
        {!!data.recipients?.length && (
          <div className="flex-[5]">
            <div className="opacity-60 mb-[2px]">Recipients:</div>
            <RecipientsList emails={data.recipients} />
          </div>
        )}
      </div>
      <div className="mt-[10px]">
        {!isMetricsExpanded && (
          <input
            type="checkbox"
            id={`expand#${data.id}`}
            className="hidden"
            onChange={() => setIsMetricsExpanded(true)}
          />
        )}
        <div
          ref={metricsWrapperRef}
          style={
            isMetricsExpanded
              ? {}
              : {
                  overflow: 'hidden',
                  display: '-webkit-box',
                  WebkitLineClamp: 2,
                  WebkitBoxOrient: 'vertical',
                }
          }
        >
          {data?.metrics?.length > 0 && (
            <div>
              <span className="font-bold">Summary: </span>
              <InlineMetricTags tags={data.metrics} />
            </div>
          )}
          {data?.dashboards?.length > 0 && (
            <div>
              <span className="font-bold">Reports: </span>
              {data.dashboards?.map((dash, index) => {
                return (
                  <span key={index}>
                    {index > 0 ? ' ' : ''}
                    {getWillyDashboardName(dash.id ?? dash)}
                    {data.dashboards.length > 1 && index !== data.dashboards.length - 1 ? ',' : ''}
                  </span>
                );
              })}
            </div>
          )}
          {/* {data.willyDashId ? (
            <span>{getWillyDashboardName(data.willyDashId)} Dashboard</span>
          ) : (

          )} */}
        </div>
        {isMetricsTruncated && !isMetricsExpanded && (
          <label
            htmlFor={`expand#${data.id}`}
            role="button"
            className="text-[#1877F2] cursor-pointer"
          >
            see more
          </label>
        )}
      </div>
    </Card>
  );
};

export const InlineMetricTags = ({ tags }): any => {
  const allMetrics = useSelector(selectAllMetrics);
  const pixelMetrics = useSelector(selectReportPixelConnectedServices);
  if (!tags) return null;
  const groupedTags = groupBy(tags, (tag) => {
    return tag.startsWith('pixel#') ? 'pixel' : 'other';
  });
  const selectedMetrics = groupedTags.other?.map((id) => allMetrics.find((m) => m.id === id));
  const selectedPixelMetrics: any = groupedTags.pixel?.map((tag) => {
    const [, metricServiceId, metricKey] = tag.split('#');
    const serviceData = pixelMetrics?.services.find((s) => s.id === metricServiceId);
    const pixelMetricsData = serviceData?.pixelMetrics?.find((m) => m.key === metricKey);
    return { title: `Pixel ${serviceData?.title || ''} ${pixelMetricsData?.shortLabel || ''}` };
  });
  const allSelectedMetrics = (selectedMetrics || []).concat(selectedPixelMetrics).filter((m) => m);
  return allSelectedMetrics.map((metric, i) => (
    <span key={metric?.title || ''}>
      {metric?.title || ''}
      {i !== allSelectedMetrics.length - 1 ? ', ' : ''}
    </span>
  ));
};
export default computeFeatureFlags(FeatureFlag.EMAIL_SLACK_REPORT_FF, ReportsComponent, () => (
  <div className="relative top-[10vh]">
    <UpgradePageFallBack
      InAppContextBannerEnabled={false}
      title="Reports"
      description="Daily, weekly, or monthly reports"
      featureFlag={FeatureFlag.EXPORT_TO_SHEETS_FF}
    />
  </div>
));
