import { $derived, $observer, $store } from '@tw/snipestate';
import { $globalConfig } from '../$globalConfig';
import moment from '@tw/moment-cached';
import { $ffStore } from 'feature-flag-system';
import { FeatureFlag } from '@tw/feature-flag-system/module/types';
import { $shop } from '../$shop';
import axiosInstance from '../../utils/axiosInstance';
import { $userId } from '../$user';

export const $shopSubscription = $store<any>();

export const $isPlanPreview = $derived((get) => {
  const subscription = get($shopSubscription);
  return !!subscription?.items?.some((item) => item.plan_preview_end_date);
});

export const $currentPlanName = $derived((get) => {
  const subscription = get($shopSubscription);
  const foundProductName: string | undefined = subscription?.items?.find(
    (item) => item.product_type === 'package',
  )?.product_name;
  return foundProductName || null;
});

export const $currentPlanPreviewPlanName = $derived((get) => {
  const subscription = get($shopSubscription);
  const planPreviewName: string | undefined = subscription?.items?.find(
    (item) => item.plan_preview_end_date,
  )?.product_name;
  return planPreviewName || null;
});

export const $isStartShowPlanPreviewCTA = $derived((get) => {
  const subscription = get($shopSubscription);
  const planPreviewItem = subscription?.items?.find((item) => item.plan_preview_end_date);
  const globalTW3Config = get($globalConfig).find((item) => item.id === 'tw3');
  const ffComputer = get($ffStore);
  if (!ffComputer.isReady || !!planPreviewItem) return false;

  const isPlusPackage = ffComputer.getConfigById(FeatureFlag.CHAT_DATA_FF); //temp indication that user already have chat data enabled means that he is on plus

  if (isPlusPackage.result) return false;
  if (!globalTW3Config?.planPreviewStartNotifyAfter) return false;

  const planPreviewStartNotifyAfterDays = globalTW3Config.planPreviewStartNotifyAfter;
  const subCreated = subscription?.created_at;
  const today = moment();
  const diffSubCreated = today.diff(subCreated, 'days');
  return diffSubCreated >= planPreviewStartNotifyAfterDays;
});

export const $planPreviewDayLeft = $derived((get) => {
  const subscription = get($shopSubscription);
  const planPreviewItem = subscription?.items?.find((item) => item.plan_preview_end_date);

  if (planPreviewItem) {
    const planPreviewEndDate = moment(planPreviewItem.plan_preview_end_date);
    const today = moment();
    return planPreviewEndDate.diff(today, 'days');
  }
  return 0;
});

export const $sqlTeamReportCredit = $derived((get) => {
  const globalTW3Config = get($globalConfig).find((item) => item.id === 'tw3');
  return globalTW3Config?.sqlTeamReportCredit ?? 0;
});

export const $isOnPremiumPlusPlan = $derived((get) => {
  const ffComputer = get($ffStore);
  const isPlusPackage = ffComputer.getConfigById(FeatureFlag.CHAT_DATA_FF);
  if (!ffComputer.isReady) return false;
  return isPlusPackage.result;
});

export const $isFreeShop = $derived((get) => {
  return get($currentPlanName) === 'Founders Dash';
});

export const $currentRevenue = $derived((get) => {
  const shop = get($shop);
  return shop?.annualRevenue?.revenue ?? 0;
});

export const $isShopWasOnPlanPreviewPlusBefore = $derived((get) => {
  const subscription = get($shopSubscription);
  return subscription?.shop_items_history?.some(
    (x) => !!x?.plan_preview_end_date && x?.product_name?.includes('Plus'),
  );
});

export const $plans = $observer<any[] | null>([], async (get, set) => {
  const uid = get($userId);
  if (!uid) return set(null);

  const url = `v2/subscription-manager/plans?isNewPricing=true`;
  const { data } = await axiosInstance.get(url);
  set(data);
});

export const $isFullAccessToChat = $derived((get) => {
  const ffComputer = get($ffStore);
  if (!ffComputer.isReady) return false;

  const data = ffComputer.getConfigById(FeatureFlag.CHAT_DATA_FF);
  const code = ffComputer.getConfigById(FeatureFlag.CHAT_CODE_FF);
  const forecasting = ffComputer.getConfigById(FeatureFlag.CHAT_FORECASTING_FF);

  return data.result && code.result && forecasting.result;
});
