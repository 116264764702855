import { useCallback } from 'react';
import { DropZone, Stack, Thumbnail, Text } from '@shopify/polaris';
import { NoteMinor } from '@shopify/polaris-icons';

export const WillyDropZone = ({ files, setFiles, onDrop }) => {
  const DropZoneEl = ({ files, setFiles }) => {
    const handleDropZoneDrop = useCallback(
      (_dropFiles: File[], acceptedFiles: File[], _rejectedFiles: File[]) => {
        setFiles((files) => [...files, ...acceptedFiles]);
        if (onDrop) onDrop(acceptedFiles);
      },
      [setFiles],
    );

    const validImageTypes = ['image/gif', 'image/jpeg', 'image/png'];

    const fileUpload = !files.length && <DropZone.FileUpload />;
    const uploadedFiles = files.length > 0 && (
      <div style={{ padding: '0' }}>
        <Stack vertical>
          {files.map((file, index) => (
            <Stack alignment="center" key={index}>
              <Thumbnail
                size="small"
                alt={file.name}
                source={
                  validImageTypes.includes(file.type) ? window.URL.createObjectURL(file) : NoteMinor
                }
              />
              <div>
                {file.name}{' '}
                <Text variant="bodySm" as="p">
                  {file.size} bytes
                </Text>
              </div>
            </Stack>
          ))}
        </Stack>
      </div>
    );

    return (
      <DropZone allowMultiple={false} onDrop={handleDropZoneDrop}>
        {uploadedFiles}
        {fileUpload}
      </DropZone>
    );
  };

  return <DropZoneEl files={files} setFiles={setFiles} />;
};

export default WillyDropZone;
