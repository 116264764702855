import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { type RootState } from 'reducers/RootType';

import { Button, Modal } from '@shopify/polaris';
import { getCustomSpends, toggleImportCustomSpendIsOpen } from 'ducks/actions';
import {
  createGoogleSheetsReport,
  googleSheetsConnect,
  GoogleSheetsCustomSpendsReportInfo,
  loadGoogleSheetsReports,
} from 'ducks/googleSheets';
import { useAppDispatch } from 'index';
import GoogleSheetsAccountSelector from './GoogleSheetsAccountSelector';
import { Building, Error, GoogleSheetsReport } from './Reports/GoogleSheetsReportsList';
import { RefreshMinor } from '@shopify/polaris-icons';
import moment from 'moment';
import axiosInstance from 'utils/axiosInstance';
import { toast } from 'react-toastify';

export const ImportSpendsFromGoogleSheets = () => {
  const dispatch = useAppDispatch();
  const importCustomSpendsGSModalOpen = useSelector(
    (state: RootState) => state.importCustomSpendsGSModalOpen,
  );
  const isGoogleSheetsConnected = useSelector((state: RootState) => state.isGoogleSheetsConnected);
  const [gsConnectLoading, setGsConnectLoading] = useState<boolean>(false);
  const googleSheetsReports = useSelector((state: RootState) => state.googleSheetsReports);
  const [selectedReport, setSelectedReport] = useState<any>();
  const [sheetsAccount, setSheetsAccount] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const [syncLoading, setSyncLoading] = useState<boolean>(false);

  useEffect(() => {
    if (!googleSheetsReports?.length) {
      dispatch(loadGoogleSheetsReports());
    }
  }, []);

  useEffect(() => {
    if (googleSheetsReports.find((x) => x.reportType === 'customSpends')) {
      setSelectedReport(googleSheetsReports.find((x) => x.reportType === 'customSpends'));
    }
  }, [googleSheetsReports]);

  const syncSheet = async (report) => {
    setSyncLoading(true);
    const url = 'v2/google-sheets/trigger-report-update';
    try {
      await axiosInstance.post(url, {
        shopId: report.shopId,
        reportId: report.id,
        sheetsAccount: report.sheetsAccount,
      });

      setSyncLoading(false);
      dispatch(loadGoogleSheetsReports());
      dispatch(getCustomSpends());
    } catch (e) {
      const message = `Error uploading Custom Spends Google Sheet. ${
        e?.data.includes('5000') ? 'You can only have a maximum of 5000 custom spends' : ''
      }`;
      toast.error(message);
      setSyncLoading(false);
    }
  };

  return (
    <Modal
      title="Import Custom Expenses from Google Sheets"
      open={importCustomSpendsGSModalOpen}
      onClose={() => {
        setSyncLoading(false);
        dispatch(toggleImportCustomSpendIsOpen());
      }}
    >
      <Modal.Section>
        {!isGoogleSheetsConnected ? (
          <div className="mb-[30px] lg:mb-[50px]">
            <Button
              onClick={() => {
                setGsConnectLoading(true);
                googleSheetsConnect();
              }}
              loading={gsConnectLoading}
            >
              Connect to Google Sheets
            </Button>
          </div>
        ) : isGoogleSheetsConnected && selectedReport && selectedReport?.building ? (
          <div className="flex items-center space-between">
            <Building title="Custom Expense sheet" />{' '}
            <Button
              icon={RefreshMinor}
              primary
              onClick={() => {
                dispatch(loadGoogleSheetsReports());
              }}
            />
          </div>
        ) : isGoogleSheetsConnected && selectedReport && selectedReport?.error ? (
          <Error report={selectedReport} />
        ) : isGoogleSheetsConnected && selectedReport ? (
          <div className="flex gap-5 align-items-center">
            <a target="_blank" href={selectedReport?.spreadsheetUrl}>
              Go to Google Sheet
            </a>
            <div>
              Last updated:{' '}
              {moment(selectedReport?.lastUpdated)
                .tz(selectedReport?.timezone)
                .format('MMMM D, Y h:mm A')}
            </div>
            <Button
              loading={syncLoading}
              icon={RefreshMinor}
              onClick={async () => await syncSheet(selectedReport)}
            >
              Sync Now
            </Button>
          </div>
        ) : (
          <>
            <div className="my-[1rem]">
              <GoogleSheetsAccountSelector onChange={setSheetsAccount} value={sheetsAccount} />
            </div>
            <Button
              onClick={() => {
                setLoading(true);
                dispatch(
                  createGoogleSheetsReport({
                    reportType: 'customSpends',
                    title: 'Import Fixed Custom Expenses',
                    frequency: 'day',
                    sheetsAccount,
                  } as GoogleSheetsCustomSpendsReportInfo),
                );
              }}
              disabled={!sheetsAccount}
              loading={loading}
            >
              Create Google Sheet
            </Button>
          </>
        )}
      </Modal.Section>
    </Modal>
  );
};
