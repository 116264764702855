import moment from '@tw/moment-cached';
import { DatePickerTimePeriods } from 'components/useDatePickerSelectedOptions';

export const frequenciesArr = ['hour', 'day', 'week', 'month'];

export type Frequency = (typeof frequenciesArr)[number];

export const FREQUENCY: { label: string; value: Frequency }[] = [
  { label: 'Hourly', value: 'hour' },
  { label: 'Daily', value: 'day' },
  { label: 'Weekly', value: 'week' },
  { label: 'Monthly', value: 'month' },
];

export const amPm = (val) => (val % 12 || 12) + ':00 ' + (val < 12 ? 'AM' : 'PM');

export const dailyHoursOptions = [6, 9, 12, 15, 18];

export const datePickerOptions = [
  {
    start: moment().startOf('day'),
    end: moment().endOf('day'),
    id: DatePickerTimePeriods.TODAY,
    label: 'Today',
  },
  {
    start: moment().subtract(1, 'day').startOf('day'),
    end: moment().subtract(1, 'day').startOf('day'), //.endOf('day') // bug with endOf day
    id: DatePickerTimePeriods.YESTERDAY,
    label: 'Yesterday',
  },
  {
    start: moment().subtract(7, 'days').startOf('day'),
    end: moment().subtract(1, 'day').endOf('day'),
    id: DatePickerTimePeriods.LAST_7_DAYS,
    label: 'Last 7 Days',
  },
  {
    start: moment().subtract(30, 'days').startOf('day'),
    end: moment().subtract(1, 'day').endOf('day'),
    id: DatePickerTimePeriods.LAST_30_DAYS,
    label: 'Last 30 Days',
  },
  {
    start: moment().subtract(90, 'days').startOf('day'),
    end: moment().subtract(1, 'day').endOf('day'),
    id: DatePickerTimePeriods.LAST_90_DAYS,
    label: 'Last 90 Days',
  },
  {
    start: moment().subtract(1, 'year').startOf('day'),
    end: moment().subtract(1, 'day').endOf('day'),
    id: DatePickerTimePeriods.LAST_365_DAYS,
    label: 'Last 365 Days',
  },
  {
    start: moment().subtract(1, 'month').startOf('month'),
    end: moment().subtract(1, 'month').endOf('month'),
    id: DatePickerTimePeriods.LAST_MONTH,
    label: 'Last Month',
  },
  {
    start: moment().startOf('week'),
    end: moment().endOf('day'),
    id: DatePickerTimePeriods.THIS_WEEK,
    label: 'This Week',
  },
  {
    start: moment().startOf('month'),
    end: moment().endOf('day'),
    id: DatePickerTimePeriods.THIS_MONTH,
    label: 'This Month',
  },
  {
    start: moment().startOf('quarter'),
    end: moment().endOf('day'),
    id: DatePickerTimePeriods.THIS_QUARTER,
    label: 'This Quarter',
  },
  {
    start: moment().startOf('year'),
    end: moment().endOf('day'),
    id: DatePickerTimePeriods.THIS_YEAR,
    label: 'This Year',
  },
];
