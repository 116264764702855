import { DOLLAR_TYPE, PERCENT_TYPE, NUMBER_TYPE } from '@tw/types/module/SummaryMetrics';
import { v4 as uuid } from 'uuid';
import { ElementTypes } from 'components/Willy/types/willyTypes';

export const OPTION_METRICS = [
  {
    id: uuid(),
    title: '+',
    value: '+',
    type: ElementTypes.OPERATOR,
  },
  {
    id: uuid(),
    title: '-',
    value: '-',
    type: ElementTypes.OPERATOR,
  },
  {
    id: uuid(),
    title: '/',
    value: '/',
    type: ElementTypes.OPERATOR,
  },
  {
    id: uuid(),
    title: 'X',
    value: '*',
    type: ElementTypes.OPERATOR,
  },
  {
    id: uuid(),
    title: '(',
    value: '(',
    type: ElementTypes.PARENTHESES,
  },
  {
    id: uuid(),
    title: ')',
    value: ')',
    type: ElementTypes.PARENTHESES,
  },
  {
    id: uuid(),
    title: 'Number',
    value: undefined,
    type: ElementTypes.INTEGER,
  },
  {
    id: uuid(),
    title: 'Metric',
    value: undefined,
    type: ElementTypes.METRIC,
  },
];

export const MetricTypeOptions: { label; value }[] = [
  { label: '$', value: DOLLAR_TYPE },
  { label: '%', value: PERCENT_TYPE },
  { label: 'number', value: NUMBER_TYPE },
];
