import { FC, useCallback, useEffect, useState } from 'react';
import {
  ActionList,
  Button,
  Checkbox,
  Icon,
  Modal,
  Popover,
  Stack,
  Tooltip,
} from '@shopify/polaris';
import DatePickerComponent from '../DatePickerComponent';
import { useSelector } from 'react-redux';
import { RootState } from '../../../reducers/RootType';
import {
  applyWorkspaceTimeRange,
  excludeShopsFromWorkspace,
  selectedSearchIdsShopsForWorkspace,
  selectedWorkspaceSelector,
  setPresets,
} from '../../../ducks/auth';
import { CreditCardMajor, DeleteMinor, WandMinor } from '@shopify/polaris-icons';
import { CirclePlusMajor } from '@shopify/polaris-icons';
import { useHistory } from 'react-router-dom';
import { SignUpStep } from '../../auth/SignUp/signUpStep.enum';
import { useAppDispatch } from '../../../index';
import DropDown from 'components/ltv/DropDown';
import { OptionDescriptor } from '@shopify/polaris/build/ts/latest/src/types';
import ReactSwitch from 'react-switch';
import { useDarkMode } from 'dark-mode-control';
import { updateColorScheme } from 'dark-mode-control';
import { analyticsEvents } from 'utils/dataLayer';
import EmojiPicker from 'emoji-picker-react';
import { userDb } from '../../../utils/DB';
import {
  TextInput,
  Icon as TwIcon,
  Autocomplete,
  ActionIcon,
  Box,
  useDisclosure,
  AutocompleteProps,
  AutocompleteItem,
} from '@tw/ui-components';
import { useIsSmall } from 'hooks/useDefaultWindowSizes';
import { setAddToWorkspaceInfo, setIsAgencyPod, setIsFromPodView } from 'ducks/signupJourney';

const defaultPresetOption = { value: -1, label: 'Select Preset' };

interface PodFilterActionsProps {}

const PodFilterActions: FC<PodFilterActionsProps> = () => {
  const history = useHistory();
  const isSmall = useIsSmall();
  const darkMode = useDarkMode();
  const workspaceTimeRange = useSelector((state: RootState) => state.workspaceTimeRange);
  const selectedWorkspace = useSelector(selectedWorkspaceSelector);
  const [shops, setShops] = useState<any>([]);
  const [addPopOverOpen, setAddPopOverOpen] = useState(false);
  const [settingsPopoverActive, setSettingsPopoverActive] = useState(false);
  const [shopsFilterOptions, setShopsFilterOptions] = useState<AutocompleteItem[]>([]);
  const [workspacePresetsOptions, setWorkspacePresetsOptions] = useState<AutocompleteItem[]>([
    defaultPresetOption,
  ] as any);
  const [shopsFilterValues, setShopsFilterValues] = useState<AutocompleteItem[]>([]);
  const [options, setOptions] = useState(shopsFilterOptions);
  const [addPresetOpen, setAddPresetOpen] = useState(false);
  const [presetName, setPresetName] = useState<string>('');
  const [presetSaveLoading, setPresetSaveLoading] = useState(false);
  const [selectedPreset, setSelectedPreset] = useState<any>(-1);
  const [inputValue, setInputValue] = useState('');
  const [autocompleteWide, { toggle: toggleAutoCompleteWide }] = useDisclosure(false);
  const dispatch = useAppDispatch();

  // const urlParams = new URLSearchParams(window.location.search);

  // const nextStep = urlParams.has('hasMspStep')
  //   ? SignUpStep.ChooseSalesPlatform
  //   : SignUpStep.ConnectStore;

  const nextStep = SignUpStep.ConnectStore;

  const addShopClick = useCallback(
    (isFree) => {
      if (isFree) {
        dispatch(setIsFromPodView(true));
        dispatch(setIsAgencyPod(!!selectedWorkspace.isAgency));
        dispatch(
          setAddToWorkspaceInfo({
            workspaceId: selectedWorkspace.id,
            isAdminOnWorkspace: selectedWorkspace?.roles?.includes('admin'),
          }),
        );
      }
      return history.push({
        pathname: `/signup${isFree ? '-free' : ''}`,
        ...(!isFree && {
          state: {
            currentIndex: nextStep,
            workspaceId: selectedWorkspace.id,
            isAdminOnWorkspace: selectedWorkspace?.roles?.includes('admin'),
            isFromAddStore: true,
            isAgency: !!selectedWorkspace.isAgency,
          },
        }),
      });
    },
    [
      dispatch,
      history,
      selectedWorkspace.id,
      selectedWorkspace.isAgency,
      selectedWorkspace?.roles,
      nextStep,
    ],
  );

  const savePreset = useCallback(async () => {
    setPresetSaveLoading(true);
    const currentPresets = selectedWorkspace.presets || [];
    currentPresets.push({ name: presetName, shops: shopsFilterValues.map((x) => x.value) });
    await dispatch(setPresets(currentPresets));
    setPresetSaveLoading(false);
    setAddPresetOpen(false);
    setPresetName('');
  }, [dispatch, presetName, selectedWorkspace.presets, shopsFilterValues]);

  const closePresetModal = useCallback(() => {
    setAddPresetOpen(false);
    setPresetName('');
  }, []);

  const deletePreset = useCallback(
    async (index) => {
      const currentPresets = selectedWorkspace.presets || [];
      currentPresets.splice(index, 1);
      await dispatch(setPresets(currentPresets));
      setSelectedPreset(-1);
    },
    [dispatch, selectedWorkspace.presets],
  );

  const setPresetList = useCallback(
    (index) => {
      const options =
        selectedWorkspace.presets?.map(({ name }, i) => ({
          value: i,
          label:
            index === i ? (
              name
            ) : (
              <div className={'flex justify-between  w-[100%]'}>
                <div>{name}</div>
                <Tooltip content={'Delete Preset'}>
                  <div onClick={(e) => e.stopPropagation()} className={'ml-10 cursor-pointer'}>
                    <Button destructive plain onClick={() => deletePreset(i)} icon={DeleteMinor} />
                  </div>
                </Tooltip>
              </div>
            ),
        })) ?? [];
      setWorkspacePresetsOptions(options);
    },
    [selectedWorkspace.presets, deletePreset],
  );

  const handlePresetChange = useCallback(
    (value) => {
      setSelectedPreset(value);
      setPresetList(value);
    },
    [setPresetList],
  );

  const applyDate = useCallback(
    (date) => {
      dispatch(applyWorkspaceTimeRange(date));
    },
    [dispatch],
  );

  useEffect(() => {
    setShops(selectedWorkspace.shops);
  }, [selectedWorkspace]);

  useEffect(() => {
    if (!shops) return;
    const options = shops?.map(({ shopId }) => ({
      value: shopId,
      label: (shopId as string).split('.myshopify.com')[0],
    }));
    setShopsFilterOptions(options);
    setShopsFilterValues(options);
  }, [shops]);

  useEffect(() => {
    setPresetList(selectedPreset);
  }, [setPresetList, selectedWorkspace, selectedPreset]);

  useEffect(() => {
    if (selectedPreset == -1) return;
    const selectedShops = selectedWorkspace.presets[selectedPreset].shops;
    const selectedShopsOptions = shops
      .filter((x) => selectedShops.some((y) => y === x.shopId))
      .map((x) => x.shopId);
    setShopsFilterValues(
      shopsFilterOptions.filter((x) => selectedShopsOptions.some((y) => y === x.value)),
    );
  }, [dispatch, selectedPreset, selectedWorkspace.presets, shops, shopsFilterOptions]);

  useEffect(() => {
    dispatch(
      excludeShopsFromWorkspace(
        shopsFilterOptions
          .filter((x) => !shopsFilterValues.some((y) => y.value === x.value))
          .map((x) => x.value),
      ),
    );
  }, [shopsFilterValues, shopsFilterOptions, dispatch]);

  const onFilterChanged = useCallback(
    (values: string[]) => {
      setSelectedPreset(-1);
      const selectedShops = shopsFilterOptions.filter((x) => values.some((y) => y === x.value));
      setShopsFilterValues(selectedShops);
    },
    [shopsFilterOptions],
  );

  useEffect(() => {
    setInputValue('');
  }, [selectedWorkspace]);

  useEffect(() => {
    if (inputValue === '') {
      setOptions(shopsFilterOptions);
      dispatch(selectedSearchIdsShopsForWorkspace(shopsFilterOptions.map((x) => x.value)));
      return;
    }

    const filterRegex = new RegExp(inputValue, 'i');
    const resultOptions = shopsFilterOptions.filter((option) =>
      (option.label as string).match(filterRegex),
    );
    dispatch(selectedSearchIdsShopsForWorkspace(resultOptions.map((x) => x.value)));
    setOptions(resultOptions);
  }, [inputValue, shopsFilterOptions, dispatch]);

  const isAllowToAddStore =
    selectedWorkspace?.roles?.includes('owner') ||
    (selectedWorkspace?.roles?.includes('admin') && selectedWorkspace.isAgency);

  return (
    <>
      <div style={{ display: 'none' }} data-testid="PodFilterActions" />
      {!isSmall && (
        <Box miw={200} {...(autocompleteWide && { w: 250 })}>
          <Autocomplete
            radius="xs"
            limit={15}
            maxDropdownHeight={400}
            data={options}
            icon={<TwIcon name="search-major" size={14} />}
            onFocus={toggleAutoCompleteWide}
            onBlur={toggleAutoCompleteWide}
            onChange={setInputValue}
            onOptionSubmit={(value) => {
              // TODO: Double check this change
              const label = options.find((o) => o.value === value)?.label;
              if (!label) return;
              setInputValue(label);
            }}
            value={inputValue}
            aria-label="Search stores..."
            placeholder="Search stores..."
            autoComplete="off"
          />
        </Box>
      )}
      <DatePickerComponent isFromPods={true} dateRange={workspaceTimeRange} onApply={applyDate} />
      <DropDown
        title={
          shopsFilterOptions?.length === shopsFilterValues.length
            ? 'All'
            : `${shopsFilterValues?.length}/${shopsFilterOptions?.length}`
        }
        options={[]}
        sections={[
          {
            title: (
              <div className={'flex justify-between items-center'}>
                <Checkbox
                  label="All"
                  checked={
                    shopsFilterOptions?.length === shopsFilterValues.length
                      ? true
                      : shopsFilterValues.length > 0
                        ? 'indeterminate'
                        : false
                  }
                  onChange={(e) => {
                    if (e) {
                      setShopsFilterValues(shopsFilterOptions);
                    } else {
                      setShopsFilterValues([]);
                    }
                  }}
                />
                <Tooltip content={'Save as preset'}>
                  <div onClick={() => setAddPresetOpen(true)} className={'cursor-pointer'}>
                    <Icon source={CirclePlusMajor} />
                  </div>
                </Tooltip>
              </div>
            ),
            options: shopsFilterOptions,
          },
        ]}
        handleSelect={(e) => {
          onFilterChanged(e);
        }}
        value={shopsFilterValues.map((x) => x.value)}
        allowMultiple={true}
        hideLabel={true}
      />
      {workspacePresetsOptions.length > 0 && (
        <DropDown
          title={selectedPreset === -1 ? '' : 'Preset: '}
          options={[defaultPresetOption, ...workspacePresetsOptions]}
          value={selectedPreset}
          handleSelect={handlePresetChange}
        />
      )}
      {!isSmall && (
        <div className={'flex items-center gap-4 iconsArea'}>
          {isAllowToAddStore && (
            <Tooltip content={'Add Store'}>
              <>
                <Popover
                  zIndexOverride={99999999}
                  activator={
                    <div className="relative top-[2px]">
                      <ActionIcon
                        size="lg"
                        radius="sm"
                        onClick={() => setAddPopOverOpen((x) => !x)}
                        variant="activator"
                        icon="plus-1"
                        iconSize={30}
                      />
                    </div>
                  }
                  active={addPopOverOpen}
                  onClose={() => {
                    setAddPopOverOpen(false);
                  }}
                >
                  <div className={'flex m-5'}>
                    <div className={'m-5'}>
                      <Button icon={CreditCardMajor} onClick={() => addShopClick(false)}>
                        Paid Store
                      </Button>
                    </div>
                    <div className={'m-5'}>
                      <Button icon={WandMinor} onClick={() => addShopClick(true)}>
                        Free Store
                      </Button>
                    </div>
                  </div>
                </Popover>
              </>
            </Tooltip>
          )}
        </div>
      )}
      <Modal
        primaryAction={{
          content: 'Save',
          onAction: () => savePreset(),
          loading: presetSaveLoading,
          disabled: !presetName,
        }}
        open={addPresetOpen}
        title={'Save Preset'}
        onClose={closePresetModal}
      >
        <Modal.Section>
          <TextInput
            placeholder={'My A team'}
            value={presetName}
            label={'Preset Name'}
            onChange={setPresetName}
          />
        </Modal.Section>
      </Modal>
    </>
  );
};

export default PodFilterActions;
