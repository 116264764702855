import { $derived, $store } from '@tw/snipestate';
import { useHistory, useLocation } from 'react-router';
import { useEffect } from 'react';

/** Store containing loction state of react router */
export const $location = $store<ReturnType<typeof useLocation>>({
  search: window.location.search,
  hash: window.location.hash,
  pathname: window.location.pathname,
  state: null,
});

export const $pathname = $derived((get) => get($location).pathname);
export const $search = $derived((get) => get($location).search);

/** Hook used to sync react router location with $location store */
export function useConnectLocation$() {
  const location = useLocation();
  const history = useHistory();

  useEffect(() => {
    // Sync store with initial location
    $location.set(location);

    // Listen for location changes
    const unlisten = history.listen((location, action) => {
      // console.log('location', action, location);
      $location.set(location);
    });

    // Clean up the listener on unmount
    return () => {
      unlisten();
    };
  }, [location, history]);
}
