import React, { Dispatch, SetStateAction, useEffect, useMemo, useState } from 'react';
import { conditionsLevels, ruleBudgetMetrics, ruleOperator, ruleTimes } from './types';
import Select from 'react-select';
import Switch from 'react-switch';
import { Stack, Tooltip, Button } from '@shopify/polaris';
import { DeleteMinor, RiskMinor } from '@shopify/polaris-icons';
import './style.scss';
import { cloneDeep } from 'lodash';
import { useSelector } from 'react-redux';
import { type RootState } from 'reducers/RootType';
import { getConditionsLength } from './helpers';
import { metrics as allMetrics } from '@tw/constants/module/Metrics/allMetrics';
import _ from 'lodash';
import { formatNumber } from 'utils/formatNumber';
import allServices from 'constants/services';

type Props = {
  rule: any;
  setRule: Dispatch<SetStateAction<any>>;
  conditionsLevelsList: any;
  condition: any;
  conditionIndex: number;
  groupIndex: number;
  deleteCondition: (groupIndex: number, conditionIndex: number) => void;
  disabled: boolean;
};

export const RuleCondition: React.FC<Props> = ({
  rule,
  setRule,
  conditionsLevelsList,
  condition,
  conditionIndex,
  groupIndex,
  deleteCondition,
  disabled,
}) => {
  const serviceId = 'facebook-ads';
  const setStyles = (width) => {
    return {
      container: (provided) => ({
        ...provided,
        width: width,
      }),
    };
  };

  const getCurrencySymbol = (currency, format) => {
    return formatNumber(0, {
      style: format,
      currency: currency,
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    })
      .replace(/\d/g, '')
      .trim();
  };

  const [conditionMetricsList, setConditionMetricsList] = useState([{ label: <></>, value: '' }]);
  const ruleMetrics = useMemo(
    () => ({
      ..._.pickBy(
        allMetrics,
        (metric) => metric?.showInRules !== undefined && metric?.showInRules.length > 0,
      ),
      ...ruleBudgetMetrics,
    }),
    [],
  );
  const currency = useSelector((state: RootState) => state.currency);
  const metric = useMemo(() => ruleMetrics[condition['path']], [condition, ruleMetrics]);
  const format = metric?.format || 'string';
  const [prefixForVal, setPrefixForVal] = useState(getCurrencySymbol(currency, format));

  const [conditionFormulaMetricsList, setConditionFormulaMetricsList] = useState([
    { label: <></>, value: '' },
  ]);

  const [showTime, setShowTime] = useState(
    condition['path'] === 'adsetBudget' || condition['path'] === 'campaignBudget' ? false : true,
  );
  const [valueShowTime, setValueShowTime] = useState(
    condition['value']['path'] === 'adsetBudget' || condition['value']['path'] === 'campaignBudget'
      ? false
      : true,
  );

  const timeList = Object.values(ruleTimes).map((ruleTime) => ({
    label: ruleTime.label,
    value: ruleTime.value,
  }));

  useEffect(() => {
    if (ruleMetrics && condition) {
      const metricsList = Object.values(ruleMetrics)
        .filter((metric) => metric.showInRules?.includes(condition['fact']))
        .sort((a, b) => (a.label > b.label ? 1 : -1))
        .map((ruleMetric) => ({
          label: (
            <span className="flex items-center gap-2">
              {ruleMetric.type === 'ads' && allServices[serviceId]?.icon?.({ small: true })}
              {ruleMetric.type === 'pixel' && allServices['pixel']?.icon?.({ small: true })}
              {ruleMetric.label}
            </span>
          ),
          value: ruleMetric.key,
        }));

      const formulaMetricsList = Object.values(ruleMetrics)
        .filter((metric) => metric?.showInRules?.includes(condition['value']['fact']))
        .sort((a, b) => (a.label > b.label ? 1 : -1))
        .map((ruleMetric) => ({
          label: (
            <span className="flex items-center gap-2">
              {ruleMetric.type === 'ads' && allServices[serviceId]?.icon?.({ small: true })}
              {ruleMetric.type === 'pixel' && allServices['pixel']?.icon?.({ small: true })}
              {ruleMetric.label}
            </span>
          ),
          value: ruleMetric.key,
        }));

      setConditionMetricsList(metricsList);
      setConditionFormulaMetricsList(formulaMetricsList);
    }
  }, [ruleMetrics, condition]);

  useEffect(() => {
    setPrefixForVal(formulaMode(condition) ? '' : getCurrencySymbol(currency, format));
    if (condition['path'] === 'adsetBudget' || condition['path'] === 'campaignBudget') {
      setShowTime(false);
    } else {
      setShowTime(true);
    }

    if (
      condition['value']['path'] === 'adsetBudget' ||
      condition['value']['path'] === 'campaignBudget'
    ) {
      setValueShowTime(false);
    } else {
      setValueShowTime(true);
    }
  }, [condition, currency, format]);

  const handleChangeCondition = (groupIndex, conditionIndex, key, val) => {
    const ruleCopy = cloneDeep(rule);
    const conditionCopy = cloneDeep(ruleCopy.conditions.all[groupIndex].any[conditionIndex]);

    conditionCopy[key] = val.value || val;

    if (key === 'fact') {
      conditionCopy.showError = checkCondition('conditionLevel', conditionCopy);
    }
    if (conditionCopy.path) {
      conditionCopy.showMetricError = checkCondition('conditionMetric', conditionCopy);
    }

    if (key === 'operator') {
      conditionCopy.showOperatorError = false;
    }

    if (key === 'params') {
      conditionCopy.showTimeError = false;
    }

    ruleCopy.conditions.all[groupIndex].any[conditionIndex] = conditionCopy;
    setRule(ruleCopy);
  };

  const formulaMode = (condition) => {
    return condition?.['value']?.['params']?.factor !== undefined || condition?.formulaMode;
  };

  const handleChangeConditionValue = (groupIndex, conditionIndex, key, val, formulaKey = '') => {
    let ruleCopy = cloneDeep(rule);
    let condition = ruleCopy.conditions.all[groupIndex].any[conditionIndex];
    if (!formulaMode(condition)) {
      condition[key] = val;
      condition.showValueError = val ? false : 'Required';
      ruleCopy.conditions.all[groupIndex].any[conditionIndex] = condition;
      setRule(ruleCopy);
      return;
    }

    if (formulaKey === '') {
      condition[key]['params'].factor = val;
      condition.showValueError = val ? false : 'Required';

      ruleCopy.conditions.all[groupIndex].any[conditionIndex] = condition;
      setRule(ruleCopy);
      return;
    }

    if (formulaKey === 'params') {
      condition[key]['params'] = {
        ...condition[key]['params'],
        ...val.value,
      };

      if (condition[key]['params']?.today && !val.value.today) {
        condition[key]['params'] = _.omit(condition[key]['params'], 'today');
      }
      condition.showFormulaTimeError = !condition.value?.params?.unit ? 'Required' : false;
      ruleCopy.conditions.all[groupIndex].any[conditionIndex] = condition;
      setRule(ruleCopy);
      return;
    }

    condition[key] = {
      ...condition[key],
      [formulaKey]: val.value,
    };
    if (formulaKey === 'fact') {
      condition.showFormulaError = checkCondition('conditionFormulaLevel', condition);

      condition.showFormulaMetricError = checkCondition('conditionFormulaMetric', condition);
    }

    if (formulaKey === 'path') {
      if (val.value === 'adsetBudget' || val.value === 'campaignBudget') {
        setValueShowTime(false);
        condition.showFormulaTimeError = false;
        condition.value = resetTime(condition.value);
      } else {
        setValueShowTime(true);
      }
      condition.showFormulaMetricError = checkCondition('conditionFormulaMetric', condition);
    }

    ruleCopy.conditions.all[groupIndex].any[conditionIndex] = condition;
    setRule(ruleCopy);
  };

  const handleChangeFormulaMode = (groupIndex, conditionIndex, key, val) => {
    let ruleCopy = cloneDeep(rule);
    let valueObj;
    ruleCopy.conditions.all[groupIndex].any[conditionIndex][key] = val.value || val;
    if (val) {
      valueObj = {
        params: {
          factor: ruleCopy.conditions.all[groupIndex].any[conditionIndex]['value'],
        },
      };
      ruleCopy.conditions.all[groupIndex].any[conditionIndex]['value'] = valueObj;
    } else {
      ruleCopy.conditions.all[groupIndex].any[conditionIndex]['value'] =
        ruleCopy.conditions.all[groupIndex].any[conditionIndex]['value']['params'].factor;
      ruleCopy.conditions.all[groupIndex].any[conditionIndex].showFormulaError = false;
      ruleCopy.conditions.all[groupIndex].any[conditionIndex].showFormulaMetricError = false;
      ruleCopy.conditions.all[groupIndex].any[conditionIndex].showFormulaTimeError = false;
    }

    setRule(ruleCopy);
    setPrefixForVal(condition.formulaMode ? '' : getCurrencySymbol(currency, format));
  };

  const resetTime = (value) => {
    value.params = {
      factor: condition.value?.params?.factor,
    };
    return value;
  };

  const formulaMetricValue = useMemo(() => {
    if (condition['value']['path']?.split('$.')?.[1]) {
      return conditionFormulaMetricsList.find(
        (option) => option.value === condition['value']['path']?.split('$.')?.[1],
      );
    } else {
      return conditionFormulaMetricsList.find(
        (option) => option.value === condition['value']['path'],
      );
    }
  }, [condition, conditionFormulaMetricsList]);

  const metricValue = useMemo(() => {
    if (condition['path']?.split('$.')?.[1]) {
      return (
        conditionMetricsList.find(
          (option) => option.value === condition['path']?.split('$.')?.[1],
        ) || ''
      );
    } else {
      return conditionMetricsList.find((option) => option.value === condition['path']) || '';
    }
  }, [condition, conditionMetricsList]);

  const checkCondition = (field, condition) => {
    let valueToCheck;

    switch (field) {
      case 'conditionLevel':
        valueToCheck = Object.values(conditionsLevels).find(
          (option) => option.value === condition['fact'],
        );
        return !valueToCheck?.show?.includes(rule.level) ? 'Error: level' : false;

      case 'conditionMetric':
        valueToCheck = Object.values(ruleMetrics).find(
          (option) => option.key === condition['path'],
        );
        return !valueToCheck?.showInRules?.includes(condition['fact']) ? 'Error: metric' : false;

      case 'conditionFormulaLevel':
        valueToCheck = Object.values(conditionsLevels).find(
          (option) => option.value === condition['value']['fact'],
        );
        return !valueToCheck?.show?.includes(rule.level) ? 'Error: level' : false;

      case 'conditionFormulaMetric':
        if (condition['value']['path']) {
          valueToCheck = Object.values(ruleMetrics).find(
            (option) => option.key === condition['value']['path'],
          );
          return !valueToCheck?.showInRules?.includes(condition['value']['fact'])
            ? 'Error: metric'
            : false;
        } else {
          return condition.showFormulaMetricError === 'Required' ? 'Required' : false;
        }
      default:
        return false;
    }
  };

  return (
    <div className={formulaMode(condition) ? '' : 'mr-10 ml-5'}>
      <Stack key={`condition-${groupIndex}-${conditionIndex}`} distribution="leading">
        <Stack.Item>
          <Stack distribution="leading">
            <div>
              <Select
                placeholder="Choose a level"
                isDisabled={disabled}
                styles={setStyles(170)}
                minMenuHeight={170}
                options={conditionsLevelsList}
                value={
                  conditionsLevelsList.find((option) => option.value === condition['fact']) ||
                  Object.values(conditionsLevels).find(
                    (option) => option.value === condition['fact'],
                  ) ||
                  ''
                }
                onChange={(val) => handleChangeCondition(groupIndex, conditionIndex, 'fact', val)}
              />
              {condition.showError && (
                <div className="flex items-center text-red-600">
                  <RiskMinor className="flex items-center  fill-red-600" width={16} height={16} />
                  {` ${condition.showError}`}
                </div>
              )}
            </div>
            <div>
              <Select
                isDisabled={disabled}
                styles={setStyles(170)}
                options={conditionMetricsList}
                value={metricValue}
                placeholder="Choose a metric"
                onChange={(val) => handleChangeCondition(groupIndex, conditionIndex, 'path', val)}
              />
              {condition.showMetricError && (
                <div className="flex items-center text-red-600">
                  <RiskMinor className="flex items-center  fill-red-600" width={16} height={16} />
                  {` ${condition.showMetricError}`}
                </div>
              )}
            </div>
            <div>
              {showTime && (
                <Select
                  isDisabled={disabled}
                  styles={setStyles(190)}
                  options={timeList}
                  placeholder="Choose a time"
                  onChange={(val) =>
                    handleChangeCondition(groupIndex, conditionIndex, 'params', val)
                  }
                  value={
                    timeList.find(
                      (option) =>
                        option.value.period === condition['params']?.period &&
                        option.value.unit === condition['params']?.unit &&
                        option.value.today === condition['params']?.today,
                    ) || ''
                  }
                />
              )}
              {condition.showTimeError && (
                <div className="flex items-center text-red-600">
                  <RiskMinor className="flex items-center  fill-red-600" width={16} height={16} />
                  {` ${condition.showTimeError}`}
                </div>
              )}
            </div>

            <div>
              <Select
                isDisabled={disabled}
                styles={setStyles(90)}
                options={ruleOperator}
                placeholder="Math"
                onChange={(val) =>
                  handleChangeCondition(groupIndex, conditionIndex, 'operator', val)
                }
                value={ruleOperator.find((option) => option.value === condition['operator']) || ''}
              />
              {condition.showOperatorError && (
                <div className="flex items-center text-red-600">
                  <RiskMinor className="flex items-center  fill-red-600" width={16} height={16} />
                  {` ${condition.showOperatorError}`}
                </div>
              )}
            </div>
          </Stack>
        </Stack.Item>

        <Stack.Item>
          <Stack>
            <div>
              <div className="inputWrapper">
                <span className={`prefix ${disabled ? 'disabled' : ''}`}>{prefixForVal}</span>
                <input
                  className="w-24"
                  disabled={disabled}
                  placeholder="Enter value"
                  value={
                    formulaMode(condition)
                      ? 'factor' in condition['value']['params']
                        ? String(condition['value']['params']?.factor)
                        : ''
                      : condition.value === 0
                        ? 0
                        : condition.value || ('' as any)
                  }
                  onChange={(ev) =>
                    handleChangeConditionValue(groupIndex, conditionIndex, 'value', ev.target.value)
                  }
                />
              </div>
              {condition.showValueError && (
                <div className="flex items-center text-red-600">
                  <RiskMinor className="flex items-center  fill-red-600" width={16} height={16} />
                  {` ${condition.showValueError}`}
                </div>
              )}
            </div>
            {formulaMode(condition) && (
              <Stack>
                <div className="mt-2.5 ">X</div>
                <div>
                  <Select
                    isDisabled={disabled}
                    styles={setStyles(170)}
                    options={conditionsLevelsList}
                    value={conditionsLevelsList.find(
                      (option) => option.value === condition['value']['fact'],
                    )}
                    placeholder="Choose a level"
                    onChange={(val) =>
                      handleChangeConditionValue(groupIndex, conditionIndex, 'value', val, 'fact')
                    }
                  />
                  {condition.showFormulaError && (
                    <div className="flex items-center text-red-600">
                      <RiskMinor
                        className="flex items-center  fill-red-600"
                        width={16}
                        height={16}
                      />
                      {` ${condition.showFormulaError}`}
                    </div>
                  )}
                </div>

                <div>
                  <Select
                    isDisabled={disabled}
                    styles={setStyles(170)}
                    options={conditionFormulaMetricsList}
                    value={formulaMetricValue}
                    placeholder="Choose a metric"
                    onChange={(val) =>
                      handleChangeConditionValue(groupIndex, conditionIndex, 'value', val, 'path')
                    }
                  />
                  {condition.showFormulaMetricError && (
                    <div className="flex items-center text-red-600">
                      <RiskMinor
                        className="flex items-center  fill-red-600"
                        width={16}
                        height={16}
                      />
                      {` ${condition.showFormulaMetricError}`}
                    </div>
                  )}
                </div>

                {valueShowTime && (
                  <div className="ml-2">
                    <Select
                      isDisabled={disabled}
                      styles={setStyles(170)}
                      options={timeList}
                      placeholder="Choose a time"
                      onChange={(val) =>
                        handleChangeConditionValue(
                          groupIndex,
                          conditionIndex,
                          'value',
                          val,
                          'params',
                        )
                      }
                      value={
                        timeList.find(
                          (option) =>
                            option.value.period === condition['value']['params']?.period &&
                            option.value.unit === condition['value']['params']?.unit &&
                            option.value.today === condition['value']['params']?.today,
                        ) || ''
                      }
                    />
                    {condition.showFormulaTimeError && (
                      <div className="flex items-center text-red-600">
                        <RiskMinor
                          className="flex items-center  fill-red-600"
                          width={16}
                          height={16}
                        />
                        {` ${condition.showFormulaTimeError}`}
                      </div>
                    )}
                  </div>
                )}
              </Stack>
            )}
          </Stack>
        </Stack.Item>

        {!disabled && (
          <div className={formulaMode(condition) ? 'mt-4 mr-40' : 'mt-4 '}>
            <Tooltip content={'Remove Condition'}>
              <Button
                plain
                onClick={() => deleteCondition(groupIndex, conditionIndex)}
                disabled={getConditionsLength(rule?.conditions) <= 1 || disabled}
                icon={DeleteMinor}
              />
            </Tooltip>
          </div>
        )}
      </Stack>
      <Stack>
        <div className="mb-8 mt-8 flex items-center gap-4">
          <Stack.Item>
            <Tooltip
              content={
                condition['value']['params']?.factor
                  ? 'Disabling function replaces the absolute value with a metric function'
                  : 'Enabling function replaces the absolute value with a metric function'
              }
            >
              <Switch
                disabled={disabled}
                checkedIcon={false}
                boxShadow="0px 1px 5px rgba(0, 0, 0, 0.2)"
                activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.6)"
                height={25}
                width={60}
                handleDiameter={19}
                checked={formulaMode(condition) ?? false}
                onChange={(val) =>
                  handleChangeFormulaMode(groupIndex, conditionIndex, 'formulaMode', val)
                }
                onColor={'#062940'}
              />
            </Tooltip>
          </Stack.Item>
          <span>{formulaMode(condition) ? 'Disable Formula Mode' : 'Enable Formula Mode'}</span>
        </div>
      </Stack>
      <Stack alignment="center" distribution="equalSpacing">
        {rule.conditions.all[groupIndex].any.length > 1 &&
          conditionIndex < rule.conditions.all[groupIndex].any.length - 1 && <p>OR</p>}
      </Stack>
    </div>
  );
};
