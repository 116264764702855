import { useAppSelector } from 'reducers/RootType';
import {
  DayOfWeek,
  ScheduleItem,
  ScheduleRulesRow,
  ScheduleTrigger,
  ScheduleType,
  TemporalScheduleInfo,
} from './types/willyTypes';
import { $shopSequences, $sequencesSchedules } from '$stores/willy/$sequences';
import { $combinedDashboard } from '$stores/willy/$combinedDashboards';
import { useCallback, useEffect, useMemo, useState } from 'react';
import {
  ActionIcon,
  Button,
  Checkbox,
  confirm,
  Modal,
  MultiSelect,
  Select,
  Text,
  TextInput,
} from '@tw/ui-components';
import { FormLayout } from '@shopify/polaris';
import { DashboardsDropDown } from './DashboardsDropDown';
import {
  createSchedule,
  deleteSchedule,
  executeSequence,
  getScheduleInfo,
  pauseSchedule,
  resumeSchedule,
} from './utils/sequenceSchedules';
import { toast } from 'react-toastify';
import moment from 'moment-timezone';
import { WillyFormGroup } from './WillyFormGroup';
import { $allRules, $willyRulePopup } from './WillyRules/$stores';
import { Link } from 'react-router-dom';
import { useLocation } from 'react-router';
import { analyticsEvents, genericEventLogger, sequencesActions } from 'utils/dataLayer';
import { useStoreValue } from '@tw/snipestate';
import { WillyRulePopup } from './WillyRules/WillyRulePopup';
import { createNewRule } from './WillyRules/utils';

type WillyScheduleSequenceParams = {
  open?: boolean;
  onClose?: () => void;
  scheduleSequenceId: string;
  schedule?: ScheduleItem;
};

export const WillyScheduleSequence: React.FC<WillyScheduleSequenceParams> = (props) => {
  const { onClose, schedule, scheduleSequenceId } = props;

  const currentShopId = useAppSelector((state) => state.currentShopId);
  const user = useAppSelector((state) => state.user);
  const timezone = useAppSelector((state) => state.shopTimezone);
  const currency = useAppSelector((state) => state.activeCurrency);

  const { search } = useLocation();

  const schedules = useStoreValue($sequencesSchedules);
  const dashboards = useStoreValue($combinedDashboard);
  const allRules = useStoreValue($allRules);

  const [scheduleDays, setScheduleDays] = useState<DayOfWeek[]>(['MONDAY', 'THURSDAY']);
  const [scheduleHours, setScheduleHours] = useState<number[]>([11, 17]);
  const [scheduleInterval, setScheduleInterval] = useState<string | null>('1h');
  const [scheduleRules, setScheduleRules] = useState<ScheduleRulesRow[]>([]);
  const [scheduleEmail, setScheduleEmail] = useState(user.email || '');
  const [scheduleType, setScheduleType] = useState<ScheduleType[]>(['email', 'dashboard']);
  const [scheduleTrigger, setScheduleTrigger] = useState<ScheduleTrigger>('time');
  const [scheduleDashboardId, setScheduleDashboardId] = useState('');
  const [scheduleName, setScheduleName] = useState('');
  const [updatingSchedule, setUpdatingSchedule] = useState(false);
  const [scheduleInfo, setScheduleInfo] = useState<TemporalScheduleInfo>();
  const [scheduleNotExists, setScheduleNotExists] = useState(false);

  const searchParams = useMemo(() => {
    const s = new URLSearchParams(search);
    s.set('tab-id', 'rules');
    return s.toString();
  }, [search]);

  const createSequenceDisabled = useMemo(() => {
    if (!scheduleSequenceId) {
      return true;
    }
    if (!scheduleName?.trim()) {
      return true;
    }
    if (!scheduleType.length) {
      return true;
    }
    if (scheduleType.includes('email') && !scheduleEmail) {
      return true;
    }
    if (scheduleType.includes('dashboard') && !scheduleDashboardId) {
      return true;
    }
    if (!scheduleDays.length && scheduleTrigger === 'time') {
      return true;
    }
    if (!scheduleTrigger) {
      return true;
    }
    if (scheduleTrigger === 'rules' && !scheduleInterval) {
      return true;
    }
    if (scheduleTrigger === 'rules' && !scheduleRules.length) {
      return true;
    }
    if (scheduleTrigger === 'rules' && scheduleRules.some((r) => !r.ruleIds.length)) {
      return true;
    }
    if (!scheduleHours.length && scheduleTrigger === 'time') {
      return true;
    }
    return false;
  }, [
    scheduleDashboardId,
    scheduleDays.length,
    scheduleEmail,
    scheduleHours.length,
    scheduleName,
    scheduleSequenceId,
    scheduleType,
    scheduleTrigger,
    scheduleInterval,
    scheduleRules,
  ]);

  const lastRun = useMemo(() => {
    if (!scheduleInfo?.info?.recentActions?.at(-1)?.takenAt) {
      return;
    }
    return moment(scheduleInfo.info.recentActions.at(-1)?.takenAt).format('YYYY-MM-DD HH:mm');
  }, [scheduleInfo]);

  const followingRuns = useMemo(() => {
    if (!scheduleInfo?.info?.nextActionTimes?.length) {
      return;
    }

    return scheduleInfo.info.nextActionTimes.map((t) => moment(t).format('YYYY-MM-DD HH:mm'));
  }, [scheduleInfo]);

  const closeScheduleModal = useCallback(() => {
    setScheduleName('');
    setScheduleDashboardId('');
    setScheduleEmail(user.email || '');
    setScheduleType(['email', 'dashboard']);
    setScheduleDays(['MONDAY', 'THURSDAY']);
    setScheduleHours([11, 17]);
    setScheduleTrigger('time');
    setScheduleInterval('1h');
    setScheduleRules([]);
    setScheduleNotExists(false);
    setScheduleInfo(undefined);
  }, [user.email]);

  const close = useCallback(() => {
    closeScheduleModal();
    onClose?.();
  }, [closeScheduleModal, onClose]);

  useEffect(() => {
    if (!schedule) {
      return;
    }

    if (!schedules.length) {
      return;
    }

    setScheduleName(schedule.name);
    setScheduleType(schedule.scheduleType);
    setScheduleEmail(schedule.email);
    setScheduleDashboardId(schedule.dashboardId);
    setScheduleDays(schedule.days);
    setScheduleHours(schedule.hours);
    setScheduleTrigger(schedule.scheduleTrigger);
    setScheduleInterval(schedule.interval);
    setScheduleRules(schedule.rules);
    setScheduleNotExists(false);
    setScheduleInfo(undefined);
  }, [schedule, schedules]);

  useEffect(() => {
    (async () => {
      if (!user.uid || !schedule?.id) {
        return;
      }
      const scheduleInfo = await getScheduleInfo(schedule.id, currentShopId, user.uid);
      if (scheduleInfo.error) {
        setScheduleNotExists(true);
        return;
      }
      setScheduleInfo(scheduleInfo);
    })();
  }, [currentShopId, schedule?.id, user.uid]);

  const logEvent = (action, schedule?: ScheduleItem) => {
    try {
      genericEventLogger(analyticsEvents.SEQUENCES, {
        action,
        sequence_id: schedule?.id,
        sequence_name: schedule?.name,
      });
    } catch (e) {
      console.error('Could not log event', e);
    }
  };

  return (
    <div>
      <FormLayout>
        <div className="flex flex-col gap-6.5">
          <Text fw="600">Run this Sequence</Text>
          <WillyFormGroup>
            <Checkbox
              label="At a specific time"
              onChange={() => setScheduleTrigger('time')}
              checked={scheduleTrigger === 'time'}
            />
            <Checkbox
              label="Based on rules"
              onChange={() => setScheduleTrigger('rules')}
              checked={scheduleTrigger === 'rules'}
            />
          </WillyFormGroup>
          <Text fw="600">Every:</Text>
          {scheduleTrigger === 'time' && (
            <WillyFormGroup>
              <MultiSelect
                data={[
                  { label: 'Sunday', value: 'SUNDAY' },
                  { label: 'Monday', value: 'MONDAY' },
                  { label: 'Tuesday', value: 'TUESDAY' },
                  { label: 'Wednesday', value: 'WEDNESDAY' },
                  { label: 'Thursday', value: 'THURSDAY' },
                  { label: 'Friday', value: 'FRIDAY' },
                  { label: 'Saturday', value: 'SATURDAY' },
                ]}
                onChange={(v) => setScheduleDays(v as DayOfWeek[])}
                value={scheduleDays}
              />
              <div className="flex flex-wrap items-center gap-4 flex-auto">
                <MultiSelect
                  label="At"
                  w="100%"
                  onChange={(hours) => {
                    setScheduleHours(hours.map((h) => parseInt(h)));
                  }}
                  data={Array(24)
                    .fill(0)
                    .map((_, i) => {
                      return {
                        label: `${i}:00`.padStart(5, '0'),
                        value: i.toString(),
                      };
                    })}
                  value={scheduleHours.map((h) => h.toString())}
                />
              </div>
              <Text size="xs" color="gray.5">
                Timezone: {timezone}
              </Text>
            </WillyFormGroup>
          )}
          {scheduleTrigger === 'rules' && (
            <WillyFormGroup>
              <Select
                data={[
                  { label: '15 Minutes', value: '15m' },
                  { label: '30 Minutes', value: '30m' },
                  { label: '1 Hour', value: '1h' },
                  { label: '2 Hours', value: '2h' },
                  { label: '4 Hours', value: '4h' },
                  { label: '8 Hours', value: '8h' },
                  { label: '1 Day', value: '1d' },
                  { label: '2 Days', value: '2d' },
                  { label: '4 Days', value: '4d' },
                  { label: '5 Days', value: '5d' },
                  { label: '6 Days', value: '6d' },
                  { label: '1 Week', value: '1w' },
                ]}
                value={scheduleInterval || '1h'}
                onChange={(v) => {
                  if (!v) {
                    return;
                  }
                  setScheduleInterval(v);
                }}
              />
            </WillyFormGroup>
          )}
          {scheduleTrigger === 'rules' && (
            <div className="flex flex-col gap-6.5">
              <Text fw="600">And if one of the following rules sets passed:</Text>
              <WillyFormGroup>
                <div className="flex items-center flex-wrap">
                  <Button
                    rightSection="plus"
                    onClick={() => {
                      setScheduleRules((old) => {
                        return [...old, { condition: 'OR', ruleIds: [] }];
                      });
                    }}
                    variant="white"
                    size="xs"
                  >
                    Add Rule
                  </Button>
                  <div className="ml-auto">
                    <Link
                      to={{
                        pathname: '/sql-editor',
                        search: searchParams,
                      }}
                      target="_blank"
                    >
                      Manage Rules
                    </Link>
                  </div>
                </div>
                {scheduleRules.length === 0 && (
                  <Text size="sm">
                    <span>no rules are set, the sequence will run every time it is scheduled</span>
                  </Text>
                )}
                {scheduleRules.map((rule, i) => (
                  <div key={`rule-${i}`} className="flex flex-col gap-6.5">
                    {i !== 0 && (
                      <Text size="sm">
                        <span className="lowercase">{rule.condition}</span>{' '}
                        <span>all of the following rules passed:</span>
                      </Text>
                    )}
                    <WillyFormGroup>
                      <div className="flex items-center gap-2">
                        <ActionIcon
                          icon="delete"
                          onClick={() => setScheduleRules((old) => old.filter((_, j) => j !== i))}
                        />
                        <div className="flex-grow">
                          <MultiSelect
                            description="All of the rules in the same row must pass for the sequence to run"
                            data={allRules.map((r) => {
                              return {
                                value: r.id,
                                label: r.name,
                              };
                            })}
                            onChange={(v) => {
                              setScheduleRules((old) => {
                                return old.map((r, j) => {
                                  if (i !== j) {
                                    return r;
                                  }
                                  return { ...r, ruleIds: v as string[] };
                                });
                              });
                            }}
                            value={rule.ruleIds}
                          />
                        </div>
                      </div>
                    </WillyFormGroup>
                    <div>
                      <Button
                        onClick={() => {
                          $willyRulePopup.set({
                            isOpen: true,
                            query: null,
                            rule: null,
                            ruleSaved: async (rule) => {
                              await createNewRule(rule);
                              setScheduleRules((old) => {
                                return old.map((r, j) => {
                                  if (i !== j) {
                                    return r;
                                  }
                                  return { ...r, ruleIds: [...r.ruleIds, rule.id] };
                                });
                              });
                            },
                          });
                        }}
                      >
                        Create Rule
                      </Button>
                    </div>
                  </div>
                ))}
              </WillyFormGroup>
            </div>
          )}
          <Text fw="600">And</Text>

          <WillyFormGroup>
            <Checkbox
              onChange={() => {
                setScheduleType((old) => {
                  return old.includes('email')
                    ? old.filter((x) => x !== 'email')
                    : [...old, 'email'];
                });
              }}
              checked={scheduleType.includes('email')}
              label="Send to Email"
            />
            <TextInput
              onChange={setScheduleEmail}
              label="Email Address"
              value={scheduleEmail}
              disabled={!scheduleType.includes('email')}
            />
          </WillyFormGroup>
          <WillyFormGroup>
            <Checkbox
              onChange={() => {
                setScheduleType((old) => {
                  return old.includes('dashboard')
                    ? old.filter((x) => x !== 'dashboard')
                    : [...old, 'dashboard'];
                });
              }}
              checked={scheduleType.includes('dashboard')}
              label="Send to Dashboard"
            />

            <DashboardsDropDown
              onSelect={setScheduleDashboardId}
              selected={scheduleDashboardId}
              disabled={!scheduleType.includes('dashboard')}
              hideGlobals
            />

            {!!scheduleDashboardId && (
              <Link
                to={{
                  pathname: `/dashboards/${scheduleDashboardId}`,
                  search: search,
                }}
                target="_blank"
              >
                View selected Dashboard
              </Link>
            )}
          </WillyFormGroup>

          <WillyFormGroup>
            <TextInput value={scheduleName} onChange={setScheduleName} label="Name" />
            <Text size="xs" color="gray.5">
              This will be the name of the report that will be sent to the selected destination
            </Text>
          </WillyFormGroup>
          {!!schedule && !!schedule.error && (
            <WillyFormGroup>
              <Text size="xs" color="red.5">
                Last run has the following error: {schedule.error}
              </Text>
            </WillyFormGroup>
          )}
          {!!schedule && !schedule.error && schedule.status !== 'error' && (
            <WillyFormGroup>
              <Text size="xs" color="green.7">
                Last run completed successfully {lastRun ? `at ${lastRun} (${timezone})` : ''}
              </Text>
            </WillyFormGroup>
          )}
          {!!schedule && !!followingRuns?.length && (
            <WillyFormGroup>
              <Text size="xs" color="gray.5">
                Following runs are scheduled to:
              </Text>
              {followingRuns.map((r, i) => (
                <Text key={i} size="xs" color="gray.5">
                  {r}
                </Text>
              ))}
            </WillyFormGroup>
          )}
          {scheduleNotExists && (
            <WillyFormGroup>
              <Text size="xs" color="red.5">
                This schedule does not exist, delete it and create a new one
              </Text>
            </WillyFormGroup>
          )}
        </div>
      </FormLayout>
      <div className="py-4">
        <div className="flex items-center gap-4 justify-between w-full overflow-auto">
          <div className="flex-auto">
            <Button variant="white" onClick={close}>
              Cancel
            </Button>
          </div>

          {/* {!!schedule && (
            <div className="flex-auto">
              <Button
                variant="white"
                onClick={async () => {
                  if (!user.uid || !currentShopId) {
                    return;
                  }
                  try {
                    setUpdatingSchedule(true);
                    if (schedule?.status === 'paused') {
                      await resumeSchedule(schedule.id, currentShopId, user.uid);
                    } else if (schedule?.status === 'scheduled') {
                      await pauseSchedule(schedule.id, currentShopId, user.uid);
                    } else {
                      return;
                    }
                    toast.success(
                      `Schedule ${schedule?.status === 'paused' ? 'resumed' : 'paused'} successfully`,
                      { delay: 1000 }
                    );
                  } catch (e) {
                    console.error('Could not pause/resume schedule', e);
                    toast.error('Could not pause/resume schedule');
                  } finally {
                    setUpdatingSchedule(false);
                  }
                }}
              >
                {schedule?.status === 'paused' ? 'Resume Schedule' : 'Pause Schedule'}
              </Button>
            </div>
          )} */}

          {!!schedule && (
            <div className="flex-auto">
              <Button
                variant="danger"
                onClick={async () => {
                  if (!user.uid || !currentShopId) {
                    return;
                  }
                  const confirmRes = await confirm({
                    title: 'Delete Schedule',
                    message: 'Are you sure you want to delete this schedule?',
                  });
                  if (!confirmRes) {
                    return;
                  }
                  try {
                    setUpdatingSchedule(true);
                    await deleteSchedule(schedule.id, currentShopId, user.uid);
                    logEvent(sequencesActions.DELETE_SCHEDULE, schedule);
                    toast.success('Schedule deleted successfully', { delay: 1000 });
                  } catch (e) {
                    console.error('Could not delete schedule', e);
                    toast.error('Could not delete schedule');
                  } finally {
                    setUpdatingSchedule(false);
                  }
                }}
              >
                Delete Schedule
              </Button>
            </div>
          )}

          {!!schedule && (
            <div className="flex-auto">
              <Button
                disabled={updatingSchedule || schedule?.status === 'running'}
                variant="white"
                onClick={async () => {
                  if (!user.uid || !currentShopId) {
                    return;
                  }
                  try {
                    setUpdatingSchedule(true);
                    await executeSequence(schedule.id, currentShopId, user.uid);
                    logEvent(sequencesActions.RUN_SCHEDULE, schedule);
                    toast.success('Sequence run successfully', { delay: 1000 });
                  } catch (e) {
                    console.error('Could not run sequence', e);
                    toast.error('Could not run sequence');
                  } finally {
                    setTimeout(() => {
                      setUpdatingSchedule(false);
                    }, 2000);
                  }
                }}
              >
                {schedule?.status === 'running' ? 'Running...' : 'Try Now'}
              </Button>
            </div>
          )}

          <div className="flex flex-col gap-2 flex-auto">
            <Button
              disabled={createSequenceDisabled}
              loading={updatingSchedule}
              onClick={async () => {
                if (createSequenceDisabled || !user.uid || !currentShopId) {
                  return;
                }
                setUpdatingSchedule(true);
                try {
                  const dash = dashboards.find((d) => d.id === scheduleDashboardId);
                  if (!dash && !!scheduleDashboardId) {
                    return;
                  }
                  const id = await createSchedule({
                    scheduleIdToUpdate: schedule?.id,
                    sequenceId: scheduleSequenceId,
                    name: scheduleName,
                    email: scheduleEmail,
                    scheduleType: scheduleType,
                    dashboardId: scheduleDashboardId,
                    dashboardType: dash?.isCustomView ? 'customView' : 'shop',
                    days: scheduleDays,
                    hours: scheduleHours,
                    rules: scheduleRules,
                    shopId: currentShopId,
                    userId: user.uid,
                    currency,
                    timezone,
                    scheduleTrigger,
                    interval: scheduleInterval,
                  });

                  logEvent(
                    !schedule ? sequencesActions.CREATE_SCHEDULE : sequencesActions.UPDATE_SCHEDULE,
                    schedule,
                  );
                  toast.success(`Schedule ${schedule ? 'updated' : 'created'} successfully`, {
                    delay: 1000,
                  });
                } catch (e) {
                  console.error('Could not create schedule', e);
                  toast.error('Could not create schedule');
                } finally {
                  setUpdatingSchedule(false);
                }
              }}
            >
              {!!schedule ? 'Update Schedule' : 'Create Schedule'}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export const WillyScheduleSequenceModal: React.FC<WillyScheduleSequenceParams> = ({ ...props }) => {
  const sequences = useStoreValue($shopSequences);

  return (
    <>
      <Modal
        opened={!!props.open}
        onClose={props.onClose || (() => {})}
        title={`Schedule Sequence ${
          sequences.find((s) => s.id === props.scheduleSequenceId)?.name
        }`}
        size="xl"
      >
        <WillyScheduleSequence {...props} />
      </Modal>
    </>
  );
};
