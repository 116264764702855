import { useCallback, useEffect, useMemo, useState } from 'react';
import { Badge, Drawer, MobileDrawer, Skeleton, Text } from '@tw/ui-components';
import {
  WillyDataSequence,
  WillySequenceInDashboard,
  SequencesReportsListProps,
  SequencesReportsListModalProps,
  DashboardDataWithSequenceHistory,
} from '../types/willyTypes';
import { OptionList } from '@shopify/polaris';
import { useStoreValue } from '@tw/snipestate';
import { $shopSequences } from '$stores/willy/$sequences';
import { useFilteredItems } from '../hooks/useFilteredItems';
import { WillySearchInput } from '../WillySearchInput';
import _db, { toArray } from 'utils/DB';
import moment from 'moment';
import { SequencesReportModal } from './SequencesReport';
import { $reportsWithSequences } from '$stores/willy/$sequences';

export const SequenceReportListItems = ({ reports }) => {
  return reports.map((report) => ({
    value: report.id,
    label: (
      <div className="flex flex-col gap-1 relative">
        {!report.read && (
          <div className="absolute top-0 right-0 -mt-1 -mr-1">
            <Badge color="red.5" size="xs">
              New
            </Badge>
          </div>
        )}
        <Text size="sm" fw="bold">
          {report.title}
        </Text>
        <Text size="sm" color="gray.5">
          <span className="line-clamp-2" dangerouslySetInnerHTML={{ __html: report.content }} />
        </Text>
        <Text size="xs" color="gray.5" fw="600">
          {moment(report.createdAt).format('LLL')}
        </Text>
      </div>
    ),
  }));
};

export const SequencesReportsList: React.FC<SequencesReportsListProps> = ({
  dashboardId,
  isSmall,
  isAll,
}) => {
  const sequences = useStoreValue($shopSequences);
  const reportsWithSequences = useStoreValue($reportsWithSequences);
  const [sequenceReports, setSequenceReports] = useState<WillySequenceInDashboard[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const [freeSearch, setFreeSearch] = useState<string>('');
  const [selectedSequence, setSelectedSequence] = useState<WillySequenceInDashboard | null>(null);

  const filteredSequenceReports = useFilteredItems(sequenceReports, freeSearch, ['title']);

  const sortedAllSequences = useMemo(() => {
    const mappedSequences = reportsWithSequences?.map((r) =>
      r.history.map((h) => ({
        ...h,
        dashboardId: r.id,
        title: isAll ? `${r.name}: ${h.title}` : h.title,
      })),
    );

    if (!mappedSequences) return [];

    return (
      mappedSequences
        ?.reduce((acc, history) => {
          return [...acc, ...history];
        }, [] as DashboardDataWithSequenceHistory[])
        ?.sort((a, b) => {
          return moment(b.createdAt) > moment(a.createdAt) ? 1 : -1;
        }) ?? []
    );
  }, [isAll, reportsWithSequences]);

  const filteredSortedAllSequences = useFilteredItems(sortedAllSequences, freeSearch, ['title']);

  const reportsGroupedBySequence = useMemo(() => {
    const sequencesDict = sequences.reduce(
      (acc, sequence) => {
        acc[sequence.id] = sequence;
        return acc;
      },
      {} as Record<string, WillyDataSequence>,
    );
    const groups = Object.groupBy(filteredSequenceReports, (report) => {
      const sequence = sequencesDict[report.sequenceId];
      return sequence?.name ? `From Workflow: ${sequence?.name}` : 'From Unknown Workflow';
    });

    return groups;
  }, [filteredSequenceReports, sequences]);

  const loadingTemplate = useMemo(() => {
    return Array.from({ length: 5 }).map((_, index) => ({
      value: `loading-${index}`,
      label: <Skeleton key={index} />,
    }));
  }, []);

  const selectSequence = useCallback(
    (reportId: string[]) => {
      const report = filteredSortedAllSequences.find((r) => r.id === reportId[0]);
      setSelectedSequence(report || null);
    },
    [filteredSortedAllSequences],
  );

  const fetchReports = useCallback(async () => {
    if (!dashboardId) return;

    setLoading(true);
    try {
      _db()
        .collection('willy_dashboards')
        .doc(dashboardId)
        .collection('sequences_dashboard_reports')
        .orderBy('createdAt', 'desc')
        .onSnapshot((response) => {
          const docs = toArray(response);
          setSequenceReports(docs);
          setError(null);
        });
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  }, [dashboardId]);

  useEffect(() => {
    fetchReports();
  }, [fetchReports]);

  return (
    <div className="flex flex-col">
      <div className={`${isAll ? 'p-6 pb-0' : ''}`}>
        <WillySearchInput
          value={freeSearch}
          onChange={(v) => setFreeSearch(v)}
          placeholder="Search Workflows"
          className="!p-0 overflow-visible"
        />
      </div>

      {!loading && !error && (isAll ? !reportsWithSequences.length : !sequenceReports.length) ? (
        <div className={`${isAll ? 'p-6 pb-0' : ''}`}>
          No workflows found {isAll ? 'in reports' : 'in this report'}
        </div>
      ) : (
        <OptionList
          options={loading ? loadingTemplate : []}
          sections={
            isAll
              ? [
                  {
                    options: SequenceReportListItems({ reports: filteredSortedAllSequences }),
                  },
                ]
              : Object.entries(reportsGroupedBySequence).map(([sequenceName, reports]) => ({
                  title: sequenceName,
                  options: !reports ? [] : SequenceReportListItems({ reports }),
                }))
          }
          onChange={selectSequence}
          selected={selectedSequence ? [selectedSequence.id] : []}
        />
      )}

      {selectedSequence && (
        <SequencesReportModal
          open={!!selectedSequence}
          onClose={() => setSelectedSequence(null)}
          report={selectedSequence}
          isSmall={isSmall}
          showBackButton
          withOverlay={false}
          dashboardId={selectedSequence.dashboardId ?? dashboardId ?? ''}
        />
      )}
    </div>
  );
};

export const SequencesReportsListModal: React.FC<SequencesReportsListModalProps> = (props) => {
  const { open, isSmall, onClose } = props;

  if (isSmall) {
    return (
      <MobileDrawer opened={open} onClose={onClose} title="Report Workflows">
        <SequencesReportsList {...props} />
      </MobileDrawer>
    );
  }

  return (
    <Drawer opened={open} onClose={onClose} title="Report Workflows" position="right" size="xs">
      <SequencesReportsList {...props} />
    </Drawer>
  );
};
