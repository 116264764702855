import {
  Banner,
  Button,
  Card,
  Checkbox,
  DataTable,
  Icon,
  Modal,
  Pagination,
  Spinner,
  TextField,
  Tooltip,
} from '@shopify/polaris';
import { ClockMinor, TimelineAttachmentMajor } from '@shopify/polaris-icons';
import moment from '@tw/moment-cached';
import React, { useEffect, useState } from 'react';
import axiosInstance from 'utils/axiosInstance';
import { services } from '@tw/types/module/services';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { getCurrencySymbol, currecySymbolsMap } from '@tw/constants';
import Select from 'react-select';
import { type RootState } from 'reducers/RootType';
import { shopsAmazonDb } from 'utils/DB';
import CogsHistory from './CogsHistory';
import { startCase } from 'lodash';
import {
  AMAZON_MARKETPLACE_IDS,
  AMAZON_REGIONS_LABELS,
  AmazonRegions,
} from '@tw/types/module/services/amazon';

type Product = {
  _id?: string;
  product_name: string;
  currency?: string;
  asin?: string;
  sku: string;
  cost: string;
  handlingFees: string;
  inStats?: boolean;
  marketplaceId?: string;
  selling_partner_id?: string;
  children?: Product[];
  isParent?: boolean;
  parent_sku?: string;
  hasChildren?: boolean;
};

type sellerAccount = {
  sellerAccountId: string;
  marketplaceIds: string[];
  region: AmazonRegions;
};
const fieldsForCSVFile = ['product_name', 'sku', 'cost', 'handlingFees', 'parent_sku'];
const classNameForChildRows = 'pl-7 text-[9pt] text-gray-500';

export default function AmazonCogs() {
  const shop = useSelector((state: RootState) => state.shop);
  const currentShopId = useSelector((state: RootState) => state.currentShopId);
  const [cogsPercentage, setCogsPercentage] = useState<number>();
  const [fixedHandlingFee, setFixddHandlingFee] = useState<number>();
  const [rows, setRows] = useState<Product[]>([]);
  const [page, setPage] = useState<number>(1);
  const [prodLoading, setProdLoading] = useState(false);
  const [file, setFile] = useState<File>();
  const [fileElm, setFileElm] = useState<HTMLInputElement>(document.createElement('input'));
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [fileData, setFileData] = useState<any[]>([]);
  const [fieldsDiff, setFieldsDiff] = useState<string[]>([]);
  const [expotLoading, setExpotLoading] = useState(false);
  const [isUploadModalOpen, setIsUploadModalOpen] = useState(false);
  const [fileCurrency, setFileCurrency] = useState({
    label: `${shop.currency} (${currecySymbolsMap[shop.currency]})`,
    value: shop.currency,
  });
  // const [fileAccount, setFileAccount] = useState({
  //   label: Object.keys(shop.amazon.sellerAccounts)[0],
  //   value: Object.keys(shop.amazon.sellerAccounts)[0],
  // });
  const [fileNaNErrors, setFileNaNErrors] = useState<{ column: string; count: number }[]>([]);
  const [expandedSku, setExpandedSku] = useState<string>('');
  const [regionList, setRegionList] = useState<any[]>([]);
  const [selectedRegion, setSelectedRegion] = useState({
    value: { sellerAccountId: '', marketplaceIds: [], region: '' },
    label: 'Please select region to display',
  });
  const [selectedSellerAccountId, setSeletedSellerAccountId] = useState<string>('');
  const [marketsList, setMarketsList] = useState<any[]>([]);
  const [currFocusVal, setCurrFocusVal] = useState('');
  const [selectedMarketplaceId, setSelectedMarketplaceId] = useState({
    value: '',
    label: 'Please select marketplace to display',
  });
  const [isCostHistoryOpen, setIsCostHistoryOpen] = useState(false);
  const [selectedHistoryRow, setSelectedHistoryRow] = useState<any>();
  const [historyType, setHistoryType] = useState<string>('costs');

  const updateServer = async (i, key) => {
    try {
      const copy = [...rows];
      const arr: any[] = [];

      if (copy[i][key] === +currFocusVal) {
        return;
      }
      if (copy[i].isParent) {
        // if the values are the same - update all children
        const childrenData = copy[i].children?.map((child) => child[key]);
        //update all children
        if (childrenData?.every((child) => child === copy[i][key])) {
          copy[i].children?.forEach((child) => {
            const obj = {
              sku: child.sku,
              selling_partner_id: child.selling_partner_id,
              marketplaceId: selectedMarketplaceId.value,
            };
            if (key === 'cost' || key === 'handlingFees') {
              obj[key] = {
                Amount: copy[i][key],
                CurrencyCode: copy[i].currency,
                date: moment().format(),
              };
            } else {
              obj[key] = copy[i][key];
            }
            arr.push(obj);
          });
        }
      }
      const obj = {
        sku: copy[i].sku,
        selling_partner_id: copy[i].selling_partner_id,
        marketplaceId: selectedMarketplaceId.value,
      };
      if (key === 'cost' || key === 'handlingFees') {
        obj[key] = {
          Amount: copy[i][key],
          CurrencyCode: copy[i].currency,
          date: moment().toDate(),
        };
        //save value in cost history as well
        const historyField = key === 'cost' ? 'costs' : 'handlingFeesList';
        obj[historyField] = [
          {
            Amount: +copy[i][key],
            CurrencyCode: copy[i].currency,
            date: moment().format(),
          },
          ...copy[i][historyField],
        ];
        copy[i][historyField] = obj[historyField];
      } else {
        obj[key] = copy[i][key];
      }
      arr.push(obj); // push the parent as well
      const res = await axiosInstance.post(`/v2/amazon/client/products`, { items: arr });
      if (res.data?.data?.every((obj) => obj?.message === 'updated'))
        toast.success(
          `${key} of ${res.data.data.length} products and variants were updated successfully!`,
        );
      else if (res.data?.data?.every((obj) => obj?.result === 'error'))
        toast.error(`Failed to updated ${key} of ${res.data.data.length} products and variants 😕`);
      else
        toast.warning(
          `Partial succes to updated ${key} of ${
            res.data.data.length
          } products and variants. Only ${
            res.data.data.filter((obj) => obj?.message === 'updated').length
          } were updated successfully`,
        );
    } catch (err) {
      console.log(err);
    }
  };

  const showCostHistory = (row, type) => {
    setIsCostHistoryOpen(true);
    setSelectedHistoryRow(row);
    setHistoryType(type);
  };

  const updateStateRowsDate = async (i, key, value, isCheckbox = false) => {
    try {
      if (+value < 0) return;
      const copy = [...rows];
      if (copy[i].isParent) {
        // if the values are the same - update all children
        const childrenData = copy[i].children?.map((child) => child[key]);
        //update all children
        if (childrenData?.every((child) => child === childrenData[0])) {
          copy[i].children?.forEach((child) => {
            child[key] = value;
          });
        }
      }
      copy[i][key] = value;
      setRows(copy);
      if (isCheckbox) await updateServer(i, key);
    } catch (err) {
      console.log(err);
      toast.error(`${key} updated failed`);
    }
  };

  useEffect(() => {
    const accounts = Object.entries(shop?.amazon?.sellerAccounts as Record<string, any>).map(
      ([key, value]) => {
        return { sellerAccountId: key, ...value } as sellerAccount;
      },
    );
    const regions = accounts.map((acc: any) => {
      const region = acc?.region || 'na';
      return { value: acc, label: AMAZON_REGIONS_LABELS[region] };
    });
    if (!regions?.length) return;
    setRegionList(regions);
    setSelectedRegion(regions[0]);
  }, [shop]);

  useEffect(() => {
    if (selectedRegion?.value?.sellerAccountId) {
      setSeletedSellerAccountId(selectedRegion.value.sellerAccountId);
    }
  }, [selectedRegion]);

  useEffect(() => {
    (async () => {
      try {
        if (!selectedRegion.value?.sellerAccountId) return;
        console.log('services shop markets', selectedRegion?.value?.marketplaceIds);
        const markets = selectedRegion?.value?.marketplaceIds?.map((market: any) => {
          return { value: market, label: AMAZON_MARKETPLACE_IDS[market] };
        });
        if (!markets?.length) return;
        const _selectedMarketplaceId = markets[0];
        await setCurrency(_selectedMarketplaceId);
        setMarketsList(markets);
        setSelectedMarketplaceId(markets[0]);
      } catch (err) {
        console.log(err);
      }
    })();
  }, [selectedSellerAccountId]);

  async function setCurrency(marketplaceId) {
    const curr = await (await shopsAmazonDb(selectedSellerAccountId).get()).data()?.marketplace_ids[
      marketplaceId.value
    ]?.marketplace?.defaultCurrencyCode;
    setFileCurrency({ label: `${curr} (${currecySymbolsMap[curr]})`, value: curr });
  }
  useEffect(() => {
    if (!selectedRegion.value?.sellerAccountId) return;
    if (!selectedMarketplaceId?.value) return;
    (async () => {
      try {
        await setCurrency(selectedMarketplaceId);
      } catch (err) {
        console.log(err);
      }
    })();
  }, [selectedMarketplaceId]);

  useEffect(() => {
    (async () => {
      try {
        await getProducts();
      } catch (err) {
        console.log(err);
      }
    })();
  }, [fileCurrency]);

  const importFile = () => {
    setIsModalOpen(false);
    setFileData([]);
    fileElm.type = 'file';
    fileElm.accept = '.csv';
    fileElm.click();
  };

  const getProducts = async (pageType?: string) => {
    setProdLoading(true);
    try {
      if (!selectedMarketplaceId.value) return;
      let uri = `/v2/amazon/client/products?account_ids=${selectedSellerAccountId}&marketplace_id=${selectedMarketplaceId.value}&page=`;
      switch (pageType) {
        case 'all':
          uri += '&getAll=true';
          break;
        case 'prev':
          uri += page - 1;
          setPage(page - 1);
          break;
        case 'next':
          uri += page + 1;
          setPage(page + 1);
          break;
        default:
          uri += '&getAll=true';
          break;
      }
      const prods = await axiosInstance.get(uri);
      const mapped: Product[] = prods.data.data.map((prd) => {
        const mostRecentCostDate = new Date(
          Math.max.apply(
            null,
            prd.cost?.map((e) => {
              return new Date(e.date);
            }),
          ),
        );
        const mostRecentCostObject = prd.cost?.filter((e) => {
          var d = new Date(e.date);
          return d.getTime() === mostRecentCostDate.getTime();
        })[0];
        const mostRecentHFDate = new Date(
          Math.max.apply(
            null,
            prd.handlingFees?.map((e) => {
              return new Date(e.date);
            }),
          ),
        );
        const mostRecentHFObject = prd.handlingFees?.filter((e) => {
          var d = new Date(e.date);
          return d.getTime() === mostRecentHFDate.getTime();
        })[0];

        return {
          _id: prd._id,
          product_name: prd.summaries.itemName,
          currency: fileCurrency.value, //mostRecentCostObject?.CurrencyCode || shop.currency,
          sku: prd.sku,
          marketplaceId: prd.summaries.marketplaceId,
          cost: mostRecentCostObject?.Amount || 0,
          costs: prd.cost.sort((objA, objB) => Date.parse(objB.date) - Date.parse(objA.date)),
          handlingFees: mostRecentHFObject?.Amount || 0,
          handlingFeesList: prd.handlingFees.sort(
            (objA, objB) => Date.parse(objB.date) - Date.parse(objA.date),
          ),
          inStats: prd.hasOwnProperty('inStats') ? prd.inStats : true,
          selling_partner_id: prd.selling_partner_id,
          isParent: true,
          hasChildren: !!prd.children?.length,
          children: prd.children.map((v) => {
            const mostRecentCostDateChild = new Date(
              Math.max.apply(
                null,
                v.cost?.map((e) => {
                  return new Date(e.date);
                }),
              ),
            );
            const mostRecentCostObjectChild = v.cost?.filter((e) => {
              var d = new Date(e.date);
              return d.getTime() === mostRecentCostDateChild.getTime();
            })[0];
            const mostRecentHFDateChild = new Date(
              Math.max.apply(
                null,
                v.handlingFees?.map((e) => {
                  return new Date(e.date);
                }),
              ),
            );
            const mostRecentHFObjectChild = v.handlingFees?.filter((e) => {
              var d = new Date(e.date);
              return d.getTime() === mostRecentHFDateChild.getTime();
            })[0];
            // console.log(prd.sku,v.sku, mostRecentHFObjectChild.Amount, );

            return {
              sku: v.sku,
              marketplaceId: v.summaries[0].marketplaceId,
              product_name: v.summaries[0].itemName,
              currency: fileCurrency.value,
              // mostRecentCostObjectChild?.CurrencyCode || fileCurrency.value || shop.currency,
              cost: mostRecentCostObjectChild?.Amount || 0,
              costs: v.cost.sort((objA, objB) => Date.parse(objB.date) - Date.parse(objA.date)),
              handlingFees: mostRecentHFObjectChild?.Amount || 0,
              handlingFeesList: v.handlingFees.sort(
                (objA, objB) => Date.parse(objB.date) - Date.parse(objA.date),
              ),
              inStats: v.hasOwnProperty('inStats') ? v.inStats : true,
              selling_partner_id: v.selling_partner_id,
              _id: v._id,
              isParent: false,
            };
          }),
        };
      });
      // console.log(uri);
      if (pageType === 'all') {
        return mapped;
      } else {
        setRows(mapped);
      }
      setProdLoading(false);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    fileElm.onchange = (e) => {
      // @ts-ignore
      setFile(e.target.files[0]);
    };
    getProducts();
  }, [fileElm]);

  useEffect(() => {
    if (!file) return;
    setIsModalOpen(true);
    const reader = new FileReader();
    reader.readAsText(file);
    reader.onload = (e) => {
      // translate csv to json
      const csv = e.target?.result as string;
      const rows = csv.split('\r\n').map((e) => e.split(','));
      const headers = rows[0];
      console.log('HEADER:', headers);
      console.log('ALLOWED LIST:', fieldsForCSVFile);
      // get diif between headers and fieldsForCSVFile
      const diff = fieldsForCSVFile.filter((x) => !headers.includes(x));
      console.log('DIFF:', diff);
      setFieldsDiff(diff);
      const data = rows
        .slice(1)
        .map((e) => e.reduce((acc, val, i) => ({ ...acc, [headers[i]]: val }), {}));
      const fd = data.map((prd) => {
        // remove keys that are not in the fileds array
        const filtered = Object.keys(prd).filter((key) => fieldsForCSVFile.includes(key as any));
        const filteredObj = filtered.reduce((obj, key) => {
          obj[key] = prd[key];
          return obj;
        }, {});
        return filteredObj;
      });

      console.log(fd);
      //@ts-ignore

      setFileData(fd);
      if (!data.length) {
        setIsModalOpen(false);
        toast.error('Empty or invalid file');
      }
    };
    reader.onerror = (e) => {
      console.log(e.target?.error);
      setIsModalOpen(false);
      toast.error(e.target?.error as any);
    };
  }, [file]);

  useEffect(() => {
    if (!fileData.length) return;
    const errArr: { column: string; count: number }[] = [];
    if (fileData.map((e) => (e as Product).cost).some((e) => isNaN(+e))) {
      errArr.push({
        column: 'Cost',
        count: fileData.map((e) => (e as Product).cost).filter((e) => isNaN(+e)).length,
      });
    }
    if (fileData.map((e) => (e as Product).handlingFees).some((e) => isNaN(+e))) {
      errArr.push({
        column: 'Handling Fees',
        count: fileData.map((e) => (e as Product).handlingFees).filter((e) => isNaN(+e)).length,
      });
    }
    if (fileData.some((e) => isNaN(+(e as Product).cost) || isNaN(+(e as Product).handlingFees))) {
      errArr.push({
        column: 'Total corrupted Products',
        count: fileData.filter(
          (e) => isNaN(+(e as Product).cost) || isNaN(+(e as Product).handlingFees),
        ).length,
      });
    }
    console.log(errArr);

    setFileNaNErrors(errArr);
  }, [fileData]);

  const cleanData = () => {
    setFileData(
      fileData.filter((e) => !isNaN(+(e as Product).cost) && !isNaN(+(e as Product).handlingFees)),
    );
  };

  const exportFile = async () => {
    setExpotLoading(true);
    const list = await getProducts('all');
    // let csv = Object.entries(Object.entries(list!)[0][1])
    //   .map((e) => e[0])
    //   .filter(e => fieldsForCSVFile.includes(e as any)).join(",");
    let csv = fieldsForCSVFile.join(',');
    console.log('CSV', csv);
    console.log('List', list);
    const ungrouped: Product[] = [];
    list!.forEach((prd) => {
      // push the parent
      ungrouped.push({
        product_name: prd.product_name.replaceAll(',', ';'),
        sku: prd.sku,
        cost: prd.cost,
        handlingFees: prd.handlingFees,
        parent_sku: '',
      });
      prd.children?.forEach((child) => {
        ungrouped.push({
          product_name: child.product_name.replaceAll(',', ';'),
          sku: child.sku,
          cost: child.cost,
          handlingFees: child.handlingFees,
          parent_sku: prd.sku,
        });
      });
    });

    console.log('ungrouped', ungrouped);

    for (const [k, v] of Object.entries(ungrouped)) {
      csv += '\r\n' + Object.values(v).join(',');
    }

    setExpotLoading(false);
    // open the download right away
    let j = document.createElement('a');
    j.download =
      'amazon_cogs_' +
      moment().format('MMM_Do_YYYY') +
      AMAZON_MARKETPLACE_IDS[selectedMarketplaceId.value] +
      '.csv';
    j.href = URL.createObjectURL(new Blob([csv]));
    j.click();
    return csv;
  };

  const saveFile = async () => {
    try {
      const transformed = fileData.map((e) => ({
        ...e,
        cost: { Amount: +e.cost, CurrencyCode: fileCurrency.value, date: moment().format() },
        handlingFees: {
          Amount: +e.handlingFees,
          CurrencyCode: fileCurrency.value,
          date: moment().format(),
        },
        selling_partner_id: selectedSellerAccountId,
        marketplaceId: selectedMarketplaceId.value,
      }));
      const res = await axiosInstance.post(`/v2/amazon/client/products`, { items: transformed });
      console.log('DATA FILE:', res);
      const passed = res.data.data.filter((e) => e.message === 'updated').length;
      const failed = res.data.data.filter((e) => e.result === 'error').length;
      const noUpdataNeeded = res.data.data.filter((e) => e.message === 'no update required').length;
      console.log({ passed, failed, noUpdataNeeded });

      if (failed > 0 && passed > 0) {
        toast.info(`File successfully imported! ${passed} products updated, ${failed} failed`);
      } else if (passed > 0) {
        toast.success(`File successfully imported! ${passed} products updated`);
      } else {
        toast.error(`${failed} products update failed`);
      }
      if (noUpdataNeeded > 0) {
        toast.info(`There are ${noUpdataNeeded} products that are already up to date`);
      }
      setIsModalOpen(false);
      setIsUploadModalOpen(false);
      await getProducts('all');
    } catch (err) {
      console.log('ERROR FILE', err);
      toast.error(`File import failed`);
    }
  };

  useEffect(() => {
    const copy = rows.filter((e) => e.isParent);
    // @ts-ignore
    setRows(
      copy.flatMap((row) => {
        const children = row.sku === expandedSku ? row.children?.map((child, j) => child) : [];
        return [row, ...children!];
      }),
    );

    // @ts-ignore
    console.log(
      'FLAT MAP',
      copy.flatMap((row) => {
        const children = row.sku === expandedSku ? row.children?.map((child, j) => child) : [];
        return [row, ...children!];
      }),
    );
  }, [expandedSku]);

  return (
    <div>
      {regionList?.length > 1 && (
        <Card>
          <h1 className="px-8 pt-5 pb-1 font-bold">Select Amazon Region</h1>
          <div className="px-7 pb-6 flex gap-5 w-full items-center">
            <div className="w-full">
              <Select
                placeholder="Select Region"
                // @ts-ignore
                options={regionList}
                value={selectedRegion}
                //@ts-ignore
                onChange={(v) => setSelectedRegion(v)}
                menuPosition="fixed"
                menuPlacement="bottom"
              />
            </div>
          </div>
        </Card>
      )}
      <Card>
        <h1 className="px-8 pt-5 pb-1 font-bold">Select Amazon Marketplace</h1>
        <div className="px-7 pb-6 flex gap-5 w-full items-center">
          {/* <div className="w-1/3">
          <TextField
            label=""
            type="number"
            value={cogsPercentage?.toString()}
            onChange={v => setCogsPercentage(+v)}
            autoComplete="off"
            inputMode="numeric"
            placeholder="Set COGS as a % of sales"
            suffix="%"
          />
        </div> */}

          {/* <div className="w-1/3">
          <TextField
            label=""
            type="number"
            value={fixedHandlingFee?.toString()}
            onChange={v => setFixddHandlingFee(+v)}
            autoComplete="off"
            inputMode="numeric"
            placeholder="Fixed Handling Fee per order"
          />
        </div> */}
          {/* <Button primary onClick={importFile}
          icon={<Icon
            source={TimelineAttachmentMajor}
            color="critical"
            />}
            >
            {file ? file.name : "Import CSV File"}
            </Button>
          <Button primary onClick={exportFile} disabled={!rows.length} loading={expotLoading} >Export CSV File</Button> */}
          <div className="w-full">
            <Select
              placeholder="Select marketplace"
              // @ts-ignore
              options={marketsList}
              value={selectedMarketplaceId}
              //@ts-ignore
              onChange={(v) => setSelectedMarketplaceId(v)}
              menuPosition="fixed"
              menuPlacement="bottom"
            />
          </div>
          <span className="flex-grow just-a-spacer"></span>
          <div className="w-[30vw] flex justify-end">
            <Button primary onClick={() => setIsUploadModalOpen(true)}>
              Upload COGs
            </Button>
          </div>
        </div>
      </Card>
      <Card>
        {rows.length ? (
          <div className="p-7 flex-col w-full relative">
            <DataTable
              columnContentTypes={[
                'text',
                'text',
                'text',
                'text',
                'text',
                // 'text',
                // 'text',
              ]}
              headings={[
                'Product Name',
                // 'ASIN',
                'SKU',
                // 'FBA/FBM',
                'Cost (COG)',
                'Handling Fee',
                'In Stats',
                // 'Marketplace (name)',
                // 'Currency'
              ]}
              rows={
                prodLoading
                  ? [
                      [
                        <Spinner size="small" />,
                        <Spinner size="small" />,
                        <Spinner size="small" />,
                        <Spinner size="small" />,
                        <Spinner size="small" />,
                      ],
                    ]
                  : rows.map((row, i) => {
                      const parent = [
                        <div className={row.isParent ? '' : classNameForChildRows}>
                          <Tooltip content={row.product_name}>
                            <h1
                              id={row.sku}
                              className={
                                row.isParent && row.hasChildren
                                  ? 'cursor-pointer underline text-blue-700'
                                  : ''
                              }
                              onClick={(e) => {
                                if (row.isParent) {
                                  setExpandedSku((stt) =>
                                    stt === row.sku ? '' : (row.sku as string),
                                  );
                                  // scroll issue fix goes here
                                }
                              }}
                            >
                              {' '}
                              {row.product_name}
                            </h1>
                          </Tooltip>
                        </div>,
                        // row.sku,
                        <div className={row.isParent ? '' : classNameForChildRows}>
                          <p>{row.sku}</p>
                        </div>,
                        // <TextField label="" type="number" prefix={row.currency} value={row.asin} onChange={(v)=>{updateStateRowsDate(i, "asin", v)}} disabled={true}  onBlur={()=>{/* go to the server */}} />,
                        // <Select label="" value={row.fba_fbm} onChange={(v)=>{console.log(v);
                        //  updateStateRowsDate(i, "fba_fbm", v)}} options={[{label:"FBA", value:"FBA"},{label:"FBM", value:"FBM"}]} disabled/>, // and go to server
                        <>
                          {/*  @ts-ignore */}
                          <TextField
                            label=""
                            type={
                              row.isParent && row.children?.some((c) => c.cost !== row.cost)
                                ? 'text'
                                : 'number'
                            }
                            step={0.1}
                            suffix={
                              (!row.isParent || (row.isParent && !row?.children?.length)) && (
                                <div
                                  // name="history"
                                  className="cost-history"
                                  color="grey"
                                  title="Show Cost History"
                                  onClick={() => showCostHistory(row, 'costs')}
                                >
                                  <Icon source={ClockMinor} />
                                </div>
                              )
                            }
                            prefix={getCurrencySymbol(fileCurrency.value)}
                            value={
                              row.isParent && row.children?.some((c) => c.cost !== row.cost)
                                ? 'Different Prices'
                                : '' + row.cost
                            }
                            onChange={(v) => {
                              updateStateRowsDate(i, 'cost', v);
                            }}
                            onBlur={() => {
                              updateServer(i, 'cost');
                            }}
                            onFocus={(e) => {
                              setCurrFocusVal((e?.target as HTMLInputElement).value);
                            }}
                            selectTextOnFocus
                            disabled={
                              row.isParent && row.children?.some((c) => c.cost !== row.cost)
                            }
                          />
                        </>,
                        // @ts-ignore
                        <TextField
                          label=""
                          type={
                            row.isParent &&
                            row.children?.some((c) => c.handlingFees !== row.handlingFees)
                              ? 'text'
                              : 'number'
                          }
                          step={0.1}
                          prefix={getCurrencySymbol(fileCurrency.value)}
                          value={
                            row.isParent &&
                            row.children?.some((c) => c.handlingFees !== row.handlingFees)
                              ? 'Different Prices'
                              : '' + row.handlingFees
                          }
                          onChange={(v) => {
                            updateStateRowsDate(i, 'handlingFees', v);
                          }}
                          onBlur={() => {
                            updateServer(i, 'handlingFees');
                          }}
                          onFocus={(e) => {
                            setCurrFocusVal((e?.target as HTMLInputElement).value);
                          }}
                          selectTextOnFocus
                          disabled={
                            row.isParent &&
                            row.children?.some((c) => c.handlingFees !== row.handlingFees)
                          }
                          suffix={
                            (!row.isParent || (row.isParent && !row?.children?.length)) && (
                              <div
                                // name="history"
                                className="cost-history"
                                color="grey"
                                title="Show Cost History"
                                onClick={() => showCostHistory(row, 'handlingFeesList')}
                              >
                                <Icon source={ClockMinor} />
                              </div>
                            )
                          }
                        />,
                        <Checkbox
                          label=""
                          checked={row.inStats}
                          onChange={(v) => {
                            updateStateRowsDate(i, 'inStats', v, true);
                          }}
                          disabled={
                            row.isParent && row.children?.some((c) => c.inStats !== row.inStats)
                          }
                        />,
                        // <div className={row.isParent?'':classNameForChildRows}>
                        //   {AMAZON_MARKETPLACE_IDS[row.marketplaceId!]}
                        // </div>,
                        // row.currency,
                      ];

                      return parent;
                    })
              }
              verticalAlign="middle"
              truncate
            />
            <div className="pb-7 pt-3 flex justify-center w-full">
              <Pagination
                hasPrevious={page > 1}
                onPrevious={() => {
                  getProducts('prev');
                }}
                hasNext={rows.length > 20}
                onNext={() => {
                  getProducts('next');
                }}
              />
            </div>
          </div>
        ) : prodLoading ? (
          <div className="flex items-center justify-center my-20">
            <Spinner size="large" />
          </div>
        ) : (
          <div className="flex items-center justify-center my-20">
            <h1>
              No products found for marketplace{' '}
              <span className="font-bold">
                {AMAZON_MARKETPLACE_IDS[selectedMarketplaceId.value]}
              </span>
            </h1>
          </div>
        )}
      </Card>
      {isModalOpen && (
        <Modal
          large
          open={true}
          onClose={() => {
            setIsModalOpen(false);
            setFile(undefined);
          }}
          title={`Import Data From CSV File (${file?.name})`}
          primaryAction={{
            content: 'Save',
            onAction: saveFile,
            disabled: !!fieldsDiff.length || !!fileNaNErrors.length,
          }}
          secondaryActions={[
            {
              content: 'Load a different file',
              onAction: importFile,
            },
          ]}
        >
          {!!fieldsDiff.length && (
            <Modal.Section>
              <Banner status="critical" title="Missing columns" onDismiss={() => {}}>
                <p className="pb-0 mb-0">This file is missing the following columns:</p>
                <ul className="pt-0 mt-2">
                  {fieldsDiff.map((field) => (
                    <li key={field}>{field}</li>
                  ))}
                </ul>
                <p className="">
                  Please make sure the spelling is right. the column names are <b>CaSe_SeNsEtIvE</b>
                  .
                </p>
              </Banner>
            </Modal.Section>
          )}
          {!!fileNaNErrors.length && (
            <Modal.Section>
              <Banner
                status="critical"
                title="Non Numerical values"
                onDismiss={() => {}}
                action={{ content: 'clean data', onAction: cleanData }}
              >
                <p className="pb-0 mb-0">
                  This file contains non-numerical values in a numeriacl columns:
                </p>
                <ul className="pt-0 mt-2">
                  {fileNaNErrors.map((field) => (
                    <li key={field.column}>
                      <b>{field.column}</b>: {field.count} products
                    </li>
                  ))}
                </ul>
                <p className="">
                  Comma Seperated Values (CSV) format does not support commas inside the product
                  name. This may cause the product name to be concidered as a SKU or Cost
                </p>
                <p className="">
                  If you wish to remove these products from the import, click on the clean button
                  bellow.
                </p>
              </Banner>
            </Modal.Section>
          )}
          {/* !!fileData.length && (
            <Modal.Section>
              <div className="flex gap-8">
                {Object.keys(shop.amazon.sellerAccounts).length > 1 && (
                  <div>
                    <p className="mb-2">Please select Amazon account</p>
                    <Select
                      options={Object.keys(shop.amazon.sellerAccounts).map((c) => ({
                        value: c,
                        label: c,
                      }))}
                      value={fileAccount}
                      onChange={(v) => setFileAccount(v!)}
                    />
                  </div>
                )}
              </div>
            </Modal.Section>
          ) */}
          <Modal.Section>
            {fileData.length ? (
              <>
                <DataTable
                  columnContentTypes={Array(Object.keys(fileData[0]).length).fill('text')}
                  headings={Object.keys(fileData[0])}
                  // @ts-ignore
                  rows={fileData.map((row, i) =>
                    Object.values({
                      ...row,
                      cost: (
                        <span className={isNaN(row.cost) ? 'text-red-700 bg-red-200 p-5' : ''}>
                          {isNaN(row.cost) ? '' : currecySymbolsMap[fileCurrency.value]}
                          {row.cost}
                        </span>
                      ),
                      handlingFees: (
                        <span
                          className={isNaN(row.handlingFees) ? 'text-red-700  bg-red-200 p-5' : ''}
                        >
                          {isNaN(row.handlingFees) ? '' : currecySymbolsMap[fileCurrency.value]}
                          {row.handlingFees}
                        </span>
                      ),
                    }),
                  )}
                  verticalAlign="middle"
                  // stickyHeader={true}
                />
              </>
            ) : (
              <Spinner size="large" />
            )}
          </Modal.Section>
        </Modal>
      )}

      {/* upload modal */}
      <Modal
        open={isUploadModalOpen}
        onClose={() => setIsUploadModalOpen(false)}
        title="Upload COGs"
      >
        <Modal.Section>
          <h1 className="font-bold text-2xl mb-2">
            1. Download Template for {AMAZON_MARKETPLACE_IDS[selectedMarketplaceId.value]}
          </h1>
          <p className="pl-6 mb-5">
            You can use the Excel file below to edit product costs and handling fees for one Amazon
            marketplace at a time. Switch to a different marketplace to upload costs for products
            sold in other marketplaces
          </p>
          <div className="pl-6">
            <Button primary onClick={exportFile} disabled={!rows.length} loading={expotLoading}>
              Export CSV File
            </Button>
          </div>
        </Modal.Section>

        <Modal.Section>
          <h1 className="font-bold text-2xl mb-2">2. Edit costs in the file</h1>
          <p className="pl-6 mb-2">
            Please note that the costs for the{' '}
            <b>{AMAZON_MARKETPLACE_IDS[selectedMarketplaceId.value]}</b> marketplace are only
            accepted in <b>{fileCurrency.label}</b>
          </p>
        </Modal.Section>
        <Modal.Section>
          <h1 className="font-bold text-2xl mb-2">
            3. Upload modified file for {AMAZON_MARKETPLACE_IDS[selectedMarketplaceId.value]}
          </h1>
          <div className="pl-6 pt-3">
            <Button
              primary
              onClick={importFile}
              icon={<Icon source={TimelineAttachmentMajor} color="critical" />}
            >
              {file ? file.name : 'Import CSV File'}
            </Button>
          </div>
        </Modal.Section>
      </Modal>
      <CogsHistory
        selectedRow={selectedHistoryRow}
        getCurrencySymbol={getCurrencySymbol}
        defaultCurrency={fileCurrency.value}
        isOpen={isCostHistoryOpen}
        onClose={() => setIsCostHistoryOpen(false)}
        onSave={async () => await getProducts()}
        service={'amazon'}
        type={historyType}
        title={`Amazon COGs Cost History - ${startCase(historyType)}`}
      />
    </div>
  );
}
