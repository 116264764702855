import React, { useEffect, useState, Fragment, useMemo } from 'react';
import { connect } from 'react-redux';
import {
  Card,
  DataTable,
  TextField,
  TextStyle,
  Button,
  Page,
  Checkbox,
  Heading,
  Banner,
  Tooltip,
  Icon,
  Select,
  ButtonGroup,
  Popover,
  ActionList,
  SortDirection,
  Pagination,
} from '@shopify/polaris';
import {
  toggleNewCustomSpendIsOpen,
  toggleNewMetricBasedCustomSpendIsOpen,
  customSpendStartDateWindowToggle,
  customSpendOnDelete,
  customSpendsExportCsvOnPress,
  editCustomSpend,
  updateCustomExpenseAttributionType,
  updateCustomExpenseCategories,
  toggleImportCustomSpendIsOpen,
  getCustomSpends,
} from '../ducks/actions';
import DeleteRowButton from '../components/DeleteRowWithConfirmButton';
import NewCustomSpendWindow from '../components/NewCustomSpend';
import NewMetricBasedCustomSpendWindow, {
  variableExpenseMetric,
} from '../components/NewMetricBasedCustomSpend';
import moment from '@tw/moment-cached';
import CURRENCIES from '../constants/currencies';
import {
  EditMinor,
  QuestionMarkMinor,
  RefreshMinor,
  RiskMinor,
  SettingsMajor,
} from '@shopify/polaris-icons';
import { useAppDispatch } from 'index';
import { useAppSelector } from 'reducers/RootType';
import axiosInstance from 'utils/axiosInstance';
import allServices from 'constants/services';
import { changeIncludeCustomAdSpend } from 'ducks/attribution/actions';
import { computeFeatureFlags, useFeatureFlagComputer } from 'feature-flag-system';
import { FeatureFlag } from '@tw/feature-flag-system/module/types';
import { UpgradePageFallBack } from 'feature-flag-system/components';
import { ImportSpendsFromGoogleSheets } from 'components/ImportSpendsFromGoogleSheets';
import { TW_LS_ATTRIBUTION_INCLUDE_CUSTOM_AD_SPEND } from 'constants/attribution';
import { mapValues } from 'lodash';
import { toast } from 'react-toastify';
import { loadReports } from 'ducks/reports';

const PAGE_SIZE = 500;

const cell2: any = {
  height: 50,
  display: 'flex',
  alignItems: 'center',
};

const StatusIndicator = ({ status }) => {
  let backgroundColor = '#bfbfbf';
  let title = 'No status';
  switch (status) {
    case 1:
      backgroundColor = '#3acf00';
      title = 'Active';
      break;
    case 2:
      backgroundColor = '#D3D3D3';
      title = 'Inactive';
      break;
    default:
      break;
  }
  return (
    <div
      style={{
        width: '10px',
        height: '10px',
        borderRadius: '50%',
        flex: '0 0 auto',
        marginRight: '15px',
        float: 'left',
        backgroundColor: backgroundColor,
      }}
      title={title}
    ></div>
  );
};

const CategoryFixedRow = ({ category, spends, currency, onDelete, page, setPage }) => {
  const hasPagination = spends.length > PAGE_SIZE;
  const paginationItems = spends.slice(page * PAGE_SIZE, (page + 1) * PAGE_SIZE);

  const dispatch = useAppDispatch();

  var visualProduct: any[] = [];

  visualProduct[0] = (
    <div style={{}}>
      <div style={cell2}></div>
      {paginationItems.map((spend) => {
        var { recurring, recurringIntervalLength, start_date, end_date } = spend;
        const badSpend =
          !start_date || (recurring && !recurringIntervalLength) || (!recurring && !end_date);
        return (
          <div key={spend.id} style={cell2}>
            {badSpend && (
              <Tooltip content="Due to a recalculation of Custom Expenses, this fixed expense is missing data and needs to be updated">
                <Icon source={RiskMinor} color="critical" />{' '}
              </Tooltip>
            )}
          </div>
        );
      })}
    </div>
  );
  visualProduct[1] = (
    <div style={{}}>
      <div style={cell2}>
        <div style={{ display: 'flex' }}>
          <TextStyle variation="strong">{category}</TextStyle>
        </div>
      </div>
      {paginationItems.map((spend) => {
        var { title, start_date, end_date } = spend;
        const isActive =
          start_date &&
          moment(start_date).isSameOrBefore(moment(), 'day') &&
          (!end_date || moment(end_date).isSameOrAfter(moment(), 'day'));
        return (
          <div key={spend.id} style={cell2}>
            <StatusIndicator status={isActive ? 1 : 2}></StatusIndicator>
            <TextStyle variation="subdued">{title}</TextStyle>
          </div>
        );
      })}
      {hasPagination && (
        <Pagination
          hasPrevious={page > 0}
          onPrevious={() => setPage(page - 1)}
          hasNext={page < spends.length / PAGE_SIZE - 1}
          onNext={() => setPage(page + 1)}
        />
      )}
    </div>
  );
  visualProduct[2] = (
    <div style={{}}>
      <div style={cell2}></div>
      {paginationItems.map((spend) => {
        var { cost } = spend;
        return (
          <div key={spend.id} style={cell2}>
            <Button disabled={true} outline fullWidth>
              {CURRENCIES[currency] + Intl.NumberFormat().format(cost)}
            </Button>
          </div>
        );
      })}
    </div>
  );

  visualProduct[3] = (
    <div style={{}}>
      <div style={cell2}></div>
      {paginationItems.map((spend) => {
        var { start_date } = spend;
        var startDateLabel;
        if (start_date) {
          start_date = moment(start_date);
          startDateLabel = start_date.format('MMM DD, YYYY');
        } else {
          start_date = moment();
          startDateLabel = 'No start date';
        }
        return (
          <div key={spend.id} style={cell2}>
            <Button disabled={true} outline disclosure>
              {startDateLabel}
            </Button>
          </div>
        );
      })}
    </div>
  );

  visualProduct[4] = (
    <div style={{}}>
      <div style={cell2}></div>
      {paginationItems.map((spend) => {
        var { end_date } = spend;
        var endDateLabel;
        if (end_date) {
          end_date = moment(end_date);
          endDateLabel = end_date.format('MMM DD, YYYY');
        } else {
          end_date = moment();
          endDateLabel = 'No end date';
        }

        return (
          <div key={spend.id} style={cell2}>
            <Button disabled={true} outline disclosure>
              {endDateLabel}
            </Button>
          </div>
        );
      })}
    </div>
  );

  visualProduct[5] = (
    <div style={{}}>
      <div style={cell2}></div>
      {paginationItems.map((spend) => {
        var { recurring, recurringIntervalLength, recurringCadence } = spend;

        let recurringLabel;
        if (recurringIntervalLength) recurringLabel = `Every ${recurringIntervalLength} `;
        if (recurringCadence) recurringLabel += ' ' + recurringCadence;
        else recurringLabel += ' day';
        if (recurringIntervalLength > 1) recurringLabel += 's';

        return (
          <div key={spend.id} style={cell2}>
            {recurring && recurringIntervalLength ? (
              <TextStyle variation="subdued"> {recurringLabel} </TextStyle>
            ) : (
              ''
            )}
          </div>
        );
      })}
    </div>
  );

  visualProduct[6] = (
    <div style={{}}>
      <div style={cell2}></div>
      {paginationItems.map((spend) => {
        var { isAdSpend } = spend;
        return (
          <div key={spend.id} style={cell2}>
            <Checkbox label labelHidden checked={!!isAdSpend} disabled={true} />
          </div>
        );
      })}
    </div>
  );
  visualProduct[7] = (
    <div style={{}}>
      <div style={cell2}></div>
      {paginationItems.map((spend) => {
        var { cost, recurring, recurringIntervalLength, end_date, start_date, recurringCadence } =
          spend;
        const costPerDay: any =
          recurring && (!recurringCadence || recurringCadence === 'day')
            ? Math.round(Number(cost) / recurringIntervalLength)
            : recurring && recurringCadence === 'month'
              ? (Number(cost) / (recurringIntervalLength * 30.5)).toFixed(2)
              : Math.round(Number(cost) / (moment(end_date).diff(moment(start_date), 'days') + 1));
        return (
          <div key={spend.id} style={cell2}>
            <Button disabled={true} outline fullWidth>
              {CURRENCIES[currency] + Intl.NumberFormat().format(costPerDay)}
            </Button>
          </div>
        );
      })}
    </div>
  );
  visualProduct[8] = (
    <div style={{}}>
      <div style={cell2}></div>
      {paginationItems.map((spend) => {
        var { id } = spend;
        return (
          <div key={spend.id} style={cell2}>
            <Button
              onClick={() => {
                dispatch(editCustomSpend(id));
                dispatch(toggleNewCustomSpendIsOpen());
              }}
              icon={EditMinor}
            ></Button>
          </div>
        );
      })}
    </div>
  );
  visualProduct[9] = (
    <div style={{}}>
      <div style={cell2}></div>
      {paginationItems.map((spend) => {
        return (
          <div key={spend.id} style={cell2}>
            <DeleteRowButton
              style={cell2}
              title="Delete Your Fixed Expense"
              primaryAction={() => onDelete(spend)}
              description="Deleting this fixed expense will remove it from all past data. This action can't be undone, are you sure you want to delete?"
              primaryActionTitle="Delete"
            />
          </div>
        );
      })}
    </div>
  );
  return visualProduct;
};

const CategoryVariableRow = ({ category, spends, onDelete, currency }) => {
  const dispatch = useAppDispatch();

  var visualProduct: any[] = [];

  visualProduct[0] = (
    <div style={{}}>
      <div style={cell2}></div>
      {spends.map((spend) => {
        var { start_date } = spend;
        const badSpend = !start_date;
        return (
          <div key={spend.id} style={cell2}>
            {badSpend && (
              <Tooltip content="Due to a recalculation of Custom Expenses, this variable expense is missing data and needs to be updated">
                <Icon source={RiskMinor} color="critical" />{' '}
              </Tooltip>
            )}
          </div>
        );
      })}
    </div>
  );
  visualProduct[1] = (
    <div style={{}}>
      <div style={cell2}>
        <div style={{ display: 'flex' }}>
          <TextStyle variation="strong">{category}</TextStyle>
        </div>
      </div>
      {spends.map((spend) => {
        var { title, start_date, end_date } = spend;
        const isActive =
          start_date &&
          moment(start_date).isSameOrBefore(moment(), 'day') &&
          (!end_date || moment(end_date).isSameOrAfter(moment(), 'day'));
        return (
          <div key={spend.id} style={cell2}>
            <StatusIndicator status={isActive ? 1 : 2}></StatusIndicator>
            <TextStyle variation="subdued">{title}</TextStyle>
          </div>
        );
      })}
    </div>
  );
  visualProduct[2] = (
    <div style={{}}>
      <div style={cell2}></div>
      {spends.map((spend) => {
        var { metricId } = spend;
        return (
          <div key={spend.id} style={cell2}>
            <TextField
              label={''}
              labelHidden={true}
              value={variableExpenseMetric.find((x) => x.id === metricId)?.title}
              disabled={true}
              autoComplete="off"
            />
            {metricId === 'blendedAds' && (
              <div style={{ marginLeft: '5px' }}>
                <Tooltip content="Blended Ads includes Facebook, Google, Snapchat, Tiktok and Pinterest Ad Spend, but does not include Custom Fixed or Variable Ad Spend ">
                  <Icon source={QuestionMarkMinor} color="subdued" />{' '}
                </Tooltip>
              </div>
            )}
          </div>
        );
      })}
    </div>
  );
  visualProduct[3] = (
    <div style={{}}>
      <div style={cell2}></div>
      {spends.map((spend) => {
        var { percent } = spend;
        const content =
          spend.metricId !== 'orders'
            ? '' + percent + '%'
            : CURRENCIES[currency] + Intl.NumberFormat().format(percent) + ' /order';
        return (
          <div key={spend.id} style={cell2}>
            <Button disabled={true} outline>
              {content}
            </Button>
          </div>
        );
      })}
    </div>
  );

  visualProduct[4] = (
    <div style={{}}>
      <div style={cell2}></div>
      {spends.map((spend) => {
        var { start_date } = spend;
        var startDateLabel;
        if (start_date) {
          start_date = moment(start_date);
          startDateLabel = start_date.format('MMM DD, YYYY');
        } else {
          start_date = moment();
          startDateLabel = 'No start date';
        }
        return (
          <div key={spend.id} style={cell2}>
            <Button disabled={true} outline disclosure>
              {startDateLabel}
            </Button>
          </div>
        );
      })}
    </div>
  );

  visualProduct[5] = (
    <div style={{}}>
      <div style={cell2}></div>
      {spends.map((spend) => {
        var { end_date } = spend;
        var endDateLabel;
        if (end_date) {
          end_date = moment(end_date);
          endDateLabel = end_date.format('MMM DD, YYYY');
        } else {
          end_date = moment();
          endDateLabel = 'No end date';
        }

        return (
          <div key={spend.id} style={cell2}>
            <Button disabled={true} outline disclosure>
              {endDateLabel}
            </Button>
          </div>
        );
      })}
    </div>
  );

  visualProduct[6] = (
    <div style={{}}>
      <div style={cell2}></div>
      {spends.map((spend) => {
        var { isAdSpend } = spend;
        return (
          <div key={spend.id} style={cell2}>
            <Checkbox label labelHidden checked={!!isAdSpend} disabled={true} />
          </div>
        );
      })}
    </div>
  );

  visualProduct[7] = (
    <div style={{}}>
      <div style={cell2}></div>
      {spends.map((spend) => {
        var { id } = spend;
        return (
          <div key={spend.id} style={cell2}>
            <Button
              onClick={() => {
                dispatch(editCustomSpend(id));
                dispatch(toggleNewMetricBasedCustomSpendIsOpen());
              }}
              icon={EditMinor}
            ></Button>
          </div>
        );
      })}
    </div>
  );
  visualProduct[8] = (
    <div style={{}}>
      <div style={cell2}></div>
      {spends.map((spend) => {
        return (
          <div key={spend.id} style={cell2}>
            <DeleteRowButton
              style={cell2}
              title="Delete Your Variable Expense"
              primaryAction={() => onDelete(spend)}
              description="Deleting this variable expense will remove it from all past data. This action can't be undone, are you sure you want to delete?"
              primaryActionTitle="Delete"
            />
          </div>
        );
      })}
    </div>
  );
  return visualProduct;
};

const CustomExpenseAttributionToggle: React.FC<{
  toggleOpen: (...args: any) => any;
  isOpen: boolean;
}> = ({ toggleOpen, isOpen }) => {
  const dispatch = useAppDispatch();
  const customExpenseAttributionType = useAppSelector(
    (state) => state.customExpenseAttributionType,
  );

  type CustomExpenseAttributionType = 'daily' | 'hourly' | 'smart';
  const handleChange = (newAttributionType: CustomExpenseAttributionType) => {
    dispatch(updateCustomExpenseAttributionType(newAttributionType));
    toggleOpen();
  };

  return (
    <Popover
      active={isOpen}
      activator={
        <div style={{ position: 'relative', top: '4px', cursor: 'pointer' }} onClick={toggleOpen}>
          <Tooltip content="Set custom expenses attribution type.">
            <Icon source={SettingsMajor} color="base" />
          </Tooltip>
        </div>
      }
      autofocusTarget="first-node"
      onClose={toggleOpen}
    >
      <Tooltip content='Custom expenses can be attributed once per day at 12 AM, hourly, or utilizing a "smart" distribution, at the average rate of sales per hour over the last 90 days. '>
        <ActionList
          items={[
            {
              content: 'Daily Attribution',
              onAction: () => handleChange('daily'),
              active: customExpenseAttributionType === 'daily',
            },
            {
              content: 'Hourly Attribution',
              onAction: () => handleChange('hourly'),
              active: customExpenseAttributionType === 'hourly',
            },
            {
              content: 'Smart Attribution',
              onAction: () => handleChange('smart'),
              active: customExpenseAttributionType === 'smart',
            },
          ]}
        />
      </Tooltip>
    </Popover>
  );
};

const CustomSpends = ({ onDelete, exportCsvOnPress, currency }) => {
  const dispatch = useAppDispatch();

  const customSpends = useAppSelector((state) => state.customSpends);
  const customSpendsFlag = useAppSelector((state) => state.customSpendsFlag);
  const customSpendCategories = useAppSelector((state) => state.customSpendCategories);
  const currentShopId = useAppSelector((state) => state.currentShopId);
  const mainDatePickerSelectionRange = useAppSelector(
    (state) => state.mainDatePickerSelectionRange,
  );
  const { includeCustomAdSpend } = useAppSelector((state) => state.attribution);

  const [showErrorBanner, setShowErrorBanner] = useState(true);
  const [filterSpends, setFilterSpends] = useState('all');
  const [pages, setPages] = useState({
    fixed: {},
    variable: {},
  });
  const handleSelectChange = (value) => setFilterSpends(value);
  const [attributedSources, setAttributedSources] = useState<string[]>([]);
  const [customExpenseSettingsOpen, setCustomExpenseSettingsOpen] = useState(false);
  const [filterByNameValue, setFilterByNameValue] = useState('');
  const [fixedSpendsSortDirection, setFixedSpendsSortDirection] =
    useState<SortDirection>('descending');
  const [variableSpendsSortDirection, setVariableSpendsSortDirection] =
    useState<SortDirection>('descending');
  const [fixedSpendsSortBy, setFixedSpendsSortBy] = useState<'start_date' | 'end_date'>();
  const [variableSpendsSortBy, setVariableSpendsSortBy] = useState<'start_date' | 'end_date'>();
  const { customSpendErrors, spreadsheetUrl, sheetsAccount, id, shopId } = useAppSelector(
    (state) => state.customSpendsGoogleSheet,
  );
  const [syncLoading, setSyncLoading] = useState(false);
  const ffComputer = useFeatureFlagComputer();
  const { shouldNotBeSeen: isGoogleSheetsDisabled } = ffComputer.getConfigById(
    FeatureFlag.EXPORT_TO_SHEETS_FF,
  );
  const relevantSources = useMemo(() => {
    const attributed = attributedSources.map((s) => {
      return {
        id: s,
        name: s,
      };
    });
    // merge attributed and all services
    const merged = Object.values<any>(allServices).concat(attributed);
    // unique by id
    const unique = merged.reduce((acc, curr) => {
      if (!acc[curr.id]) {
        acc[curr.id] = curr;
      }
      return acc;
    }, {});
    return Object.values<any>(unique).map((s) => {
      return {
        value: s.id,
        originalValue: s.id,
        label: s.name,
      };
    });
  }, [attributedSources]);

  useEffect(() => {
    (async () => {
      if (!mainDatePickerSelectionRange) return;
      const { data } = await axiosInstance.post('/v2/attribution/get-attributed-sources', {
        shopDomain: currentShopId,
        startDate: moment(mainDatePickerSelectionRange.start)
          .subtract(1, 'months')
          .startOf('day')
          .format('YYYY-MM-DD'),
        endDate: moment(mainDatePickerSelectionRange.end).endOf('day').format('YYYY-MM-DD'),
      });

      setAttributedSources(data.sources || []);
      dispatch(loadReports());
    })();
  }, [currentShopId, dispatch, mainDatePickerSelectionRange]);

  // filter spends by filters & group by category
  const [filteredFixedSpendsByCategory, filteredVariableSpendsByCategory] = useMemo(() => {
    const filterByActiveStatus = (spend) => {
      return (
        spend.start_date &&
        moment(spend.start_date).isSameOrBefore(moment(), 'day') &&
        (!spend.end_date || moment(spend.end_date).isSameOrAfter(moment(), 'day'))
      );
    };

    const filterByInActiveStatus = (spend) => {
      return (
        (spend.start_date && moment(spend.start_date).isAfter(moment(), 'day')) ||
        (spend.end_date && moment(spend.end_date).isBefore(moment(), 'day'))
      );
    };

    const filterByName = (spend) => {
      return spend.title.toLowerCase().includes(filterByNameValue.toLowerCase());
    };

    const fixedCategories: { [key in string]: any[] } = {};
    const variableCategories: { [key in string]: any[] } = {};

    for (const spend of customSpends) {
      let isFilter =
        filterByName(spend) &&
        (filterSpends !== 'active' || filterByActiveStatus(spend)) &&
        (filterSpends !== 'inactive' || filterByInActiveStatus(spend));

      if (!isFilter) continue;

      const categories = spend.metricId ? variableCategories : fixedCategories;

      const category = spend.category || 'Uncategorized';
      if (!categories[category]) {
        categories[category] = [];
      }
      categories[category].push(spend);
    }

    return [fixedCategories, variableCategories];
  }, [customSpends, filterByNameValue, filterSpends]);

  // update missing categories on DB
  useEffect(() => {
    const missingFixedCategories = Object.keys(filteredFixedSpendsByCategory).filter(
      (c) => !customSpendCategories.includes(c),
    );
    if (missingFixedCategories.length) {
      dispatch(updateCustomExpenseCategories(missingFixedCategories));
    }

    const missingVarCategories = Object.keys(filteredVariableSpendsByCategory).filter(
      (c) => !customSpendCategories.includes(c),
    );

    if (missingVarCategories.length) {
      dispatch(updateCustomExpenseCategories(missingVarCategories));
    }
  }, [
    customSpendCategories,
    dispatch,
    filteredFixedSpendsByCategory,
    filteredVariableSpendsByCategory,
  ]);

  // sort spends inside categories
  const sortedFixedSpends: { [key in string]: any[] } = useMemo(() => {
    if (!fixedSpendsSortBy) return filteredFixedSpendsByCategory;

    return mapValues(filteredFixedSpendsByCategory, (spends) => {
      // @ts-ignore
      const sorted = spends.sort((a, b) => {
        if (fixedSpendsSortBy === 'start_date') {
          if (!a.start_date) return 1;
          if (!b.start_date) return -1;
          return moment(a.start_date).diff(moment(b.start_date));
        } else if (fixedSpendsSortBy === 'end_date') {
          if (!a.end_date) return 1;
          if (!b.end_date) return -1;
          return moment(a.end_date).diff(moment(b.end_date));
        }
      });

      if (fixedSpendsSortDirection === 'ascending') {
        return sorted.reverse();
      }

      return sorted;
    });
  }, [filteredFixedSpendsByCategory, fixedSpendsSortBy, fixedSpendsSortDirection]);

  const sortedVariableSpends: { [key in string]: any[] } = useMemo(() => {
    if (!variableSpendsSortBy) return filteredVariableSpendsByCategory;

    return mapValues(filteredVariableSpendsByCategory, (spends) => {
      // @ts-ignore
      const sorted = spends.sort((a, b) => {
        if (variableSpendsSortBy === 'start_date') {
          if (!a.start_date) return 1;
          if (!b.start_date) return -1;
          return moment(a.start_date).diff(moment(b.start_date));
        } else if (variableSpendsSortBy === 'end_date') {
          if (!a.end_date) return 1;
          if (!b.end_date) return -1;
          return moment(a.end_date).diff(moment(b.end_date));
        }
      });

      if (variableSpendsSortDirection === 'ascending') {
        return sorted.reverse();
      }

      return sorted;
    });
  }, [filteredVariableSpendsByCategory, variableSpendsSortBy, variableSpendsSortDirection]);

  // reset pagination when filters change
  useEffect(() => {
    setPages({
      fixed: {},
      variable: {},
    });
  }, [filterByNameValue, filterSpends]);

  const syncSheet = async (shopId, id, sheetsAccount) => {
    setSyncLoading(true);
    const url = 'v2/google-sheets/trigger-report-update';
    try {
      await axiosInstance.post(url, {
        shopId,
        reportId: id,
        sheetsAccount,
      });
      setSyncLoading(false);
      dispatch(getCustomSpends());
    } catch (e) {
      const message = `Error uploading Custom Spends Google Sheet. ${
        e?.data.includes('5000') ? 'You can only have a maximum of 5000 custom spends' : ''
      }`;
      toast.error(message);
      setSyncLoading(false);
    }
  };

  return (
    <div className="p-6.5">
      <Card key="1" sectioned>
        {customSpends && showErrorBanner && customSpendsFlag && (
          <Banner
            status="critical"
            title="Custom Expenses"
            onDismiss={() => {
              setShowErrorBanner(false);
            }}
          >
            We are currently working on an overhaul of the Custom Expenses section. Some of your
            Custom Expenses may need to be updated to work with the new system.
          </Banner>
        )}
        {!!customSpendErrors && Object.keys(customSpendErrors)?.length > 0 && (
          <Banner status="critical" title="Custom Expenses Google Sheet Error">
            <div>
              There are error(s) on your Custom Expenses Google Sheet. Please fix the following
              error(s):
            </div>
            {Object.keys(customSpendErrors)?.map((error) => (
              <div>
                Row {error} : {customSpendErrors[error]}
              </div>
            ))}
            <div className="flex gap-5 align-items-center">
              <a target="_blank" href={spreadsheetUrl}>
                Go to Google Sheet
              </a>
              <Button
                icon={RefreshMinor}
                loading={syncLoading}
                onClick={async () => await syncSheet(shopId, id, sheetsAccount)}
              >
                Sync Now
              </Button>
            </div>
          </Banner>
        )}
        <Card.Header
          actions={[
            {
              content: 'Add Fixed Expense',
              onAction: () => dispatch(toggleNewCustomSpendIsOpen()),
            },
            {
              content: 'Add Variable Expense',
              onAction: () => dispatch(toggleNewMetricBasedCustomSpendIsOpen()),
            },
            {
              content: 'Export CSV',
              onAction: exportCsvOnPress,
              disabled: !customSpends?.length,
            },
            {
              content: 'Import Fixed Expenses from Google Sheets',
              onAction: () => dispatch(toggleImportCustomSpendIsOpen()),
              disabled: isGoogleSheetsDisabled,
            },
          ]}
          title="There is a maximum limit of 5000 custom expenses per shop"
        >
          <CustomExpenseAttributionToggle
            isOpen={customExpenseSettingsOpen}
            toggleOpen={() => setCustomExpenseSettingsOpen((x) => !x)}
          />
        </Card.Header>
        <div>
          <Checkbox
            checked={includeCustomAdSpend}
            onChange={(checked) => {
              dispatch(changeIncludeCustomAdSpend(checked));
              localStorage.setItem(
                TW_LS_ATTRIBUTION_INCLUDE_CUSTOM_AD_SPEND,
                JSON.stringify(checked),
              );
            }}
            label="Include in pixel"
            helpText="Include custom ad spend in all calculations on the pixel page"
          />
          {!!customSpends && customSpends.length > 0 && (
            <div className="py-4 flex gap-4">
              <div className="w-1/2">
                <TextField
                  label=""
                  labelHidden
                  autoComplete=""
                  placeholder="Filter by spend name"
                  value={filterByNameValue}
                  onChange={(value) => setFilterByNameValue(value)}
                  clearButton
                  onClearButtonClick={() => setFilterByNameValue('')}
                />
              </div>
              <div className="w-1/2">
                <Select
                  options={[
                    { label: 'All', value: 'all' },
                    { label: 'Active', value: 'active' },
                    { label: 'Inactive', value: 'iactive' },
                  ]}
                  value={filterSpends}
                  onChange={handleSelectChange}
                  label="Filter Expenses"
                  labelInline
                />
              </div>
            </div>
          )}
          {!!(customSpends && customSpends.length && customSpends.some((e) => e.cost)) && (
            <Fragment>
              <Heading>Fixed Expenses</Heading>
              <DataTable
                columnContentTypes={[
                  'text',
                  'text',
                  'text',
                  'text',
                  'text',
                  'text',
                  'text',
                  'text',
                  'text',
                  'text',
                ]}
                sortable={[false, false, false, true, true, false, false, false, false, false]}
                headings={[
                  '',
                  'Category',
                  'Cost',
                  'Start Date',
                  'End Date',
                  'Recurring',
                  'Ad Spend',
                  <div className="table-tooltip">
                    <Tooltip content="Effective cost per day for each custom expense based on amount spend and selected time parameters. For monthly recurring expenses, this will show an approximate daily cost.">
                      <div style={{ display: 'flex' }}>
                        {' '}
                        <div>Cost Per Day</div>
                        <Icon source={QuestionMarkMinor} color="subdued"></Icon>
                      </div>
                    </Tooltip>
                  </div>,
                  '',
                  '',
                ]}
                rows={Object.entries(sortedFixedSpends).map(([category, spends]) =>
                  CategoryFixedRow({
                    category,
                    spends,
                    currency,
                    onDelete,
                    page: pages.fixed[category] || 0,
                    setPage: (page) => {
                      setPages((prev) => ({
                        ...prev,
                        fixed: {
                          ...prev.fixed,
                          [category]: page,
                        },
                      }));
                    },
                  }),
                )}
                onSort={(columnIndex, direction) => {
                  setFixedSpendsSortBy(columnIndex === 3 ? 'start_date' : 'end_date');
                  setFixedSpendsSortDirection(direction);
                }}
              />
            </Fragment>
          )}
          {!!(customSpends && customSpends.length && customSpends.some((e) => e.metricId)) && (
            <Fragment>
              <br />
              <br />
              <Heading>Variable Expenses</Heading>
              <DataTable
                columnContentTypes={[
                  'text',
                  'text',
                  'text',
                  'text',
                  'text',
                  'text',
                  'text',
                  'text',
                  'text',
                  'text',
                ]}
                sortable={[false, false, false, false, true, true, false, false, false, false]}
                headings={
                  customSpends.some((e) => e?.metricId === 'orders')
                    ? [
                        '',
                        'Category',
                        'Metric',
                        'Percent / Per Order',
                        'Start Date',
                        'End Date',
                        'Ad Spend',
                        '',
                        '',
                      ]
                    : [
                        '',
                        'Category',
                        'Metric',
                        'Percent',
                        'Start Date',
                        'End Date',
                        'Ad Spend',
                        '',
                        '',
                      ]
                }
                rows={Object.entries(sortedVariableSpends).map(([category, spends]) =>
                  CategoryVariableRow({
                    category,
                    spends,
                    onDelete,
                    currency,
                  }),
                )}
                onSort={(columnIndex, direction) => {
                  setVariableSpendsSortBy(columnIndex === 4 ? 'start_date' : 'end_date');
                  setVariableSpendsSortDirection(direction);
                }}
              />
            </Fragment>
          )}
        </div>
      </Card>
      <NewMetricBasedCustomSpendWindow relevantSources={relevantSources} />
      <NewCustomSpendWindow relevantSources={relevantSources} />
      <ImportSpendsFromGoogleSheets />
    </div>
  );
};

const mapStateToProps = ({
  customSpendStartDateWindowIsOpen,
  customSpendEndDateWindowIsOpen,
  importCustomSpendsTableIsOpen,
  currency,
  loadingCustomSpends,
}) => ({
  customSpendStartDateWindowIsOpen: customSpendStartDateWindowIsOpen,
  customSpendEndDateWindowIsOpen,
  importTableIsOpen: importCustomSpendsTableIsOpen,
  currency,
  loadingCustomSpends,
});

const mapDispatchToProps = (dispatch) => {
  return {
    toggleNewMetricBasedCustomSpendIsOpen: () => dispatch(toggleNewMetricBasedCustomSpendIsOpen()),
    customSpendStartDateWindowToggle: (id) => dispatch(customSpendStartDateWindowToggle(id)),
    onDelete: (customSpend) => dispatch(customSpendOnDelete(customSpend)),
    exportCsvOnPress: (data) => dispatch(customSpendsExportCsvOnPress(data)),
  };
};

export default computeFeatureFlags(
  FeatureFlag.SPENDS_PAGE_FF,
  connect(mapStateToProps, mapDispatchToProps)(CustomSpends),
  () => (
    <UpgradePageFallBack
      featureFlag={FeatureFlag.SPENDS_PAGE_FF}
      InAppContextBannerEnabled={false}
      title="Spends"
    />
  ),
);
