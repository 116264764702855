import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/analytics';
import 'firebase/compat/performance';
import 'firebase/compat/firestore';

import { firebase as config } from '../config';
export const app = firebase.initializeApp(config);

app.firestore().settings({
  cacheSizeBytes: 1048576,
});
