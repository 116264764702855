import { CancelMajor, DeleteMinor, EditMajor, TickMinor } from '@shopify/polaris-icons';
import { WillyFieldTypes } from './types/willyTypes';
import { useSortable } from '@dnd-kit/sortable';
import { ReactComponent as DragHandle } from '../../icons/drag-handle.svg';
import { WillyDashboardElement } from 'components/Willy/types/willyTypes';
import { useIsSmall } from 'hooks/useDefaultWindowSizes';

type WillySectionHeaderProps = {
  id: string;
  type: WillyFieldTypes;
  title: string;
  editingFields: string[];
  editingFieldChanged: (fields: string[]) => Promise<void>;
  titleChanged: (id: string, title: string) => Promise<void>;
  fieldRemoved: (id: string) => Promise<void>;
  canEdit?: boolean;
  isDnd?: boolean;
  secondaryToolbarOpen?: boolean;
  editLayout?: boolean;
  inMobileDrawer?: boolean;
};

export const WillySectionHeader: React.FC<WillySectionHeaderProps> = ({
  id,
  type,
  title,
  editingFields,
  editingFieldChanged,
  titleChanged,
  fieldRemoved,
  canEdit,
  isDnd,
  secondaryToolbarOpen,
  editLayout,
  inMobileDrawer,
}) => {
  const EditIcon = type === 'text' ? TickMinor : CancelMajor;
  const isSmall = useIsSmall();

  const { attributes, listeners } = useSortable({ id: id || '' });

  return (
    <div
      className={`w-full flex justify-center flex-col p-4 text-ellipsis overflow-hidden bg-white font-semibold group py-4 sticky top-0 z-[110] flex-shrink-0 min-w-[150px] border-0 border-solid border-b-[1px] border-gray-300`}
    >
      <div className="flex gap-4 justify-between align-center">
        <span
          className="dark:text-gray-300"
          contentEditable={canEdit}
          suppressContentEditableWarning
          onBlur={async (e) => {
            const title = e?.target?.innerHTML.trim();
            if (title?.length > 0) {
              titleChanged(id, title);
            }
          }}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              e.preventDefault();
              e.currentTarget.blur();
            }
          }}
        >
          {title}
        </span>
        {canEdit && (!isSmall || (isSmall && inMobileDrawer)) && (
          <div
            className={`flex gap-2 opacity-0 transition-opacity group-hover/field:opacity-100 absolute right-4 pl-4 bg-white ${
              editLayout || inMobileDrawer ? 'opacity-100' : ''
            }`}
          >
            {editingFields.includes(id) && (
              <EditIcon
                className="flex w-9 cursor-pointer fill-gray-500 dark:fill-gray-300"
                onClick={() => {
                  const newFields = editingFields.filter((f) => f !== id);
                  editingFieldChanged(newFields);
                }}
              />
            )}
            {!editingFields.includes(id) && (
              <EditMajor
                className="flex w-9 cursor-pointer fill-gray-500 dark:fill-gray-300"
                onClick={() => {
                  editingFieldChanged([...editingFields, id]);
                }}
              />
            )}
            <DeleteMinor
              className="flex w-9 cursor-pointer fill-gray-500 dark:fill-gray-300"
              onClick={async (e) => {
                e.preventDefault();
                e.stopPropagation();

                fieldRemoved(id);
              }}
            />
            {isDnd && canEdit && editLayout && secondaryToolbarOpen && (
              <div id={'drag-' + id} aria-label={'Drag ' + title} className="flex">
                <DragHandle
                  {...attributes}
                  {...listeners}
                  className="w-8 cursor-move fill-gray-600 dark:fill-gray-300"
                />
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};
