import { $activeAppVersion, $activeRoute } from '$stores/nav-config-stores';
import {
  $trial_banner,
  FREE_TRIAL_BANNER_HEIGHT,
  FREE_TRIAL_BANNER_HEIGHT_VAR,
  closeTrialBanner,
} from '$stores/willy/$upgradePopupManager';
import { useStoreValue } from '@tw/snipestate';
import { Button, Icon, Text } from '@tw/ui-components';
import { useIsSmall } from 'hooks/useDefaultWindowSizes';
import { useEffect } from 'react';
import { useHistory } from 'react-router';

export function useShowFreeTrialBanner() {
  const isSmall = useIsSmall();
  const { show: showTrialBanner } = useStoreValue($trial_banner);
  const { activeRoute: r } = useStoreValue($activeRoute);
  const appVersion = useStoreValue($activeAppVersion);

  return !!(
    !isSmall &&
    showTrialBanner &&
    (r?.isWillyPage || r?.isHybridPage) &&
    !r?.isShoplessPage &&
    !r?.isSettingsPage &&
    appVersion === '3.0'
  );
}

export const FreeTrialBanner = () => {
  const history = useHistory();
  const trial_banner = useStoreValue($trial_banner);

  return (
    <div
      style={{ height: FREE_TRIAL_BANNER_HEIGHT + 'px' }}
      className="w-full bg-blue-600 z-[9999999] flex items-center px-[20px] justify-between"
    >
      <div className="flex items-center gap-[20px]">
        <Text color="white" fz={16} fw={500}>
          {trial_banner.daysLeft == 0 ? (
            <span>
              {`Your ${trial_banner.currentPlanName ?? ''} free trial has ended. To get full access to all features upgrade`}
            </span>
          ) : (
            <span>
              Your {trial_banner.currentPlanName} free trial ends in{' '}
              <b>{trial_banner.daysLeft} days.</b>
            </span>
          )}
        </Text>
        <Button variant="activator" size="xs" onClick={() => history.push('/plans')}>
          Upgrade
        </Button>
      </div>
      <div className="cursor-pointer" onClick={closeTrialBanner(trial_banner.daysLeft === 0)}>
        <Icon name="close" color="white" size={14} />
      </div>
    </div>
  );
};

export function useComputeFreeTrialBannerHeight() {
  const showFreeTrialBanner = useShowFreeTrialBanner();

  useEffect(() => {
    document.documentElement.style.setProperty(
      FREE_TRIAL_BANNER_HEIGHT_VAR,
      (showFreeTrialBanner ? FREE_TRIAL_BANNER_HEIGHT : 0) + 'px',
    );
  }, [showFreeTrialBanner]);

  useEffect(() => {
    return () => {
      document.documentElement.style.setProperty(FREE_TRIAL_BANNER_HEIGHT_VAR, '0px');
    };
  }, []);
}
