import { useState, useCallback } from 'react';

// Usage:
//
// - pass an object with keys and validation functions to useValidation:
//
// const { errors, validate, resetErrors } = useValidation({
//   title: (v)=> v.length ? undefined : 'Title is required',
//   description: (v)=> v.length ? undefined : 'Description is required',
// });
//
// - calling validate will run all validation functions:
//   validate({title: 'dead', description: 'beef'}) // returns true
//   validate({title: '', description: 'beef'}) // returns false, sets `errors` to {title: 'Title is required'}
//
// - calling validate with a key will run the validation function for that key and return true if valid
//   validate('Cool description', 'description') // returns true
//   validate('', 'description') // returns false, sets `errors` to {description: 'Description is required'}
//
// - calling resetErrors will clear the errors object

export const useValidation = (validators: Record<string, (value: any) => string | undefined>) => {
  const [errors, setErrors] = useState<Record<string, string>>({});

  const validateOne = (key: string, value: any) => {
    const error = validators[key](value);
    if (error) {
      setErrors(() => ({ ...errors, [key]: error }));
    } else {
      let newErrors = { ...errors };
      delete newErrors[key];
      setErrors(newErrors);
    }
  };

  const validate = (values: Record<string, any> | any, name?: keyof typeof validators) => {
    let errs: Record<string, string> = {};
    if (name) {
      validateOne(name, values);
    } else {
      Object.keys(validators).forEach((name) => {
        let err = validators[name](values[name]);
        if (err) {
          errs[name] = err;
        }
      });
      setErrors(errs);
    }
    return Object.keys(errs).length === 0;
  };

  const resetErrors = useCallback(() => {
    setErrors({});
  }, []);

  return { errors, validate, resetErrors };
};
