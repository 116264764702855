import { Flex, Icon, Text, Button } from '@tw/ui-components';
import _db from 'utils/DB';
import { useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import { useLocation } from 'react-router';
import { useIsSmall } from 'hooks/useDefaultWindowSizes';

import { WillyPageWrapper } from './WillyPageWrapper';
import { SequencesList, SequencesListProps } from './SequencesList';
import { WillyTemplatesInner } from './dashboardManagment/WillyTemplates';
import { WillySequenceSettingsModal } from './dashboardManagment/WillySeqSettingsModal';

import { Allotment } from 'allotment';
import { MENU_SIZE } from './constants';

import { computeFeatureFlags } from '../../feature-flag-system';
import { FeatureFlag } from '@tw/feature-flag-system/module/types';
import { UpgradePageFallBack } from '../../feature-flag-system/components';
import { $seqSettingsModal } from '$stores/willy/$seqSettingsModal';
import { SequenceRunsModal } from './utils/SequenceRuns';
import { $sequenceSelectorDrawer, closeSequenceSelectorDrawer } from '$stores/willy/$mobileDrawers';
import { useComputedValue } from '@tw/snipestate';
import { SequencesReportsList } from './dashboardManagment/SequencesReportsList';
import { MobileDrawer } from '@tw/ui-components';

const SEQUENCE_NAV_ITEMS = [
  {
    id: 'all',
    label: 'Workflows',
    link: '/workflows?filter=all',
    icon: 'clock',
    content: ({ setRunsOpen }) => <SequencesList activeTab="all" setRunsOpen={setRunsOpen} />,
  },
  {
    id: 'user',
    label: 'Managed By You',
    link: '/workflows?filter=user',
    icon: 'presentation',
    content: ({ setRunsOpen }) => <SequencesList activeTab="user" setRunsOpen={setRunsOpen} />,
  },
  {
    id: 'templates',
    label: 'Templates',
    link: '/workflows?filter=templates',
    icon: 'button-play',
    content: (
      <div className="relative h-full px-6 sm:p-0 sm:pl-6">
        <WillyTemplatesInner
          templateType="sequence"
          setTemplateType={() => {}}
          isSequencesPage
          hideHeader
          onlyInner
        />
      </div>
    ),
  },
  {
    id: 'reports',
    label: 'Reports',
    link: '/workflows?filter=reports',
    icon: 'tiles-view',
    content: <SequencesReportsList isAll={true} />,
  },
];

type SequencesProps = { activeTab: string };
type NavItem = (typeof SEQUENCE_NAV_ITEMS)[0];
type SequencesNavItemProps = SequencesProps & { item: NavItem };

const SequencesNavItem: React.FC<SequencesNavItemProps> = ({ activeTab, item }) => {
  return (
    <Link
      to={item.link}
      onClick={() => closeSequenceSelectorDrawer()}
      className={
        'no-underline text-[var(--mantine-color-gray-7)] font-medium group/dashboard-label gap-2 ' +
        'render-item flex rounded-md items-center ' +
        'cursor-pointer hover:bg-[var(--gray-light-mode-300)] py-1 ' +
        (item.id === activeTab ? 'bg-[var(--gray-light-mode-300)]' : '')
      }
    >
      <div className="flex items-center gap-2 pl-4 py-1">
        <Icon name={item.icon as any} />
        <Text span fz="sm" c="gray.8" fw={500}>
          {item.label}
        </Text>
        <div className="opacity-0 group-hover/report-side-panel:opacity-100 pl-4 min-w-[24px]" />
      </div>
    </Link>
  );
};

const SequencesSidePanel: React.FC<SequencesProps> = ({ activeTab }) => {
  const isSmall = useIsSmall();

  return (
    <>
      <div className="bg-white sticky top-0 z-10">
        {!isSmall && (
          <>
            <Flex justify="space-between" align="center" p="md" h={65}>
              <Text fz="xl" fw="500">
                Workflows
              </Text>
            </Flex>

            <div className="border-t border-solid border-l-0 border-r-0 border-b-0 willy-border" />
          </>
        )}
      </div>

      <div className="willy-side-panel dash-panel p-4 gap-4 flex flex-col">
        {SEQUENCE_NAV_ITEMS.map((item) => (
          <SequencesNavItem key={item.id} item={item} activeTab={activeTab} />
        ))}
      </div>
    </>
  );
};

export const MobileDrawerSequenceSelector = () => {
  const { search } = useLocation();
  const searchParams = useMemo(() => new URLSearchParams(search), [search]);
  const activeTab = searchParams.get('filter') || 'all';
  const drawerOpen = useComputedValue($sequenceSelectorDrawer, (r) => r.opened);

  return (
    <MobileDrawer
      opened={drawerOpen}
      onClose={() => closeSequenceSelectorDrawer()}
      title="Workflows"
    >
      <div className="m-[-16px]">
        <SequencesSidePanel activeTab={activeTab} />
      </div>
    </MobileDrawer>
  );
};

export const SequencesComponent: React.FC<SequencesListProps & SequencesProps> = (props) => {
  const { activeTab } = props;
  const currentTab = SEQUENCE_NAV_ITEMS.find((item) => item.id === activeTab);
  const [runsOpen, setRunsOpen] = useState<string>();

  return (
    <div className="w-full h-full @container">
      <Allotment>
        <Allotment.Pane>
          <Allotment vertical>
            <Allotment.Pane maxSize={MENU_SIZE} minSize={MENU_SIZE}>
              <div className="willy-dash-header bg-white flex flex-row items-center lg:w-full h-full grow overflow-hidden overflow-x-auto  px-6 py-4 lg:px-[24px]">
                <div className="flex items-center justify-between flex-grow">
                  <Text size="lg" weight={500}>
                    {currentTab?.label}
                  </Text>
                </div>
                <Button
                  onClick={() => {
                    $seqSettingsModal.set({ opened: true, isCreate: true });
                  }}
                  leftSection="plus-1"
                  iconSize={20}
                >
                  New Workflow
                </Button>
              </div>
            </Allotment.Pane>
            <Allotment.Pane className="dash-pane-wrapper !overflow-auto willy-main-pane relative @container">
              {typeof currentTab?.content === 'function'
                ? currentTab.content({ setRunsOpen })
                : currentTab?.content}
            </Allotment.Pane>
          </Allotment>
        </Allotment.Pane>
      </Allotment>
      <WillySequenceSettingsModal context="templates" />
      <SequenceRunsModal
        opened={!!runsOpen}
        close={() => setRunsOpen(undefined)}
        sequenceId={runsOpen}
      />
    </div>
  );
};

export const SequencesPage: React.FC<SequencesListProps> = (props) => {
  const { search } = useLocation();
  const searchParams = useMemo(() => new URLSearchParams(search), [search]);
  const activeTab = searchParams.get('filter') || 'all';

  return (
    <WillyPageWrapper
      className="willy-page"
      showResizer={true}
      hideSidePanel={false}
      sidePanelContent={<SequencesSidePanel activeTab={activeTab} />}
      mainContent={<SequencesComponent {...props} activeTab={activeTab} />}
    />
  );
};

export const Sequences = computeFeatureFlags(
  [FeatureFlag.TW_3_0_FF, FeatureFlag.TW_ONLY_3_0_FF],
  SequencesPage,
  () => (
    <UpgradePageFallBack
      InAppContextBannerEnabled={false}
      title="TW 3.0"
      description="Chat, analyze and manage with TW 3.0, the AI assistant that helps you effortlessly interact with your eCommerce business."
      featureFlag={FeatureFlag.TW_3_0_FF}
    />
  ),
);
