import { Banner, Button, ButtonGroup, Modal, Select, TextField } from '@shopify/polaris';
import { FC } from 'react';
import { useSelector } from 'react-redux';
import type { RootState } from 'reducers/RootType';
import { useAppDispatch } from 'index';
import { GOOGLE_SHEETS_BANNER_METADATA } from 'ducks/googleSheets';
import GoogleSheetsAccountSelector from 'components/GoogleSheetsAccountSelector';
interface IExportModalProps {
  showModal: boolean;
  title: string;
  onSave: () => void;
  onClose: () => void;
  loading: boolean;
  fileName: string;
  setFileName: React.Dispatch<React.SetStateAction<string>>;
}
export const ExportModal: FC<IExportModalProps> = ({
  showModal,
  title,
  onSave,
  onClose,
  loading,
  fileName,
  setFileName,
}) => {
  const dispatch = useAppDispatch();

  const handleSave = () => {
    onSave();
  };

  const handleClose = () => {
    setFileName('');
    onClose();
  };

  return (
    <Modal small open={showModal} onClose={handleClose} title={title}>
      <Modal.Section>
        <div className="flex flex-col space-y-3">
          <div className="p-4">
            <TextField
              clearButton
              label="File Name"
              type="text"
              autoComplete={'off'}
              value={fileName}
              onChange={(value) => setFileName(value)}
              placeholder="Export-File-Name"
              onClearButtonClick={() => setFileName('')}
            />
          </div>
        </div>
      </Modal.Section>
      <Modal.Section>
        <div className="flex flex-col">
          <ButtonGroup>
            <Button id="att-product-analytics-export-file-cancel" onClick={handleClose}>
              Cancel
            </Button>
            <Button
              primary
              id="att-product-analytics-export-file-save"
              onClick={handleSave}
              disabled={!fileName || fileName === '' || loading}
              loading={loading}
            >
              Save
            </Button>
          </ButtonGroup>
        </div>
      </Modal.Section>
    </Modal>
  );
};

interface IGoogleSheetsExportModalProps {
  showModal: boolean;
  onSave: () => void;
  onClose: () => void;
  loading: boolean;
  title: string;
  sheetsAccount: string;
  setTitle: React.Dispatch<React.SetStateAction<string>>;
  setSheetsAccount: React.Dispatch<React.SetStateAction<string>>;
}
export const GoogleSheetsExportModal: FC<IGoogleSheetsExportModalProps> = ({
  showModal,
  onSave,
  onClose,
  loading,
  title,
  setTitle,
  sheetsAccount,
  setSheetsAccount,
}) => {
  const dispatch = useAppDispatch();

  const { googleSheetsBannerMetadata } = useSelector((state: RootState) => state);
  const { text, link, bannerTitle, showBanner, status } = googleSheetsBannerMetadata;

  const handleSave = () => {
    onSave();
  };

  const handleClose = () => {
    setTitle('');
    dispatch({
      type: GOOGLE_SHEETS_BANNER_METADATA,
      payload: {
        text: 'There was an error exporting the data, please try again!',
        link: '',
        bannerTitle: 'Error',
        showBanner: false,
        status: 'critical',
      },
    });
    onClose();
  };

  return (
    <Modal small open={showModal} onClose={handleClose} title="Export to Google Sheets">
      <Modal.Section>
        <div className="flex flex-col space-y-3">
          {showBanner ? (
            <Banner status={status} title={bannerTitle}>
              {link && <a href={link} target="_blank">{`${text}`}</a>}
            </Banner>
          ) : (
            <>
              <div className="my-[1rem]">
                <TextField
                  clearButton
                  label="Title"
                  type="text"
                  autoComplete={'off'}
                  value={title}
                  onChange={(value) => setTitle(value)}
                  placeholder="Export-File-Name"
                  onClearButtonClick={() => setTitle('')}
                />
              </div>
              <div className="my-[1rem]">
                <GoogleSheetsAccountSelector onChange={setSheetsAccount} value={sheetsAccount} />
              </div>
            </>
          )}
        </div>
      </Modal.Section>
      <Modal.Section>
        <div className="flex flex-col">
          <ButtonGroup>
            <Button id="att-product-analytics-export-file-cancel" onClick={handleClose}>
              {`${showBanner ? 'Close' : 'Cancel'}`}
            </Button>
            {!showBanner && (
              <Button
                primary
                id="att-product-analytics-export-file-save"
                onClick={handleSave}
                disabled={!title || title === '' || loading}
                loading={loading}
              >
                Save
              </Button>
            )}
          </ButtonGroup>
        </div>
      </Modal.Section>
    </Modal>
  );
};
