import { Anchor, Flex, Icon, Tooltip } from '@tw/ui-components';
import { useIsNavWideStyle } from 'components/Nav/stores';
import { useDarkMode } from 'dark-mode-control';

export const BottomSectionLink = ({ href, icon, label }) => {
  const darkMode = useDarkMode();
  const isWide = useIsNavWideStyle();

  return (
    <Anchor
      underline="never"
      target="_blank"
      href={href}
      fw={500}
      fz="sm"
      color={darkMode ? 'gray.1' : 'gray.8'}
    >
      {isWide ? (
        <Flex align="center" gap="xs">
          <Flex>
            <Icon name={icon} />
          </Flex>
          <div style={{ whiteSpace: 'nowrap' }}>{label}</div>
        </Flex>
      ) : (
        <Tooltip label={label}>
          <Icon name={icon} size={20} />
        </Tooltip>
      )}
    </Anchor>
  );
};
