import SummaryDatePicker from 'components/SummaryDatePicker';
import {
  chartOpenChanged,
  changeSelectedColumns,
  changeAttributionFreeSearch,
} from 'ducks/attribution/actions';
import { useAppDispatch } from 'index';
import { useSelector } from 'react-redux';
import { type RootState } from 'reducers/RootType';
import { servicesWithEnabledAdSegmentation } from 'utils/selectors';
import { AllServicesIds, PixelColumn } from 'types/attribution';
import { ALL_SOURCES_ID, AFFLUENCER } from 'constants/types';
import AttributionDateModelPicker from 'components/attribution-new/AttributionDateModelPicker';
import AttributionModelPicker from 'components/attribution-new/AttributionModelPicker';
import { useHistory, useLocation } from 'react-router';
import { RulesEnginePopup } from 'components/RulesEngine/RulesEnginePopup';
import DropDown from 'components/ltv/DropDown';
import { ColumnsSelector } from 'components/library/TWTable/ColumnSelector';
import allServices from 'constants/services';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Columns3Major, FilterMajor } from '@shopify/polaris-icons';
import { localStoragePixelColumnsKeys } from 'constants/attribution';
import AttributionSettings from 'components/attribution-new/AttributionSettings';
import {
  selectAllPixelColumnsIncludingCustom,
  selectPixelColumnsForCustomMetrics,
} from 'pages/Attribution/selectPixelColumns';
import ReconnectBanner from './ReconnectFacebook/ReconnectBanner';
import { useDarkMode } from 'dark-mode-control';
import { snakeCase } from 'lodash';
import _db, { userDb } from 'utils/DB';
import { BaseColumn, savedPreset } from 'components/library/TWTable/types';
import { v4 as uuidV4 } from 'uuid';
import moment from '@tw/moment-cached/module/timezone';
import { CustomMetricsType } from 'ducks/customMetrics';
import { defaultPresets } from 'components/attribution-new/columns';
import { getSourcesList } from 'utils/attributions';
import { useAttributionActiveSource } from 'utils/useAttributionActiveSource';
import { useAttributionActivePage } from 'utils/useAttributionActivePage';
import { SourceTypesWithExtra } from 'types/services';
import { ActionIcon, Flex, Icon, TextInput, Tooltip, Text, Size, Button } from '@tw/ui-components';
import { useIsSmall } from 'hooks/useDefaultWindowSizes';
import { MetricsCurrentlyFiltered } from 'components/MetricsCurrentlyFiltered';
import { useStoreValue } from '@tw/snipestate';
import { $activeAppVersion } from '$stores/nav-config-stores';
import { AttributionFiltersDropdown } from 'components/attribution-new/AttributionFiltersDropdown';
import { useFeatureFlag } from 'feature-flag-system';
import { FeatureFlag } from '@tw/feature-flag-system/module/types';
import { dashboardsActions, genericEventLogger, analyticsEvents } from 'utils/dataLayer';
import { WillyMainChatRef } from 'components/Willy/WillyMainChat';

const emptyArray = [];

type AttributioHeaderProps = {
  chatOpen?: boolean;
  setChatOpen?: (val: boolean) => void;
  mainChatRef?: React.RefObject<WillyMainChatRef>;
};

const AttributionHeader: React.FC<AttributioHeaderProps> = ({
  chatOpen,
  setChatOpen,
  mainChatRef,
}) => {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const history = useHistory();
  const isSmall = useIsSmall();
  const darkMode = useDarkMode();

  const enabledSegments = useSelector(servicesWithEnabledAdSegmentation);
  const currentShopId = useSelector((state: RootState) => state.currentShopId);
  const hasPixelInstalled = useSelector((state: any) => state.hasPixelInstalled);
  const isFacebookConnected = useSelector((state: RootState) => state.isFacebookConnected);
  const enableRulesEngine = useSelector((state: RootState) => state.shop?.enableRulesEngine);
  const allPixelColumns = useSelector(selectAllPixelColumnsIncludingCustom);
  const customMetricsColumns = useSelector(selectPixelColumnsForCustomMetrics);
  const timezone = useSelector((state: RootState) => state.shopTimezone);
  const { chartOpen, selectedColumns, freeSearch, useNewModels } = useSelector(
    (state: RootState) => state.attribution,
  );
  const activeSource = useAttributionActiveSource();
  const sourceCategory = useAttributionActivePage();
  const sourcesList = useMemo(() => {
    if (sourceCategory === 'all') {
      return emptyArray;
    }
    return getSourcesList(sourceCategory || 'all');
  }, [sourceCategory]);

  const headerLabel = useMemo(() => {
    switch (sourceCategory) {
      case 'all':
        return 'Pixel';
      case 'ads':
        return 'Ads';
      case 'email':
        return 'Email / SMS';
      case 'social':
        return 'Organic';
      default:
        return '';
    }
  }, [sourceCategory]);

  const { shouldNotBeSeen: isChatBlocked } = useFeatureFlag(FeatureFlag.CHAT_FF);

  const [attributionSettingsOpen, setAttributionSettingsOpen] = useState(false);
  const [savedPresets, setSavedPresets] = useState<savedPreset[]>([]);
  const [filterWidth, setFilterWidth] = useState(isSmall ? '90px' : '');

  const activeAppVersion = useStoreValue($activeAppVersion);
  const isThreePointOh = activeAppVersion === '3.0';

  const key = localStoragePixelColumnsKeys.all;

  const collection = useMemo(() => {
    if (!currentShopId) return null;
    return _db(currentShopId).collection('attributionColumnPresets');
  }, [currentShopId]);

  const formatColumnsToRender = useCallback(
    (columns: PixelColumn[]) => {
      return columns.map((c) => ({
        ...c,
        columnMetadata: {
          subTitle: c.showInSources &&
            !c.showInSources.includes(activeSource) &&
            activeSource !== 'all' && (
              <p className="text-xl text-gray-400 italic">(Not available for this page)</p>
            ),
        },
        disableSort:
          c.showInSources && !c.showInSources.includes(activeSource) && activeSource !== 'all',
      }));
    },
    [activeSource],
  );

  const columnsToRender: BaseColumn<any, any>[] = useMemo(() => {
    return formatColumnsToRender(allPixelColumns);
  }, [allPixelColumns, formatColumnsToRender]);

  const selectedColumnsToRender = useMemo(() => {
    return formatColumnsToRender(selectedColumns);
  }, [selectedColumns, formatColumnsToRender]);

  const shouldShowAdsSegmentIndicator = useMemo(() => {
    return (
      enabledSegments.includes(activeSource) ||
      (activeSource === ALL_SOURCES_ID && sourcesList?.some((s) => enabledSegments.includes(s)))
    );
  }, [enabledSegments, activeSource, sourcesList]);

  const serviceOptions = useMemo(
    () => [
      { label: 'All', value: ALL_SOURCES_ID },
      ...(sourcesList?.map((s) => {
        const source = allServices[s];
        return {
          label: source?.title!,
          value: source?.id!,
          id: snakeCase('attribution_header_' + source?.title!),
        };
      }) || []),
      { label: 'Affluencer', value: AFFLUENCER },
    ],
    [sourcesList],
  );

  const handleChannelChange = useCallback(
    (id: AllServicesIds) => {
      history.push({
        pathname: `${
          location.pathname.includes('dashboards') ? '/dashboards' : ''
        }/attribution/${sourceCategory}/${id}`,
        search: location.search,
      });
    },
    [history, location, sourceCategory],
  );

  const toggleAttributionSettingsOpen = useCallback(() => {
    setAttributionSettingsOpen((o) => !o);
  }, []);

  useEffect(() => {
    (async () => {
      collection?.onSnapshot((snapshot) => {
        const presets = snapshot.docs.map((d) => ({ ...d.data(), id: d.id }) as any);
        const p = presets.map((p) => {
          return {
            ...p,
            columns: p.columns
              .map((c) => allPixelColumns.find((ac) => ac.key === c))
              .filter((c) => c),
          };
        });

        setSavedPresets(p);
      });
    })();
  }, [allPixelColumns, currentShopId, collection]);

  const savedPresetPlusDefault = useMemo(() => {
    const customMetricsPreset: savedPreset[] = customMetricsColumns?.length
      ? [
          {
            id: 'custom_metrics',
            name: 'Custom Metrics',
            description: 'Only metrics you created',
            columns: customMetricsColumns,
            isDefault: true,
          },
        ]
      : [];
    return [...savedPresets, ...defaultPresets['all'], ...customMetricsPreset];
  }, [savedPresets, customMetricsColumns]);

  const dropdownsGroup = useMemo(
    () => (
      <Flex gap="sm" align="center">
        {!isSmall && <AttributionDateModelPicker />}
        {!isSmall && (
          <AttributionModelPicker
            type={sourceCategory as SourceTypesWithExtra}
            sourceId={activeSource as AllServicesIds | 'tw_referrer'}
          />
        )}

        <DropDown
          id="att-header-channel-dropdown"
          value={activeSource}
          handleSelect={handleChannelChange}
          options={serviceOptions}
          disabled={sourceCategory === 'all'}
          size={isThreePointOh ? 'xs' : 'sm'}
        />
      </Flex>
    ),
    [activeSource, handleChannelChange, isSmall, isThreePointOh, serviceOptions, sourceCategory],
  );

  const chatWithQuerySection = useMemo(
    () => (
      <>
        <div className="flex-shrink-0">
          <Tooltip label={isChatBlocked ? 'Upgrade to Access Chat' : 'Chat with Report'}>
            <Button
              leftSection="star-plus"
              iconSize={16}
              variant="secondary"
              onClick={() => {
                setChatOpen?.(!chatOpen);
                if (!chatOpen) {
                  genericEventLogger(analyticsEvents.DASHBOARDS, {
                    action: dashboardsActions.OPEN_CHAT,
                    source: 'attribution',
                  });
                }
                mainChatRef?.current?.focusTextInput();
              }}
              disabled={isChatBlocked}
              size="xs"
            >
              Moby
            </Button>
          </Tooltip>
        </div>
        <div className="h-[30px] w-[1px] bg-[#D1D4DB]" />
      </>
    ),
    [chatOpen, isChatBlocked, mainChatRef, setChatOpen],
  );

  return (
    <div
      className={`flex items-center justify-between gap-4 ${
        isThreePointOh ? 'w-full flex-row-reverse' : 'flex-wrap'
      }`}
    >
      {!isFacebookConnected &&
        hasPixelInstalled &&
        activeSource === allServices['facebook-ads']!.id && <ReconnectBanner />}

      <Flex align="center" gap="sm" wrap={`${isThreePointOh ? 'nowrap' : 'wrap'}`}>
        {shouldShowAdsSegmentIndicator && <MetricsCurrentlyFiltered forceShow />}

        {isThreePointOh && !isChatBlocked && chatWithQuerySection}

        <TextInput
          id="att-header-filter-text-field"
          type="search"
          miw={isSmall ? filterWidth : 150}
          w={filterWidth}
          leadingIcon={<Icon name="search-major" size={12} />}
          value={freeSearch}
          onChange={(value) => dispatch(changeAttributionFreeSearch(value))}
          placeholder={`Filter ${activeSource === 'all' ? 'sources' : 'campaigns'}`}
          onFocus={() => isSmall && setFilterWidth('150px')}
          onBlur={() => isSmall && setFilterWidth('90px')}
          size={isThreePointOh ? 'xs' : 'sm'}
        />

        {enableRulesEngine && (
          <div id="att-header-rules-engine-popup">
            <RulesEnginePopup serviceId={activeSource}></RulesEnginePopup>
          </div>
        )}

        {!isThreePointOh && dropdownsGroup}
        <Tooltip label={chartOpen ? 'Hide Graph' : 'Show Graph'}>
          <div>
            <ActionIcon
              id="att-header-line-chart"
              size={isThreePointOh ? 'md' : 'lg'}
              radius="sm"
              onClick={() => dispatch(chartOpenChanged(!chartOpen))}
              variant="activator"
              icon="line-chart"
              bg={chartOpen ? 'gray.1' : 'transparent'}
            />
          </div>
        </Tooltip>

        {isThreePointOh && <div className="h-[30px] w-[1px] bg-[#D1D4DB]" />}

        <div id="att-header-column-selector">
          <ColumnsSelector
            selectedColumns={selectedColumnsToRender}
            columns={columnsToRender}
            icon={<Icon name="columns-3-major" size={18} />}
            storageKey={key!}
            title={
              <span className="flex items-center gap-3">
                <Icon name="columns-3-major" size={18} />
                <span>Select Columns</span>
              </span>
            }
            setSelectedColumns={async (columns) => {
              dispatch(changeSelectedColumns(columns));
              await userDb().set(
                {
                  shops: {
                    [currentShopId]: {
                      ui: {
                        attribution: {
                          selectedColumns: columns.map((c) => c.key),
                        },
                      },
                    },
                  },
                },
                {
                  merge: true,
                },
              );
            }}
            customMetricsType={CustomMetricsType.Attribution}
            savedPresets={savedPresetPlusDefault}
            allowSavePreset
            onSavePreset={async (selectedColumns, presetName, presetDescription = '') => {
              const uid = uuidV4();
              const newPreset = {
                name: presetName,
                description: presetDescription,
                columns: selectedColumns.map((c) => c),
                id: uid,
              };

              setSavedPresets((old) => [...old, newPreset]);
              await collection
                ?.doc(uid)
                .set(
                  { ...newPreset, columns: newPreset.columns.map((c) => c.key) },
                  { merge: true },
                );
            }}
            onDeletedPreset={async (presetId) => {
              setSavedPresets((old) => old.filter((p) => p.id !== presetId));
              await collection?.doc(presetId).delete();
            }}
            onEditPreset={async (id, name, description = '', columns) => {
              const newPreset = {
                name,
                description,
                columns,
                id,
              };

              setSavedPresets((old) => {
                return old.map((p) => {
                  if (p.id === id) {
                    return newPreset;
                  }

                  return p;
                });
              });
              await collection
                ?.doc(id)
                .set(
                  { ...newPreset, columns: newPreset.columns.map((c) => c.key) },
                  { merge: true },
                );
            }}
          />
        </div>
        <div id="att-header-settings">
          <AttributionSettings
            isOpen={attributionSettingsOpen}
            onOpen={toggleAttributionSettingsOpen}
            type="ads"
            hideAttributionModel={!isSmall}
            hideDateModel={!isSmall}
            hideOneDayConversionValue={activeSource !== 'facebook-ads' && sourceCategory !== 'ads'}
          />
        </div>
        {!isThreePointOh && (
          <div id="att-header-date-picker">
            <SummaryDatePicker
              showGroupByButton={false}
              showCompareButton
              earliestDateProp={useNewModels ? moment('2022-07-31').tz(timezone).toDate() : null}
              compareSeparately={isThreePointOh}
            />
          </div>
        )}
      </Flex>
      {isThreePointOh && (
        <Flex align="center" gap="md">
          <Flex align="center" pl="sm" gap="sm">
            <Icon name="pixel-cc" color={'gray.5'} size={18} />
            <Text color="gray.7" weight={500} size="lg">
              {headerLabel}
            </Text>
          </Flex>
          <div id="att-header-date-picker">
            <SummaryDatePicker
              showGroupByButton={false}
              showCompareButton
              earliestDateProp={useNewModels ? moment('2022-07-31').tz(timezone).toDate() : null}
              compareSeparately={isThreePointOh}
              buttonSize="xs"
            />
          </div>
          <div className="h-[30px] w-[1px] bg-[#D1D4DB]" />
          {dropdownsGroup}
          <AttributionFiltersDropdown />
        </Flex>
      )}
    </div>
  );
};

export default AttributionHeader;
