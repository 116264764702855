import { FC, useEffect, useRef, useState } from 'react';
import { FACEBOOK_APP_ID } from 'types/creativeCockpit';
import ReactPlayer from 'react-player';
import { buildCreativeVideoUrl } from './CreativesCockpit/utils';
interface FacebookVideoPlayerProps {
  videoId?: string;
  onVideoReady: ({ width, height }) => any;
}

const minWidth = '100%';
const minHeight = '100%';
let height = '100%';
let width = '100%';

const FacebookVideoPlayer: FC<FacebookVideoPlayerProps> = (props) => {
  const [videoUrl, setVideoUrl] = useState('');
  const { videoId, onVideoReady } = props;
  const fbVideoRef = useRef<any>(null);
  useEffect(() => {
    if (videoId) {
      setVideoUrl(buildCreativeVideoUrl('facebook-ads', videoId));
    }
  }, [videoId]);

  const videoLoaded = () => {
    if (fbVideoRef?.current) {
      const { wrapper } = fbVideoRef.current;
      const innerWrapper = wrapper?.getElementsByTagName('div')?.[0];
      const innerinnerWrapper = innerWrapper?.getElementsByTagName('span')?.[0];
      if (innerinnerWrapper) {
        let realWrapperHeight = innerinnerWrapper?.style['height'];
        let realWrapperWidth = innerinnerWrapper?.style['width'];
        height = realWrapperHeight;
        width = realWrapperWidth;
        onVideoReady({
          width: realWrapperWidth || minWidth,
          height: realWrapperHeight || minHeight,
        });
      }
    }
  };

  return (
    <div className="relative" style={{ minWidth, minHeight, height }}>
      <ReactPlayer
        ref={fbVideoRef}
        appId={FACEBOOK_APP_ID}
        width="100%"
        height="100%"
        url={videoUrl}
        videoId={videoId}
        id={videoId}
        config={{
          facebook: { appId: FACEBOOK_APP_ID },
        }}
        onReady={() => videoLoaded()}
      />
    </div>
  );
};

export default FacebookVideoPlayer;
