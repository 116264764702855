import { useSelector } from 'react-redux';
import { RootState } from 'reducers/RootType';
import STATS from '@tw/stats';
import { Collapse, Container, Flex, Stack, Text } from '@tw/ui-components';
import { SummaryMetricIdsTypes } from '@tw/types/module/SummaryMetrics';
import React, { FC, useCallback, useMemo, useState } from 'react';
import { ChevronUpMinor } from '@shopify/polaris-icons';
import { formatNumber } from 'utils/formatNumber';
import { RoundingOptionsMapper } from 'ducks/summary';
import { IconMapper, TWIconProps } from 'iconMap';
import { AllMetrics, MetricsBreakdownsDictionary } from './constants';
import { MetricBreakdownType, MetricWithBreakdownType } from './types';
import { isSummaryMetricsIconKeys } from '../../types/utils';

type MetricBreakdownProps = {
  metricId: SummaryMetricIdsTypes;
};

const calcBreakdownValue = (
  metricId: string,
  state,
  includeAmazon: boolean,
  metric?: MetricWithBreakdownType,
) => {
  if (typeof STATS[metricId] === 'function') return STATS[metricId](state);
  const breakdowns = includeAmazon
    ? metric?.breakdowns?.concat(metric?.amazonBreakdowns ?? [])
    : metric?.breakdowns;
  if (!breakdowns?.length) return 0;
  return breakdowns.reduce((acc, breakdown) => {
    const metricWithBreakdown = MetricsBreakdownsDictionary[breakdown.metricId];
    const breakdownValue = calcBreakdownValue(
      breakdown.metricId,
      state,
      includeAmazon,
      metricWithBreakdown,
    );
    switch (breakdown.operator) {
      case '+':
        return acc + breakdownValue;
      case '-':
        return acc - breakdownValue;
      case '*':
        return acc * breakdownValue;
      case '/':
        return acc / breakdownValue;
      default:
        return acc;
    }
  }, 0);
};

export const MetricBreakdown: React.FC<MetricBreakdownProps> = ({ metricId }) => {
  const currency = useSelector((state: RootState) => state.activeCurrency);
  const state = useSelector((state: RootState) => state);
  const selectedStatsServcies = useSelector((state: RootState) => state.selectedStatsServcies);
  const includeAmazon = useMemo(
    () => selectedStatsServcies.includes('amazon'),
    [selectedStatsServcies],
  );

  const metric = useMemo(() => {
    return MetricsBreakdownsDictionary[metricId];
  }, [metricId]);

  const {
    type,
    title,
    valueToFixed,
    metricId: allMetricsMetricId,
  } = (AllMetrics[metricId] as any) ?? ({} as any);

  const defaultRoundingOption = useSelector((state: RootState) => state.defaultRoundingOption);
  const currencyRounding = useMemo(() => {
    if (type === 'currency') {
      return RoundingOptionsMapper[defaultRoundingOption];
    }
    return null;
  }, [defaultRoundingOption, type]);

  const value = useMemo(() => {
    let val = calcBreakdownValue(allMetricsMetricId, state, includeAmazon, metric!);
    if (type === 'percent') val = val / 100;
    return val;
  }, [allMetricsMetricId, state, includeAmazon, metric, type]);

  if (!metric) return null;

  return (
    <Container p="md">
      {(includeAmazon && metric.amazonBreakdowns
        ? metric.breakdowns?.concat(metric.amazonBreakdowns)
        : metric?.breakdowns
      )?.map((metric) => {
        return <BreakdownLevel key={metric.metricId} breakdown={metric} isFirstLevel />;
      })}
      <BreakdownRow
        text={title ?? metricId}
        value={formatNumber(value, {
          style: type ?? 'decimal',
          currency,
          minimumFractionDigits: currencyRounding ?? valueToFixed ?? 0,
          maximumFractionDigits: currencyRounding ?? valueToFixed ?? 0,
        })}
        bold
      />
    </Container>
  );
};

const BreakdownLevel: FC<{
  breakdown: MetricBreakdownType;
  isFirstLevel?: boolean;
}> = ({ breakdown, isFirstLevel }) => {
  const state = useSelector((state: RootState) => state);
  const selectedStatsServcies = useSelector((state: RootState) => state.selectedStatsServcies);
  const includeAmazon = useMemo(
    () => selectedStatsServcies.includes('amazon'),
    [selectedStatsServcies],
  );

  const [collapsed, setCollapsed] = useState(false);
  const toggleCollapse = useCallback(() => setCollapsed((collapsed) => !collapsed), []);

  const { metricId, operator, summaryMetric } = breakdown;
  const { type, title, shortTitle, icon, valueToFixed, id } = summaryMetric;

  const metricWithBreakdown = useMemo(() => {
    return MetricsBreakdownsDictionary[id];
  }, [id]);

  const breakdowns = useMemo(
    () =>
      !includeAmazon
        ? metricWithBreakdown?.breakdowns
        : metricWithBreakdown?.breakdowns?.concat(metricWithBreakdown?.amazonBreakdowns ?? []),
    [includeAmazon, metricWithBreakdown?.breakdowns, metricWithBreakdown?.amazonBreakdowns],
  );

  const currency = useSelector((state: RootState) => state.activeCurrency);
  const defaultRoundingOption = useSelector((state: RootState) => state.defaultRoundingOption);
  const currencyRounding = useMemo(() => {
    if (type === 'currency') {
      return RoundingOptionsMapper[defaultRoundingOption];
    }
    return null;
  }, [defaultRoundingOption, type]);

  const value = useMemo(
    () => calcBreakdownValue(metricId, state, includeAmazon, metricWithBreakdown),
    [includeAmazon, metricId, metricWithBreakdown, state],
  );

  // const Icon = useMemo(() => {
  //   return typeof icon === 'string'
  //     ? IconMapper[icon]
  //     : typeof icon === 'function'
  //     ? () => icon()
  //     : null;
  // }, [icon]);

  const Icon: React.FC<TWIconProps> | null = useMemo(() => {
    if (typeof icon === 'string') {
      return IconMapper[icon];
    }
    if (typeof icon === 'function') {
      const res = icon(state);
      if (isSummaryMetricsIconKeys(res)) {
        return IconMapper[res];
      } else {
        return res as any;
      }
    }
  }, [icon, state]);

  return (
    <Stack gap="md" pb="md">
      <BreakdownRow
        toggleCollapse={breakdowns?.length ? toggleCollapse : undefined}
        collapsed={breakdowns?.length ? collapsed : undefined}
        text={title ?? shortTitle ?? metricId}
        value={formatNumber(value, {
          style: type ?? 'decimal',
          currency,
          minimumFractionDigits: currencyRounding ?? valueToFixed ?? 0,
          maximumFractionDigits: currencyRounding ?? valueToFixed ?? 0,
        })}
        bold={isFirstLevel}
        red={operator === '-'}
        Icon={Icon}
      />
      {breakdowns?.length && (
        <Collapse in={collapsed}>
          <Stack gap="sm" pl="lg">
            {breakdowns.map((breakdown) => (
              <BreakdownLevel key={breakdown.metricId} breakdown={breakdown} />
            ))}
          </Stack>
        </Collapse>
      )}
      {isFirstLevel && <div className="w-full h-[1px] bg-[#E5E7EB]"></div>}
    </Stack>
  );
};

type BreakdownRowProps = {
  text: string;
  value: string;
  toggleCollapse?: () => void;
  collapsed?: boolean;
  bold?: boolean;
  red?: boolean;
  Icon?: any;
};

const BreakdownRow: FC<BreakdownRowProps> = ({
  text,
  value,
  toggleCollapse,
  collapsed,
  bold,
  red,
  Icon,
}) => {
  return (
    <Flex
      justify="flex-start"
      gap="xs"
      align="center"
      onClick={() => (toggleCollapse ? toggleCollapse() : {})}
    >
      {Icon && <Icon />}
      <Text c="gray.7" size="md" weight={bold ? 600 : 400}>
        {text}
      </Text>
      {toggleCollapse && (
        <ChevronUpMinor
          width={16}
          className="transition-transform fill-[#6B7480] cursor-pointer"
          style={{
            transform: collapsed ? 'none' : 'rotate(180deg)',
          }}
        />
      )}
      <Text color={red ? 'red.6' : 'gray.7'} size="md" weight={bold ? 600 : 400} ml={'auto'}>
        {value}
      </Text>
    </Flex>
  );
};
