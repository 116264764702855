import { useCallback, useEffect, useState } from 'react';
import {
  AxisDomain,
  EditMetricModalPayload,
  WidgetQuery,
  GridColumnOptions,
  GridOptions,
  MessageTypes,
  TileModes,
  WillyMetric,
  WillyWidgetElement,
} from './types/willyTypes';
import { WillyWidget } from './WillyWidget';
import { WillyEditMetric } from './WillyEditMetric';
import { DEFAULT_AXIS_DOMAIN } from './constants';
import { cohortColor } from 'constants/general';

type WillyCustomQueryWidgetProps = {
  widget: WillyWidgetElement;
  queryVarsValues?: Record<string, string | number>;
  widgetSaved: (query: Partial<WillyWidgetElement>) => Promise<void>;
  currency: string;
  functionName?: string;
};

export const WillyCustomQueryWidget: React.FC<WillyCustomQueryWidgetProps> = ({
  widget,
  currency,
  queryVarsValues,
  widgetSaved,
  functionName,
}) => {
  const [grid, setGrid] = useState<GridOptions>('flex');
  const [gridColumns, setGridColumns] = useState<GridColumnOptions>(2);
  const [tileMode, setTileMode] = useState<TileModes>('tile');
  const [twoColumnMobile, setTwoColumnMobile] = useState(false);
  const [metrics, setMetrics] = useState<WillyMetric[]>(widget.metrics || []);
  const [dataMetricIds, setDataMetricsIds] = useState<string[]>([]);
  const [stacked, setStacked] = useState(false);
  const [skinny, setSkinny] = useState(false);
  const [yAxisDomain, setYAxisDomain] = useState<AxisDomain>(DEFAULT_AXIS_DOMAIN);
  const [allowDataOverflow, setAllowDataOverflow] = useState(false);
  const [dimension, setDimension] = useState('');
  const [incrementedStacked, setIncrementedStacked] = useState(false);
  const [hasConditionalFormatting, setHasConditionalFormatting] = useState(false);
  const [conditionalFormattingColor, setConditionalFormattingColor] = useState(cohortColor);
  const [filtersOpen, setFiltersOpen] = useState(false);
  const [wrapText, setWrapText] = useState(false);
  const [title, setTitle] = useState(widget.title);
  const [type, setType] = useState<MessageTypes>(widget.type || 'table');
  const [queries, setQueries] = useState<WidgetQuery[]>(widget.queries || []);
  const [errorLoadingData, setErrorLoadingData] = useState(false);
  const [breakdownMode, setBreakdownMode] = useState(false);
  const [editMetricModalOpen, setEditMetricModalOpen] = useState<
    Parameters<EditMetricModalPayload>[0]
  >({
    open: false,
  });

  const metricsChanged = useCallback(
    async (id: string, metrics: WillyMetric[]) => {
      setMetrics(metrics);
      await widgetSaved({ metrics });
    },
    [widgetSaved],
  );

  const titleChanged = useCallback(
    (title: string) => {
      setTitle(title);
      widgetSaved({ title });
    },
    [widgetSaved],
  );

  const typeChanged = useCallback(
    (type: MessageTypes) => {
      setType(type);
      widgetSaved({ type });
    },
    [widgetSaved],
  );

  const dataMetricIdsChanged = useCallback(async (id: string, metricsIds: string[]) => {
    setDataMetricsIds(metricsIds);
  }, []);

  const queriesChanged = useCallback(
    async (queries: WidgetQuery[]) => {
      setQueries(queries);
      widgetSaved({ queries });
    },
    [widgetSaved],
  );

  useEffect(() => {
    if (!widget.metrics) {
      return;
    }

    setMetrics(widget.metrics);
  }, [widget.metrics]);

  useEffect(() => {
    if (!widget.queries) {
      return;
    }

    setQueries(widget.queries);
  }, [widget.queries]);

  if (errorLoadingData) {
    return (
      <div className="text-red-500 px-4">
        Error loading data, reopen the popup or edit the query
      </div>
    );
  }

  return (
    <div>
      <WillyWidget
        permission={{ providers: [] }}
        permissionChanged={() => {}}
        context="dashboard"
        isDynamic
        shouldReplaceTablesWithNlq
        metrics={metrics || []}
        queries={queries}
        currency={currency}
        incrementedStacked={incrementedStacked}
        wrapText={wrapText}
        stacked={stacked}
        title={title}
        type={type}
        queryId={widget.queryId}
        functionName={functionName}
        stackedChanged={setStacked}
        incrementedStackedChanged={setIncrementedStacked}
        setWrapText={setWrapText}
        metricsChanged={metricsChanged}
        titleChanged={titleChanged}
        typeChanged={typeChanged}
        queriesChanged={queriesChanged}
        // suppressMetricClick
        setEditMetricModalOpen={setEditMetricModalOpen}
        grid={grid}
        setGrid={setGrid}
        gridColumns={gridColumns}
        setGridColumns={setGridColumns}
        twoColumnMobile={twoColumnMobile}
        setTwoColumnMobile={setTwoColumnMobile}
        tileMode={tileMode}
        setTileMode={setTileMode}
        queryVariablesForPopup={queryVarsValues}
        skinny={skinny}
        setSkinny={setSkinny}
        yAxisDomain={yAxisDomain}
        setYAxisDomain={setYAxisDomain}
        allowDataOverflow={allowDataOverflow}
        setAllowDataOverflow={setAllowDataOverflow}
        dimension={dimension}
        setDimension={setDimension}
        dataMetricIds={dataMetricIds}
        dataMetricIdsChanged={dataMetricIdsChanged}
        hasGlobalConditionalFormatting={hasConditionalFormatting}
        setHasGlobalConditionalFormatting={setHasConditionalFormatting}
        globalConditionalFormattingColor={conditionalFormattingColor}
        setGlobalConditionalFormattingColor={setConditionalFormattingColor}
        breakdownMode={breakdownMode}
        breakdownModeChanged={async (val) => {
          setBreakdownMode(val);
        }}
        dialect={widget.dialect}
        mode={widget.mode}
        builderSetup={widget.builderSetup}
        builderSetupChanged={async (builderSetup) => {
          await widgetSaved({ builderSetup });
        }}
        filtersOpen={filtersOpen}
        setFiltersOpen={setFiltersOpen}
      />

      <WillyEditMetric
        open={editMetricModalOpen.open}
        metric={metrics?.find((m) => m.key === editMetricModalOpen.metricId) ?? null}
        availableMetrics={metrics}
        onClose={() => setEditMetricModalOpen({ open: false })}
        onSaved={async (metricToSave) => {
          const newMetrics: WillyMetric[] = metrics.map((m) => {
            if (m.key === editMetricModalOpen.metricId) {
              return {
                ...m,
                ...metricToSave,
              };
            }
            return m;
          });
          await widgetSaved({
            ...widget,
            metrics: newMetrics,
          });
          setEditMetricModalOpen({ open: false });
        }}
        onRemoved={async (metricToRemove) => {
          const newMetrics: WillyMetric[] = metrics.filter((m) => m.key !== metricToRemove.key);
          await widgetSaved({
            ...widget,
            metrics: newMetrics,
          });
          setEditMetricModalOpen({ open: false });
        }}
      />
    </div>
  );
};
