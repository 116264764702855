import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import 'firebase/compat/auth';
import { isDevFirestore } from '../config';
import { serviceAds } from '../ducks/constants';
import { Shop } from '@tw/types';
import { services, ServicesIds } from '@tw/types/module/services';
import Cookies from './Cookies';

export function getUserId() {
  return firebase.auth().currentUser?.uid;
}

export function getAuthUser() {
  return firebase.auth().currentUser;
}

export function getUserEmail() {
  return firebase.auth().currentUser?.email || '';
}

function firestore() {
  let db = firebase.firestore();
  // firebase.firestore.setLogLevel('debug');
  //if (location.hostname === "localhost") {
  if (isDevFirestore) {
    db.settings({
      host: 'localhost:8080',
      ssl: false,
    });
  }
  return db;
}
export const firestoreRef = firestore;

function _db(shopId?: string | null) {
  let shopDomain = shopId || Cookies.get('currentShopId')!;
  return firestore().collection('shops').doc(shopDomain);
}

export async function getShopifySourcesDictionary() {
  return (
    await firestore().collection('shopify_sources_dictionary').doc('dictionary').get()
  ).data();
}

export function appsDb(shopId?: string) {
  return _db(shopId).collection('apps');
}

export function workspaceDb() {
  return firestore().collection('workspaces');
}

export function userDb(uid?: string) {
  return firestore()
    .collection('users')
    .doc(uid || getUserId());
}

export async function userData(uid?: string) {
  return (
    await firestore()
      .collection('users')
      .doc(uid || getUserId())
      .get()
  ).data();
}

export function userShopDb() {
  return firestore().collection('users').doc(getUserId());
}

export function stats(shopDomain) {
  return firestore().collection('stats').doc(shopDomain);
}

export function pixelAggregatedJourneyDb(shopDomain) {
  return firestore().collection('pixel_aggregated_journeys').doc(shopDomain).collection('day');
}

export function generalUtmDb() {
  return firestore().collection('utms').doc('generalUtms');
}

export function shopsAmazonDb(sellerAccountId) {
  return firestore().collection('shopsAmazon').doc(String(sellerAccountId));
}
export function globalSettingsConfigurations() {
  return firestore().collection('globalSettings').get();
}

export function shopServicesDb() {
  return _db().collection('services');
}

export function influencersDb() {
  return _db().collection('influencers');
}

export const FieldValue = firebase.firestore.FieldValue;
export const FieldPath = firebase.firestore.FieldPath;
export const serverTimestamp = FieldValue.serverTimestamp;
export const Timestamp = firebase.firestore.Timestamp;

export default _db;

export function toArray<T = any>(snapshot) {
  const docs: T[] = [];

  snapshot.forEach((doc) => {
    docs.push({
      ...doc.data(),
      id: isNaN(doc.id) ? doc.id : +doc.id,
    });
  });
  return docs;
}

export const toHash = (snapshot) => {
  const docs = {};
  snapshot.forEach((doc) => {
    docs[doc.id] = {
      ...(typeof doc.data == 'function' ? doc.data() : doc),
      id: isNaN(doc.id) ? doc.id : +doc.id,
    };
  });
  return docs;
};

export function batch() {
  let db = firebase.firestore();
  return db.batch();
}

export async function getShopData(): Promise<Shop> {
  const snapshot = await _db().get();
  const data = snapshot.data() as Shop;
  if (data) {
    return { ...snapshot.data(), id: Cookies.get('currentShopId') } as Shop;
  }
  return data;
}

export async function getShopById(shopDomain) {
  const snapshot = await firestore().collection('shops').doc(shopDomain).get();

  const adSegmentsArray = await Promise.all(
    serviceAds.map(async (service) => {
      const segmentsServices = (
        await _db(shopDomain).collection('ads-segments').doc(service).collection('segments').get()
      ).docs;
      return Object.fromEntries(segmentsServices.map((doc) => [doc.id, doc.data()]));
    }),
  );
  const segments = Object.fromEntries(adSegmentsArray.map((k, i) => [serviceAds[i], k]));
  let shop = snapshot.data();
  if (shop) {
    shop['id'] = shopDomain;
    shop['shopId'] = shopDomain;
  }

  const connectedServices: { [key in ServicesIds]?: boolean } = {
    'facebook-ads': !!shop!.facebookAccessToken,
    'google-ads': !!shop!.googleAdsAccessToken,
    'tiktok-ads': !!shop!.tiktokAccessToken,
    'pinterest-ads': !!shop!.pinterestToken,
    'snapchat-ads': !!shop!.snapchatAccessToken,
    bing: services['bing'].getShopProviderStatus?.(shop as Shop).status === 'connected',
    klaviyo: !!shop!.klaviyoKey,
    amazon: services['amazon'].getShopProviderStatus?.(shop as Shop).status === 'connected',
  };
  return { ...shop, segments, connectedServices };
}
