import {
  ActionIcon,
  Badge,
  Button,
  Checkbox,
  confirm,
  Flex,
  Group,
  MobileDrawer,
  Modal,
  Popover,
  Tabs,
  Text,
  Textarea,
  TextInput,
} from '@tw/ui-components';
import { baseURL } from '../../../config';
import React, { Fragment, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { removeDashImage, updateDashImage } from '../utils/dashImageCrud';
import { toast } from 'react-toastify';
import { WillyEmoji } from '../types/emojiTypes';
import { $dialect, $isTwGlobalDashboardCreatorClaim, $user } from '$stores/$user';
import { updateDashboardForFFConfigs } from '../api/updateDashboardForFFConfigs';
import { WillyBaseMainElement, WillyDataSequence, HistoryItem, Dialect } from '../types/willyTypes';
import { SalesPlatform, ServicesIds } from '@tw/types/module/services';
import { emptyEmoji } from './WillyDashDescription';
import { $seqSettingsModal, closeSeqSettingsModal } from '../../../$stores/willy/$seqSettingsModal';
import _db, { firestoreRef, Timestamp } from '../../../utils/DB';
import { v4 as uuidV4 } from 'uuid';
import { MobilePlusMajor } from '@shopify/polaris-icons';
import { Button as PolarisButton } from '@shopify/polaris';
import { CopyToClipboard } from '../CopyToClipboard';
import { WillyDashAndSeqBaseSettings } from './WillyDashAndSeqBaseSettings';
import { FeatureFlag } from '@tw/feature-flag-system/module/types';
import {
  analyticsEvents,
  chatActions,
  genericEventLogger,
  templateLibraryActions,
  sequencesActions,
} from 'utils/dataLayer';
import { DEFAULT_DIALECT, willyToolMap } from '../constants';
import { startCase } from 'lodash';
import axiosInstance from 'utils/axiosInstance';
import { $currentShopId } from '$stores/$shop';
import { useStoreValue, useWritableStore } from '@tw/snipestate';
import { createNewSequence } from '../utils/sequences';
import { DashboardsDropDown } from '../DashboardsDropDown';
import { WillyScheduleSequence } from '../WillyScheduleSequence';
import { $shopSequences } from '$stores/willy/$sequences';
import { DateOptionsList } from 'pages/FreeQuery/DateOptionsList';
import {
  DatePickerOptionValue,
  DatePickerTimePeriods,
  getDatePickerOptionsDictionary,
  getDatePickerOptionValueOptions,
} from 'components/useDatePickerSelectedOptions';

const tabs = [
  { value: 'sequence', label: 'Sequence' },
  { value: 'schedule', label: 'Schedule' },
];

type WillySequenceSettingsProps = {
  context: 'templates' | 'chat';
  isSmall?: boolean;
};

export const WillySequenceSettings: React.FC<WillySequenceSettingsProps> = ({ context }) => {
  const [{ opened, sequence, conversationId, isCreate, userMessages }, setSettings] =
    useWritableStore($seqSettingsModal);

  const sequences = useStoreValue($shopSequences);
  const nameSuggestedRef = useRef(false);

  const userHistoryItems = useMemo(() => {
    if (!userMessages) {
      return [];
    }
    return userMessages.map<HistoryItem>((x) => {
      return {
        messageId: x.id,
        role: 'user',
        text: x.text || '',
        createdAt: Timestamp.now().toDate().toISOString(),
      };
    });
  }, [userMessages]);

  const isTwGlobalDashboardCreatorClaim = useStoreValue($isTwGlobalDashboardCreatorClaim);
  const user = useStoreValue($user);
  const currentShopId = useStoreValue($currentShopId);
  const [loading, setLoading] = useState(false);
  const [file, setFile] = useState<File>();
  const [imageSrc, setImageSrc] = useState<string | undefined>(undefined);
  const [name, setName] = useState<string>('');
  const [isGlobal, setIsGlobal] = useState<boolean>(false);
  const [userPrompts, setUserPrompts] = useState<HistoryItem[]>([]);
  const [startingDashboard, setStartingDashboard] = useState<string>('');
  const [startingDashboardDate, setStartingDashboardDate] = useState<DatePickerTimePeriods>(
    DatePickerTimePeriods.TODAY,
  );
  const [dateOptionsListOpened, setDateOptionsListOpened] = useState(false);
  const [description, setDescription] = useState<string>('');
  const [reportPrompt, setReportPrompt] = useState<string>('');
  const [category, setCategory] = useState<string>('');
  const [roles, setRoles] = useState<string[]>([]);
  const [emoji, setEmoji] = useState<WillyEmoji>(emptyEmoji as WillyEmoji);
  const [isBeta, setIsBeta] = useState<boolean>(false);
  const [isHide, setIsHide] = useState<boolean>(false);
  const [dialect, setDialect] = useState<Dialect>($dialect.get());
  const [suggestingTitle, setSuggestingTitle] = useState(false);
  const [activeTab, setActiveTab] = useState(tabs[0].value);
  const [providers, setProviders] = useState<ServicesIds[]>([]);
  const [providersBlockingCombination, setProvidersBlockingCombination] = useState<'AND' | 'OR'>(
    'AND',
  );
  const [msps, setMsps] = useState<SalesPlatform[]>([]);

  const [featureFlagConfigs, setFeatureFlagConfigs] = useState<any[]>(sequence?.packages ?? []);
  const [featureFlagDefaultCopyConfigs, setFeatureFlagDefaultCopyConfigs] = useState<any[]>(
    sequence?.defaultPackages ?? [],
  );

  const datePickerOptionValueOptions = useMemo(() => {
    return getDatePickerOptionsDictionary();
  }, []);

  const activeSchedule = useMemo(() => {
    const activeSequence = sequences.find((x) => x.id === sequence?.id);
    if (!activeSequence?.schedule) {
      return;
    }

    return activeSequence.schedule;
  }, [sequences, sequence?.id]);

  const ffConfigsToRemoveDashId = useMemo(() => {
    const packages = sequence?.packages ?? [];
    return packages.filter((p) => !featureFlagConfigs?.includes(p));
  }, [featureFlagConfigs, sequence?.packages]);

  const ffConfigsDefaultToRemoveDashId = useMemo(() => {
    const packages = sequence?.defaultPackages ?? [];
    return packages.filter((p) => !featureFlagDefaultCopyConfigs?.includes(p));
  }, [featureFlagDefaultCopyConfigs, sequence?.defaultPackages]);

  const loadingFields = useMemo(() => {
    const anyLoading = {
      name: !!suggestingTitle,
    };

    return anyLoading;
  }, [suggestingTitle]);

  const currentAnalyticsEvent = useMemo(() => {
    return context === 'chat'
      ? analyticsEvents.CHAT
      : sequence
        ? analyticsEvents.SEQUENCES
        : analyticsEvents.TEMPLATE_LIBRARY;
  }, [context, sequence]);

  const currentAnalyticsActions = useMemo(() => {
    return context === 'chat' ? chatActions : sequence ? sequencesActions : templateLibraryActions;
  }, [context, sequence]);

  const newId = useMemo(() => {
    if (opened) {
      return uuidV4();
    }

    return '';
  }, [opened]);

  const saveImage = useCallback(
    async (seq: WillyBaseMainElement) => {
      if (file) {
        await updateDashImage(file, seq);
      }
    },
    [file],
  );

  const suggestTitle = useCallback(async () => {
    if (!opened || name || !conversationId || nameSuggestedRef.current) {
      return;
    }

    try {
      setSuggestingTitle(true);
      const conversation = await _db().collection('conversations').doc(conversationId).get();
      const userMessages = conversation.data()?.history?.filter((x) => x.role === 'user');
      if (!userMessages) {
        return;
      }

      const { data } = await axiosInstance.post<any, { data: { completion: string } }>(
        '/v2/willy/suggest-sequence-title',
        {
          shopId: currentShopId,
          messages: userMessages.map((x) => x.text),
        },
      );

      setName(data?.completion ?? '');
      nameSuggestedRef.current = true;
    } catch (e) {
      console.error(e);
    } finally {
      setSuggestingTitle(false);
    }
  }, [opened, name, currentShopId, conversationId]);

  const onSave = useCallback(
    async (continueToSchedule = false) => {
      if (!currentShopId || !user.uid) {
        toast.error('Shop ID is required');
        return;
      }
      const ref = isGlobal
        ? firestoreRef().collection('global_data_sequences')
        : _db().collection('data_sequences');

      if (isCreate) {
        setLoading(true);
        const { error, success, message, conversationData, sequence } = await createNewSequence({
          shopId: currentShopId,
          userId: user.uid,
          dialect,
          conversationId,
          messages: userPrompts,
          startingDashboard,
          startingDashboardDate,
          baseMainElement: {
            id: newId,
            type: 'sequence',
            canEdit: false,
            createdAt: Timestamp.now(),
            updatedAt: Timestamp.now(),
            name,
            description,
            emoji,
            isGlobal,
            roles,
            isBeta,
            isHide,
            category,
            dialect,
            providers,
            providersBlockingCombination,
            packages: featureFlagConfigs,
            defaultPackages: featureFlagDefaultCopyConfigs,
            msps,
          },
        });
        if (error) {
          toast.error(error);
        } else if (success) {
          if (isGlobal && isTwGlobalDashboardCreatorClaim && conversationData) {
            await updateDashboardForFFConfigs(
              {
                configs: featureFlagConfigs,
                dashboardId: conversationData.id,
                mergeStrategy: 'merge',
              },
              FeatureFlag.TEMPLATES_FF,
            );
            await updateDashboardForFFConfigs(
              {
                configs: featureFlagDefaultCopyConfigs,
                dashboardId: conversationData.id,
                mergeStrategy: 'merge',
              },
              FeatureFlag.WILLY_DEFAULT_TEMPLATES_FF,
            );
          }
          genericEventLogger(currentAnalyticsEvent, {
            action: currentAnalyticsActions.CREATE_SEQUENCE,
            sequence_id: conversationData?.id,
            sequence_name: conversationData?.title,
          });
          toast.success(message);
          setSettings((old) => ({ ...old, sequence: sequence }));
          if (continueToSchedule) {
            setActiveTab('schedule');
          }
        }
        setLoading(false);
      } else {
        // edit
        if (!sequence) return;
        if (isGlobal) {
          const confirmed = await confirm({
            title: 'Edit Template',
            message: 'Are you sure you want to edit this template?',
          });
          if (!confirmed) {
            return;
          }
        }
        setLoading(true);
        try {
          let newSequence: Partial<WillyDataSequence> = {
            description,
            updatedAt: Timestamp.now(),
            name,
            emoji,
            history: userPrompts,
            dialect,
            reportPrompt,
            startingDashboard,
            startingDashboardDate: startingDashboardDate,
          };

          if (isGlobal) {
            newSequence = {
              ...newSequence,
              providers,
              providersBlockingCombination,
              category,
              roles,
              isBeta,
              isHide,
              dialect,
              msps,
            };
          }
          await saveImage(sequence);
          await ref.doc(sequence.id).set(newSequence, { merge: true });
          // Update packages that have access to this dashboard
          if (isGlobal && isTwGlobalDashboardCreatorClaim) {
            await Promise.all([
              updateDashboardForFFConfigs(
                {
                  configs: featureFlagConfigs,
                  mergeStrategy: 'merge',
                  dashboardId: sequence.id,
                },
                FeatureFlag.TEMPLATES_FF,
              ),
              updateDashboardForFFConfigs(
                {
                  configs: ffConfigsToRemoveDashId,
                  mergeStrategy: 'delete',
                  dashboardId: sequence.id,
                },
                FeatureFlag.TEMPLATES_FF,
              ),
              updateDashboardForFFConfigs(
                {
                  configs: featureFlagDefaultCopyConfigs,
                  mergeStrategy: 'merge',
                  dashboardId: sequence.id,
                },
                FeatureFlag.WILLY_DEFAULT_TEMPLATES_FF,
              ),
              updateDashboardForFFConfigs(
                {
                  configs: ffConfigsDefaultToRemoveDashId,
                  mergeStrategy: 'delete',
                  dashboardId: sequence.id,
                },
                FeatureFlag.WILLY_DEFAULT_TEMPLATES_FF,
              ),
            ]);
          }

          const messagesChanged = sequence.history.some(
            (x, i) => x.text?.trim() !== userPrompts[i]?.text?.trim(),
          );
          if (!isGlobal && messagesChanged) {
            await ref.doc(sequence.id).set({ assistantMessages: [] }, { merge: true });
          }
          if (continueToSchedule) {
            setActiveTab('schedule');
          }
          genericEventLogger(currentAnalyticsEvent, {
            action: currentAnalyticsActions.UPDATE_SEQUENCE,
            sequence_id: sequence.id,
            sequence_name: sequence.name,
          });
          toast.success('Sequence updated successfully!');
        } catch (e) {
          console.error(e);
          toast.error('Could not save sequence');
        } finally {
          setLoading(false);
        }
      }
    },
    [
      isGlobal,
      isCreate,
      conversationId,
      description,
      name,
      emoji,
      reportPrompt,
      user.uid,
      saveImage,
      isTwGlobalDashboardCreatorClaim,
      currentAnalyticsEvent,
      currentAnalyticsActions.CREATE_SEQUENCE,
      currentAnalyticsActions.UPDATE_SEQUENCE,
      providers,
      providersBlockingCombination,
      category,
      roles,
      isBeta,
      isHide,
      dialect,
      featureFlagConfigs,
      featureFlagDefaultCopyConfigs,
      sequence,
      userPrompts,
      ffConfigsToRemoveDashId,
      ffConfigsDefaultToRemoveDashId,
      newId,
      currentShopId,
      startingDashboard,
      startingDashboardDate,
      setSettings,
      msps,
    ],
  );

  useEffect(() => {
    suggestTitle();
  }, [suggestTitle]);

  useEffect(() => {
    if (!opened) {
      setName('');
      setDescription('');
      setCategory('');
      setStartingDashboard('');
      setStartingDashboardDate(DatePickerTimePeriods.TODAY);
      setRoles([]);
      setIsGlobal(false);
      setFeatureFlagConfigs([]);
      setFeatureFlagDefaultCopyConfigs([]);
      setUserPrompts([]);
      setEmoji(emptyEmoji as WillyEmoji);
      setIsBeta(false);
      setIsHide(false);
      setProviders([]);
      setProvidersBlockingCombination('AND');
      setImageSrc(undefined);
      setFile(undefined);
      setMsps([]);
    }
  }, [opened]);

  useEffect(() => {
    if (sequence) {
      setName(sequence.name ?? '');
      setReportPrompt(sequence.reportPrompt ?? '');
      setDescription(sequence.description ?? '');
      setStartingDashboard(sequence.startingDashboard ?? '');
      setStartingDashboardDate(sequence.startingDashboardDate as DatePickerTimePeriods);
      setIsGlobal(sequence.isGlobal ?? false);
      setUserPrompts(sequence.history?.map((x) => ({ ...x, text: x.text ?? '' })) ?? []);
      setCategory(sequence.category ?? '');
      setRoles(sequence.roles ?? []);
      setFeatureFlagConfigs(sequence.packages ?? []);
      setFeatureFlagDefaultCopyConfigs(sequence.defaultPackages ?? []);
      setEmoji(sequence.emoji ?? (emptyEmoji as WillyEmoji));
      setIsBeta(sequence.isBeta ?? false);
      setIsHide(sequence.isHide ?? false);
      setDialect('clickhouse');
      setProviders(sequence.providers ?? []);
      setProvidersBlockingCombination(sequence.providersBlockingCombination ?? 'AND');
      setImageSrc(
        sequence?.image && sequence?.image === sequence.id
          ? `${baseURL}/v2/media/dashboard-image/${sequence.id}`
          : undefined,
      );
      setMsps(sequence.msps ?? []);
    } else {
      setName('');
      setDescription('');
      setCategory('');
      setStartingDashboard('');
      setStartingDashboardDate(DatePickerTimePeriods.TODAY);
      setRoles([]);
      setIsGlobal(false);
      setFeatureFlagConfigs([]);
      setFeatureFlagDefaultCopyConfigs([]);
      setUserPrompts(userHistoryItems);
      setEmoji(emptyEmoji as WillyEmoji);
      setIsBeta(false);
      setIsHide(false);
      setProviders([]);
      setProvidersBlockingCombination('AND');
      setImageSrc(undefined);
      setFile(undefined);
      setMsps([]);
    }
  }, [sequence, userHistoryItems]);

  return (
    <Flex direction="column" gap="md" pt="md">
      <Flex direction="column" gap="md" pt="md">
        <Tabs
          value={activeTab}
          onChange={(id) => {
            if (!id) {
              return;
            }
            setActiveTab(id);
          }}
        >
          <Tabs.List>
            <Tabs.Tab value="sequence">
              <Text size="md" color="gray.7">
                Settings
              </Text>
            </Tabs.Tab>
            <Tabs.Tab value="schedule" disabled={!sequence?.id || !name || !userPrompts.length}>
              <Text size="md" color="gray.7">
                Schedule
              </Text>
            </Tabs.Tab>
          </Tabs.List>
        </Tabs>

        {activeTab === 'schedule' && (
          <WillyScheduleSequence
            scheduleSequenceId={sequence?.id || ''}
            schedule={activeSchedule}
            onClose={() => {
              setSettings({ opened: false });
            }}
          />
        )}
        {activeTab === 'sequence' && (
          <Flex direction="column" gap="md">
            {isTwGlobalDashboardCreatorClaim && (
              <Checkbox
                label={'Global'}
                checked={isGlobal}
                disabled={!isCreate}
                onChange={setIsGlobal}
              />
            )}

            <TextInput
              disabled
              value={sequence?.id || newId}
              label="ID"
              trailingIcon={<CopyToClipboard text={sequence?.id || ''} />}
            />

            <WillyDashAndSeqBaseSettings
              id={sequence?.id}
              name={name}
              setName={setName}
              description={description}
              setDescription={setDescription}
              category={category}
              setCategory={setCategory}
              roles={roles}
              setRoles={setRoles}
              isBeta={isBeta}
              setIsBeta={setIsBeta}
              isHide={isHide}
              setIsHide={setIsHide}
              dialect={dialect}
              setDialect={(d) => setDialect(d === 'bigquery' ? 'bigquery' : 'clickhouse')}
              isGlobal={isGlobal}
              featureFlagConfigs={featureFlagConfigs}
              featureFlagDefaultCopyConfigs={featureFlagDefaultCopyConfigs}
              setFeatureFlagConfigs={setFeatureFlagConfigs}
              setFeatureFlagDefaultCopyConfigs={setFeatureFlagDefaultCopyConfigs}
              providers={providers}
              setProviders={setProviders}
              providersBlockingCombination={providersBlockingCombination}
              setProvidersBlockingCombination={setProvidersBlockingCombination}
              setFile={setFile}
              imageSrc={imageSrc}
              setImageSrc={setImageSrc}
              isReportSettings={true}
              emoji={emoji}
              setEmoji={setEmoji}
              image={sequence?.image}
              removeDashImage={() => removeDashImage(sequence!)}
              loading={loadingFields}
              msps={msps}
              setMsps={setMsps}
              credits={sequence?.credits}
              setCredits={() => {}}
            />
            <Flex justify="space-between" align="center">
              <Text size={'md'} color={'gray.7'}>
                Sequence Prompts
              </Text>
              <Flex cursor="pointer" align="center">
                <PolarisButton
                  icon={MobilePlusMajor}
                  plain
                  onClick={() => {
                    const id = uuidV4();
                    setUserPrompts([
                      ...userPrompts,
                      {
                        createdAt: new Date().toISOString(),
                        messageId: id,
                        text: '',
                        role: 'user',
                      },
                    ]);
                  }}
                >
                  Add New Prompt
                </PolarisButton>
              </Flex>
            </Flex>
            {userPrompts.map((message) => {
              const assistantAnswerTools = sequence?.assistantMessages
                ?.filter((x) => x.originalQuestion === message.text)
                .map((x) => x.toolNames || [])
                .flat();
              return (
                <Flex key={message.messageId} align="center" gap="xs">
                  <div className="flex-auto flex flex-col gap-2">
                    <Textarea
                      value={message.text || ''}
                      minRows={3}
                      onChange={(v) => {
                        setUserPrompts((prev) => {
                          return prev.map((item) => {
                            if (item.messageId === message.messageId) {
                              return {
                                ...item,
                                text: v.target.value,
                              };
                            }
                            return item;
                          });
                        });
                      }}
                    />
                    <div className="flex flex-wrap gap-2 items-center">
                      {!!assistantAnswerTools?.length && (
                        <Fragment>
                          <Text size="xs" color="gray.6">
                            Tools for this prompt:
                          </Text>
                          {assistantAnswerTools.map((toolName) => {
                            return (
                              <Badge variant="dot" key={toolName}>
                                <span className="normal-case">
                                  {willyToolMap[toolName]?.title || startCase(toolName)}
                                </span>
                              </Badge>
                            );
                          })}
                        </Fragment>
                      )}
                      <ActionIcon
                        ml="auto"
                        onClick={() => {
                          setUserPrompts((prev) => {
                            return prev.filter((item) => item.messageId !== message.messageId); // TODO id or messageId??
                          });
                        }}
                        icon="delete"
                        color={'red.6'}
                      />
                    </div>
                  </div>
                </Flex>
              );
            })}

            <Flex direction="column" justify="space-between" gap="xs">
              <Text size={'md'} color={'gray.7'}>
                You can select a Report to preload it's data for a specific range before the
                sequence starts
              </Text>
              <Flex align="center" gap="sm">
                <DashboardsDropDown
                  label=""
                  labelHidden
                  selected={startingDashboard}
                  onSelect={(dashboard) => {
                    setStartingDashboard(dashboard);
                  }}
                  hideGlobals
                />

                <Popover
                  opened={dateOptionsListOpened}
                  onClose={() => setDateOptionsListOpened(false)}
                >
                  <Popover.Target>
                    <Button onClick={() => setDateOptionsListOpened(true)}>
                      {startingDashboardDate
                        ? datePickerOptionValueOptions[startingDashboardDate].label
                        : `Select Date Range`}
                    </Button>
                  </Popover.Target>
                  <Popover.Dropdown p={0}>
                    <DateOptionsList
                      selectedOption={startingDashboardDate || null}
                      onOptionSelect={(option) => {
                        setStartingDashboardDate(option as DatePickerTimePeriods);
                        setDateOptionsListOpened(false);
                      }}
                    />
                  </Popover.Dropdown>
                </Popover>
              </Flex>
            </Flex>
          </Flex>
        )}
      </Flex>
      {activeTab === 'sequence' && (
        <div className="">
          <Group justify="flex-end">
            <Button variant="white" onClick={() => setSettings({ opened: false })}>
              Cancel
            </Button>
            <Button
              onClick={() => onSave()}
              loading={loading}
              disabled={!name?.trim() || !userPrompts?.length || userPrompts.some((x) => !x.text)}
            >
              Save
            </Button>
            {!sequence?.schedule && (
              <Button
                onClick={() => onSave(true)}
                loading={loading}
                disabled={!name?.trim() || !userPrompts?.length || userPrompts.some((x) => !x.text)}
              >
                Save and Schedule
              </Button>
            )}
          </Group>
        </div>
      )}
    </Flex>
  );
};

export const WillySequenceSettingsModal: React.FC<WillySequenceSettingsProps> = ({
  isSmall,
  ...props
}) => {
  const [{ opened, isCreate, sequence }, setSettings] = useWritableStore($seqSettingsModal);

  const activator = useMemo(
    () => (
      <ActionIcon
        iconSize={20}
        icon="lighthouse-1"
        onClick={() => setSettings({ opened: false })}
      />
    ),
    [setSettings],
  );

  const modalTitle = useMemo(
    () => (isCreate ? 'Create New Sequence' : `${sequence?.name ?? ''} Settings`),
    [isCreate, sequence?.name],
  );

  return (
    <Modal
      opened={opened}
      onClose={closeSeqSettingsModal}
      title={modalTitle}
      trapFocus
      headerBorder
      size="lg"
      portalProps={{ style: { zIndex: 100, position: 'relative' } }}
    >
      <WillySequenceSettings {...props} />
    </Modal>
  );
};
