import { Icon, TextField } from '@shopify/polaris';
import React, { useEffect, useState } from 'react';
import CURRENCIES from '../../constants/currencies';
import { fixedHandlingFeeOnChange } from '../../ducks/products';
import { useAppDispatch } from 'index';
import { useAppSelector } from 'reducers/RootType';
import CogsHistory from 'pages/cogs/CogsHistory';
import { getCurrencySymbol } from '@tw/constants';
import { ClockMinor } from '@shopify/polaris-icons';

const HandlingFeesFixedForOrder = () => {
  const dispatch = useAppDispatch();

  const customFixedHandlingFeePerOrder = useAppSelector(
    (state) => state.customFixedHandlingFeePerOrder,
  );
  const currency = useAppSelector((state) => state.currency);
  const [isCostHistoryOpen, setIsCostHistoryOpen] = useState(false);
  const [selectedHistoryRow, setSelectedHistoryRow] = useState();

  const showCostHistory = (fee) => {
    setIsCostHistoryOpen(true);
    setSelectedHistoryRow(fee);
  };
  const [handlingFee, setHandlingFee] = useState(
    customFixedHandlingFeePerOrder?.handling_fees?.[0]?.value ?? '',
  );
  useEffect(() => {
    setHandlingFee(customFixedHandlingFeePerOrder?.handling_fees?.[0]?.value ?? '');
  }, [customFixedHandlingFeePerOrder]);

  const updateHistory = () => {
    let history = customFixedHandlingFeePerOrder?.handling_fees ?? [];
    if (+handlingFee !== history[0]?.value) {
      history.unshift({
        value: +handlingFee,
        date: new Date(),
      });
      dispatch(fixedHandlingFeeOnChange(handlingFee, history));
    }
  };

  return (
    <>
      <TextField
        label={'Fixed Handling Fee'}
        placeholder="Set Fixed Handling Fee per Order"
        type="number"
        value={handlingFee}
        onChange={setHandlingFee}
        onBlur={updateHistory}
        prefix={customFixedHandlingFeePerOrder ? CURRENCIES[currency] : ' '}
        inputMode="numeric"
        autoComplete="off"
        suffix={
          <div
            className="cost-history"
            color="grey"
            title="Show Cost History"
            onClick={() => showCostHistory(customFixedHandlingFeePerOrder)}
          >
            <Icon source={ClockMinor} />
          </div>
        }
      />
      <CogsHistory
        selectedRow={selectedHistoryRow}
        getCurrencySymbol={getCurrencySymbol}
        defaultCurrency={currency}
        isOpen={isCostHistoryOpen}
        onClose={() => setIsCostHistoryOpen(false)}
        service={'shopify'}
        type={'tw'}
        costType={'handling_fees'}
        title={'Fixed Handling Fee History'}
      />
    </>
  );
};

export default HandlingFeesFixedForOrder;
