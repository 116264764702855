import { FeatureFlag } from '@tw/feature-flag-system/module/types';
import CDPHeader from 'components/headers/CDPHeader';
import { DatePickerHeader } from 'components/headers/DatePickerHeader';
import { DurationFiltersHeader } from 'components/headers/DurationFiltersHeader';
import { InsightsHeader } from 'components/headers/InsightsHeader';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { RootState } from 'reducers/RootType';
import { NavSection } from '../types';
import { NavSectionIcon } from '@tw/ui-components';

export const RETENTION_SECTION: NavSection = {
  title: 'Retention',
  icon: (props) => <NavSectionIcon src="insights" {...props} />,
  routes: [
    {
      url: '/cdp',
      isHybridPage: true,
      dependsOnFFSystem: FeatureFlag.SCDP_PAGE_FF,
      label: 'SCDP',
      labs: true,
      header: () => {
        return <CDPHeader />;
      },
    },
    {
      url: '/insights/cohorts',
      dependsOnFFSystem: FeatureFlag.INSIGHT_GENERAL_FF,
      label: 'Cohorts',
      header: () => <InsightsHeader />,
    },
    {
      url: '/ltv/bar-view',
      dependsOnFFSystem: FeatureFlag.INSIGHT_GENERAL_FF,
      label: 'LTV 60/90 Days',
      header: () => <DurationFiltersHeader />,
    },
    {
      url: '/ltv/time-between-orders',
      dependsOnFFSystem: FeatureFlag.INSIGHT_GENERAL_FF,
      label: 'Sales Cycle',
      header: () => <DurationFiltersHeader />,
    },
    {
      url: '/aov',
      dependsOnFFSystem: FeatureFlag.INSIGHT_GENERAL_FF,
      label: 'AOV',
      header: () => <DurationFiltersHeader />,
    },
    {
      url: '/post-purchase-survey/report',
      label: 'Post-Purchase Survey',
      isHybridPage: true,
      header: () => <DatePickerHeader showGroupByButton={false} />,
      lastUpdate: () => {
        const InnerComponent = () => {
          const lastUpdate = useSelector((state: RootState) => state.postPurchaseSurvey.lastUpdate);
          return <> {moment(lastUpdate).local().format('LT')} </>;
        };
        return <InnerComponent />;
      },
    },
    {
      //an hidden section
      url: '/post-purchase-survey/builder',
      label: 'Post-Purchase Survey',
      header: () => <></>,
      lastUpdate: () => {
        const InnerComponent = () => {
          const lastUpdate = useSelector((state: RootState) => state.postPurchaseSurvey.lastUpdate);
          return <> {moment(lastUpdate).local().format('LT')} </>;
        };
        return <InnerComponent />;
      },
      hidden: true,
    },
    {
      url: '/store-data',
      dependsOnFFSystem: FeatureFlag.STORE_DATA_FF,
      label: 'Store Data',
      header: () => <DatePickerHeader />,
    },
    {
      url: '/customers',
      dependsOnFFSystem: FeatureFlag.CUSTOMERS_PAGE_FF,
      label: 'Customers',
      header: () => <DatePickerHeader />,
      hidden: true,
    },
    {
      url: '/orders',
      dependsOnFFSystem: FeatureFlag.ORDERS_PAGE_FF,
      label: 'Orders',
      header: () => <DatePickerHeader />,
      hidden: true,
    },
  ],
};
