import moment from '@tw/moment-cached/module/timezone';
import { INIT_SHOP } from 'ducks/constants';
import { $currentShopId } from '$stores/$shop';
import { userDb } from '../utils/DB';
import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import 'firebase/compat/auth';
import 'firebase/compat/analytics';
import axiosInstance from 'utils/axiosInstance';
import { SignUpStep } from '../routes/auth/SignUp/signUpStep.enum';
import firestore = firebase.firestore;
import { subscriptionRevenue } from '@tw/types/module/services/subscription-manager';
import { toast } from 'react-toastify';
const analytics = firebase.analytics;

export const BETA_END = '2021-06-23';
export const FREE_TRIAL_DAYS = 7;
export const DAYS_FOR_TIER_CALCULATION = 60;

export const APP_INVITES_START = '2021-11-02';
export const APP_INVITES_END = '2022-01-17';

var urlParams = new URLSearchParams(window.location.search);

export const FREE_TRIAL_INDICATOR_ON_PRESS = 'FREE_TRIAL_INDICATOR_ON_PRESS';
export const freeTrialIndicatorOnPress = () => {
  return (dispatch) => {
    dispatch({
      type: FREE_TRIAL_INDICATOR_ON_PRESS,
    });
  };
};

export const SUBSCRIPTION_MODAL_ON_CLOSE = 'SUBSCRIPTION_MODAL_ON_CLOSE';
export const subscriptionModalOnClose = () => ({
  type: SUBSCRIPTION_MODAL_ON_CLOSE,
});

function informPartnersAboutPurchase(product) {
  var price = product.prices[0].unit_amount;
  analytics().logEvent('purchase' as any, { value: price, product: product.id });
}

export const SUBSCRIPTION_PRODUCTS_LOADED = 'SUBSCRIPTION_PRODUCTS_LOADED';
export const subscriptionProductsLoaded = (products) => {
  return {
    type: SUBSCRIPTION_PRODUCTS_LOADED,
    products,
  };
};

export const REDIRECTING_TO_PAYMENT = 'REDIRECTING_TO_PAYMENT';
export const SUBSCRIPTION_PAYMENT_ON_ACTION = 'SUBSCRIPTION_PAYMENT_ON_ACTION';
export const SUBSCRIPTION_PAYMENT_ON_SERVICE_ERROR = 'SUBSCRIPTION_PAYMENT_ON_SERVICE_ERROR';
export const START_SUBSCRIPTION = 'START_SUBSCRIPTION';

export const SALES_TIER_DETECTED = 'SALES_TIER_DETECTED';
export const salesTierDetected = (totalSales) => {
  return {
    type: SALES_TIER_DETECTED,
    totalSales,
  };
};

//TODO create reducer and pull is duplicate

const ORDER_SUMMARY_DONE = 'ORDER_SUMMARY_DONE';

export const orderSummaryDone = () => (dispatch) => {
  return dispatch({
    type: ORDER_SUMMARY_DONE,
  });
};

export const SIGNUP_FLOW_START = 'SIGNUP_FLOW_START';
export const signupFlowStarted = () => ({
  type: SIGNUP_FLOW_START,
});

export const GET_PRODUCTS = 'GET_PRODUCTS';
export const getProducts = () => {
  return async (dispatch) => {
    try {
      const url = `v2/subscription-manager/products`;
      const { data } = await axiosInstance.get(url);
      return dispatch({
        type: GET_PRODUCTS,
        data: data,
      });
    } catch (error) {
      console.log(error);
    }
  };
};

export const GET_PRICES = 'GET_PRICES';
export const getPrices = () => {
  return async (dispatch) => {
    try {
      const url = `v2/subscription-manager/plans`;
      const { data } = await axiosInstance.get(url);
      return dispatch({
        type: GET_PRICES,
        data: data,
      });
    } catch (error) {
      console.log(error);
    }
  };
};

export const GET_PLANS = 'GET_PLANS';
export const getPlans = () => {
  return async (dispatch) => {
    try {
      const url = `v2/subscription-manager/plans?isNewPricing=true`;
      const { data } = await axiosInstance.get(url);
      return dispatch({
        type: GET_PLANS,
        data: data,
      });
    } catch (error) {
      console.log(error);
    }
  };
};

export const GET_REVENUES = 'GET_REVENUES';
export const getRevenues = () => {
  return async (dispatch) => {
    try {
      const url = `v2/subscription-manager/revenues`;
      const { data } = await axiosInstance.get(url);
      return dispatch({
        type: GET_REVENUES,
        data: data,
      });
    } catch (error) {
      console.log(error);
    }
  };
};

// export const getAdsSpend30Days = async (shopId) => {
//   const url = `/v2/summary-page/compare-stats`;
//   const { data } = await axiosInstance.post(url, {
//     periods: [
//       {
//         start: moment().subtract(30, 'days').format(),
//         end: moment().subtract(1, 'days').format(),
//       },
//       {
//         start: moment().subtract(30, 'days').format(),
//         end: moment().subtract(1, 'days').format(),
//       },
//     ],
//     todayHour: null,
//     key: '',
//     shopDomain: shopId,
//     metricsToCompare: ['blendedAds'],
//     includeCalculatedStats: true,
//     skipComparision: true,
//     includeRawStats: false,
//     includeCharts: false,
//     activeOrderSegment: null,
//   });
//   const { calculatedStats } = data;
//   const { blendedAds } = calculatedStats[0];
//   return blendedAds;
// };

export const OPEN_UPGRADE_PLAN_MODAL = 'OPEN_UPGRADE_PLAN_MODAL';
export const CLOSE_UPGRADE_PLAN_MODAL = 'CLOSE_UPGRADE_PLAN_MODAL';
export const upgradePlanClicked =
  (history?, forceBookDemo = false) =>
  async (dispatch, getState) => {
    const { isFreeShop, currentShopId, user } = getState();
    const searchLocation = new URLSearchParams(location.search);
    const shopId = searchLocation.get('shopId');
    const testchilipiper = searchLocation.get('testchilipiper');

    const isTestChiliPiper = testchilipiper === 'true';
    const internalShop = currentShopId || shopId;

    const openCalendarModal = (shopRevenueId) => {
      const route = isFreeShop ? 'app-router' : 'in-app-upgrade-call';
      const { email, firstName, lastName, accountType } = user;
      window.ChiliPiper.submit('triplewhale', route, {
        map: true,
        lead: {
          FirstName: firstName,
          LastName: lastName,
          Email: email,
          Revenue: shopRevenueId,
          Brand: accountType === 'BRAND' ? 'brand' : 'agency',
        },
      });
    };

    const getShopRevenueId = async () => {
      let shopRevenueId = 0;
      const shop = (await firestore().collection('shops').doc(internalShop).get()).data();
      const { revenue } = shop?.annualRevenue || {};

      if (revenue) {
        const { data } = await axiosInstance.post(
          `v2/subscription-manager/revenues/getTierByAnnualRevenue`,
          { revenue, shopId: $currentShopId.get() },
        );
        shopRevenueId = +data;
      }
      return { shopRevenueId, revenue };
    };

    if (forceBookDemo || testchilipiper) {
      let { shopRevenueId } = await getShopRevenueId();
      openCalendarModal(shopRevenueId);
    } else {
      if (isFreeShop) {
        if (internalShop) {
          let { shopRevenueId, revenue } = await getShopRevenueId();
          // let blendedAdSpend30Days = 0;

          // if (revenue && revenue < 250000) {
          //   try {
          //     // blendedAdSpend30Days = await getAdsSpend30Days(internalShop);
          //   } catch (e) {}
          // }

          // if ((!revenue || revenue < 500000) && blendedAdSpend30Days < 15000) {
          if (revenue === undefined) {
            toast.info(
              'We are still calculating your revenue. Please try again in a few minutes.',
              { position: 'top-center', autoClose: false },
            );
          } else if (revenue < 500000) {
            history.push({
              pathname: '/signup',
              state: {
                currentIndex: SignUpStep.ChoosePlan,
                shopId: shopId,
                freeShopRevenueId: shopRevenueId,
                isFromUpgradeClick: true,
              },
            });
          } else {
            openCalendarModal(shopRevenueId);
          }
        }
      } else {
        history.push(`/store-settings?shop-id=${currentShopId}&location=sitePlan&`);
      }
    }
  };

export const upgradePlanClosed = () => async (dispatch) => {
  return dispatch({
    type: CLOSE_UPGRADE_PLAN_MODAL,
  });
};

const upgradePlanModalOpen = (state = { isModalOpen: false, productId: undefined }, action) => {
  switch (action.type) {
    case OPEN_UPGRADE_PLAN_MODAL:
      return { isModalOpen: true, productId: action.payload };
    case CLOSE_UPGRADE_PLAN_MODAL:
      return { isModalOpen: false };
    default:
      return state;
  }
};

export const GET_SUB_INVOICES = 'GET_SUB_INVOICES';
export const getInvoicesForSubscription = (subId) => {
  return async (dispatch) => {
    try {
      const url = `v2/subscription-manager/invoices/get-invoices/${subId}`;
      const data = await axiosInstance.get(url);
      return dispatch({
        type: GET_SUB_INVOICES,
        data: data?.data,
      });
    } catch (error) {
      console.log(error);
    }
  };
};

const dayOfTrial = (state = 0, action) => {
  switch (action.type) {
    case INIT_SHOP:
      var today = moment();
      var { createdAt } = action;
      var createdAtMoment = createdAt?.toDate ? moment(createdAt.toDate()) : moment();
      var diff = today.diff(createdAtMoment, 'days');
      return diff;
    default:
      return state;
  }
};

const totalSalesTier = (state = 0, action) => {
  switch (action.type) {
    case SALES_TIER_DETECTED:
      return action.totalSales;
    default:
      return state;
  }
};

const subscriptionProducts = (state = [], action) => {
  switch (action.type) {
    case SUBSCRIPTION_PRODUCTS_LOADED:
      return action.products;
    default:
      return state;
  }
};

const redirectingToPayment = (state = false, action) => {
  switch (action.type) {
    case REDIRECTING_TO_PAYMENT:
      return true;
    default:
      return state;
  }
};

const userForceEndFreeTrial = (state = false, action) => {
  switch (action.type) {
    case FREE_TRIAL_INDICATOR_ON_PRESS:
      return true;
    case SUBSCRIPTION_MODAL_ON_CLOSE:
      return false;
    default:
      return state;
  }
};

const isBetaUser = (state = false, action) => {
  switch (action.type) {
    case INIT_SHOP:
      var { createdAt } = action;
      return createdAt?.toDate
        ? moment(createdAt.toDate()).isBefore(moment(BETA_END).endOf('day'))
        : false;
    default:
      return state;
  }
};

const hasAuto7daysFreeTrial = (state = false, action) => {
  switch (action.type) {
    case INIT_SHOP:
      var { createdAt } = action;
      return createdAt?.toDate
        ? moment(createdAt.toDate()).isBefore(moment(APP_INVITES_START).endOf('day'))
        : false;
    default:
      return state;
  }
};

const freeTrialDays = (state = 0, action) => {
  switch (action.type) {
    case INIT_SHOP:
      var { freeTrialDays } = action;
      return freeTrialDays || 0;
    default:
      return state;
  }
};

const shopifyAccessToken = (state = '', action) => {
  switch (action.type) {
    case INIT_SHOP:
      return action.shopifyAccessToken || state;
    default:
      return state;
  }
};

const prices = (state = [], action) => {
  switch (action.type) {
    case GET_PRICES: {
      return action.data || state;
    }
    default: {
      return state;
    }
  }
};

const plans = (state = [], action) => {
  switch (action.type) {
    case GET_PLANS: {
      return action.data || state;
    }
    default: {
      return state;
    }
  }
};

type RevenueAction = { type: string; data?: subscriptionRevenue[] };
const revenues = (state = new Array<subscriptionRevenue>(), action: RevenueAction) => {
  switch (action.type) {
    case GET_REVENUES: {
      return action.data || state;
    }
    default: {
      return state;
    }
  }
};

const stripeProducts = (state = [], action) => {
  switch (action.type) {
    case GET_PRODUCTS: {
      return action.data || state;
    }
    default: {
      return state;
    }
  }
};

const stripeInvoices = (state = [], action) => {
  switch (action.type) {
    case GET_SUB_INVOICES: {
      return action.data || state;
    }
    default: {
      return state;
    }
  }
};

export const GET_SHOP_REVENUE_TIER = 'GET_SHOP_REVENUE_TIER';
export const getRevenueIdForShop = (revenue) => async (dispatch) => {
  try {
    if (revenue) {
      const { data } = await axiosInstance.post(
        `v2/subscription-manager/revenues/getTierByAnnualRevenue`,
        { revenue, shopId: $currentShopId.get() },
      );
      return dispatch({
        type: GET_SHOP_REVENUE_TIER,
        payload: data,
      });
    } else {
      return dispatch({
        type: GET_SHOP_REVENUE_TIER,
        payload: 0,
      });
    }
  } catch (err) {
    console.log(err);
  }
};

const shopRevenueTier = (state = 0, action) => {
  switch (action.type) {
    case GET_SHOP_REVENUE_TIER:
      return action.payload ?? state;
    default: {
      return state;
    }
  }
};

export const reducers = {
  dayOfTrial,
  totalSalesTier,
  subscriptionProducts,
  redirectingToPayment,
  userForceEndFreeTrial,
  isBetaUser,
  hasAuto7daysFreeTrial,
  freeTrialDays,
  shopifyAccessToken,
  prices,
  stripeProducts,
  stripeInvoices,
  plans,
  revenues,
  upgradePlanModalOpen,
  shopRevenueTier,
};
