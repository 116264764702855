import { Button, Icon, MultiSelect, Popover, Select, TextInput, Tooltip } from '@tw/ui-components';
import { BqColumn, FilterOperator } from '@tw/willy-data-dictionary/module/columns/types';
import {
  AttributionMultipaneDropdown,
  ExpandableDropdownOption,
  InfoPanel,
} from 'components/attribution-new/AttributionMultipaneDropdown';
import { getDatePickerOptionsDictionary } from 'components/useDatePickerSelectedOptions';
import { OptionsObj } from 'pages/FreeQuery/dataStuff/columns/types';
import { DateOptionsList } from 'pages/FreeQuery/DateOptionsList';
import { DatePickerSimple } from 'pages/FreeQuery/DatePickerSimple';
import { useCallback, useEffect, useMemo, useState } from 'react';

type WillyDynamicFieldValueProps = {
  value: string | string[];
  column?: BqColumn;
  options: OptionsObj[];
  operator: FilterOperator;
  loadingOptions?: boolean;
  updateOnInput?: boolean;
  datePickerMode?: 'date' | 'options' | 'both';
  valueChange: (value: string | string[]) => void;
};

export const WillyDynamicFieldValue: React.FC<WillyDynamicFieldValueProps> = ({
  value,
  column,
  options,
  operator,
  loadingOptions,
  updateOnInput,
  datePickerMode = 'both',
  valueChange,
}) => {
  const [textVal, setTextVal] = useState<string>(
    Array.isArray(value) ? value.join(', ') : value || '',
  );
  const [datePickerOpened, setDatePickerOpened] = useState<boolean>(false);

  const valueIsArray = useMemo(() => Array.isArray(value), [value]);

  const isBetween = useMemo(() => operator === 'BETWEEN', [operator]);

  const isMultiSelect = useMemo(() => {
    return column?.multiSelect && (operator === 'IN' || operator === 'NOT_IN');
  }, [column, operator]);

  const isDate = useMemo(() => {
    if (!column) {
      return false;
    }
    return column.type === 'date' || ['start_date', 'end_date'].includes(column.id);
  }, [column]);

  const attModelOptions: ExpandableDropdownOption[] = useMemo(() => {
    return options?.map((o) => {
      const option = {
        label: o.label,
        value: o.value,
        id: o.value,
        disabled: o.disabled,
      };
      if (o.explanations) {
        option['infoPanel'] = <InfoPanel modelValues={o.explanations} videoEmbed={o.videoEmbed} />;
      }
      return option;
    });
  }, [options]);

  const cleanValue = useCallback((value?: string) => {
    if (!value || typeof value !== 'string') {
      return value;
    }
    if (value.startsWith('%')) {
      value = value.slice(1);
    }
    if (value.endsWith('%')) {
      value = value.slice(0, -1);
    }
    return value;
  }, []);

  useEffect(() => {
    const val = Array.isArray(value) ? value.join(',') : cleanValue(value) ?? '';
    setTextVal(val);
  }, [value, cleanValue]);

  const datePickerOptionValueOptions = useMemo(() => {
    return getDatePickerOptionsDictionary();
  }, []);

  const datePickerActivator = useMemo(() => {
    if (!isDate) {
      return null;
    }

    if (datePickerOptionValueOptions[value as string]) {
      return datePickerOptionValueOptions[value as string].label;
    }

    if (!value || (Array.isArray(value) && !value[0])) {
      return isBetween ? 'Select Start Date' : 'Select Date';
    }

    if (Array.isArray(value)) {
      return `${value[0] || ''} - ${value[1] || ''}`;
    }

    return value;
  }, [isDate, value, isBetween, datePickerOptionValueOptions]);

  return (
    <>
      {isDate && (
        <div className="flex items-center gap-1">
          <Popover
            withinPortal={false}
            opened={datePickerOpened}
            onClose={() => setDatePickerOpened(false)}
          >
            <Popover.Target>
              <div>
                <Button variant="activator" onClick={() => setDatePickerOpened(true)}>
                  {datePickerActivator}
                </Button>
              </div>
            </Popover.Target>
            <Popover.Dropdown maw="300px">
              <div className="flex flex-col gap-4">
                {(datePickerMode === 'both' || datePickerMode === 'options') && (
                  <DateOptionsList
                    selectedOption={value as string}
                    onOptionSelect={(option) => {
                      valueChange(option);
                      setDatePickerOpened(false);
                    }}
                  />
                )}
                {(datePickerMode === 'both' || datePickerMode === 'date') && (
                  <DatePickerSimple
                    selected={Array.isArray(value) ? value : value.split(',')}
                    allowRange={isBetween}
                    onChange={(value) => {
                      if (isBetween) {
                        valueChange([value.start, value.end]);
                      } else {
                        valueChange(value.start);
                      }
                      if (!isBetween) {
                        setDatePickerOpened(false);
                      }
                    }}
                    onClose={() => setDatePickerOpened(false)}
                  />
                )}
              </div>
            </Popover.Dropdown>
          </Popover>
        </div>
      )}

      {column?.id === 'model' && (
        <AttributionMultipaneDropdown
          allowMultiple={
            !!column && column.multiSelect && (operator === 'IN' || operator === 'NOT_IN')
          }
          value={Array.isArray(value) ? value[0] : value || ''}
          options={attModelOptions}
          handleSelect={(value) => {
            const singleValue = Array.isArray(value) ? value[0] : value;
            valueChange(singleValue);
          }}
        />
      )}

      {!!options.length && !isDate && column?.id !== 'model' && (
        <div className="max-w-100">
          {isMultiSelect && (
            <MultiSelect
              styles={{
                dropdown: { minWidth: 'max-content' },
              }}
              data={options}
              value={Array.isArray(value) ? value : value ? [value] : []}
              onChange={(value) => {
                valueChange(Array.isArray(value) ? value : [value]);
              }}
            />
          )}
          {!isMultiSelect && (
            <Select
              data={options}
              searchable
              value={Array.isArray(value) ? value[0] : value}
              onChange={(value) => {
                if (value === null) {
                  return;
                }
                valueChange(value);
              }}
            />
          )}
        </div>
      )}

      {!options.length && !column?.columns && !isDate && column?.id !== 'model' && (
        <div
          className={`flex gap-1 items-center`}
          onKeyDown={(e) => {
            e.stopPropagation();

            if (e.key === 'Enter') {
              valueChange(textVal);
            }
          }}
        >
          <div className="flex gap-2 items-center">
            <TextInput
              type={column?.type === 'numeric' || column?.type === 'formula' ? 'number' : 'text'}
              value={textVal}
              onChange={(val) => {
                if (updateOnInput) {
                  valueChange(val);
                } else {
                  setTextVal(val);
                }
              }}
              disabled={loadingOptions}
            />
            {!updateOnInput && (
              <div className="flex">
                <Button
                  variant="activator"
                  onClick={() => {
                    valueChange(textVal);
                  }}
                >
                  Apply
                </Button>
              </div>
            )}
          </div>

          {valueIsArray && (
            <Tooltip label="This is a multi-value parameter, you can add more values by separating them with a comma">
              <Icon name="info" />
            </Tooltip>
          )}
        </div>
      )}
    </>
  );
};
