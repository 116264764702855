import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useAppSelector } from 'reducers/RootType';
import { StepCompletionInfo } from 'types/WizardData';
import { type RootState } from 'reducers/RootType';
import { WizardContext } from '../../WizardContext';
import { setOnboardingStatus } from 'ducks/shop';
import { useAppDispatch } from 'index';
import SalesPlatformName from 'components/SalesPlatform/SalesPlatformName';
import SalesPlatformIcon from 'components/SalesPlatform/SalesPlatformIcon';
import { SalesPlatform } from '@tw/types/module/services';
import { ShopifyConnect } from './ShopifyConnect';
import { SensoryConnect } from './SensoryConnect';
import { UseSetupProgressArgs } from 'routes/allShopsPinnedSections/hooks/useSetupProgress';

export const MAIN_PLATFORM_ACTIVATION_REDIRECT_TO = 'MAIN_PLATFORM_ACTIVATION_REDIRECT_TO';

export const WelcomeConnectMainSalesPlatform: React.FC = () => {
  const msp: SalesPlatform = useSelector((state: RootState) => state.msp);
  const { nextStep } = useContext(WizardContext);
  const isConnected = useSelector((state: RootState) => state.mspConnected);
  const [isNextEnabled, setIsNextEnabled] = useState<boolean>(true);
  const [showWarn, setShowWarn] = useState<boolean>(false);

  const next = useCallback(() => {
    if (isConnected) {
      nextStep();
    } else {
      setShowWarn(true);
    }
  }, [isConnected, nextStep]);

  return (
    <div className="flex flex-col items-center justify-center">
      <div className="mb-[40px]">
        <SalesPlatformIcon customHeight={100} />
      </div>
      <p className="step-title">Give Triple Whale access to your shop</p>
      <p className="step-description mb-[70px]">
        Click below to add the Triple Whale app to your <SalesPlatformName /> store. Once connected,
        you’ll be able to use <SalesPlatformName /> Analytics in Triple Whale.
      </p>

      {/* Connected State */}
      {isConnected && (
        <p className="text-[#052A42] text-[20px] font-medium mb-[16px]">
          Your account is connected!
        </p>
      )}

      {/* Not Connected State */}
      {!isConnected && (
        <>
          {msp === 'shopify' && <ShopifyConnect resetContinueWarn={() => setShowWarn(false)} />}
          {msp !== 'shopify' && (
            <SensoryConnect
              providerId={msp}
              nextEnabled={(enable) => setIsNextEnabled(enable)}
              resetContinueWarn={() => setShowWarn(false)}
            />
          )}
        </>
      )}

      <button
        className={`step-button ${!isNextEnabled ? 'disabled' : ''}`}
        onClick={next}
        disabled={!isNextEnabled}
      >
        Next
      </button>
      <span className="mt-[12px] text-[12px] text-[#C63E24]" style={{ opacity: showWarn ? 1 : 0 }}>
        In order to proceed with setup, a connection with <SalesPlatformName /> is required.
      </span>
    </div>
  );
};

export function useMspConnectedInfo({
  isFromPod,
  mspConnected,
}: UseSetupProgressArgs): StepCompletionInfo {
  const stateMspConnected = useAppSelector((state) => state.mspConnected);
  const connected = isFromPod ? mspConnected : stateMspConnected;

  const text = connected ? 'Data is Flowing' : 'Not Connected';
  const status: StepCompletionInfo['status'] = connected ? 'complete' : 'not-started';

  return { status, text };
}

export const SalesPlatformConnectionIndication: React.FC<any> = ({
  mspName,
  shopDomain,
  isFromPod,
  mspConnected,
}) => {
  const currentShopId = useSelector((state: RootState) => state.currentShopId);
  const { status, text } = useMspConnectedInfo({ isFromPod, mspConnected });
  const dispatch = useAppDispatch();
  const connected = status === 'complete';

  const shopId = useMemo(() => {
    return isFromPod ? shopDomain : currentShopId;
  }, [isFromPod, shopDomain, currentShopId]);

  useEffect(() => {
    dispatch(setOnboardingStatus(shopId, 'shopify', connected));
  }, [dispatch, connected, shopId]);

  return (
    <div className={connected ? 'text-green' : 'text-red-400'}>
      <span>{text}</span>
    </div>
  );
};

export const ConnectSalesPlatformTitle: React.FC<any> = ({ mspName }) => (
  <span>
    Connect <SalesPlatformName mspName={mspName} />
  </span>
);

export const ConnectSalesPlatformCTAText: React.FC<any> = ({ mspName }) => (
  <span>
    Go to <SalesPlatformName mspName={mspName} /> Admin
  </span>
);
