import React, { Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import DropDown from 'components/ltv/DropDown';
import { Tooltip } from '@shopify/polaris';
import BaseChart from 'components/library/BaseChart/BaseChart';
import moment from '@tw/moment-cached';
import { Area, ComposedChart, Line, Tooltip as ChartTooltip } from 'recharts';
import { metrics, metrics as METRICS } from 'constants/metrics/metrics';
import {
  getMetricChartForStats,
  getServiceNewStatsForShop,
  getShopifyNewStatsForShop,
} from 'ducks/newStats';
import { useSelector } from 'react-redux';
import { type RootState } from 'reducers/RootType';
import { MetricsKeys } from 'types/metrics';
import { AbstractChart } from '@tw/types';
import { ServicesIds } from '@tw/types/module/services';
import { CHART_COLORS } from 'constants/general';
import { formatNumber } from 'utils/formatNumber';
import { LighthouseAnomalyNotificationExtraData, LighthouseClientNotification } from './types';
import { ReactComponent as ChartHeaderIcon } from './ChartHeaderIcon.svg';
import { LimitedGranularity } from 'types/general';
import SERVICES from '../../constants/services';
import axiosInstance from 'utils/axiosInstance';
import { MetricData } from '@tw/types/module/metrics/metrics';
import { convertBucketSpanToUnit } from 'utils/lighthouse';
import {
  ChevronLeftMinor,
  ChevronRightMinor,
  MagicMinor,
  TimeDecayModelMinor,
} from '@shopify/polaris-icons';
import { ExtraServicesIds } from 'types/services';
import { lighthousePopupClose } from 'ducks/lighthouse';
import { useAppDispatch } from 'index';
import { Loader } from '@tw/ui-components';
import { DraggableModal } from 'components/DraggableProvider/DraggableModal';
import { AttributionData, AttributionStatsRequest } from 'types/attribution';
import { useAttributionParams } from 'utils/useAttributionParam';
import { BaseColumn } from 'components/library/TWTable/types';
import ToggleStatus from 'components/attribution-new/toggleStatus';
import TWTable from 'components/library/TWTable/TWTable';
import { isLocalhost } from 'config';
import { getMockGraph, getMockSpendGraph } from './mockData';
import { shopIntegrations } from 'ducks/shopIntegrations';

type AnomalyDetails = {
  startChart: moment.Moment;
  endChart: moment.Moment;
  startPercentage?: number;
  endPercentage?: number;
  color?: string;
};

type GranularityDictionary = {
  [granularity in LimitedGranularity]: GranularityData;
};

type GranularityData = {
  dateNumber: (date: moment.Moment) => number;
  startChart: (date: moment.Moment, duration: number) => moment.Moment;
  endChart: (date: moment.Moment, duration: number) => moment.Moment;
  seconds: number;
};

const granularityDictionary: GranularityDictionary = {
  hour: {
    dateNumber: (date: moment.Moment) => moment(date).hour(),
    startChart: (date: moment.Moment, duration) => moment(date).startOf('day'),
    endChart: (date: moment.Moment) => moment(date).endOf('day'),
    seconds: 3600,
  },
  day: {
    dateNumber: (date: moment.Moment) => moment(date).dayOfYear(),
    startChart: (date: moment.Moment, duration) => moment(date).subtract(duration + 1, 'days'),
    endChart: (date: moment.Moment, duration) => {
      let endChart = moment(date).add(duration + 1, 'days');
      if (endChart.isAfter(moment())) {
        endChart = moment();
      }
      return endChart;
    },
    seconds: 86400,
  },
  week: {
    dateNumber: (date: moment.Moment) => moment(date).dayOfYear(),
    startChart: (date: moment.Moment, duration) => moment(date).subtract(duration + 1, 'week'),
    endChart: (date: moment.Moment, duration) => moment(date).add(duration, 'week'),
    seconds: 604800,
  },
  month: {
    dateNumber: (date: moment.Moment) => moment(date).dayOfYear(),
    startChart: (date: moment.Moment, duration) => moment(date).subtract(duration + 1, 'month'),
    endChart: (date: moment.Moment, duration) => moment(date).add(duration, 'month'),
    seconds: 0,
  },
};

const columns: BaseColumn<
  AttributionData,
  'campaign' | 'spend' | 'productSpend' | 'roas' | 'pixelRoasWithoutProduct'
>[] = [
  {
    key: 'campaign',
    name: 'Campaign',
    Heading: () => <div className="text-[#97B2D0]">Campaign</div>,
    Value: (campaign, metadata) => {
      const { setData } = metadata || {};
      return (
        <div className="flex items-center gap-3 p-4">
          <span>
            <ToggleStatus
              attribution={
                {
                  entity: 'campaign',
                  id: campaign.campaignId,
                  serviceId: campaign.serviceId,
                  status: campaign.status,
                  accountId: campaign.accountId,
                } as any
              }
              setData={setData}
            />
          </span>
          <span className="flex items-center">{SERVICES[campaign.serviceId!]?.icon?.(true)}</span>
          <span className="whitespace-nowrap overflow-hidden text-ellipsis">{campaign.name}</span>
        </div>
      );
    },
    sortable: false,
    dataType: 'text',
  },
  {
    key: 'spend',
    name: 'Ad Spend',
    Heading: () => <div className="text-[#97B2D0]">Spend</div>,
    Value: (campaign, { currency }) => {
      return (
        <div className="p-4">
          {formatNumber(campaign.spend || 0, {
            currency: currency,
            minimumFractionDigits: metrics.spend?.minimumFractionDigits || 0,
            maximumFractionDigits: metrics.spend?.toFixed || 2,
            style: 'currency',
          })}
        </div>
      );
    },
    sortable: false,
    dataType: 'numeric',
  },
  {
    key: 'roas',
    name: 'ROAS',
    Heading: () => <div className="text-[#97B2D0]">ROAS</div>,
    Value: (campaign) => {
      return (
        <div className="p-4">
          {formatNumber(campaign.roas || 0, {
            minimumFractionDigits: metrics.pixelRoas?.minimumFractionDigits || 0,
            maximumFractionDigits: metrics.pixelRoas?.toFixed || 2,
            style: 'decimal',
          })}
        </div>
      );
    },
    sortable: false,
    dataType: 'numeric',
  },
];

const dateOptions = [
  { value: 0, label: 'Today' },
  { value: 7, label: 'Last 7 days' },
  { value: 14, label: 'Last 14 days' },
  { value: 30, label: 'Last 30 days' },
];

export const LighthouseChart: React.FC<LighthouseClientNotification> = ({
  extraData,
  startDate,
  endDate,
  title,
  Icon,
}) => {
  const data = extraData as LighthouseAnomalyNotificationExtraData;
  const primaryMetric = data?.metric ?? '';
  const metricName = METRICS[primaryMetric]?.shortLabel || data.metric || 'Unknown metric';
  const serviceId: ServicesIds | ExtraServicesIds = data?.service_id ?? 'facebook-ads';
  const dependOnServices: ServicesIds[] = useMemo(
    () => METRICS[primaryMetric]?.dependOnServices ?? [serviceId],
    [primaryMetric, serviceId],
  );
  const [secondaryMetric, setSecondaryMetric] = useState<MetricsKeys | 'typical'>('typical');
  const [loadingChart, setLoadingChart] = useState<boolean>(false);
  const [chartData, setChartData] = useState<any[]>([]);
  const currency = useSelector((state: RootState) => state.currency);
  const integrations = useSelector(shopIntegrations);
  const currentShopId = useSelector((state: RootState) => state.currentShopId);
  const shopTimeZone = useSelector((state: RootState) => state.shopTimezone);
  const [newStats, setNewStats] = useState({});
  const [services, setServices] = useState<Array<any>>([]);
  const [numberOfDaysToMove, setNumberOfDaysToMove] = useState<number>(0);
  const [activeTab, setActiveTab] = useState(0);
  const [campaign, setCampaign] = useState<AttributionData>();
  const [loadingCampaign, setLoadingCampaign] = useState<boolean>(true);
  const [startDateCampaign, setStartDateCampaign] = useState(0);
  const isHourlyAnomaly = convertBucketSpanToUnit(data.bucket_span) === 'hour';
  const dispatch = useAppDispatch();
  const attributionParams = useAttributionParams();

  const sd = useMemo(
    () => moment(startDate).add(numberOfDaysToMove, 'day'),
    [numberOfDaysToMove, startDate],
  );
  const ed = useMemo(
    () => (isHourlyAnomaly ? moment(sd).endOf('day') : endDate),
    [isHourlyAnomaly, sd, endDate],
  );

  const anomalyDetails: AnomalyDetails = useMemo(
    () => ({
      startChart: granularityDictionary[data.unitOfTime].startChart(sd, data.duration),
      endChart: granularityDictionary[data.unitOfTime].endChart(ed, data.duration),
    }),
    [data.unitOfTime, sd, ed, data.duration],
  );

  const metrics = useMemo(() => {
    return Object.values(METRICS)
      .filter(
        (metric) =>
          (!metric.showInServices || metric.showInServices?.includes(serviceId as any)) &&
          metric.chart &&
          (serviceId === ('triple-whale' as any) || metric.type === METRICS[primaryMetric]?.type),
      )
      .concat({ label: 'Typical', key: 'typical' } as any);
  }, [primaryMetric, serviceId]);

  const getStats = useCallback(
    async (serviceId, start, end) => {
      try {
        let stats;
        if (serviceId === 'shopify') {
          stats = await getShopifyNewStatsForShop(
            false,
            { start: start, end: end },
            data.unitOfTime,
            () => {},
            currentShopId,
            shopTimeZone,
          );
        } else {
          stats = await getServiceNewStatsForShop(
            serviceId,
            false,
            { start: start, end: end },
            currency,
            integrations,
            data.unitOfTime,
            () => {},
            currentShopId,
          );
        }
        return stats;
      } catch (error) {}
    },
    [currency, currentShopId, data.unitOfTime, integrations, shopTimeZone],
  );

  const initialStatsChart = useCallback(
    async (chart) => {
      if (data?.level === 'campaign') {
        return chart;
      }
      try {
        const allStats = await Promise.all(
          dependOnServices.map(async (service, index) => {
            const stats = await getStats(
              service,
              anomalyDetails.startChart,
              anomalyDetails.endChart,
            );
            return { service, stats };
          }),
        );
        const newStats = allStats.reduce((acc, { service, stats }) => {
          acc[service] = stats;
          return acc;
        }, {});

        setNewStats(newStats);

        const chartPoints: AbstractChart[] = getMetricChartForStats(primaryMetric, serviceId, {
          newStats,
        });
        chartPoints?.forEach((point) => {
          let pointInChart = chart.find((chartPoint) => chartPoint.date === point.x);
          if (!pointInChart) {
            pointInChart = { date: point.x };
            chart.push(pointInChart);
          }
          pointInChart[primaryMetric] = { ...pointInChart[primaryMetric], fromSelector: point.y };
        });
        chart.sort((point1, point2) => point1.date - point2.date);
        chart = chart?.filter((point) => point[primaryMetric]?.fromAnomalyGraph !== undefined);
        return chart;
      } catch (error) {
        console.log(error);
      }
    },
    [
      anomalyDetails.endChart,
      anomalyDetails.startChart,
      dependOnServices,
      primaryMetric,
      serviceId,
      data,
    ],
  );

  const getAnomaliesChart = useCallback(async () => {
    const url = '/v2/ai/anomalies/get-graph';
    const params = {
      ...data,
      startDate: anomalyDetails.startChart.format(),
      endDate: anomalyDetails.endChart.format(),
      metric: primaryMetric,
      bucket_span: granularityDictionary[data.unitOfTime].seconds,
      timezone: shopTimeZone,
      serviceId: serviceId,
    };
    try {
      setLoadingChart(true);
      let graph;
      if (data.isMock) {
        graph = getMockGraph();
      } else if (data?.isMockSpend) {
        graph = getMockSpendGraph();
      } else {
        const response = (await axiosInstance.post(url, params)).data;
        graph = response.graph;
      }
      const dateToNumber = granularityDictionary[data.unitOfTime].dateNumber;
      const chart = graph.map((point) => {
        const index = dateToNumber(moment(point.date));
        return {
          date: index,
          [primaryMetric]: { fromAnomalyGraph: point.actual },
          typical: [point.modelLower, point.modelUpper],
          anomalyScore: point.anomalyScore,
        };
      });
      const chartWithStats = await initialStatsChart(chart);
      setChartData([...chartWithStats]);
    } catch (error) {}
    setLoadingChart(false);
  }, [
    anomalyDetails.endChart,
    anomalyDetails.startChart,
    initialStatsChart,
    primaryMetric,
    shopTimeZone,
    serviceId,
    data,
  ]);

  const secondaryChart = useCallback(() => {
    try {
      if (!newStats || !chartData?.length || secondaryMetric === 'typical') return;
      let chart = chartData;
      const services: any[] = [];
      const relevantsServices = dependOnServices.filter(
        (service) => SERVICES[service]?.type === METRICS[secondaryMetric]?.type,
      );
      relevantsServices.forEach((service, index) => {
        const chartPoints: AbstractChart[] = getMetricChartForStats(secondaryMetric, service, {
          newStats,
        });
        if (!chartPoints.length) return;
        chartPoints?.forEach((chartPoint) => {
          let pointInChart = chart.find((point) => chartPoint.x === point.date);
          if (!pointInChart) {
            pointInChart = { date: chartPoint.x };
            chart.push(pointInChart);
          }
          pointInChart[service] = { ...pointInChart[service], [secondaryMetric]: chartPoint.y };
        });
        services.push({ serviceId: service, color: CHART_COLORS[index] });
      });
      setServices([...services]);
      chart.sort((point1, point2) => point1.date - point2.date);
      setChartData([...chart]);
    } catch (error) {}
  }, [chartData, dependOnServices, newStats, secondaryMetric]);

  useEffect(() => {
    getAnomaliesChart();
  }, [getAnomaliesChart]);

  useEffect(() => {
    secondaryChart();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [secondaryMetric, newStats]);

  useEffect(() => {
    if (data?.level === 'campaign' && attributionParams) {
      (async () => {
        const { service_id, campaign_id } = data;

        const params: Partial<AttributionStatsRequest> = {
          ...attributionParams,
          attributionFilters: [
            {
              key: 'campaignId',
              value: campaign_id!,
            },
          ],
          model: 'lastPlatformClick-v2',
          dateModel: 'eventDate',
          sources: [service_id],
          breakdown: 'campaignId',
          startDate: moment().subtract(startDateCampaign, 'days').startOf('day').format(),
          endDate: moment().format(),
        };
        setLoadingCampaign(true);
        const { data: attributionData } = await axiosInstance.post(
          `/v2/attribution/get-all-stats`,
          params,
        );
        const campaign = attributionData?.data?.stats?.[0];

        setCampaign(campaign);
        setLoadingCampaign(false);
      })();
    }
  }, [data, attributionParams, startDateCampaign]);

  const handleSecondaryMetricChange = useCallback((metric: string) => {
    setSecondaryMetric(metric as MetricsKeys);
  }, []);

  return (
    <DraggableModal
      onClose={() => {
        dispatch(lighthousePopupClose());
      }}
      open={true}
      width="medium"
      style={{
        backgroundColor: '#11283E',
        border: '1px solid rgba(192, 214, 234, 0.1)',
      }}
    >
      <div className="blue-chart-wrapper w-full text-white">
        <div
          className={`blue-chart !bg-inherit  !rounded-none sm:!rounded-[8px] ${
            data?.level === 'campaign' ? 'min-h-[670px]' : 'min-h-[570px]'
          }`}
          style={{ minWidth: 'unset' }}
          data-testid="LighthouseChart"
        >
          {loadingChart && (
            <div className="absolute flex items-center justify-center w-full h-full rounded-lg">
              <Loader />
            </div>
          )}
          <div className="text-white p-6.5 border border-x-0 border-t-0 border-solid border-[#2E4E65]">
            <div className="flex items-center gap-7 text-[16px] font-medium">
              <div className="border border-solid border-[#2E4E65] rounded-[7px] p-[4px] flex items-center justify-center bg-[#062940]">
                {Icon(40)}
              </div>
              <div className="flex flex-col">
                <span className="flex items-baseline gap-4">
                  <span className="flex">{`${metricName} Anomaly`}</span>
                  <span className="text-[0.7rem] flex">
                    {data.unitOfTime === 'hour'
                      ? sd.format('MMM DD YYYY')
                      : `${anomalyDetails.startChart.format(
                          'MMM DD YY',
                        )} - ${anomalyDetails.endChart.format('MMM DD YY')}`}
                  </span>
                </span>
                <div className="flex items-center gap-2 w-full">
                  <ChartHeaderIcon />
                  <div className="pt-[10px] text-[12px] flex items-center">
                    <span className="flex items-center gap-3">
                      {SERVICES[serviceId]?.icon?.(true)}
                      {SERVICES[serviceId]?.name}
                    </span>
                    {data.level === 'campaign' && (
                      <span className="flex items-center">
                        <ChevronRightMinor width={20} fill="#576B82" />
                        {data.campaign_name}
                      </span>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="flex border border-solid border-[#2E4E65] rounded-md absolute right-[5%] top-[71px] bg-[var(--dark-bg)] p-1">
            <TimeDecayModelMinor
              onClick={() => setActiveTab(0)}
              width={30}
              className="fill-white p-[5px_8px] cursor-pointer rounded-md transition-colors duration-300 ease-in-out"
              style={{ backgroundColor: activeTab === 0 ? '#1877F2' : 'var(--dark-bg)' }}
            />
            <MagicMinor
              onClick={() => setActiveTab(1)}
              width={30}
              className="fill-white p-[5px_8px] cursor-pointer rounded-md transition-colors duration-300 ease-in-out"
              style={{ backgroundColor: activeTab === 1 ? '#1877F2' : 'var(--dark-bg)' }}
            />
          </div>
          <div className="p-6.5 overflow-y-auto tw-scroll">
            {activeTab === 0 && (
              <div>
                <div className="blue-chart-top-section gap-4">
                  <DropDown
                    showFilter
                    value={Object.values(METRICS).find((m) => m.key === primaryMetric)?.key}
                    handleSelect={() => {}}
                    options={Object.values(METRICS).map((m) => ({ label: m.label, value: m.key }))}
                    disabled
                    hideChevron={true}
                  />
                  <DropDown
                    showFilter
                    value={metrics.find((m) => m.key === secondaryMetric)?.key}
                    handleSelect={handleSecondaryMetricChange}
                    options={metrics.map((m) => ({ label: m.label, value: m.key }))}
                  />
                </div>
                {chartData.length > 0 && (
                  <div className="flex items-center relative">
                    {isHourlyAnomaly && !moment(startDate).isSame(sd, 'day') && (
                      <ChevronLeftMinor
                        className="absolute cursor-pointer z-10 left-0 w-10 h-10 fill-secondary-text"
                        onClick={() => setNumberOfDaysToMove((a) => a - 1)}
                      />
                    )}
                    <div className="flex-auto lighthouse-chart">
                      <BaseChart
                        data={chartData}
                        ChartType={ComposedChart}
                        showTooltip={true}
                        margin={{ left: -50, right: -50 }}
                        xAxis={[
                          {
                            dataKey: 'date',
                            tickFormatter: (value: string) => {
                              if (!moment(value).isValid() || !value) {
                                return '';
                              }
                              if (data.unitOfTime === 'hour') {
                                return moment().hour(+value).minutes(0).format('HH:mm');
                              }
                              return moment().dayOfYear(+value).format('MMM D');
                            },
                          },
                        ]}
                        yAxis={[
                          {
                            yAxisId: 'primaryMetric',
                            tickFormatter: (value, index) => {
                              if (index % 2 !== 0) {
                                return '';
                              }
                              return +value < 1000 ? value : +value / 1000 + 'K';
                            },
                          },
                          {
                            yAxisId: 'secondaryMetric',
                            orientation: 'right',
                            tick: secondaryMetric !== 'typical',
                            tickFormatter: (value, index) => {
                              if (index % 2 !== 0) {
                                return '';
                              }
                              return +value < 1000 ? value : +value / 1000 + 'K';
                            },
                          },
                        ]}
                      >
                        <Fragment>
                          {isLocalhost && (
                            <Line
                              type="monotone"
                              yAxisId={'primaryMetric'}
                              name={'actual'}
                              dataKey={(data) => {
                                return data[primaryMetric].fromSelector;
                              }}
                              stroke="#8884d8"
                              strokeWidth={1}
                              dot={false}
                            />
                          )}
                          <Line
                            type="monotone"
                            yAxisId={'primaryMetric'}
                            name={(METRICS[primaryMetric]?.label as string) || ''}
                            dataKey={(data) => {
                              return data[primaryMetric]?.fromAnomalyGraph || 0;
                            }}
                            stroke="#1877F2"
                            strokeWidth={2}
                            dot={<AnomalyDot width={18} primaryMetric={primaryMetric} />}
                          />
                          {secondaryMetric === 'typical' && (
                            <Area
                              yAxisId={'primaryMetric'}
                              type="monotone"
                              name="typical"
                              dataKey={(data) => {
                                return data['typical'];
                              }}
                              fill="#8884d8"
                              stroke="none"
                              dot={false}
                              fillOpacity={0.2}
                            />
                          )}
                          {services.map((service) => {
                            return (
                              <Line
                                yAxisId={'secondaryMetric'}
                                type="monotone"
                                name={(METRICS[secondaryMetric]?.label as string) || ''}
                                dataKey={(data) => {
                                  if (secondaryMetric === 'typical') return;
                                  return data[service.serviceId]?.[secondaryMetric] || 0;
                                }}
                                dot={false}
                                stroke={service.color}
                                strokeWidth={2}
                                strokeDasharray="3,3"
                                key={service.serviceId}
                              />
                            );
                          })}
                          <ChartTooltip
                            cursor={{ fill: 'none' }}
                            labelStyle={{ fontWeight: 'bold' }}
                            contentStyle={{ fontSize: '10px' }}
                            formatter={(value: any, metricLabel: string) => {
                              const metric =
                                Object.values(METRICS).find((m) => m.label === metricLabel)?.key ??
                                primaryMetric;
                              if (!isNaN(value)) {
                                return formatNumber(value, {
                                  style: METRICS[metric]?.format || 'decimal',
                                  currency,
                                  minimumFractionDigits: METRICS[metric]?.toFixed,
                                  maximumFractionDigits: METRICS[metric]?.toFixed,
                                });
                              }
                              if (value.length > 0) {
                                const formatNumbers = value.map((val) =>
                                  formatNumber(val, {
                                    style: METRICS[metric]?.format || 'decimal',
                                    currency,
                                    minimumFractionDigits: METRICS[metric]?.toFixed,
                                    maximumFractionDigits: METRICS[metric]?.toFixed,
                                  }),
                                );
                                return formatNumbers.join(' - ');
                              }
                              return value;
                            }}
                            labelFormatter={(value) => {
                              if (data.unitOfTime === 'hour') {
                                return moment().hour(+value).minutes(0).format('HH:mm');
                              }
                              if (moment(value).isValid()) {
                                value = '' + value;
                                return moment()
                                  .dayOfYear(+value)
                                  .format(value.includes('T') ? 'LT' : 'MMM D');
                              }
                              return value;
                            }}
                          />
                        </Fragment>
                      </BaseChart>
                    </div>
                    {isHourlyAnomaly && !moment(endDate).isSame(ed, 'day') && (
                      <ChevronRightMinor
                        className="absolute cursor-pointer z-10 right-0 w-10 h-10 fill-secondary-text"
                        onClick={() => setNumberOfDaysToMove((a) => a + 1)}
                      />
                    )}
                  </div>
                )}
                {services.length > 1 && (
                  <div className="flex overflow-auto gap-4 p-[var(--padding)]">
                    {services.map((service) => (
                      <div key={service.serviceId}>
                        <Tooltip
                          content={<div style={{ color: service.color }}>{service.serviceId}</div>}
                        >
                          <div
                            className="rounded-[40px] border border-solid px-[12px] py-[6px] max-w-[170px] truncate cursor-pointer"
                            style={{ color: service.color, borderColor: service.color }}
                          >
                            <span title={service.serviceId}>{service.serviceId}</span>
                          </div>
                        </Tooltip>
                        <div
                          className="border-solid border-0 border-b mt-[8px] mx-[10px]"
                          style={{ borderColor: service.color }}
                        ></div>
                      </div>
                    ))}
                  </div>
                )}
                {data?.level === 'campaign' && (
                  <div className="flex flex-col mt-12">
                    <div className="flex justify-between gap-8">
                      <div className="flex flex-col gap-2">
                        <span className="text-xl">Effect on campaign</span>
                        <span className=" text-lg text-[#97B2D0]">
                          Campaign that was affected by this anomaly
                        </span>
                      </div>
                      <DropDown
                        options={dateOptions}
                        value={startDateCampaign}
                        handleSelect={(value) => setStartDateCampaign(value)}
                        theme="dark"
                      />
                    </div>
                    <TWTable
                      loading={loadingCampaign}
                      columns={columns}
                      data={campaign ? [campaign] : []}
                      metadata={{ currency, setData: setCampaign }}
                      stickyColumnIndex={0}
                      stickyHeader
                      padding="0px"
                      dark
                      height={100}
                      skeletonRows={1}
                    />
                  </div>
                )}
              </div>
            )}
            {activeTab === 1 && <p className="whitespace-pre-line">{extraData?.story}</p>}
          </div>
        </div>
      </div>
    </DraggableModal>
  );
};

const AnomalyDot = (props) => {
  const { cx, cy, payload, primaryMetric } = props;
  const color = anomalyColor(
    primaryMetric,
    payload.typical?.[0],
    payload[primaryMetric]?.fromAnomalyGraph,
  );
  return (
    <>
      {payload?.anomalyScore && (
        <svg
          x={cx - 10}
          y={cy - 10}
          width="19"
          height="18"
          viewBox="0 0 19 18"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle cx="9.51776" cy="9.16205" r="8.66351" fill={color} fillOpacity="0.29" />
          <circle cx="9.51752" cy="9.16181" r="3.88361" fill={color}>
            <animate
              attributeName="r"
              values="3.88361; 5.66351; 3.88361"
              dur="1.5s"
              begin="0s"
              repeatCount="indefinite"
            />
          </circle>
        </svg>
      )}
    </>
  );
};

const anomalyColor = (metric, typical, actual) => {
  const metricObj: MetricData<any> = metrics[metric];
  const isUp = actual > typical;
  if ((isUp && metricObj?.valueIsNegative) || (!isUp && !metricObj?.valueIsNegative)) {
    return '#EA643D';
  } else if ((isUp && !metricObj?.valueIsNegative) || (!isUp && metricObj?.valueIsNegative)) {
    return '#10B981';
  }
  return 'yellow';
};
