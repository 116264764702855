import axiosInstance from 'utils/axiosInstance';
import { BqColumn, EVENT_DATE } from './columns/types';
import { BqTable } from './tables';
import _db from 'utils/DB';
import { SAVED_QUERIES_COLLECTION } from '../constants';
import { SavedQuery } from 'components/Willy/types/willyTypes';
import { extractCustomQueryColumns } from 'components/Willy/utils/queryFormatter';

export function getQueryWithoutPivot(query: string) {
  const fullQueryLower = query.toLowerCase();
  // start from second select
  const secondSelectIndex = fullQueryLower.indexOf('select', fullQueryLower.indexOf('select') + 1);
  const pivotIndex = fullQueryLower.indexOf('pivot');
  // find ')' before pivot
  const lastBracketIndex = query.lastIndexOf(')', pivotIndex);
  const pivotString = query.slice(lastBracketIndex + 1);
  const queryWithoutPivot = query.slice(secondSelectIndex, lastBracketIndex);
  return { queryWithoutPivot, pivotString };
}

export const addEventDateColumns: () => BqColumn[] = () => {
  return [
    {
      name: 'event_date',
      id: 'event_date_day',
      type: 'date',
      title: 'day',
    },
    {
      name: "FORMAT_DATE('%F',DATE_TRUNC(event_date,WEEK(SUNDAY))) as week",
      clickhouseFormula: `formatDateTime(toStartOfWeek(event_date, 1), '%Y-%m-%d') as week`,
      id: 'event_date_week',
      type: 'date',
      title: 'week',
    },
    {
      name: "FORMAT_DATE('%Y-%m',event_date) as month",
      clickhouseFormula: `formatDateTime(event_date, '%Y-%m') as month`,
      id: 'event_date_month',
      type: 'date',
      title: 'month',
    },
    {
      name: "FORMAT_DATE('%Y-%m', DATE_TRUNC(event_date,QUARTER)) as quarter",
      clickhouseFormula: `formatDateTime(toStartOfQuarter(event_date), '%Y-%m') as quarter`,
      id: 'event_date_quarter',
      type: 'date',
      title: 'quarter',
    },
    {
      name: 'EXTRACT(YEAR FROM event_date) as year',
      clickhouseFormula: `toYear(event_date) as year`,
      id: 'event_date_year',
      type: 'date',
      title: 'year',
    },
  ];
};

// Finishing tomorrow morning
export const getCustomTables = async (docs: any, shopId: string): Promise<BqTable[]> => {
  const tables: BqTable[] = [];

  for (const doc of docs) {
    try {
      const r = await axiosInstance.post('/v2/willy/get-custom-table-schema', {
        tableId: doc.bqTableName,
        datasetId: doc.bqDatasetName,
        projectId: doc.projectId,
        shopId,
      });
      const table: BqTable = {
        id: doc.customName,
        name: doc.customName,
        isDeletable: true,
        type: 'custom_table',
        defaultQuery: `select * from ${doc.customName} limit 10`,
        columns: r.data.map((c) => ({
          name: c.name,
          id: c.name,
          type:
            c.type === 'STRING'
              ? 'string'
              : c.type === 'DATE'
                ? 'date'
                : c.type === 'TIMESTAMP'
                  ? 'timestamp'
                  : c.type === 'BOOLEAN'
                    ? 'boolean'
                    : c.type === 'RECORD'
                      ? 'record repeated'
                      : c.type === 'FLOAT'
                        ? 'numeric'
                        : c.type === 'INTEGER'
                          ? 'numeric'
                          : 'string',
          title: c.name,
        })),
      };
      tables.push(table);
    } catch (err) {
      console.log(err);
    }
  }

  return tables;
};

export function getCustomViews(views: SavedQuery[]): SavedQuery[] {
  let tables: SavedQuery[] = views.map((v) => {
    const cols = extractCustomQueryColumns(v.query);

    const tableId = v.name.replace(/\s/g, '_');

    const columns = cols.map<BqColumn>((c) => {
      const fromV = (v.columns || []).find((vc) => vc.id === c);
      if (!fromV) {
        return {
          name: c,
          id: c,
          type: 'unknown',
          title: c,
          tableId: tableId,
        };
      }
      return {
        ...fromV,
        tableId: tableId,
      };
    });

    return {
      ...v,
      columns,
    } as SavedQuery;
  });
  return tables;
}

export function formatCustomViews(views: SavedQuery[]): BqTable[] {
  const withTableId = views.map<SavedQuery & { tableId: string }>((v) => {
    // replace space with underscore
    const tableId = v.name.replace(/\s/g, '_');
    return {
      ...v,
      tableId,
    };
  });
  // unique by name
  const uniqueViews = withTableId.reduce(
    (acc, curr) => {
      const found = acc.find((v) => v.tableId === curr.tableId);
      if (!found) {
        acc.push(curr);
      }
      return acc;
    },
    [] as (SavedQuery & { tableId: string })[],
  );

  return uniqueViews.map((v) => {
    return {
      id: v.tableId,
      name: v.name,
      isDeletable: true,
      type: 'custom_view',
      underlyingView: v.query,
      defaultQuery: `select * from custom_view_${v.tableId} limit 10`,
      docId: v.id,
      columns: v.columns || [],
    };
  });
}

export function deleteCustomView(docId: string) {
  _db().collection(SAVED_QUERIES_COLLECTION).doc(docId).delete();
}
