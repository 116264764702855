import { toast } from 'react-toastify';
import axiosInstance from './axiosInstance';
import { ServicesIds } from '@tw/types/module/services';

export async function connectToSensory(
  shopId: string,
  providerId: ServicesIds,
  newTab: boolean = false,
) {
  try {
    const response = await axiosInstance.post(`/v2/account-manager/integrations/getUrl`, {
      providerId,
      buId: shopId,
      redirectUrl: window.location.href,
    });
    const url = response?.data?.url;
    if (url) {
      window.open(url, newTab ? '_blank' : '_self');
    } else {
      throw '';
    }
  } catch (error) {
    toast.error('Something went wrong. Please try again later.');
  }
}

export async function connectToSensoryBasicAuth(
  shopId: string,
  providerId: ServicesIds,
  credentials,
) {
  try {
    await axiosInstance.post(`/v2/account-manager/integrations/newCreds`, {
      providerId: providerId,
      buId: shopId,
      credentials,
    });
    toast.success('Account connected successfully');
    return true;
  } catch (e) {
    toast.error('Invalid credentials, please check your connection info and try again');
  }
}
