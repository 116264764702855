import { $store } from '@tw/snipestate';
import { Message, WillyDataSequence, WillyWidgetElement } from 'components/Willy/types/willyTypes';

type DashSettingsModalStore = {
  opened: boolean;
  sequence?: WillyDataSequence;
  isCreate?: boolean;
  conversationId?: string;
  userMessages?: Message[];
};

export const $seqSettingsModal = $store<DashSettingsModalStore>({
  opened: false,
});

export const closeSeqSettingsModal = () => {
  $seqSettingsModal.set({ opened: false });
};
