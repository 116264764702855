import { io } from 'socket.io-client';
import { WillySocket } from './types/willyTypes';
import firebaseClient from 'firebase/compat/app';
import { isLocalhost, firebase } from 'config';
import { projectIdToHostMap } from '@tw/constants';
import { useEffect, useMemo, useState } from 'react';

const baseURL = isLocalhost
  ? 'http://localhost'
  : `https://${projectIdToHostMap[firebase.projectId]}`;

let socket: WillySocket;

export const getSocket = () => {
  if (!socket) {
    socket = io(baseURL, {
      path: '/willy/realtime',
      reconnectionDelayMax: 10000,
      protocols: ['websocket'],
      transports: ['websocket'],
      addTrailingSlash: false,
      query: { userId: firebaseClient.auth()?.currentUser?.uid },
      auth: async (cb) => {
        const token = await firebaseClient.auth()?.currentUser?.getIdToken();
        cb({ token });
      },
    });
  }
  return socket;
};

export const useWillySocket = () => {
  const [isConnected, setIsConnected] = useState(true);

  const socket = useMemo(() => getSocket(), []);

  // useEffect(() => {
  //   if (socket) {
  //     socket.on('connect', () => {
  //       setIsConnected(true);
  //     });
  //     socket.on('disconnect', () => {
  //       setIsConnected(false);
  //     });
  //   }
  // }, [socket]);

  return { socket, isConnected };
};

export const openAiStopSequence = '[DONE]';
