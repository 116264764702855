import { useSelector } from 'react-redux';
import { type RootState } from 'reducers/RootType';
import { SalesPlatform } from '@tw/types/module/services';
import { WelcomeInstallShopifyPixel } from './WelcomeInstallShopifyPixel';
import { WelcomeInstallWooPixel } from './WelcomeInstallWooPixel';
import { WelcomeInstallStripePixel } from './WelcomeInstallStripePixel';

export const WelcomeInstallPixel: React.FC = () => {
  const msp: SalesPlatform = useSelector((state: RootState) => state.msp);
  return (
    <>
      {msp === 'shopify' && <WelcomeInstallShopifyPixel />}
      {msp === 'bigcommerce' && <WelcomeInstallShopifyPixel />}
      {msp === 'woocommerce' && <WelcomeInstallWooPixel />}
      {msp === 'stripe' && <WelcomeInstallStripePixel />}
    </>
  );
};
