import { $shouldShowMobyUpgradeCtas, openFreeTrialPopup } from '$stores/willy/$upgradePopupManager';
import { useStoreValue } from '@tw/snipestate';
import { Alert, Button, Text } from '@tw/ui-components';
import { useFoundersDashUpgradeButton } from 'components/UpgradePlan/components/FDUpgradeButton';
import { useHistory } from 'react-router';
import { useAppSelector } from 'reducers/RootType';

export const PromptLibraryUpgradeMoby = () => {
  const history = useHistory();
  const isFreeShop = useAppSelector((state) => state.isFreeShop);
  const shouldShowMobyUpgradeCtas = useStoreValue($shouldShowMobyUpgradeCtas);
  const { buttonText, action } = useFoundersDashUpgradeButton('prompt_library');
  return (
    <>
      {shouldShowMobyUpgradeCtas && (
        <Alert>
          <div className="flex flex-col gap-5">
            <Text color="one.7" fw={500} align="center" fz={14}>
              Upgrade to access pre-made prompts and to quickly chat with your data
            </Text>
            {isFreeShop ? (
              <Button
                variant="primary"
                fullWidth
                onClick={() => {
                  action();
                }}
              >
                {buttonText}
              </Button>
            ) : (
              <Button
                fullWidth
                onClick={() => {
                  openFreeTrialPopup('prompt_library');
                }}
              >
                Upgrade
              </Button>
            )}
          </div>
        </Alert>
      )}
    </>
  );
};
