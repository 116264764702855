import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { connect, useSelector } from 'react-redux';
import {
  TextField,
  Modal,
  FormLayout,
  DatePicker,
  Popover,
  Button,
  Checkbox,
  Stack,
  Banner,
  TextStyle,
  Select,
} from '@shopify/polaris';
import {
  toggleNewCustomSpendIsOpen,
  newCustomSpendStartDateOnMonthChange,
  customSpendSave,
  clearInitialData,
  newCustomSpendEndDateOnMonthChange,
} from '../ducks/actions';
import moment from '@tw/moment-cached/module/timezone';
import CURRENCIES from '../constants/currencies';
import { AdSpendCheckbox } from './AdSpendCheckbox';
import { CalendarMinor } from '@shopify/polaris-icons';
import { CustomSpendCategory } from './CustomSpendCategory';
import { type RootState } from 'reducers/RootType';
import { useAppDispatch } from 'index';
import { CustomSpendSource } from './CustomSpendSource';
import { CustomSpendCampaign } from './CustomSpendCampaign';
import allServices from 'constants/services';
import { OptionDescriptor } from '@shopify/polaris/build/ts/latest/src/types';
import axiosInstance from 'utils/axiosInstance';

const NewCustomSpend = ({
  relevantSources,
  isOpen,
  startDateMonth,
  startDateYear,
  endDateMonth,
  endDateYear,
  onStartMonthChange,
  onEndMonthChange,
  currency,
}) => {
  const dispatch = useAppDispatch();
  const initialData = useSelector((state: RootState) => state.initialCustomSpendData);
  const shopTimezone = useSelector((state: RootState) => state.shopTimezone);
  const currentShopId = useSelector((state: RootState) => state.currentShopId);
  const mainDatePickerSelectionRange = useSelector(
    (state: RootState) => state.mainDatePickerSelectionRange,
  );

  const customSpendCostOnChange = (value) => {
    if (isNaN(value)) return;
    setCustomSpendCost(value);
  };

  const recurringLengthOnChange = (value) => {
    if (isNaN(value) || value < 1) return;
    setCustomSpendRecurringLength(value);
  };

  const [startDatePickerIsOpen, setStartDatePickerIsOpen] = useState(false);
  const [endDatePickerIsOpen, setEndDatePickerIsOpen] = useState(false);
  const [customSpendStartDate, setCustomSpendStartDate]: any = useState(null);
  const [customSpendEndDate, setCustomSpendEndDate]: any = useState(null);
  const [customSpendTitle, setCustomSpendTitle] = useState('');
  const [customSpendCost, setCustomSpendCost]: any = useState(0);
  const [customSpendRecurring, setCustomSpendRecurring] = useState(false);
  const [customSpendRecurringLength, setCustomSpendRecurringLength]: any = useState(1);
  const [customSpendRecurringCadence, setCustomSpendRecurringCadence] = useState('day');
  const [customSpendIsAdSpend, setCustomSpendIsAdSpend] = useState<boolean>(false);
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [customSpendId, setCustomSpendId]: any = useState(null);
  const [startDateLabel, setStartDateLabel] = useState('MM/DD/YYYY');
  const [endDateLabel, setEndDateLabel] = useState('MM/DD/YYYY');
  const [category, setCategory] = useState('');
  const [source, setSource] = useState<OptionDescriptor>({ label: '', value: '' });
  const [campaign, setCampaign] = useState<OptionDescriptor>({ label: '', value: '' });
  const [relevantCampaigns, setRelevantCampaigns] = useState<OptionDescriptor[]>([]);

  const saveEnabled =
    !!customSpendCost &&
    customSpendCost !== 0 &&
    customSpendTitle.trim().length > 0 &&
    !!customSpendStartDate &&
    (customSpendEndDate
      ? moment(customSpendEndDate).isSameOrAfter(moment(customSpendStartDate), 'day')
      : true) &&
    (customSpendRecurringLength && customSpendRecurringCadence === 'day' && customSpendEndDate
      ? moment(customSpendEndDate).diff(moment(customSpendStartDate), 'days') + 1 >=
        Number(customSpendRecurringLength)
      : true) &&
    (customSpendRecurringLength && customSpendRecurringCadence === 'month' && customSpendEndDate
      ? moment(customSpendStartDate)
          .clone()
          .add(Number(customSpendRecurringLength), 'months')
          .subtract(1, 'days')
          .date() === moment(customSpendEndDate).date() ||
        moment(customSpendEndDate).diff(moment(customSpendStartDate), 'months') + 1 >
          Number(customSpendRecurringLength)
      : true);

  const reset = useCallback(() => {
    setCustomSpendCost(0);
    setCustomSpendTitle('');
    setCustomSpendEndDate(null);
    setCustomSpendStartDate(null);
    setCustomSpendRecurring(false);
    setCustomSpendRecurringLength(1);
    setCustomSpendRecurringCadence('day');
    setCustomSpendIsAdSpend(false);
    setIsLoading(false);
    setIsEdit(false);
    setCustomSpendId(null);
    setStartDateLabel('MM/DD/YYYY');
    setEndDateLabel('MM/DD/YYYY');
    setCategory('');
    setSource({ label: '', value: '' });
    setCampaign({ label: '', value: '' });
  }, []);

  const _onSubmit = useCallback(() => {
    setIsLoading(true);

    var obj = {
      title: customSpendTitle,
      cost: customSpendCost,
      currency: currency ?? '',
      recurring: customSpendRecurring,
      start_date: moment(customSpendStartDate).tz(shopTimezone, true).startOf('day'),
      end_date: customSpendEndDate
        ? moment(customSpendEndDate).tz(shopTimezone, true).startOf('day')
        : !customSpendRecurring
          ? moment(customSpendStartDate).tz(shopTimezone, true).startOf('day')
          : null,
      isAdSpend: customSpendIsAdSpend,
    };
    if (category) obj['category'] = category;
    if (isEdit) obj['id'] = customSpendId;
    if (customSpendRecurring) {
      obj['recurringIntervalLength'] = Number(customSpendRecurringLength) || 1;
      obj['recurringCadence'] = customSpendRecurringCadence || 'day';
    }
    if (source) obj['source'] = source.value;
    if (campaign) obj['campaignId'] = campaign.value;
    if (campaign) obj['campaignName'] = campaign.label;

    dispatch(customSpendSave({ obj }));
    dispatch(toggleNewCustomSpendIsOpen());
    dispatch(clearInitialData());
    reset();
  }, [
    customSpendTitle,
    customSpendCost,
    currency,
    customSpendRecurring,
    customSpendStartDate,
    shopTimezone,
    customSpendEndDate,
    customSpendIsAdSpend,
    category,
    isEdit,
    customSpendId,
    dispatch,
    reset,
    customSpendRecurringLength,
    customSpendRecurringCadence,
    source,
    campaign,
  ]);

  useEffect(() => {
    if (!initialData?.id) {
      reset();
      return;
    }
    const {
      title,
      cost,
      start_date,
      end_date,
      id,
      recurring,
      recurringIntervalLength,
      recurringCadence,
      isAdSpend,
      category,
      source,
      campaignId,
      campaignName,
    } = initialData;
    setCustomSpendCost(cost);
    setCustomSpendTitle(title);
    end_date && setCustomSpendEndDate(moment(end_date));
    setCustomSpendStartDate(moment(start_date));
    setCustomSpendRecurring(recurring);
    recurringIntervalLength && setCustomSpendRecurringLength('' + recurringIntervalLength);
    recurringCadence && setCustomSpendRecurringCadence(recurringCadence);
    setCustomSpendIsAdSpend(isAdSpend || false);
    setIsLoading(false);
    setCustomSpendId(id);
    setIsEdit(true);
    setStartDateLabel(moment(start_date).format('MMM DD, YYYY'));
    end_date && setEndDateLabel(moment(end_date).format('MMM DD, YYYY'));
    category && setCategory(category);

    if (source) {
      const s = Object.values(allServices).find((service) => service.id === source);
      if (s) {
        setSource({ label: s.name, value: s.id });
      } else {
        setSource({ label: source, value: source });
      }
    }

    if (campaignId && campaignName) {
      setCampaign({ label: campaignName, value: campaignId });
    }
  }, [initialData, reset]);

  const startOnChange = (val) => {
    setCustomSpendStartDate(moment(val.start).clone().local());
    setStartDateLabel(moment(val.start).clone().local().format('MMM DD, YYYY'));
  };

  const endOnChange = (val) => {
    setCustomSpendEndDate(moment(val.start).clone().local());
    setEndDateLabel(moment(val.start).clone().local().format('MMM DD, YYYY'));
  };

  useEffect(() => {
    if (!source.value) {
      setCampaign({ label: '', value: '' });
    }
  }, [source]);

  useEffect(() => {
    (async () => {
      if (!mainDatePickerSelectionRange || !source.value) return;
      const { data } = await axiosInstance.post('/v2/attribution/get-attributed-campaigns', {
        shopDomain: currentShopId,
        source: source.value,
        startDate: moment(mainDatePickerSelectionRange.start)
          .subtract(1, 'month')
          .startOf('day')
          .format('YYYY-MM-DD'),
        endDate: moment(mainDatePickerSelectionRange.end).endOf('day').format('YYYY-MM-DD'),
      });

      setRelevantCampaigns((data.campaigns || []).map((c) => ({ label: c, value: c })));
    })();
  }, [currentShopId, mainDatePickerSelectionRange, source]);

  return (
    <Modal
      title={isEdit ? 'Edit Fixed Expense' : 'Add New Fixed Expense'}
      open={isOpen}
      onClose={() => {
        reset();
        dispatch(clearInitialData());
        dispatch(toggleNewCustomSpendIsOpen());
      }}
      primaryAction={{
        content: 'Save',
        onAction: _onSubmit,
        disabled: !saveEnabled,
        loading: isLoading,
      }}
    >
      <Modal.Section>
        <FormLayout>
          {isEdit && (
            <Banner status="info">
              <p>
                Editing this fixed expense will affect past data. If you don't want to affect past
                data, please create a new fixed expense. You can end this expense by selecting today
                as the end date.
              </p>
            </Banner>
          )}
          <FormLayout.Group condensed>
            <TextField
              label={'Title'}
              placeholder={'Name your custom expense'}
              // labelHidden={true}
              onChange={setCustomSpendTitle}
              value={customSpendTitle}
              autoComplete="off"
            />
            <TextField
              label={'Cost'}
              placeholder={'Enter sum'}
              // labelHidden={true}
              type="number"
              onChange={customSpendCostOnChange}
              value={customSpendCost}
              prefix={CURRENCIES[currency]}
              maxLength={9}
              autoComplete="off"
            />
            <CustomSpendCategory val={category} onChange={setCategory} />
          </FormLayout.Group>
          <FormLayout.Group>
            <div className={'flex'}>
              <div className={'pt-3 pr-2'}>
                <RecurringCheckbox
                  value={customSpendRecurring}
                  onChange={setCustomSpendRecurring}
                  disabled={false}
                />
              </div>
              <div style={{ padding: '7px 5px' }}> Recurring </div>
              {customSpendRecurring && (
                <>
                  <div style={{ padding: '7px 10px 0px 0px' }}> every </div>
                  <TextField
                    label={'Recurring Length'}
                    labelHidden
                    placeholder="1"
                    onChange={recurringLengthOnChange}
                    value={customSpendRecurringLength}
                    maxLength={3}
                    autoComplete="off"
                  />
                  <div style={{ paddingLeft: '5px' }}>
                    <Select
                      label={'Recurring Cadence'}
                      labelHidden
                      options={[
                        { label: 'days', value: 'day' },
                        { label: 'months', value: 'month' },
                      ]}
                      onChange={setCustomSpendRecurringCadence}
                      value={customSpendRecurringCadence}
                    />
                  </div>
                </>
              )}
            </div>

            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <div style={{ paddingTop: '5px', paddingRight: '10px', marginLeft: '20px' }}>
                starting{' '}
              </div>
              <Popover
                active={startDatePickerIsOpen}
                activator={
                  <Button
                    outline
                    onClick={() => setStartDatePickerIsOpen(!startDatePickerIsOpen)}
                    disclosure
                    fullWidth
                    icon={CalendarMinor}
                  >
                    {startDateLabel}
                  </Button>
                }
                onClose={() => setStartDatePickerIsOpen(!startDatePickerIsOpen)}
                sectioned
                fullHeight
              >
                <DatePicker
                  month={startDateMonth}
                  year={startDateYear}
                  onChange={startOnChange}
                  onMonthChange={onStartMonthChange}
                  selected={!!customSpendStartDate && customSpendStartDate.clone().toDate()}
                />
                <Stack distribution="trailing" alignment="center">
                  <Button
                    primary
                    onClick={() => {
                      setStartDatePickerIsOpen(false);
                    }}
                  >
                    Apply
                  </Button>
                </Stack>
                <div>
                  {' '}
                  <TextStyle variation="subdued">{shopTimezone}</TextStyle>
                </div>
              </Popover>
            </div>
          </FormLayout.Group>
          <FormLayout.Group>
            <AdSpendCheckbox value={customSpendIsAdSpend} onChange={setCustomSpendIsAdSpend} />

            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <div>
                <div
                  style={{
                    paddingTop: customSpendRecurring ? '0px' : '5px',
                    paddingRight: '10px',
                    marginLeft: '20px',
                  }}
                >
                  ending{' '}
                </div>
                {customSpendRecurring && <div style={{ paddingLeft: '5px' }}>(optional)</div>}
              </div>
              <Popover
                active={endDatePickerIsOpen}
                activator={
                  <Button
                    outline
                    onClick={() => setEndDatePickerIsOpen(!endDatePickerIsOpen)}
                    disclosure
                    fullWidth
                    icon={CalendarMinor}
                  >
                    {endDateLabel}
                  </Button>
                }
                onClose={() => setEndDatePickerIsOpen(!endDatePickerIsOpen)}
                sectioned
                fullHeight
              >
                <DatePicker
                  month={endDateMonth}
                  year={endDateYear}
                  onChange={endOnChange}
                  onMonthChange={onEndMonthChange}
                  selected={!!customSpendEndDate && customSpendEndDate.clone().toDate()}
                />
                <Stack distribution="trailing" alignment="center">
                  {customSpendRecurring && (
                    <Button
                      primary
                      onClick={() => {
                        setCustomSpendEndDate(null);
                        setEndDateLabel('MM/DD/YYYY');
                        setEndDatePickerIsOpen(false);
                      }}
                    >
                      Clear End Date
                    </Button>
                  )}
                  <Button
                    primary
                    onClick={() => {
                      setEndDatePickerIsOpen(false);
                    }}
                  >
                    Apply
                  </Button>
                </Stack>
                <div>
                  {' '}
                  <TextStyle variation="subdued">{shopTimezone}</TextStyle>
                </div>
              </Popover>
            </div>
            <CustomSpendSource
              source={source}
              setSource={setSource}
              disabled={false}
              relevantSources={relevantSources}
            />
            <CustomSpendCampaign
              campaign={campaign}
              setCampaign={setCampaign}
              source={source}
              relevantCampaigns={relevantCampaigns}
            />
          </FormLayout.Group>
        </FormLayout>
      </Modal.Section>
    </Modal>
  );
};

function RecurringCheckbox({ onChange, value, disabled }) {
  const [checked, setChecked] = useState(value);
  const handleChange = useCallback((val) => {
    setChecked(val);
    onChange(val);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    setChecked(value);
  }, [value]);
  return (
    <Checkbox
      label={'Recurring'}
      labelHidden
      checked={checked}
      onChange={handleChange}
      disabled={disabled}
    />
  );
}

const mapStateToProps = ({
  newCustomSpendStartDateWindowIsOpen,
  newCustomSpendEndDateWindowIsOpen,
  newCustomSpendStartDateMonth,
  newCustomSpendStartDateYear,
  newCustomSpendEndDateMonth,
  newCustomSpendEndDateYear,
  newCustomSpendStartDate,
  newCustomSpendEndDate,
  currency,
  newCustomSpendWindowIsOpen,
}) => ({
  customSpendStartDate: newCustomSpendStartDate,
  customSpendEndDate: newCustomSpendEndDate,
  startDateMonth: newCustomSpendStartDateMonth,
  startDateYear: newCustomSpendStartDateYear,
  endDateMonth: newCustomSpendEndDateMonth,
  endDateYear: newCustomSpendEndDateYear,
  customSpendStartDateWindowIsOpen: newCustomSpendStartDateWindowIsOpen,
  customSpendEndDateWindowIsOpen: newCustomSpendEndDateWindowIsOpen,
  currency,
  isOpen: newCustomSpendWindowIsOpen,
});

const mapDispatchToProps = (dispatch) => {
  return {
    onStartMonthChange: (month, year) =>
      dispatch(newCustomSpendStartDateOnMonthChange(month, year)),
    onEndMonthChange: (month, year) => dispatch(newCustomSpendEndDateOnMonthChange(month, year)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(NewCustomSpend);
