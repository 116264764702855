import 'swiper/css';
import 'swiper/css/navigation';
import './ContentHub.scss';

import UploadMedia from 'components/ContentHub/UploadMedia';
import {
  changeContentHubFolder,
  closeContentHubUploadMedia,
  finishReloadContentHubTab,
  openContentHubUploadMedia,
} from 'ducks/actions';
import { MediaPickerInternalData } from 'ducks/contentHub/mediaPicker';
import { useAppDispatch } from 'index';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useLocation, useParams } from 'react-router';
import { type RootState } from 'reducers/RootType';
import { DisplayMode, TabsIds } from 'types/ContentHub';
import { AdAssetType } from 'types/general';
import axiosInstance from 'utils/axiosInstance';
import useDebounce from 'utils/useDebounce';

import { Button, Tabs } from '@shopify/polaris';
import { AddImageMajor, SearchMinor } from '@shopify/polaris-icons';

import AdMediaCollectionWrapper from './ads/AdsMediaCollectionWrapper';
// import AffluencerMediaCollection from './affluencer/AffluencerMediaCollection';
import ProductMediaCollection from './products/ProductMediaCollection';
import SearchResults from './searchResults/SearchResults';
import UploadMediaCollection from './upload/UploadMediaCollection';
import { TabDescriptor } from '@shopify/polaris/build/ts/latest/src/components/Tabs/types';
import { gradualReleaseFeatures } from 'ducks/shop';
import { MediaItemType } from '@tw/types';
import { InAppContextBanner } from 'components/InAppContextBanner';
import { FeatureFlag } from '@tw/feature-flag-system/module/types';
import { computeFeatureFlags } from 'feature-flag-system';
import { UpgradePageFallBack } from 'feature-flag-system/components';

export const ContentHubContext = React.createContext<Partial<ContentHubContextProps>>({});

type TabDescriptorWithData = {
  CollectionComponent: JSX.Element;
  id: TabsIds;
} & TabDescriptor;

type ContentHubContextProps = {
  displayMode: DisplayMode;
  openItem: MediaItemType;
  setOpenItem: React.Dispatch<any>;
  isMediaPicker: boolean;
  searchResultsMedia: any;
  searchResultsAds: any;
  searchResultsProducts: any;
  searching: boolean;
  mediaType: AdAssetType;
  setMediaType: React.Dispatch<AdAssetType>;
  gotToUploaded: () => void;
  debounce: string;
  searchingMedia: boolean;
  searchingAds: boolean;
  searchingProducts: boolean;
  reloadData: boolean;
  setReloadData: React.Dispatch<any>;
  openTabSingleItem: (tab, customId) => void;
};

const ContentHubComponent: React.FC<{ isMediaPicker: boolean }> = ({ isMediaPicker }) => {
  const mediaPickerData: MediaPickerInternalData = useSelector(
    (state: RootState) => state.mediaPickerData,
  );
  // const { generative_tooling: hasContentHub } = useSelector(gradualReleaseFeatures);
  const hasContentHub = true;

  const searchTerm = useSelector((state: RootState) => state.contentHubSearchTerm);
  const dispatch = useAppDispatch();

  const triggerReloadContentHubTab = useSelector(
    (state: RootState) => state.triggerReloadContentHubTab,
  );
  const currentShopId = useSelector((state: RootState) => state.currentShopId);
  const shopInitiated = useSelector((state: RootState) => state.shopInitiated);
  const isFromMobileApp = useSelector((state: RootState) => state.mobileApp.isFromMobileApp);

  if (mediaPickerData.isOpen) {
    // console.log('its a media picker');
  } else {
    // console.log('just content hub');
  }
  const history = useHistory();
  const location = useLocation();
  const params = useParams<{ tabId: string; customId: string }>();

  const [displayMode, setDisplayMode] = useState<DisplayMode>('grid');
  const [tabComponent, setTabComponent] = useState<JSX.Element>();
  const [openItem, setOpenItem] = useState<any>(null);
  const [searchResultsAds, setSearchResultsAds] = useState<any[]>([]);
  const [searchResultsMedia, setSearchResultsMedia] = useState<any[]>([]);
  const [searchResultsProducts, setSearchResultsProducts] = useState<any[]>([]);
  const [searching, setSearching] = useState(false);
  const [searchingAds, setSearchingAds] = useState(false);
  const [searchingMedia, setSearchingMedia] = useState(false);
  const [searchingProducts, setSearchingProducts] = useState(false);
  const [mediaType, setMediaType] = useState<AdAssetType>('image');
  const [reloadData, setReloadData] = useState(false);
  const [singleItem, setSingleItem] = useState<string | null>(null);
  const [showSearch, setShowSearch] = useState(false);
  const debounce = useDebounce(searchTerm, 1500);

  const tabs: TabDescriptorWithData[] = useMemo(() => {
    const t: TabDescriptorWithData[] = [];
    if (isMediaPicker) {
      return t.concat([
        {
          content: 'Uploaded Media',
          id: 'uploaded',
          CollectionComponent: <UploadMediaCollection />,
        },
      ]);
    }
    return t.concat([
      {
        content: 'Uploaded Media',
        id: 'uploaded',
        CollectionComponent: <UploadMediaCollection />,
      },
      {
        content: 'Facebook Ads',
        id: 'facebook-ads',
        CollectionComponent: <AdMediaCollectionWrapper serviceId="facebook-ads" />,
      },
      {
        content: 'TikTok Ads',
        id: 'tiktok-ads',
        CollectionComponent: <AdMediaCollectionWrapper serviceId="tiktok-ads" />,
      },
      {
        content: 'YouTube Ads',
        id: 'google-ads',
        CollectionComponent: <AdMediaCollectionWrapper serviceId="google-ads" />,
      },
      {
        content: 'Products & Collections',
        id: 'products',
        CollectionComponent: <ProductMediaCollection />,
      },
      // {
      //   content: 'Affluencer Hub',
      //   id: 'affluencer-hub',
      //   CollectionComponent: <AffluencerMediaCollection />,
      // },
    ]);
  }, [isMediaPicker]);

  const getDefaultTab = useCallback(() => {
    const { tabId } = params;
    const tab = tabs.find((tab) => tab.id === tabId);
    return tab || tabs[0];
  }, [params, tabs]);

  const [activeTab, setActiveTab] = useState<TabDescriptorWithData | null>(getDefaultTab());

  useEffect(() => {
    if (activeTab?.id) {
      const tab = tabs.find((tab) => tab.id === activeTab.id);
      let component = tab?.CollectionComponent;
      setTabComponent(component);
    }
  }, [activeTab, tabs]);

  useEffect(() => {
    if (activeTab?.id) {
      console.log(location.pathname);
      const pastTab = location.pathname.split('/')[2];
      const tab = tabs.find((tab) => tab.id === activeTab?.id);
      const uploadedMediaPath = location.pathname.match(/creative-hub\/uploaded(.*)/);
      if (tab && tab.id === 'uploaded' && uploadedMediaPath) {
      } else {
        dispatch(changeContentHubFolder('/'));
        const customId = singleItem
          ? singleItem
          : pastTab === tab?.id || pastTab === 'search'
            ? params.customId
            : null;
        !isMediaPicker &&
          history.push({
            pathname: `/creative-hub/${tab?.id}/${customId ? customId : ''}`,
            search: location.search,
          });
      }

      setSingleItem(null);
    }
  }, [
    activeTab,
    history,
    isMediaPicker,
    location.search,
    location.pathname,
    params.customId,
    tabs,
    singleItem,
    dispatch,
  ]);

  useEffect(() => {
    const search = async () => {
      setSearching(true);
      setShowSearch(true);
      try {
        if (!debounce?.trim()) {
          setSearchResultsAds([]);
          setSearchResultsMedia([]);
          setSearching(false);
          setShowSearch(false);
          if (!activeTab) {
            setActiveTab(getDefaultTab());
          }
          return;
        }
        setActiveTab(null);
        setSearchingAds(true);
        setSearchingMedia(true);
        setSearchingProducts(true);

        axiosInstance
          .post('/v2/metrics-table/search-ads', {
            query: debounce,
            shopId: currentShopId,
            page: 0,
            limit: 12,
          })
          .then((response) => {
            const { data } = response;
            setSearchResultsAds(data);
            setSearchingAds(false);
          });
        axiosInstance
          .post('/v2/media/search-content-hub-media', {
            query: debounce,
            shopId: currentShopId,
            page: 0,
            limit: 12,
          })
          .then((response) => {
            const { data } = response;
            setSearchResultsMedia(data);
            setSearchingMedia(false);
          });
        axiosInstance
          .post('/v2/media/search-content-hub-products', {
            query: debounce,
            shopId: currentShopId,
            last_id: '',
            limit: 10,
          })
          .then((response) => {
            const { data } = response;
            setSearchResultsProducts(data);
            setSearchingProducts(false);
          });
      } catch (e) {
        console.error(e);
      }
      setSearching(false);
    };
    search();
  }, [currentShopId, debounce, tabs, activeTab, getDefaultTab]);

  const reloadTab = useCallback(() => {
    setReloadData(true);
    dispatch(finishReloadContentHubTab());
  }, [dispatch]);

  useEffect(() => {
    if (triggerReloadContentHubTab) {
      reloadTab();
    }
  }, [triggerReloadContentHubTab, reloadTab]);

  const gotToUploaded = () => {
    if (activeTab?.id !== 'uploaded') {
      setActiveTab(tabs.find((t) => t.id === 'uploaded')!);
    }
    reloadTab();
  };

  const openTabSingleItem = (tabId, customId) => {
    setSingleItem(customId);
    setActiveTab(tabs.find((t) => t.id === tabId)!);
  };

  const contextProps: ContentHubContextProps = {
    displayMode,
    openItem,
    searchResultsMedia,
    searchResultsAds,
    searchResultsProducts,
    isMediaPicker,
    searching,
    mediaType,
    setMediaType,
    setOpenItem,
    gotToUploaded,
    debounce,
    searchingAds,
    searchingMedia,
    searchingProducts,
    reloadData,
    setReloadData,
    openTabSingleItem,
  };

  useEffect(() => {
    if (shopInitiated && !hasContentHub) {
      console.log('no content hub', hasContentHub, shopInitiated);

      history.push('/summary');
    }
  }, [hasContentHub, history, shopInitiated]);

  return (
    <ContentHubContext.Provider value={contextProps}>
      {!isMediaPicker && <InAppContextBanner page="creativeHub" />}
      <div className="flex flex-col gap-6.5 content-hub">
        <div className="flex flex-wrap gap-6.5 justify-between items-center">
          {isMediaPicker && !isFromMobileApp && (
            <div className="flex gap-4 justify-between items-center w-full sm:w-auto">
              <Button
                onClick={() => {
                  dispatch(openContentHubUploadMedia());
                }}
                icon={AddImageMajor}
              >
                Upload
              </Button>
            </div>
          )}
        </div>
        <div className="flex items-center justify-between">
          {!isMediaPicker && (
            <div className="tw-tabs">
              <Tabs
                tabs={tabs}
                selected={tabs.findIndex((tab) => tab.id === activeTab?.id)}
                onSelect={(index) => {
                  setActiveTab(tabs[index]);
                  setShowSearch(false);
                  setOpenItem(null);
                }}
              />
            </div>
          )}
          {/* {!isMediaPicker &&<div className="w-full sm:w-auto">
            <TextField
              autoComplete='off'
              prefix={<Icon source={SearchMinor} />}
              value={searchInput}
              onChange={setSearchInput}
              labelHidden
              label
              placeholder="Search"
            />
          </div>} */}
          {/* {!!tabComponent && (
              <div className="flex items-center gap-4">
                <BubbleToggleButton
                  onClick={() => setDisplayMode!('table')}
                  active={displayMode === 'table'}
                  icon={() => <TableView />}
                />
                <BubbleToggleButton
                  onClick={() => setDisplayMode!('grid')}
                  active={displayMode === 'grid'}
                  icon={() => <CardView />}
                />
              </div>
            )} */}
        </div>
        {showSearch ? <SearchResults /> : <div>{tabComponent}</div>}
      </div>
      <UploadMedia gotToUploaded={() => gotToUploaded()} />
    </ContentHubContext.Provider>
  );
};

const ContentHub = computeFeatureFlags(FeatureFlag.CC_CREATIVE_HUB_FF, ContentHubComponent, () => (
  <UpgradePageFallBack
    classWrapper={'h-[calc(100vh-100px)]'}
    InAppContextBannerEnabled={true}
    InAppContextBannerPage={'creativeHub'}
    featureFlag={FeatureFlag.CC_CREATIVE_HUB_FF}
  />
));

export default ContentHub;
