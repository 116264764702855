import { Button, Card, Spinner, Tooltip } from '@shopify/polaris';
import allServices from 'constants/services';
import { useDarkMode } from 'dark-mode-control';
import { orderBy } from 'lodash';
import { Fragment, useCallback, useEffect, useState } from 'react';
import { AttributionOverlapsResponse, AttributionStatsRequest } from 'types/attribution';
import axiosInstance from 'utils/axiosInstance';
import { Text } from '@tw/ui-components';

type Services = 'facebook-ads' | 'google-ads' | 'tiktok-ads' | 'snapchat-ads' | 'pinterest-ads';
const SERVICES = ['facebook-ads', 'google-ads', 'tiktok-ads', 'snapchat-ads', 'pinterest-ads'];
const services = Object.values(allServices).filter((s) => SERVICES.includes(s.id));

const BACKGROUNDS: Record<Services, string> = {
  'facebook-ads': 'rgba(34,75,136,0.5)',
  'google-ads': 'rgba(49,169,111,0.5)',
  'tiktok-ads': 'rgba(108,32,61,0.5)',
  'snapchat-ads': 'rgba(247,244,64,0.5)',
  'pinterest-ads': 'rgba(197,34,43,0.5)',
};

export default function ChannelOverlap({ getRequestParams, attributionData }) {
  const [data, setData] = useState<any[]>([]);
  const [total, setTotal] = useState(0);
  const [overlaps, setOverlaps] = useState<AttributionOverlapsResponse | null>(null);
  const [showOverlapLabel, setShowOverlapLabel] = useState(false);
  const [loading, setLoading] = useState(true);
  const [channels, setChannels] = useState<Services[]>(['facebook-ads', 'google-ads']);
  const [range, setRange] = useState<string>('');
  const [channelOverlap, setChannelOverlap] = useState<number>(0);
  const doDarkMode = useDarkMode();

  useEffect(() => {
    (async () => {
      const requestParams = await getRequestParams();
      if (!requestParams) return;
      const { params } = requestParams;
      delete params.attributionFilterKey;
      delete params.attributionFilterValue;
      params.breakdown = 'source';
      params.sources = SERVICES;
      const result = await axiosInstance.post<
        any,
        { data: AttributionOverlapsResponse },
        AttributionStatsRequest
      >(`/v2/attribution/overlaps`, params);
      setOverlaps(result.data);
      setLoading(false);
    })();
  }, [getRequestParams]);

  useEffect(() => {
    if (!overlaps || !attributionData || !attributionData.length) {
      return;
    }
    let data = {};
    for (const channel of channels) {
      const service = services.find((service) => service.id === channel);
      if (!service) return;
      const serviceData = attributionData.find((d) => d.id === channel);
      const overlap = overlaps?.[channel] || {};
      let orders = +serviceData?.pixelPurchases || 0; //+(overlap.source_orders || 0);
      orders =
        orders ||
        (+overlap.total_percentage > 0
          ? Math.round(+overlap.total_orders / +overlap.total_percentage)
          : 0);
      data[channel] = {
        numOrders: orders,
        id: service.id,
        title: service.title,
        icon: service.icon,
        color: service.color,
        background: BACKGROUNDS[service.id],
        overlaps: overlap?.total_percentage || 0,
      };
    }
    let orderedData = orderBy<any>(data, 'numOrders', 'desc');

    let biggestNumber = 0;
    orderedData = orderedData.map((d) => {
      let volumePercentage: number;
      if (d?.numOrders === 0) {
        volumePercentage = 0;
      } else if (d?.numOrders > biggestNumber) {
        volumePercentage = 1;
      } else {
        if (d?.numOrders / biggestNumber === 1) {
          volumePercentage = 0.9;
        } else {
          volumePercentage = d?.numOrders / biggestNumber;
        }
      }
      const res = { ...d, volumePercentage };
      biggestNumber = Math.max(biggestNumber, d.numOrders);
      return res;
    });

    setTotal(orderedData.reduce((acc, channel) => acc + channel.numOrders, 0));
    setData(orderedData);
  }, [overlaps, channels, attributionData]);

  const toggleChannel = (channel: Services) => {
    if (channels.includes(channel)) {
      setChannels((all) => all.filter((c) => c !== channel));
    } else {
      if (channels.length === 2) {
        return;
      }
      setChannels((ch) => ch.concat(channel));
    }
  };

  useEffect(() => {
    if (channels.length < 2) {
      setChannelOverlap(0);
    } else {
      const overlap_percentage = +(overlaps?.[channels[0]]?.breakdown?.[channels[1]] || 0);
      const total_orders = +(data.find((channel) => channel.id === channels[0])?.numOrders || 0);
      const overlap = Math.round(+overlap_percentage * total_orders);
      setChannelOverlap(overlap);
    }
  }, [channels, overlaps, data]);

  useEffect(() => {
    (async () => {
      const requestParams = await getRequestParams();
      if (!requestParams) return;
      const {
        period: { start, end },
      } = requestParams;
      let str = '';
      if (start.isSame(end, 'day')) {
        str = start.format('MMM D');
      } else if (start.isSame(end, 'month')) {
        str = start.format('MMM D') + ' - ' + end.format('D');
      } else if (start.isSame(end, 'year')) {
        str = start.format('MMM D') + ' - ' + end.format('MMM D');
      } else {
        str = start.format('YYYY-MM-DD') + ' - ' + end.format('YYYY-MM-DD');
      }
      setRange(str);
    })();
  }, [getRequestParams]);

  const mouseOver = useCallback(
    (e, channel, i) => {
      e.target.style.background = `radial-gradient(circle, ${channel.background} 20%, rgba(${
        doDarkMode ? '0, 0, 0, 0.1' : '240, 240, 240, 0.9'
      }) 100%)`;
      if (i > 0) {
        setShowOverlapLabel(true);
      }
    },
    [doDarkMode],
  );

  const mouseLeave = useCallback((e, channel) => {
    e.target.style.background = `radial-gradient(circle, ${channel.background} 20%, rgba(0, 0, 0, 0) 100%)`;
    setShowOverlapLabel(false);
  }, []);

  return (
    <Card
      title={
        <Text fw={600} size="xl" id="att-channel-overlap">
          Channel Overlap
        </Text>
      }
    >
      <div className="channel-overlap-wrapper">
        <div className="channel-overlap-body dark:!bg-transparent">
          {loading && (
            <div style={{ height: '100%', display: 'flex', alignItems: 'center' }}>
              <Spinner size="large" />
            </div>
          )}
          {!loading && (
            <>
              <div className="channel-overlap-header">
                <div className="channels-names-title">
                  <Text fz="sm">
                    {data.map((channel, i, arr) => (
                      <Fragment key={channel.title}>
                        {channel.title} {i < arr.length - 1 && '& '}
                      </Fragment>
                    ))}
                  </Text>
                </div>

                <div className="sum-data-title">
                  <Text fz="sm">
                    {Number(total).toLocaleString(undefined, {
                      minimumFractionDigits: 0,
                      maximumFractionDigits: 2,
                    })}{' '}
                    Orders
                  </Text>
                </div>

                <div className="date-range-title">
                  <Text fz="sm">{range}</Text>
                </div>
              </div>
              <div className="channel-overlap-channels">
                {data.map((channel, i, arr) => {
                  let overlapLabel = ``;
                  let translationX = 0;
                  if (i > 0 && channel.numOrders) {
                    translationX = (channelOverlap / channel.numOrders) * 100;
                    overlapLabel = `${channelOverlap}`;
                  }
                  return (
                    <Fragment key={channel.title + overlapLabel + i}>
                      <Tooltip
                        dismissOnMouseOut={true}
                        content={channel.title + ` (${channel.numOrders})`}
                      >
                        <div
                          onMouseOver={(e) => mouseOver(e, channel, i)}
                          onMouseLeave={(e) => mouseLeave(e, channel)}
                          className="channel-overlap-item"
                          style={{
                            width: `calc(200px * ${channel.volumePercentage})`,
                            height: `calc(200px * ${channel.volumePercentage})`,
                            borderColor: channel.color,
                            transform: `translateX(-${translationX}%)`,
                            //background: channel.background,
                            // eslint-disable-next-line no-dupe-keys
                            background: `radial-gradient(circle, ${channel?.background} 20%, rgba(0,0,0,0) 100%)`,
                          }}
                        >
                          <span className={'flex'} onMouseOver={(e) => e.stopPropagation()}>
                            {channel.icon({ small: false })}
                          </span>
                          <span onMouseOver={(e) => e.stopPropagation()}>
                            {showOverlapLabel && (
                              <Tooltip content={`${translationX.toFixed(0)}% Overlap`}>
                                <div className="overlap-content">{overlapLabel}</div>
                              </Tooltip>
                            )}
                          </span>
                        </div>
                      </Tooltip>
                    </Fragment>
                  );
                })}
              </div>
              <div className="channel-overlap-footer">
                {services.map((service) => (
                  <Button
                    plain
                    key={service.id}
                    disabled={false}
                    icon={service.icon}
                    pressed={channels.includes(service.id as Services)}
                    onClick={() => toggleChannel(service.id as Services)}
                  >
                    {service.title}
                  </Button>
                ))}
              </div>
            </>
          )}
        </div>
      </div>
    </Card>
  );
}
