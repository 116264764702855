import { openFreeTrialPopup } from '$stores/willy/$upgradePopupManager';
import { Alert, Icon, Text } from '@tw/ui-components';
import { FC } from 'react';

type NoCreditsLeftAlertProps = {
  creditsLeft?: number;
};

export const NoCreditsLeftAlert: FC<NoCreditsLeftAlertProps> = ({ creditsLeft }) => {
  const creditsText = creditsLeft
    ? `You have only ${creditsLeft} credit${creditsLeft === 1 ? '' : 's'} left.`
    : 'You have no credits left.';
  return (
    <Alert color="yellow.3" icon={<Icon name="exclamation" color="yellow.4" size={20} />}>
      <Text size="sm" weight={500} color="yellow.8">
        {`${creditsText} Unlock unlimited reports for your team. `}
        <span
          onClick={() => openFreeTrialPopup('template_library')}
          className="text-[14px] text-[var(--mantine-color-yellow-8)] font-medium underline cursor-pointer"
        >
          Start Free Trial
        </span>
      </Text>
    </Alert>
  );
};
