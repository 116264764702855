import {
  ActionIcon,
  Button,
  Checkbox,
  Collapse,
  Container,
  Flex,
  Grow,
  Icon,
  Modal,
  MultiSelect,
  Select,
  Text,
  Textarea,
  TextInput,
  Tooltip,
} from '@tw/ui-components';
import {
  ExpressionMetricData,
  WillyCustomMetric,
  WillyExpressionMetric,
  WillyExpressionOrCustomMetric,
  WillyMetric,
  WillyWidgetElement,
} from './types/willyTypes';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { valueFormats, valueFormatsArr } from '@tw/types';
import { GRADIENT_CHART_COLORS, RANDOM_COLOR } from 'constants/general';
import { ColorPickerPopover } from './ColorPicker';
import { capitalize } from 'lodash';
import { isDefined } from 'utils/isDefined';
import {
  metricIsCustom,
  metricIsExpression,
  metricIsNonCustomExpression,
} from './WillyMetrics/utils';
import { $dialect, $isTWDevClaim } from '$stores/$user';
import { $tables } from '$stores/willy/$tables';
import { useAppSelector } from 'reducers/RootType';
import { MetricBox, WillySectionRef } from './WillyMetrics/MetricBox';
import { WillyMetricBuilder } from './WillyMetrics/WillyMetricBuilder';
import { WillyCustomMetricBuilder } from './WillyMetrics/WillyCustomMetrics/WillyCustomMetricBuilder';
import { v4 as uuidV4 } from 'uuid';
import { WillyCustomQueryModal } from './WillyCustomQueryModal';
import axiosInstance from 'utils/axiosInstance';
import { useSelector } from 'react-redux';
import { formatSqlSafely, keyIsSomeDate, saveNewQuery } from './utils/willyUtils';
import { Prism as ReactSyntaxHighlighter } from 'react-syntax-highlighter';
import { vscDarkPlus } from 'react-syntax-highlighter/dist/esm/styles/prism';
import { IconPicker } from './IconPicker';
import { analyticsEvents, genericEventLogger, metricBuilderActions } from 'utils/dataLayer';
import { WillyMetricUrls } from './WillyMetricUrls';
import SummaryDatePicker from '../SummaryDatePicker';
import { WillyPanelTabs } from './WillyPanelTabs';
import { useFeatureFlag } from 'feature-flag-system';
import { FeatureFlag } from '@tw/feature-flag-system/module/types';
import { $currentDateRange, $prevDateRange } from '../../$stores/willy/$dateRange';
import moment from 'moment';
import { $activeAccounts } from '../../$stores/$shop';
import { toast } from 'react-toastify';
import {
  AggregationFunction,
  AggregationFunctions,
} from '@tw/willy-data-dictionary/module/columns/types';
import { useStoreValue } from '@tw/snipestate';

const YYYY = moment().format('YYYY');
const MM = moment().format('MM');
const DD = moment().format('DD');
const YYYYMM = moment().format('YYYY-MM');
const YYYYMMDD = moment().format('YYYY-MM-DD');
const LT = moment().format('LT');
const LTS = moment().format('LTS');
const L = moment().format('L');
const LL = moment().format('LL');
const LLL = moment().format('LLL');
const LLLL = moment().format('LLLL');

const WillyEmptyCustomMetric: WillyCustomMetric = {
  id: '',
  key: '',
  name: '',
  description: '',
  toFixed: 2,
  format: valueFormatsArr[0],
  color: RANDOM_COLOR.start,
  colorName: RANDOM_COLOR.name,
  icon: 'blended-metrics',
  expression: [],
};

const WillyEmptyExpressionMetric: WillyExpressionMetric = {
  id: '',
  key: '',
  name: '',
  description: '',
  toFixed: 2,
  format: valueFormatsArr[0],
  color: RANDOM_COLOR.start,
  colorName: RANDOM_COLOR.name,
  icon: 'blended-metrics',
  tableId: '',
  columnId: '',
  aggFunction: 'SUM',
};

type WillyEditMetricProps = {
  open: boolean;
  metric: WillyMetric | WillyExpressionOrCustomMetric | null;
  availableMetrics: WillyMetric[];
  fromExpressionBuilder?: boolean;
  isFilterMode?: boolean;
  onClose: () => void;
  onSaved: (metric: WillyMetric, isEdit: boolean, isFilterMode: boolean) => Promise<void>;
  onRemoved: (metric: WillyMetric) => Promise<void>;
  onResetOverride?: (metric: WillyMetric) => Promise<void>;
};

const allAvailableFunctionCalls = [
  {
    label: 'get_customer_journey',
    value: 'get_customer_journey',
    oneOfTheseRequired: ['triple_id', 'customer_id', 'order_id', 'customer_email'],
  },
];

export const WillyEditMetric: React.FC<WillyEditMetricProps> = ({
  open,
  metric,
  availableMetrics,
  fromExpressionBuilder,
  isFilterMode,
  onClose,
  onSaved,
  onRemoved,
  onResetOverride,
}) => {
  const isTWDevClaim = useStoreValue($isTWDevClaim);
  const tables = useStoreValue($tables);
  const dialect = useStoreValue($dialect);
  const currentDateRange = useStoreValue($currentDateRange);
  const prevDateRange = useStoreValue($prevDateRange);
  const activeAccounts = useStoreValue($activeAccounts);
  const currency = useAppSelector((state) => state.activeCurrency);
  const shopTimezone = useAppSelector((state) => state.shopTimezone);
  const currentShopId = useAppSelector((state) => state.currentShopId);
  const isOverride = useMemo(
    () =>
      metricIsExpression(metric) &&
      metricIsNonCustomExpression(metric) &&
      metric.filter?.some((x) => x.isOverride),
    [metric],
  );
  const [isLoading, setIsLoading] = useState(false);
  const [showTest, setShowTest] = useState(false);
  const [customMetricValidationError, setCustomMetricValidationError] = useState<boolean>(false);
  const [isFilterValid, setIsFilterValid] = useState(true);
  const [conditionalStyleValueType, setConditionalStyleValueType] = useState<
    'number' | 'boolean' | 'null'
  >('number');
  const [editedMetric, setEditedMetric] = useState<
    WillyMetric | WillyExpressionOrCustomMetric | null
  >();
  const [customQueryModalOpened, setCustomQueryModalOpened] = useState(false);
  const [errorSavingQuery, setErrorSavingQuery] = useState('');
  const [viewMore, setViewMore] = useState(false);
  const [metricIsCustomMetric, setMetricIsCustomMetric] = useState(false);

  const previewRef = useRef<HTMLDivElement | null>(null);
  const boxRef = useRef<WillySectionRef>(null);
  const isEdit = useMemo(() => !!metric, [metric]);
  const modalBodyRef = useRef<HTMLDivElement | null>(null);

  const anyMetric: any = useMemo(() => editedMetric, [editedMetric]);

  const { shouldNotBeSeen: isSQLBlocked } = useFeatureFlag(FeatureFlag.SQL_FF);

  const formattedSql = useMemo(() => {
    if (!editedMetric || metricIsExpression(editedMetric)) {
      return '';
    }
    let queryToFormat = editedMetric.popupWidget?.queries?.[0]?.query || '';
    try {
      return formatSqlSafely(queryToFormat);
    } catch (e) {
      return queryToFormat;
    }
  }, [editedMetric]);

  const metricIsExpressionMetric = useMemo(() => {
    if (!editedMetric) {
      return !!fromExpressionBuilder;
    }

    return metricIsExpression(editedMetric);
  }, [fromExpressionBuilder, editedMetric]);

  useEffect(() => {
    if (!metricIsExpressionMetric) {
      setMetricIsCustomMetric(false);
    } else {
      setMetricIsCustomMetric(metricIsCustom(editedMetric as WillyExpressionOrCustomMetric));
    }
  }, [editedMetric, metricIsExpressionMetric]);

  const testMetric = useCallback(async () => {
    if (!editedMetric || !metricIsExpression(editedMetric)) return;
    boxRef.current?.fetch(editedMetric);
    boxRef.current?.fetchPreviousPeriod(editedMetric);
    boxRef.current?.applyTestMetric(editedMetric);
    setShowTest(true);
    genericEventLogger(analyticsEvents.METRIC_BUILDER, {
      action: metricIsCustomMetric
        ? metricBuilderActions.TEST_CUSTOM_METRIC
        : metricBuilderActions.TEST_METRIC,
      metric: editedMetric.name,
    });
  }, [editedMetric, metricIsCustomMetric]);

  const [isGlobal, setIsGlobal] = useState(false);

  useEffect(() => {
    if (!metric || !metricIsExpression(metric)) {
      setIsGlobal(false);
      return;
    }
    setIsGlobal(!!metric.isGlobal);
  }, [metric]);

  const isSaveDisabled = useMemo(() => {
    let isDisabled = false;
    if (!editedMetric) return true;
    if (!editedMetric.name || !editedMetric.icon) {
      isDisabled = true;
    }

    if (
      metricIsExpression(editedMetric) &&
      !metricIsCustom(editedMetric) &&
      (!editedMetric.columnId || !editedMetric.tableId || !isFilterValid)
    ) {
      isDisabled = true;
    }
    if (
      metricIsExpression(editedMetric) &&
      metricIsCustom(editedMetric) &&
      customMetricValidationError
    ) {
      isDisabled = true;
    }
    const willyMetric = editedMetric as WillyMetric;
    if (willyMetric?.onClickAction === 'function' && !willyMetric?.functionName) {
      isDisabled = true;
    }

    return isDisabled;
  }, [customMetricValidationError, editedMetric, isFilterValid]);

  const metricsForPossibleParameters = useMemo(() => {
    return availableMetrics.filter((x) => x.isDimension).filter((x) => !keyIsSomeDate(x.key));
  }, [availableMetrics]);

  const availableFunctionCalls = useMemo(() => {
    const availableFunctionCalls = allAvailableFunctionCalls.filter((f) => {
      if (f.oneOfTheseRequired) {
        return f.oneOfTheseRequired.some((x) => {
          return metricsForPossibleParameters.some((m) => m.key === x);
        });
      }
      return true;
    });
    return availableFunctionCalls;
  }, [metricsForPossibleParameters]);

  const onClickOptions = useMemo(() => {
    const options = [
      { label: 'Do Nothing', value: 'none' },
      { label: 'Run a Query', value: 'query' },
      { label: 'Open a Dashboard', value: 'url' },
    ];
    if (availableFunctionCalls.length > 0) {
      options.push({ label: 'Call a Function', value: 'function' });
    }
    return options;
  }, [availableFunctionCalls]);

  useEffect(() => {
    if (!anyMetric) {
      return;
    }
    if (!metricIsExpression(anyMetric)) {
      return;
    }

    if (metricIsCustom(anyMetric)) {
      return;
    }

    if (anyMetric.tableId && anyMetric.columnId && !isEdit) {
      let autoId = anyMetric.tableId + '_' + anyMetric.columnId;
      if (anyMetric.relatedProvider) {
        autoId = autoId + '_' + anyMetric.relatedProvider;
      }
      setEditedMetric((old) => {
        if (!old) {
          return old;
        }
        //need this random since we want the key will be unique
        const random = Math.floor(1000 + Math.random() * 9000);
        return {
          ...old,
          id: autoId + '_' + random,
          key: autoId + '_' + random,
        };
      });
    }
    // don't include the anyMetric in the dependencies
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [anyMetric?.tableId, anyMetric?.columnId, anyMetric?.relatedProvider, tables, open]);

  useEffect(() => {
    if (metric && open) {
      setEditedMetric(metric);
    } else if (metricIsExpressionMetric && metricIsCustomMetric) {
      const m: WillyCustomMetric = WillyEmptyCustomMetric;
      m.isCustomMetric = true;
      m.id = uuidV4().replaceAll('-', '_');
      setEditedMetric(m);
      setIsGlobal(false);
    } else if (metricIsExpressionMetric) {
      const m: WillyExpressionMetric = WillyEmptyExpressionMetric;
      m.id = uuidV4().replaceAll('-', '_');
      setIsGlobal(false);
      setEditedMetric(m);
    } else {
      const m: WillyMetric = {
        key: '',
        name: '',
        description: '',
        toFixed: 2,
        format: valueFormatsArr[0],
        color: RANDOM_COLOR.start,
        colorName: RANDOM_COLOR.name,
        icon: 'blended-metrics',
        onClickAction: 'none',
      };
      setEditedMetric(m);
    }
    setShowTest(false);
  }, [metric, metricIsExpressionMetric, metricIsCustomMetric, open]);

  const saveNewQueryFromPopup = useCallback(
    async (
      query: Omit<WillyWidgetElement, 'queryId'>,
      variables?: Record<string, any> | undefined,
    ) => {
      if (!activeAccounts || !dialect) {
        setErrorSavingQuery(`Some data didn't load yet, please try again later`);
        return false;
      }
      const res = await saveNewQuery({
        widgetWithoutQuery: query,
        metric: editedMetric as WillyMetric,
        shopId: currentShopId,
        timezone: shopTimezone,
        currency,
        activeAccounts,
        variables,
        dialect,
      });
      if (res.error) {
        setErrorSavingQuery(res.error);
        return false;
      } else if (res.metric) {
        setEditedMetric(res.metric);
        setErrorSavingQuery('');
        return true;
      } else {
        setErrorSavingQuery('Unknown error');
        return false;
      }
    },
    [activeAccounts, currency, currentShopId, editedMetric, shopTimezone, dialect],
  );

  const _onSaved = useCallback(
    async (e) => {
      e.preventDefault();
      if (!editedMetric) return;

      setIsLoading(true);
      try {
        await onSaved(
          metricIsExpressionMetric ? ({ ...editedMetric, isGlobal } as any) : editedMetric,
          isEdit,
          !!isFilterMode,
        );
        toast.success('Metric saved');
      } catch (e) {
        console.error(e);
      }
      setIsLoading(false);
    },
    [editedMetric, onSaved, metricIsExpressionMetric, isGlobal, isEdit, isFilterMode],
  );

  if (!editedMetric) return null;

  return (
    <>
      <Modal
        closeOnClickOutside={false}
        opened={open}
        onClose={onClose}
        headerBorder
        size="lg"
        padding={0}
        withCloseButton={false}
      >
        <Modal.Header>
          <Flex justify="space-between" px="md" align="center" w="100%">
            <Flex direction="column">
              <Text fw="bold">
                {isFilterMode
                  ? `Filter Metric: ${metric?.name}`
                  : isEdit
                    ? `Edit ${isGlobal ? 'Global' : ''} Metric: ${metric?.name}`
                    : `${isGlobal ? 'Global' : ''} Metric Builder`}
              </Text>
              {(metric as WillyMetric)?.expressionMetric && (
                <Text size="sm">Expression metric</Text>
              )}
              <Text size="xs" color="gray.5">
                {metric?.key}
              </Text>
            </Flex>
            <Flex ml="auto" gap="xs" align="center">
              {metricIsExpressionMetric && !isEdit && (
                <WillyPanelTabs
                  tabs={[
                    { value: 'custom', label: 'Custom Metric' },
                    { value: 'calculated', label: 'Calculated Metric' },
                  ]}
                  value={metricIsCustomMetric ? 'calculated' : 'custom'}
                  onTabChange={(v) => {
                    setMetricIsCustomMetric(v === 'calculated');
                  }}
                  className="!bg-transparent"
                />
              )}

              <ActionIcon icon="close" iconSize={12} onClick={onClose} variant="transparent" />
            </Flex>
          </Flex>
        </Modal.Header>
        <div className="-mb-6.5" ref={modalBodyRef}>
          <Flex direction="column" gap="lg" p="md">
            {metricIsExpressionMetric && isTWDevClaim && (
              <div>
                <Checkbox
                  disabled={!!(metric as ExpressionMetricData) || !!isFilterMode}
                  label={'Global Metric'}
                  checked={isGlobal}
                  onChange={setIsGlobal}
                />
              </div>
            )}
            <TextInput
              label="Metric Title"
              placeholder="Enter Title"
              disabled={isFilterMode}
              value={editedMetric.name}
              onChange={(v) => setEditedMetric({ ...editedMetric, name: v })}
            />
            <Textarea
              label="Metric Description"
              placeholder="Enter Description"
              disabled={isFilterMode}
              value={editedMetric.description}
              onChange={(v) => setEditedMetric({ ...editedMetric, description: v.target.value })}
              minRows={4}
              autosize
            />
            {metricIsExpressionMetric && !metricIsCustomMetric && (
              <WillyMetricBuilder
                metric={editedMetric as WillyExpressionMetric}
                setIsFilterValid={setIsFilterValid}
                setMetric={
                  setEditedMetric as React.Dispatch<React.SetStateAction<WillyExpressionMetric>>
                }
                isFilterMode={isFilterMode}
                isFilter={isFilterMode}
              />
            )}

            {metricIsCustomMetric &&
              metricIsCustom(editedMetric as WillyExpressionOrCustomMetric) && (
                <WillyCustomMetricBuilder
                  isGlobal={isGlobal}
                  metric={editedMetric as WillyCustomMetric}
                  setMetric={
                    setEditedMetric as React.Dispatch<React.SetStateAction<WillyCustomMetric>>
                  }
                  setIsError={setCustomMetricValidationError}
                />
              )}

            <Flex
              gap="sm"
              align="center"
              cursor="pointer"
              onClick={() => setViewMore((curr) => !curr)}
            >
              <Text>View More</Text>
              <Icon name={viewMore ? 'caret-up-bold' : 'caret-down-bold'} size={14} />
            </Flex>
            <Collapse in={viewMore}>
              <Flex direction="column" gap="lg">
                <Flex gap="md" direction="column">
                  {metricIsExpressionMetric && !metricIsCustomMetric && (
                    <Grow>
                      <Tooltip
                        label={
                          AggregationFunctions[
                            (editedMetric as WillyExpressionMetric).aggFunction || 'SUM'
                          ]?.description
                        }
                      >
                        <Select
                          allowDeselect={false}
                          disabled={isFilterMode}
                          label="Aggregation"
                          value={(editedMetric as WillyExpressionMetric).aggFunction}
                          data={Object.values(AggregationFunctions).map((f) => ({
                            label: f.id,
                            value: f.id,
                          }))}
                          onChange={(v) => {
                            if (!isDefined(v)) return;
                            setEditedMetric({
                              ...editedMetric,
                              aggFunction: v as AggregationFunction,
                            });
                          }}
                        />
                      </Tooltip>
                    </Grow>
                  )}
                  {(editedMetric as WillyMetric).isDimension && (
                    <Flex>
                      <Checkbox
                        label="Is Date"
                        checked={(editedMetric as WillyMetric).isDate}
                        onChange={(checked) => {
                          setEditedMetric({ ...editedMetric, isDate: checked });
                        }}
                      />
                    </Flex>
                  )}
                  {!(editedMetric as WillyMetric).isDate && (
                    <Grow>
                      <Select
                        allowDeselect={false}
                        label="Format"
                        disabled={isFilterMode}
                        value={editedMetric.format}
                        data={valueFormatsArr
                          .filter((f) =>
                            ['string', 'decimal', 'percent', 'currency', 'duration'].includes(f),
                          )
                          .map((f) => ({ label: capitalize(f), value: f }))}
                        onChange={(v) => {
                          if (!isDefined(v)) return;
                          setEditedMetric({ ...editedMetric, format: v as valueFormats });
                        }}
                      />
                    </Grow>
                  )}
                  {(editedMetric as WillyMetric).isDate && (
                    <Grow>
                      <Select
                        allowDeselect={false}
                        label="Date Format"
                        disabled={isFilterMode}
                        value={editedMetric.dateFormat}
                        data={[
                          { label: 'Auto', value: '' },
                          {
                            label: 'Do not format (Good if you formatted the date in the query)',
                            value: 'none',
                          },
                          { label: `Only year (${YYYY})`, value: 'YYYY' },
                          { label: `Only month (${MM})`, value: 'MM' },
                          { label: `Only day (${DD})`, value: 'DD' },
                          { label: `Year - Month (${YYYYMM})`, value: 'YYYY-MM' },
                          { label: `Year - Month - Day (${YYYYMMDD})`, value: 'YYYY-MM-DD' },
                          { label: `Hour and minute (${LT})`, value: 'LT' },
                          { label: `Hour, minute and second (${LTS})`, value: 'LTS' },
                          { label: `Short date (${L})`, value: 'L' },
                          { label: `Long date (${LL})`, value: 'LL' },
                          { label: `Long date and time (${LLL})`, value: 'LLL' },
                          { label: `Day of week, long date and time (${LLLL})`, value: 'LLLL' },
                        ]}
                        onChange={(v) => {
                          if (!isDefined(v)) return;
                          setEditedMetric({ ...editedMetric, dateFormat: v });
                        }}
                      />
                    </Grow>
                  )}
                </Flex>
                <Flex gap="md">
                  <Grow>
                    <TextInput
                      label="Minimum decimal places"
                      type="number"
                      disabled={isFilterMode}
                      value={editedMetric.minimumFractionDigits?.toString() || '0'}
                      onChange={(v) =>
                        setEditedMetric({
                          ...editedMetric,
                          minimumFractionDigits: Math.min(+v, editedMetric.toFixed ?? 0),
                        })
                      }
                      description="Cannot be greater than maximum decimal places"
                    />
                  </Grow>
                  <Grow>
                    <TextInput
                      label="Maximum decimal places"
                      type="number"
                      disabled={isFilterMode}
                      value={editedMetric.toFixed?.toString()}
                      onChange={(v) =>
                        setEditedMetric({
                          ...editedMetric,
                          toFixed: Math.max(+v, editedMetric.minimumFractionDigits ?? 0),
                        })
                      }
                      description="Cannot be less than minimum decimal places"
                    />
                  </Grow>
                </Flex>
                <Checkbox
                  label="Display as Negative Value"
                  checked={editedMetric.valueIsNegative}
                  onChange={(v) => {
                    setEditedMetric({ ...editedMetric, valueIsNegative: v });
                  }}
                  description="For negative metrics, the metric will be displayed as an absolute value without negative sign"
                />
              </Flex>
            </Collapse>

            <Container bg="gray.2" w="calc(100% + 32px)" h={1} ml={-16} />

            {!isFilterMode && (
              <Flex gap="lg">
                <ColorPickerPopover
                  label="Metric Color"
                  activatorClassName="w-[28px] h-[28px] !rounded-[6px]"
                  className="gap-[12px]"
                  color={editedMetric.color || GRADIENT_CHART_COLORS[0].start}
                  onChange={(color) => {
                    setEditedMetric({
                      ...editedMetric,
                      color,
                      colorName: `color_${color.slice(1)}`,
                    });
                  }}
                />
                <Flex align="center" gap="sm">
                  <Text>Metric Icon</Text>
                  <IconPicker
                    selectedIcon={editedMetric.icon}
                    onChange={(icon) => {
                      setEditedMetric({ ...editedMetric, icon });
                    }}
                  />
                </Flex>
              </Flex>
            )}

            {!metricIsExpressionMetric && (
              <Flex direction="column" gap="xs">
                <Text size="md">
                  <p>When I click on this metric, I want to:</p>
                </Text>
                <Select
                  allowDeselect={false}
                  disabled={isFilterMode}
                  value={(editedMetric as WillyMetric).onClickAction || 'none'}
                  data={onClickOptions}
                  onChange={(v) => {
                    if (!isDefined(v)) return;
                    setEditedMetric({
                      ...editedMetric,
                      onClickAction: v as 'query' | 'url' | 'none',
                    });
                    setTimeout(() => {
                      if (modalBodyRef.current) {
                        modalBodyRef.current.scrollIntoView({ behavior: 'smooth', block: 'end' });
                      }
                    }, 100);
                  }}
                />
                {(editedMetric as WillyMetric).onClickAction === 'url' && (
                  <WillyMetricUrls
                    metric={editedMetric as WillyMetric}
                    onChange={setEditedMetric}
                  />
                )}
                {(editedMetric as WillyMetric).onClickAction === 'query' && (
                  <div className="prose dark:prose-invert flex flex-col gap-4">
                    {(editedMetric as WillyMetric).popupWidget?.queries?.length && (
                      <div>
                        <ReactSyntaxHighlighter
                          language={'sql'}
                          style={vscDarkPlus}
                          showLineNumbers
                          customStyle={{ maxHeight: '300px' }}
                        >
                          {formattedSql}
                        </ReactSyntaxHighlighter>
                      </div>
                    )}
                    <div className="flex items-center gap-4">
                      {(editedMetric as WillyMetric).popupWidget?.queries?.length && (
                        <Button
                          variant="white"
                          onClick={async () => {
                            const confirmed = confirm(
                              'Are you sure you want to delete this query?',
                            );
                            if (confirmed) {
                              setEditedMetric({
                                ...editedMetric,
                                popupWidget: null,
                              });
                            }
                          }}
                        >
                          Delete Query
                        </Button>
                      )}
                      {metricsForPossibleParameters.length > 0 && (
                        <MultiSelect
                          size="sm"
                          // label="Pass parameters to query"
                          placeholder="Pass parameters to the query"
                          value={(editedMetric as WillyMetric).queryVars || []}
                          onChange={async (value) => {
                            setEditedMetric({ ...editedMetric, queryVars: value });
                          }}
                          data={metricsForPossibleParameters.map((x) => {
                            return {
                              value: x.key,
                              label: x.key,
                            };
                          })}
                        />
                      )}
                      <Tooltip label={isSQLBlocked ? 'Upgrade to access SQL' : ''}>
                        <Button
                          variant="primary"
                          onClick={() => {
                            setCustomQueryModalOpened(true);
                          }}
                          disabled={isSQLBlocked}
                        >
                          {!!(editedMetric as WillyMetric).popupWidget ? 'Edit Query' : 'Add Query'}
                        </Button>
                      </Tooltip>
                    </div>
                  </div>
                )}
                {(editedMetric as WillyMetric).onClickAction === 'function' && (
                  <div className="flex items-center gap-4">
                    <Select
                      allowDeselect={false}
                      value={(editedMetric as WillyMetric).functionName || 'none'}
                      data={availableFunctionCalls}
                      onChange={(v) => {
                        if (!isDefined(v)) return;
                        setEditedMetric({
                          ...editedMetric,
                          functionName: v,
                        });
                      }}
                    />
                    {metricsForPossibleParameters.length > 0 && (
                      <MultiSelect
                        size="sm"
                        placeholder="Pass parameters to the function"
                        value={(editedMetric as WillyMetric).queryVars || []}
                        onChange={async (value) => {
                          setEditedMetric({ ...editedMetric, queryVars: value });
                        }}
                        data={metricsForPossibleParameters
                          .filter((x) =>
                            availableFunctionCalls
                              ?.find((x) => (editedMetric as WillyMetric)?.functionName === x.value)
                              ?.oneOfTheseRequired?.includes(x.key),
                          )
                          .map((x) => {
                            return {
                              value: x.key,
                              label: x.key,
                            };
                          })}
                      />
                    )}
                  </div>
                )}
              </Flex>
            )}
            {!metricIsExpressionMetric && (
              <Flex direction="column">
                <Flex direction="row" gap="xs">
                  <Select
                    label="Conditional Style"
                    disabled={isFilterMode}
                    allowDeselect={false}
                    value={(editedMetric as WillyMetric).conditionalStyleType || 'none'}
                    data={[
                      { label: 'None', value: 'none' },
                      { label: 'Single Value', value: 'singleValue' },
                      { label: 'Scale', value: 'scale' },
                    ]}
                    onChange={(v) => {
                      if (v === null) {
                        return;
                      }
                      setEditedMetric({
                        ...editedMetric,
                        conditionalStyleType: v as 'none' | 'singleValue',
                      });
                    }}
                  />
                  {(editedMetric as WillyMetric).conditionalStyleType === 'singleValue' && (
                    <Select
                      label="Condition"
                      data={[
                        {
                          label: `Is ${editedMetric.valueIsNegative ? 'less' : 'greater'} than`,
                          value: 'number',
                        },
                        {
                          label: 'Is true',
                          value: 'boolean',
                        },
                        {
                          label: 'Is not empty',
                          value: 'null',
                        },
                      ]}
                      allowDeselect={false}
                      value={conditionalStyleValueType}
                      onChange={(v) => {
                        if (v === null) {
                          return;
                        }
                        setConditionalStyleValueType(v as 'number' | 'boolean' | 'null');
                        if (v === 'boolean') {
                          setEditedMetric({
                            ...editedMetric,
                            conditionalStyleValue: 'true',
                          });
                        } else if (v === 'null') {
                          setEditedMetric({
                            ...editedMetric,
                            conditionalStyleValue: 'null',
                          });
                        } else {
                          setEditedMetric({
                            ...editedMetric,
                            conditionalStyleValue: '0',
                          });
                        }
                      }}
                    />
                  )}
                  {(editedMetric as WillyMetric).conditionalStyleType === 'singleValue' &&
                    conditionalStyleValueType === 'number' && (
                      <TextInput
                        label="Value"
                        type="text"
                        value={
                          (editedMetric as WillyMetric).conditionalStyleValue?.toString() || '0'
                        }
                        onChange={(v) => {
                          setEditedMetric({
                            ...editedMetric,
                            conditionalStyleValue: v,
                          });
                        }}
                      />
                    )}
                  {(editedMetric as WillyMetric).conditionalStyleType === 'scale' && (
                    <ColorPickerPopover
                      label="Color Scale"
                      activatorClassName="w-8 h-8"
                      color={
                        (editedMetric as WillyMetric).conditionalStyleColor ||
                        GRADIENT_CHART_COLORS[0].start
                      }
                      onChange={(color) => {
                        setEditedMetric({
                          ...editedMetric,
                          conditionalStyleColor: color,
                        });
                      }}
                    />
                  )}
                </Flex>
              </Flex>
            )}
            {metricIsExpressionMetric && (
              <Collapse in={showTest} ref={previewRef}>
                <Container bg="gray.2" w="calc(100% + 32px)" h={1} ml={-16} mb={20} />
                <Flex gap="lg" wrap="wrap">
                  <Button
                    variant="activator"
                    leftSection={<Icon name="reset" size={16} />}
                    onClick={testMetric}
                  >
                    Regenerate
                  </Button>
                  <SummaryDatePicker
                    showGroupByButton={false}
                    skipLoadOnCompareChange
                    showCompareButton
                    compareSeparately={false}
                    isWillyDashboard
                  />
                  <MetricBox
                    ref={boxRef}
                    metricId={(editedMetric as WillyExpressionMetric).id}
                    prevDateRange={prevDateRange}
                    currentDateRange={currentDateRange}
                    currency={currency}
                  />
                </Flex>
              </Collapse>
            )}
          </Flex>
          <Modal.Footer border>
            <Flex justify={'space-between'} align={'center'} px="md">
              <Grow>
                {metricIsExpressionMetric && (
                  <Tooltip
                    label={
                      isSaveDisabled || !currentDateRange
                        ? 'complete define metric in order to test it'
                        : metricIsExpression(editedMetric) && editedMetric.isBlocked
                          ? 'Upgrade to test this metric'
                          : metricIsExpression(editedMetric) && editedMetric.isNotConnected
                            ? 'Integration is not connected'
                            : `Test the metric with the current date range`
                    }
                  >
                    <Button
                      variant="secondary"
                      disabled={
                        showTest
                          ? false
                          : isSaveDisabled ||
                            !currentDateRange ||
                            (metricIsExpression(editedMetric) &&
                              (editedMetric.isBlocked || editedMetric.isNotConnected))
                      }
                      loading={isLoading}
                      onClick={() => {
                        setTimeout(() => {
                          if (!showTest && previewRef.current) {
                            previewRef.current.scrollIntoView({ behavior: 'smooth' });
                          }
                        }, 200);
                        showTest ? setShowTest(false) : testMetric();
                      }}
                    >
                      {showTest ? 'Close Preview' : 'Open Preview'}
                    </Button>
                  </Tooltip>
                )}
                {!metricIsExpressionMetric && (
                  <Button
                    onClick={async () => {
                      if (!editedMetric) return;
                      setIsLoading(true);
                      await onRemoved(editedMetric as WillyMetric);
                      setIsLoading(false);
                    }}
                    type="button"
                    variant="danger"
                  >
                    Reset
                  </Button>
                )}
              </Grow>
              <Grow>
                <Flex gap={'xs'} justify={'flex-end'}>
                  {isFilterMode && isOverride && (
                    <Button
                      color={'red.6'}
                      disabled={isSaveDisabled}
                      loading={isLoading}
                      onClick={async () => {
                        setIsLoading(true);
                        await onResetOverride?.(editedMetric as WillyMetric);
                        setIsLoading(false);
                      }}
                    >
                      Reset to Default
                    </Button>
                  )}
                  <Flex ml="auto" gap="md">
                    <Button variant="white" onClick={onClose}>
                      Cancel
                    </Button>
                    <Button
                      loading={isLoading}
                      onClick={_onSaved}
                      disabled={isSaveDisabled}
                      type="submit"
                    >
                      Save
                    </Button>
                  </Flex>
                </Flex>
              </Grow>
            </Flex>
          </Modal.Footer>
        </div>
      </Modal>

      <WillyCustomQueryModal
        metric={editedMetric as WillyMetric}
        opened={customQueryModalOpened}
        onClose={() => setCustomQueryModalOpened(false)}
        querySaved={saveNewQueryFromPopup}
        errorSavingQuery={errorSavingQuery}
        queryVars={(editedMetric as WillyMetric).queryVars}
      />
    </>
  );
};
