import { GenericActionResult } from '@tw/types/module/willy/langchain/langchain';
import { updateColorScheme } from 'dark-mode-control';
import { useEffect } from 'react';
import { useAppDispatch } from '../../../../../index';

export const WillyActionTheme: React.FC<{ data: GenericActionResult }> = ({ data }) => {
  const {
    data: { data: theme },
  } = data;

  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(updateColorScheme(theme === 'dark'));
  }, [dispatch, theme]);

  return <></>;
};
