import { useEffect } from 'react';
import { useHistory } from 'react-router';
import { $store } from '@tw/snipestate';

/** Store containing loction state of react router */
export const $history = $store<Partial<ReturnType<typeof useHistory>>>({});

/** Hook used to sync react router location with $location store */
export function useConnectHistory$() {
  const history = useHistory();

  useEffect(() => {
    $history.set(history);
  }, [history]);
}
