import { GRADIENT_CHART_COLORS, GradientChartDefs } from 'constants/general';
import { useDarkMode } from 'dark-mode-control';
import React, { FC, Fragment, useCallback, useMemo, useState } from 'react';
import {
  Area,
  Bar,
  ComposedChart,
  Line,
  ReferenceLine,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
  Scatter,
} from 'recharts';
import { isMobileApp } from 'utils/Device';
import { ServicesIds } from '@tw/types/module/services';
import {
  ChartInfo,
  RawNlqData,
  WillyDataColumn,
  WillyMetric,
  ChatSources,
  EditMetricModalPayload,
  ChartDataItem,
  AxisDomain,
  WillyDashboardElement,
} from './types/willyTypes';
import { mean as lodashMean, max } from 'lodash';
import { formatNumber } from 'utils/formatNumber';
import _db from 'utils/DB';
import {
  convertDataToChart,
  getCurrentAnalyticsActionSet,
  getDataLayerEventByContext,
  median,
  mode,
} from './utils/willyUtils';
import { ColoredCheckbox } from 'components/library/ColoredCheckbox';
import { CaretDownMinor } from '@shopify/polaris-icons';
import { DEFAULT_AXIS_DOMAIN, chartTypes } from './constants';
import { v4 as uuidV4 } from 'uuid';
import { formatValue, formatText, formatColor } from './utils/formatters';
import { closestCenter, DndContext, DragOverlay, useDroppable } from '@dnd-kit/core';
import { SortableContext, rectSortingStrategy, useSortable } from '@dnd-kit/sortable';
import { ReactComponent as DragHandle } from '../../icons/drag-handle.svg';
import { CSS } from '@dnd-kit/utilities';
import { Portal } from 'components/Portal';
import { Icon, Text, Menu, Checkbox, Flex, ActionIcon, Popover } from '@tw/ui-components';
import { useWillyDndMetrics } from './hooks/useWillyDndMetrics';
import { WillyPieChart } from './WillyPieChart';
import allServices from 'constants/services';
import { useWindowSize } from 'utils/useWindowSize';
import { ColorPickerPopover } from './ColorPicker';
import {
  genericEventLogger,
  analyticsEvents,
  chatActions,
  sqwhaleActions,
  dashboardsActions,
} from 'utils/dataLayer';

type WillyChartProps = {
  rawData: RawNlqData;
  errorInQuery: Record<string, string>;
  previousPeriodData?: RawNlqData;
  dataColumns: WillyDataColumn;
  queryId: string;
  serviceIds?: ServicesIds[];
  loading?: boolean;
  loadingPreviousPeriod?: boolean;
  context: ChatSources;
  metrics: WillyMetric[];
  currency: string;
  metricsChanged: (id: string, v: WillyMetric[]) => Promise<void>;
  setEditMetricModalOpen?: EditMetricModalPayload;
  stacked?: boolean;
  skinny?: boolean;
  incrementedStacked?: boolean;
  type?: 'chart' | 'pie' | 'funnel';
  showPreviousPeriod?: boolean;
  yAxisDomain: AxisDomain;
  allowDataOverflow?: boolean;
  dimension?: string;
  dashboard?: WillyDashboardElement;
  allowLegendDnd?: boolean;
  showQuickEdit?: boolean;
};

const orientations = ['left', 'right'] as const;
const barRadius = [3, 3, 0, 0] as [number, number, number, number];

export const WillyChart: React.FC<WillyChartProps> = ({
  rawData,
  previousPeriodData,
  errorInQuery,
  queryId,
  dataColumns,
  loading,
  loadingPreviousPeriod,
  context,
  metrics,
  currency,
  metricsChanged,
  setEditMetricModalOpen,
  stacked,
  skinny,
  incrementedStacked,
  type,
  showPreviousPeriod = false,
  yAxisDomain,
  allowDataOverflow,
  dimension,
  dashboard,
  allowLegendDnd = true,
  showQuickEdit,
}) => {
  const doDarkMode = useDarkMode();
  const windowSize = useWindowSize();

  const nlqRawData = useMemo(
    () => ({
      data: rawData,
      previousPeriodData: showPreviousPeriod ? previousPeriodData : undefined,
      dataColumns: dataColumns,
    }),
    [dataColumns, rawData, previousPeriodData, showPreviousPeriod],
  );

  const isFunnelChart = useMemo(() => {
    const hasNoDimensions =
      !Object.keys(nlqRawData?.dataColumns).includes('x') ||
      nlqRawData?.dataColumns?.x?.length === 0;

    const hasMetrics = Object.keys(nlqRawData?.dataColumns).includes('y');
    const hasMoreThanOneMetric = nlqRawData?.dataColumns?.y?.length > 1;
    const isFunnel = type === 'funnel';

    if (hasNoDimensions && hasMetrics && hasMoreThanOneMetric && isFunnel) {
      return true;
    }
    return false;
  }, [nlqRawData?.dataColumns, type]);

  const chartInfo: ChartInfo = useMemo(() => {
    if (isFunnelChart) {
      let d = {
        data: nlqRawData.data
          .map((y, i) => {
            const matchingMetric = metrics.find((x) => x.key === y.name);
            const defaultColor = GRADIENT_CHART_COLORS[i % GRADIENT_CHART_COLORS.length];

            return {
              x: formatText(nlqRawData.dataColumns.y[i]),
              colorName: matchingMetric?.colorName ?? defaultColor.name,
              color: matchingMetric?.color ?? doDarkMode ? defaultColor.start : defaultColor.stop,
              fill: matchingMetric?.color ?? defaultColor.name,
              [nlqRawData.dataColumns.y[i]]: {
                metric: formatText(nlqRawData.dataColumns.y[i]),
                value: y.value[0],
              },
            };
          })
          .sort((a, b) => +b.value - +a.value),
        dataColumns: nlqRawData.dataColumns.y,
      };

      return d;
    } else {
      return convertDataToChart(
        nlqRawData.data,
        nlqRawData.dataColumns,
        metrics,
        nlqRawData.previousPeriodData,
        dimension,
      );
    }
  }, [
    isFunnelChart,
    nlqRawData.data,
    nlqRawData.dataColumns,
    nlqRawData.previousPeriodData,
    metrics,
    doDarkMode,
    dimension,
  ]);

  const { data, stackedData } = chartInfo || {};

  const updateMetrics = useCallback(
    async (metric: WillyMetric, propsToUpdate: Partial<Record<keyof WillyMetric, any>>) => {
      const newMetrics: WillyMetric[] = metrics.map((y) => {
        if (y.key === metric.key) {
          return {
            ...y,
            ...propsToUpdate,
          };
        }
        return y;
      });
      await metricsChanged(queryId, newMetrics);
    },
    [metricsChanged, metrics, queryId],
  );

  const mod = useCallback(
    (key: string) => {
      return mode(data.map((d) => +d[key]?.value));
    },
    [data],
  );

  const med = useCallback(
    (key: string) => {
      return median(data.map((d) => +d[key]?.value));
    },
    [data],
  );

  const mean = useCallback(
    (key: string) => {
      return lodashMean(data.map((d) => +d[key]?.value));
    },
    [data],
  );

  const extraXAxis = useMemo(() => {
    if (type !== 'funnel')
      return Object.values((data[0] as ChartDataItem) || {}).filter(
        (d) => typeof d?.value === 'string',
      );

    return [];
  }, [data, type]);

  const metricsWithoutDimensions = useMemo(() => {
    if (!metrics) {
      return [];
    }
    const filteredMetrics = metrics?.filter((x) => !x.isDimension);

    return filteredMetrics.map((x) => {
      return {
        ...x,
        active: x.active !== false,
        rounded: type === 'funnel' || (!incrementedStacked && !stacked),
      };
    });
  }, [incrementedStacked, metrics, stacked, type]);

  const activeMetricsWithoutDimensions = useMemo(() => {
    return metricsWithoutDimensions.filter((x) => x.active && !x.hidden && x.isBlocked !== true);
  }, [metricsWithoutDimensions]);

  const percentOfTotal = useCallback(
    (value: string) => {
      const maxVal = max(nlqRawData?.data?.map((d) => +d?.value)) ?? +value;
      return `${((+value / maxVal) * 100).toFixed(2).replace('.00', '')}%`;
    },
    [nlqRawData],
  );

  const formatFunnelLabel = useCallback(
    (value: string) => {
      return `${formatNumber(+value, {} as any)}\n\n${percentOfTotal(value)}`;
    },
    [percentOfTotal],
  );

  const chart = useMemo(() => {
    if (!data?.length && !loading) {
      return (
        <div className="flex flex-col w-full justify-center items-center m-auto">
          <p className="text-gray-600 dark:text-gray-300 text-xl">No data to display</p>
        </div>
      );
    }
    //set funnel as pie chart for now
    return type === 'pie' || type === 'funnel' ? (
      <WillyPieChart
        loading={loading}
        metrics={metrics}
        rawData={rawData}
        dataColumns={dataColumns}
        previousPeriodData={previousPeriodData}
      />
    ) : (
      <div className={`flex-auto grow ${windowSize.isSmall || isMobileApp ? 'h-full' : ''}`}>
        <ResponsiveContainer
          width={'100%'}
          height={windowSize.isSmall && context !== 'summary' ? '100%' : '99%'}
          minHeight={250}
        >
          <ComposedChart
            barGap={0}
            barCategoryGap={5}
            data={incrementedStacked ? stackedData : data}
            className="bg-transparent"
            margin={{ top: 20, right: 10, left: 10, bottom: extraXAxis?.length > 0 ? 30 : 10 }}
          >
            <defs>
              <GradientChartDefs metrics={metrics} />
            </defs>
            <XAxis
              dy={8}
              fontSize={12}
              fontWeight={500}
              className={doDarkMode ? 'text-gray-400' : 'text-gray-400'}
              stroke={doDarkMode ? '#9ca3af' : '#062940'}
              strokeWidth={0.5}
              padding={{ left: 35, right: 35 }}
              tickLine={false}
              dataKey={'x'}
              tickFormatter={(value: string) => {
                return value;
              }}
            />
            {extraXAxis?.map((x, i) => {
              return (
                <XAxis
                  key={`xAxis_${x.metric}_${i}`}
                  dy={8}
                  fontSize={12}
                  fontWeight={500}
                  className={doDarkMode ? 'text-gray-400' : 'text-gray-400'}
                  stroke={doDarkMode ? '#9ca3af' : '#062940'}
                  strokeWidth={0.5}
                  padding={{ left: 35, right: 35 }}
                  // tickMargin={50}
                  height={1}
                  scale="auto"
                  // tickCount={data.length}
                  interval={0}
                  // tickLine={false}
                  dataKey={(entity) => {
                    let v = entity[x.metric]?.value;
                    if (!v || isNaN(v) || v === null || v === undefined) v = 0;
                    return v;
                  }}
                  xAxisId={x.metric}
                  tick={(props) => {
                    const { x, y, payload } = props;

                    const isService = allServices[payload.value];
                    if (isService) {
                      return (
                        <foreignObject x={x - 10} y={y} width={20} height={30}>
                          {isService.icon ? (
                            isService.icon({ small: true })
                          ) : (
                            <Text>{payload.value?.charAt?.(0)}</Text>
                          )}
                        </foreignObject>
                      );
                    }
                    return payload.value?.charAt?.(0);
                  }}
                  tickFormatter={(value: string) => {
                    const isService = allServices[value];
                    if (isService) {
                      return '';
                    }
                    return value;
                  }}
                />
              );
            })}
            {orientations.map((orientation) => {
              let yn = metricsWithoutDimensions?.find((x) => x.yAxisId === orientation);
              if (!yn) {
                yn = metricsWithoutDimensions[0];
              }
              const metricsInOrientation = activeMetricsWithoutDimensions
                ?.filter((m) => (m.yAxisId ?? 'left') === orientation)
                .map((m) => m.name);
              return (
                <YAxis
                  key={`yAxis_${yn?.key}_${orientation}`}
                  yAxisId={orientation}
                  type="number"
                  label={(props) => {
                    const { viewBox } = props;
                    let text = '';
                    metricsInOrientation.forEach(
                      (m, i) => (text += i < metricsInOrientation.length - 1 ? `${m}, ` : m),
                    );
                    return <YAxisLabel viewBox={viewBox} text={text} orientation={orientation} />;
                  }}
                  domain={[
                    yAxisDomain.min ?? DEFAULT_AXIS_DOMAIN.min,
                    yAxisDomain.max ?? DEFAULT_AXIS_DOMAIN.max,
                  ]}
                  allowDataOverflow={allowDataOverflow}
                  orientation={orientation}
                  dx={0}
                  padding={{ top: 5, bottom: 7 }}
                  tickLine={false}
                  fontSize={12}
                  stroke={doDarkMode ? '#9ca3af' : '#062940'}
                  strokeWidth={0.5}
                  tickFormatter={(value: number) => {
                    if (loading || value < 0) return '';

                    const val = value || 0;
                    const toFixed = yn?.toFixed || 0;
                    const maxDigits = Math.round(val > 9999 ? 0 : toFixed);
                    const format = yn?.format || 'decimal';

                    return formatNumber(val, {
                      currency,
                      style: format,
                      maximumFractionDigits: format === 'currency' ? 0 : maxDigits,
                      minimumFractionDigits: Math.round(yn?.minimumFractionDigits || 0),
                      notation: 'compact',
                    });
                  }}
                />
              );
            })}
            {activeMetricsWithoutDimensions.map((yn, i) => (
              <Fragment key={`chartElement_${yn.key}_${i}`}>
                {(yn.chartType === 'bar' || typeof yn.chartType === 'undefined') && (
                  <Fragment>
                    <Bar
                      isAnimationActive
                      yAxisId={yn.yAxisId ?? 'left'}
                      dataKey={(entity) => {
                        let v = entity[yn.key]?.value;
                        if (!v || isNaN(v) || v === null || v === undefined) v = 0;
                        return loading ? Math.floor(Math.random() * 60) + 20 : v;
                      }}
                      fill={loading ? 'url(#loading)' : yn.color}
                      barSize={skinny ? (windowSize.isSmall ? 5 : 10) : undefined}
                      radius={yn.rounded ? barRadius : undefined}
                      id={yn.key}
                      name={yn.name}
                      stackId={stacked && yn.yAxisId === 'left' ? 'stacked' : undefined}
                    >
                      {/* {type === 'funnel' && (
                        <LabelList position="top" formatter={formatFunnelLabel} />
                      )} */}
                    </Bar>
                    {showPreviousPeriod && (
                      <Bar
                        isAnimationActive
                        yAxisId={yn.yAxisId ?? 'left'}
                        dataKey={(entity) => {
                          let v = entity[yn.key]?.previousValue;
                          if (!v || isNaN(v) || v === null || v === undefined) v = 0;
                          return loading ? Math.floor(Math.random() * 60) + 20 : v;
                        }}
                        fill={loadingPreviousPeriod ? `url(#loading)` : yn.color}
                        fillOpacity={0.3}
                        barSize={skinny ? (windowSize.isSmall ? 5 : 10) : undefined}
                        radius={yn.rounded ? barRadius : undefined}
                        id={yn.key}
                        name={yn.name}
                        stackId={stacked && yn.yAxisId === 'left' ? 'stacked' : undefined}
                      />
                    )}
                  </Fragment>
                )}
                {yn.chartType === 'area' && (
                  <Fragment>
                    <Area
                      dataKey={(entity) => {
                        let v = entity[yn.key]?.value;
                        if (!v || isNaN(v) || v === null || v === undefined) v = 0;
                        return loading ? Math.floor(Math.random() * 60) + 20 : v;
                      }}
                      id={yn.key}
                      yAxisId={yn.yAxisId ?? 'left'}
                      type="monotone"
                      dot={false}
                      stroke={loading ? 'url(#loading)' : yn.color}
                      strokeWidth={1}
                      activeDot={false}
                      name={yn.name}
                      fill={loading ? 'url(#loading)' : yn.color}
                      fillOpacity={0.5}
                      stackId={stacked && yn.yAxisId === 'left' ? 'area-stacked' : undefined}
                    />
                    {showPreviousPeriod && (
                      <Area
                        dataKey={(entity) => {
                          let v = entity[yn.key]?.previousValue;
                          if (!v || isNaN(v) || v === null || v === undefined) v = 0;
                          return loading ? Math.floor(Math.random() * 60) + 20 : v;
                        }}
                        yAxisId={yn.yAxisId ?? 'left'}
                        type="monotone"
                        dot={false}
                        stroke={loading ? 'url(#loading)' : yn.color}
                        strokeDasharray={windowSize.isSmall ? '3 3' : '5 5'}
                        strokeWidth={1}
                        activeDot={false}
                        name={yn.name}
                        id={yn.key}
                        fill={loading ? 'url(#loading)' : yn.color}
                        fillOpacity={0.1}
                      />
                    )}
                  </Fragment>
                )}
                {yn.chartType === 'scatter' && (
                  <Fragment>
                    <Scatter
                      dataKey={(entity) => {
                        let v = entity[yn.key]?.value;
                        if (!v || isNaN(v) || v === null || v === undefined) v = 0;
                        return loading ? Math.floor(Math.random() * 60) + 20 : v;
                      }}
                      yAxisId={yn.yAxisId ?? 'left'}
                      stroke={loading ? 'url(#loading)' : yn.color}
                      name={yn.name}
                      id={yn.key}
                      fill={loading ? 'url(#loading)' : yn.color}
                    />
                    {showPreviousPeriod && (
                      <Scatter
                        dataKey={(entity) => {
                          let v = entity[yn.key]?.previousValue;
                          if (!v || isNaN(v) || v === null || v === undefined) v = 0;
                          return loading ? Math.floor(Math.random() * 60) + 20 : v;
                        }}
                        yAxisId={yn.yAxisId ?? 'left'}
                        stroke={loading ? 'url(#loading)' : yn.color}
                        strokeDasharray={windowSize.isSmall ? '3 3' : '5 5'}
                        strokeWidth={1}
                        name={yn.name}
                        id={yn.key}
                        fill={loading ? 'url(#loading)' : yn.color}
                      />
                    )}
                  </Fragment>
                )}
              </Fragment>
            ))}
            {/* ensure line is always on top, only behind reference lines */}
            {activeMetricsWithoutDimensions?.map((yn, i) => {
              return (
                yn.chartType === 'line' && (
                  <Fragment key={`active-metric-without-dimension-${i}`}>
                    <Line
                      dataKey={(entity) => {
                        let v = entity[yn.key]?.value;
                        if (!v || isNaN(v) || v === null || v === undefined) v = 0;
                        return loading ? Math.floor(Math.random() * 60) + 20 : v;
                      }}
                      yAxisId={yn.yAxisId ?? 'left'}
                      type="monotone"
                      dot={false}
                      stroke={loading ? 'url(#loading)' : yn.color}
                      strokeWidth={windowSize.isSmall ? 1 : 2}
                      activeDot={{
                        r: 4,
                        filter: 'drop-shadow(1px 1px 3px rgba(0, 0, 0, 0.1))',
                      }}
                      name={yn.name}
                      id={yn.key}
                    />
                    {showPreviousPeriod && (
                      <Line
                        dataKey={(entity) => {
                          let v = entity[yn.key]?.previousValue;
                          if (!v || isNaN(v) || v === null || v === undefined) v = 0;
                          return loading ? Math.floor(Math.random() * 60) + 20 : v;
                        }}
                        yAxisId={yn.yAxisId ?? 'left'}
                        type="monotone"
                        dot={false}
                        stroke={loading ? 'url(#loading)' : yn.color}
                        strokeDasharray={windowSize.isSmall ? '3 3' : '5 5'}
                        strokeWidth={1}
                        activeDot={{ r: 4 }}
                        name={yn.name}
                        id={yn.key}
                      />
                    )}
                  </Fragment>
                )
              );
            })}
            {metricsWithoutDimensions
              ?.filter((yn) => yn.showMean)
              ?.map((yn, i) => {
                const formattedColor = formatColor(yn.color, doDarkMode);
                const formattedValue = formatValue(mean(yn.key), yn.key, metrics, currency);

                return (
                  <ReferenceLine
                    yAxisId={yn.yAxisId ?? 'left'}
                    label={{
                      value: `${yn.name} Mean: ${formattedValue}`,
                      position: 'insideBottomRight',
                      fill: formattedColor,
                      fillOpacity: yn.showMeanLabel ? 1 : 0,
                      fontWeight: 800,
                      fontSize: 12,
                      onMouseOver: () => {
                        updateMetrics(yn, { showMeanLabel: true });
                      },
                      onMouseOut: () => {
                        updateMetrics(yn, { showMeanLabel: false });
                      },
                    }}
                    key={'mean_' + yn.key}
                    y={mean(yn.key)}
                    strokeWidth={2}
                    stroke={formattedColor}
                    strokeDasharray="3 3"
                  />
                );
              })}
            {metricsWithoutDimensions
              ?.filter((yn) => yn.showMode)
              ?.map((yn, i) => {
                const formattedColor = formatColor(yn.color, doDarkMode);
                const formattedValue = formatValue(mod(yn.key), yn.key, metrics, currency);

                return (
                  <ReferenceLine
                    yAxisId={yn.yAxisId ?? 'left'}
                    label={{
                      value: `${yn.name} Mode: ${formattedValue}`,
                      position: 'insideBottomRight',
                      fill: formattedColor,
                      fillOpacity: yn.showModeLabel ? 1 : 0,
                      fontWeight: 800,
                      fontSize: 12,
                      onMouseOver: () => {
                        updateMetrics(yn, { showModeLabel: true });
                      },
                      onMouseOut: () => {
                        updateMetrics(yn, { showModeLabel: false });
                      },
                    }}
                    key={'mode_' + yn.key}
                    y={mod(yn.key)}
                    fill={formattedColor}
                    stroke={formattedColor}
                    strokeWidth={2}
                    strokeDasharray="2 8"
                  />
                );
              })}
            {metricsWithoutDimensions
              ?.filter((yn) => yn.showMedian)
              ?.map((yn, i) => {
                const formattedColor = formatColor(yn.color, doDarkMode);
                const formattedValue = formatValue(med(yn.key), yn.key, metrics, currency);

                return (
                  <ReferenceLine
                    yAxisId={yn.yAxisId ?? 'left'}
                    label={{
                      value: `${yn.name} Median: ${formattedValue}`,
                      position: 'insideBottomRight',
                      fill: formattedColor,
                      fillOpacity: yn.showMedianLabel ? 1 : 0,
                      fontSize: 12,
                      fontWeight: 800,
                      onMouseOver: () => {
                        updateMetrics(yn, { showMedianLabel: true });
                      },
                      onMouseOut: () => {
                        updateMetrics(yn, { showMedianLabel: false });
                      },
                    }}
                    key={'median_' + yn.key}
                    y={med(yn.key)}
                    stroke={formattedColor}
                    strokeWidth={2}
                    strokeDasharray="3 12"
                  />
                );
              })}
            <Tooltip
              cursor={{ fill: 'none' }}
              offset={20}
              wrapperStyle={{ outline: 'none' }}
              labelStyle={{ fontWeight: 'bold' }}
              contentStyle={{
                fontSize: '10px',
                background: doDarkMode ? 'var(--dark-bg)' : '#fff',
              }}
              content={
                <GradientChartTooltips
                  extraXAxis={extraXAxis}
                  metrics={metrics}
                  currency={currency}
                />
              }
            />
          </ComposedChart>
        </ResponsiveContainer>
      </div>
    );
  }, [
    data,
    loading,
    type,
    metrics,
    rawData,
    dataColumns,
    previousPeriodData,
    windowSize.isSmall,
    context,
    incrementedStacked,
    stackedData,
    extraXAxis,
    doDarkMode,
    activeMetricsWithoutDimensions,
    metricsWithoutDimensions,
    currency,
    skinny,
    //formatFunnelLabel,
    stacked,
    loadingPreviousPeriod,
    showPreviousPeriod,
    mean,
    updateMetrics,
    mod,
    med,
    yAxisDomain.max,
    yAxisDomain.min,
    allowDataOverflow,
  ]);

  if (errorInQuery[queryId] && !loading) {
    return (
      <div className="flex items-center justify-center h-full w-full text-red-800">
        {errorInQuery[queryId]}
      </div>
    );
  }

  if (type !== 'pie' && type !== 'funnel') {
    return (
      <div className="w-full h-full border-0 flex-auto flex flex-col">
        <div className="w-full flex-auto flex flex-col">{chart}</div>
        <div
          className={`flex-auto sm:min-h-[65px]`}
          style={{
            borderTop: '1px solid rgba(128, 128, 128, 0.35)',
          }}
        >
          <Legend
            metrics={metricsWithoutDimensions.filter((x) => x.active && x.isBlocked !== true)}
            updateMetrics={updateMetrics}
            metricsChanged={metricsChanged}
            queryId={queryId}
            setEditMetricModalOpen={setEditMetricModalOpen}
            context={context}
            dashboard={dashboard}
            allowDnd={allowLegendDnd}
            showQuickEdit={showQuickEdit}
          />
        </div>
      </div>
    );
  }

  return <>{chart}</>;
};

const GradientChartTooltips = (props) => {
  const dark = useDarkMode();
  const { active, payload, label, extraXAxis, metrics, currency } = props;

  if (!active || !payload || !payload.length) return null;

  // in case of previous period, we have 2 payloads of each metric
  const hasPreviousPeriod = payload?.[0]?.id === payload?.[1]?.id;

  let currentPeriodPayload = payload;
  let previousPeriodPayload = payload;
  let previousPeriodLabel = label;
  if (hasPreviousPeriod) {
    currentPeriodPayload = payload.filter((_, i) => i % 2 === 0);
    previousPeriodPayload = payload.filter((_, i) => i % 2 === 1);
    previousPeriodLabel = previousPeriodPayload[0]?.payload?.previousX;
  }
  return (
    <div className="p-6 border-solid border-[1px] border-gray-200 rounded text-[#374051] font-medium text-[14px] bg-[rgba(255,255,255,0.95)] shadow">
      {extraXAxis?.map((x) => {
        return (
          <div className="pb-6 font-bold dark:text-white" key={x.metric}>
            <div className="flex gap-1">
              <div>{x.metric?.name}:</div>
              <div>{payload[0]?.payload[x.metric]?.value}</div>
            </div>
          </div>
        );
      })}
      <div className="dark:text-white text-2xl font-bold">{label}</div>
      {currentPeriodPayload?.map((pld, i) => {
        return (
          <div className="pt-6" key={pld.id + '_' + i + '_current'}>
            <div className="flex items-center gap-1">
              <div
                className="w-[8px] h-[8px] rounded mr-2"
                style={{ backgroundColor: formatColor(pld.color, dark, metrics) }}
              ></div>
              <div>{pld.name}:</div>
              {formatValue(pld.value, pld.payload[pld.id]?.metric || pld.id, metrics, currency)}
            </div>
          </div>
        );
      })}
      {hasPreviousPeriod && (
        <div className="pt-6">
          <div className="dark:text-white">{previousPeriodLabel}</div>
          {previousPeriodPayload?.map((pld, i) => {
            return (
              <div className="pt-6" key={pld.id + '_' + i + '_previous'}>
                <div className="flex items-center gap-1">
                  <div
                    className="w-8 h-8 rounded-md mr-2"
                    style={{ backgroundColor: formatColor(pld.color, dark, metrics) }}
                  ></div>
                  <div>{pld.name}:</div>
                  {formatValue(pld.value, pld.payload[pld.id]?.metric || pld.id, metrics, currency)}
                </div>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

type LegendProps = {
  queryId: string;
  metrics: WillyMetric[];
  updateMetrics: (
    metric: WillyMetric,
    propsToUpdate: Partial<Record<keyof WillyMetric, any>>,
  ) => Promise<void>;
  metricsChanged: (id: string, v: WillyMetric[]) => Promise<void>;
  setEditMetricModalOpen?: (params: { open: boolean; queryId?: string; metricId?: string }) => void;
  context?: ChatSources;
  dashboard?: WillyDashboardElement;
  allowDnd?: boolean;
  showQuickEdit?: boolean;
};

const Legend: React.FC<LegendProps> = ({
  metrics,
  queryId,
  updateMetrics,
  metricsChanged,
  setEditMetricModalOpen,
  context,
  dashboard,
  allowDnd,
  showQuickEdit,
}) => {
  const { sensors, handleDragStart, handleDragOver, handleDragEnd, activeId } = useWillyDndMetrics({
    queryId,
    metrics,
    metricsChanged,
  });

  const mainElement = useMemo(
    () => (
      <div className="flex gap-12 justify-between">
        {orientations.map((orientation) => (
          <LegendGroup
            id={orientation}
            queryId={queryId}
            metrics={metrics.filter((x) => {
              if (orientation === 'right') {
                return x.yAxisId === 'right';
              }
              return x.yAxisId === 'left' || !x.yAxisId;
            })}
            updateMetrics={updateMetrics}
            key={`legend-group-${orientation}`}
            setEditMetricModalOpen={setEditMetricModalOpen}
            metricsChanged={async (queryId, orientationMetrics) => {
              const newMetrics = metrics.map((x) => {
                const metricInOrientation = orientationMetrics.find((y) => y.key === x.key);
                if (metricInOrientation) {
                  return metricInOrientation;
                }
                return x;
              });
              await metricsChanged(queryId, newMetrics);
            }}
            context={context}
            dashboard={dashboard}
            allowDnd={allowDnd}
          />
        ))}
      </div>
    ),
    [
      allowDnd,
      context,
      dashboard,
      metrics,
      metricsChanged,
      queryId,
      setEditMetricModalOpen,
      updateMetrics,
    ],
  );

  if (!metrics?.length) return null;
  return (
    <div>
      {!showQuickEdit ? (
        <Flex align="center" gap="md" overflow="auto" p="md">
          {metrics
            .filter((metric) => !metric.hidden)
            .map((metric) => (
              <Flex key={`${metric.key}-simple-legend`} align="center" gap="xs">
                <div
                  className="w-[10px] h-[10px] rounded"
                  style={{ backgroundColor: metric.color }}
                />
                <Text size="sm" weight={500} color="gray.7" truncate>
                  {metric.name}
                </Text>
              </Flex>
            ))}
        </Flex>
      ) : allowDnd ? (
        <DndContext
          id={`willy-legend-${queryId}`}
          onDragEnd={handleDragEnd}
          onDragOver={handleDragOver}
          sensors={sensors}
          collisionDetection={closestCenter}
          onDragStart={handleDragStart}
          autoScroll={{ canScroll: () => false }}
        >
          {mainElement}
          <Portal>
            <DragOverlay>
              {activeId ? (
                <div className="rounded border-2 border-logo inline-block w-full">
                  <LegendItem
                    metric={metrics.find((x) => x.key === activeId)!}
                    queryId={queryId}
                    updateMetrics={() => {}}
                    index={0}
                  />
                </div>
              ) : null}
            </DragOverlay>
          </Portal>
        </DndContext>
      ) : (
        mainElement
      )}
    </div>
  );
};

type LegendGroupProps = {
  id: string;
  queryId: string;
  metrics: WillyMetric[];
  setEditMetricModalOpen?: (params: { open: boolean; queryId?: string; metricId?: string }) => void;
  updateMetrics: (
    metric: WillyMetric,
    propsToUpdate: Partial<Record<keyof WillyMetric, any>>,
  ) => Promise<void>;
  metricsChanged: (id: string, v: WillyMetric[]) => Promise<void>;
  context?: ChatSources;
  dashboard?: WillyDashboardElement;
  allowDnd?: boolean;
};

const LegendGroup: React.FC<LegendGroupProps> = ({
  id,
  metrics,
  queryId,
  setEditMetricModalOpen,
  updateMetrics,
  metricsChanged,
  context,
  dashboard,
  allowDnd = true,
}) => {
  const { setNodeRef } = useDroppable({ id });
  const [settingsOpen, setSettingsOpen] = useState(false);

  const mainElement = () => (
    <>
      <span className="opacity-0 transition-opacity group-hover:opacity-100 dark:text-gray-300 text-lg font-bold capitalize sticky top-0 bg-white z-10">
        <Flex gap="xs" align="center">
          <Menu>
            <Menu.Target>
              <Flex cursor="pointer" align="center">
                <Icon name="settings" size={12} />
              </Flex>
            </Menu.Target>
            <Menu.Dropdown>
              <Flex direction="column">
                <Menu.Label>
                  <Text color="gray.5" size="xs" weight={500}>
                    DISPLAY TYPE
                  </Text>
                </Menu.Label>
                {Object.values(chartTypes).map((type) => {
                  return (
                    <Menu.Item key={type.id}>
                      <Flex
                        align="center"
                        gap="sm"
                        onClick={() => {
                          let chartType = type.id;
                          if (type.id === 'stacked-bar') {
                            chartType = 'bar';
                          }
                          metricsChanged(
                            queryId,
                            metrics.map((x) => ({ ...x, chartType })),
                          );
                          setSettingsOpen(false);
                        }}
                      >
                        <Icon name={type.icon} size={16} />
                        <Text size="xs" weight={500}>
                          {type.title}
                        </Text>
                      </Flex>
                    </Menu.Item>
                  );
                })}
              </Flex>
            </Menu.Dropdown>
          </Menu>
          <Text tt="uppercase" size="xs" weight={500} color="gray.6">
            {id} Y-Axis
          </Text>
        </Flex>
      </span>
      <div className="flex flex-wrap gap-3">
        {!metrics.length && (
          <p className="flex text-gray-600 dark:text-gray-300 text-lg">No metrics on {id} axis</p>
        )}
        {metrics?.map((yn, i) => (
          <LegendItem
            metric={yn}
            queryId={queryId}
            setEditMetricModalOpen={setEditMetricModalOpen}
            updateMetrics={updateMetrics}
            key={'legend_' + yn.key}
            index={i}
            context={context}
            dashboard={dashboard}
            allowDnd={allowDnd}
          />
        ))}
      </div>
    </>
  );

  return (
    <div
      className="flex flex-col justify-start p-4 pt-0 mt-4 gap-2 max-h-48 overflow-auto flex-auto basis-0"
      ref={setNodeRef}
    >
      {allowDnd ? (
        <SortableContext id={id} items={metrics?.map((x) => x.key)} strategy={rectSortingStrategy}>
          {mainElement()}
        </SortableContext>
      ) : (
        mainElement()
      )}
    </div>
  );
};

type LegendItemProps = {
  metric: WillyMetric;
  queryId: string;
  setEditMetricModalOpen?: (params: { open: boolean; queryId?: string; metricId?: string }) => void;
  updateMetrics: (
    metric: WillyMetric,
    propsToUpdate: Partial<Record<keyof WillyMetric, any>>,
  ) => void;
  index: number;
  context?: ChatSources;
  dashboard?: WillyDashboardElement;
  allowDnd?: boolean;
};

const LegendItem: React.FC<LegendItemProps> = ({
  metric,
  queryId,
  setEditMetricModalOpen,
  updateMetrics,
  index,
  context,
  dashboard,
  allowDnd = true,
}) => {
  const [active, setActive] = useState(false);

  const closePopover = useCallback(() => {
    setActive(false);
  }, []);

  const { attributes, listeners, setNodeRef, transform, transition, isDragging } = useSortable({
    id: metric.key,
  });
  const style: React.CSSProperties = useMemo(
    () => ({
      transform: CSS.Transform.toString(transform),
      transition,
      zIndex: isDragging ? '100' : 'auto',
      opacity: isDragging ? 0.3 : 1,
      touchAction: 'manipulation',
    }),
    [isDragging, transform, transition],
  );

  return (
    <div
      key={'legend_' + metric.key}
      className="flex items-center gap-2"
      ref={setNodeRef}
      style={style}
    >
      {allowDnd && (
        <div
          {...listeners}
          {...attributes}
          className="text-logo flex w-6 h-6 mt-[-2px] cursor-grab opacity-0 transition-opacity group-hover:opacity-100"
        >
          <DragHandle />
        </div>
      )}
      <ColoredCheckbox
        color={metric.color!}
        label
        labelHidden
        checked={!metric.hidden}
        onChange={(checked) => {
          updateMetrics(metric, { hidden: !checked });
          genericEventLogger(getDataLayerEventByContext(context), {
            action: getCurrentAnalyticsActionSet(context).TOGGLE_LEGEND,
            key: metric.key,
            metric: metric.name,
            query_id: queryId,
            checked,
            dashboard_id: dashboard?.id,
            dashboard_name: dashboard?.name,
            template_id: dashboard?.globalDashboardId,
            template_name: dashboard?.globalDashboardId && dashboard?.name,
          });
        }}
      />
      <Menu
        opened={active}
        onClose={closePopover}
        closeOnItemClick={false}
        closeOnClickOutside={false}
        closeOnEscape
      >
        <Menu.Target>
          <span
            className="flex items-center font-medium text-gray-600 fill-gray-500 dark:text-gray-300 dark:fill-gray-300 cursor-pointer whitespace-nowrap text-lg"
            onClick={() => {
              setActive((i) => !i);
            }}
          >
            {metric.name}
            <CaretDownMinor
              className="opacity-0 transition-opacity group-hover:opacity-100"
              width={20}
            />
          </span>
        </Menu.Target>

        <Menu.Dropdown>
          <Menu.Label>
            <Flex align="center" justify="flex-start" gap="sm">
              <ColorPickerPopover
                activatorClassName="w-6 h-6 !rounded-[5px]"
                color={metric.color}
                onChange={(color) => {
                  updateMetrics(metric, { color, colorName: uuidV4() });
                }}
                onReset={() => {
                  const currentDef = GRADIENT_CHART_COLORS[index % GRADIENT_CHART_COLORS.length];
                  updateMetrics(metric, {
                    color: currentDef.start,
                    colorName: currentDef.name,
                  });
                }}
              />
              <span
                contentEditable
                suppressContentEditableWarning
                onBlur={(e) => {
                  updateMetrics(metric, { name: e.currentTarget.innerText });
                }}
                className="font-medium text-[--gray-light-mode-800]"
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    e.preventDefault();
                    e.currentTarget.blur();
                  }
                }}
              >
                {metric.name}
              </span>
              {!!setEditMetricModalOpen && (
                <ActionIcon
                  onClick={() => {
                    setEditMetricModalOpen({ open: true, metricId: metric.key, queryId });
                    closePopover();
                  }}
                  size="xs"
                  iconSize={10}
                  icon="edit"
                  color="one.3"
                  variant="transparent"
                />
              )}
            </Flex>
          </Menu.Label>
          <Menu.Divider />
          <Menu.Label>
            <Text color="gray.5" size="xs" weight={500}>
              DISPLAY TYPE
            </Text>
          </Menu.Label>
          {Object.values(chartTypes).map((type) => {
            return (
              <Menu.Item key={type.id} bg={metric.chartType === type.id ? 'gray.2' : undefined}>
                <Flex
                  align="center"
                  gap="sm"
                  onClick={() => {
                    let chartType = type.id;
                    if (type.id === 'stacked-bar') {
                      chartType = 'bar';
                    }
                    updateMetrics(metric, { chartType: chartType });
                    closePopover();
                  }}
                >
                  <Icon name={type.icon} size={16} />
                  <Text size="xs" weight={500}>
                    {type.title}
                  </Text>
                </Flex>
              </Menu.Item>
            );
          })}
          <Menu.Divider />
          <Menu.Label>
            <Text color="gray.5" size="xs" weight={500}>
              PLOT LINE
            </Text>
          </Menu.Label>
          <Menu.Item>
            <Checkbox
              size="xs"
              label="Mean"
              fw={500}
              checked={metric.showMean}
              onChange={() => {
                updateMetrics(metric, { showMean: !metric.showMean });
                closePopover();
              }}
            />
          </Menu.Item>
          <Menu.Item>
            <Checkbox
              size="xs"
              label="Mode"
              checked={metric.showMode}
              onChange={() => {
                updateMetrics(metric, { showMode: !metric.showMode });
                closePopover();
              }}
            />
          </Menu.Item>
          <Menu.Item>
            <Checkbox
              size="xs"
              label="Median"
              checked={metric.showMedian}
              onChange={() => {
                updateMetrics(metric, { showMedian: !metric.showMedian });
                closePopover();
              }}
            />
          </Menu.Item>
        </Menu.Dropdown>
      </Menu>
    </div>
  );
};

type YAxisLabelProps = {
  viewBox: { x: number; y: number; width: number; height: number };
  orientation: 'left' | 'right';
  text: string;
};

const YAxisLabel: FC<YAxisLabelProps> = ({ viewBox, orientation, text }) => {
  return (
    <foreignObject
      x={viewBox.x}
      y={viewBox.y}
      width={viewBox.width}
      height={viewBox.height}
      className="relative"
    >
      <div
        style={{
          justifyContent: orientation === 'left' ? 'flex-start' : 'flex-end',
        }}
        className="flex items-center w-full h-full"
      >
        <Popover shadow="md">
          <Popover.Target>
            <div
              style={{
                maxHeight: '60%',
                writingMode: 'vertical-rl',
                textAlign: 'center',
                transform: `${orientation === 'left' ? 'rotate(180deg)' : ''}`,
                cursor: 'pointer',
              }}
            >
              <Text size="xs" weight={500} color="gray.6" truncate>
                {text}
              </Text>
            </div>
          </Popover.Target>
          <Popover.Dropdown p="xs" maw="200px" border="1px solid #D1D4DB">
            <Text size="xs" weight={500} color="gray.7">
              {text}
            </Text>
          </Popover.Dropdown>
        </Popover>
      </div>
    </foreignObject>
  );
};
