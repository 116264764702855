import { useCallback, useMemo } from 'react';
import { useHistory, useLocation } from 'react-router';

export function useMutableQueryParams() {
  const { search } = useLocation();
  const history = useHistory();
  const params = useMemo(() => new URLSearchParams(search), [search]);
  const queryObj = useMemo<Partial<Record<string, string>>>(
    () => Object.fromEntries(params),
    [params],
  );

  const set = useCallback(
    (key: string, val: unknown) => {
      if (!key) return;
      params.set(key, String(val));
      history.replace({ search: params.toString() });
    },
    [history, params],
  );

  const replace = useCallback(
    (obj: Record<string, unknown>) => {
      for (const key in obj) {
        if (key) params.set(key, String(obj[key]));
      }
      history.replace({ search: params.toString() });
    },
    [history, params],
  );

  const remove = useCallback(
    (...keys: string[]) => {
      if (!keys) return;
      keys.forEach((key) => params.delete(key));
      history.replace({ search: params.toString() });
    },
    [history, params],
  );

  return [queryObj, { set, replace, remove, search }] as const;
}
