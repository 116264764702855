import '../initializers';

import { useEffect } from 'react';
import { useAppDispatch } from 'index';
import { useSelector } from 'react-redux';
import { useRouteMatch } from 'react-router';
import { useLocation } from 'react-router-dom';
import { ConfirmModal } from '@tw/ui-components';
import type { RootState } from 'reducers/RootType';
import { $activeAppVersion, useActiveRoute } from '$stores/nav-config-stores';
import { summarySettingsCloseButtonOnPress } from 'ducks/summary';
import { useIsFromSignupInvitation } from '../Router/hooks';
import lazyWithRetries from 'utils/lazyWithRetries';

// import { ForecastingGoalModal } from 'pages/Forecasting/goals/ForecastingGoalModalNew';
// import { SpecialDailyGoalModal } from 'pages/Forecasting/pacing/SpecialDailyGoalModal';

import { IntegrationConnectedErrorMsg } from 'components/IntegrationConnectionErrorMsg';
import { StatusChangeConfirmationModal } from 'components/attribution-new/StatusChangeConfirmationModal/components';
import { AddCustomMetricModal } from 'components/CustomMetricsModal/AddCustomMetricModal';
import { FloppyWhaleModal } from 'components/FloppyWhaleModal';
import { CreateEditAdSegmentModal } from 'components/Segmentation/CreateEditAdSegmentationModal';
import { AddCustomTileSectionModal } from '../components/AddCustomTileSectionModal';
import { TwitterAccountsConfig } from '../components/TwitterAccountsConfig';
import { TikTokSettings } from 'components/TikTokSettings';
import { TilesSectionSettingsModal } from '../components/TilesSectionSettingsModal';
import { ShopifySalesBySegmentModal } from '../components/ShopifySalesBySegmentModal/shopifySalesBySegmentModal';
import { WELCOME_WIZARD_BASE_PATH } from 'components/WizardWalkthrough/constants';
import { UpgradePlanModal } from 'components/UpgradePlan/components/UpgradePlanModal';
import { NewActivityModal } from 'components/ActivityFeed/NewActivityModal';
import { SensoryAuthConfig } from '../components/SensoryAuthConfig';
import { EditWillySummaryTitle } from '../components/Willy/EditWillySummaryTitle';

import EnquirelabsConfig from 'components/EnquirelabsConfig';
import GoogleAdsAccountsConfig from 'components/Gads/GAdsAccountsConfig';
import ImportingServicesComponent from 'components/ImportingServicesIndicator';
import ConfirmationModal from '../components/ConfirmationModal/ConfirmationModal';
import FacebookAdsAccountsConfig from '../components/FacebookAdsAccountsConfig';
import GoogleSheetsSettings from 'components/GoogleSheetsSettings';
import GorgiasConfig from '../components/GorgiasConfig';
import KlaviyoConfig from '../components/KlaviyoConfig';
import KnoConfig from '../components/KnoConfig';
import MetricPopup from '../components/MetricPopup';
import PinterestAccountsConfig from '../components/PinterestAccountsConfig';
import Profitwell from '../components/Profitwell';
import Prompt from '../components/Prompt';
import SnapchatOrganizationsConfig from '../components/SnapchatOrganizationsConfig';
import SummarySettings from '../components/SummarySettings';
import TiktokAccountsConfig from '../components/TiktokAccountsConfig';
import AmazonConfig from 'components/AmazonConfig';
import BingConfig from 'components/BingConfig';
import SelectRolePopUp from 'components/GeneralPopups/SelectRolePopup';
import ReconnectToShopify from 'components/ReconnectToShopify';
import LighthousePopup from 'components/Lighthouse/LighthousePopup';
import ActionBanner from 'components/ActionBanner';
import MediaPicker from 'components/ContentHub/MediaPicker';
import UnpaidModal from 'components/GeneralPopups/UnpaidSubscriptionModal/UnpaidModal';
import { WillyDashSettingsModal } from 'components/Willy/dashboardManagment/WillyDashSettingsModal';
import { ManageConnectionModal } from 'routes/integrations/components/ManageConnectionModal/ManageConnectionModal';
import { AuthRedirectNotice } from 'components/AuthRedirectNotice';
import { CustomizeNavigationModal } from 'components/Nav/components/navs/AlanNav/CustomizeNavigationModal';
import { MobileWillyDashItemDrawer } from 'components/Willy/dashboardManagment/MobileWillyDashItemDrawer';
import { MobileDrawerDashReportSelector } from 'components/Willy/dashboardManagment/WillyDashSidePanel';
import { MobileDrawerSequenceSelector } from 'components/Willy/WillySequences';
import { UnlockMobyPopup } from 'components/UnlockMoby/UnlockMobyPopup';
import { UpgradePlanModal as WillyUpgradePlan } from 'components/Willy/UpgradePlan/UpgradePlanModal';
import { $showFreeTrialPopup, openFreeTrialPopup } from '$stores/willy/$upgradePopupManager';
import { useStoreValue } from '@tw/snipestate';
import { BookDemoModal } from 'components/Willy/UpgradePlan/BookDemoModal';

const PopupsAndConfigs = () => {
  const location = useLocation();
  const dispatch = useAppDispatch();
  const isSummarySettingsOpen = useSelector((state: RootState) => state.isSummarySettingsOpen);
  const showSummaryImportingNow = useSelector((state: RootState) => state.showSummaryImportingNow);
  const email = useSelector((state: RootState) => state.userEmail);
  const currentShopId = useSelector((state: RootState) => state.currentShopId);
  const { isModalOpen } = useSelector((state: RootState) => state.upgradePlanModalOpen);
  const isSignupInvitation = useIsFromSignupInvitation();
  const isWizard = window.location.pathname.includes(WELCOME_WIZARD_BASE_PATH);
  const isSignup = location.pathname.includes('signup') || location.pathname.includes('signin');

  const isMatchToWillyDashboard = useRouteMatch('/dashboards/:dashboardId');
  const { activeRoute } = useActiveRoute();
  const isWillyPage = !!(activeRoute?.isWillyPage || isMatchToWillyDashboard?.isExact);

  const showFreeTrialPopup = useStoreValue($showFreeTrialPopup);

  useEffect(() => {
    if (showFreeTrialPopup) {
      const timeoutId = setTimeout(() => {
        openFreeTrialPopup('initial');
      }, 3000); // 3000 milliseconds = 3 seconds

      // Cleanup function to clear the timeout if the component unmounts before the timeout completes
      return () => clearTimeout(timeoutId);
    }
  });

  useEffect(() => {
    if (sessionStorage.getItem(currentShopId) === 'true') return;
    sessionStorage.setItem(currentShopId, 'false');
  }, [currentShopId]);

  return (
    <>
      {!isWillyPage && (
        <>
          <MediaPicker />
          {showSummaryImportingNow && <ImportingServicesComponent inModal />}
          <LighthousePopup />
          <ActionBanner />
        </>
      )}
      <ShopifySalesBySegmentModal />
      <SummarySettings
        type="summary"
        open={isSummarySettingsOpen}
        onClose={() => dispatch(summarySettingsCloseButtonOnPress())}
      />
      <NewActivityModal />
      <EditWillySummaryTitle />
      <AddCustomTileSectionModal />
      <AddCustomMetricModal />
      <CreateEditAdSegmentModal />
      <FloppyWhaleModal />
      <StatusChangeConfirmationModal />
      <MetricPopup />
      <FacebookAdsAccountsConfig />
      <GoogleAdsAccountsConfig />
      <TiktokAccountsConfig />
      <TwitterAccountsConfig />
      <GorgiasConfig isLoading={undefined} />
      <KlaviyoConfig isLoading={undefined} />
      <SnapchatOrganizationsConfig isLoading={undefined} />
      <PinterestAccountsConfig />
      {/* <SensoryAccountConfig /> */}
      <ManageConnectionModal />
      <SensoryAuthConfig />
      <AuthRedirectNotice />
      <KnoConfig isLoading={undefined} />
      <AmazonConfig />
      <BingConfig />
      <Prompt />
      <TilesSectionSettingsModal />
      <EnquirelabsConfig />
      <ConfirmationModal />
      <Profitwell
        authToken="f67d0756deb7a2cb82010f763d2a0024"
        email={email}
        shouldNotLoad={false}
      />
      <UnpaidModal />
      <UnlockMobyPopup />
      <ReconnectToShopify />
      {!isSignup && !isSignupInvitation && !isWizard && <SelectRolePopUp />}
      <TikTokSettings done={() => {}} />
      <GoogleSheetsSettings done={() => {}} />
      <IntegrationConnectedErrorMsg />
      <WillyDashSettingsModal />
      {isModalOpen && <UpgradePlanModal />}
      <WillyUpgradePlan />
      <BookDemoModal />
      <CustomizeNavigationModal />
      <MobileWillyDashItemDrawer />
      <MobileDrawerDashReportSelector />
      <MobileDrawerSequenceSelector />
      {/* This should be the last component in the list!!! */}
      <ConfirmModal />
    </>
  );
};

export default PopupsAndConfigs;
