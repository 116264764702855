import { TextStyle } from '@shopify/polaris';
import { ServicesIds } from '@tw/types/module/services';
import allServices from 'constants/services';
import { shopIntegrations } from 'ducks/shopIntegrations';
import QueryString from 'qs';
import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import axiosInstance from 'utils/axiosInstance';

export default function PixelQueryString({ query }) {
  const integrations = useSelector(shopIntegrations);
  const [source, setSource] = useState('');
  const [campaign, setCampaign] = useState('');
  let [adset, setAdset] = useState('');
  let [adName, setAdName] = useState('');

  const setQueryParams = useCallback(async () => {
    if (!query) {
      return;
    }
    const combinedSource = query.tw_source || query.triplesource || query.utm_source;
    const combinedAdId = query.tw_adid || query.trc_mad_id || query.utm_content;
    const combinedCampaign = query.tw_campaign || query.utm_campaign;

    const hasNewQuery = !!combinedSource && !!combinedAdId && !isNaN(combinedAdId); // ad ids are always numbers
    if (hasNewQuery) {
      const channel = Object.values(allServices).find(
        (s) =>
          s.id === combinedSource ||
          s.sourceVariations?.includes((combinedSource as string)?.toLowerCase?.()),
      );
      const channelId: ServicesIds = channel?.id || combinedSource;
      const channelAccounts = integrations[channelId] || [];
      const q = {
        source: channelId,
        accountIds: channelAccounts.map((acc) => acc.id),
        id: combinedAdId,
      };
      const { data } = await axiosInstance.get(
        '/v2/metrics-table/get-names-by-source-and-ad-id' +
          QueryString.stringify(q, { addQueryPrefix: true }),
      );

      const { ad, adset, campaign } = data || {};

      // if ad is not found, we fallback to tracking parameters from the URL
      setSource(ad ? channel?.name || channelId || '' : combinedSource);
      setCampaign(ad ? campaign || '' : combinedCampaign);
      setAdset(ad ? adset || '' : query.utm_term);
      setAdName(ad || combinedAdId || '');
    } else {
      setSource(combinedSource);
      setCampaign(combinedCampaign);
      setAdset(query.utm_term);
      setAdName(combinedAdId);
    }
  }, [query, integrations]);

  useEffect(() => {
    setQueryParams();
  }, [setQueryParams]);

  const queryList: any[] = [];
  if (source) {
    queryList.push({
      title: 'Source',
      content: source,
    });
  }
  if (campaign) {
    queryList.push({
      title: 'Campaign',
      content: campaign,
    });
  }
  if (adset) {
    queryList.push({
      title: 'Ad Set',
      content: adset,
    });
  }
  if (adName) {
    queryList.push({
      title: 'Ad Name',
      content: adName,
    });
  }

  if (!query) {
    return <></>;
  }

  return (
    <>
      {queryList.map((item, i) => (
        <div className="pixel-campaign-list" key={i}>
          <TextStyle variation="subdued">{item.title}: </TextStyle>
          <TextStyle variation="strong">{item.content.replaceAll('+', ' ')}</TextStyle>
        </div>
      ))}
    </>
  );
}
