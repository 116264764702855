import { useAppSelector } from 'reducers/RootType';
import { Message, WillyMetric, WillyPrompt, WillyToolName } from './types/willyTypes';
import React, { Fragment, useEffect, useMemo, useRef, useState } from 'react';
import axiosInstance from 'utils/axiosInstance';
import { ProgressBar, Tooltip } from '@shopify/polaris';
import { SecureMajor, InfoMinor } from '@shopify/polaris-icons';
import { CodeAction, WillySimpleText } from './WillySimpleText';
import { Button, Icon, Badge, ActionIcon, Skeleton } from '@tw/ui-components';
import { WillyActionMain } from './WillyAction/types/main/WillyActionMain';
import { WillyEditMetric } from './WillyEditMetric';
import { WillyUserAvatar } from './WillyUserAvatar';
import { ReactComponent as AlanIcon } from 'components/Icons/alan-icon.svg';
import _db from 'utils/DB';
import { User } from 'components/UserProfileManagment/User/constants';
import { WillyMessageCodeExecutionResult } from './WillyMessageCodeExecutionResult';
import { ReactComponent as ButtonPlay } from 'components/Icons/Button-Play.svg';
import { WillyMessageWidget } from './WillyMessageWidget';
import { $seqSettingsModal } from '../../$stores/willy/$seqSettingsModal';
import { FAVORITE_PROMPTS_COLLECTION, willyToolMap } from './constants';
import { startCase } from 'lodash';
import { WillyMessageProgress } from './WillyMessageProgress';
import {
  convertCodeResponseToMessageCodeInterpreterResponse,
  convertDeprecatedToolName,
  convertNlqResponseToMessageData,
  removeFavoritePrompt,
} from './utils/willyUtils';
import { WillyToolProgress } from './WillyToolProgress';
import { MessageToolDetailsTabs, MessageToolTabs, PossibleTab } from './MessageToolDetailsTabs';
import { useStoreValue } from '@tw/snipestate';
import { $favoritePrompts } from '$stores/willy/$favoritePrompts';
import { $hasSequenceAccess } from '$stores/willy/$sequences';
import { $userId } from '$stores/$user';

type MessageTemplateProps = {
  message: Message;
  conversationMessages: Message[];
  userName: string;
  conversationUser: string;
  loading?: boolean;
  codeActions?: CodeAction[];
  isLast?: boolean;
  lastMessageFromUser?: Message;
  canEdit?: boolean;
  isSequenceMode?: boolean;
  firstOfAssistant?: boolean;
  lastOfAssistant?: boolean;
  handleSubmit?: (text: string, skipUserMessage?: boolean, userMessageText?: string) => void;
  chatSourceIds?: { dashboardId: string; widgetId: string; onAdd?: () => void };
};

export const WillyMessageTemplate: React.FC<MessageTemplateProps> = ({
  message,
  conversationMessages,
  userName,
  conversationUser,
  loading,
  codeActions = [],
  isLast,
  lastMessageFromUser,
  canEdit = false,
  isSequenceMode,
  handleSubmit,
  chatSourceIds,
  firstOfAssistant,
  lastOfAssistant,
}) => {
  const inputRef = useRef<HTMLDivElement>(null);
  const currentShopId = useAppSelector((state) => state.currentShopId);
  const favoritePrompts = useStoreValue($favoritePrompts);
  const userId = useStoreValue($userId);
  const hasSequenceAccess = useStoreValue($hasSequenceAccess);
  const [willyMetrics, setWillyMetrics] = useState<WillyMetric[]>([]);
  const [editMessage, setEditMessage] = useState(false);
  const [cachedEditedMessage, setCachedEditedMessage] = useState<string | undefined>(undefined);
  const [messageUserName, setMessageUserName] = useState('');
  const [messageAvatarSource, setMessageAvatarSource] = useState('');
  const [loadingUserName, setLoadingUserName] = useState(false);
  const [loadingAvatarSource, setLoadingAvatarSource] = useState(false);
  const [selectedTab, setSelectedTab] = useState<PossibleTab | null>(null);
  const [editMetricModalOpen, setEditMetricModalOpen] = useState<{
    open: boolean;
    queryId?: string;
    metricId?: string;
  }>({ open: false });

  const readyForToolbar = useMemo(() => {
    if (!isLast) {
      return true;
    }

    if (message?.loading) {
      return false;
    }

    return !loading;
  }, [isLast, loading, message?.loading]);

  const isFavoritePrompt = useMemo(() => {
    if (!message.text || message.role !== 'user') {
      return false;
    }
    return favoritePrompts.some((x) => x.prompt === message.text);
  }, [favoritePrompts, message.text, message.role]);

  const shouldShowTabs = useMemo(() => {
    if (!message.toolResults?.name) {
      return false;
    }
    const arr: WillyToolName[] = ['TextToSQL', 'SQLExecutor', 'SQLModifier'];
    return arr.includes(convertDeprecatedToolName(message.toolResults.name));
  }, [message.toolResults?.name]);

  const messageData = useMemo(() => {
    if (!message?.toolResults) {
      return null;
    }

    message.toolResults.name = convertDeprecatedToolName(message.toolResults.name);

    if (
      message.toolResults.name !== 'TextToSQL' &&
      message.toolResults.name !== 'SQLExecutor' &&
      message.toolResults.name !== 'SQLModifier'
    ) {
      return null;
    }
    if (message.toolResults.name === 'SQLExecutor') {
      return convertNlqResponseToMessageData(message.toolResults as any);
    }
    if (!message.toolResults.nlqResponse) {
      return null;
    }
    return convertNlqResponseToMessageData(message.toolResults.nlqResponse);
  }, [message.toolResults]);

  const forecastData = useMemo(() => {
    if (!message?.toolResults) {
      return null;
    }

    if (message.toolResults.name !== 'Forecasting') {
      return null;
    }

    if (!message.toolResults.message) {
      return null;
    }

    return convertNlqResponseToMessageData(message.toolResults.message);
  }, [message.toolResults]);

  const messageCode = useMemo(() => {
    if (!message?.toolResults || !Object.values(message.toolResults).length) {
      return null;
    }
    if (message.toolResults.name !== 'TextToPython') {
      return null;
    }
    return convertCodeResponseToMessageCodeInterpreterResponse(message.toolResults);
  }, [message.toolResults]);

  useEffect(() => {
    (async () => {
      if (!message.userId) {
        setMessageUserName(userName);
        return;
      }
      if (conversationUser === message.userId) {
        setMessageUserName(userName);
        return;
      }

      try {
        setLoadingUserName(true);
        const { data } = await axiosInstance.get<User>(
          `/v2/willy/get-user-name?shopId=${currentShopId}&userId=${message.userId}`,
        );
        const { firstName, lastName, email } = data;
        setMessageUserName(firstName || lastName || email || message.userId);
        setLoadingUserName(false);
      } catch (e) {
        console.error('Could not fetch user name', e);
      }
    })();
  }, [conversationUser, currentShopId, message.userId, userName]);

  return (
    <Fragment>
      <div className="group @container w-full text-gray-800 dark:text-gray-100 border-b border-black/10 dark:border-gray-900/50">
        <div className="gap-6 p-4 flex flex-col @3xl:flex-row m-auto @3xl:!px-90">
          {(message.role === 'user' || firstOfAssistant) && (
            <div className="relative flex @3xl:items-start">
              {message.role === 'user' && (
                <div className="flex gap-2 items-center">
                  <div className="w-10 h-10 rounded-sm overflow-hidden flex items-center">
                    <WillyUserAvatar
                      avatarSource={messageAvatarSource}
                      userId={message.userId}
                      userName={messageUserName}
                      loading={loadingAvatarSource}
                    />
                  </div>
                  <p className="flex @3xl:hidden font-semibold leading-none">
                    {loadingUserName && <Skeleton width="100px" />}
                    {!loadingUserName && messageUserName}
                  </p>
                </div>
              )}
              {message.role !== 'user' && (
                <div className="flex gap-2 items-center">
                  <div className="w-10 h-10 rounded-sm flex items-center">
                    {firstOfAssistant && (
                      <AlanIcon className="w-full h-auto fill-[white] text-[#0C70F2]" />
                    )}
                  </div>
                  {firstOfAssistant && (
                    <p className="flex @3xl:hidden font-semibold leading-none">Triple Whale</p>
                  )}
                </div>
              )}
            </div>
          )}
          <div className="relative flex @3xl:flex-col flex-auto max-w-full">
            {/* <p>{message.id}</p> */}
            {(firstOfAssistant || message.role === 'user') && (
              <div className="py-2 mb-4">
                {message.role === 'user' && (
                  <p className="@3xl:flex hidden font-semibold leading-none">{messageUserName}</p>
                )}
                {message.role !== 'user' && (
                  <p className="@3xl:flex hidden font-semibold leading-none">Triple Whale</p>
                )}
              </div>
            )}
            <div className="min-h-[20px] flex flex-col items-start max-w-full flex-auto">
              <div className="group/message flex items-start gap-2 w-full">
                <div className="flex-auto flex flex-col gap-4 w-full h-full">
                  {/* If message has progress value, show it here */}
                  {!!message.loading && <div className="relative blinking-cursor"></div>}
                  {typeof message.progress !== 'undefined' && (
                    <div className="flex flex-col gap-2">
                      <div className="max-w-[180px]">
                        <ProgressBar progress={message.progress * 100} size="small" />
                        <p className="font-bold text-xl whitespace-nowrap">
                          {message.progressText || 'Still working...'}
                        </p>
                      </div>
                    </div>
                  )}
                  {!!message.toolProgress && (
                    <WillyToolProgress
                      progress={message.toolProgress}
                      question={message.question}
                    />
                  )}

                  {/* If message has data, show widget here */}
                  {!!message.data && (
                    <div className="flex flex-col gap-6.5">
                      {message.data?.map((data) => {
                        return (
                          <div key={data.queryId || data.processId}>
                            <WillyMessageProgress message={data} />
                            {!!data.data && (
                              <div className="chatWidget">
                                <WillyMessageWidget
                                  message={message}
                                  data={data}
                                  chatSourceIds={chatSourceIds}
                                />
                              </div>
                            )}
                          </div>
                        );
                      })}
                    </div>
                  )}

                  {/* If message has codeInterpreterResponse, show code component here */}
                  {!!message.codeInterpreterResponse && (
                    <div>
                      {message.codeInterpreterResponse?.map((codeResult, index) => {
                        return (
                          <div key={codeResult.processId}>
                            <WillyMessageCodeExecutionResult key={index} codeResult={codeResult} />
                            {codeResult.queries && (
                              <WillyMessageWidget
                                codeResult={codeResult}
                                message={message}
                                chatSourceIds={chatSourceIds}
                              />
                            )}
                          </div>
                        );
                      })}
                    </div>
                  )}

                  {/* If message has action, show action template here */}
                  {!!message.actions && message.actions?.length > 0 && (
                    <div className="flex flex-col gap-4 justify-center">
                      {message.actions.map((action, index) => {
                        return <WillyActionMain key={index} message={message} />;
                      })}
                    </div>
                  )}

                  {/* message toolResults */}
                  {!!messageData && (
                    <div className="chatWidget">
                      <WillyMessageWidget
                        message={message}
                        data={messageData}
                        chatSourceIds={chatSourceIds}
                      />
                    </div>
                  )}

                  {!!messageCode && (
                    <WillyMessageWidget
                      codeResult={messageCode}
                      message={message}
                      chatSourceIds={chatSourceIds}
                    />
                  )}

                  {!!forecastData && (
                    <WillyMessageWidget
                      message={message}
                      data={forecastData}
                      chatSourceIds={chatSourceIds}
                    />
                  )}

                  {/* every message can have text, show it here */}
                  {!!message.text && (
                    <div className="flex-auto h-full w-full relative flex gap-4">
                      <div
                        className="w-full h-full flex-auto flex flex-col outline-none overflow-auto"
                        ref={inputRef}
                        tabIndex={1}
                        suppressContentEditableWarning={true}
                        contentEditable={message.role === 'user' && editMessage}
                        onBlur={(e) => {
                          const text = e.currentTarget?.innerText?.trim() ?? '';
                          if (text && text.length > 0) {
                            setCachedEditedMessage(text.trim());
                          }
                        }}
                      >
                        <WillySimpleText
                          text={message.text || ''}
                          error={!!message.error}
                          codeActions={codeActions}
                        />
                      </div>
                    </div>
                  )}

                  {/* message toolbar: regenerate response from this message show/hide query (if exists) */}
                  {readyForToolbar && (
                    <>
                      <div
                        className={`flex items-center flex-wrap gap-2 ${
                          isLast || editMessage
                            ? ''
                            : 'opacity-100 @3xl:opacity-0 group-hover:opacity-100 transition-opacity'
                        }`}
                      >
                        {message.role === 'user' && !editMessage && canEdit && (
                          <div className="flex items-center gap-4">
                            <Tooltip content="Edit">
                              <div
                                onClick={() => {
                                  inputRef.current?.focus();
                                  setEditMessage(true);
                                }}
                              >
                                <ActionIcon icon="new-edit" size="sm" />
                              </div>
                            </Tooltip>
                            <Tooltip
                              content={
                                isFavoritePrompt ? 'Remove from My Prompts' : 'Add to My Prompts'
                              }
                            >
                              <div
                                onClick={async () => {
                                  if (!message.text) {
                                    return;
                                  }
                                  if (isFavoritePrompt) {
                                    const prompt = favoritePrompts.find(
                                      (x) => x.prompt === message.text,
                                    );
                                    if (
                                      !!prompt?.id &&
                                      !!prompt.userId &&
                                      prompt?.userId === userId
                                    ) {
                                      await removeFavoritePrompt(prompt.id);

                                      return;
                                    }
                                  }
                                  const prompt: WillyPrompt = {
                                    prompt: message.text,
                                    userId: userId,
                                    category: 'My Prompts',
                                    subCategory: 'My Prompts',
                                    title: message.text.substring(0, 20),
                                  };
                                  await _db().collection(FAVORITE_PROMPTS_COLLECTION).add(prompt);
                                }}
                              >
                                <ActionIcon
                                  icon="star-plus"
                                  size="sm"
                                  color={isFavoritePrompt ? 'one.5' : undefined}
                                />
                              </div>
                            </Tooltip>
                          </div>
                        )}
                        {message.role !== 'user' &&
                          !isSequenceMode &&
                          lastOfAssistant &&
                          hasSequenceAccess && (
                            <Tooltip content="Save as Sequence until here">
                              <div
                                className="flex items-center justify-center cursor-pointer"
                                onClick={async () => {
                                  if (!message.conversationId) {
                                    return;
                                  }
                                  $seqSettingsModal.set({
                                    opened: true,
                                    isCreate: true,
                                    conversationId: message.conversationId,
                                    userMessages: conversationMessages.filter(
                                      (x) => x.role === 'user',
                                    ),
                                  });
                                }}
                              >
                                <ButtonPlay className="w-7 flex items-center justify-center dark:fill-gray-300 fill-gray-400" />
                              </div>
                            </Tooltip>
                          )}
                        {message.role === 'user' && editMessage && (
                          <div className="flex items-center gap-4">
                            <Button
                              size="xs"
                              onClick={() => {
                                const oldText = message.text?.trim();
                                const newText = cachedEditedMessage?.trim() ?? '';

                                if (oldText && oldText != newText) {
                                  handleSubmit?.(newText);
                                }

                                const input = inputRef.current;
                                if (input) {
                                  input.innerText = message.text?.trim() ?? '';
                                }

                                setEditMessage(false);
                              }}
                            >
                              Save and Submit
                            </Button>
                            <Button
                              size="xs"
                              variant="white"
                              onClick={() => {
                                const input = inputRef.current;
                                if (input) {
                                  input.innerText = message.text?.trim() ?? '';
                                }

                                setCachedEditedMessage(undefined);
                                setEditMessage(false);
                              }}
                            >
                              Cancel
                            </Button>
                          </div>
                        )}
                        {message.verified && (
                          <Tooltip content="This query was verified by our team">
                            <div className="flex justify-center items-center h-[30px]">
                              <SecureMajor className="w-8 flex items-center justify-center fill-green" />
                            </div>
                          </Tooltip>
                        )}
                        {isLast && canEdit && !isSequenceMode && (
                          <Tooltip content="Regenerate">
                            <div
                              className="cursor-pointer justify-center pt-2"
                              onClick={() => {
                                if (!lastMessageFromUser?.text) {
                                  return;
                                }
                                handleSubmit?.(lastMessageFromUser.text);
                              }}
                            >
                              <Icon name="regenerate" color="gray.4" width={18} />
                            </div>
                          </Tooltip>
                        )}
                        {isLast && (
                          <Button
                            size="xs"
                            variant="activator"
                            onClick={() => {
                              window.open(
                                'https://docs.google.com/forms/d/e/1FAIpQLSchadlp-9rQ25smRi-TjQFPfYcCwjgbZouDyCZjxpFHMiiYgA/viewform?usp=sf_link',
                                '_blank',
                              );
                            }}
                          >
                            Request Support
                          </Button>
                        )}

                        {message.notes?.map((x) => {
                          return (
                            <Tooltip content={message.notes} key={x}>
                              <div className="flex justify-center items-center h-[30px]">
                                <InfoMinor className="w-8 flex items-center justify-center fill-gray-400" />
                              </div>
                            </Tooltip>
                          );
                        })}
                        {!!message.toolsNames && message.toolsNames.length > 0 && (
                          <div className="flex items-center gap-4 justify-between flex-auto">
                            {shouldShowTabs && (
                              <div>
                                <MessageToolTabs
                                  message={message}
                                  selectedTab={selectedTab}
                                  tabChanged={(tab) => setSelectedTab(tab)}
                                />
                              </div>
                            )}
                            <div className="ml-auto flex items-center gap-4">
                              {message.toolsNames?.map((toolName) => {
                                toolName = convertDeprecatedToolName(toolName);
                                return (
                                  <Badge
                                    variant="dot"
                                    key={toolName}
                                    color={willyToolMap[toolName]?.color}
                                  >
                                    <span className="normal-case">
                                      {willyToolMap[toolName]?.title || startCase(toolName)}
                                    </span>
                                  </Badge>
                                );
                              })}
                            </div>
                          </div>
                        )}
                      </div>
                      <div
                        className={`transition-transform duration-100 origin-top ${
                          selectedTab ? ' scale-y-100' : 'scale-y-0'
                        }`}
                      >
                        {!!message.toolResults && message.role === 'tool' && (
                          <MessageToolDetailsTabs activeTab={selectedTab} message={message} />
                        )}
                      </div>
                    </>
                  )}
                </div>

                <WillyEditMetric
                  open={editMetricModalOpen.open}
                  metric={
                    willyMetrics?.find((m) => m?.key === editMetricModalOpen.metricId) ?? null
                  }
                  availableMetrics={willyMetrics}
                  onClose={() => setEditMetricModalOpen({ open: false })}
                  onSaved={async (metric) => {
                    setWillyMetrics((old) => {
                      return old.map((m) => {
                        if (m.key === editMetricModalOpen.metricId) {
                          return {
                            ...m,
                            ...metric,
                          };
                        }
                        return m;
                      });
                    });
                    setEditMetricModalOpen({ open: false });
                  }}
                  onRemoved={async (metricToRemove) => {
                    setWillyMetrics((old) => {
                      return old.filter((m) => m.key !== metricToRemove.key);
                    });
                    setEditMetricModalOpen({ open: false });
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};
