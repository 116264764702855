import { Checkbox } from '@shopify/polaris';
import { useCallback, useEffect, useState } from 'react';

export function AdSpendCheckbox({ onChange, value }) {
  const [checked, setChecked] = useState(value);
  const handleChange = useCallback(
    (val) => {
      setChecked(val);
      onChange(val);
    },
    [onChange],
  );

  useEffect(() => {
    setChecked(value);
  }, [value]);

  return (
    <Checkbox
      label="Ad Spend"
      helpText="This will be included in total ad spend, and will affect all metrics that include blended ads in their calculation"
      checked={checked}
      onChange={handleChange}
    />
  );
}
