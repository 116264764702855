import allServices from 'constants/services';
import { FilterFieldAutocomplete } from './ltv/FilterFieldAutocomplete';
import { searchAds } from 'ducks/serviceSegmentations';
import { OptionDescriptor } from '@shopify/polaris/build/ts/latest/src/types';
import { shopIntegrations } from 'ducks/shopIntegrations';
import { useSelector } from 'react-redux';

type CustomSpendCampaignProps = {
  source: OptionDescriptor;
  campaign: OptionDescriptor;
  setCampaign: (campaign: OptionDescriptor) => void;
  relevantCampaigns: OptionDescriptor[];
  disabled?: boolean;
};

export const CustomSpendCampaign: React.FC<CustomSpendCampaignProps> = ({
  source,
  campaign,
  setCampaign,
  relevantCampaigns,
  disabled,
}) => {
  const integrations = useSelector(shopIntegrations);

  return (
    <FilterFieldAutocomplete
      disabled={disabled}
      searchFunc={async (name) => {
        if (!source.value) {
          return [];
        }
        if (Object.values(allServices).find((s) => s.id === source.value)?.type !== 'ads') {
          return [
            {
              label: name,
              value: name,
            },
          ];
        }
        const res = await searchAds(name, source.value as any, 'campaign', integrations);

        const resFromList = relevantCampaigns.filter((s) =>
          s.label?.toString().toLowerCase().includes(name.toLowerCase()),
        );
        const mapRes = res.map((r) => ({ label: r.name, value: r.campaignId })).concat(resFromList);
        return [{ label: name, value: name }, ...mapRes];
      }}
      onSelectSearchResult={(selectedOption) => {
        setCampaign(selectedOption);
      }}
      placeholder="Campaign"
      initVal={campaign.label?.toString()}
      emptyStateMessage={source.value ? 'No campaigns found' : 'Select a source first'}
      initialOptions={relevantCampaigns}
    />
  );
};
