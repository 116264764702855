import { useState, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import {
  Checkbox,
  Collapsible,
  TextField,
  Icon as PolarisIcon,
  Text,
  Button,
} from '@shopify/polaris';
import { startCase, groupBy } from 'lodash';
import { CircleAlertMajor, PlusMinor, SearchMinor } from '@shopify/polaris-icons';
import {
  selectUniqueSectionsWithMetrics,
  selectReportPixelConnectedServices,
} from 'utils/selectors';
import LockedFeatureIndicator from 'components/library/LockedFeatures/LockedFeatureIndicator';
import { FeatureFlag } from '@tw/feature-flag-system/module/types';
import { useFeatureFlagValue } from 'feature-flag-system';
import { toggleShowingModalAddSaleBySegment } from 'ducks/shopify/salesBySegmentModal';
import { useAppDispatch } from 'index';

export const MetricsSelector = ({
  value,
  onChange,
  error = '',
  validate = (v) => {},
  display,
}: {
  value: string[];
  onChange: (value: string[]) => void;
  error?: string;
  validate?: (value?: string[]) => boolean | void;
  display?: boolean;
}) => {
  const uniqSections = useSelector(selectUniqueSectionsWithMetrics);
  const pixelMetrics = useSelector(selectReportPixelConnectedServices);
  const blockedIntegrations = useFeatureFlagValue(FeatureFlag.LIMIT_INTEGRATIONS_FF, 'blockList');
  const [searchValue, setSearchValue] = useState();

  const groupedSections = groupBy(uniqSections, 'types');
  const pixelGroupedSections = (() => {
    let { services } = pixelMetrics;
    services = Array.isArray(services) ? services : [];
    if (!blockedIntegrations.length) return services;
    return services.filter(({ id }) => !blockedIntegrations.includes(id));
  })();

  const onSearchValueChange = (text) => setSearchValue(text);

  useEffect(() => {
    if (!validate) return;
    if (error) validate(value);
  }, [value]);

  return (
    <>
      <div className="mb-[1rem] lg:flex lg:justify-start lg:items-center">
        {error.length ? (
          <div className="ml-[1rem] flex items-center">
            <div className="max-w-[12px] max-h-[12px] mr-[8px]">
              <PolarisIcon source={CircleAlertMajor} color="critical" />
            </div>
            <Text as="p" variant="bodyMd" color="critical">
              {error}
            </Text>
          </div>
        ) : (
          <></>
        )}
      </div>
      <div className="lg:w-[50%]">
        <TextField
          label=""
          placeholder="Search metrics"
          value={searchValue}
          onChange={onSearchValueChange}
          prefix={<PolarisIcon source={SearchMinor} color="base" />}
          inputMode="search"
          autoComplete="off"
          clearButton
          onClearButtonClick={() => onSearchValueChange('')}
        />
      </div>

      <div className="mt-[24px]">
        <MetricsGroup
          display={display}
          groupId={'summary'}
          groupedSections={groupedSections.summary.filter((section) => {
            if (
              blockedIntegrations.length &&
              Array.isArray(section.services) &&
              !section.services.some((s) => !blockedIntegrations.includes(s))
            )
              return false;

            // rm filter once reports can get segments
            return section.id !== 'salesBySegment';
          })}
          // groupedSections={groupedSections.summary}
          selectedMetrics={value}
          onMetricSelect={onChange}
          searchText={searchValue}
        />
      </div>
      {pixelMetrics.services?.length > 0 && (
        <div className="mtt-[24px]">
          <MetricsGroup
            groupId={'pixel'}
            groupedSections={pixelGroupedSections}
            selectedMetrics={value}
            onMetricSelect={onChange}
            searchText={searchValue}
            display={display}
          />
        </div>
      )}
    </>
  );
};

interface MetricsGroup {
  groupId: string;
  groupedSections: any[];
  selectedMetrics: string[];
  onMetricSelect: Function;
  searchText?: string;
  display?: boolean;
}
export const MetricsGroup: React.FC<MetricsGroup> = ({
  groupId,
  groupedSections,
  selectedMetrics,
  onMetricSelect,
  searchText,
  display,
}) => {
  searchText = searchText?.toLowerCase();

  const allowedMetrics = useFeatureFlagValue(FeatureFlag.LIMIT_METRICS_FF, 'allowList');
  const [isToggleState, setToggleState] = useState<boolean | null>();
  const filteredSections = sectionHasFilteredTiles(groupId, searchText, groupedSections);
  const [hasFilteredSections, setHasFilteredSections] = useState<boolean>(
    !!searchText && filteredSections.length > 0,
  );

  const isExpanded = useMemo(() => {
    if (isToggleState == null) {
      return hasFilteredSections;
    } else {
      return isToggleState;
    }
  }, [hasFilteredSections, isToggleState]);

  useEffect(() => {
    setHasFilteredSections(!!searchText && filteredSections.length > 0);
    setToggleState(null);
  }, [searchText, filteredSections.length]);

  return (
    <div className="metrics-section">
      <div
        className="py-[10px] pl-[16px] mb-[25px] text-[16px] font-medium cursor-pointer rounded-[8px]"
        style={{ backgroundColor: 'hsla(218, 17%, 91%, 0.4)' }}
        onClick={() => setToggleState(!isExpanded)}
      >
        {startCase(groupId)}
      </div>

      <Collapsible
        open={isExpanded || !!display}
        id=""
        transition={{ duration: '800ms', timingFunction: 'ease-in-out' }}
      >
        {groupId === 'pixel'
          ? groupedSections
              .filter((section) => {
                if (!searchText) return true;
                return section.pixelMetrics.some((metric) => {
                  const label = `Pixel ${section.title} ${metric.shortLabel}`;
                  return label.toLowerCase().includes(searchText as string); // "as" because we return earlier if undefined
                });
              })
              .filter(
                (section) =>
                  !display ||
                  section.pixelMetrics.some((m) =>
                    selectedMetrics.includes(`pixel#${section.id}#${m.key}`),
                  ),
              )
              .map((section) => {
                const isLocked =
                  !!allowedMetrics.length &&
                  !section.pixelMetrics.some((t) => allowedMetrics.includes(t.key));

                return (
                  <MetricsSection
                    section={section}
                    searchText={searchText}
                    isLocked={isLocked}
                    key={section.id}
                    display={display}
                  >
                    <PixelMetricsSelection
                      section={section}
                      selectedMetrics={selectedMetrics}
                      onMetricSelect={onMetricSelect}
                      searchText={searchText}
                      isLocked={isLocked}
                      display={display}
                    />
                  </MetricsSection>
                );
              })
          : groupedSections
              .filter((section) => {
                if (section.tiles.length === 0) return false;
                if (!searchText) return true;
                return section.tiles.some((tile) =>
                  tile.reportTitle
                    ? tile.reportTitle.toLowerCase().includes(searchText)
                    : tile.title.toLowerCase().includes(searchText),
                );
              })
              .filter(
                (section) => !display || section.tiles.some((t) => selectedMetrics.includes(t.id)),
              )
              .map((section) => {
                const isLocked =
                  !!allowedMetrics.length &&
                  !section.tiles.some((t) => allowedMetrics.includes(t.id));

                return (
                  <MetricsSection
                    section={section}
                    searchText={searchText}
                    isLocked={isLocked}
                    key={section.id}
                    display={display}
                  >
                    <MetricsSelection
                      section={section}
                      selectedMetrics={selectedMetrics}
                      onMetricSelect={onMetricSelect}
                      searchText={searchText}
                      allowedMetrics={allowedMetrics}
                      isLocked={isLocked}
                      display={display}
                    />
                  </MetricsSection>
                );
              })}
      </Collapsible>
    </div>
  );
};

const MetricsSection = ({ section, children, searchText, isLocked, display }) => {
  const [isExpanded, setExpanded] = useState(searchText ? true : false);

  return (
    <div className="mb-[20px] px-[10px]" key={section.id}>
      <div
        className="text-[15px] font-medium mb-[21px] cursor-pointer inline-flex justify-between w-full"
        onClick={() => setExpanded(!isExpanded)}
      >
        <span>
          {section?.pixelMetrics
            ? section?.icon?.({ small: false })
            : (section?.icons || []).map((I, i) => <I key={i} className="w-[14px]" />)}
          <span className="ml-[8px]">{`${section?.title} ${
            section?.title?.endsWith('Metrics') ? '' : 'Metrics'
          }`}</span>
          <div className="ml-[10px] inline-block h-[23px] align-middle">
            {isLocked && (
              <LockedFeatureIndicator
                iconSize={23}
                iconOnly
                featureFlag={FeatureFlag.LIMIT_METRICS_FF}
                extraData={{ targetToUnlock: section?.id }}
              />
            )}
          </div>
        </span>
      </div>

      <Collapsible
        open={isExpanded || !!display}
        id=""
        transition={{ duration: '300ms', timingFunction: 'ease-in-out' }}
      >
        {children}
      </Collapsible>
    </div>
  );
};

const MetricsSelection = ({
  section,
  selectedMetrics,
  onMetricSelect,
  searchText,
  allowedMetrics,
  isLocked,
  display,
}) => {
  const [selectAll, setSelectAll] = useState(false);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (
      isLocked ||
      section.tiles.some(
        (m) =>
          !selectedMetrics.includes(m.id) &&
          (!allowedMetrics.length || allowedMetrics.includes(m.id)),
      )
    ) {
      setSelectAll(false);
    } else {
      setSelectAll(true);
    }
  }, [selectedMetrics, section.tiles]);

  const onToggleSelectAll = (isSelected) => {
    setSelectAll(isSelected);
    if (isSelected) {
      const metricsToAdd = section.tiles
        .filter(
          (t) =>
            !selectedMetrics.includes(t.id) &&
            (!allowedMetrics.length || allowedMetrics.includes(t.id)),
        )
        .map((t) => t.id);
      onMetricSelect(selectedMetrics.concat(metricsToAdd));
    } else {
      onMetricSelect(selectedMetrics.filter((m) => !section.tiles.map((t) => t.id).includes(m)));
    }
  };

  return (
    <>
      {section.id === 'salesBySegment' && (
        <Button
          onClick={() => dispatch(toggleShowingModalAddSaleBySegment(true, false))}
          plain
          icon={PlusMinor}
        >
          Add Segment
        </Button>
      )}
      <div>
        <Checkbox
          label={`All ${section.title} metrics`}
          checked={selectAll}
          value="all"
          onChange={onToggleSelectAll}
          disabled={isLocked || display}
        />
      </div>
      {section.tiles
        .filter((tile) =>
          searchText
            ? tile.reportTitle
              ? tile.reportTitle.toLowerCase().includes(searchText)
              : tile.title.toLowerCase().includes(searchText)
            : true,
        )
        .filter((tile) => !display || selectedMetrics.includes(tile.id))
        .map((metric) => {
          const { id } = metric;
          return (
            <label className="mr-[21px] text-[13px]" key={id}>
              <Checkbox
                label={metric.reportTitle ?? metric.title}
                checked={selectedMetrics.includes(id)}
                value={id}
                onChange={() => {
                  if (selectedMetrics.includes(id)) {
                    onMetricSelect(selectedMetrics.filter((m) => m !== id));
                  } else {
                    onMetricSelect(selectedMetrics.concat(id));
                  }
                }}
                disabled={display || (allowedMetrics.length && !allowedMetrics.includes(id))}
              />
            </label>
          );
        })}
    </>
  );
};

const PixelMetricsSelection = ({
  section,
  selectedMetrics,
  onMetricSelect,
  searchText,
  isLocked,
  display,
}) => {
  const [selectAll, setSelectAll] = useState(false);

  useEffect(() => {
    if (
      section.pixelMetrics
        .map((metric) => `pixel#${section.id}#${metric.key}`)
        .some((m) => !selectedMetrics.includes(m))
    ) {
      setSelectAll(false);
    } else {
      setSelectAll(true);
    }
  }, [selectedMetrics, section.tiles]);

  const onToggleSelectAll = (isSelected) => {
    setSelectAll(isSelected);
    if (isSelected) {
      const metricsToAdd = section.pixelMetrics
        .map((metric) => {
          const metricId = `pixel#${section.id}#${metric.key}`;
          return !selectedMetrics.includes(metricId) && metricId;
        })
        .filter(Boolean);
      onMetricSelect(selectedMetrics.concat(metricsToAdd));
    } else {
      onMetricSelect(
        selectedMetrics.filter(
          (m) =>
            !section.pixelMetrics.map((metric) => `pixel#${section.id}#${metric.key}`).includes(m),
        ),
      );
    }
  };

  return (
    <>
      <div>
        <Checkbox
          label={`All Pixel ${section.title} Metrics`}
          checked={selectAll}
          value="all"
          onChange={onToggleSelectAll}
          disabled={isLocked || display}
        />
      </div>
      {section.pixelMetrics
        .filter((metric) => {
          if (!searchText) return true;
          const label = `Pixel ${section.title} ${metric.shortLabel}`;
          return label.toLowerCase().includes(searchText);
        })
        .filter(
          (metric) => !display || selectedMetrics.includes(`pixel#${section.id}#${metric.key}`),
        )
        .map((metric) => {
          const metricId = `pixel#${section.id}#${metric.key}`;
          return (
            <label className="mr-[21px] text-[13px]" key={metricId}>
              <Checkbox
                disabled={display}
                label={`Pixel ${section.title} ${metric.shortLabel}`}
                checked={selectedMetrics.includes(metricId)}
                value={metricId}
                onChange={() => {
                  if (selectedMetrics.includes(metricId)) {
                    onMetricSelect(selectedMetrics.filter((m) => m !== metricId));
                  } else {
                    onMetricSelect(selectedMetrics.concat(metricId));
                  }
                }}
              />
            </label>
          );
        })}
    </>
  );
};

const sectionHasFilteredTiles = (groupId, searchText, groupedSections) => {
  if (groupId === 'pixel') {
    return groupedSections.filter((section) => {
      if (!searchText) return true;
      return section.pixelMetrics.filter((metric) => {
        const label = `Pixel ${section.title} ${metric.shortLabel}`;
        return label.toLowerCase().includes(searchText);
      }).length;
    });
  }
  return groupedSections.filter((section) => {
    if (section.tiles.length === 0) return false;
    if (!searchText) return true;
    return section.tiles.filter((tile) =>
      tile.reportTitle
        ? tile.reportTitle.toLowerCase().includes(searchText)
        : tile.title.toLowerCase().includes(searchText),
    ).length;
  });
};
