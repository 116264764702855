import { useHistory, useLocation } from 'react-router';
import ProductMediaCard from './ProductMediaCard';

type ProductMediaCardsProps = {
  data: any[];
};

const ProductMediaCards: React.FC<ProductMediaCardsProps> = ({ data }) => {
  const history = useHistory();
  const location = useLocation();
  return (
    <div
      className={'grid gap-6.5 grid-cols-[repeat(auto-fill,minmax(230px,1fr))] auto-rows-[240px]'}
    >
      {data.map((prod, i) => {
        return (
          <div key={prod?.id}>
            <div
              className="rounded w-full shadow-[rgb(0,0,0,8%)_0px_0px_8px] bg-white product-cards overflow-hidden"
              // onClick={() =>
              //   history.push({
              //     pathname: `/content-hub/products/${prod?.handle}`,
              //     search: location.search,
              //   })
              // }
            >
              <ProductMediaCard
                prod={prod}
                openItem={() =>
                  history.push({
                    pathname: `/creative-hub/products/${prod?.handle}`,
                    search: location.search,
                  })
                }
              />
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default ProductMediaCards;
