import { FeatureFlag, FeatureFlagConfigKey } from '@tw/feature-flag-system/module/types';
import { $derived, $effect, $observer, $store } from '@tw/snipestate';

import { $ffStore } from 'feature-flag-system';
import { firestoreRef, toArray } from 'utils/DB';
import { WillyDashboardElement } from '../../components/Willy/types/willyTypes';
import {
  $isAdminClaim,
  $isTwGlobalDashboardCreatorClaim,
  $isTwSuperAdmin,
  $userId,
} from '../$user';
import { $shopWithSensory } from '../$shopWithSensory';
import { services } from '@tw/types/module/services';
import { $globalDashboardsStatistics } from './$globalDashboardsStatistics';
import { $msp } from '$stores/$shop';

const $globalDashboardsSnapshot = $observer(
  { loading: true, data: [] as WillyDashboardElement[] },
  (get, set) => {
    if (!get($userId) || !get($ffStore).isReady) return;

    return firestoreRef()
      .collection('willy_global_dashboards')
      .onSnapshot((snapshot) => {
        set({ loading: false, data: toArray(snapshot).sort((a, b) => (a.name < b.name ? -1 : 1)) });
      });
  },
);

const $snapshotDone = $store<number | null>(null);
export const $globalDashboards = $observer<WillyDashboardElement[]>([], (get, set) => {
  const ffComputer = get($ffStore);
  if (!ffComputer.isReady) return;

  const isAdminClaim = get($isAdminClaim);
  const isTwGlobalDashboardCreatorClaim = get($isTwGlobalDashboardCreatorClaim);
  const isTwSuperAdmin = get($isTwSuperAdmin);
  const globalDashboardsStatistics = get($globalDashboardsStatistics);
  const shopMsp = get($msp);

  const { numericLimit } = ffComputer.getConfigById(FeatureFlag.DASHBOARDS_COUNT_FF);
  const { allowList } = ffComputer.getConfigById(FeatureFlag.TEMPLATES_FF);
  const allowedDashboardIds = new Set(allowList);

  const { loading, data: snapshotData } = get($globalDashboardsSnapshot);

  const data = snapshotData.reduce((acc, d) => {
    if (!isAdminClaim && d.isBeta) return acc;
    if (!isTwGlobalDashboardCreatorClaim && d.isHide) return acc;

    let isProviderLocked = false;

    const { providers, providersBlockingCombination, msps } = d;
    let isTemplateAvailableToMsp = true;
    if (!!msps?.length && shopMsp) {
      isTemplateAvailableToMsp = msps.includes(shopMsp);
    }
    if (!!providers?.length) {
      const providersIsConnected = providers.map(
        (x) => services[x]?.getIsConnected?.(get($shopWithSensory)) ?? true,
      );
      const providersIsAvailable = providers.map((x) => {
        const provider = services[x];
        if (shopMsp && !!provider?.msps?.length) {
          return provider?.msps?.includes(shopMsp);
        } else {
          return true;
        }
      });

      if (providersBlockingCombination === 'OR') {
        isProviderLocked = providersIsConnected.every((x) => !x);
      } else {
        isProviderLocked = providersIsConnected.includes(false);
      }
      isTemplateAvailableToMsp = !providersIsAvailable.includes(false);
    }

    const pkgMap = ffComputer.ffPackagesConfig;

    const packages: FeatureFlagConfigKey[] = [];
    if (pkgMap) {
      for (let pkgName in pkgMap) {
        const _pkgName = pkgName as FeatureFlagConfigKey;
        const val = pkgMap?.[_pkgName]?.[FeatureFlag.TEMPLATES_FF]?.value;
        if (Array.isArray(val) && (val as any[]).includes(d.id)) packages.push(_pkgName);
      }
    }

    const defaultPackages: FeatureFlagConfigKey[] = [];
    if (pkgMap) {
      for (let pkgName in pkgMap) {
        const _pkgName = pkgName as FeatureFlagConfigKey;
        const val = pkgMap?.[_pkgName]?.[FeatureFlag.WILLY_DEFAULT_TEMPLATES_FF]?.value;
        if (Array.isArray(val) && (val as any[]).includes(d.id)) defaultPackages.push(_pkgName);
      }
    }

    if (!isTemplateAvailableToMsp) return acc;

    acc.push({
      ...d,
      isGlobal: true,
      isCustomView: false,
      type: 'dashboard',
      installCount: globalDashboardsStatistics[d.id]?.installed ?? 0,
      packages,
      defaultPackages,
      canView: true,
      canEdit: isTwGlobalDashboardCreatorClaim,
      canDelete: isTwSuperAdmin,
      isLocked: !!(allowedDashboardIds.size && !allowedDashboardIds.has(d.id)),
      isProviderLocked,
    });

    return acc;
  }, [] as WillyDashboardElement[]);

  set(data);

  if ($snapshotDone.get() === null && !loading) {
    $snapshotDone.set(data.length);
  }
});

export const $globalDashboardIds = $derived(
  (get) => new Set(get($globalDashboards).map((d) => d.id)),
);
export const $loadingGlobalDashboards = $store(true);
$effect((unsub, get) => {
  const snapDone = get($snapshotDone);
  const dashIds = get($globalDashboardIds);
  if (snapDone !== null && snapDone === dashIds.size) {
    unsub();
    $loadingGlobalDashboards.set(false);
  }
});
