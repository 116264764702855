export default {
  billingInterval: {
    year: {
      suffix: 'yr',
    },
    month: {
      suffix: 'mo',
    },
    'one-time': {
      suffix: '',
    },
  },
};
