import './ProductAnalytics.scss';

import { CreativesCockpitContext } from 'components/CreativesCockpit/context';
import CreativeChart from 'components/CreativesCockpit/CreativeChart/CreativeChart';
import CreativeDashboard from 'components/CreativesCockpit/CreativeDashboard/CreativeDashboard';

import { CreativeColumn } from 'components/CreativesCockpit/CreativeTable/columns';
import { MAX_ACTIVE_CREATIVES, TW_CREATIVE_PAGE_SIZE } from 'constants/creativeCockpit';
import { CHART_COLORS } from 'constants/general';
import { useAppDispatch } from 'index';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { type RootState } from 'reducers/RootType';
import {
  Button,
  Checkbox,
  Collapsible,
  Icon,
  Page,
  SortDirection,
  Stack,
  TextField,
} from '@shopify/polaris';

import {
  ChartData,
  ChartMode,
  DashboardView,
  ICreativesCockpitContext,
  SelectableCreative,
  SortingDimension,
} from '../../types/creativeCockpit';
import {
  TW_PROD_ANALYTICS_COLUMNS,
  TW_PROD_ANALYTICS_PRIMARY_METRIC,
  TW_PROD_ANALYTICS_SECONDARY_METRIC,
  TW_PROD_ANALYTICS_SORT_BY,
} from 'constants/productAnalytics';
import {
  changeSearchTerm,
  getProductsForAnalytics,
  ITEM_TYPE,
  ROWS_PER_PAGE,
  TOGGLE_INCLUDE_ACTIVE_PRODUCTS_ONLY,
  TOGGLE_INCLUDE_FREE_PRODUCTS,
  toggleActiveProduct,
  toggleSelectedProduct,
} from 'ducks/productAnalytics';
import { productAnalyticsStats, comparisonAnalyticsStats } from 'utils/selectors';
import { getDefaultColumns } from 'utils/getDefaultColumns';
import { useLastSevenDays } from 'utils/useLastSevenDays';
import ProductAdsPopup from 'components/ProductAnalytics/ProductAdsPopup';
import ProductCardPopup from 'components/ProductAnalytics/ProductCardPopup';
import ProductSkusPopup from 'components/ProductAnalytics/ProductSkusPopup';
import { InAppContextBanner } from 'components/InAppContextBanner';
import { FeatureFlag } from '@tw/feature-flag-system/module/types';
import { computeFeatureFlags } from 'feature-flag-system';
import { UpgradePageFallBack } from 'feature-flag-system/components';
import InsightsSection from 'components/Insights/InsightsSection';
import InsightsFilterField from 'components/Insights/InsightFilterField';
import DropDown from 'components/ltv/DropDown';
import { SearchMinor } from '@shopify/polaris-icons';
import InsightFilter from 'components/Insights/Filters/InsightFilter';
import { appliedInsightsFiltersQueries, FiltersContext } from 'ducks/insights/filterReducers';
import { FilterProperty } from '@tw/types/module/services/insights';
import Switch from 'components/Insights/Switch';
import { CustomMetricsType, selectCustomMetricsForType } from 'ducks/customMetrics';
import { getCustomMetricsFromRowData } from 'utils/customMetrics';
import { useColumnsWithCustomMetrics } from 'components/CreativesCockpit/CreativeDashboard/useColumnsWithCustomMetrics';
import { DEFAULT_FILTERS_LIST } from 'components/Insights/Filters/constants';

const sortableColumns = [
  'product',
  'dateStockRunsOut',
  'daysOfStock',
  'avgSold30',
  'totalSold30',
  'productAov',
  'productNcAov',
  'ncOrderValue',
  'orderValue',
  'revenue',
  'grossProfit',
  'itemsInInventory',
  'itemsSoldAvg',
  'itemsSoldTotal',
  'ordersWithProduct',
  'returnRate',
  'returnsTotal',
  'rpr',
  'customerCount',
  'ncRevenue',
  'ncGrossProfit',
  'ncOrdersWithProduct',
  'productStatus',
  'modePurchasePrice',
  'avgPurchasePrice',
  'addedToCartEvents',
  'ltv60',
  'ltv90',
  'ltv180',
  'ltv365',
];

const defaultColumnKeys = [
  'showInGraph',
  'product',
  'itemsInInventory',
  'itemsSoldAvg',
  'daysOfStock',
  'itemsSoldTotal',
  'spend',
  'contributionMargin',
  'contributionMarginPerUnit',
  'ltv180',
  'returnRate',
];

const ALLOWED_FILTER_PROPS = DEFAULT_FILTERS_LIST;

const fixedColumns = ['showInGraph', 'product'];

const type: 'product' = 'product';
const serviceName: string = 'products';
const serviceId = 'triple-whale';
const customMetricsType: CustomMetricsType = CustomMetricsType.ProductAnalytics;

const ProductAnalyticsComponent: React.FC = () => {
  const dispatch = useAppDispatch();
  const allStats = useSelector(productAnalyticsStats) as SelectableCreative[];
  const comparisons = useSelector(comparisonAnalyticsStats) as SelectableCreative[];
  const activeCurrency = useSelector((state: RootState) => state.activeCurrency);

  const {
    itemType,
    pageSize,
    chartOpen,
    productsCount,
    isLoadingAdStats,
    isLoadingProducts,
    includeFreeProducts,
    includeOnlyActiveProducts,
    searchTerm,
  } = useSelector((state: RootState) => state.productAnalytics);
  const mainDatePickerSelectionRange = useSelector(
    (state: RootState) => state.mainDatePickerSelectionRange,
  );
  const { start, end } =
    useSelector((state: RootState) => state.mainDatePickerSelectionRange) || {};
  const groupStatsBy = useSelector((state: RootState) => state.groupStatsBy);
  const datesToCompare = useSelector((state: RootState) => state.datesToCompare);
  const isLightboxOpen = useSelector((state: RootState) => state.isLightboxOpen);

  const [sortDirection, setSortDirection] = useState<SortDirection>('descending');
  const [pageIndex, setPageIndex] = useState<number>(0);
  const [selectedCreatives, setSelectedCreatives] = useState<SelectableCreative[]>([]);
  const [creativeInModal, setCreativeInModal] = useState<SelectableCreative | null>(null);
  const [adsModalProduct, setAdsModalProduct] = useState<any | null>(null);
  const [skusModalProduct, setSkusModalProduct] = useState<any | null>(null);
  const [selectedColumns, setSelectedColumns] = useState<CreativeColumn[]>([]);
  const [chartMode, setChartMode] = useState<ChartMode>('bar');
  const [chartData, setChartData] = useState<ChartData>('creative');
  const defaultPrimaryMetric =
    localStorage.getItem(TW_PROD_ANALYTICS_PRIMARY_METRIC) || 'itemsSoldTotal';
  const defaultSecondaryMetric =
    localStorage.getItem(TW_PROD_ANALYTICS_SECONDARY_METRIC) || 'returnRate';
  const defaultSortBy =
    (localStorage.getItem(TW_PROD_ANALYTICS_SORT_BY) as SortingDimension) || 'itemsSoldTotal';
  const [sortBy, setSortBy] = useState<SortingDimension>(defaultSortBy);
  const [primaryMetric, setPrimaryMetric] = useState<string>(defaultPrimaryMetric);
  const [secondaryMetric, setSecondaryMetric] = useState<string>(defaultSecondaryMetric);
  const [dashboardView, setDashboardView] = useState<DashboardView>('table');
  const [compareStats, setCompareStats] = useState<SelectableCreative[]>([]);
  const [viewBy, setViewBy] = useState(FilterProperty.PRODUCT_ID);

  const { [FiltersContext.ProductAnalytics]: filters } = useSelector(appliedInsightsFiltersQueries);

  // columns with custom metrics
  const columns = useColumnsWithCustomMetrics(type, serviceId, customMetricsType);
  useEffect(() => {
    const selectedColumns = getDefaultColumns(
      TW_PROD_ANALYTICS_COLUMNS,
      columns.map((x) => ({
        ...x,
        sortable: sortableColumns.includes(x.key),
        isDefault: defaultColumnKeys.includes(x.key),
        isFixed: fixedColumns.includes(x.key),
      })),
    );
    setSelectedColumns(selectedColumns);
  }, [columns]);

  // stats with custom metrics
  const customMetrics = useSelector(selectCustomMetricsForType(customMetricsType));
  const statsWithCustomMetrics = useMemo(() => {
    return compareStats.map((row) => ({
      ...row,
      metrics: { ...row.metrics, ...getCustomMetricsFromRowData(customMetrics, row.metrics) },
    }));
  }, [compareStats, customMetrics]);

  useLastSevenDays(); //set datepicker to last 7 days. sometimes no orders for today

  const calculatedSoryBy = useMemo(() => {
    if ((sortBy as unknown) !== 'product') return sortBy;

    switch (viewBy) {
      case FilterProperty.PRODUCT_ID:
        return 'productName';
      case FilterProperty.PRODUCT_VARIANT_ID:
        return 'variantName';
      case FilterProperty.PRODUCT_SKU:
        return 'sku';
    }
  }, [viewBy, sortBy]);

  useEffect(() => {
    const pageSizeFromLocalStorage = localStorage.getItem(TW_CREATIVE_PAGE_SIZE);
    if (pageSizeFromLocalStorage) {
      dispatch({ type: ROWS_PER_PAGE, payload: parseInt(pageSizeFromLocalStorage) });
    }
  }, [dispatch]);

  useEffect(() => {
    const { start, end, id } = datesToCompare || {};
    const fetchComparisons = async () => {
      if (start !== end && id !== 'none') {
        await dispatch(
          getProductsForAnalytics({
            page: pageIndex,
            limit: pageSize,
            itemType,
            isCompare: true,
            orderBy: sortBy,
            order: sortDirection === 'ascending' ? 1 : -1,
          }),
        );
      }
    };
    fetchComparisons();
  }, [dispatch, itemType, datesToCompare, pageIndex, pageSize, sortBy, sortDirection]);

  const fetchData = useCallback(
    ({ pageIndex }) => {
      const params = {
        page: pageIndex,
        limit: pageSize,
        itemType,
        orderBy: calculatedSoryBy,
        order: sortDirection === 'ascending' ? 1 : -1,
        filters,
      };
      dispatch(getProductsForAnalytics(params));
      setCreativeInModal(null);
      setAdsModalProduct(null);
      setSkusModalProduct(null);
    },
    [calculatedSoryBy, dispatch, filters, itemType, pageSize, sortDirection],
  );

  // reset data on filter changed
  useEffect(() => {
    if (mainDatePickerSelectionRange) {
      setPageIndex(0);
      fetchData({ pageIndex: 0 });
    }
  }, [
    itemType,
    pageSize,
    mainDatePickerSelectionRange,
    calculatedSoryBy,
    sortDirection,
    groupStatsBy,
    includeFreeProducts,
    includeOnlyActiveProducts,
    filters,
    fetchData,
  ]);

  // move to next page
  useEffect(() => {
    if (pageIndex === 0) return;

    fetchData({ pageIndex });
  }, [fetchData, pageIndex]);

  useEffect(() => {
    const selected = allStats.filter((c) => c?.selected);
    selected?.forEach((creative, i) => (creative.color = CHART_COLORS[i]));
    setSelectedCreatives(selected);
  }, [allStats]);

  useEffect(() => {
    let result = allStats.map((creative) => {
      let comparison = comparisons.find((cmp) => cmp.id === creative.id);
      let { metrics, ...rest } = comparison || {};
      let name;
      let formattedName;
      switch (viewBy) {
        case FilterProperty.PRODUCT_VARIANT_ID:
          name = creative['variantName'];
          formattedName = (
            <>
              <p>{name}</p>
              <p className="text-[10px]">{creative['productName']}</p>
            </>
          );
          break;
        case FilterProperty.PRODUCT_SKU:
          name = creative['sku'];
          formattedName = (
            <>
              <p>{name}!</p>
              <p className="text-[10px]">{creative['productName']}</p>
            </>
          );
          break;
        default:
          name = creative['productName'];
      }
      return { ...creative, name, formattedName, comparisons: { ...rest, ...metrics } };
    });
    setCompareStats(result as SelectableCreative[]);
  }, [comparisons, allStats, itemType, viewBy]);

  useEffect(() => {
    if (!isLightboxOpen.open) {
      setCreativeInModal(null);
    }
  }, [isLightboxOpen]);

  const toggleCreativeSelection = (creative: SelectableCreative) => {
    if (!creative.selected && selectedCreatives.length >= MAX_ACTIVE_CREATIVES) {
      return;
    }
    dispatch(toggleSelectedProduct(creative));
    if (creativeInModal?.id === creative.id) {
      setCreativeInModal({ ...creativeInModal, selected: !creativeInModal.selected });
    }
  };

  const toggleCreativeActive = (creative: SelectableCreative) => {
    dispatch(toggleActiveProduct(creative.id));
  };

  const setItemType = useCallback(
    (viewBy: FilterProperty) => {
      const newTtemType = viewBy === FilterProperty.PRODUCT_ID ? 'products' : 'variants';
      dispatch({ type: ITEM_TYPE, payload: newTtemType });
    },
    [dispatch],
  );

  const toggleIncludeFreeProducts = (value: boolean) => {
    dispatch({ type: TOGGLE_INCLUDE_FREE_PRODUCTS, payload: value });
  };

  const toggleIncludeActiveProductsOnly = (value: boolean) => {
    dispatch({ type: TOGGLE_INCLUDE_ACTIVE_PRODUCTS_ONLY, payload: value });
  };

  const setPageSize = (pageSize: number) => {
    dispatch({ type: ROWS_PER_PAGE, payload: pageSize });
  };

  useEffect(() => {
    return () => {
      setItemType(FilterProperty.PRODUCT_ID);
    };
  }, [setItemType]);

  const contextProps: ICreativesCockpitContext = {
    chartData,
    chartMode,
    creatives: statsWithCustomMetrics,
    currency: activeCurrency,
    dashboardView,
    itemType,
    loadingColumn: isLoadingAdStats,
    loadingCreatives: isLoadingProducts,
    pageIndex,
    pageSize,
    primaryMetric,
    secondaryMetric,
    selectedColumns,
    showComparisons: comparisons?.length > 0 && datesToCompare?.id !== 'none',
    customMetricsType: customMetricsType,
    selectedCreatives,
    serviceId,
    serviceName,
    setChartData,
    setChartMode,
    setCreativeInModal,
    setAdsModalProduct,
    setSkusModalProduct,
    skusModalProduct,
    setDashboardView,
    setPageIndex,
    setPageSize,
    setPrimaryMetric,
    setSecondaryMetric,
    setSelectedColumns,
    setSortBy,
    setSortDirection,
    sortBy,
    sortDirection,
    toggleCreativeActive,
    toggleCreativeSelection,
    totalCreativesNumber: productsCount,
    type: type!,
    creativeInModal,
    start,
    end,
  };

  return (
    <CreativesCockpitContext.Provider value={contextProps}>
      {creativeInModal && (
        <ProductCardPopup action={setCreativeInModal} product={creativeInModal} />
      )}
      {adsModalProduct && (
        <ProductAdsPopup
          action={setAdsModalProduct}
          product={adsModalProduct}
          source={skusModalProduct ? 'skuPopup' : 'product'}
        />
      )}
      {skusModalProduct && (
        <ProductSkusPopup action={setSkusModalProduct} product={skusModalProduct} />
      )}
      <Page fullWidth data-testid="ProductAnalytics">
        <InAppContextBanner page="productAnalytics" />
        <div className="creatives-cockpit products-analytics">
          <InsightsSection>
            <>
              <Collapsible open={chartOpen} id="attribution-collapsible">
                <div className="mb-[30px]">
                  <CreativeChart />
                </div>
              </Collapsible>
              <div className="insights-page">
                <CreativeDashboard
                  sortableColumns={sortableColumns}
                  viewFilters={
                    <>
                      <InsightsFilterField>
                        <DropDown
                          handleSelect={(value) => {
                            setViewBy(value);
                            setItemType(value);
                          }}
                          options={[
                            { value: FilterProperty.PRODUCT_ID, label: 'Product' },
                            { value: FilterProperty.PRODUCT_VARIANT_ID, label: 'Variant' },
                            { value: FilterProperty.PRODUCT_SKU, label: 'SKU' },
                          ]}
                          value={viewBy}
                        ></DropDown>
                      </InsightsFilterField>
                      <InsightFilter
                        filterFields={ALLOWED_FILTER_PROPS}
                        filtersContext={FiltersContext.ProductAnalytics}
                      />
                      <InsightsFilterField tooltipContent="Include revenue-related values when product was given for free">
                        <Switch
                          label="Include free products"
                          checked={includeFreeProducts}
                          onChange={toggleIncludeFreeProducts}
                        ></Switch>
                      </InsightsFilterField>
                      <InsightsFilterField tooltipContent="Display only products whose status is Active">
                        <Switch
                          label="Include only active products"
                          checked={includeOnlyActiveProducts}
                          onChange={toggleIncludeActiveProductsOnly}
                        ></Switch>
                      </InsightsFilterField>
                    </>
                  }
                  viewOptions={
                    <div
                      className="flex space-x-2 wide-input"
                      id={`att-Product-Analytics-${itemType}-search`}
                      onKeyDown={(e) => {
                        if (e.key === 'Enter') {
                          e.preventDefault();
                          dispatch(
                            getProductsForAnalytics({
                              itemType,
                            }),
                          );
                        }
                      }}
                    >
                      <TextField
                        clearButton
                        onClearButtonClick={() => {
                          dispatch(changeSearchTerm(''));
                          dispatch(
                            getProductsForAnalytics({
                              itemType,
                            }),
                          );
                        }}
                        value={searchTerm}
                        onChange={(searchTerm) => {
                          dispatch(changeSearchTerm(searchTerm));
                        }}
                        label="Search"
                        labelHidden
                        placeholder={`Search`}
                        autoComplete="off"
                        prefix={<Icon source={SearchMinor} />}
                        connectedRight={
                          <Button
                            icon={SearchMinor}
                            onClick={() => {
                              dispatch(
                                getProductsForAnalytics({
                                  itemType,
                                }),
                              );
                            }}
                            disabled={searchTerm?.trimEnd?.()?.trimStart?.() === ''}
                          />
                        }
                      />
                    </div>
                  }
                />
              </div>
            </>
          </InsightsSection>
        </div>
      </Page>
    </CreativesCockpitContext.Provider>
  );
};

const ProductAnalytics = computeFeatureFlags(
  FeatureFlag.PRODUCT_ANALYTICS_FF,
  ProductAnalyticsComponent,
  () => (
    <UpgradePageFallBack
      InAppContextBannerEnabled={true}
      InAppContextBannerPage={'productAnalytics'}
      title="Product Analytics"
      description="Real-time KPIs to make informed merchandising decisions by SKU"
      featureFlag={FeatureFlag.PRODUCT_ANALYTICS_FF}
    />
  ),
);

export default ProductAnalytics;
