import { Fragment, useContext } from 'react';
import { WizardContext } from '../WizardContext';
import { MeetingPlanner } from './MeetingPlanner';
import MobileQRCode from './MobileQRCode';

import './steps.scss';
import { useFeatureFlag } from 'feature-flag-system';
import { FeatureFlag } from '@tw/feature-flag-system/module/types';
import { useAppSelector } from 'reducers/RootType';
import { REVENUE_IDS_LT_1MIL } from 'constants/revenueIdSets';
import { Anchor, Text } from '@tw/ui-components';

export const WelcomeSummary: React.FC = () => {
  const { completeWizard } = useContext(WizardContext);
  const { shouldNotBeSeen: isHubspotMeetingLocked } = useFeatureFlag(
    FeatureFlag.HUBSPOT_MEETING_FF,
  );

  const { revenue } = useAppSelector((state) => state.subscription);

  return (
    <div className="h-full w-full flex flex-col items-center justify-center">
      {!isHubspotMeetingLocked ? (
        <Fragment>
          <p className="step-title">All done! Need further assistance?</p>
          {REVENUE_IDS_LT_1MIL.has(revenue) ? (
            <Text>
              Explore our{' '}
              <Anchor href="https://try.triplewhale.com/customer-success-office-hours">
                office hours
              </Anchor>
            </Text>
          ) : (
            <>
              <p className="step-description mb-[15px]">
                Book time with our team to review your setup and prepare you to get the most out of
                Triple Whale.
              </p>

              <div className="w-full">
                <MeetingPlanner />
              </div>
            </>
          )}
        </Fragment>
      ) : (
        <MobileQRCode />
      )}

      <button className="step-button" onClick={completeWizard}>
        Let's get started!
      </button>
    </div>
  );
};

export const WelcomeSummarySideComponent: React.FC = () => {
  const { shouldNotBeSeen: isHubspotMeetingLocked } = useFeatureFlag(
    FeatureFlag.HUBSPOT_MEETING_FF,
  );

  return !isHubspotMeetingLocked ? <MobileQRCode /> : null;
};
