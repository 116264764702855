//get optional plans
//get current plan for store
import { ActionCodeOperation } from 'firebase/auth';
import axiosInstance from 'utils/axiosInstance';

export const GET_CURRENT_SHOP_PLAN = 'GET_CURRENT_SHOP_PLAN';

const LOADING_PLANS_AND_PRICES = 'LOADING_PLANS_AND_PRICES';
export const GET_PLANS_AND_PRICES = 'GET_PLANS_AND_PRICES';
export const getPlansAndPrices = () => {
  return async (dispatch, getState) => {
    dispatch({ type: LOADING_PLANS_AND_PRICES });
    const url = `v2/subscription-manager/plans`;
    const { data } = await axiosInstance.get(url);
    const plans = data;
    return dispatch({
      type: GET_PLANS_AND_PRICES,
      data: plans,
    });
  };
};

const currentPlan = (state = {}, action) => {
  switch (action.type) {
    case GET_CURRENT_SHOP_PLAN: {
      return action.currentPlan || state;
    }
    default: {
      return state;
    }
  }
};

const plansPrices = (state = [], action) => {
  switch (action.type) {
    case GET_PLANS_AND_PRICES: {
      return action.data || state;
    }
    default: {
      return state;
    }
  }
};

const loadingPlansPrices = (state = false, action) => {
  switch (action.type) {
    case LOADING_PLANS_AND_PRICES:
      return true;
    case GET_PLANS_AND_PRICES: {
      return false;
    }
    default: {
      return state;
    }
  }
};

export const reducers = {
  currentPlan,
  plansPrices,
  loadingPlansPrices,
};
