import { Button, Drawer, MobileDrawer, Text } from '@tw/ui-components';
import { WillySequenceInDashboard } from '../types/willyTypes';
import { useCallback, useEffect } from 'react';
import _db from 'utils/DB';

type SequencesReportProps = {
  report: WillySequenceInDashboard;
  dashboardId: string;
};

export const SequencesReport: React.FC<SequencesReportProps> = ({ report, dashboardId }) => {
  const markAsRead = useCallback(async () => {
    if (!report.id || !dashboardId) {
      return;
    }

    await _db()
      .collection('willy_dashboards')
      .doc(dashboardId)
      .collection('sequences_dashboard_reports')
      .doc(report.id)
      .set({ read: true }, { merge: true });
  }, [report, dashboardId]);

  useEffect(() => {
    markAsRead();
  }, [markAsRead]);

  return (
    <div className="flex flex-col gap-2">
      <Text as="h2" size="sm" fw="bold">
        {report.title}
      </Text>

      <div className="prose dark:prose-invert">
        <p dangerouslySetInnerHTML={{ __html: report.content }} />
      </div>
    </div>
  );
};

type SequencesReportModalProps = {
  open: boolean;
  onClose: () => void;
  isSmall?: boolean;
  showBackButton?: boolean;
  withOverlay?: boolean;
};

export const SequencesReportModal: React.FC<SequencesReportProps & SequencesReportModalProps> = (
  props,
) => {
  const { open, onClose, isSmall, showBackButton, withOverlay = true } = props;

  if (isSmall) {
    return (
      <MobileDrawer opened={open} onClose={onClose}>
        <SequencesReport {...props} />
      </MobileDrawer>
    );
  }
  return (
    <Drawer
      title={
        showBackButton ? (
          <Button onClick={() => onClose()} variant="white" size="xs" leftSection="caret-left">
            Back to All Reports
          </Button>
        ) : undefined
      }
      transitionProps={{
        timingFunction: 'ease-in-out',
        duration: 1000,
      }}
      opened={open}
      onClose={onClose}
      position="right"
      size="xl"
      withOverlay={withOverlay}
    >
      <SequencesReport {...props} />
    </Drawer>
  );
};
