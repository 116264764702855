import { RootState, useAppSelector } from 'reducers/RootType';
import { useSelector } from 'react-redux';
import { Provider } from '@tw/types/module/services/account-manager';
import { Box, LoadingOverlay, Select, Text, Button, Flex, Card } from '@tw/ui-components';
import { confirm } from '@tw/ui-components';
import { useAppDispatch } from 'index';
import { SENSORY_SAVING_INTEGRATION_CHANGES, updateInt } from 'ducks/sensory';
import { useEffect, useState } from 'react';
import { ProviderSetting } from '@tw/types/module/sensory';

export const IntegrationsSettings = () => {
  const providersIntegrations = useAppSelector((state) => state.sensory.providerAccounts) as any[];
  const sensoryProviderConfigData = useSelector(
    (state: RootState) => state.sensory.sensoryProviderConfigData as Provider,
  );
  return (
    <Box key="manage-settings">
      {(() => {
        const filteredIntegrations = providersIntegrations.filter(
          (int) => int.provider_id == sensoryProviderConfigData.id && int.integration_id,
        );

        if (filteredIntegrations.length === 0) {
          return (
            <Box>
              <Text>You can't edit settings without a connected account.</Text>
              <Text>Please connect an account and try again.</Text>
            </Box>
          );
        }

        return filteredIntegrations.map((integration) => {
          return sensoryProviderConfigData?.settings ? (
            <Box key={integration.id}>
              <Settings integration={integration} configData={sensoryProviderConfigData}></Settings>
            </Box>
          ) : (
            <Text>Settings are not available for this provider</Text>
          );
        });
      })()}
    </Box>
  );
};

const Settings = ({ integration, configData }) => {
  const dispatch = useAppDispatch();
  const [dynamicSettingsOptions, setDynamicSettingsOptions] = useState({});
  const [currentSettings, setCurrentSettings] = useState<any[]>([]);

  const sensoryProviderSettingLoading = useAppSelector(
    (state) => state.sensory.sensoryProviderSettingLoading,
  );
  const sensoryProviderDynamicSettings = useAppSelector(
    (state) => state.sensory.sensoryProviderDynamicSettings,
  );

  useEffect(() => {
    if (
      sensoryProviderDynamicSettings &&
      sensoryProviderDynamicSettings.providerId == integration.provider_id
    ) {
      setDynamicSettingsOptions(sensoryProviderDynamicSettings.fields);
    }
  }, [integration.provider_id, sensoryProviderDynamicSettings]);

  useEffect(() => {
    if (integration.settings) {
      setCurrentSettings(integration.settings);
    }
  }, [integration.settings]);

  const saveSettings = async () => {
    try {
      if (
        await confirm({
          title: 'Update Account',
          message: 'Are you sure you want to update account?',
        })
      ) {
        dispatch(async () => {
          dispatch({ type: SENSORY_SAVING_INTEGRATION_CHANGES });
          integration.settings = currentSettings;
          await dispatch(updateInt(integration));
        });
      }
    } catch (err) {
      console.error(err);
    }
  };

  const settingChanged = (field: string, value) => {
    let currentField = currentSettings.find((s) => s[field]);
    if (currentField) {
      currentField[field] = value;
    } else {
      currentField = { [field]: value };
      currentSettings.push(currentField);
    }
    setCurrentSettings([...currentSettings]);
  };

  const dynamicSettingChanged = (collectionName: string, field: string, value) => {
    let currentDynamicSettings = currentSettings.find((s) => s['propertiesMap']);
    if (!currentDynamicSettings) {
      currentDynamicSettings = {
        propertiesMap: {},
      };
      currentSettings.push(currentDynamicSettings);
    }

    if (!currentDynamicSettings.propertiesMap[collectionName]) {
      currentDynamicSettings.propertiesMap[collectionName] = [];
    }

    let fieldSetting = currentDynamicSettings.propertiesMap[collectionName].find(
      (x) => x.fieldName === field,
    );
    if (fieldSetting) {
      fieldSetting.sourceField = value;
    } else {
      currentDynamicSettings.propertiesMap[collectionName].push({
        fieldName: field,
        sourceField: value,
      });
    }
    setCurrentSettings([...currentSettings]);
  };

  return (
    <Box>
      <Flex align="center" mt={'sm'} justify={'space-between'}>
        <Text fz={'lg'}>Account {integration.provider_account_name}</Text>
        <Button onClick={saveSettings}>Save Settings</Button>
      </Flex>
      <LoadingOverlay visible={sensoryProviderSettingLoading} />
      <Box mt={'sm'} overflow={'scroll'}>
        {configData?.settings?.map((setting: ProviderSetting) => (
          <Box key={setting.field}>
            {setting.settingType === 'list' && (
              <Flex align="center" direction="row" my={'sm'}>
                <Text tt="capitalize" mr={'sm'}>
                  {setting.label}:
                </Text>
                <Select
                  data={setting.values!}
                  value={
                    currentSettings.find((s) => s[setting.field])
                      ? currentSettings.find((s) => s[setting.field])[setting.field]
                      : ''
                  }
                  onChange={(data) => settingChanged(setting.field, data)}
                />
              </Flex>
            )}
            {setting.settingType === 'dynamicMapping' &&
              setting.mappingFields
                ?.filter((x) => x?.['fieldNames']?.length)
                .map((mappingField) => (
                  <Card my={'xs'} key={'card' + mappingField.collectionName}>
                    <Box key={mappingField.collectionName}>
                      <Text tt="capitalize" weight={'bold'}>
                        {mappingField.collectionName}
                      </Text>
                      {mappingField['fieldNames']?.map((fieldName) => (
                        <Flex align="center" direction="row" my={'sm'} justify="space-between">
                          <Text tt="capitalize" mr={'sm'} fz={'sm'}>
                            {fieldName}:
                          </Text>
                          <Select
                            w="70%"
                            searchable
                            data={
                              dynamicSettingsOptions[mappingField.collectionName]
                                ?.map((x) => {
                                  return { value: x.id, label: x.label };
                                })
                                .sort((a, b) => a.label.localeCompare(b.label)) ?? []
                            }
                            value={
                              currentSettings
                                .find((s) => s['propertiesMap'])
                                ?.propertiesMap[
                                  mappingField.collectionName
                                ]?.find((x) => x.fieldName === fieldName)?.sourceField ?? ''
                            }
                            onChange={(data) =>
                              dynamicSettingChanged(mappingField.collectionName, fieldName, data)
                            }
                          />
                        </Flex>
                      ))}
                    </Box>
                  </Card>
                ))}
          </Box>
        ))}
      </Box>
    </Box>
  );
};
