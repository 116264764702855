import { useEffect } from 'react';
import { useLocation } from 'react-router';
import { useAppSelector } from 'reducers/RootType';
import { $navigationSections } from '$stores/nav-config-stores';
import { useStoreValue } from '@tw/snipestate';

const outerRoutes = {
  '/pods-view': 'Pods',
  '/signin': 'Sign In | Triple Whale',
  '/signup': 'Sign Up | Triple Whale',
} as const;

/**
 * @description Hook used to dynamically change page title based on location, currentShopId, and user.
 */
export const useUpdateTabTitleOnRouteChange = (): void => {
  const { pathname } = useLocation();
  const navigationSections = useStoreValue($navigationSections);
  const currentShopId = useAppSelector((state) => state.currentShopId);
  const user = useAppSelector((state) => state.user);

  useEffect(() => {
    if (outerRoutes[pathname]) {
      document.title = outerRoutes[pathname];
      return;
    }

    for (const section of navigationSections) {
      for (const route of section.routes) {
        const matchesPath =
          pathname.includes(route.url) ||
          (route.urlToCheck && pathname.includes(route.urlToCheck as string));

        // because of some dynamic trickery in `src/constants/routes/utils.tsx`, label
        // can actually be a ReactNode, so we need to check if it's actually a string
        const label = typeof route.label === 'string' ? route.label : route.shortLabel;

        if (matchesPath && label) {
          document.title = label;
          return;
        }
      }
    }

    for (const route in outerRoutes) {
      if (pathname.includes(route)) {
        document.title = outerRoutes[route];
        return;
      }
    }

    document.title = 'Triple Whale';
  }, [pathname, navigationSections, currentShopId, user]);
};
