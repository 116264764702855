import { useStoreValue } from '@tw/snipestate';
import { Icon, Text, Tooltip } from '@tw/ui-components';
import { REPORTS_SUB_SECTION } from 'constants/routes/configs/reports-subnav';
import { useCallback, useMemo, useState } from 'react';
import Nestable from 'react-nestable';
import { useHistory, useLocation } from 'react-router';
import { cx } from 'utils/cx';
import { $dashSidePanelOpenMainCats, toggleSidePanelMainCats } from './stores';
import { closeReportSelectorDrawer } from '$stores/willy/$mobileDrawers';
import { windowSize } from 'utils/classes/WindowSizeObserver';
import { isMobileApp } from 'utils/Device';
import { NavLink } from 'react-router-dom';

export const WillyDashSidePanelStandardCats = () => {
  const { pathname } = useLocation();

  const standardSectionItems = useMemo(() => {
    return REPORTS_SUB_SECTION().map((section) => {
      const mainRoute = section.routes[0];
      const childrenRoutes = section.routes.length === 1 ? section.routes.slice(1) : section.routes;
      let isChildRouteActive = false;
      const children = childrenRoutes.map((route) => {
        const isActive = pathname.includes(route.url);
        if (isActive) isChildRouteActive = isActive;
        return {
          id: route.label,
          value: route.label,
          url: route.url,
          depth: 1,
          isActive,
        };
      });

      return {
        id: section.title,
        value: section.title,
        url: mainRoute.url,
        depth: 0,
        children: children,
        icon: section.icon,
        isActive: isChildRouteActive || pathname.includes(mainRoute.url),
      };
    });
  }, [pathname]);

  const [nestableRef, setNestableRef] = useState<Nestable>();
  const [items, setItems] = useState<any>(standardSectionItems);
  const dashSidePanelOpenMainCats = useStoreValue($dashSidePanelOpenMainCats);

  const toggleOpenSingleItem = useCallback(
    (itemId: string) => {
      const newItems = items.map((i) => {
        if (i.id !== itemId) return i;
        return {
          ...i,
          metadata: { ...i.metadata, isOpen: !i.metadata?.isOpen },
        };
      });

      const collapsedIds = newItems.reduce((acc, x) => {
        if (!x.metadata?.isOpen) acc.push(x.id);
        return acc;
      }, [] as string[]);

      nestableRef?.collapse(collapsedIds);

      setItems(newItems);
    },
    [items, nestableRef],
  );
  // const isOpen = useComputedValue($openNavSection, (sec) => sec === props.title);

  return (
    <div className="m-2 group/standard-cat">
      <div
        className="flex items-center p-4 cursor-pointer justify-between"
        onClick={() => toggleSidePanelMainCats('standard')}
      >
        <span className="flex items-center gap-[6px]">
          <span className="lg:opacity-0 group-hover/report-side-panel:opacity-100">
            <span className="render-collapse-icon">
              <div
                style={{
                  transform: !dashSidePanelOpenMainCats?.includes('standard')
                    ? 'rotate(-90deg)'
                    : '',
                }}
              >
                <Icon name="arrow-down-3" size={9} color="gray.4" />
              </div>
            </span>
          </span>
          <Text fz="xs" fw="500" c="gray.5" tt="uppercase">
            Standard
          </Text>
        </span>
        <span className="lg:opacity-0 group-hover/report-side-panel:opacity-100 flex items-center">
          <Tooltip
            label="Any adjustments or formatting to Standard Reports are private to you."
            lightTooltip
            position="right"
            multiline
          >
            <Icon name="info" size={14} color="gray.4" />
          </Tooltip>
        </span>
      </div>
      {/* <style>{`
        .nestable .nestable-list { padding: 0px; }
        .nestable-list .nestable-item .render-item { padding-left: calc(20px * var(--i)); }
      `}</style> */}
      {dashSidePanelOpenMainCats?.includes('standard') && (
        <Nestable
          ref={(el) => el && setNestableRef(el)}
          renderCollapseIcon={({ isCollapsed }) => {
            return (
              <span className="inline-block relative pr-3 render-collapse-icon">
                <div
                  className="inline-block"
                  style={{ transform: isCollapsed ? 'rotate(-90deg)' : '' }}
                >
                  <Icon name="arrow-down-3" size={9} color="gray.4" />
                </div>
              </span>
            );
          }}
          collapsed
          items={standardSectionItems}
          renderItem={({ item, collapseIcon }) => (
            <RenderItem
              item={item}
              toggleOpenSingleItem={toggleOpenSingleItem}
              collapseIcon={collapseIcon}
            />
          )}
        />
      )}
    </div>
  );
};

const RenderItem = ({ item, toggleOpenSingleItem, collapseIcon }) => {
  const history = useHistory();
  const { search } = useLocation();
  const url = useMemo(() => `/dashboards${item.url}${search}`, [item.url, search]);

  const handleTouchEvent = (e) => {
    if (e.target?.['tagName'] === 'use') return;
    if (item.children.length) {
      toggleOpenSingleItem(item.id);
    }
    if (windowSize.isSmall || isMobileApp) {
      closeReportSelectorDrawer();
      history.push(url);
    }
  };

  const Wrapper = useMemo(
    () =>
      !!item.depth || !item.children.length
        ? (props) => <NavLink {...props} to={url} />
        : (props) => <div {...props} />,
    [item.depth, item.children.length, url],
  );

  const classes = useMemo(
    () =>
      cx(
        'no-underline text-[var(--mantine-color-gray-8)]',
        'font-medium group/dashboard-label gap-2 px-4',
        'render-item flex rounded-md items-center',
        'cursor-pointer hover:bg-[var(--gray-light-mode-300)]',
        item.isActive && 'bg-[var(--gray-light-mode-300)]',
        item.depth && '-my-2.5 py-2',
        !item.depth && 'py-0.5',
      ),
    [item.depth, item.isActive],
  );

  return (
    <Wrapper
      style={{ ['--i' as any]: item.depth || 0 }}
      className={classes}
      onClick={handleTouchEvent}
    >
      <div className="lg:opacity-0 group-hover/report-side-panel:opacity-100 pl-4 min-w-[24px]">
        {collapseIcon}
      </div>
      <div className="block w-full ">
        <span className="flex items-center gap-1">
          {item.icon && (
            <span className="mb-[-0.225rem] ml-[-0.5rem]">
              {item.icon({ selected: item.isActive })}
            </span>
          )}
          <span className="whitespace-nowrap overflow-hidden text-ellipsis inline-flex items-center gap-2">
            <span className="truncate" contentEditable={false}>
              <Text span fz="sm" c="gray.8" fw={500}>
                {item.value}
              </Text>
            </span>
          </span>
        </span>
      </div>
      <span className="lg:opacity-0 group-hover/report-side-panel:opacity-100 flex items-center">
        {item.tooltip && (
          <Tooltip label={item.tooltip} lightTooltip position="right" multiline>
            <Icon name="info" size={14} color="gray.4" />
          </Tooltip>
        )}
      </span>
    </Wrapper>
  );
};
