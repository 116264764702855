import { useMemo } from 'react';
import { WillyDataColumn, RawNlqData, NlqResponseDataType } from '../types/willyTypes';
import { keyIsSomeDate } from '../utils/willyUtils';

export const useDefaultType = (
  data?: RawNlqData,
  dataColumns?: WillyDataColumn,
  question?: string,
  dataType?: NlqResponseDataType,
) => {
  const defaultType = useMemo(() => {
    return guessDefaultType(data, dataColumns, question, dataType);
  }, [question, dataColumns, data, dataType]);

  return defaultType;
};

export function guessDefaultType(
  data?: RawNlqData,
  dataColumns?: WillyDataColumn,
  question?: string,
  dataType?: NlqResponseDataType,
) {
  if (dataType === 'forecast') {
    return 'chart';
  }
  if (dataType === 'causal') {
    return 'chart';
  }
  if (!dataColumns || !data) {
    return 'table';
  }

  if (['chart', 'graph', 'plot'].some((x) => question?.toLowerCase().includes(x))) {
    return 'chart';
  } else if (['table', 'list', 'tabular'].some((x) => question?.toLowerCase().includes(x))) {
    return 'table';
  }

  if (dataColumns?.x?.length === 0) {
    return 'tile';
  }

  const dataLength = data.find((d) => d.name === dataColumns.x[0])?.value?.length || 0;

  if (dataLength > 1) {
    return 'table';
  }

  if (!dataColumns.x.some((x) => keyIsSomeDate(x))) {
    return 'tile';
  }

  if (dataColumns?.y?.length > 0) {
    return 'table';
  }

  return 'table';
}
