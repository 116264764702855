import {
  DAILY_GROUP_STATS,
  groupBtnPressed,
  HOURLY_GROUP_STATS,
  isGroupBtnDisabled,
  MONTHLY_GROUP_STATS,
  WEEKLY_GROUP_STATS,
} from 'ducks/stats';
import { useAppDispatch } from 'index';
import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { type RootState } from 'reducers/RootType';

import { ChoiceList, Stack } from '@shopify/polaris';

export default function GroupStatsButtons({ onClose }) {
  const dispatch = useAppDispatch();
  const mainDatePickerSelectionRange = useSelector(
    (state: RootState) => state.mainDatePickerSelectionRange,
  );
  const groupStatsBy = useSelector((state: RootState) => state.groupStatsBy);
  const [group, setGroup] = useState(groupStatsBy);
  const isBtnDisabled = useCallback(
    (group) => {
      return isGroupBtnDisabled({ mainDatePickerSelectionRange, group });
    },
    [mainDatePickerSelectionRange],
  );

  const handleChoice = useCallback(
    (selected) => {
      selected = selected[0];
      if (selected === 'hour') {
        dispatch(groupBtnPressed(selected, HOURLY_GROUP_STATS));
      } else if (selected === 'day') {
        dispatch(groupBtnPressed(selected, DAILY_GROUP_STATS));
      } else if (selected === 'week') {
        dispatch(groupBtnPressed(selected, WEEKLY_GROUP_STATS));
      } else if (selected === 'month') {
        dispatch(groupBtnPressed(selected, MONTHLY_GROUP_STATS));
      }
      onClose && onClose();
    },
    [dispatch, onClose],
  );

  const isChoiceDisabled = useCallback(
    (choice) => {
      return isBtnDisabled(choice);
    },
    [isBtnDisabled],
  );

  useEffect(() => {
    setGroup(groupStatsBy);
  }, [groupStatsBy]);
  return (
    <Stack alignment="center">
      <ChoiceList
        title
        titleHidden
        choices={[
          // { label: 'Hour', value: 'hour', disabled: isChoiceDisabled('hour') },
          { label: 'Day', value: 'day', disabled: isChoiceDisabled('day') },
          { label: 'Week', value: 'week', disabled: isChoiceDisabled('week') },
          { label: 'Month', value: 'month', disabled: isChoiceDisabled('month') },
        ]}
        selected={group as any}
        onChange={handleChoice}
        allowMultiple={false}
      />
    </Stack>
  );
}
