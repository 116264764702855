import { useIsSmall } from 'hooks/useDefaultWindowSizes';
import { ChatHistory } from './ChatHistory';
import { ActionIcon, MobileDrawer, Tooltip } from '@tw/ui-components';
import { useState } from 'react';
import { Popover } from '@shopify/polaris';
import { analyticsEvents, chatActions, genericEventLogger } from 'utils/dataLayer';

type ChatHistoryPopupProps = {
  conversationId: string;
  clearConversation: () => void;
};

export const ChatHistoryPopup: React.FC<ChatHistoryPopupProps> = ({
  conversationId,
  clearConversation,
}) => {
  const isSmall = useIsSmall();

  const [opened, setOpened] = useState(false);

  const activator = (
    <div>
      <ActionIcon
        iconSize={20}
        icon="rewind-clock"
        onClick={() =>
          setOpened((x) => {
            if (!x) {
              genericEventLogger(analyticsEvents.CHAT, {
                action: chatActions.OPEN_CHAT_HISTORY,
              });
            }

            return !x;
          })
        }
      />
    </div>
  );

  if (isSmall) {
    return (
      <div>
        {activator}
        <MobileDrawer
          title="Prompt Library"
          onClose={() => setOpened(false)}
          opened={opened}
          position="bottom"
          withinPortal
        >
          <ChatHistory
            clearConversation={clearConversation}
            conversationId={conversationId}
            onConversationClicked={() => setOpened(false)}
          />
        </MobileDrawer>
      </div>
    );
  }

  return (
    <Popover
      active={opened}
      onClose={() => setOpened(false)}
      preventCloseOnChildOverlayClick
      preferredAlignment="right"
      // width="70%"
      // shadow="md"
      activator={
        <div>
          <Tooltip label="Chat History">{activator}</Tooltip>
        </div>
      }
    >
      {/* <Popover.Target></Popover.Target> */}
      {/* <Popover.Dropdown h="85vh" overflow="hidden"> */}
      <div className="overflow-hidden">
        <ChatHistory
          clearConversation={clearConversation}
          conversationId={conversationId}
          onConversationClicked={() => setOpened(false)}
        />
      </div>
      {/* </Popover.Dropdown> */}
    </Popover>
  );
};
