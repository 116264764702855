import { $derived, $mutableDerived, useStoreValue } from '@tw/snipestate';
import { $search } from '../$location';
import moment from 'moment-timezone';
import { getPrevDates } from '../../utils/getPreviousDate';
import { $mspConnected, $shop, $timezone } from '../$shop';
import {
  mapFromPeriodIdToTimeUnit,
  PreviousPeriodIds,
} from '@tw/types/module/datePicker/datePicker';
import {
  getDatePickerOptionValueOptions,
  DatePickerTimePeriods,
} from '../../components/useDatePickerSelectedOptions';
import QueryString from 'qs';
import { $history } from '../$history';
import { isEqual } from 'lodash';
import { Moment } from '@tw/moment-cached';
import { useHistory } from 'react-router';
import { Granularity } from '@tw/types';
import store from '../../index';
import { MAIN_DATE_PICKER_SELECTION_CHANGE } from '../../ducks/constants';

export type CurrentDateRange = {
  start: moment.Moment;
  end: moment.Moment;
  id: DatePickerTimePeriods;
};
export type PrevDateRange = { start: moment.Moment; end: moment.Moment; id: PreviousPeriodIds };
export const CURRENT_DATE_ID = 'current-date-id';
export const PREV_DATE_ID = 'prev-date-id';

export const pushDateToQS = (
  history: Partial<ReturnType<typeof useHistory>>,
  start?: Moment,
  end?: Moment,
  isCurrent = true,
  id?: PreviousPeriodIds | DatePickerTimePeriods,
  removeId = false,
) => {
  const prevQuery = QueryString.parse(location.search, { ignoreQueryPrefix: true });
  const startKey = isCurrent ? 'start' : 'prev-start';
  const endKey = isCurrent ? 'end' : 'prev-end';
  const idKey = isCurrent ? CURRENT_DATE_ID : PREV_DATE_ID;
  const query = {
    ...prevQuery,
  };

  // Conditionally add or remove the ID based on `id` and `removeId`
  if (id && !removeId) {
    query[idKey] = id;
  } else if (removeId) {
    delete query[idKey]; // Remove the idKey from the query object if removeId is true
  }

  if (start && end) {
    query[startKey] = start.format('YYYY-MM-DD');
    query[endKey] = end.format('YYYY-MM-DD');
  } else {
    delete query[startKey];
    delete query[endKey];
  }

  if (!isEqual(prevQuery, query)) {
    history.push?.({
      pathname: location.pathname,
      search: QueryString.stringify(query),
    });
  }
};

export const pushFullDateToQS = (
  history,
  start: Moment,
  end: Moment,
  prevStart: Moment,
  prevEnd: Moment,
  currentId?: DatePickerTimePeriods,
  prevId?: PreviousPeriodIds,
  removeCurrentId = false,
  removePrevId = false,
) => {
  const prevQuery = QueryString.parse(location.search, { ignoreQueryPrefix: true });
  const query = {
    ...prevQuery,
  };

  // Conditionally add or remove the ID based on `id` and `removeId`
  if (currentId && !removeCurrentId) {
    query[CURRENT_DATE_ID] = currentId;
  } else if (removeCurrentId) {
    delete query[CURRENT_DATE_ID]; // Remove the idKey from the query object if removeId is true
  }

  if (prevId && !removePrevId) {
    query[PREV_DATE_ID] = prevId;
  } else if (removePrevId) {
    delete query[PREV_DATE_ID]; // Remove the idKey from the query object if removeId is true
  }

  query['start'] = start.format('YYYY-MM-DD');
  query['end'] = end.format('YYYY-MM-DD');
  query['prev-start'] = prevStart.format('YYYY-MM-DD');
  query['prev-end'] = prevEnd.format('YYYY-MM-DD');

  if (!isEqual(prevQuery, query)) {
    history.push?.({
      pathname: location.pathname,
      search: QueryString.stringify(query),
    });
  }
};

export const $currentDateRange = $mutableDerived((get) => {
  const mspConnected = get($mspConnected);
  const timezone = get($timezone);
  if (!timezone || !mspConnected) return undefined;

  const search = get($search);
  const history = get($history);
  moment.tz.setDefault(timezone);
  const searchParams = new URLSearchParams(search);
  let start = searchParams.get('start');
  const end = searchParams.get('end');
  const id = searchParams.get(CURRENT_DATE_ID) as DatePickerTimePeriods | undefined;
  const datePickerOptionValueOptions = getDatePickerOptionValueOptions();
  const dateFromId = datePickerOptionValueOptions.find((x) => x.id === id);
  if (!start || !end) {
    if (!dateFromId) {
      pushDateToQS(
        history,
        moment()
          .tz(timezone ?? '')
          .startOf('day'),
        moment()
          .tz(timezone ?? '')
          .endOf('day'),
        true,
        'today' as DatePickerTimePeriods,
      );
      return undefined;
    } else {
      pushDateToQS(history, dateFromId.start, dateFromId.end, true, id);
      return undefined;
    }
  }

  if (start && end && dateFromId) {
    if (
      start !== dateFromId.start.format('YYYY-MM-DD') ||
      end !== dateFromId.end.format('YYYY-MM-DD')
    ) {
      pushDateToQS(history, dateFromId.start, dateFromId.end, true, id);
      store.dispatch({
        type: MAIN_DATE_PICKER_SELECTION_CHANGE,
        range: dateFromId,
      });
      // location.reload(); //this is ugly but we need to refactor the old redux to listed to this store, after we will do it, we can remove this line
      return undefined;
    }
  }

  start =
    moment(end).diff(start, 'days') > 365
      ? moment(end).subtract(364, 'days').format('YYYY-MM-DD')
      : start;

  return {
    id: id ?? 'custom',
    start: moment(start).startOf('day'),
    end: moment(end).endOf('day'),
  } as CurrentDateRange;
});

export const $granularity = $mutableDerived((get) => {
  const dateRange = get($currentDateRange);
  if (!dateRange) {
    return 'day' as Granularity;
  }

  const { start, end } = dateRange;

  const is30DaysOrMore = Math.abs(moment(start).diff(end, 'days')) >= 31;
  const isThreeMonthsOrMore = Math.abs(moment(start).diff(end, 'days')) >= 89;
  const isOneDay = moment(start).diff(end, 'days') === 0;

  if (isOneDay) {
    return 'hour';
  }

  if (isThreeMonthsOrMore) {
    return 'month';
  }

  if (is30DaysOrMore) {
    return 'week';
  }

  return 'day';
});

const $compareDatePickerSelectedOption = $derived(
  (get) => (get($shop) as any).compareDatePickerSelectedOption,
);
export const $prevDateRange = $mutableDerived((get) => {
  const mspConnected = get($mspConnected);
  if (!mspConnected) return undefined;

  const timezone = get($timezone);
  const compareDatePickerSelectedOption = get($compareDatePickerSelectedOption);
  const search = get($search);
  const current = get($currentDateRange);
  const history = get($history);

  moment.tz.setDefault(timezone);
  const searchParams = new URLSearchParams(search);
  const start = searchParams.get('prev-start');
  const end = searchParams.get('prev-end');
  let prevId = searchParams.get(PREV_DATE_ID) as PreviousPeriodIds | undefined;
  if (!mspConnected || !current) return undefined;

  if (!start || !end) {
    if (prevId === 'none') {
      return {
        id: prevId,
      } as PrevDateRange;
    }
    const defaultCompareOption: PreviousPeriodIds =
      prevId ?? compareDatePickerSelectedOption ?? 'default';
    if (
      defaultCompareOption === 'previousPeriod' ||
      defaultCompareOption === 'default' ||
      !mapFromPeriodIdToTimeUnit[defaultCompareOption]
    ) {
      const prev = getPrevDates(current);

      pushDateToQS(history, prev.start, prev.end, false, 'previousPeriod');

      return undefined;
    }

    const period = mapFromPeriodIdToTimeUnit[defaultCompareOption]!;
    const prevStart = moment
      .tz(start, timezone ?? '')
      .subtract(1, period)
      .startOf('day');
    const prevEnd = moment
      .tz(end, timezone ?? '')
      .subtract(1, period)
      .endOf('day');

    pushDateToQS(history, prevStart, prevEnd, false, prevId);

    return undefined;
  }
  return {
    id: prevId ?? 'default',
    start: moment(start).startOf('day'),
    end: moment(end).endOf('day'),
  } as PrevDateRange;
});
