import React, { useEffect, useMemo, useState } from 'react';
import axiosInstance from '../../../../utils/axiosInstance';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../reducers/RootType';
import {
  ProviderAuth_basicAuth,
  ProviderAuth_oAuth,
  ProviderAuthMethod,
  SensoryProvider,
} from '@tw/types/module/sensory';
import { Spinner, TextField } from '@shopify/polaris';
import { GenericUrlConnect } from './GenericUrlConnect';
import { connectToSensoryBasicAuth, connectToSensory } from '../../../../utils/connectToSensory';
import SalesPlatformIcon from '../../../SalesPlatform/SalesPlatformIcon';
import SalesPlatformName from '../../../SalesPlatform/SalesPlatformName';
import { useAppDispatch } from '../../../../index';
import { SET_MSP_CONNECTED } from '../../../../ducks/constants';
import { TextInput } from '@tw/ui-components';

export const SensoryConnect = ({ providerId, nextEnabled, resetContinueWarn }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [authMethod, setAuthMethod] = useState<ProviderAuthMethod>('basic_auth');
  const [authConfig, setAuthConfig] = useState<ProviderAuth_oAuth | ProviderAuth_basicAuth | null>(
    null,
  );
  const [bigRealURLValue, setBigRealURLValue] = useState<string>('');
  const [error, setError] = useState<string | null>(null);
  const [fields, setFields] = React.useState<
    {
      value: any;
      field: string;
      label?: string;
      valueType: 'string' | 'number' | 'boolean';
    }[]
  >([]);
  const validate = (value: string): string | null => {
    const pattern = /^store-[^.]+\.mybigcommerce\.com$/;
    if (!pattern.test(value)) {
      return 'Url must be in this format "store-<store-name>.mybigcommerce.com"';
    }
    return null;
  };

  const filteredFields = useMemo(() => {
    if (providerId === 'bigcommerce') {
      return fields.filter((f) => f.field !== 'context');
    }
    return fields;
  }, [fields, providerId]);

  const handleBlur = () => {
    const errorMessage = validate(bigRealURLValue);
    setError(errorMessage);
  };
  const currentShopId = useSelector((state: RootState) => state.currentShopId);
  const dispatch = useAppDispatch();
  useEffect(() => {
    if (nextEnabled) {
      nextEnabled(!isLoading);
    }
  }, [isLoading, nextEnabled]);

  const isBigCommerceNotOriginURL = useMemo(() => {
    return providerId === 'bigcommerce' && !/^store-[^.]+\.mybigcommerce\.com$/.test(currentShopId);
  }, [currentShopId, providerId]);

  useEffect(() => {
    (async () => {
      setIsLoading(true);
      const providers = (
        await axiosInstance.get(`/v2/account-manager/integrations/providers/${currentShopId}`)
      )?.data as SensoryProvider[];
      const provider = providers.find((p) => p.id === providerId) ?? ({} as SensoryProvider);
      setAuthMethod(provider.auth_method);
      setAuthConfig(provider.auth_config);
      setFields(
        provider.auth_method === 'basic_auth'
          ? ((provider.auth_config as ProviderAuth_basicAuth).fields as any)
          : [],
      );
      setIsLoading(false);
    })();
  }, [currentShopId, providerId]);

  const fieldsToSend = useMemo(() => {
    if (providerId === 'bigcommerce') {
      const extractContextFromUrl = (
        isBigCommerceNotOriginURL ? bigRealURLValue : currentShopId
      ).match(/^store-([^\.]+)\.mybigcommerce\.com$/);
      return filteredFields.reduce((acc, x) => ({ ...acc, [x.field]: x.value }), {
        context: extractContextFromUrl ? extractContextFromUrl[1] : '',
      });
    }
    return filteredFields.reduce((acc, x) => ({ ...acc, [x.field]: x.value }), {});
  }, [bigRealURLValue, currentShopId, filteredFields, isBigCommerceNotOriginURL, providerId]);

  return (
    <>
      {!isLoading && authMethod === 'oauth' && (
        <>
          <GenericUrlConnect
            connectAction={() => connectToSensory(currentShopId, providerId)}
            resetContinueWarn={resetContinueWarn}
          />
        </>
      )}
      {!isLoading && authMethod === 'basic_auth' && (
        <div>
          {isBigCommerceNotOriginURL && (
            <div className={'mb-5'}>
              <TextInput
                label={'Your ".mybigcommerce" URL'}
                placeholder={'store-<store-name>.mybigcommerce.com'}
                required
                onChange={(val) => setBigRealURLValue(val)}
                onBlur={handleBlur}
                error={error}
              ></TextInput>
            </div>
          )}
          {filteredFields.map((f: any, index) => (
            <div className={'mb-5'}>
              <TextInput
                label={f.label}
                value={f.value}
                placeholder={f.field}
                type={f.valueType}
                required
                onChange={(val) => {
                  const list = filteredFields.slice();
                  list[index] = { ...list[index], value: val as any };
                  setFields(list);
                }}
              />
            </div>
          ))}
          <button
            disabled={filteredFields.some((x) => !x.value)}
            className={`mt-5 step-action-button !rounded-[8px] py-[15px] px-[30px] flex items-center gap-[15px] ${
              filteredFields.some((x) => !x.value) ? 'disabled' : ''
            }`}
            onClick={async () => {
              const res = await connectToSensoryBasicAuth(currentShopId, providerId, fieldsToSend);
              if (res) {
                dispatch({ type: SET_MSP_CONNECTED });
              }
            }}
          >
            <SalesPlatformIcon />
            <span className="font-semibold text-[18px]">
              Connect to <SalesPlatformName />
            </span>
          </button>
        </div>
      )}
      {isLoading && (
        <div
          style={{
            alignItems: 'center',
            display: 'flex',
            justifyContent: 'center',
            height: '100%',
            width: '100%',
          }}
        >
          <Spinner accessibilityLabel="App is loading" size="large" />
        </div>
      )}
    </>
  );
};
