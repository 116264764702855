import { Flex } from '@tw/ui-components';
import { BottomSectionLink } from './BottomSectionLink';
import { useIsNavWideStyle } from 'components/Nav/stores';
import { BOTTOM_SECTION_LINKS } from 'components/Nav/constants/bottomSectionLinks';

export const BottomSection = () => {
  const isWide = useIsNavWideStyle();

  return (
    <Flex direction="column" gap="xs" w="100%" align={isWide ? 'flex-start' : 'center'}>
      {BOTTOM_SECTION_LINKS.map(({ label, href, icon }) => (
        <BottomSectionLink key={'btl-' + label} href={href} label={label} icon={icon} />
      ))}
    </Flex>
  );
};
