import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import _db from 'utils/DB';
import { RootState } from 'reducers/RootType';
import { useDashContext } from '../dashContext';
import { getMainElementDbRef } from '../utils/willyUtils';
import { useStoreValue } from '@tw/snipestate';

export const useDashHistory = () => {
  const user = useSelector((state: RootState) => state.user);
  const userEmail = useSelector((state: RootState) => state.userEmail);
  const { $dashboard, $dashboardId, $history, $canEdit } = useDashContext();
  const canEdit = useStoreValue($canEdit);
  const dashboard = useStoreValue($dashboard);
  const dashboardId = useStoreValue($dashboardId);
  const history = useStoreValue($history);

  const updateHistory = useCallback(
    (action, payload?) => {
      if (!dashboardId || !dashboard || !canEdit || dashboard.isGlobal) return;
      if (userEmail && user?.uid) {
        try {
          getMainElementDbRef(dashboard!)
            .collection('history')
            .add({
              // @NOTE MIKE W
              // we don't need this; I think it's slowing down things
              // @todo remove in existing shops
              // data: JSON.stringify(dashboard),
              action: action ?? 'dashboard_updated',
              payload: payload ?? {},
              time: +new Date(),
              userEmail,
              user: {
                uid: user?.uid,
                email: user.email || userEmail || '',
                firstName: user?.firstName ?? '',
                lastName: user?.lastName ?? '',
              },
            });
        } catch (e) {
          console.error('Error updating history', e);
        }
      }
    },
    [
      canEdit,
      dashboard,
      dashboardId,
      user.email,
      user?.firstName,
      user?.lastName,
      user?.uid,
      userEmail,
    ],
  );

  return {
    history,
    updateHistory,
  };
};

export default useDashHistory;
