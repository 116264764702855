import './CreativeCockpitHighlights.scss';

import TWImage from 'components/library/TWImage/TWImage';
import { CREATIVES_NO_COPY_MESSAGE, CreativeTypesElements } from 'constants/creativeCockpit';
import { CHART_COLORS } from 'constants/general';
import { metrics } from 'constants/metrics/metrics';
import { $currentShopId } from '$stores/$shop';
import { getHighlights, saveHighlightsCreatives } from 'ducks/creativesCockpit/creativesCockpit';
import { useAppDispatch } from 'index';
import { FC, useCallback, useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import ShowMoreText from 'components/ShowMoreText';
import { type RootState } from 'reducers/RootType';
import { formatNumber } from 'utils/formatNumber';

import { Icon, Tooltip } from '@shopify/polaris';
import { ArrowDownMinor, ArrowUpMinor } from '@shopify/polaris-icons';
import { CreativeHighlightsRequest, HighlightCreative } from '@tw/types';

import { CreativesCockpitContext } from '../context';
import { Card, Container, Loader, Text, Icon as TWIcon } from '@tw/ui-components';
import { shopIntegrations } from 'ducks/shopIntegrations';

interface CreativeCockpitHighlightsProps {
  isShareMode?: boolean;
  sharedHighlightsCreatives: HighlightCreative[];
}

const CreativeCockpitHighlights: FC<CreativeCockpitHighlightsProps> = ({
  isShareMode = false,
  sharedHighlightsCreatives,
}) => {
  const dispatch = useAppDispatch();

  const [highlights, setHighlights] = useState<HighlightCreative[]>([]);
  const [loading, setLoading] = useState(false);

  const mainDatePickerSelectionRange = useSelector(
    (state: RootState) => state.mainDatePickerSelectionRange,
  );

  const { setCreativeInModal, serviceId, type, sortBy, sortDirection, serviceName } =
    useContext(CreativesCockpitContext);
  const accountSegments = useSelector((state: RootState) => state.adSegmentations.segments);
  const { segments } = useSelector((state: RootState) => state.creativeCockpit);
  const { filters } = useSelector((state: RootState) => state.creativeCockpit);
  const integrations = useSelector(shopIntegrations)[serviceId!] || [];
  const activeCurrency = useSelector((state: RootState) => state.activeCurrency);
  const { attributionModel, dateModel } = useSelector((state: RootState) => state.attribution);
  const currency = useSelector((state: RootState) => state.activeCurrency);

  const fetchHighlights = useCallback(async () => {
    if (isShareMode) {
      try {
        setHighlights(sharedHighlightsCreatives);
      } catch (e) {
        console.log("can't load cc report ", e);
      }
    } else {
      if (!mainDatePickerSelectionRange || !serviceId || !type) {
        return;
      }
      let { start, end } = mainDatePickerSelectionRange as any;
      const segmentsData = Object.values<any>({
        ...accountSegments?.[serviceId],
        ...segments?.[serviceId],
      })
        .filter((seg: any) => seg.enabled)
        .map((x) => x.expressionList);

      const filtersData = Object.values<any>({
        ...accountSegments?.[serviceId],
        ...filters?.[serviceId],
      })
        .filter((seg: any) => seg.enabled)
        .map((x) => x.expressionList);

      start = start.format('YYYY-MM-DD');
      end = end.format('YYYY-MM-DD');
      const granularity = start === end ? 'hour' : 'day';
      const params: CreativeHighlightsRequest = {
        start,
        end,
        service_id: serviceId,
        data_type: 'ads-metrics',
        account_ids: integrations.map((x) => `${x.id}`),
        granularity,
        entity: 'ad',
        creative_type: type,
        sort_by: sortBy,
        sort_direction: sortDirection,
        segments: segmentsData,
        filters: filtersData,
        currency: activeCurrency,
        pixel_attribution_model: attributionModel,
        pixel_date_model: dateModel,
        metrics: [
          'roas',
          'pixelRoas',
          'purchases',
          'pixelPurchases',
          'pixelConversionValue',
          'conversionValue',
          'impressions',
          'clicks',
        ],
        page: 0,
        shopDomain: $currentShopId.get()!,
        useNexus: true,
      };

      setLoading(true);

      const { creatives } = await getHighlights(params);
      setHighlights(creatives);
      if (!isShareMode) {
        dispatch(saveHighlightsCreatives(creatives));
      }
    }
    setLoading(false);
  }, [
    integrations,
    serviceId,
    type,
    sortBy,
    sortDirection,
    activeCurrency,
    attributionModel,
    dateModel,
    segments,
    mainDatePickerSelectionRange,
    accountSegments,
  ]);

  useEffect(() => {
    fetchHighlights();
  }, [fetchHighlights]);

  return (
    <div className="creative-cockpit-highlights" data-testid="CreativeCockpitHighlights">
      <Card className="pt-10 pb-5 px-10 relative" bg="named.3" shadow="sm">
        <Text size="lg" weight={500}>
          Creative Highlights
        </Text>
        <section className="flex items-center">
          {!highlights?.length && !loading && (
            <Text align="center">
              We didn't find any {serviceName} {CreativeTypesElements[type!]?.label} highlights for
              this date range
            </Text>
          )}
          {loading && (
            <div className="loading-creatives-wrapper left-0">
              <Loader />
            </div>
          )}
          <ul className="list-none highlights-list pl-0 mt-8 mb-0 pb-5 flex items-center gap-6 overflow-x-auto relative">
            {highlights?.map((highlight, i) => {
              const isPositive = highlight.delta > 0;
              const isZero = highlight.delta === 0;
              if (!metrics[highlight.metric]) console.log(highlight);
              return (
                <li key={i} className="">
                  <Card withBorder>
                    <div className="w-96 h-105 overflow-hidden relative">
                      {type !== 'copy' && (
                        <TWImage
                          wrapperClass="w-full h-full"
                          className="w-full h-full object-cover rounded-md cursor-pointer"
                          src={highlight.image || highlight.thumbnail}
                          alt={highlight.name}
                          onClick={() => {
                            setCreativeInModal?.(highlight as any);
                          }}
                        />
                      )}
                      {type === 'copy' && (
                        <div className="p-6.5 flex flex-col gap-6.5">
                          <div
                            style={{ borderColor: CHART_COLORS[i % CHART_COLORS.length] }}
                            className="border border-solid rounded-md mr-auto p-4 flex flex-col"
                          >
                            <TWIcon name="text-file" size={24} />
                          </div>
                          <div
                            className="overflow-y-auto overflow-x-hidden h-64 break-words"
                            style={{ whiteSpace: 'break-spaces' }}
                          >
                            <Text>
                              <ShowMoreText lines={3}>
                                {highlight.body || CREATIVES_NO_COPY_MESSAGE}
                              </ShowMoreText>
                            </Text>
                          </div>
                        </div>
                      )}
                      {!isZero && (
                        <div
                          className={`absolute bottom-4 right-4 rounded-6xl py-1 px-4 flex gap-1 text-white fill-white ${
                            isPositive ? 'bg-green' : 'bg-negative'
                          }`}
                        >
                          <Icon source={isPositive ? ArrowUpMinor : ArrowDownMinor} />
                          <span>
                            <Tooltip
                              content={`Previous period the ${
                                metrics[highlight.metric]?.label
                              } was ${highlight.prevValue}`}
                            >
                              {formatNumber(highlight.delta, {
                                style: 'percent',
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              })}
                            </Tooltip>
                          </span>
                        </div>
                      )}
                    </div>
                    <div className="flex justify-between items-center pt-3">
                      <Text weight={500}>{metrics[highlight.metric]?.label}</Text>
                      <Text>
                        {formatNumber(highlight.value, {
                          currency,
                          style: metrics[highlight.metric]?.format || 'decimal',
                          maximumFractionDigits: metrics[highlight.metric]?.toFixed,
                          minimumFractionDigits: metrics[highlight.metric]?.toFixed,
                        })}
                      </Text>
                    </div>
                  </Card>
                </li>
              );
            })}
            <Container className="sticky right-0 h-100 w-32 right-edge-gradient shrink-0"></Container>
          </ul>
        </section>
      </Card>
    </div>
  );
};

export default CreativeCockpitHighlights;
