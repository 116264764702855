import { CodeInterpreterResponse, Message } from './types/willyTypes';
import { useState } from 'react';
import { WillySimpleText } from './WillySimpleText';
import { WillyCollapsibleCode } from './WillyCollapsibleCode';

type WillyCodeExecutionResultProps = {
  codeResult: CodeInterpreterResponse;
};

export const WillyMessageCodeExecutionResult: React.FC<WillyCodeExecutionResultProps> = ({
  codeResult,
}) => {
  const [codeExpanded, setCodeExpanded] = useState(false);

  return (
    <div className="flex flex-col gap-6.5">
      {!!codeResult.pythonCode && (
        <WillyCollapsibleCode
          code={codeResult.pythonCode}
          progress={codeResult.progress}
          step={codeResult.step}
          language="python"
          codeExpanded={codeExpanded}
          setCodeExpanded={setCodeExpanded}
        />
      )}
      {!!codeResult.error && (
        <div className="flex flex-col gap-2">
          <WillySimpleText error text={codeResult.error} />
        </div>
      )}
    </div>
  );
};
