import { ActionIcon, Icon, Modal, Title, Text, Button, Card, Avatar } from '@tw/ui-components';
import { VideoPlayer } from 'components/GetStarted/VideoPlayer';
import review1Profile from 'components/Icons/review_1_profile.png';
import review2Profile from 'components/Icons/review_2_profile.png';
import { $store, useStoreValue, useWritableStore } from '@tw/snipestate';
import { $free_trial_modal, closeFreeTrialPopup } from '$stores/willy/$upgradePopupManager';
import { useIsSmall } from 'hooks/useDefaultWindowSizes';
import { useStartFreeTrial } from './useStartFreeTrial';

const reviews = [
  {
    image: review1Profile,
    name: 'Jack Kavanagh',
    title: 'Director of Strategy, Shopanova',
    review:
      "Our team didn't have time for a traditional forecasting exercise, so we turned to Moby. It took just five minutes. We just asked, 'Hey Moby, can you forecast our revenue for the next six months?' And guess what? Moby's forecast was within two percent accuracy.",
  },
  {
    image: review2Profile,
    name: 'Ron Shah',
    title: 'CEO, Obvi',
    review:
      "We'll be tracking everything down to every hour's profit on our lovely triplewhale dashboard where we have synced up every cost down to even human capital and salaries as well as our overhead and COGS. Don't be afraid of knowing the real picture. It helps paint a better future",
  },
];

export const UpgradePlanModal = () => {
  const free_trial_modal = useStoreValue($free_trial_modal);
  const source = free_trial_modal.source;
  const isSmall = useIsSmall();
  const { startFreeTrial, loading } = useStartFreeTrial(source);

  return (
    <Modal.Root
      size="90vw"
      maw="1140px"
      zIndex={999}
      centered
      opened={free_trial_modal.opened}
      onClose={() => closeFreeTrialPopup()}
      p={0}
    >
      <Modal.Overlay />
      <Modal.Content mah="90vh" p={0}>
        <div className="flex justify-between p-[30px] pb-[10px] items-center">
          <Modal.Title>
            <div className="text-blue-600 uppercase font-semibold text-xl	">
              Premium Plus Free Trial
            </div>
          </Modal.Title>

          <Modal.CloseButton />
        </div>

        <Modal.Body p={0}>
          <div className={` h-full overflow-hidden flex flex-col `}>
            <div className="flex flex-col lg:flex-row gap-5 bg-white p-[30px] pt-0">
              <div className="w-full lg:w-1/2 flex flex-col gap-8">
                <Text fw={700} fz={isSmall ? 24 : 36} color="gray.8">
                  {free_trial_modal.source === 'initial'
                    ? 'A gift, just for you and your team: 30 days to experience the future of unlimited data-driven growth.'
                    : 'Welcome to the future of unlimited data-driven growth.'}
                </Text>
                <Text fw="400" fz={16} maw={600} color="gray.7" align={'start'}>
                  Premium Plus means more data, greater flexibility, and the ability to gain deeper
                  insights faster than ever before. Empower your brand to scale to new heights.
                </Text>
                <Text fw="400" fz={16} maw={600} color="gray.7" align={'start'}>
                  For 30-days, enjoy features only available on Premium Plus:
                </Text>
                <div className="flex flex-col gap-5">
                  <div className="flex gap-5 items-center">
                    <Icon name="circle-check" color="named.6" size={17} />
                    <Text fw="400" fz={16} maw={600} color="gray.7" align={'start'}>
                      Unlimited custom reports and ready-made templates to share with your team
                    </Text>
                  </div>
                  <div className="flex gap-5 items-center">
                    <Icon name="circle-check" color="named.6" size={17} />
                    <Text fw="400" fz={16} maw={600} color="gray.7" align={'start'}>
                      AI powered insights and forecasting with Moby to accelerate your growth
                    </Text>
                  </div>
                  <div className="flex gap-5 items-center">
                    <Icon name="circle-check" color="named.6" size={17} />
                    <Text fw="400" fz={16} maw={600} color="gray.7" align={'start'}>
                      Data enrichment with Sonar to boost campaign ROI
                    </Text>
                  </div>
                  <div className="flex gap-5 items-center">
                    <Icon name="circle-check" color="named.6" size={17} />
                    <Text fw="400" fz={16} maw={600} color="gray.7" align={'start'}>
                      Full data warehouse with SQL Editor for unmatched transparency and control.
                    </Text>
                  </div>
                </div>
                <div className="w-[400px]">
                  <Button
                    loading={loading}
                    variant="primary"
                    fullWidth
                    onClick={() => {
                      startFreeTrial();
                      closeFreeTrialPopup(false);
                    }}
                  >
                    Start Free Trial
                  </Button>
                </div>
              </div>
              <div className="w-full lg:w-1/2">
                <VideoPlayer
                  rounded
                  source="https://www.youtube.com/embed/ptEgco69AXQ?si=akVSEqM4hHZg9j6U&controls=1"
                />
              </div>
            </div>
            <div className="bg-light-gray flex gap-8 p-[30px]">
              {reviews.map((review) => {
                return (
                  <div className="w-1/2 mih-[216px] overflow-scroll no-scrollbar" key={review.name}>
                    <Card shadow="md" w="100%" h="100%">
                      <div className="flex gap-5 h-full">
                        <Avatar>
                          <img src={review.image} alt="profile" width="36" />
                        </Avatar>
                        <div className="flex flex-col justify-between h-full">
                          <Text fw={500} color="black" fz={16}>
                            {'"'}
                            {review.review}
                            {'"'}
                          </Text>
                          <div className="flex flex-col">
                            <Text fw={500} color="black" fz={14}>
                              {review.name}
                            </Text>
                            <Text fw={500} color="gray.5" fz={14}>
                              {review.title}
                            </Text>
                          </div>
                        </div>
                      </div>
                    </Card>
                  </div>
                );
              })}
            </div>
          </div>
        </Modal.Body>
      </Modal.Content>
    </Modal.Root>
  );
};
