import {
  ActionIcon,
  Badge,
  Button,
  Icon,
  Modal,
  MultiSelect,
  Select,
  Text,
  Textarea,
  TextInput,
} from '@tw/ui-components';
import { useEffect, useState } from 'react';
import { WillyParameter } from './types/willyTypes';
import { BqColumn, OptionsObj } from 'pages/FreeQuery/dataStuff/columns/types';
import { useAppSelector } from 'reducers/RootType';
import { $dialect } from '$stores/$user';
import { $activeAccounts } from '$stores/$shop';
import { WillyFormGroup } from './WillyFormGroup';
import { executeCustomQuery } from './utils/willyUtils';
import { useStoreValue } from '@tw/snipestate';

type EditParameterProps = {
  open: boolean;
  parameter: WillyParameter;
  column?: BqColumn;
  options: OptionsObj[];
  onClose: () => void;
  parameterChanged: (parameter: WillyParameter) => Promise<void>;
};
export const EditParameter: React.FC<EditParameterProps> = ({
  open,
  parameter,
  column,
  options,
  onClose,
  parameterChanged,
}) => {
  const [editedParameter, setEditedParameter] = useState<WillyParameter>(parameter);
  const [testError, setTestError] = useState<string>();
  const [testData, setTestData] = useState<string[]>();
  const [testLoading, setTestLoading] = useState<boolean>();

  const shopTimezone = useAppSelector((state) => state.shopTimezone);
  const currentShopId = useAppSelector((state) => state.currentShopId);
  const currency = useAppSelector((state) => state.activeCurrency);
  const dialect = useStoreValue($dialect);
  const activeAccounts = useStoreValue($activeAccounts);

  useEffect(() => {
    setEditedParameter(parameter);
  }, [parameter]);

  return (
    <Modal opened={open} onClose={onClose} title="Edit parameter">
      <div className="flex flex-col gap-4">
        {!!column?.options?.length && (
          <WillyFormGroup>
            <Text size="xs" color="gray.6">
              Limit options for this parameter to a predefined list of values (default is all
              values)
            </Text>

            <MultiSelect
              value={editedParameter.options}
              data={options.map((o) => {
                return {
                  label: o.label,
                  value: o.value,
                  disabled: o.disabled,
                };
              })}
              onChange={(v) => {
                setEditedParameter((old) => ({
                  ...old,
                  options: v,
                }));
              }}
            />
          </WillyFormGroup>
        )}
        {!column?.options?.length && (
          <WillyFormGroup>
            <div className="flex flex-col gap-2">
              <Text size="sm" fw="bold">
                Options for this parameter
              </Text>
              <Text size="sm" color="gray.6">
                Options are used to provide a list of values that can be selected for this
                parameter.
              </Text>
            </div>
            <Button
              rightSection={<Icon name="plus-circle" />}
              variant="white"
              size="sm"
              onClick={() => {
                setEditedParameter((old) => {
                  const options = old.options || [];
                  return {
                    ...old,
                    options: [...options, ''],
                  };
                });
              }}
            >
              Add option
            </Button>
            <div className="flex items-center gap-1">
              {editedParameter.options
                ?.filter((x) => !!x)
                .map((o, i) => {
                  return (
                    <Badge variant="outline" key={o}>
                      {o}
                    </Badge>
                  );
                })}
            </div>
            {editedParameter.options?.map((o, i) => {
              return (
                <div className="flex items-center gap-2" key={i}>
                  <TextInput
                    value={o}
                    required
                    error={!o.trim() ? 'Option cannot be empty' : null}
                    onChange={(v) => {
                      setEditedParameter((old) => {
                        if (!old) {
                          return old;
                        }
                        const newOptions = old.options || [];
                        return {
                          ...old,
                          options: newOptions.map((x, j) => {
                            if (j === i) {
                              return v;
                            }
                            return x;
                          }),
                        };
                      });
                    }}
                  />
                  <ActionIcon
                    icon="delete"
                    onClick={() => {
                      setEditedParameter((old) => {
                        const newOptions = old.options || [];
                        return {
                          ...old,
                          options: newOptions.filter((x, j) => j !== i),
                        };
                      });
                    }}
                  />
                </div>
              );
            })}
          </WillyFormGroup>
        )}
        <WillyFormGroup>
          <Text size="sm" fw="bold">
            Populate options from a query (max 100 values)
          </Text>
          <Textarea
            minRows={7}
            maxRows={7}
            autosize
            value={editedParameter.query}
            onChange={(v) => setEditedParameter((old) => ({ ...old, query: v.target.value }))}
            placeholder="SELECT DISTINCT
            channel
          FROM
            pixel_orders_table
          WHERE
            event_date > DATE_SUB(CURRENT_DATE(), INTERVAL 90 day)
          GROUP BY
            channel"
          />
          <Button
            loading={testLoading}
            onClick={async () => {
              if (
                !editedParameter.query ||
                !activeAccounts ||
                !currency ||
                !dialect ||
                !shopTimezone
              ) {
                return;
              }

              setTestError(undefined);
              setTestData(undefined);
              setTestLoading(true);

              const data = await executeCustomQuery({
                query: editedParameter.query,
                activeAccounts,
                currency,
                dialect,
                shopId: currentShopId,
                timezone: shopTimezone,
              });
              setTestLoading(false);

              if (data.error || data.errorForInterface) {
                setTestError(data.error || data.errorForInterface);
                return;
              }

              setTestData(data.data?.[0]?.value?.map((o) => o?.toString()));
            }}
          >
            Test
          </Button>
          {testError && (
            <Text size="sm" color="red.6">
              {testError}
            </Text>
          )}
          {testData && <Select data={testData.map((o) => ({ label: o, value: o }))} />}
        </WillyFormGroup>
        <Modal.Footer>
          <div className="flex items-center justify-between gap-4">
            <Button variant="white" size="sm" onClick={onClose}>
              Cancel
            </Button>
            <Button
              disabled={!!testError || testLoading}
              variant="primary"
              size="sm"
              onClick={() => {
                parameterChanged(editedParameter);
                onClose();
              }}
            >
              Save
            </Button>
          </div>
        </Modal.Footer>
      </div>
    </Modal>
  );
};
