import { Flex, Icon, Popover, Text } from '@tw/ui-components';
import { selectedWorkspaceSelector } from 'ducks/auth';
import { setAddToWorkspaceInfo, setIsAgencyPod, setIsFromPodView } from 'ducks/signupJourney';
import { useIsSmall } from 'hooks/useDefaultWindowSizes';
import { useAppDispatch } from 'index';
import { useCallback, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { SignUpStep } from 'routes/auth/SignUp/signUpStep.enum';

export function AddNewShopButton() {
  const history = useHistory();
  const dispatch = useAppDispatch();
  const isSmall = useIsSmall();
  const [showIcon, setShowIcon] = useState(false);
  const selectedWorkspace = useSelector(selectedWorkspaceSelector);

  const addShopClick = useCallback(
    (isFree: boolean) => {
      if (isFree) {
        dispatch(setIsFromPodView(true));
        dispatch(setIsAgencyPod(!!selectedWorkspace.isAgency));
        dispatch(
          setAddToWorkspaceInfo({
            workspaceId: selectedWorkspace.id,
            isAdminOnWorkspace: selectedWorkspace?.roles?.includes('admin'),
          }),
        );
      }

      history.push({
        pathname: `/signup${isFree ? '-free' : ''}`,
        ...(!isFree && {
          state: {
            currentIndex: SignUpStep.ConnectStore,
            workspaceId: selectedWorkspace.id,
            isAdminOnWorkspace: selectedWorkspace?.roles?.includes('admin'),
            isFromAddStore: true,
            isAgency: !!selectedWorkspace.isAgency,
          },
        }),
      });
    },
    [dispatch, history, selectedWorkspace.id, selectedWorkspace.isAgency, selectedWorkspace?.roles],
  );

  const options = useMemo(
    () => [
      {
        label: 'Free Shop',
        onClick: () => addShopClick(true),
      },
      {
        label: 'Paid Shop',
        onClick: () => addShopClick(false),
      },
    ],
    [addShopClick],
  );

  return (
    <Popover
      width="dropdown"
      position={isSmall ? 'bottom-start' : 'right-start'}
      positionDependencies={[isSmall]}
      shadow="md"
      onChange={() => setShowIcon((x) => !x)}
    >
      <Popover.Target>
        <Flex justify="space-between" align="center" cursor="pointer">
          <Flex gap="sm" align="center">
            <Icon name="union" size={14} color="gray.6" />
            <Text fz="sm" fw={500} c="gray.7">
              Add new shop
            </Text>
          </Flex>
          {showIcon && <Icon name="caret-right" />}
        </Flex>
      </Popover.Target>
      <Popover.Dropdown bg="named.5" border="none" p={0} overflow="hidden">
        {options.map(({ label, onClick }) => (
          <div
            className="flex cursor-pointer justify-between items-center w-[208px] px-6 py-4 hover:bg-[--mantine-color-gray-2]"
            key={label}
            onClick={onClick}
          >
            <Text fz="sm">{label}</Text>
          </div>
        ))}
      </Popover.Dropdown>
    </Popover>
  );
}
