import { ExpressionElement, WillyCustomMetric } from 'components/Willy/types/willyTypes';
import { ExpressionBuilder } from './ExpressionBuilder';
import { useEffect, useState } from 'react';
import { validateExpressionElements } from '../utils';

export type CustomMetricBuilderType = {
  metric: WillyCustomMetric;
  isGlobal: boolean;
  setIsError: React.Dispatch<React.SetStateAction<boolean>>;
  setMetric: React.Dispatch<React.SetStateAction<WillyCustomMetric>>;
};

export const WillyCustomMetricBuilder: React.FC<CustomMetricBuilderType> = ({
  metric,
  setIsError,
  isGlobal,
  setMetric,
}) => {
  const [error, setError] = useState<string[]>([]);
  const [isDirty, setIsDirty] = useState(false);

  useEffect(() => {
    const [isValid, error] = validateExpressionElements(metric, isGlobal);
    if (!isValid && isDirty) {
      setError(error);
      setIsError(true);
    } else {
      setError([]);
      setIsError(false);
    }
  }, [isDirty, isGlobal, metric, setIsError]);

  return (
    <div>
      <div className="text-xl">
        1. Click on an element or drag it to the editor<br></br>
        2. Select a metric from the drop down<br></br>
        3. Add an operator<br></br>
        4. Add more element(s)<br></br>
        {error.length > 0 && <div className="text-red-500">Error:</div>}
        {error.map((e, i) => (
          <li key={i} className="text-red-500">
            {' '}
            {e}
          </li>
        ))}
      </div>
      <ExpressionBuilder
        isGlobal={metric.isGlobal}
        expression={metric.expression}
        onExpressionChanged={(expression: ExpressionElement[]) => {
          setMetric((old) => {
            return { ...old, expression };
          });
        }}
        setIsDirty={setIsDirty}
      ></ExpressionBuilder>
    </div>
  );
};
