import { IntegrationWidget } from '../../../../../routes/integrations/components/IntegrationWidget';
import React from 'react';
import { useIntegrationsConfig } from '../../../../../routes/integrations/hooks/useIntegrationsConfig';
import { ShopProviderStatusEnum } from '@tw/types/module/types/ShopProviders';

export const WillyActionProviderConnect: React.FC<{ data: any }> = ({ data }) => {
  const integrationsWidgets = useIntegrationsConfig();
  const { provider_id, operation } = data;
  const w = integrationsWidgets.find((w) => w.id === provider_id || w.serviceId === provider_id);
  if (!w) {
    return <div className={'text-3xl'}>Integration is not supported</div>;
  }

  return <IntegrationWidget {...w} />;
};
