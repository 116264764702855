import { $derived, $effect, $mutableDerived, $observer, $store } from '@tw/snipestate';
import { $currentShopId } from '$stores/$shop';
import _db, { toArray } from 'utils/DB';
import type { WillyDashboardElement } from 'components/Willy/types/willyTypes';
import { $globalDashboards, $loadingGlobalDashboards } from './$globalDashboards';
import { $userId, $userRoles, $isSimpleUser, $isTwGlobalDashboardCreatorClaim } from '../$user';
import { $favoriteDashboards } from './$favoriteDashboards';
import { WillyElementType } from 'components/Willy/types/willyTypes';
import { DashboardPermissionsManager } from 'components/Willy/dashboardManagment/permissions-management/DashboardPermissionsManager';
import { $ffStore } from 'feature-flag-system';
import { FeatureFlag } from '@tw/feature-flag-system/module/types';
import { $sqlTeamReportCredit } from './$subscription';
import {
  LimitedAccess,
  // limitedAccessRanks,
} from 'components/Nav/components/navs/AlanNav/LimitedAccessTooltip';

const $shopDashboardsSnapshot = $observer(
  { loading: true, data: [] as WillyDashboardElement[] },
  (get, set) => {
    const shopId = get($currentShopId);
    const userId = get($userId);
    if (!shopId || !userId) return;

    return _db(shopId)
      .collection('willy_dashboards')
      .onSnapshot((querySnapshot) => {
        set({
          loading: false,
          data: toArray(querySnapshot).sort((a, b) => a.order - b.order),
        });
      });
  },
);

const $snapshotDone = $store<number | null>(null);
export const $shopDashboards = $observer<WillyDashboardElement[]>([], (get, set) => {
  const userId = get($userId);
  if (!userId) return;

  const { loading, data: snapshotData } = get($shopDashboardsSnapshot);
  const loadingGlobalDashes = get($loadingGlobalDashboards);
  if (loading || loadingGlobalDashes) return;

  const globalDashboards = get($globalDashboards);
  const userRoles = get($userRoles);
  const favoriteDashboards = get($favoriteDashboards);

  const data = snapshotData.reduce((acc, x) => {
    let isLocked = false;
    let isProviderLocked = false;

    const globalDashboard =
      (!!x.globalDashboardId && globalDashboards.find((d) => d.id === x.globalDashboardId)) ??
      false;

    if (globalDashboard) {
      isLocked = globalDashboard.isLocked ?? false;
      isProviderLocked = globalDashboard.isProviderLocked ?? false;
    }
    if (isProviderLocked) return acc;

    const perms = DashboardPermissionsManager.computeDashPerms(
      x.generalAccess?.type,
      x.generalAccess?.permission,
      x.userPermissions,
      userRoles,
      userId,
    );
    acc.push({
      ...x,
      isLocked,
      isProviderLocked,
      canView: perms.canView,
      canEdit: perms.canEdit,
      canDelete: perms.canDelete,
      isGlobal: false,
      type: 'dashboard' as WillyElementType,
      isFavorite: favoriteDashboards.includes(x.id),
      isCustomView: false,
    });
    return acc;
  }, [] as WillyDashboardElement[]);

  set(data);
  if ($snapshotDone.get() === null && !loading) {
    $snapshotDone.set(data.length);
  }
});

export const $dashboardIds = $derived((get) => new Set(get($shopDashboards).map((d) => d.id)));
export const $shopDashesLoading = $store(true);
$effect((unsub, get) => {
  const snapDone = get($snapshotDone);
  const dashIds = get($dashboardIds);
  if (snapDone !== null && snapDone === dashIds.size) {
    unsub();
    $shopDashesLoading.set(false);
  }
});

export const $dashboardCredits = $derived((get) => {
  const { numericLimit } = get($ffStore).getConfigById(FeatureFlag.DASHBOARDS_COUNT_FF);
  const sqlTeamReportCredits = get($sqlTeamReportCredit);
  const credits = numericLimit ?? 0;
  const usedCredits = get($shopDashboards).reduce(
    (acc, d) => acc + (d.isSqlTeamReport ? sqlTeamReportCredits : d.credits ?? 0),
    0,
  );

  return {
    credits: credits,
    creditsLeft: Math.max(0, credits - usedCredits),
    creditsUsed: usedCredits,
  };
});

export const $creditsLeft = $derived((get) => get($dashboardCredits).creditsLeft);
export const $creditsLeftInfinity = $derived((get) => get($creditsLeft) === Infinity);

export const $createDashboardAccess = $derived((get): LimitedAccess => {
  if (get($isSimpleUser)) return 'no-access';
  if (!get($creditsLeft)) return 'no-credits';
  return 'can-access';
});
//@ts-ignore - function to quickly toggle access states - please make sure to comment before deploying. $createDashboardAccess needs to be $mutableDerived as well
// window.test = () =>
//   $createDashboardAccess.set((x) => {
//     const index = limitedAccessRanks[x];
//     const reversedMap = Object.entries(limitedAccessRanks).map(([k, v]) => [v, k]);
//     return Object.fromEntries(reversedMap)[(index + 1) % 3];
//   });

export const $addTemplateAccess = $derived((get) => {
  if (get($isTwGlobalDashboardCreatorClaim)) return 'can-access';

  const canCreate = get($createDashboardAccess);
  if (['can-access', 'no-access'].includes(canCreate)) return canCreate;

  return !get($creditsLeft) ? 'no-credits' : 'can-access';
});
//@ts-ignore
// window.test2 = () =>
//   $addTemplateAccess.set((x) => {
//     const index = limitedAccessRanks[x];
//     const reversedMap = Object.entries(limitedAccessRanks).map(([k, v]) => [v, k]);
//     return Object.fromEntries(reversedMap)[(index + 1) % 3];
//   });

export const $canCreateDashboard = $derived((get) => {
  const createDashboardAccess = get($createDashboardAccess);
  return createDashboardAccess === 'can-access';
});

export const $canCreateAndMoveFolders = $derived((get): LimitedAccess => {
  if (get($isSimpleUser)) return 'no-access';
  return 'can-access';
});
