import { LighthouseNotificationTypes } from '@tw/types/module/types/lighthouse';
import firebase from 'firebase/compat/app';
import { type AppDispatch } from 'index';
import { type RootState } from 'reducers/RootType';
import { combineReducers, Reducer } from 'redux';
import { getNotificationSubtopic } from '@tw/helpers/module/notifications';
import axiosInstance from 'utils/axiosInstance';
import moment from '@tw/moment-cached/module/timezone';
import { remove } from 'lodash';

import {
  LIGHTHOUSE_TYPES_TO_SHOW,
  LighthouseClientNotification,
  LighthouseClientNotificationGroup,
  LighthouseNotificationGroup,
  LighthouseSettings,
} from 'components/Lighthouse/types';
import { LighthouseTypesDictionary } from 'utils/lighthouse';

import {
  GENERATIVE_AI_POPUP_CLOSED,
  LIGHTHOUSE_HAS_MORE_NOTIFICATIONS,
  LIGHTHOUSE_MARK_AS_READ,
  LIGHTHOUSE_DELETE_NOTIFICATION,
  LIGHTHOUSE_ARCHIVE_NOTIFICATION,
  LIGHTHOUSE_SETTINGS_CHANGE,
  LIGHTHOUSE_CLOSE,
  LIGHTHOUSE_OPEN,
  LIGHTHOUSE_POPUP_OPEN,
  LIGHTHOUSE_POPUP_CLOSE,
  LIGHTHOUSE_TAB_CHANGE,
  LIGHTHOUSE_ANOMALY_THRESHOLD_CHANGE,
  LIGHTHOUSE_LOAD_NEW_NOTIFICATIONS,
  LIGHTHOUSE_SET_NEW_NOTIFICATIONS,
  LIGHTHOUSE_FETCH_NOTIFICATIONS,
  LIGHTHOUSE_NOTIFICATIONS_RECEIVED,
  LIGHTHOUSE_REAL_TIME_NOTIFICATIONS_RECEIVED,
  GET_CURRENT_USER,
  LIGHTHOUSE_INVENTORY_REVENUE_THRESHOLD_CHANGE,
  INIT_SHOP,
} from 'ducks/constants';
import { GET_SHOP_SUBSCRIPTION } from './shop';
import { genericEventLogger, analyticsEvents, lighthouseActions } from 'utils/dataLayer';

export const lighthouseClose = (shopId) => {
  genericEventLogger(analyticsEvents.LIGHTHOUSE, {
    action: lighthouseActions.CLOSE,
    shop: shopId,
  });

  return {
    type: LIGHTHOUSE_CLOSE,
  };
};

export const lighthouseOpen = (shopId) => {
  genericEventLogger(analyticsEvents.LIGHTHOUSE, {
    action: lighthouseActions.OPEN,
    shop: shopId,
  });

  return async (dispatch: AppDispatch, getState: () => RootState) => {
    const { lighthouse } = getState();
    lighthouse.notifications?.forEach((item) => {
      genericEventLogger(analyticsEvents.LIGHTHOUSE, {
        action: lighthouseActions.NOTIFICATION_IMPRESSION,
        category: item.type,
        date: item.date,
        title: item.notifications[0].title,
      });
    });

    dispatch({
      type: LIGHTHOUSE_OPEN,
    });
  };
};

export const lighthouseFetchNotifications = (
  page: number,
  shopId: string,
  // types: LighthouseNotificationTypes[]
) => {
  return async (dispatch: AppDispatch, getState: () => RootState) => {
    const { currency, shopTimezone } = getState();
    const { lighthouseNotificationSettings } = getState().notificationSettings;
    let notificationTypesToGet = Object.keys(lighthouseNotificationSettings);
    notificationTypesToGet = remove(notificationTypesToGet, (topic) => topic !== 'generative');
    dispatch({
      type: LIGHTHOUSE_FETCH_NOTIFICATIONS,
    });
    const { data } = await axiosInstance.post<LighthouseNotificationGroup[]>(
      '/v2/lighthouse/get-notifications',
      {
        shopId: shopId,
        page,
        types: notificationTypesToGet,
        timezone: shopTimezone,
      },
    );

    if (!data) {
      return;
    }

    let unread = 0;

    let newItems: LighthouseClientNotificationGroup[] = data
      .map((group) => {
        const filteredNotification = group?.notifications?.filter((x) => {
          const subtopic = getNotificationSubtopic(x);
          return !!lighthouseNotificationSettings[x?.type][subtopic];
        });
        if (filteredNotification.length > 0) {
          return {
            notifications:
              filteredNotification?.map((x) => {
                if (!x.read) {
                  unread++;
                }
                return LighthouseTypesDictionary[group.type]?.transformer(x, { currency });
              }) || [],
            type: group.type,
            date: group.date,
          };
        } else {
          return [];
        }
      })
      .flat();

    newItems = newItems
      .sort((group1, group2) => moment(group2.date).valueOf() - moment(group1.date).valueOf())
      .map((x, i, arr) => ({
        ...x,
        isFirstInDate: i === 0 || !moment(x.date).isSame(arr[i - 1].date, 'day'),
      }));

    dispatch({
      type: LIGHTHOUSE_NOTIFICATIONS_RECEIVED,
      payload: { newItems, shouldConcat: page > 0 },
    });

    if (data?.length < 20) {
      dispatch({
        type: LIGHTHOUSE_HAS_MORE_NOTIFICATIONS,
        payload: false,
      });
    } else {
      dispatch({
        type: LIGHTHOUSE_HAS_MORE_NOTIFICATIONS,
        payload: true,
      });
    }
    dispatch(lighthouseSetNewNotifications(unread));
  };
};

// export const fetchNewNotificationsCount = (
//   shopId: string
//   // types: LighthouseNotificationTypes[]
// ) => {
//   return async (dispatch: AppDispatch, getState: () => RootState) => {
//     const { lighthouseNotificationSettings } = getState().notificationSettings;
//     const notificationTypesToGet = Object.keys(lighthouseNotificationSettings);
//     dispatch({
//       type: LIGHTHOUSE_LOAD_NEW_NOTIFICATIONS,
//     });
//     const { data } = await axiosInstance.post<any>(
//       '/v2/lighthouse/get-unread-notifications-count',
//       {
//         shopId,
//         types: notificationTypesToGet,
//         // types,
//       }
//     );
//     const count = data.totalUnreadCount;
//     dispatch(lighthouseSetNewNotifications(count));
//   };
// };

export const lighthouseMarkNotificationAsArchived = (
  notification: LighthouseClientNotification,
) => {
  return async (dispatch: AppDispatch, getState: () => RootState) => {
    const { currentShopId } = getState();
    if (!currentShopId) return;

    const url = `/v2/lighthouse/mark-notification-as-archived`;
    try {
      dispatch({
        type: LIGHTHOUSE_ARCHIVE_NOTIFICATION,
        payload: notification,
      });
      await axiosInstance.post(url, {
        shopId: currentShopId,
        id: notification.docId,
      });
    } catch (error) {
      console.error(error);
    }
  };
};

export const lighthouseDeleteNotification = (notification: LighthouseClientNotification) => {
  return async (dispatch: AppDispatch, getState: () => RootState) => {
    const { currentShopId } = getState();
    if (!currentShopId) return;

    const url = `/v2/lighthouse/delete-notification/${currentShopId}/${notification.docId}`;
    try {
      await axiosInstance.delete(url);
      dispatch({
        type: LIGHTHOUSE_DELETE_NOTIFICATION,
        payload: notification,
      });
    } catch (error) {
      console.error(error);
    }
  };
};

export const lighthouseAnomalyThresholdChange = (threshold: number = 1) => {
  const normalizedThreshold = 70 + threshold * 5;
  return async (dispatch: AppDispatch, getState: () => RootState) => {
    const { user } = getState();
    try {
      await firebase
        .firestore()
        .collection('users')
        .doc(user.uid)
        .set({ lighthouse: { anomaly: { threshold: normalizedThreshold } } }, { merge: true });
    } catch (error) {
      console.error(error);
    }
    dispatch({
      type: LIGHTHOUSE_ANOMALY_THRESHOLD_CHANGE,
      payload: normalizedThreshold,
    });
  };
};

export const lighthouseInventoryRevenueThresholdChange = (threshold: number) => {
  return async (dispatch: AppDispatch, getState: () => RootState) => {
    const { user } = getState();
    try {
      await firebase
        .firestore()
        .collection('users')
        .doc(user.uid)
        .set({ lighthouse: { inventory: { revenueThreshold: threshold } } }, { merge: true });
    } catch (error) {
      console.error(error);
    }
    dispatch({
      type: LIGHTHOUSE_INVENTORY_REVENUE_THRESHOLD_CHANGE,
      payload: threshold,
    });
  };
};

export const lighthouseSettingsChange = (settings: LighthouseSettings) => {
  return async (dispatch: AppDispatch, getState: () => RootState) => {
    const { user } = getState();
    dispatch({
      type: LIGHTHOUSE_SETTINGS_CHANGE,
      payload: settings,
    });
    try {
      await firebase
        .firestore()
        .collection('users')
        .doc(user.uid)
        .set({ lighthouse: { settings } }, { merge: true });
    } catch (error) {
      console.error(error);
    }
  };
};

// export const lighthouseTabChange = (tabs: LighthouseNotificationTypes[]) => {
//   return {
//     type: LIGHTHOUSE_TAB_CHANGE,
//     payload: tabs,
//   };
// };

export const lighthousePopupOpen = (item: any) => {
  return async (dispatch: AppDispatch, getState: () => RootState) => {
    const { currentShopId } = getState();
    genericEventLogger(analyticsEvents.LIGHTHOUSE, {
      action: lighthouseActions.NOTIFICATION_CLICK,
      shop: currentShopId,
      category: item.type,
    });
    const notification = LighthouseTypesDictionary[item.type];
    if (notification.link && notification.noPopup) {
      window.location.replace(`${notification.link}?lighthouse=1`);
    } else {
      dispatch({
        type: LIGHTHOUSE_POPUP_OPEN,
        payload: item,
      });
    }

    if (item.read) return;
    const url = '/v2/lighthouse/mark-notification-as-read';
    const params = {
      shopId: currentShopId,
      id: item.docId,
    };

    try {
      await axiosInstance.post(url, params);
      dispatch({
        type: LIGHTHOUSE_MARK_AS_READ,
        payload: item,
      });
    } catch (error) {
      console.error(error);
    }
  };
};

export const lighthousePopupClose = () => {
  return {
    type: LIGHTHOUSE_POPUP_CLOSE,
  };
};

export const lighthouseSetNewNotifications = (count: number) => {
  return {
    type: LIGHTHOUSE_SET_NEW_NOTIFICATIONS,
    payload: count,
  };
};

const isLighthouseOpen = (state = false, action) => {
  switch (action.type) {
    case LIGHTHOUSE_OPEN:
      return true;
    case LIGHTHOUSE_CLOSE:
      return false;
    default:
      return state;
  }
};

const isPopupOpen = (state = false, action) => {
  switch (action.type) {
    case LIGHTHOUSE_POPUP_OPEN:
      return true;
    case LIGHTHOUSE_POPUP_CLOSE:
    case GENERATIVE_AI_POPUP_CLOSED:
      return false;
    default:
      return state;
  }
};

const popupItem = (state = null, action) => {
  switch (action.type) {
    case LIGHTHOUSE_POPUP_OPEN:
      return action.payload;
    case LIGHTHOUSE_POPUP_CLOSE:
      return null;
    default:
      return state;
  }
};

const lighthouseAnomalyThreshold: Reducer<number> = (state = 1, action) => {
  switch (action.type) {
    case INIT_SHOP:
      const { notificationSettings } = action;
      const a = notificationSettings?.anomaly?.threshold
        ? (notificationSettings.anomaly.threshold - 70) / 5
        : state;
      return a;
    case LIGHTHOUSE_ANOMALY_THRESHOLD_CHANGE:
      return action.payload ? (action.payload - 70) / 5 : state;
    default:
      return state;
  }
};

const lighthouseInventoryRevenueThreshold: Reducer<number> = (state = 5, action) => {
  switch (action.type) {
    case INIT_SHOP:
      const { notificationSettings } = action;
      return notificationSettings?.inventory?.threshold ?? state;
    case LIGHTHOUSE_INVENTORY_REVENUE_THRESHOLD_CHANGE:
      return action.payload;
    default:
      return state;
  }
};

const lighthouseSettings: Reducer<LighthouseSettings> = (state = {}, action) => {
  switch (action.type) {
    case GET_CURRENT_USER:
      const { lighthouse } = action.user;
      return lighthouse?.settings ?? state;
    case LIGHTHOUSE_SETTINGS_CHANGE:
      return action.payload;
    default:
      return state;
  }
};

// const tabs: Reducer<LighthouseNotificationTypes[]> = (
//   state = Object.values(LighthouseTypesDictionary).map((item) => item.key),
//   action
// ) => {
//   switch (action.type) {
//     case INIT_SHOP:
//       const notificationSettings = action.notificationSettings;
//       const defaultTabs = [...state];
//       state.map((tab, i) => {
//         if (notificationSettings[tab]) {
//         }
//       });
//       return state;
//     // case GET_CURRENT_USER:
//     //   return JSON.parse(localStorage.getItem(LIGHTHOUSE_TYPES_TO_SHOW)!) || state;
//     // case LIGHTHOUSE_TAB_CHANGE:
//     //   return action.payload;
//     case GET_SHOP_SUBSCRIPTION:
//       const { features = [] } = action.payload || {};
//       const tabs = state.filter((tab) => {
//         const existTabs = LighthouseTypesDictionary[tab]?.dependOnFeatures?.filter(
//           (feature) => !features.includes(feature)
//         );
//         return !existTabs?.length;
//       });
//       return tabs ?? state;
//     default:
//       return state;
//   }
// };

const loadingNewNotificationsCount = (state = false, action) => {
  switch (action.type) {
    case LIGHTHOUSE_LOAD_NEW_NOTIFICATIONS:
      return true;
    case LIGHTHOUSE_SET_NEW_NOTIFICATIONS:
      return false;
    default:
      return state;
  }
};

const newNotificationsCount = (state = 0, action) => {
  switch (action.type) {
    case LIGHTHOUSE_POPUP_OPEN:
      if (action.payload.read) return state;
      return state - 1;
    case LIGHTHOUSE_DELETE_NOTIFICATION:
    case LIGHTHOUSE_ARCHIVE_NOTIFICATION:
      if (action.payload.read) return state;
      return state - 1;
    case LIGHTHOUSE_SET_NEW_NOTIFICATIONS:
      return (action.payload as number) ?? state;
    default:
      return state;
  }
};

const notifications: Reducer<LighthouseClientNotificationGroup[]> = (state = [], action) => {
  switch (action.type) {
    case LIGHTHOUSE_NOTIFICATIONS_RECEIVED:
      const { shouldConcat, newItems = [] } = action.payload;
      if (shouldConcat) {
        return [...state, ...newItems.filter((item) => item.type !== 'timing')];
      }
      return newItems;
    case LIGHTHOUSE_REAL_TIME_NOTIFICATIONS_RECEIVED:
      return [action.payload, ...state];
    case LIGHTHOUSE_MARK_AS_READ:
      state.forEach((group) => {
        group.notifications.forEach((notification) => {
          if (notification.id === action.payload.id) notification.read = true;
        });
      });
      return [...state];
    case LIGHTHOUSE_DELETE_NOTIFICATION:
    case LIGHTHOUSE_ARCHIVE_NOTIFICATION:
      state.map(
        (group) =>
          (group.notifications = group.notifications.filter(
            (notification) => notification.id !== action.payload.id,
          )),
      );
      return [...state];
    default:
      return state;
  }
};

const hasMoreNotifications = (state = true, action) => {
  switch (action.type) {
    case LIGHTHOUSE_HAS_MORE_NOTIFICATIONS:
      return action.payload;
    default:
      return state;
  }
};

const loadingNotifications = (state = false, action) => {
  switch (action.type) {
    case LIGHTHOUSE_FETCH_NOTIFICATIONS:
      return true;
    case LIGHTHOUSE_NOTIFICATIONS_RECEIVED:
      return false;
    default:
      return state;
  }
};

export const reducers = combineReducers({
  isLighthouseOpen,
  isPopupOpen,
  popupItem,
  lighthouseAnomalyThreshold,
  lighthouseInventoryRevenueThreshold,
  newNotificationsCount,
  notifications,
  hasMoreNotifications,
  loadingNotifications,
  loadingNewNotificationsCount,
  lighthouseSettings,
});
