import { useAppDispatch } from 'index';
import moment from '@tw/moment-cached/module/timezone';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Button, DatePicker, OptionList, Range } from '@shopify/polaris';
import { Button as TwButton, Popover as TwPopover } from '@tw/ui-components';

import { useFeatureFlag } from 'feature-flag-system';
import { FeatureFlag } from '@tw/feature-flag-system/module/types';

import { RangeWithId } from 'types/general';

import { Moment } from '@tw/moment-cached';
import {
  getDatePickerOptionValueOptions,
  DatePickerTimePeriods,
  optionsWithActive,
} from '../useDatePickerSelectedOptions';
import { useEarliestDate } from '../useEarliestDate';
import LockedFeatureIndicator from '../library/LockedFeatures/LockedFeatureIndicator';
import { getPopoverOpenerButtonTitle } from '../useGetPopoverOpenerButtonTitle';
import { CurrentDateRange } from '../../$stores/willy/$dateRange';

type WillyCurrentDatePickerProps = {
  earliestDate: Date;
  timezone: string;
  selectedOption?: DatePickerTimePeriods;
  onValueChanged: (option: CurrentDateRange) => void;
  start: Moment;
  end: Moment;
  disabled?: boolean;
};

export const WillyCurrentDatePicker: React.FC<WillyCurrentDatePickerProps> = ({
  earliestDate,
  timezone,
  selectedOption,
  onValueChanged,
  start,
  end,
  disabled,
}) => {
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const [year, setYear] = useState<number>(moment().year());
  const [month, setMonth] = useState<number>(moment().month());
  const [datePickerValue, setDatePickerValue] = useState<RangeWithId | null>(null);

  useEffect(() => {
    setDatePickerValue({ start: start.toDate(), end: end.toDate() });
  }, [start, end]);

  const earliestDateForDisplay = useEarliestDate(earliestDate);

  const { numericLimit: datePickerLimitation } = useFeatureFlag(FeatureFlag.LIMIT_LOOKBACK_FF);

  const currentPopoverLabel = useMemo(() => {
    const option = optionsWithActive(selectedOption).find((o) => {
      return o.value.id === selectedOption;
    });
    return option?.label || getPopoverOpenerButtonTitle({ start, end });
  }, [end, selectedOption, start]);

  const options = useMemo(() => {
    let options = optionsWithActive(selectedOption);
    if (earliestDateForDisplay && datePickerLimitation) {
      const earliestDateFreeFeature = moment()
        .subtract(datePickerLimitation, 'month')
        .startOf('day');
      options = options.map((e) => {
        return { ...e, disabled: earliestDateFreeFeature?.isAfter(e.value.start) };
      });
    }
    return options;
  }, [datePickerLimitation, earliestDateForDisplay, selectedOption]);

  const _onSelectionChange = useCallback((_val: Range) => {
    setDatePickerValue(_val);
  }, []);

  const _onApply = useCallback(() => {
    let { start, end } = datePickerValue || {};
    start = moment()
      .year(start!.getFullYear())
      .month(start!.getMonth())
      .date(start!.getDate())
      .startOf('day') as any;
    end = moment()
      .year(end!.getFullYear())
      .month(end!.getMonth())
      .date(end!.getDate())
      .endOf('day') as any;

    // highlight the relevant option if current selection much one of them
    const e = moment(end);
    const option = options.find((o) => {
      return (
        (o.value.start.isSame(start) && o.value.end.isSame(end)) ||
        (o.value.start.isSame(start) && o.value.end.isSame(e.startOf('day')))
      );
    });

    onValueChanged({
      id: option?.value?.id ?? DatePickerTimePeriods.CUSTOM,
      start: moment(start),
      end: moment(end),
    });

    setIsPopoverOpen((x) => !x);
  }, [datePickerValue, onValueChanged, options]);

  const onOptionSelect = useCallback(
    (val: DatePickerTimePeriods) => {
      const datePickerOptionValueOptions = getDatePickerOptionValueOptions();
      const { start, end, id } = datePickerOptionValueOptions.find((o) => o.id === val)!;
      onValueChanged({ id, start, end });
      setIsPopoverOpen((x) => !x);
    },
    [onValueChanged],
  );

  const datePickerOptionValueOptions = useMemo(() => getDatePickerOptionValueOptions(), []);
  return (
    <div className={`flex gap-4 items-center`}>
      <TwPopover
        shadow="sm"
        opened={isPopoverOpen}
        position="bottom-start"
        onClose={() => setIsPopoverOpen(false)}
      >
        <TwPopover.Target>
          <span>
            <TwButton
              variant="activator"
              onClick={() => setIsPopoverOpen((x) => !x)}
              leftSection="calendar-1"
              rightSection="caret-down"
              disabled={disabled}
            >
              {currentPopoverLabel}
            </TwButton>
          </span>
        </TwPopover.Target>
        <TwPopover.Dropdown bg="white" fz="sm" p="xs" mah="55vh" overflow="scroll">
          <div
            className="flex flex-col sm:flex-row gap-20 justify-between"
            aria-label="Date Selections"
            style={{ maxWidth: '800px' }}
          >
            <div className="flex gap-4 flex-col" style={{ maxWidth: '370px' }}>
              <div className="max-h-[230px] sm:max-h-none overflow-auto">
                <OptionList
                  onChange={(updated) => {
                    onOptionSelect(updated[0] as any);
                  }}
                  options={datePickerOptionValueOptions.map((o) => ({
                    value: o.id,
                    label: o.label,
                  }))}
                  selected={[selectedOption ?? '']}
                />
              </div>
              {!!datePickerLimitation && (
                <LockedFeatureIndicator
                  featureFlag={FeatureFlag.LIMIT_LOOKBACK_FF}
                  layout="vertical"
                  extraData={{ targetToUnlock: 12 }}
                />
              )}
              <DatePicker
                multiMonth={false}
                month={month}
                year={year}
                onChange={(date) => {
                  _onSelectionChange(date);
                }}
                onMonthChange={(month, year) => {
                  setMonth(month);
                  setYear(year);
                }}
                selected={datePickerValue!}
                allowRange
                disableDatesAfter={
                  new Date(
                    moment().toDate().toLocaleString('en-US', {
                      timeZone: moment().tz(),
                    }),
                  )
                }
                disableDatesBefore={earliestDateForDisplay.toDate()}
              />
              <div className="flex flex-wrap justify-between items-end">
                <div>
                  <span
                    style={{ display: 'block', color: 'var(--p-text-subdued)', fontSize: '10px' }}
                  >
                    Timezone: {timezone?.replace(/_/g, ' ')}
                  </span>
                </div>
                <div className="flex items-center gap-4">
                  <Button primary onClick={() => _onApply()} id="tr-date-picker-apply">
                    Apply
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </TwPopover.Dropdown>
      </TwPopover>
    </div>
  );
};
