import { posthog } from 'posthog-js';
import { setCurrentCustomUiIfNotExist, summarySectionsOrderChanged } from 'ducks/summary';
import { useAppDispatch } from 'index';
import React, { Fragment, Suspense, useCallback, useEffect, useRef, useState } from 'react';
import lazyWithRetries from 'utils/lazyWithRetries';
import RenderIfVisible from 'react-render-if-visible';
import { useSelector } from 'react-redux';
import { type RootState } from 'reducers/RootType';
import {
  SummarySection as Section,
  SummarySectionIds,
  SummarySectionTypes,
} from 'types/SummarySection';

// import { selectAllSections } from 'utils/selectors';
import {
  closestCenter,
  DndContext,
  DragEndEvent,
  DragOverlay,
  DragStartEvent,
  KeyboardSensor,
  PointerSensor,
  TouchSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import { restrictToVerticalAxis } from '@dnd-kit/modifiers';
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import { SummaryMetricIdsTypes } from '@tw/types/module/SummaryMetrics';
import { onboardingResponse } from '@tw/types/module/services/subscription-manager';
import { isInChromeExtensionIframe, isInIframe } from 'config';
// import ImportingServicesIndicator from './ImportingServicesIndicator';
import ProgressBar from './onboarding/ProgressBar';
import TripleWhaleIconBase64 from './TripleWhaleIconBase64';
import { selectSummaryPinnedSectionTiles } from 'utils/selectors';
import { Button, Icon, SkeletonBodyText, SkeletonThumbnail } from '@shopify/polaris';
import { ArrowUpMinor } from '@shopify/polaris-icons';
import { appLink } from '@tw/constants';
import { statsHiddenTiles, statsTiles } from '../constants/defaultTileSections';
import { getDefaultShownTiles } from 'utils/getDefaultTiles';
import { useIsSmall } from 'hooks/useDefaultWindowSizes';
import { useStoreValue } from '@tw/snipestate';
import { $activeAppVersion } from '../$stores/nav-config-stores';

export const DraggedWidget: React.FC = (props) => {
  return (
    <div className="rounded p-6.5 flex flex-col gap-10">
      <div className="flex-auto">
        <SkeletonBodyText lines={4} />
      </div>
      <div className="flex-auto">
        <div className="flex gap-6.5 justify-between">
          <div className="flex-auto">
            <SkeletonThumbnail size="medium" />
          </div>
          <div className="flex-auto">
            <SkeletonThumbnail size="medium" />
          </div>
          <div className="flex-auto">
            <SkeletonThumbnail size="medium" />
          </div>
        </div>
      </div>
      <div className="flex-auto">
        <SkeletonBodyText lines={4} />
      </div>
    </div>
  );
};

const TileSection = lazyWithRetries(() => import('./TileSection'));
const FallbackTile = <DraggedWidget />;
const defaultHeight = 150;
const sectionHeaderHeight = 0;

type SummarySectionProps = { sections: Section[]; type: SummarySectionTypes };

const SummarySection: React.FC<SummarySectionProps> = ({ sections, type }) => {
  const dispatch = useAppDispatch();

  const isFreeShop = useSelector((state: RootState) => state.isFreeShop);
  const isSmall = useIsSmall();
  const activeAppVersion = useStoreValue($activeAppVersion);
  const isThreePointOh = activeAppVersion === '3.0';
  const user = useSelector((state: RootState) => state.user);
  const summarySectionsCustomization = useSelector(
    (state: RootState) => state.summarySectionsCustomization,
  );
  const productsDisabled = useSelector((state: RootState) => state.productsDisabled);

  const summaryPinnedTiles = useSelector(selectSummaryPinnedSectionTiles);
  const hasLighthouse = useSelector((state: RootState) => state.hasLighthouse);

  const onboarding = useSelector((state: RootState) => state.onboarding as onboardingResponse);

  const [activeId, setActiveId] = useState<SummarySectionIds | null>();
  const [sectionsToShow, setSectionsToShow] = useState<Section[]>([]);
  // const [activeSection, setActiveSection] = useState<number | null>();
  const [isScrolled, setIsScrolled] = useState<boolean>(false);

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(TouchSensor, { activationConstraint: { tolerance: 5, delay: 250 } }),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    }),
  );

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  useEffect(() => {
    if (Object.keys(summarySectionsCustomization).length) return;
    dispatch(setCurrentCustomUiIfNotExist(user?.shops));
  }, [dispatch, user?.shops, summarySectionsCustomization]);

  const handleScroll = () => {
    setIsScrolled(window.scrollY ? true : false);
  };

  // const ALL_SECTIONS = SECTIONS.concat(FINANCE_SECTIONS as any);
  const activeSectionTiles = useCallback(() => {
    const section = sectionsToShow.find((s) => s.id === activeId);
    const defaultSection = sectionsToShow.find((s) => s.id === activeId);
    const isCustom = !defaultSection;

    let tiles: SummaryMetricIdsTypes[] | null | undefined =
      summarySectionsCustomization[activeId!]?.tiles || null;

    if (activeId === 'pinned') {
      tiles = summaryPinnedTiles;
    }

    if (!tiles && !section?.isHideTilesByDefault) {
      tiles =
        isCustom || section?.isDynamicSection
          ? section?.tiles || []
          : getDefaultShownTiles(isFreeShop, defaultSection);
    }

    return tiles;
  }, [activeId, sectionsToShow, summaryPinnedTiles, summarySectionsCustomization, isFreeShop]);

  const handleDragStart = (event: DragStartEvent) => {
    setActiveId(event.active.id.toString() as SummarySectionIds);
  };

  const handleDragEnd = (event: DragEndEvent) => {
    const { active, over } = event;
    if (active.id !== over?.id) {
      const oldIndex = sectionsToShow.findIndex((s) => s.id === active.id);
      const newIndex = sectionsToShow.findIndex((s) => s.id === over?.id);
      if (oldIndex === -1 || newIndex === -1) {
        return;
      }
      const newOrderedSections = arrayMove(sectionsToShow, oldIndex, newIndex);
      setActiveId(null);
      dispatch(summarySectionsOrderChanged(newOrderedSections));
    }
  };

  useEffect(() => {
    const sec = sections.filter((s) => {
      const { id } = s;

      if (id !== 'lighthouse') return true;

      return !!(hasLighthouse && id === 'lighthouse');
    });

    setSectionsToShow(sec);
  }, [hasLighthouse, sections]);

  if (isInIframe) {
    return (
      <RenderIfVisible defaultHeight={defaultHeight} stayRendered={true}>
        <Suspense fallback={FallbackTile}>
          <TileSection
            defaultSectionId="pinned"
            icons={isInChromeExtensionIframe ? [TripleWhaleIconBase64] : []}
            title={isInChromeExtensionIframe ? 'Pinned on TripleWhale' : ''}
            hideSettings={true}
            emptySectionTitle="Section is empty"
            emptySectionLabel="Let's pin some tiles on TripleWhale now!"
            emptySectionAction={{
              content: 'Go to TripleWhale.com',
              url: appLink,
              external: true,
            }}
            emptySectionSecondaryAction={{
              content: 'Done? Click to refresh',
              onAction: () => window.location.reload(),
            }}
          />
        </Suspense>
      </RenderIfVisible>
    );
  }

  return (
    <Fragment>
      {onboarding.completePercentage > 0 &&
      onboarding.completePercentage < 80 &&
      !localStorage.hideOptimization &&
      type === 'summary' &&
      !isFreeShop &&
      !isThreePointOh ? (
        <ProgressBar
          completeCount={onboarding.completeCount}
          completePercentage={onboarding.completePercentage}
          taskCount={onboarding.taskCount}
          fromSummary
        />
      ) : null}
      <DndContext
        onDragEnd={(e) => {
          handleDragEnd(e);
        }}
        sensors={sensors}
        modifiers={[restrictToVerticalAxis]}
        collisionDetection={closestCenter}
        onDragStart={handleDragStart}
      >
        <div>
          <SortableContext items={sectionsToShow ?? []} strategy={verticalListSortingStrategy}>
            {sectionsToShow?.map((section, i) => {
              const {
                FilterBar,
                id,
                title,
                isDynamicSection,
                isExternalApp,
                isHideTilesByDefault,
                invalidConnectionShopField,
                onDeleteSection = null,
                invalidConnectionReasonShopField,
                widgets,
                disabledEditSection,
                addNewBox,
                emptySectionTitle,
                icons,
                tooltip,
                disabledSwitchMode,
                services,
              } = section;
              const defaultSection = sectionsToShow.find((s) => s.id === id);
              const isCustom = !defaultSection;
              let tiles: SummaryMetricIdsTypes[] | null = null;
              if (id === 'pinned') {
                tiles = summaryPinnedTiles;
              } else if (summarySectionsCustomization[id]?.tiles) {
                tiles = summarySectionsCustomization[id].tiles || [];
              }

              if (!tiles && !isHideTilesByDefault) {
                tiles =
                  isCustom || isDynamicSection
                    ? section.tiles
                    : getDefaultShownTiles(isFreeShop, defaultSection) || [];
              }

              if (id === 'customMetrics') {
                // this is part of the migration from stats section to custom metrics https://triplewhale.atlassian.net/browse/TW-6187
                // in case the user have no stats tiles its means he already changes is custom metrics, so in this case we want the stats will be visible
                if (!statsTiles.concat(statsHiddenTiles).some((x) => tiles?.includes(x))) {
                  tiles = [...statsTiles, ...(tiles ?? [])];
                }
              }

              let tileElement: JSX.Element | null;

              if (id === 'inventory' && productsDisabled) {
                tileElement = <Fragment></Fragment>;
              } else {
                tileElement = (
                  <RenderIfVisible
                    stayRendered={true}
                    defaultHeight={
                      isSmall
                        ? sectionHeaderHeight + defaultHeight * (tiles?.length || 1)
                        : sectionHeaderHeight +
                          defaultHeight * (Math.ceil((tiles?.length || 1) / 3) || 1)
                    }
                    placeholderElementClass={`anchor_${id} scroll-mt-[100px]`}
                  >
                    <Suspense fallback={FallbackTile}>
                      <div className={`anchor_${id} scroll-mt-[100px]`}>
                        <TileSection
                          id={id}
                          title={title}
                          invalidConnectionShopField={invalidConnectionShopField}
                          invalidConnectionReasonShopField={invalidConnectionReasonShopField}
                          defaultSectionId={id}
                          tiles={tiles!}
                          onDelete={onDeleteSection as any}
                          filterBar={FilterBar || <></>}
                          disabledEditSection={disabledEditSection}
                          widgets={widgets}
                          addNewBox={addNewBox}
                          isExternalApp={isExternalApp}
                          isDynamic={false}
                          emptySectionTitle={emptySectionTitle}
                          icons={icons}
                          tooltip={tooltip}
                          disabledSwitchMode={disabledSwitchMode}
                          services={services}
                        />
                      </div>
                    </Suspense>
                  </RenderIfVisible>
                );
              }

              return (
                <div key={id} className={`${i === 0 ? '' : 'mt-24'}`}>
                  {tileElement}
                </div>
              );
            })}

            {activeId && (
              <DragOverlay>
                {
                  <RenderIfVisible stayRendered={true} defaultHeight={defaultHeight}>
                    <Suspense fallback={FallbackTile}>
                      <TileSection
                        title={sectionsToShow.find((s) => s.id === activeId)?.title || ''}
                        id={sectionsToShow.find((s) => s.id === activeId)?.id}
                        // filterBar={sections.find(s => s.id === activeId)?.FilterBar || <></>}
                        tiles={activeSectionTiles()!}
                        emptySectionTitle={
                          sectionsToShow.find((s) => s.id === activeId)?.emptySectionTitle
                        }
                        widgets={
                          sectionsToShow.find((s) => s.id === activeId)?.widgets &&
                          sectionsToShow.find((s) => s.id === activeId)?.widgets?.length! > 0
                            ? [() => <DraggedWidget />]
                            : []
                        }
                        defaultSectionId={sectionsToShow.find((s) => s.id === activeId)?.id || ''}
                      />
                    </Suspense>
                  </RenderIfVisible>
                }
              </DragOverlay>
            )}
          </SortableContext>
        </div>
      </DndContext>

      {type === 'summary' && isScrolled && (
        <div className="flex flex-col items-center fixed bottom-[15px] right-[20px] text-[12px] gap-1 scroll-to-top-buttom">
          <Button
            onClick={() => {
              window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
            }}
          >
            {(<Icon source={ArrowUpMinor} />) as any}
          </Button>
          back to top
        </div>
      )}
    </Fragment>
  );
};

export default SummarySection;
