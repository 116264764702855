import React, { useCallback, useRef, useState, useEffect, useMemo } from 'react';
import moment, { Moment } from '@tw/moment-cached/module/timezone';
import { useSelector } from 'react-redux';
import { Button, ChoiceList, Text, TextField, DatePicker, Popover, Tabs } from '@shopify/polaris';

import { CalendarMinor, ChevronLeftMinor } from '@shopify/polaris-icons';
import { MetricsSelector } from './ReportMetricsSelection';
import { useValidation } from 'hooks/useValidation';
import type {
  AnyGoogleSheetsReportInfo,
  GoogleSheetsForecastReportInfo,
  GoogleSheetsMetricsReportInfo,
} from 'ducks/googleSheets';
import { RootState } from 'reducers/RootType';
import GoogleSheetsAccountSelector from 'components/GoogleSheetsAccountSelector';
import { gradualReleaseFeatures } from 'ducks/shop';
import { BuildForecastReport } from './GoogleSheetsForecastReportBuilder';
import { selectAllActiveOrderSegmentMetrics } from 'utils/selectors';

const blankSheetsReport: GoogleSheetsMetricsReportInfo = {
  title: '',
  frequency: 'day',
  metrics: [],
  sheetsAccount: '',
  startDate: '',
  oneTime: true,
};

export const createDefaultReports = ({ title, frequency, startDate, endDate, oneTime }) => {
  return {
    title: title ?? '',
    metrics: [],
    sheetsAccount: '',
    frequency: frequency ?? 'day',
    ...(startDate ? { startDate } : {}),
    ...(endDate ? { endDate } : {}),
    oneTime: oneTime ?? true,
  } as GoogleSheetsMetricsReportInfo;
};

const validateTitle = (value: string) => {
  if (!value.trim()?.length) {
    return 'Please give your Google Sheet a title';
  }
};

const validateMetrics = (value: string[]) => {
  if (!value?.length) {
    return 'Please select at least one metric';
  }
};

export const GoogleSheetsReportBuilder: React.FC<{
  onCancel: () => void;
  onSubmit: (report: AnyGoogleSheetsReportInfo) => void;
  isFromActions?: boolean;
  defaultReports?: any;
}> = ({ onCancel, onSubmit, isFromActions = false, defaultReports = null }) => {
  // const { forecasting: forecastingEnabled } = useSelector(gradualReleaseFeatures);
  const scrollRef = useRef<null | HTMLDivElement>(null);
  const scrollToRef = () => scrollRef.current?.scrollIntoView({ behavior: 'smooth' });
  const [report, setReport] = useState<AnyGoogleSheetsReportInfo>(
    defaultReports ? createDefaultReports(defaultReports) : blankSheetsReport,
  );
  const { activeOrderSegment } = useSelector(selectAllActiveOrderSegmentMetrics);

  useEffect(() => {
    setReport((prev) => {
      return { ...prev, activeOrderSegment };
    });
  }, [activeOrderSegment]);

  const { errors, validate, resetErrors } = useValidation({
    title: validateTitle,
    metrics: report?.reportType === 'forecast' ? (v) => undefined : validateMetrics,
  });

  const validateReport = useCallback(
    (r) => {
      return validate(r);
    },
    [validate],
  );

  const resetForm = useCallback(() => {
    resetErrors();
    setReport({ ...blankSheetsReport });
  }, []);

  useEffect(
    function scrollToTopWithErrors() {
      if (Object.keys(errors).length) {
        scrollToRef();
      }
    },
    [errors],
  );

  const tabs = [
    {
      id: 'metrics',
      content: 'Metrics',
      accessibilityLabel: 'Metrics',
      panelID: 'metrics',
    },
    {
      id: 'forecast',
      content: 'Forecast',
      accessibilityLabel: 'Forecast',
      panelID: 'forecast',
    },
  ];

  const toggleForecastReport = (reportType) => {
    setReport((prev) => {
      return { ...prev, reportType: reportType };
    });
  };

  // const defaultForecastingReport = useMemo(() => {
  //   return {
  //     forecastingGroupBy: 'month',
  //     startDate: moment(forecastingStartDate).format('YYYY-MM-DD'),
  //     numPeriodsAhead: '3',
  //     numPeriodsBehind: '1',
  //     forecastingScenarioIds: [],
  //   };
  // }, [forecastingStartDate]);

  // useEffect(() => {
  //   if (report.reportType === 'forecast') {
  //     setReport((prev) => {
  //       return { ...prev, ...defaultForecastingReport };
  //     });
  //   }
  // }, [report.reportType, defaultForecastingReport]);

  return (
    <>
      <div ref={scrollRef} />
      <section className="reports-sections lg:flex max-w-[1200px] mx-auto">
        {!isFromActions && (
          <div className="flex-[1] my-[1.5rem]">
            <Button icon={ChevronLeftMinor} plain size="slim" onClick={onCancel}>
              Back
            </Button>
          </div>
        )}
        <div className="flex-[8]">
          <Text as="h2" variant="headingLg">
            Create a new Google Sheets report
          </Text>
          <ReportTitle
            value={report.title}
            onChange={(value) => {
              setReport({ ...report, title: value });
            }}
            error={errors.title}
            validate={(v) => validate(v, 'title')}
          />
          <GoogleSheetsAccountSelector
            value={report.sheetsAccount}
            onChange={(sheetsAccount) => {
              setReport({ ...report, sheetsAccount });
            }}
          />
          {/* {forecastingEnabled && (
            <Tabs
              tabs={tabs}
              selected={report?.reportType === 'forecast' ? 1 : 0}
              onSelect={(selected) => {
                toggleForecastReport(tabs[selected].id);
              }}
            ></Tabs>
          )} */}
          {report?.reportType !== 'forecast' && (
            <BuildMetricsReport
              report={report as GoogleSheetsMetricsReportInfo}
              setReport={setReport}
              errors={errors}
              validate={validate}
            />
          )}
          {/* {report?.reportType === 'forecast' && (
            <BuildForecastReport
              report={report as GoogleSheetsForecastReportInfo}
              setReport={setReport}
              forecastingScenarios={forecastingScenarios}
            />
          )} */}
          <ActionButtons
            isFromActions
            onCancel={onCancel}
            onSubmit={() => {
              validateReport(report) && onSubmit(report);
            }}
            onReset={resetForm}
          />
        </div>
      </section>
    </>
  );
};

const BuildMetricsReport = ({
  report,
  setReport,
  errors,
  validate,
}: {
  report: GoogleSheetsMetricsReportInfo;
  setReport: (report: GoogleSheetsMetricsReportInfo) => void;
  errors: { [key: string]: string };
  validate: (val: any, key: string) => boolean;
}) => {
  return (
    <>
      <ReportFrequency
        value={report.frequency}
        onChange={(value) => {
          setReport({ ...report, frequency: value });
        }}
      />
      <IsRecurringSwitch
        value={'' + report.oneTime}
        frequency={report.frequency}
        onChange={(oneTime) => {
          setReport({ ...report, oneTime: oneTime === 'true' ? true : false });
        }}
      />
      <ReportDateSelector
        startDate={report.startDate}
        endDate={report.endDate}
        frequency={report.frequency}
        oneTime={report.oneTime}
        onChange={(startDate, endDate?) => {
          setReport({ ...report, startDate, endDate });
        }}
      />
      <MetricsSelector
        value={report.metrics}
        onChange={(metrics) => {
          setReport({ ...report, metrics });
        }}
        error={errors.metrics}
        validate={(v) => validate(v, 'metrics')}
      />
    </>
  );
};

export const ReportTitle = ({ value, error, onChange, validate }) => {
  return (
    <section className="my-[1.5rem]">
      <TextField
        autoComplete="off"
        label="Report name"
        placeholder="New Sheets Report"
        value={value}
        onChange={onChange}
        error={error}
        onBlur={() => validate(value)}
      />
    </section>
  );
};

export const ReportFrequency = ({
  value,
  onChange,
  disabled,
}: {
  value: string;
  onChange?: (value: string) => void;
  disabled?: boolean;
}) => {
  return (
    <section className="my-[1.5rem]">
      <ChoiceList
        disabled={disabled}
        title="Report Granularity"
        choices={[
          {
            label: 'Daily',
            value: 'day',
          },
          {
            label: 'Weekly',
            value: 'week',
          },
          {
            label: 'Monthly',
            value: 'month',
          },
        ]}
        selected={[value]}
        onChange={(v) => {
          if (onChange) {
            onChange(v[0]);
          }
        }}
      />
    </section>
  );
};

export const IsRecurringSwitch = ({
  disabled,
  value,
  frequency,
  onChange,
}: {
  value: string;
  frequency: string;
  onChange?: (value: string) => void;
  disabled?: boolean;
}) => {
  return (
    <section className="my-[1.5rem]">
      <ChoiceList
        disabled={disabled}
        title="Auto-Sync Frequency"
        choices={[
          {
            label: 'One-time',
            value: 'true',
          },
          {
            label: `Update every ${frequency}`,
            value: 'false',
          },
        ]}
        selected={[value]}
        onChange={(v) => {
          if (onChange) {
            onChange(v[0]);
          }
        }}
      />
    </section>
  );
};

export const ActionButtons = ({
  isFromActions = false,
  onCancel,
  onSubmit,
  onReset,
}: {
  isFromActions?: boolean;
  onCancel: () => void;
  onSubmit: () => void;
  onReset?: () => void;
}) => {
  const buttons = [
    <div className="mr-[8px]">
      {!isFromActions && (
        <Button plain onClick={onCancel}>
          Cancel
        </Button>
      )}
    </div>,
    !!onReset && (
      <button
        className="bg-white hover:bg-slate-200 text-[#1877F2] px-[24px] py-[8px] mr-[8px] rounded-[4px] border cursor-pointer"
        onClick={onReset}
      >
        Reset
      </button>
    ),
    <button
      className="bg-[#1877F2] hover:bg-[#156BD9] text-white px-[24px] py-[8px] rounded-[4px] border-none cursor-pointer disabled:opacity-60 disabled:pointer-events-none"
      onClick={onSubmit}
    >
      Save
    </button>,
  ].filter(Boolean);
  return (
    <section className="flex justify-end items-center">
      {buttons.map((button, i) => (
        <div key={i} className="ml-[1rem]">
          {button}
        </div>
      ))}
    </section>
  );
};

const formatDateForButtonTitle = (date: Moment) => {
  const midnight = moment().startOf('day');
  if (date.local().isSame(midnight, 'day')) {
    return 'Today';
  }

  const midnightYesterday = moment().subtract(1, 'days').startOf('day');
  if (date.local().isSame(midnightYesterday, 'day')) {
    return 'Yesterday';
  }

  const format = 'MMM D, YYYY';
  return date.local().format(format);
};

const getPopoverOpenerButtonTitle = (
  selected?: { start: Date; end: Date },
  range: boolean = false,
) => {
  if (!selected) {
    return 'Select a date';
  }
  const start = moment(selected.start.toISOString());
  const end = moment(selected.end.toISOString());
  if (range) {
    if (start.isSame(end, 'day')) {
      return formatDateForButtonTitle(start);
    }
    return `${formatDateForButtonTitle(start)} - ${formatDateForButtonTitle(end)}`;
  }
  return formatDateForButtonTitle(start);
};

export const ReportDateSelector = ({
  startDate,
  endDate,
  onChange,
  frequency,
  oneTime,
  disabled,
}: {
  startDate?: string;
  endDate?: string;
  onChange?: (startDate: string, endDate?: string) => void;
  frequency: string;
  oneTime?: boolean;
  disabled?: boolean;
}) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [selected, setSelected] = useState<{ start: Date; end: Date }>();
  const [month, setMonth] = useState<number>(new Date().getMonth());
  const [year, setYear] = useState<number>(new Date().getFullYear());

  useEffect(() => {
    if (!startDate && !!onChange) {
      setSelected({
        start: moment().subtract(1, 'days').startOf('day').toDate(),
        end: moment().subtract(1, 'days').startOf('day').toDate(),
      });
      onChange(moment().subtract(1, 'days').startOf('day').toISOString());
    }
  }, [startDate]);

  useEffect(() => {
    if (!oneTime) {
      setSelected({
        start: moment(startDate).startOf('day').toDate(),
        end: moment(startDate).startOf('day').toDate(),
      });
    }
  }, [oneTime]);

  return (
    <section className="my-[1.5rem]">
      <div className="mb-[0.5rem]">
        <Text as="h3" variant="bodyMd">
          {oneTime ? 'Choose a Date Range' : 'Choose a Start Date'}
        </Text>
        <Text as="p" variant="bodyMd" color="subdued">
          {`The report will be populated with data `}
          {oneTime ? 'for the selected range, ' : 'from the start date up to now, '}
          {`broken down by ${frequency}. `}
          {!oneTime && `New data will sync automatically every ${frequency}`}
        </Text>
      </div>
      <Popover
        fluidContent
        preferredAlignment="left"
        active={isOpen}
        activator={
          <div className="flex flex-col">
            <div>
              <Button
                onClick={() => setIsOpen(!isOpen)}
                disclosure
                id="google-sheets-report-start-date"
                accessibilityLabel="Report Start Date Picker"
                icon={CalendarMinor}
              >
                {getPopoverOpenerButtonTitle(selected, !!oneTime)}
              </Button>
            </div>
          </div>
        }
        onClose={() => setIsOpen(false)}
      >
        <div id="google-sheets-report-start-date-selections" aria-label="Date Selections">
          <div className="p-[1rem]" style={{ maxWidth: '375px' }}>
            <DatePicker
              id="google-sheets-report-start-date-picker"
              multiMonth={false}
              allowRange={!!oneTime}
              selected={selected}
              month={month}
              year={year}
              onMonthChange={(m, y) => {
                setMonth(m);
                setYear(y);
              }}
              onChange={(v) => {
                if (!onChange) {
                  return;
                }
                setSelected(v);
                if (oneTime) {
                  onChange(v.start.toISOString(), v.end.toISOString());
                } else {
                  onChange(v.start.toISOString());
                }
                if (v.start.toISOString() !== v.end.toISOString()) {
                  setIsOpen(false);
                }
              }}
              disableDatesBefore={
                disabled && startDate ? new Date(startDate) : new Date('Jan 1, 2010')
              }
              disableDatesAfter={
                disabled && endDate
                  ? new Date(endDate)
                  : new Date(
                      moment().subtract(1, 'days').toDate().toLocaleString('en-US', {
                        timeZone: moment().tz(),
                      }),
                    )
              }
            />
          </div>
        </div>
      </Popover>
    </section>
  );
};
