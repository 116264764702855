import './InfluencerForm.scss';

import { debounce } from 'lodash';
import moment from '@tw/moment-cached/module/timezone';
import { FC, useCallback, useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Options, OptionsOrGroups } from 'react-select';
import AsyncCreatableSelect from 'react-select/async-creatable';
import { type RootState } from 'reducers/RootType';
import { influencer, influencerExpense, SelectableInfluencer } from 'types/Influencers';
import { filterCreatives } from 'utils/influencers';
import { useAppDispatch } from 'index';

import {
  Button,
  ButtonGroup,
  DatePicker,
  DropZone,
  FormLayout,
  Label,
  Popover,
  Spinner,
  Stack,
  Text,
  TextField,
  TextStyle,
} from '@shopify/polaris';
import { DeleteMinor, PlusMinor, CancelMinor } from '@shopify/polaris-icons';

import { ReactComponent as FileIcon } from '../../../icons/file_image.svg';
import { ReactComponent as Edit } from '../../../icons/influencer_edit.svg';
import { InfluencersContext } from '../context';
import { connectToShopify } from 'utils/connectToShopify';
import { InfluencerExpenses } from '../InfluencerExpenses/InfluencerExpenses';
import { confirmationModal } from 'ducks/confirmationModal';
import TWTable from 'components/library/TWTable/TWTable';
import { convertDateToMoment } from 'utils/convertDateToMoment';
import { getCurrencySymbol } from 'utils/getCurrencySymbol';
import { Box, Image, Icon, ActionIconDecorator } from '@tw/ui-components';

interface InfluencerFormProps {
  onSubmit: (i: influencer) => void;
  influencer: SelectableInfluencer;
  onChange: any; //(i: influencer | SelectablInfluencer | Function) => void;
  onCancel: () => void;
  editMode: boolean;
}

const profileReader = new FileReader();

const InfluencerForm: FC<InfluencerFormProps> = ({
  influencer,
  onSubmit,
  onChange,
  onCancel,
  editMode,
}) => {
  const isFromMobileApp = useSelector((state: RootState) => state.mobileApp.isFromMobileApp);
  const date = moment();
  const [month, setMonth] = useState(date.month());
  const [year, setYear] = useState(date.year());
  const [showDiscountValue, setShowDiscValue] = useState(false);
  const [showOther, setShowOther] = useState(false);
  const [errors, setErrors] = useState({});
  const [submitted, setSubmitted] = useState(false);
  const [files, setFiles] = useState<string[]>([]);
  const [profileFile, setProfileFile] = useState<string[]>([]);
  const [affluencerExpenses, setAffluencerExpenses] = useState<influencerExpense[]>([]);
  const [startDatePickerIsOpen, setStartDatePickerIsOpen] = useState<boolean[]>([]);
  const [endDatePickerIsOpen, setEndDatePickerIsOpen] = useState<boolean[]>([]);
  const [startDateLabel, setStartDateLabel] = useState('MM/DD/YYYY');
  const [endDateLabel, setEndDateLabel] = useState('MM/DD/YYYY');
  const [startDateMonth, setStartDateMonth] = useState(date.month());
  const [startDateYear, setStartDateYear] = useState(date.year());
  const [endDateMonth, setEndDateMonth] = useState(date.month());
  const [endDateYear, setEndDateYear] = useState(date.year());
  const [optionsOpened, setOptionsOpened] = useState<boolean[]>([]);
  const shopTimezone = useSelector((state: RootState) => state.shopTimezone);
  const activeCurrency = useSelector((state: RootState) => state.activeCurrency);
  const [urlParams, setUrlParams] = useState<string[]>([]);
  const dispatch = useAppDispatch();

  const {
    uploadProgress,
    uploadingInProgress,
    uploadError,
    discountCodeError,
    loadedDiscountCodes,
    shopId,
    profileUploadProgress,
    searchDiscountCodes,
    handleCreativeUpload,
    handleCreativeDeleted,
    uploadProfilePhoto,
    influencersDispatch,
    loadDefaultDiscountCodes,
  } = useContext(InfluencersContext);

  const debouncedSearch = debounce(async (strSearch, resolve) => {
    const codes = await searchDiscountCodes!(strSearch);
    const codeOptions = discountCodesOptions(codes);
    resolve(codeOptions);
  }, 2000);

  const loadDiscountCodes = (
    strSearch: string,
    callback: any,
  ): Promise<OptionsOrGroups<Options<any>, any>> => {
    return new Promise(async (resolve) => {
      debouncedSearch(strSearch, resolve);
    });
  };

  const validate = (influencer: SelectableInfluencer) => {
    if (!influencer.name) {
      setErrors({ name: "Influencer's name is required" });
      return false;
    }

    return true;
  };

  const handleSubmit = (influencer: SelectableInfluencer) => {
    if (validate(influencer)) {
      setSubmitted(true);
      influencer.hasExpenses = !!affluencerExpenses.length;
      influencer.expenses = affluencerExpenses;
      onSubmit(influencer);
    }
  };

  const onFileUpload = async (fileList) => {
    fileList = Array.from(fileList);
    if (editMode) {
      await handleCreativeUpload!(influencer, fileList);
      influencersDispatch!({ type: 'replace', influencer: influencer });
    } else {
      onChange((prevState: SelectableInfluencer): influencer => {
        return {
          ...prevState,
          files: [...(prevState.files || []), ...fileList],
        };
      });
    }
  };

  const onCreativeDeleted = async (creativeName: string | undefined, creativeSrc: string) => {
    await handleCreativeDeleted!(influencer, creativeName, creativeSrc);
    influencersDispatch!({ type: 'replace', influencer: influencer });
  };

  const onProfileUpload = (file) => {
    editMode
      ? uploadProfilePhoto!(influencer, file)
      : onChange((prevState: SelectableInfluencer): influencer => {
          return {
            ...prevState,
            profile_file: file,
          };
        });
  };

  const discountCodesOptions = (discountCodes) => {
    return (
      discountCodes &&
      discountCodes!.map((discountCode) => {
        return { label: discountCode.code, value: discountCode.code };
      })
    );
  };

  const handleDiscountCodeSelect = (code: string, newCode: boolean = false, i: number) => {
    if (code && (influencer?.discountCodes || []).find((c) => c.code === code)) {
      setErrors({ discountCode: 'Discount codes must be unique' });
      return;
    }
    setErrors({ discountCode: '' });
    onChange({
      ...influencer,
      codeStrings: code
        ? [...new Set((influencer.codeStrings || []).concat(code))]
        : [...(influencer?.codeStrings || [])],
      discountCodes: [
        ...(influencer.discountCodes || [])?.slice(0, i),
        {
          ...(influencer.discountCodes?.[i] || {}),
          code: code,
          isNew: newCode,
        },
        ...(influencer.discountCodes || [])?.slice(i + 1),
      ],
    });
  };

  const removeDiscount = async (code) => {
    const index = getDiscountCodeIndex(code);
    const discountCodes = [...(influencer?.discountCodes || [])];
    discountCodes.splice(index, 1);
    onChange({ ...influencer, discountCodes });
  };

  const handleFieldChange = (influencer, field: string) => {
    if (errors && errors[field]) {
      delete errors[field];
    }
    onChange(influencer);
  };

  const addLink = useCallback(() => {
    const links = influencer?.links || [];
    links.push({ id: '', campaign: '', source: '', link: '' });
    onChange({ ...influencer, links });
  }, [onChange, influencer]);

  const addCode = useCallback(() => {
    const discountCodes = [...(influencer?.discountCodes || [])];
    discountCodes.push({ code: '', value_type: 'percentage' });
    onChange({ ...influencer, discountCodes });
  }, [onChange, influencer]);

  useEffect(() => {
    if (discountCodeError || uploadError) {
      setSubmitted(false);
    }
  }, [discountCodeError, uploadError]);

  useEffect(() => {
    profileReader.onload = function (e) {
      const str = e?.target?.result as string;
      setProfileFile((f) => f.concat(str));
    };

    loadDefaultDiscountCodes!();
  }, [loadDefaultDiscountCodes]);

  useEffect(() => {
    const files: string[] = [];
    influencer?.files?.forEach((file) => {
      const reader = new FileReader();
      reader.onload = function (e) {
        const str = e?.target?.result as string;
        files.push(str);
        setFiles(files);
      };
      reader.readAsDataURL(file);
    });
  }, [influencer.files]);

  useEffect(() => {
    influencer?.profile_file?.forEach((file) => {
      profileReader.readAsDataURL(file);
    });
  }, [influencer.profile_file]);

  useEffect(() => {
    if (!influencer.links?.length) {
      addLink();
    }
  }, [influencer, onChange, addLink]);

  useEffect(() => {
    if (influencer.urlParams?.length) {
      setUrlParams([...(influencer.urlParams || []).slice(0, 4)]);
    }
  }, [influencer, onChange, setUrlParams]);

  useEffect(() => {
    if (influencer?.expenses?.length) {
      setAffluencerExpenses(influencer.expenses);
    }
  }, [influencer]);

  const getDiscountCodeIndex = (code) => {
    return influencer.discountCodes?.findIndex((dis) => dis.code === code) || 0;
  };

  return (
    <div className="InfluencerForm">
      <FormLayout>
        {uploadingInProgress && (
          <div className="absolute w-11/12 top-2/3 z-50">
            <div className="flex justify-center ">
              <Spinner size="large"></Spinner>
            </div>
          </div>
        )}
        <Stack>
          <Stack.Item>
            {profileFile?.length === 0 && !influencer?.profile_photo?.url && (
              <DropZone
                disabled={!!isFromMobileApp}
                label={<span className="font-semibold">Profile Picture</span>}
                variableHeight={true}
                onDrop={(file) => onProfileUpload(file)}
                allowMultiple={false}
              >
                <div className="flex flex-col gap-4 items-center justify-center h-40 w-40">
                  <div className="text-center">
                    <FileIcon width={18} height={18} />
                  </div>
                </div>
              </DropZone>
            )}
            {profileFile?.length > 0 && !influencer?.profile_photo?.url && (
              <div className="flex items-center gap-4">
                {profileFile.map((file, index) => {
                  return (
                    <div className="w-40 h-40 overflow-hidden" key={index}>
                      <Edit
                        className="absolute right-4 top-4"
                        width={18}
                        height={18}
                        onClick={() => setProfileFile([])}
                      />
                      <img
                        alt="influencer asset"
                        src={file}
                        className="object-cover w-full h-full"
                      />
                    </div>
                  );
                })}
              </div>
            )}
            {influencer?.profile_photo?.url && (
              <div className="w-40 h-40 overflow-hidden relative">
                <Edit
                  className="absolute right-4 top-4"
                  width={18}
                  height={18}
                  onClick={() =>
                    handleFieldChange({ ...influencer, profile_photo: {} }, 'profile_photo')
                  }
                />
                <img
                  alt="influencer asset"
                  src={influencer.profile_photo.url}
                  className="object-cover w-full h-full"
                />
              </div>
            )}
          </Stack.Item>
          <Stack.Item fill>
            <TextField
              label={<span className="font-semibold">Affluencer Name</span>}
              value={influencer.name}
              disabled={!!influencer.is_auto_created}
              placeholder="e.g., MrBeast"
              onChange={(value) => handleFieldChange({ ...influencer, name: value }, 'name')}
              requiredIndicator
              error={errors['name']}
              autoComplete="off"
            ></TextField>
          </Stack.Item>
        </Stack>
        <div>
          {Object.entries(profileUploadProgress!).map(([name, progress]) => {
            return (
              <div key={name}>
                <div>{name || ''}</div>
                <div className="barWrapper">
                  <div className="progressBar">
                    <span
                      className="progress"
                      style={{ width: `${progress}%`, maxWidth: '100%', minWidth: '4%' }}
                    ></span>
                  </div>
                </div>
                <div className="upload-progress">
                  {progress === 100 ? `Upload complete!` : `${progress}% complete`}
                </div>
              </div>
            );
          })}
        </div>
        <Stack>
          <>
            {' '}
            <span className="font-semibold flex items-center justify-between gap-4 mt-5 mb-4">
              <span>Triple Whale Generated Links</span>
              <PlusMinor className="w-10 h-10 cursor-pointer" onClick={addLink} />
            </span>
            {influencer.links?.length &&
              influencer.links?.map((linkData, i) => {
                return (
                  // <Stack key={i}>
                  <div className={`flex justify-around gap-5 ${i > 0 ? ' mt-10' : ''}`}>
                    <TextField
                      label={'Url'}
                      value={linkData.url}
                      onChange={(value) => {
                        handleFieldChange(
                          {
                            ...influencer,
                            links: [
                              ...influencer.links!.slice(0, i),
                              { ...linkData, url: value },
                              ...influencer.links!.slice(i + 1),
                            ],
                          },
                          'links',
                        );
                      }}
                      autoComplete="off"
                    />
                    <TextField
                      label={'Campaign'}
                      value={linkData.campaign}
                      onChange={(value) => {
                        handleFieldChange(
                          {
                            ...influencer,
                            links: [
                              ...influencer.links!.slice(0, i),
                              { ...linkData, campaign: value },
                              ...influencer.links!.slice(i + 1),
                            ],
                          },
                          'links',
                        );
                      }}
                      autoComplete="off"
                    />
                    <TextField
                      label={'Source'}
                      value={linkData.source}
                      onChange={(value) => {
                        handleFieldChange(
                          {
                            ...influencer,
                            links: [
                              ...influencer.links!.slice(0, i),
                              { ...linkData, source: value },
                              ...influencer.links!.slice(i + 1),
                            ],
                          },
                          'links',
                        );
                      }}
                      autoComplete="off"
                    />
                    <span
                      className=" w-8 mt-12 cursor-pointer"
                      onClick={() => {
                        dispatch(
                          confirmationModal({
                            title: 'Delete Link',
                            text: 'Are you sure you want to delete this link?',
                            onCancel: () => {},
                            onConfirm: () => {
                              (influencer.links || []).splice(i, 1);
                              handleFieldChange(
                                {
                                  ...influencer,
                                },
                                'links',
                              );
                            },
                            confirmButtonText: 'Delete',
                          }),
                        );
                      }}
                    >
                      <DeleteMinor></DeleteMinor>
                    </span>
                  </div>
                  // </Stack>
                );
              })}
            {!!urlParams?.length && (
              <div className="">
                <span className="font-semibold flex items-center justify-between gap-4 mt-5 mb-4">
                  UTM Links Found
                </span>
                <ul className="flex flex-wrap ">
                  {urlParams?.map((param, i) => {
                    return <li className="w-full p-2 break-words">{param}</li>;
                  })}
                </ul>
                {(influencer?.urlParams || [])?.length > 4 && urlParams.length <= 4 && (
                  <span
                    className="cursor-pointer underline text-logo"
                    onClick={() => {
                      setUrlParams(influencer.urlParams || []);
                    }}
                  >
                    Show All Links
                  </span>
                )}
              </div>
            )}
          </>
        </Stack>
        <div className="flex items-center justify-between gap-4">
          <Text as="p" variant="bodyMd" fontWeight="bold">
            Discount Codes
          </Text>
          <PlusMinor
            className="w-10 h-10 cursor-pointer"
            onClick={() => {
              addCode();
            }}
          />
          {errors['discountCode'] && (
            <span className=" text-negative">{errors['discountCode']}</span>
          )}
        </div>
        {discountCodeError && (
          <div>
            {discountCodeError === 'permission' ? (
              <div style={{ color: '#d02121' }}>
                <span>Discount code can't be saved. Please</span>{' '}
                <span
                  className="cursor-pointer underline text-logo"
                  onClick={() => {
                    connectToShopify(shopId!, true);
                  }}
                >
                  grant permission
                </span>
                <span> to save the code.</span>
              </div>
            ) : (
              <div style={{ color: '#d02121' }}>{discountCodeError}</div>
            )}
          </div>
        )}
        <TWTable
          data={influencer.discountCodes}
          noDataNode={[[<p>No discount codes</p>]]}
          padding="5px"
          dark={false}
          columns={[
            {
              key: 'code',
              name: 'Code',
              sortable: false,
              dataType: 'text',
              Heading: () => 'Code',
              Value: (row) => {
                const index = getDiscountCodeIndex(row.code);
                return (
                  <div className={`${optionsOpened[index] ? ' h-72' : ''}`}>
                    <AsyncCreatableSelect
                      aria-label="Discount Code"
                      className={`discountCodeSelect `}
                      isClearable
                      onMenuClose={() => {
                        setOptionsOpened((opened) => {
                          const newOpened = [...opened];
                          if (newOpened[index]) {
                            delete newOpened[index];
                          }
                          return newOpened;
                        });
                      }}
                      onMenuOpen={() => {
                        setOptionsOpened((opened) => {
                          const newOpened = [...opened];
                          newOpened[index] = true;
                          return newOpened;
                        });
                      }}
                      onChange={(newValue: any | null, actionMeta: any) => {
                        handleDiscountCodeSelect(
                          newValue && newValue.value ? newValue.value : '',
                          false,
                          index,
                        );
                      }}
                      defaultOptions={
                        loadedDiscountCodes ? discountCodesOptions(loadedDiscountCodes) : []
                      }
                      loadOptions={(str) => loadDiscountCodes(str, {})}
                      placeholder="Select Discount Code"
                      value={row?.code ? { value: row?.code, label: row?.code } : ''}
                      onCreateOption={(code) => {
                        handleDiscountCodeSelect(code, true, index);
                      }}
                    />
                  </div>
                );
              },
            },
            {
              key: 'value',
              name: 'value',
              sortable: false,
              dataType: 'text',
              Heading: () => 'Value',
              Value: (row) => {
                const index = getDiscountCodeIndex(row.code);
                return (
                  <div className="flex">
                    <ButtonGroup segmented>
                      <Button
                        pressed={row.value_type === 'fixed_amount'}
                        disabled={row.value_type !== 'fixed_amount' && !row.isNew}
                        onClick={() => {
                          onChange({
                            ...influencer,
                            discountCodes: [
                              ...(influencer.discountCodes || [])?.slice(0, index),
                              {
                                ...(influencer.discountCodes?.[index] || {}),
                                value_type: 'fixed_amount',
                              },
                              ...(influencer.discountCodes || [])?.slice(index + 1),
                            ],
                          });
                        }}
                      >
                        {
                          (
                            <span className="whitespace-nowrap">
                              {getCurrencySymbol(activeCurrency)}
                            </span>
                          ) as any
                        }
                      </Button>
                      <Button
                        pressed={!row.value_type || row.value_type === 'percentage'}
                        disabled={row.value_type && row.value_type !== 'percentage' && !row.isNew}
                        onClick={() => {
                          onChange({
                            ...influencer,
                            discountCodes: [
                              ...(influencer.discountCodes || [])?.slice(0, index),
                              {
                                ...(influencer.discountCodes?.[index] || {}),
                                value_type: 'percentage',
                              },
                              ...(influencer.discountCodes || [])?.slice(index + 1),
                            ],
                          });
                        }}
                      >
                        {(<span className="whitespace-nowrap">%</span>) as any}
                      </Button>
                    </ButtonGroup>
                    <TextField
                      label={''}
                      value={row.value}
                      disabled={!row.isNew}
                      onChange={(value) => {
                        onChange({
                          ...influencer,
                          discountCodes: [
                            ...(influencer.discountCodes || [])?.slice(0, index),
                            {
                              ...(influencer.discountCodes?.[index] || {}),
                              value: value,
                            },
                            ...(influencer.discountCodes || [])?.slice(index + 1),
                          ],
                        });
                      }}
                      autoComplete="off"
                    ></TextField>
                  </div>
                );
              },
            },
            {
              key: 'start_date',
              name: 'From',
              sortable: false,
              dataType: 'text',
              Heading: () => 'From',
              Value: (row) => {
                const index = getDiscountCodeIndex(row.code);
                return (
                  <div className="flex justify-between items-center ">
                    <Popover
                      active={startDatePickerIsOpen[index]}
                      activator={
                        <div
                          onClick={() =>
                            setStartDatePickerIsOpen!((startsOpen) => {
                              const newStartsOpen = [...startsOpen];
                              if (newStartsOpen[index]) {
                                delete newStartsOpen[index];
                              } else {
                                newStartsOpen[index] = true;
                              }
                              return newStartsOpen;
                            })
                          }
                        >
                          <Text as="p" variant="bodyMd">
                            {row.start_date ? moment(row.start_date).format('ll') : 'None'}
                          </Text>
                        </div>
                      }
                      onClose={() =>
                        setStartDatePickerIsOpen((startsOpen) => {
                          const newStartsOpen = [...startsOpen];
                          if (newStartsOpen[index]) {
                            delete newStartsOpen[index];
                          }
                          return newStartsOpen;
                        })
                      }
                      sectioned
                      fullHeight
                    >
                      <DatePicker
                        month={startDateMonth}
                        year={startDateYear}
                        allowRange={false}
                        onChange={(val) => {
                          onChange({
                            ...influencer,
                            discountCodes: [
                              ...(influencer.discountCodes || [])?.slice(0, index),
                              {
                                ...row,
                                start_date: convertDateToMoment(val.start).startOf('day').format(),
                              },
                              ...(influencer.discountCodes || [])?.slice(index + 1),
                            ],
                          });
                        }}
                        onMonthChange={(month, year) => {
                          setStartDateMonth(month);
                          setStartDateYear(year);
                        }}
                        selected={row?.start_date ? moment(row?.start_date).toDate() : undefined}
                      />
                      <Stack distribution="trailing" alignment="center">
                        <Button
                          primary
                          onClick={() => {
                            setStartDatePickerIsOpen([]);
                          }}
                        >
                          Apply
                        </Button>
                      </Stack>
                      <div>
                        {' '}
                        <TextStyle variation="subdued">{shopTimezone}</TextStyle>
                      </div>
                    </Popover>
                    <span
                      className={`cursor-pointer ${row.start_date ? 'w-12 h-12' : ''}`}
                      onClick={() => {
                        onChange({
                          ...influencer,
                          discountCodes: [
                            ...(influencer.discountCodes || [])?.slice(0, index),
                            {
                              ...row,
                              start_date: null,
                            },
                            ...(influencer.discountCodes || [])?.slice(index + 1),
                          ],
                        });
                      }}
                    >
                      <CancelMinor />
                    </span>
                  </div>
                );
              },
            },
            {
              key: 'end_date',
              name: 'Until',
              sortable: false,
              dataType: 'text',
              Heading: () => 'Until',
              Value: (row) => {
                const index = getDiscountCodeIndex(row.code);
                return (
                  <div className="flex items-center justify-between">
                    <Popover
                      active={endDatePickerIsOpen[index]}
                      activator={
                        <div
                          onClick={() =>
                            setEndDatePickerIsOpen((endsOpen) => {
                              const newEndsOpen = [...endsOpen];
                              if (newEndsOpen[index]) {
                                delete newEndsOpen[index];
                              } else {
                                newEndsOpen[index] = true;
                              }
                              return newEndsOpen;
                            })
                          }
                        >
                          <Text as="p" variant="bodyMd">
                            {row.end_date ? moment(row.end_date).format('ll') : 'None'}
                          </Text>
                        </div>
                      }
                      onClose={() =>
                        setEndDatePickerIsOpen((endsOpen) => {
                          const newEndsOpen = [...endsOpen];
                          if (newEndsOpen[index]) {
                            delete newEndsOpen[index];
                          }
                          return newEndsOpen;
                        })
                      }
                      sectioned
                      fullHeight
                    >
                      <DatePicker
                        month={endDateMonth}
                        year={endDateYear}
                        onChange={(val) => {
                          onChange({
                            ...influencer,
                            discountCodes: [
                              ...(influencer.discountCodes || [])?.slice(0, index),
                              {
                                ...row,
                                end_date: convertDateToMoment(val.start).startOf('day').format(),
                              },
                              ...(influencer.discountCodes || [])?.slice(index + 1),
                            ],
                          });
                        }}
                        onMonthChange={(month, year) => {
                          setEndDateMonth(month);
                          setEndDateYear(year);
                        }}
                        selected={row?.end_date ? moment(row?.end_date).toDate() : undefined}
                      />
                      <Stack distribution="trailing" alignment="center">
                        <Button
                          primary
                          onClick={() => {
                            setEndDatePickerIsOpen([]);
                          }}
                        >
                          Apply
                        </Button>
                      </Stack>
                      <div>
                        {' '}
                        <TextStyle variation="subdued">{shopTimezone}</TextStyle>
                      </div>
                    </Popover>
                    <span
                      className={`cursor-pointer ${row.end_date ? 'w-12 h-12' : ''}`}
                      onClick={() => {
                        onChange({
                          ...influencer,
                          discountCodes: [
                            ...(influencer.discountCodes || [])?.slice(0, index),
                            {
                              ...row,
                              end_date: null,
                            },
                            ...(influencer.discountCodes || [])?.slice(index + 1),
                          ],
                        });
                      }}
                    >
                      <CancelMinor />
                    </span>
                  </div>
                );
              },
            },
            {
              key: 'delete',
              name: 'Delete',
              sortable: false,
              dataType: 'text',
              Heading: () => 'Delete',
              Value: (row) => {
                return (
                  <div className="flex items-center justify-center">
                    <Button
                      size="slim"
                      plain
                      icon={DeleteMinor}
                      onClick={() => {
                        dispatch(
                          confirmationModal({
                            title: 'Delete Discount Code',
                            text: 'Are you sure you want to delete this Discount Code?',
                            onCancel: () => {},
                            onConfirm: () => removeDiscount(row.code),
                            confirmButtonText: 'Delete',
                          }),
                        );
                      }}
                    />
                  </div>
                );
              },
            },
          ]}
        ></TWTable>

        <InfluencerExpenses
          affluencer={influencer}
          affluencerExpenses={affluencerExpenses}
          setAffluencerExpenses={setAffluencerExpenses}
        />

        <hr />
        <Label id="creatives">
          <span className=" font-semibold">Upload Content</span>
        </Label>
        <DropZone
          disabled={!!isFromMobileApp}
          variableHeight={true}
          onDrop={(file) => onFileUpload(file)}
        >
          <div className=" grid justify-center align-middle grid-cols-1 p-2">
            <div className=" text-center">
              <FileIcon />
            </div>
            <div className=" text-center">Drag & Drop Files or Browse your Files</div>
          </div>
        </DropZone>
        {uploadError && <span style={{ color: '#d02121' }}>{uploadError}</span>}
        {files && (
          <div className="flex items-center gap-4">
            {files.map((file, index) => {
              return (
                <div className="w-40 h-40 overflow-hidden" key={index}>
                  <img alt="influencer asset" src={file} className="object-cover w-full h-full" />
                </div>
              );
              // return <span key={file.name}>{file.name}</span>;
            })}
          </div>
        )}
        <div className="creatives">
          <Stack>
            {influencer.creatives &&
              filterCreatives(influencer.creatives).map((creative, i) => {
                return (
                  <Box pos="relative" key={i}>
                    <ActionIconDecorator
                      position="top"
                      actionIconProps={{
                        icon: 'delete',
                        size: 'xs',
                        iconSize: 12,
                        onClick: () =>
                          onCreativeDeleted(creative.name, creative.thumbnail || creative.url),
                      }}
                    >
                      <Image
                        src={creative.thumbnail || creative.url}
                        shadowBorderSize="sm"
                        h={80}
                        w={80}
                      />
                    </ActionIconDecorator>
                    {creative.type === 'video' && (
                      <Box
                        pos="absolute"
                        display="flex"
                        top="50%"
                        right="50%"
                        transform="translate(50%, -50%)"
                      >
                        <Icon name="play-circle" color="white" size={20} />
                      </Box>
                    )}
                  </Box>
                );
              })}
          </Stack>
        </div>
        <div>
          {Object.entries(uploadProgress!).map(([name, progress]) => {
            return (
              <div key={name}>
                <div>{name}</div>
                <div className="barWrapper">
                  <div className="progressBar">
                    <span
                      className="progress"
                      style={{ width: `${progress}%`, maxWidth: '100%', minWidth: '4%' }}
                    ></span>
                  </div>
                </div>
                <div className="upload-progress">
                  {progress === 100 ? `Upload complete!` : `${progress}% complete`}
                </div>
              </div>
            );
          })}
        </div>
        <hr className="mt-10" />
        <div className="form-footer mt-7">
          <Button
            loading={submitted ? true : false}
            primary
            onClick={() => handleSubmit(influencer)}
          >
            Save
          </Button>
          <Button onClick={() => onCancel()}>Cancel</Button>
        </div>
      </FormLayout>
    </div>
  );
};

export default InfluencerForm;
