import { v4 as uuidV4 } from 'uuid';
import { Timestamp } from 'utils/DB';
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';
import { useHistory, useLocation } from 'react-router';
import { useStoreValue, useWritableStore } from '@tw/snipestate';
import {
  Menu,
  ActionIcon,
  Button,
  confirm,
  Flex,
  Icon,
  Loader,
  MobileDrawer,
  Tooltip,
} from '@tw/ui-components';
import DropDown from 'components/ltv/DropDown';
import { useEffect, useCallback, useMemo, useState } from 'react';
import { MobileVerticalDotsMajor } from '@shopify/polaris-icons';
import { OptionList, Popover } from '@shopify/polaris';
import {
  analyticsEvents,
  chatActions,
  genericEventLogger,
  sequencesActions,
} from 'utils/dataLayer';
import axiosInstance from 'utils/axiosInstance';
import _db, { firestoreRef } from 'utils/DB';
import { $seqSettingsModal } from '$stores/willy/$seqSettingsModal';
import { $currentShopId } from '$stores/$shop';
import { $hasSequenceAccess, $shopSequences } from '$stores/willy/$sequences';
import { $dialect, $isTwGlobalDashboardCreatorClaim } from '$stores/$user';
import { useFilteredItems } from './hooks/useFilteredItems';
import { WillySearchInput } from './WillySearchInput';
import { WillyEmoji } from './types/emojiTypes';
import { emptyEmoji } from './dashboardManagment//WillyDashDescription';
import { createNewSequence } from './utils/sequences';
import { WillyElementType } from './types/willyTypes';

export type SequencesListProps = {
  sequenceId?: string;
  opened?: boolean;
  setOpened?: React.Dispatch<React.SetStateAction<boolean>>;
  setRunsOpen?: React.Dispatch<React.SetStateAction<string | undefined>>;
  activeTab?: string;
};

const userFilterOptions = [
  { value: 'user', label: 'My Workflows' },
  { value: 'store', label: 'Store' },
  { value: 'all', label: 'All' },
];

export const SequencesList: React.FC<SequencesListProps> = ({
  sequenceId,
  setOpened = () => {},
  setRunsOpen = () => {},
  activeTab,
}) => {
  const { search } = useLocation();
  const history = useHistory();
  const [sequences, setSequences] = useWritableStore($shopSequences);
  const currentShopId = useStoreValue($currentShopId);
  const dialect = useStoreValue($dialect);
  const isTwGlobalDashboardCreatorClaim = useStoreValue($isTwGlobalDashboardCreatorClaim);
  const [userFilter, setUserFilter] = useState(
    activeTab || userFilterOptions[userFilterOptions.length - 1].value,
  );

  useEffect(() => {
    if (userFilter !== activeTab) {
      setUserFilter(activeTab || userFilterOptions[userFilterOptions.length - 1].value);
    }
  }, [activeTab, userFilter]);

  const [freeSearch, setFreeSearch] = useState('');

  const filteredSequence = useFilteredItems(sequences, freeSearch, ['name', 'id'], userFilter);

  const toggleSequenceActionMenu = useCallback(
    (id: string, open: boolean) => {
      setSequences((old) => {
        return old.map((x) => {
          return {
            ...x,
            actionsMenuOpen: x.id === id ? open : false,
          };
        });
      });
    },
    [setSequences],
  );

  const menuItems = useCallback(
    (c) => [
      {
        content: 'Edit',
        icon: () => <Icon name="edit" />,
        disabled: !c.canEdit,
        onAction: () => {
          $seqSettingsModal.set({ opened: true, sequence: c });
          setOpened(false);
        },
      },
      {
        content: 'Rename',
        icon: () => <Icon name="refresh" />,
        disabled: !c.canEdit,
        onAction: () => {
          setSequences((old) => {
            return old.map((x) => {
              if (x.id === c.id) {
                return {
                  ...x,
                  renameMode: true,
                };
              }
              return x;
            });
          });
        },
      },
      {
        content: 'Last Runs',
        icon: () => <Icon name="clock" />,
        onAction: () => {
          setOpened(false);
          setRunsOpen(c.id);
        },
      },
      ...(isTwGlobalDashboardCreatorClaim
        ? [
            {
              content: 'Turn Into Template',
              icon: () => <Icon name="global" />,
              onAction: async () => {
                const conf = await confirm({
                  title: 'Turn Workflow into template?',
                  message:
                    'This will make a copy in its current state, and publish to the template library.',
                });
                if (!conf) return;

                const newSequenceTemplate = {
                  shopId: currentShopId!,
                  userId: c.user,
                  dialect: c.dialect ?? dialect,
                  conversationId: c.conversationId,
                  messages: c.messages ?? [],
                  startingDashboard: '',
                  startingDashboardDate: null,
                  baseMainElement: {
                    id: uuidV4(),
                    type: 'sequence' as WillyElementType,
                    canEdit: false,
                    isGlobal: true,
                    createdAt: Timestamp.now(),
                    updatedAt: Timestamp.now(),
                    conversationId: c.fromConversationId,
                    name: c.name ?? '',
                    description: c.description ?? '',
                    emoji: c.emoji ?? (emptyEmoji as WillyEmoji),
                    roles: c.roles ?? [],
                    isBeta: c.isBeta ?? false,
                    isHide: c.isHide ?? false,
                    image: c.image,
                    category: c.category ?? '',
                    dialect: c.dialect ?? dialect ?? 'clickhouse',
                    providers: c.providers ?? [],
                    providersBlockingCombination: c.providersBlockingCombination ?? 'AND',
                    packages: c.packages ?? [],
                    defaultPackages: c.defaultPackages ?? [],
                    msps: c.msps ?? '',
                  },
                };

                const { error, success, message, conversationData } =
                  await createNewSequence(newSequenceTemplate);

                if (error) {
                  toast.error(error);
                } else if (success) {
                  genericEventLogger(analyticsEvents.SEQUENCES, {
                    action: sequencesActions.CREATE_SEQUENCE,
                    sequence_id: conversationData?.id,
                    sequence_name: conversationData?.title,
                  });
                  toast.success(message);
                }
              },
            },
          ]
        : []),
      {
        content: 'Delete',
        icon: () => <Icon name="delete" color="red.5" width={20} height={20} />,
        destructive: true,
        disabled: !c.canEdit,
        onAction: async () => {
          const conf = await confirm({
            title: 'Delete sequence?',
            message: 'This action cannot be undone',
          });
          if (!conf) return;
          const ref = c.isGlobal
            ? firestoreRef().collection('global_data_sequences')
            : _db().collection('data_sequences');
          const batch = firestoreRef().batch();
          batch.delete(ref.doc(c.id));
          if (!c.isGlobal) {
            const runs = await ref.doc(c.id).collection('runs').get();
            runs.forEach((run) => {
              batch.delete(run.ref);
            });

            await batch.commit();
          }
          await ref.doc(c.id).delete();
          if (c.globalDashboardId) {
            await axiosInstance.post('v2/willy/update-stats', {
              shopId: currentShopId,
              sequenceId: c.globalDashboardId,
              actionType: 'uninstalled',
            });
          }
        },
      },
    ],
    [currentShopId, dialect, isTwGlobalDashboardCreatorClaim, setOpened, setRunsOpen, setSequences],
  );

  return (
    <div>
      <div className={`${activeTab ? 'p-6 pb-0' : 'p-4'} flex gap-4 w-full`}>
        <WillySearchInput
          value={freeSearch}
          onChange={(v) => setFreeSearch(v)}
          placeholder="Search Workflows"
          className="!p-0 overflow-visible w-full"
        />
        {!activeTab && (
          <DropDown
            handleSelect={(v) => setUserFilter(v)}
            options={userFilterOptions}
            value={userFilter}
          />
        )}
      </div>
      <div>
        {!sequences.length && (
          <div className="p-4">
            <p>There are no workflows yet. Create one to get started</p>
          </div>
        )}
      </div>

      {!activeTab && (
        <div className="px-4 flex items-center gap-4 w-full justify-start">
          <Button
            onClick={() => {
              $seqSettingsModal.set({ opened: true, isCreate: true });
              setOpened(false);
            }}
            leftSection="plus-1"
            iconSize={20}
          >
            New Workflow
          </Button>
          <Link
            to={{
              pathname: '/workflows',
              search: '?filter=templates',
            }}
            className="no-underline"
          >
            <Button leftSection={<Icon color="white" name="lighthouse-1" />}>
              Workflow Library
            </Button>
          </Link>
        </div>
      )}
      <OptionList
        options={filteredSequence.map((c) => ({
          value: c.id,
          label: (
            <span
              className="flex items-center gap-4 overflow-hidden group/conversation-item w-full"
              onClick={(e) => {
                if (c.renameMode) {
                  e.stopPropagation();
                  e.preventDefault();
                  return;
                }
              }}
              onDoubleClick={(e) => {
                if (c.renameMode) {
                  e.stopPropagation();
                  e.preventDefault();
                  return;
                }
                setSequences((old) => {
                  return old.map((x) => {
                    if (x.id === c.id) {
                      return {
                        ...x,
                        renameMode: true,
                      };
                    }
                    return x;
                  });
                });
              }}
              onKeyDown={(e) => {
                if (c.renameMode) {
                  e.stopPropagation();
                  e.preventDefault();
                }
              }}
              onKeyUp={(e) => {
                if (c.renameMode) {
                  e.stopPropagation();
                  e.preventDefault();
                }
              }}
            >
              {!c.schedule && (
                <div className="flex-shrink-0 flex">
                  <Tooltip label="No schedule for this sequence yet">
                    <Icon name="minus" color="gray.5" />
                  </Tooltip>
                </div>
              )}
              {!!c.schedule && (
                <div className="flex items-center">
                  {c.schedule.status === 'running' && (
                    <Tooltip label="Scheduled sequence is running">
                      <Loader size="xs" />
                    </Tooltip>
                  )}
                  {c.schedule.status === 'error' && (
                    <Tooltip
                      label={`Scheduled sequence has an error: ${
                        c.schedule.error || 'Unknown error'
                      }`}
                    >
                      <Icon name="info" color="red.5" />
                    </Tooltip>
                  )}
                  {(c.schedule.status === 'scheduled' || c.schedule.status === 'skipped') && (
                    <Tooltip
                      label={`Waiting for the next scheduled run ${
                        c.schedule.status === 'skipped' ? '(last run skipped due to rules)' : ''
                      }`}
                    >
                      <Icon name="clock" />
                    </Tooltip>
                  )}
                  {c.schedule.status === 'paused' && (
                    <Tooltip label="Scheduled sequence is paused">
                      <Icon name="play-arrow" />
                    </Tooltip>
                  )}
                </div>
              )}
              <span
                className={`focus:outline ${
                  c.renameMode
                    ? 'overflow-auto text-clip'
                    : 'overflow-hidden text-ellipsis whitespace-nowrap'
                }`}
                contentEditable={c.renameMode}
                suppressContentEditableWarning
                ref={(el) => {
                  if (!el) return;
                  if (c.renameMode) {
                    const range = document.createRange();
                    range.selectNodeContents(el);
                    const selection = window.getSelection();
                    selection?.removeAllRanges();
                    selection?.addRange(range);

                    el.focus();
                  } else {
                    el.blur();
                  }
                }}
                onBlur={(e) => {
                  const ref = c.isGlobal
                    ? firestoreRef().collection('global_data_sequences')
                    : _db().collection('data_sequences');
                  ref.doc(c.id).set({ title: e.target.textContent }, { merge: true });

                  setSequences((old) => {
                    return old.map((x) => {
                      return {
                        ...x,
                        renameMode: false,
                      };
                    });
                  });
                }}
                onKeyDown={(e) => {
                  e.stopPropagation();
                  if (e.key === 'Enter') {
                    e.preventDefault();
                    e.currentTarget.blur();
                  } else if (e.key === 'Escape') {
                    e.preventDefault();
                    e.currentTarget.textContent = c.name || c.history?.[0]?.text || '';
                    e.currentTarget.blur();
                  }
                }}
              >
                {c.name || c.history?.[0]?.text || ''}
              </span>

              <span
                className="ml-auto"
                onClick={(e) => {
                  e.stopPropagation();
                  e.preventDefault();
                }}
              >
                <Popover
                  active={!!c.actionsMenuOpen}
                  onClose={() => {
                    toggleSequenceActionMenu(c.id, false);
                  }}
                  activator={
                    <MobileVerticalDotsMajor
                      className="flex-shrink-0 ml-auto cursor-pointer opacity-100 sm:opacity-0 group-hover/conversation-item:opacity-100 transition-opacity
                                   fill-gray-500 dark:fill-gray-300 w-8"
                      onClick={(e) => {
                        e.stopPropagation();
                        toggleSequenceActionMenu(c.id, !c.actionsMenuOpen);
                      }}
                    />
                  }
                >
                  <Menu shadow="md" bg="white">
                    {menuItems(c).map((item) => (
                      <Menu.Item
                        key={item.content}
                        onClick={() => {
                          item.onAction();
                          toggleSequenceActionMenu(c.id, false);
                        }}
                        disabled={item.disabled}
                      >
                        <Flex gap="sm" align="center">
                          {item.icon()}
                          {item.content}
                        </Flex>
                      </Menu.Item>
                    ))}
                  </Menu>
                </Popover>
              </span>
            </span>
          ),
        }))}
        selected={sequenceId ? [sequenceId] : []}
        onChange={async ([v]) => {
          if (!v) {
            return;
          }

          const sequence = sequences.find((c) => c.id === v);
          if (!sequence) {
            return;
          }

          const params = new URLSearchParams(search);

          params.set('sequenceId', sequence.id);
          params.delete('conversationId');
          params.delete('runId');
          history.push({
            pathname: '/chat',
            search: params.toString(),
          });

          setOpened(false);

          genericEventLogger(analyticsEvents.CHAT, {
            action: chatActions.RUN_SEQUENCE,
            id: sequence.id,
            text: sequence.history?.[0]?.text || '',
            conversationId: sequence.id,
          });
        }}
      />
    </div>
  );
};

type SequencesListModalProps = {
  isSmall?: boolean;
} & SequencesListProps;

export const SequencesListModal: React.FC<SequencesListModalProps> = ({
  isSmall,
  opened = false,
  setOpened = () => {},
  ...props
}) => {
  const hasSequenceAccess = useStoreValue($hasSequenceAccess);

  const activator = useMemo(
    () => <ActionIcon iconSize={20} icon="lighthouse-1" onClick={() => setOpened((x) => !x)} />,
    [setOpened],
  );

  if (!hasSequenceAccess) {
    return null;
  }

  if (isSmall) {
    return (
      <>
        {activator}
        <MobileDrawer
          title="Workflows"
          onClose={() => setOpened?.(false)}
          opened={opened}
          position="bottom"
          withinPortal
        >
          <Flex direction="column" gap="lg" overflow="auto" h="100%">
            <SequencesList opened={opened} setOpened={setOpened} {...props} />
          </Flex>
        </MobileDrawer>
      </>
    );
  }

  return (
    <div>
      <Popover
        active={opened}
        onClose={() => setOpened(false)}
        activator={
          <div>
            <Tooltip label="Workflows">{activator}</Tooltip>
          </div>
        }
      >
        <SequencesList opened={opened} setOpened={setOpened} {...props} />
      </Popover>
    </div>
  );
};
