import { $derived, $effect, $mutableObserver, $store, useStoreValue } from '@tw/snipestate';
import {
  BASE_HEADER_HEIGHT,
  DATE_PICKER_HEIGHT,
  TOP_BAR_HEIGHT,
  TOP_BAR_HEIGHT_VAR,
} from 'components/TopBar/constants';
import { getAppConfigManager } from './AppConfigManager';

export const $navigationLoading = $derived((get) => get(getAppConfigManager().$config).loading);

export const $activeAppVersion = $derived(
  (get) => get(getAppConfigManager().$config).activeAppVersion,
);

export const $navigationSections = $derived(
  (get) => get(getAppConfigManager().$config).navSections,
);

export const $activeRoute = $derived((get) => get(getAppConfigManager().$config).activeRoute);

export const $activeSection = $derived((get) => get($activeRoute).activeSection);

export const useActiveRoute = () => useStoreValue($activeRoute);

/** Left side navigation option */
export type NavbarOption = 'alan' | 'settings' | 'main' | 'pods';

export const $activeNav = $derived((get): NavbarOption => {
  const version = get($activeAppVersion);
  const r = get($activeRoute).activeRoute;
  if (r?.isSettingsPage) return 'settings';
  if (r?.isShoplessPage) return 'pods';
  if (r?.isWillyPage || (r?.isHybridPage && version === '3.0')) return 'alan';
  return 'main';
});

const $innerActiveRoute = $derived((get) => get($activeRoute).activeRoute);

/**
 * Store that keeps track of the height of the top bar.
 * We need this, because the main container of the app needs a fixed height to scroll correctly.
 *
 * **IMPORTANT** - ONLY USE $topBarHeight within the SnipeState hooks IF YOU CAN'T USE `var(--top-bar-height)` directly!!
 * This reduces rerenders.
 */
export const $topBarHeight = $mutableObserver(BASE_HEADER_HEIGHT, (get, set) => {
  const activeRoute = get($innerActiveRoute);
  if (!activeRoute) return;
  set(activeRoute.isWillyPage ? 0 : BASE_HEADER_HEIGHT);
});

$effect((_, get) => {
  const isZeroHeader = $innerActiveRoute.get()?.isWillyPage;
  const topBarHeight = get($topBarHeight);
  const val = isZeroHeader ? 0 : topBarHeight;
  document.documentElement.style.setProperty(TOP_BAR_HEIGHT, val + 'px');
  document.documentElement.style.setProperty(
    DATE_PICKER_HEIGHT,
    `calc(100vh - ${TOP_BAR_HEIGHT_VAR} - 60px)`,
  );
});
