import { useSelector } from 'react-redux';
import { ProductProps } from '@tw/types/module/services/insights';
import { Box, Flex, QuantityDecorator, Text, Image } from '@tw/ui-components';
import { ActivityDetail } from './ActivityDetail';
import { RootState } from 'reducers/RootType';

export const ProductInfo: React.FC<{
  properties: ProductProps & { quantity: number };
  showVariantInfo?: boolean;
}> = ({ properties, showVariantInfo = true }) => {
  const currency = useSelector((state: RootState) => state.currency);

  return (
    <Flex gap="sm">
      <QuantityDecorator quantity={properties.quantity}>
        <Image shadowBorderSize="sm" width={80} src={properties.imgUrl} />
      </QuantityDecorator>
      <Box>
        <Text span fw={500}>
          {properties.productTitle}
        </Text>
        {showVariantInfo && (
          <ActivityDetail label="Price">
            {Number(properties.price).toLocaleString(undefined, {
              style: 'currency',
              currency,
            })}
          </ActivityDetail>
        )}
        {showVariantInfo && (
          <ActivityDetail label="Variant">{properties.variantTitle}</ActivityDetail>
        )}
        {showVariantInfo && <ActivityDetail label="SKU">{properties.sku}</ActivityDetail>}
      </Box>
    </Flex>
  );
};
