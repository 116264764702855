import { $currentShopId } from '$stores/$shop';
import { ActionList, Button, FormLayout, OptionList, Popover, TextField } from '@shopify/polaris';
import { MobileVerticalDotsMajor, DeleteMajor, EditMajor, InfoMinor } from '@shopify/polaris-icons';
import { useStoreValue, useWritableStore } from '@tw/snipestate';
import { confirm, Modal, Skeleton } from '@tw/ui-components';
import DropDown from 'components/ltv/DropDown';
import { toggleMobileSidePanel } from 'ducks/willy';
import { useAppDispatch } from 'index';
import { useState, useCallback, useEffect, useMemo } from 'react';
import { useLocation, useHistory } from 'react-router';
import axiosInstance from 'utils/axiosInstance';
import { windowSize } from 'utils/classes/WindowSizeObserver';
import { genericEventLogger, analyticsEvents, chatActions } from 'utils/dataLayer';
import _db, { toArray } from 'utils/DB';
import { isMobileApp } from 'utils/Device';
import { useFilteredItems } from './hooks/useFilteredItems';
import { Conversation } from './types/willyTypes';
import { WillySearchInput } from './WillySearchInput';
import { WillyUserAvatar } from './WillyUserAvatar';
import { User } from 'components/UserProfileManagment/User/constants';
import { CopyToClipboard } from './CopyToClipboard';
import { useConversationsStore } from '$stores/willy/$conversation';

type ChatHistoryProps = {
  conversationId: string;
  clearConversation: () => void;
  onConversationClicked: () => void;
};

export const ChatHistory: React.FC<ChatHistoryProps> = ({
  conversationId,
  clearConversation,
  onConversationClicked,
}) => {
  const dispatch = useAppDispatch();
  const { search } = useLocation();
  const history = useHistory();

  const currentShopId = useStoreValue($currentShopId);
  const [{ conversations, loading }, setConversations] = useConversationsStore();

  const userFilterOptions = [
    { value: 'user', label: 'My Chats' },
    { value: 'store', label: 'Store' },
    { value: 'all', label: 'All' },
  ];
  const [userFilter, setUserFilter] = useState(userFilterOptions[0].value);
  const [freeSearch, setFreeSearch] = useState('');
  const [userToDisplay, setUserToDisplay] = useState<string>('');
  const [conversationInfoModal, setConversationInfoModal] = useState<Conversation>();

  const filteredConversations = useFilteredItems(
    conversations,
    freeSearch,
    ['title', 'id', 'id', 'history[0].text'],
    userFilter,
  );

  const filteredConversationsGroupedByDate = useMemo(() => {
    // the idea is to group conversations by date
    // so conversations from today will be in one group called "Today"
    // another groups will be: "Yesterday", "last 7 days", "last 30 days", "older this year", "older"
    const groups = Object.groupBy(filteredConversations, (c) => {
      const date = c.createdAt.toDate();
      const today = new Date();
      if (date.toDateString() === today.toDateString()) {
        return 'Today';
      }
      if (date.toDateString() === new Date(today.setDate(today.getDate() - 1)).toDateString()) {
        return 'Yesterday';
      }
      if (date > new Date(today.setDate(today.getDate() - 6))) {
        return 'Previous 7 Days';
      }
      if (date > new Date(today.setDate(today.getDate() - 23))) {
        return 'Previous 30 Days';
      }
      if (date > new Date(today.setMonth(today.getMonth() - 1))) {
        return 'Older this year';
      }
      return 'Older';
    });

    return groups;
  }, [filteredConversations]);

  useEffect(() => {
    if (!conversationInfoModal?.user) {
      return;
    }
    (async () => {
      const { data } = await axiosInstance.get<User>(
        `/v2/willy/get-user-name?shopId=${currentShopId}&userId=${conversationInfoModal.user}`,
      );
      const { firstName, lastName, email } = data;
      setUserToDisplay(`${firstName} ${lastName} (${email})`);
    })();
  }, [conversationInfoModal?.user, currentShopId]);

  return (
    <>
      {loading && (
        <OptionList
          options={Array(50)
            .fill(0)
            .map((_, i) => ({
              value: i.toString(),
              label: <Skeleton key={i} animate height={'1.5rem'} />,
            }))}
          selected={[]}
          onChange={() => {}}
        />
      )}
      <div className="h-full">
        {!conversations.length && !loading && (
          <div className="p-4">
            <p>Ask a question to get started. You can also choose from the templates</p>
          </div>
        )}
        <div className="flex items-start pr-6">
          <div className="grow">
            <WillySearchInput
              value={freeSearch}
              onChange={setFreeSearch}
              placeholder="Search conversations"
              className="!pb-0"
            />
          </div>
          <div className="mt-6">
            <DropDown
              handleSelect={(v) => setUserFilter(v)}
              options={userFilterOptions}
              value={userFilter}
            />
          </div>
        </div>
        <div className="overflow-auto h-full">
          <OptionList
            sections={Object.entries(filteredConversationsGroupedByDate).map(
              ([date, conversations]) => {
                return {
                  title: date,
                  options: conversations.map((c) => ({
                    value: c.id,
                    label: (
                      <ConversationOptionListItem
                        conversation={c}
                        clearConversation={clearConversation}
                        setConversationInfoModal={setConversationInfoModal}
                        setConversations={setConversations}
                      />
                    ),
                  })),
                };
              },
            )}
            selected={conversationId ? [conversationId] : []}
            onChange={async ([v]) => {
              onConversationClicked();
              if (windowSize.isSmall || isMobileApp) {
                dispatch(toggleMobileSidePanel(false));
              }

              if (!v) {
                return;
              }
              const conversation = conversations.find((c) => c.id === v);
              if (!conversation) {
                return;
              }
              const params = new URLSearchParams(search);
              params.set('conversationId', conversation.id);
              params.delete('sequenceId');
              params.delete('runId');
              history.push({
                search: params.toString(),
              });

              genericEventLogger(analyticsEvents.CHAT, {
                action: chatActions.CHOOSE_EXISTING,
                id: conversation.id,
                text: conversation.history?.[0]?.text || '',
                conversationId: conversation.id,
              });
            }}
          />
        </div>

        <Modal
          opened={!!conversationInfoModal}
          onClose={() => {
            setConversationInfoModal(undefined);
          }}
        >
          <FormLayout>
            <FormLayout.Group>
              <TextField
                onChange={() => {}}
                autoComplete="off"
                label="Conversation ID"
                value={conversationInfoModal?.id}
                readOnly
                disabled
                connectedRight={
                  <CopyToClipboard text={conversationInfoModal?.id!} className="flex h-full" />
                }
              />
              <TextField
                onChange={() => {}}
                autoComplete="off"
                label="Created At"
                value={conversationInfoModal?.createdAt.toDate().toLocaleString()}
                readOnly
                disabled
              />
            </FormLayout.Group>
            <FormLayout.Group title="Owner">
              <div className="flex items-start gap-2 w-full">
                <WillyUserAvatar userName={userToDisplay} userId={conversationInfoModal?.user} />
                <div className="flex-auto">
                  <TextField
                    onChange={() => {}}
                    autoComplete="off"
                    label="Owner"
                    labelHidden
                    value={userToDisplay}
                    readOnly
                    disabled
                  />
                </div>
              </div>
            </FormLayout.Group>
            <FormLayout.Group>
              <TextField
                autoComplete="off"
                label="Title"
                value={conversationInfoModal?.title}
                onChange={(v) => {
                  setConversationInfoModal((old) => {
                    if (!old) return old;
                    return {
                      ...old,
                      title: v,
                    };
                  });
                }}
                connectedRight={
                  <Button
                    onClick={async () => {
                      if (!conversationInfoModal) return;
                      await _db()
                        .collection('conversations')
                        .doc(conversationInfoModal.id)
                        .set({ title: conversationInfoModal.title }, { merge: true });
                    }}
                  >
                    Save
                  </Button>
                }
              />
            </FormLayout.Group>
          </FormLayout>
        </Modal>
      </div>
    </>
  );
};

type ConversationOptionListItemProps = {
  conversation: Conversation;
  clearConversation: () => void;
  setConversationInfoModal: (conversation: Conversation) => void;
  setConversations: React.Dispatch<
    React.SetStateAction<{ conversations: Conversation[]; loading: boolean }>
  >;
};

const ConversationOptionListItem: React.FC<ConversationOptionListItemProps> = ({
  conversation,
  clearConversation,
  setConversationInfoModal,
  setConversations,
}) => {
  const conversationId = conversation.id;

  const toggleConversationActionMenu = useCallback(
    (id: string, open: boolean) => {
      setConversations((old) => {
        return {
          ...old,
          conversations: old.conversations.map((x) => {
            if (x.id === id) {
              return {
                ...x,
                actionsMenuOpen: open,
              };
            }
            return x;
          }),
        };
      });
    },
    [setConversations],
  );

  const toggleConversationRenameMode = useCallback(
    (id: string, renameMode: boolean) => {
      setConversations((old) => {
        return {
          ...old,
          conversations: old.conversations.map((x) => {
            if (x.id === id) {
              return {
                ...x,
                renameMode: !!renameMode,
              };
            }
            return x;
          }),
        };
      });
    },
    [setConversations],
  );

  return (
    <span
      className="flex items-center gap-2 overflow-hidden group/conversation-item w-full"
      onClick={(e) => {
        if (conversation.renameMode) {
          e.stopPropagation();
          e.preventDefault();
          return;
        }
      }}
      onDoubleClick={(e) => {
        if (conversation.renameMode) {
          e.stopPropagation();
          e.preventDefault();
          return;
        }
        toggleConversationRenameMode(conversation.id, true);
      }}
      onKeyDown={(e) => {
        if (conversation.renameMode) {
          e.stopPropagation();
          e.preventDefault();
        }
      }}
      onKeyUp={(e) => {
        if (conversation.renameMode) {
          e.stopPropagation();
          e.preventDefault();
        }
      }}
    >
      <span
        className={`flex-auto focus:outline ${
          conversation.renameMode
            ? 'overflow-auto text-clip'
            : 'overflow-hidden text-ellipsis whitespace-nowrap'
        }`}
        contentEditable={conversation.renameMode}
        suppressContentEditableWarning
        ref={(el) => {
          if (!el) return;
          if (conversation.renameMode) {
            const range = document.createRange();
            range.selectNodeContents(el);
            const selection = window.getSelection();
            selection?.removeAllRanges();
            selection?.addRange(range);

            el.focus();
          } else {
            el.blur();
          }
        }}
        onBlur={(e) => {
          _db()
            .collection('conversations')
            .doc(conversation.id)
            .set({ title: e.target.textContent }, { merge: true });

          toggleConversationRenameMode(conversation.id, false);
        }}
        onKeyDown={(e) => {
          e.stopPropagation();
          if (e.key === 'Enter') {
            e.preventDefault();
            e.currentTarget.blur();
          } else if (e.key === 'Escape') {
            e.preventDefault();
            e.currentTarget.textContent =
              conversation.title || conversation.history?.[0]?.text || '';
            e.currentTarget.blur();
          }
        }}
      >
        {conversation.title || conversation.history?.[0]?.text || ''}
      </span>
      <span
        onClick={(e) => {
          e.stopPropagation();
          e.preventDefault();
        }}
      >
        <Popover
          active={!!conversation.actionsMenuOpen}
          onClose={() => {
            toggleConversationActionMenu(conversation.id, false);
          }}
          preventCloseOnChildOverlayClick
          activator={
            <MobileVerticalDotsMajor
              className="w-8 flex-shrink-0 ml-auto cursor-pointer opacity-100 sm:opacity-0 group-hover/conversation-item:opacity-100 transition-opacity
                        fill-gray-500 dark:fill-gray-300"
              onClick={(e) => {
                e.stopPropagation();
                toggleConversationActionMenu(conversation.id, !conversation.actionsMenuOpen);
              }}
            />
          }
        >
          <ActionList
            onActionAnyItem={() => {
              toggleConversationActionMenu(conversation.id, false);
            }}
            items={[
              {
                content: 'Delete',
                icon: DeleteMajor,
                destructive: true,
                onAction: async () => {
                  const conf = await confirm({
                    title: 'Delete conversation?',
                    message: 'This action cannot be undone',
                  });
                  if (!conf) return;
                  await _db().collection('conversations').doc(conversation.id).delete();

                  if (conversationId === conversation.id) {
                    clearConversation();
                  }
                },
              },
              {
                content: 'Rename',
                icon: EditMajor,
                onAction: () => {
                  toggleConversationActionMenu(conversation.id, false);
                  toggleConversationRenameMode(conversation.id, true);
                },
              },
              {
                content: 'Info',
                icon: InfoMinor,
                onAction: () => {
                  toggleConversationActionMenu(conversation.id, false);
                  setConversationInfoModal(conversation);
                },
              },
            ]}
          />
        </Popover>
      </span>
    </span>
  );
};
