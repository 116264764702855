import React, { useMemo, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'reducers/RootType';
import { Tooltip, Area, ComposedChart, Line, ResponsiveContainer, YAxis } from 'recharts';
import { RoundingOptionsMapper } from 'ducks/summary';
import { AbstractChart } from '@tw/types';
import { formatNumber } from 'utils/formatNumber';
import moment from '@tw/moment-cached/module/timezone';
import { useIsSmall } from 'hooks/useDefaultWindowSizes';

type SummaryChartProps = {
  data: AbstractChart[];
  color: string;
  gradientColor?: string;
  height: number;
  yAxisStartAuto?: boolean;
  showGraphTooltip?: boolean;
  metric?: any;
  isOneDay?: boolean;
  className?: string;
  isDashboardTile?: boolean;
};

export const NewSummaryChart: React.FC<SummaryChartProps> = (props) => {
  const {
    data = [],
    color,
    gradientColor,
    height,
    yAxisStartAuto,
    showGraphTooltip = false,
    className = '',
  } = props;

  const isSmall = useIsSmall();

  let chartData = useMemo(
    () =>
      data?.map?.((current) => {
        return {
          x: current?.x,
          y: current?.y || 0,
          z: (current as any)?.z || 0,
        };
      }),
    [data],
  );

  if (!data?.length) {
    return null;
  }

  // do not allow chart with one element
  if (chartData.length < 2) {
    chartData = [];
  }

  return (
    <div style={{ position: 'relative', width: '100%', paddingBottom: `${height}px` }}>
      <div
        className={className}
        style={{
          position: 'absolute',
          left: 0,
          right: 0,
          bottom: 0,
          top: 0,
        }}
      >
        <ResponsiveContainer width={'100%'} height={height}>
          <ComposedChart
            cy={30}
            data={chartData}
            className="bg-transparent !cursor-pointer max-w-[250px]"
          >
            <YAxis hide domain={[yAxisStartAuto ? 'auto' : 0, 'auto']} />
            <defs>
              <linearGradient id={`base-color-for-chart`} x1="0" y1="0" x2="0" y2="1">
                <stop offset="0.2" stopColor={color} stopOpacity={1} />
                <stop
                  offset="1"
                  stopColor={gradientColor || color}
                  stopOpacity={gradientColor ? 1 : 0.2}
                />
              </linearGradient>
            </defs>
            <Line
              dataKey={'z'}
              stroke={`url(#base-color-for-chart)`}
              type="linear"
              dot={false}
              strokeOpacity={1}
              strokeWidth={isSmall ? 1 : 2}
              strokeDasharray="3,3"
              activeDot={false}
            />
            <Area
              type="linear"
              dataKey={'y'}
              stroke={`url(#base-color-for-chart)`}
              strokeOpacity={1}
              fillOpacity={0}
              strokeWidth={isSmall ? 1 : 2}
              dot={false}
              activeDot={false}
            />
            {showGraphTooltip && <Tooltip content={<ChartTooltip {...props} />} />}
          </ComposedChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
};

// how many orders last 7 days. give me a total and daily breakdown. order by date asc

const ChartTooltip = (props: any) => {
  const { payload, active, metric, isOneDay, isDashboardTile } = props;
  const { valueToFixed, type, format, toFixed } = metric || {};
  const { x, y } = payload?.[0]?.payload || {};
  const currency = useSelector((state: RootState) => state.activeCurrency);
  const currencyConversionRate = useSelector((state: RootState) => state.currencyConversionRate);
  const defaultRoundingOption = useSelector((state: RootState) => state.defaultRoundingOption);
  const dateRange = useSelector((state: RootState) => state.mainDatePickerSelectionRange);
  const year = dateRange?.start?.year() ?? moment().year();
  const currencyRounding = useCallback(
    (metric) => {
      if (metric?.type === 'currency' || metric?.format === 'currency') {
        return RoundingOptionsMapper[defaultRoundingOption];
      }
      return null;
    },
    [defaultRoundingOption],
  );
  const formatDate = useCallback(
    (x) => {
      if (isDashboardTile && x && x.length == 2)
        return moment(x, 'HH').format('h:mm A'); //hourly
      else if (isDashboardTile) return moment(x).format('DD MMM');
      else if (isOneDay && x !== undefined)
        return moment(x.toString().padStart(2, '0'), 'HH').format('h:mm A');
      else
        return moment()
          .year(year)
          .dayOfYear(+x || 0)
          .format('DD MMM');
    },
    [isOneDay, isDashboardTile, year],
  );

  if (!active) return null;
  return (
    <div className="bg-white dark:bg-[var(--gray-dark-mode-600)] p-4 flex flex-col gap-4 rounded shadow">
      <div className="flex flex-col gap-4">
        <span className="font-bold">{formatDate(x)}</span>
        <span>
          {formatNumber((metric?.type === 'percent' ? y / 100 : y) * currencyConversionRate, {
            style: type ?? format,
            currency,
            minimumFractionDigits: currencyRounding(metric) ?? valueToFixed ?? toFixed,
            maximumFractionDigits: currencyRounding(metric) ?? valueToFixed ?? toFixed,
          })}
        </span>
      </div>
    </div>
  );
};
