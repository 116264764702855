import React, { useCallback, useEffect, useState } from 'react';
import SalesPlatformIcon from '../../../SalesPlatform/SalesPlatformIcon';
import SalesPlatformName from '../../../SalesPlatform/SalesPlatformName';
import SendEmailModal from '../SendEmailModal';
import { connectToShopify } from '../../../../utils/connectToShopify';
import { MAIN_PLATFORM_ACTIVATION_REDIRECT_TO } from './WelcomeConnectMainSalesPlatfrom';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../reducers/RootType';

export const GenericUrlConnect = ({ resetContinueWarn, connectAction }) => {
  const [showInviteUser, setShowInviteUser] = useState<boolean>(false);
  const currentShopId = useSelector((state: RootState) => state.currentShopId);
  const msp = useSelector((state: RootState) => state.msp);

  const connectMsp = useCallback(() => {
    localStorage.setItem(
      `${MAIN_PLATFORM_ACTIVATION_REDIRECT_TO}_${currentShopId}`,
      location.pathname,
    );
    if (connectAction) {
      connectAction(currentShopId);
    }
  }, [connectAction, currentShopId]);

  useEffect(() => {
    if (resetContinueWarn) resetContinueWarn();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showInviteUser]);

  return (
    <>
      {!showInviteUser ? (
        <div className="rounded-[5px] bg-[#F5F7FA] py-[25px] px-[34px] flex flex-col items-center gap-[15px] max-w-[440px] relative">
          <button
            className="step-action-button !rounded-[8px] py-[15px] px-[30px] flex items-center gap-[15px]"
            onClick={connectMsp}
          >
            <SalesPlatformIcon />
            <span className="font-semibold text-[18px]">
              Connect to <SalesPlatformName />
            </span>
          </button>
          <p className="text-[12px] text-[#536B84]">
            Not the admin on your <SalesPlatformName /> store?{' '}
            <span
              className="text-logo cursor-pointer underline"
              onClick={() => setShowInviteUser(true)}
            >
              Invite another user
            </span>
          </p>
        </div>
      ) : (
        <SendEmailModal
          wizardStep="connectShopify"
          msp={msp}
          onClose={() => {
            setShowInviteUser(false);
          }}
        />
      )}
    </>
  );
};
