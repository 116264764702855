import React, { useEffect, useState } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import {
  Card,
  DataTable,
  DropZone,
  TextStyle,
  Page,
  Button,
  TextField,
  Tooltip,
} from '@shopify/polaris';
import { ClockMinor, SaveMinor } from '@shopify/polaris-icons';
import DeleteRowButton from './DeleteRowWithConfirmButton';
import CURRENCIES from '../constants/currencies';
import TextFieldWithOnBlurSave from './TextFieldWithOnBlurSave';
import { type RootState } from '../reducers/RootType';
import { paymentGatewayOnDelete, paymentGatewayOnSave } from '../ducks/paymentGateways';
import { useAppDispatch } from '../index';
import CogsHistory from 'pages/cogs/CogsHistory';
import { getCurrencySymbol } from '@tw/constants';
import { computeFeatureFlags } from 'feature-flag-system';
import { FeatureFlag } from '@tw/feature-flag-system/module/types';
import { UpgradePageFallBack } from 'feature-flag-system/components';
import moment from 'moment';
import { Text, Icon } from '@tw/ui-components';

const cellHeight = {
  height: 37,
};

const cell = {
  ...cellHeight,
  display: 'flex',
  alignItems: 'center',
};

const PaymentGatewayCostsComponent = () => {
  const paymentGatewayCosts = useSelector((state: RootState) => state.paymentGatewayCosts);
  const [internalPaymentGatewayCosts, setInternalPaymentGatewayCosts] = useState([
    ...paymentGatewayCosts.filter((item) => item.id !== 'shopify_payments'),
  ]);

  useEffect(() => {
    setInternalPaymentGatewayCosts([
      ...paymentGatewayCosts.filter((item) => item.id !== 'shopify_payments'),
    ]);
  }, [paymentGatewayCosts]);

  const onValChange = (stat, value, index) => {
    const newPaymentGatewayCosts = [...internalPaymentGatewayCosts];
    if (stat === 'cost' && +value > 100) newPaymentGatewayCosts[index][stat] = 100;
    else if (+value < 0) newPaymentGatewayCosts[index][stat] = value * -1;
    else newPaymentGatewayCosts[index][stat] = value;
    let costHistory = internalPaymentGatewayCosts[index][`${stat}s`] || [];
    costHistory.unshift({
      value: +newPaymentGatewayCosts[index][stat],
      date: moment().toDate(),
    });
    newPaymentGatewayCosts[index][`${stat}s`] = costHistory;
    setInternalPaymentGatewayCosts(newPaymentGatewayCosts);
  };

  const currency = useSelector((state: RootState) => state.currency);
  const dispatch = useAppDispatch();

  const [isCostHistoryOpen, setIsCostHistoryOpen] = useState(false);
  const [selectedHistoryRow, setSelectedHistoryRow] = useState();
  const [costType, setCostType] = useState('fees');

  const showCostHistory = (row, name) => {
    setIsCostHistoryOpen(true);
    setSelectedHistoryRow(row);
    setCostType(name);
  };
  const shopifyPayment = [
    <div style={cell}>
      <TextStyle variation="subdued">shopify_payments</TextStyle>
    </div>,
    <div style={cell}>
      <div className="flex align-items-center gap-4">
        <Icon name="info" size={12} />
        <Text size="sm">Fees are imported directly from Shopify</Text>
      </div>
    </div>,
    <div></div>,
    <div></div>,
    <div></div>,
  ];

  return (
    <div className="p-6.5">
      {!!(paymentGatewayCosts && paymentGatewayCosts.length) && (
        <div className="noScrollTable">
          <DataTable
            columnContentTypes={['text', 'text', 'text', 'text', 'text']}
            headings={[
              //'ID',
              'Payment Gateway Name',
              'Cost',
              'Fee',
              '',
              '',
            ]}
            rows={[
              shopifyPayment,
              ...internalPaymentGatewayCosts.map(
                ({ id, payment_gateway_name, name, cost = '0', fee = '0.3' }, i) => [
                  <Tooltip content={'Payment Gateways taken from your Shopify orders'}>
                    <div style={cell}>
                      <TextStyle variation="subdued">{name || payment_gateway_name}</TextStyle>
                    </div>
                  </Tooltip>,
                  <div style={cellHeight}>
                    <TextField
                      label={''}
                      type="number"
                      min={0}
                      max={100}
                      labelHidden={true}
                      value={cost as any}
                      onChange={(value) => onValChange('cost', value, i)}
                      prefix="%"
                      maxLength={9}
                      autoComplete={''}
                      suffix={
                        <div
                          className="cost-history"
                          color="grey"
                          title="Show Cost History"
                          onClick={() => showCostHistory(internalPaymentGatewayCosts[i], 'costs')}
                        >
                          <Icon name="clock" />
                        </div>
                      }
                    />
                  </div>,
                  <div style={cellHeight}>
                    <Tooltip content={`${fee === '0.3' ? 'Default fee is $.03' : ''}`}>
                      <TextField
                        label={''}
                        labelHidden={true}
                        type="number"
                        min={0}
                        value={fee as any}
                        onChange={(value) => onValChange('fee', value, i)}
                        prefix={CURRENCIES[currency]}
                        maxLength={9}
                        autoComplete={''}
                        suffix={
                          <div
                            className="cost-history"
                            color="grey"
                            title="Show Cost History"
                            onClick={() => showCostHistory(internalPaymentGatewayCosts[i], 'fees')}
                          >
                            <Icon name="clock" />
                          </div>
                        }
                      />
                    </Tooltip>
                  </div>,
                  <div style={cellHeight}>
                    <Tooltip content="Delete">
                      <DeleteRowButton
                        primaryAction={() => dispatch(paymentGatewayOnDelete(id))}
                        description={''}
                      />
                    </Tooltip>
                  </div>,
                  <div style={cellHeight}>
                    <Button
                      primary
                      disabled={
                        isNaN(fee as any) || fee === '' || isNaN(cost as any) || cost === ''
                      }
                      onClick={() => dispatch(paymentGatewayOnSave(+cost, +fee, id))}
                    >
                      Save
                    </Button>
                  </div>,
                ],
              ),
            ]}
          />
        </div>
      )}
      <CogsHistory
        selectedRow={selectedHistoryRow}
        getCurrencySymbol={getCurrencySymbol}
        defaultCurrency={currency}
        isOpen={isCostHistoryOpen}
        onClose={() => setIsCostHistoryOpen(false)}
        service={'shopify'}
        type={'payment_gateway_costs'}
        costType={costType}
        title={`Payment Gateway Cost History - ${costType}`}
      />
    </div>
  );
};

export const PaymentGatewayCosts = computeFeatureFlags(
  FeatureFlag.PAYMENT_GATEWAY_COSTS_PAGE_FF,
  PaymentGatewayCostsComponent,
  () => (
    <UpgradePageFallBack
      featureFlag={FeatureFlag.PAYMENT_GATEWAY_COSTS_PAGE_FF}
      InAppContextBannerEnabled={false}
      title="Payment Gateway Costs"
    />
  ),
);
