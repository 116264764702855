import {
  Anchor,
  Box,
  Button,
  Collapse,
  Flex,
  Modal,
  Select,
  Switch,
  Table,
  Text,
  Tooltip,
} from '@tw/ui-components';
import { useSelector } from 'react-redux';
import { RootState } from 'reducers/RootType';
import { SegmentDataView } from './utils';
import { CreateSegmentReq, SegmentSync, SegmentSyncReq } from '@tw/types/module/services/cdp';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useAppDispatch } from 'index';
import axiosInstance from 'utils/axiosInstance';
import { setCDPSegmentsFromServer } from 'ducks/cdp/segments';
import { toast } from 'react-toastify';
import { ProviderIcon } from 'components/library/ProviderIcon';
import moment from '@tw/moment-cached';
import { services } from '@tw/types/module/services';
import { MARKETS_LIST } from './markets';
import TWAlert from 'components/library/TWAlert/TWAlert';
import { shopProvidersStatus } from 'ducks/shopIntegrations';
import { gradualReleaseFeatures } from 'ducks/shop';

type SyncProvider = {
  value: 'facebook-ads' | 'klaviyo' | 'smsbump';
  hasAccounts: boolean;
  hasMarkets: boolean;
  segmentTerminology: string;
  name: string;
  enabled?: boolean;
};

type ProviderValue = SyncProvider['value'];

type SyncModalProps = {
  isOpened: boolean;
  onClose: () => void;
  onNewSegmentCreated?: (segmentId: string) => void;
  segment: SegmentDataView;
  isNewSyncOpenByDefault?: boolean;
  showExistingSyncs?: boolean;
};
const SyncModal: React.FC<SyncModalProps> = ({
  onClose,
  onNewSegmentCreated,
  isOpened,
  segment,
  isNewSyncOpenByDefault = false,
  showExistingSyncs = true,
}) => {
  const currentShopId = useSelector((state: RootState) => state.currentShopId);
  const facebookAdsAccounts = useSelector((state: RootState) => state.facebookAdsAccounts);
  const isBacebookConnected = useSelector((state: RootState) => state.isFacebookConnected);
  const providersStatus = useSelector(shopProvidersStatus);

  const dispatch = useAppDispatch();

  const [showCreateSectoin, setShowCreateSection] = useState(isNewSyncOpenByDefault);
  const [selectedProvider, setSelectedProvider] = useState<SyncProvider>();
  const [selectedAccountId, setSelectedAccountId] = useState<string>();
  const [selectedMarketKey, setSelectedMarketKey] = useState<string>();
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState<{ title: string; detail?: string }>();
  const { cdp_yotpo_sync: yotpoSync } = useSelector(gradualReleaseFeatures);

  const PROVIDERS: SyncProvider[] = useMemo(
    () => [
      {
        value: 'facebook-ads',
        hasAccounts: true,
        hasMarkets: true,
        segmentTerminology: 'audience',
        name: services['facebook-ads'].name,
        enabled: true,
      },
      {
        value: 'klaviyo',
        hasAccounts: false,
        hasMarkets: false,
        segmentTerminology: 'list',
        name: services['klaviyo'].name,
        enabled: true,
      },
      {
        value: 'smsbump',
        hasAccounts: false,
        hasMarkets: false,
        segmentTerminology: 'list',
        name: services['smsbump'].name,
        enabled: yotpoSync,
      },
    ],
    [yotpoSync],
  );

  useEffect(() => {
    setErrorMessage(undefined);
  }, [selectedProvider, selectedAccountId, selectedMarketKey]);

  const headers = (
    <tr>
      <th>
        <Text align="left" weight="bolder">
          Provider
        </Text>
      </th>
      <th>
        <Text align="left" weight="bold">
          Account name
        </Text>
      </th>
      <th>
        <Text align="left" weight="bold">
          Last update
        </Text>
      </th>
      <th>
        <Text align="center" weight="bold">
          Active
        </Text>
      </th>
    </tr>
  );

  const emptyRow = (
    <tr>
      <td colSpan={4}>
        <Text c="gray.5" align="center">
          <Text>This audience segment has not been synced with a provider.</Text>
          <Text>
            Click{' '}
            <Text span fw={500}>
              Create Segment Sync
            </Text>{' '}
            below to get started.
          </Text>
        </Text>
      </td>
    </tr>
  );

  const selectProvider = useCallback((provider: ProviderValue) => {
    setSelectedProvider(PROVIDERS.find((p) => p.value === provider));
    setSelectedAccountId(undefined);
    setSelectedMarketKey(undefined);
  }, []);

  const clearSelectedProvider = useCallback(() => {
    setSelectedProvider(undefined);
    setSelectedAccountId(undefined);
    setSelectedMarketKey(undefined);
  }, []);

  const handleSubmit = async () => {
    if (!selectedProvider) return;

    setErrorMessage(undefined);

    try {
      setIsLoading(true);

      let segmentId = segment.id;
      if (!segmentId) {
        const request: CreateSegmentReq = {
          name: segment.name,
          description: segment.description,
          type: segment.type,
          query: segment.query!,
        };
        const { data } = await axiosInstance.post(
          `/v2/cdp/create-segment/${currentShopId}`,
          request,
        );
        segmentId = data.segment.id;
        onNewSegmentCreated?.(segmentId);
      }

      const request: SegmentSyncReq = {
        segmentId,
        shopDomain: currentShopId,
        providerId: selectedProvider.value,
        accountId: selectedAccountId,
        market: selectedMarketKey,
      };
      await axiosInstance.post(`/v2/cdp/integrations/create-sync`, request);

      dispatch(setCDPSegmentsFromServer());
      toast.success('Sync created successfully');
      toast.info(
        'Your request is being processed. This may take a few minutes, depending on the size of the segment, you can close this modal and check the status in the list.',
      );
      clearSelectedProvider();
      onClose();
    } catch (error) {
      const errorData = error.data?.error;

      if (errorData) {
        setErrorMessage({
          title: errorData.errorMessage,
          detail: errorData.errorDetail,
        });
      } else {
        console.log(error);
        toast.error("Couldn't create sync, please try again later.");
      }
    } finally {
      setIsLoading(false);
    }
  };

  const buildOptionName = (label: string, disabled: boolean) => {
    return `${label} ${disabled ? '(Already in use, see the list above)' : ''}`;
  };

  const availableAccouts: {
    value: string;
    label: string;
    disabled: boolean;
  }[] = useMemo(() => {
    if (selectedProvider?.value === 'facebook-ads') {
      return facebookAdsAccounts.map((account) => {
        const alreadyInUse = segment.integrationsSyncDetails?.some(
          (int) => int.accountId === account.id,
        );
        return {
          value: account.id,
          label: buildOptionName(`${account.name} - (#${account.account_id})`, alreadyInUse),
          disabled: alreadyInUse,
        };
      });
    } else {
      return [];
    }
  }, [facebookAdsAccounts, segment.integrationsSyncDetails, selectedProvider?.value]);

  const isSelectedProviderConnected = useMemo(() => {
    if (selectedProvider?.value === 'facebook-ads') {
      return isBacebookConnected;
    } else if (selectedProvider?.value === 'klaviyo') {
      return providersStatus['klaviyo']?.status === 'connected';
    } else if (selectedProvider?.value === 'smsbump') {
      return providersStatus['smsbump']?.status === 'connected';
    }

    return false;
  }, [isBacebookConnected, selectedProvider?.value, providersStatus]);

  const providersOptions = useMemo(() => {
    return PROVIDERS.filter((f) => f.enabled).map((provider) => {
      const isDisabled =
        !provider.hasAccounts &&
        segment.integrationsSyncDetails.some((i) => i.providerId === provider.value);
      return {
        value: provider.value,
        label: buildOptionName(provider.name, isDisabled),
        disabled: isDisabled,
      };
    });
  }, [PROVIDERS, segment.integrationsSyncDetails]);

  const isSubmitEnabled = useMemo(() => {
    return (
      selectedProvider &&
      isSelectedProviderConnected &&
      (!selectedProvider.hasAccounts || selectedAccountId) &&
      (!selectedProvider.hasMarkets || selectedMarketKey)
    );
  }, [isSelectedProviderConnected, selectedAccountId, selectedMarketKey, selectedProvider]);

  return (
    <Modal
      title={
        <Text size="lg" fw={500}>
          Syncs for "{segment.name}"
        </Text>
      }
      onClose={() => {
        clearSelectedProvider();
        setErrorMessage(undefined);
        onClose();
      }}
      opened={isOpened}
      headerBorder
      size="lg"
    >
      <Flex gap="md" direction="column" pt="md">
        {showExistingSyncs && (
          <Table
            verticalSpacing="xs"
            highlightOnHover={segment.integrationsSyncDetails.length > 0}
            fz="sm"
          >
            <thead>{headers}</thead>
            {segment.integrationsSyncDetails.length === 0 && <tbody>{emptyRow}</tbody>}
            {segment.integrationsSyncDetails.length > 0 && (
              <tbody>
                {segment.integrationsSyncDetails.map((sync, i) => (
                  <TableRow
                    key={sync.providerAudienceId + i}
                    segmentId={segment.id}
                    syncDetails={sync}
                  />
                ))}
              </tbody>
            )}
          </Table>
        )}

        <Box>
          {!isNewSyncOpenByDefault && (
            <Text ta="center" mb="md">
              <Anchor onClick={() => setShowCreateSection(!showCreateSectoin)}>
                + Create Segment Sync
              </Anchor>
            </Text>
          )}
          <Collapse in={showCreateSectoin}>
            <Flex direction="column" gap="xl">
              <Flex direction="column" gap="xs">
                <Select
                  label="Provider"
                  placeholder="Select provider"
                  data={providersOptions}
                  onChange={(v) => selectProvider(v as ProviderValue)}
                  value={selectedProvider?.value || null}
                  error={
                    selectedProvider && !isSelectedProviderConnected ? (
                      <>
                        You don't seems to have any {selectedProvider.name} accounts connected,
                        please{' '}
                        <Anchor size="xs" href="/integrations" target="_blank">
                          {' '}
                          connect one first.
                        </Anchor>{' '}
                      </>
                    ) : undefined
                  }
                />

                {selectedProvider?.hasAccounts && (
                  <Select
                    label={`${selectedProvider.name} Account`}
                    placeholder="Select account"
                    data={availableAccouts}
                    onChange={(val) => setSelectedAccountId(val!)}
                  />
                )}

                {selectedProvider?.hasMarkets && (
                  <Select
                    placeholder="Start typing to search for a market"
                    searchable
                    label="Lookalike audience market"
                    data={MARKETS_LIST}
                    onChange={(val) => setSelectedMarketKey(val!)}
                  />
                )}
              </Flex>

              <Flex direction="column" gap="xs">
                <Button
                  variant="secondary"
                  onClick={handleSubmit}
                  disabled={!isSubmitEnabled || isLoading}
                  loading={isLoading}
                >
                  Submit
                </Button>

                {selectedProvider && isSubmitEnabled && !errorMessage && (
                  <Text ta="center" c="green.7" size="xs">
                    New {selectedProvider.segmentTerminology} (named{' '}
                    <b>"Triple Whale Generated SCDP Audience {segment.name}"</b>) will be created in
                    your {selectedProvider.name} account.
                  </Text>
                )}
              </Flex>
            </Flex>
          </Collapse>
        </Box>

        {errorMessage && (
          <TWAlert type="error" title={errorMessage.title} message={errorMessage.detail}></TWAlert>
        )}
      </Flex>
    </Modal>
  );
};

export default SyncModal;

const TableRow: React.FC<{ segmentId: string; syncDetails: SegmentSync }> = ({
  segmentId,
  syncDetails,
}) => {
  const facebookAdsAccounts = useSelector((state: RootState) => state.facebookAdsAccounts);
  const dispatch = useAppDispatch();
  const currentShopId = useSelector((state: RootState) => state.currentShopId);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const toggleSync = useCallback(async () => {
    const action = syncDetails.isSynced ? 'un-synced' : 'synced';

    try {
      setIsLoading(true);

      const request: SegmentSyncReq = {
        segmentId: segmentId,
        shopDomain: currentShopId,
        accountId: syncDetails?.accountId,
        providerId: syncDetails.providerId,
      };
      await axiosInstance.post('/v2/cdp/integrations/toggle-sync', request);
      dispatch(setCDPSegmentsFromServer());
      toast.success(`Segment was ${action} successfully.`);
    } catch (e) {
      toast.error(`Failed to ${action} segment.`);
    } finally {
      setIsLoading(false);
    }
  }, [
    currentShopId,
    dispatch,
    segmentId,
    syncDetails?.accountId,
    syncDetails.isSynced,
    syncDetails.providerId,
  ]);

  const lastUpdatedAtText: string = useMemo(() => {
    return syncDetails.lastUpdatedAt ? moment(syncDetails.lastUpdatedAt).fromNow() : '-';
  }, [syncDetails.lastUpdatedAt]);

  return (
    <tr>
      <td>
        <Flex align="center" gap="xs">
          <ProviderIcon provider={syncDetails.providerId} />
          <Text>{services[syncDetails.providerId].name}</Text>
        </Flex>
      </td>
      <td>
        <Text>
          {facebookAdsAccounts.find((acc) => acc.id === syncDetails.accountId)?.name ||
            syncDetails.accountId}
        </Text>
      </td>
      <td>
        <>
          {syncDetails.error ? (
            <Tooltip
              color="red.1"
              multiline
              zIndex={99999}
              closeDelay={1500}
              label={
                <Text span size="xs" c="red.4">
                  <Text fw={300}>{syncDetails.error.errorMessage}</Text>
                  <Text maw={500} fw={300}>
                    {syncDetails.error.errorDetail}
                  </Text>
                </Text>
              }
            >
              <Text c="red.6">{lastUpdatedAtText}</Text>
            </Tooltip>
          ) : (
            <Text>{lastUpdatedAtText}</Text>
          )}
        </>
      </td>
      <td>
        <Flex justify="center">
          <Switch
            size="xs"
            checked={syncDetails.isSynced}
            onChange={toggleSync}
            disabled={isLoading}
          />
        </Flex>
      </td>
    </tr>
  );
};
