import React, { useEffect, useState } from 'react';
import { useAppDispatch } from '../../../index';
import { useSelector } from 'react-redux';
import { RootState, useAppSelector } from '../../../reducers/RootType';
import axiosInstance from '../../../utils/axiosInstance';
import { updatePixelInstallStatus, updatePixelSettings } from '../../../ducks/pixel';
import { Icon, Spinner } from '@shopify/polaris';
import {
  PIXEL_3RD_PARTY_INSTALL,
  PIXEL_ON_POST_PURCHASE_UPSELL,
} from '../../../constants/knowledgeBaseLinks';
import { ExternalMinor } from '@shopify/polaris-icons';
import { toast } from 'react-toastify';
import { getThemeAppExtensionDeepLink } from '../../attribution/themeAppHelper';
import { Button, Modal, Text } from '@tw/ui-components';
import { connectToShopify } from 'utils/connectToShopify';
import SALES_PLATFORMS from 'constants/SalesPlatform';

export const AutomaticInstall: React.FC<{}> = ({}) => {
  const dispatch = useAppDispatch();
  const shopId = useSelector((state: RootState) => state.currentShopId);
  let { allow_auto_install } = useSelector((state: any) => state.pixelSettings);
  const pixelInstallStatus = useSelector((state: any) => state.pixelInstallStatus);
  const [installInProgress, setInstallInProgress] = useState<boolean>(false);
  const [hasProperShopifyPermissions, setHasProperShopifyPermissions] = useState(null);
  const [isPermissionsModalOpen, setIsPermissionsModalOpen] = useState<boolean>(false);
  const shopMsp = useAppSelector((state) => state.msp);
  const MSP_TEXT = shopMsp ? SALES_PLATFORMS[shopMsp]?.title : shopMsp;

  async function fetchData() {
    try {
      const { data: shopifyScopes } = await axiosInstance.get(
        `/v2/shopify/get-shopify-scopes?shopId=${shopId}`,
      );
      try {
        setHasProperShopifyPermissions(
          shopifyScopes?.access_scopes.map((i) => i.handle).includes('write_pixels') &&
            shopifyScopes?.access_scopes.map((i) => i.handle).includes('read_customer_events'),
        );
      } catch (e) {
        console.log(e);
      }
    } catch (error) {}
  }

  useEffect(() => {
    if (!shopId) return;
    if (shopMsp !== 'shopify') return;
    fetchData();
  }, [shopId, hasProperShopifyPermissions, shopMsp]);

  async function automaticInstall() {
    if (!hasProperShopifyPermissions && shopMsp === 'shopify') {
      setIsPermissionsModalOpen(true);
    } else {
      setInstallInProgress(true);
      try {
        await dispatch(updatePixelSettings({ allow_auto_install: true }));
        const res = await axiosInstance.post('v2/pixel/installation/auto-install', {
          shopDomain: shopId,
        });
        if (shopMsp === 'shopify') {
          window.open(getThemeAppExtensionDeepLink(shopId), '_blank')?.focus();
        }
        await dispatch(updatePixelInstallStatus(res.data));
        if (
          res.data?.thankyou_status ||
          res.data?.web_pixel_status ||
          (shopMsp === 'bigcommerce' && res.data?.status)
        ) {
          toast.success('Pixel was installed successfully.');
        } else {
          toast.error('An error occurred, please try again later.');
        }
        setInstallInProgress(false);
      } catch {
        dispatch(updatePixelInstallStatus());
      }
    }
  }

  async function removeAutoInstall() {
    setInstallInProgress(true);
    try {
      await dispatch(updatePixelSettings({ allow_auto_install: false }));
      const res = await axiosInstance.post('v2/pixel/installation/auto-uninstall', {
        shopDomain: shopId,
      });
      window.open(getThemeAppExtensionDeepLink(shopId), '_blank')?.focus();
      await dispatch(updatePixelInstallStatus(res.data));
      if (!res.data?.thankyou_status && !res.data?.web_pixel_status) {
        toast.success('Pixel was uninstalled successfully.');
      } else {
        toast.error('An error occurred, please try again later.');
      }
      setInstallInProgress(false);
    } catch {
      dispatch(updatePixelInstallStatus());
    }
  }

  async function closePermissionModal() {
    await fetchData();
    setIsPermissionsModalOpen(false);
  }

  return (
    <div className="text-secondary-text">
      <p className="text-[15px] bolder mb-[5px]">Enable The Triple Pixel</p>
      {shopMsp !== 'bigcommerce' ? (
        <div>
          <div className="text-[14px] regular mb-[12px]">
            To track visitors, the Triple Whale Pixel must be installed on all of your online store
            pages and on your Order Confirmation page. To begin installation, click the "Enable
            Pixel" button below. When you click the button:
            <div className="flex">
              <ul>
                <li>We will automatically attempt to install the Triple Pixel on your checkout</li>
                <li>
                  You will be directed to your {MSP_TEXT} theme editor, where you should see the
                  Triple Pixel app block toggled "on"
                </li>
                <li>
                  Click <span className="shopify-like-button">Save</span> in your theme editor to
                  enable the pixel on your online store pages
                </li>
              </ul>
              <div>
                <img
                  src={'/master/theme_app.png'}
                  className="w-[250px] m-[10px]"
                  alt="Shopify theme app"
                />
              </div>
            </div>
          </div>
          {allow_auto_install && pixelInstallStatus?.status ? (
            <div className="red">
              <Button onClick={async () => await removeAutoInstall()}>Disable Triple Pixel</Button>
            </div>
          ) : (
            <Button onClick={async () => await automaticInstall()} disabled={installInProgress}>
              Enable Triple Pixel {installInProgress && <Spinner size="small" />}
            </Button>
          )}
        </div>
      ) : (
        <div>
          <div className="text-[14px] regular mb-[12px]">
            Click the button below to automatically install the Triple Pixel on your store
          </div>
          <Button onClick={async () => await automaticInstall()} disabled={installInProgress}>
            Enable Triple Pixel {installInProgress && <Spinner size="small" />}
          </Button>
        </div>
      )}

      <div className="mt-[30px] mb-[30px]">
        <span className="font-medium">
          {' '}
          Note: If you are running a headless shop, standard installation will not work. Please
          follow the instructions on the "Headless installation" tab.
        </span>
      </div>

      <div className="mt-[30px] mb-[30px]">
        <p className="text-[15px] font-bold mb-[5px]">Do you have any custom landing pages?</p>
        <p className="text-[15px] regular mb-[12px]">
          If you have landing pages that aren’t hosted by {MSP_TEXT} (Unbounce, Unstack, etc.), you
          will need to manually install the Pixel separately on those page by following the
          instructions on the Third-Party Landing Pages tab.
        </p>
        <p className="text-[13px]">
          <a href={PIXEL_3RD_PARTY_INSTALL} target="_blank">
            Installing the Pixel on Custom Landing Pages
            <span className="text-icon w-[13px]">
              <Icon source={ExternalMinor} />
            </span>
          </a>
        </p>
      </div>

      {shopMsp === 'shopify' && (
        <div className="mt-[30px] mb-[30px]">
          <p className="text-[15px] font-bold mb-[5px]">
            Do you have a post-purchase app that uses Shopify's Upsell page?
          </p>
          <p className="text-[15px] mb-[12px]">
            If you have a post-purchase app that uses Shopify's Upsell page, then you will need to
            install the Pixel manually by following the instructions for Post-Purchase upsells in
            the Manual Installation tab.
          </p>
          <p className="text-[13px]">
            <a href={PIXEL_ON_POST_PURCHASE_UPSELL} target="_blank">
              Installing the Pixel on the Post-Purchase Upsell Page
              <span className="text-icon w-[13px]">
                <Icon source={ExternalMinor} />
              </span>
            </a>
          </p>
        </div>
      )}
      <Modal.Root
        opened={isPermissionsModalOpen}
        onClose={async () => await closePermissionModal()}
        centered
      >
        <Modal.Overlay />
        <Modal.Content className="">
          <Modal.Body>
            <div className="float-end ml-[10px]">
              <Modal.CloseButton />
            </div>
            <div id="online-store">
              <div className="mb-[25px]">
                <Text as="p">
                  To add the Triple Whale Web Pixel Extension to your store, you must first update
                  Triple Whale’s app permissions in Shopify.
                </Text>
              </div>
              <div className="float-end flex gap-6 pb-[20px]">
                <Button variant={'white'} onClick={async () => await closePermissionModal()}>
                  Updated, refresh
                </Button>
                <Button onClick={async () => await connectToShopify(shopId, true)}>Update</Button>
              </div>
            </div>
          </Modal.Body>
        </Modal.Content>
      </Modal.Root>
    </div>
  );
};
