import Attribution from 'pages/Attribution/Attribution';
import { FeatureFlag } from '@tw/feature-flag-system/module/types';
import { computeFeatureFlags } from 'feature-flag-system';
import { UpgradePageFallBack } from 'feature-flag-system/components';

const AttributionSocialPageComponent = ({ setDashboardPixelData }) => {
  return <Attribution sourceCategory="social" setDashboardPixelData={setDashboardPixelData} />;
};

const AttributionSocialPage = computeFeatureFlags(
  FeatureFlag.PIXEL_ORGANIC_FF,
  AttributionSocialPageComponent,
  () => (
    <UpgradePageFallBack
      InAppContextBannerEnabled={true}
      InAppContextBannerPage={'attribution'}
      featureFlag={FeatureFlag.PIXEL_ORGANIC_FF}
    />
  ),
);

export default AttributionSocialPage;
