import { useMeetingLink } from '../hooks/useMeetingLink';

export const MeetingPlanner: React.FC = () => {
  const height = 600; // seems to work 🙃
  const scale = 0.8;
  const margin = -(height * ((1 - scale) / 2));
  const meetingLink = useMeetingLink();

  if (!meetingLink) return null;

  return (
    <div
      className="overflow-hidden w-full flex justify-center"
      style={{
        minHeight: `${height}px`,
        maxHeight: `${height}px`,
        marginTop: margin,
        marginBottom: margin,
        transform: `scaleX(${scale}) scaleY(${scale})`,
      }}
    >
      <iframe
        style={{ display: 'block', border: 'none' }}
        className="meetings-iframe-container w-full"
        width="560"
        height={`${height}`}
        src={meetingLink}
      />
    </div>
  );
};
